import { Injectable, Inject } from '@angular/core';
import { environment } from '../../../environments/environment';

// Services
import { CookieService } from 'ngx-cookie-service';
import { BrokerService } from './broker.service';

// Types
import { PlanesType } from '../interfaces/planes.interface';

// Models
import { ResumenHC, Reporte, DetalleCuenta, simulator } from '../models/resumenhc.model';
import { Router } from '@angular/router';
import { Generic } from '../models/generic.model';
import { LoadingService } from './loading.service';
import { Identity } from '../models/identity.model';
import { User } from '../models/user.model';
import { ModalService } from './modal.service';
import { ProbabilidadCredito } from '../models/probabilidadcredito.model';
import { SolicitudCreditoRequest } from '../models/solicitudcreditorequest.model';
import { SolicitudCreditoResponse } from '../models/solicitudcreditoresponse.model';
import { CountDkw } from '../models/notificationcount.model';
import { ListDkw } from '../models/notificationlist.model';
import { MiDeuda } from '../models/misdeudas.model';
import { ChartData, ChartType } from 'chart.js';
// import { Label } from 'ng2-charts';
import { Huellas } from '../models/listHuellas.model';
import { Notificaciones } from '../models/listNotificaciones.model';
import { IdentityAddData } from '../models/identity.addData.model';
import { AccionesdkwService } from './accionesdkw.service';
import { Diagnostico } from '../models/diagnostico.model';
import { TextosDarkWeb } from '../models/textosDarkWeb.model';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { PQRS } from '../models/pqrs.model';
import { ContactResponse } from '../models/contactResponse.model';
import { ContactRequest } from '../models/contactRequest.model';
import { DomSanitizer } from '@angular/platform-browser';
import { Notificacion } from '../models/notificationTypes.model';
import { Pais } from '../models/paises.model';
import { Reclamaciones } from '../models/reclamaciones.model';
import { ReclamacionTipos } from '../models/reclamacionTipos.model';
import { Ciudad } from '../models/ciudades.model';
import { StatePayment } from '../models/confirmacionPago.model';
import { Alerta } from '../models/alerta.model';
import { TiposAlertas } from '../models/alertasTipo.model';
import { ListadoAlertas } from '../models/alertasConsulta.model';
import { Facturas } from '../models/misfacturas.model';
import { Factura } from '../models/factura.model';
import { Plan } from '../models/plan.model';
import { HistoriaCredito } from '../models/historiaCredito.model';
import { FormularReclamacion } from '../models/formular-reclamacion.module';
import { ThemeService } from 'ng2-charts';
import { DebtsService } from './debts.service';
import { authorization,setAuthorization } from '../models/authorization.model';

declare var $: any;
declare var dataLayer: any;

// Interface Promocode Antiguo
// interface Descuento1 {
//   action: string;
//   session: string;
//   cedula: string;
//   params: {
//     promoCode: string;
//     idPlan: string;
//   };
// }

interface Descuento {
  action: string;
  session: any;
  cedula: any;
  params: {
    promoCode: any;
    idPlan: string;
  };
}

@Injectable({
  providedIn: 'root'
})
export class SesionService {

  messageAlerts: any[] = [];
  //LLenar la Los mensajes de Alerta
  messError = false;
  messCheck = false;
  messBg = '';
  messTitle: any;
  messDescription: any;
  messIcon = '';
  messColorTxt = ''


  cookieValue: any = null;
  loggedIn = false;
  estadoCelular = false;
  proteccion = false;
  monitoreo = false;
  poliza = false;
  estadoCodigo = false;
  estadoUserF: any;
  viewFree:boolean=true;
  estadoDark: any;
  terminos: any;
  advertising: boolean = false;
  today: any;
  authorizations: Array<authorization> =[];
  setAuthorizations: setAuthorization[] =[];

  // Planes
  planes = {} as PlanesType;
  planesPromo = {} as PlanesType;
  linkPlanes = '/comparacion-planes';
  facturacion = 1;
  campLocal: any;
  planesSSO: any;
  landingProtege: any;
  home: any;

  // User
  user: any = null;
  dataUser: any;
  planCurrently: any;
  spinnerLoader = false;

  // Data
  miscore: any;
  miscoreInt: any;
  totalPositivas: any;
  totalNegativas: any;
  //totalDeuda: any;
  reporteCovid: any;
  resumenhc: any;
  ctascerradas: any;
  resumenhcFree: any;
  ctascerradasFree: any;
  diagnostico: any;
  numCuenta: any;
  detalleCuentaFree: any;
  nombreCuenta: any;
  simulator!: simulator;

  validateOTPU:string="";
  principal:string="/home";

  // Mis Deudas
  misdeudas: any;
  resumenDeudasChartLabels: string[] = [];
  resumenDeudasChartData: ChartData<'doughnut'> = { labels: [], datasets: [] };
  resumenDeudasChartBackgrounds = [
    {
      backgroundColor: ['#3D87AF', '#3B2B5B', '#BA2F7D', '#00A29A'],
    },
  ];
  resumenDeudasChartType: ChartType = 'doughnut';

  totalOtrosChartLabels: string[] = [];
  totalOtrosChartData: ChartData<'doughnut'> = { labels: [], datasets: [] };
  totalOtrosChartBackgrounds = [
    {
      backgroundColor: ['#3D87AF', '#BA2F7D'],
    },
  ];
  totalOtrosChartType: ChartType = 'doughnut';
  totalOtrosChartTypeMobile: ChartType = 'doughnut';

  totalTcChartLabels: string[] = [];
  totalTcChartData: ChartData<'doughnut'> = { labels: [], datasets: [] };
  totalTcChartBackgrounds = [
    {
      backgroundColor: ['#C8C922', '#C0C0C0'],
    },
  ];
  totalTcChartType: ChartType = 'doughnut';
  totalTcChartTypeMobile: ChartType = 'doughnut';

  // Escaneo
  anonimo: any = null;
  anonimoInterno: any = null;
  identity: any = null;
  countdk: any = null;
  listdk: any = null;
  textosDarkWeb: any = null;
  actualizandoListdk = false;

  // Proximo credito
  probabilidad: any;
  probabilidadClase1: any;
  probabilidadClase2: any;
  probabilidadClase3: any;
  simulacionError = false;
  simulacionResponse: any;

  // Notificaciones y alertas
  listNotificaciones: any;
  listHuellas: any;

  // Preguntas frecuentes
  pqrs: any = null;

  // Contacto
  contacto: any;

  // Notificaciones
  notificacionesActivas: any;
  listadoNotificaciones: any;
  notifications: any;
  bellCountData: any;

  // Paises
  paises: any;

  // Ciudades
  ciudades: any;

  // Bancos
  bancos: any;

  // Reclamaciones
  reclamos: boolean;
  reclamaciones: any;
  //tiposReclamos: ReclamacionTipos;
  tiposReclamos: any;
  solicitudReclamaciones: any;

  // Confirmacion pago
  confirmacion: any;

  // Alertas al sistema financiero
  //solicitud: any;
  alertasTipos: any;
  //listadoAlertas: ListadoAlertas;
  listadoAlertas: any;
  responseAlertWasCreated: boolean = false;
  alertCode: any;
  alertText: any;
  caseError = true;
  listaTipos: any;

  // Facturacion
  misfacturas: any;
  factura: any;

  // Evidente
  stateEvidente: any;

  // Pagos
  plan: any;
  tipoPlan: any;
  planId: any;
  valorPlanMensual: any;
  valorPlanTotal: any;
  valorPlanTotalDescuento: any;
  estadoTransaccion = false;
  descuento: any;
  descuentoData: any;

  // SERVICES
  service: any;

  //PONTE AL DÍA
  sesionCookie: any;

  //UPSELLING
  upsellDay: any;
  expiratonDays: any;
  planExpiration: any;
  activeUser: any;
  dateToday: any;
  daysMore: any;

  //ALERT PENDING
  alertPending = false;

  //CARRITOS ABANDONADOS
  //public Data = {};
  spinnerDebt = false;
  btnValidar = true;
  dataCart2: any;
  dataCart3: any;
  showHeader = true;


  spinnerScore = false;
  numberScore = false;

  errorServiceCreditHistory = false;

  //Loader
  // spinnerDebt = false;
  // btnValidar = true;
  loadHCFree = true;
  loadHCPay = false;
  // spinnerScore = false;
  // numberScore = false;

  public footerPay = false;

  // Riesgo del servicio de semaforo
  public risk: any;
  public riskUser: any;

  //Parametros para intermitencia
  public messageIntermittence: any;
  public intermittenceClaimHour: any;
  public intermittenceGeneral: any;
  public testimonials: any;
  public sliders: any;


  // tslint:disable-next-line: variable-name
  private _loadedLibraries: { [url: string]: ReplaySubject<any> } = {};

  //Parametros Dash PAD
  public showModulePad = false;
  public isLoadedDebts= true;

  //Parametros Dash BC
  public showModuleBC = false;

  //menu
  dataMenu:any;
  flagNewMenu:boolean=false;

  //advance score
  modalAdvance=false;

  //campain Freemium
  campainFree:boolean=false;
  campainfinalDate:string="";

  constructor(
    @Inject(DOCUMENT) public readonly document: any,
    private cookieService: CookieService,
    public broker: BrokerService,
    private router: Router,
    private loading: LoadingService,
    private modal: ModalService,
    private accionesDkw: AccionesdkwService,
    private sanitizer: DomSanitizer,
    public theme: ThemeService,
    public debts:DebtsService
  ) {
    this.messageAlerts = [
      {
        spinner: [
          {
            text: "Por favor espera un momento...",
            description: "Estamos validando tu información para activar la alerta"
          },
          {
            text: "Por favor espera un momento",
            description: "Estamos validando la información para activar tu plan"
          }
        ]
      },
      {
        alerts: [
          {
            text: "¡Muy bien!",
            description: "Tu alerta ha sido registrada exitosamente."
          },
          {
            text: "¡Lo sentimos!",
            description: "Esta alerta ya esta registrada en nuestro sistema."
          },
          {
            text: "¡Lo sentimos!",
            description: "No es posible responder a tu solicitud por favor intenalo más tarde."
          }
        ]
      },
      {
        reclamations: [
          {
            text: "¡Muy bien!",
            description: "Tu reclamación ha sido registrada exitosamente."
          },
          {
            text: "¡Lo sentimos!",
            description: "En este momento no es posible radicar tu reclamación. Por favor inténtalo más tarde."
          },
          {
            text: "¡Lo sentimos!",
            description: "No es posible responder a tu solicitud por favor intentalo más tarde."
          }
        ]
      }
    ];
    this.reclamos = environment.reclamos;
    this.notificacionesActivas = [
      {
        notificationId: '1',
        name: 'Cambio estado documento',
        description: 'Cambio en el estado de su documento de identidad',
        checked: false
      },
      {
        notificationId: '2',
        name: 'Cambio nombre registrado DC',
        description: 'Cambio en el nombre registrado en DataCrédito',
        checked: false
      },
      {
        notificationId: '3',
        name: 'Cambio de novedad',
        description: 'Cambio de novedad en una obligación o cuenta en su historia de crédito',
        checked: false
      },
      {
        notificationId: '5',
        name: 'Cambio en el saldo en mora',
        description: 'Cambio en el saldo en mora de una obligación o cuenta en su historia de crédito',
        checked: false
      },
      {
        notificationId: '6',
        name: 'Registro de un reclamo',
        description: 'Registro de un reclamo en su historia de crédito',
        checked: false
      },
      {
        notificationId: '7',
        name: 'Respuesta a un reclamo registrado',
        description: 'Respuesta a un reclamo registrado en su historia de crédito',
        checked: false
      },
      {
        notificationId: '8',
        name: 'Inactivación de una alerta',
        description: 'Inactivación de una alerta en su historia de crédito',
        checked: false
      },
      {
        notificationId: '9',
        name: 'Ingreso de una nueva obligación o cuenta',
        description: 'Ingreso de una nueva obligación o cuenta a su historia de crédito',
        checked: false
      },
      {
        notificationId: '10',
        name: 'Ingreso de una nueva huella de consulta',
        description: 'Ingreso de una nueva huella de consulta en su historia de crédito',
        checked: false
      },
      {
        notificationId: '11',
        name: 'No se generaron cambios en su historia de crédito',
        description: 'No se generaron cambios en su historia de crédito en el mes',
        checked: false
      },
      {
        notificationId: '20',
        name: 'Deep/Dark web',
        description: 'Alertas por información personal comprometida en la Deep/Dark web',
        checked: false
      },
    ];
  }


  /**********************************************************
  ************************** GENERALES ***************************
  ***********************************************************/

  sanitizerHTML(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  isEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  loadingOpen() {
    this.loading.open();
  }


  /**********************************************************
  ************************** PLANES ***************************
  ***********************************************************/

  obtener_planes() {
    if (this.isEmpty(this.planes)) {
      this.broker.obtener_planes().subscribe((data: any) => {
        this.planes = data;
      },
        (error) => {
          // this.router.navigate(['/error']);
        }
      );
    }
  }
  obtener_planes_promo() {
    if (this.isEmpty(this.planesPromo)) {
      this.broker.obtener_planes_promo().subscribe((data: any) => {
        this.planesPromo = data;
      },
        (error) => {
          // this.router.navigate(['/error']);
        }
      );
    }
  }
  
  //MENU
  getMenu() {
    return new Observable<boolean>(obs => {
      if (this.isEmpty(this.dataMenu)) {
        this.broker.getMenu()
        .subscribe(
        {
          next: (data: any) => {
            this.dataMenu =data.items;
            this.flagNewMenu = data.new;
            obs.next(true);
          },
          error: (error) => {
          }
        });
      }else{
        obs.next(true);
      }
    });
  }

  //INTERMITENCIA
  getIntermittence() {
    return new Observable<boolean>(obs => {
      if (this.isEmpty(this.sliders)) {
        this.broker.getIntermittenceParameter().subscribe({
          next: (response: any) => {
            let date = new Date()
            let day = date.getDate();
            let month = date.getMonth();
            let year = date.getFullYear();
            this.messageIntermittence = response.modal.message;
            this.intermittenceClaimHour = response.claim;
            this.intermittenceGeneral = response.modal;
            this.testimonials = response.testimonials;
            this.sliders = response.sliders;
            obs.next(true);
          },
          error:(error:any)=>{
            obs.next(true);
          }
        });
      }else{
        obs.next(true);
      }
    });
  }


  /**********************************************************
  ************************** SESION ***************************
  ***********************************************************/

  validateCookie() {
    if (this.cookieValue) {
      this.loadingOpen();
      this.validateSession(true);
    }
  }

  validateSession(loading: boolean) {
    if (this.cookieValue) {
      this.broker.validationSession(this.cookieValue).subscribe((resp: any) => {
        this.loggedIn = true;
        this.user = resp;
        // if ( !this.dataUser ) {
        //   this.datosUsuario( loading );
        // }
      },
        (error) => {
          this.cleanData();
        }
      );
    }
  }

  getCookieServices(service: string) {
    return this.cookieService.get(service);
  }
  setCookieServices(service: string, value: string) {
    this.cookieService.set(service, value, undefined, '/', environment.domain, true);
  }
  deleteCookieServices(service: string, value: string) {
    this.cookieService.delete(service, value, environment.domain);
  }

  getCookie() {
    this.cookieValue = this.cookieService.get('IDSESSIONMDC');
  }

  deleteCookie() {
    this.cookieService.delete('IDSESSIONMDC', '/', environment.domain);
  }
  deleteStorage(){
    sessionStorage.removeItem('itemsPAD');
  }

  // SESION
  closeSession() {
    if (this.cookieValue) {
      this.loading.open();

      this.broker.cerrarSesion(this.cookieValue).subscribe((resp: any) => {
        this.cleanData();
      },
        (error) => {
          this.cleanData();
        }
      );
    } else {
      this.cleanData();
    }
  }

  cleanData() {
    this.cookieService.deleteAll();
    this.deleteCookie();
    this.loggedIn = false;
    this.cookieValue = null;
    this.router.navigate(['/']);
    this.linkPlanes = '/comparacion-planes';
    this.loading.close();
    this.user = null;
    this.dataUser = null;
    this.deleteStorage();
  }

  /**********************************************************
  ************************** DATA ***************************
  ***********************************************************/
  public allHistory: any;
  public pdfDownload: any;
  public isLoadHC:boolean = true;
  // HISTORIA DE CREDITO

  infoHC(repeat:boolean) {
      this.spinnerScore = true;
      this.numberScore = false;
      this.consultarAlerta();
      // this.listarAlertas();
      this.isLoadHC = true;
      this.broker.infoHC(this.cookieValue).subscribe(
        {
          next: (data:any) =>{
            this.allHistory = data;
            this.spinnerScore = false;
            this.numberScore = true;
            this.resumenhc = data.openAccounts;
            this.ctascerradas = data.closedAccounts;
            this.reclamaciones = data.reclamos;
            this.diagnostico = data.diagnostic;
            this.listHuellas = data.trace;
            this.pdfDownload = data.pdfDownload;
            if(data.simulator && data.simulator != null){
              this.simulator = data.simulator.scenery;
            }
            if(repeat && this.resumenhc ==null && this.ctascerradas ==null && this.reclamaciones ==null && this.listHuellas ==null){
              setTimeout(() => {
                this.infoHC(false);
                }, 500);
            }
            this.miscore = data.score.score;
            this.miscoreInt = parseInt(data.score.score);
            
      
            let totalPositivas = 0;
            let totalNegativas = 0;
      
            for (let i = 0; i < this.resumenhc.length; i++) {
              let num = this.resumenhc[i].cuentasPositivas;
              totalPositivas = num + totalPositivas;
      
              let num2 = this.resumenhc[i].cuentasNegativas;
              totalNegativas = num2 + totalNegativas;
            }
      
            this.totalPositivas = totalPositivas;
            this.totalNegativas = totalNegativas;
      
            if (data.probability.objResult) {
              this.probabilidad = data.probability;
              this.probabilidadClase1 = this.probabilidadClase(this.probabilidad.objResult.HIP);
              this.probabilidadClase2 = this.probabilidadClase(this.probabilidad.objResult.CAB);
              this.probabilidadClase3 = this.probabilidadClase(this.probabilidad.objResult.TDC);
            }
      
            this.misdeudas = data.debtsSummary;
            this.resumenDeudasChartLabels = [
              this.misdeudas[0].nombreDeuda,
              this.misdeudas[1].nombreDeuda,
              this.misdeudas[2].nombreDeuda,
              this.misdeudas[3].nombreDeuda
            ];
            let dataDeudasChart = [
              this.misdeudas[0].saldoActual,
              this.misdeudas[1].saldoActual,
              this.misdeudas[2].saldoActual,
              this.misdeudas[3].saldoActual
            ];
      
            this.resumenDeudasChartData.labels = this.resumenDeudasChartLabels;
            this.resumenDeudasChartData.datasets = [{ data: dataDeudasChart }];
      
            // this.theme.setColorschemesOptions()
      
            // let totalDeuda = 0;
      
            // for (let i = 0; i < this.misdeudas.length; i++) {
            //   if (this.misdeudas[i].nombreDeuda == 'Saldo en Tarjetas de Crédito/Rotativos') {
            //     let cupoTotal = this.misdeudas[i].cupoTotal;
            //     let valorInicial = this.misdeudas[i].valorInicial;
            //     totalDeuda = cupoTotal + valorInicial;
            //   }
            // }
      
            // this.totalDeuda = totalDeuda;
      
            const totalCO = this.misdeudas[0].valorInicial + this.misdeudas[1].valorInicial + this.misdeudas[2].valorInicial;
            const saldoCO = this.misdeudas[0].saldoActual + this.misdeudas[1].saldoActual + this.misdeudas[2].saldoActual;
            const pagoCO = totalCO - saldoCO;
      
            const porcetanjeTCO = Math.round(((totalCO - pagoCO) * 100) / totalCO);
            const porcentajeSCO = Math.round(((totalCO - saldoCO) * 100) / totalCO);
      
            // CREDITOS Y OTROS
            this.totalOtrosChartLabels = [
              'Total Pagado ' + porcentajeSCO + '%',
              'Total Deuda ' + porcetanjeTCO + '%'
            ];
            let dataOtrosChart = [
              (totalCO - saldoCO),
              saldoCO
            ];
      
            this.totalOtrosChartData.labels = this.totalOtrosChartLabels;
            this.totalOtrosChartData.datasets = [{ data: dataOtrosChart }];
      
            const totalTc = this.misdeudas[3].cupoTotal;
            const saldoTc = this.misdeudas[3].saldoActual;
            const pagoTc = totalTc - saldoTc;
      
            const porcetanjeTTC = Math.round(((totalTc - pagoTc) * 100) / totalTc);
            const porcentajeSTC = Math.round(((totalTc - saldoTc) * 100) / totalTc);
      
            // TC
            this.totalTcChartLabels = [
              'Total Pagado ' + porcentajeSTC + '%',
              'Total Deuda ' + porcetanjeTTC + '%'
            ];
            let dataTcChart = [
              (totalTc - saldoTc),
              saldoTc
            ];
            this.totalTcChartData.labels = this.totalTcChartLabels;
            this.totalTcChartData.datasets = [{ data: dataTcChart }];
            this.errorServiceCreditHistory = false;
            this.isLoadHC = false;
            this.debts.getDebtsPrintPadYa(this.allHistory);
          },
          error: (error: any) =>{
            if(repeat){
              setTimeout(() => {
              this.infoHC(false);
              }, 500);
            }else{
              this.errorServiceCreditHistory = true;
            }
          }
        }
      );
  }

  // FREEPREMIUM
  freepremium(repeat:boolean) {
    this.today = new Date();
    this.consultarAlerta();
    this.isLoadHC = true;
    this.broker.freepremium().subscribe(
      {
        next: (dataFree: any)=>{
          this.allHistory = dataFree;
          this.resumenhc = dataFree.openAccounts;
          this.ctascerradas = dataFree.closedAccounts;
          this.reclamaciones = dataFree.reclamos;
          this.listHuellas = dataFree.trace;
          if(dataFree.score != null && dataFree.score && dataFree.finalDate && dataFree.finalDate != null){
            this.campainFree =true;
            this.modal.modalFreemium()	
            this.miscore = dataFree.score.score;
            this.miscoreInt = parseInt(dataFree.score.score);
            this.campainfinalDate = dataFree.finalDate;
          }
          if(repeat && this.resumenhc ==null && this.ctascerradas ==null && this.reclamaciones ==null && this.listHuellas ==null){
            setTimeout(() => {
              this.freepremium(false);
              }, 500);
          }
          let totalPositivas = 0;
          let totalNegativas = 0;
          this.risk = dataFree.risk;
    
          for (let i = 0; i < this.resumenhc.length; i++) {
            let num = this.resumenhc[i].cuentasPositivas;
            totalPositivas = num + totalPositivas;
    
            let num2 = this.resumenhc[i].cuentasNegativas;
            totalNegativas = num2 + totalNegativas;
          }
    
          this.totalPositivas = totalPositivas;
          this.totalNegativas = totalNegativas;      
          if(this.risk != undefined && this.risk != null && this.risk != ''){
            this.broker.getParametersRisk().subscribe({
              next: (dataRisk:any) => {
                    let riskService = dataFree.risk.split(" ")[1].toLowerCase();
                    this.riskUser = riskService;
                    this.risk=dataRisk[riskService];
                    this.risk['descOne'] = this.randomDescRisk();
              },
              error: (err) => {    
               },
            });
          }
          this.errorServiceCreditHistory = false;
          this.isLoadHC = false;
          this.debts.getDebtsPrintPadYa(this.allHistory);
        },
        error: (error: any) =>{
          if(repeat){
            setTimeout(() => {
              this.freepremium(false);
              }, 500);
          }else{
            this.errorServiceCreditHistory = true;
            this.isLoadHC = false;
          }
        }
      }
    );
  }

  randomDescRisk(){		
		let minimo = 1;
		let maximo = this.risk.desc.length;
		let numRandom = Math.floor(Math.random() * (maximo - minimo + minimo)) + minimo;
		return this.risk.desc[numRandom-1];
	}


  // TIPOS Y SUBTIPOS RECLAMOS
  tiposReclamosListado() {
    if (environment.reclamos) {
      this.broker.TiposReclamaciones().subscribe((data: any) => {
        this.tiposReclamos = data;
      },
        (error) => {
          // this.router.navigate(['/error']);
        }
      );
    }
  }
  // CODIGOS Y ESTADOS DE RECLAMO
  getStateReclamo(codigo: string) {
    const states = [
      { codigo: '', texto: '' },
      { codigo: '1', texto: 'Colocado' },
      { codigo: '2', texto: 'Modificado' },
      { codigo: '3', texto: 'Aplicado' },
      { codigo: '4', texto: 'Devuelto' },
    ];

    const found = states.find(data => data.codigo === codigo);

    if (found) {
      return found.texto;
    } else {
      return 'N/A';
    }

  }

  // Tipo de cuenta
  getTipoCuenta(accountE: string) {
    for (const info of this.resumenhc) {
      const found = info.detalleCuenta.find((data: any) => data.accountE === accountE);
      if (found) {
        return found.tipoCuenta;
      } else {
        return 'N/A';
      }
    }

    for (const info of this.ctascerradas) {
      const found = info.detalleCuenta.find((data: any) => data.accountE === accountE);
      if (found) {
        return found.tipoCuenta;
      } else {
        return 'N/A';
      }
    }
  }

  getReclamo(cuenta: DetalleCuenta) {
    if (this.estadoUserF == 'ACTIVO' || this.estadoUserF == 'ACTIVE_PROCESS') {
      this.router.navigate(['/private/reclamaciones']);
    } else {
      this.router.navigate(['/freemium/reclamaciones']);
    }
  }


  // CODIGOS Y ESTADOS DE CUENTA TARJETAS DE CREDITO Y CTAS CARTERA
  getStateText(codigo: string, codigoEstadoCuenta: any) {
    const states = [
      { codigo: '', texto: '' },
      { codigo: '01', texto: 'AL DIA' },
      { codigo: '02', texto: 'T. NO ENTREGADA' },
      { codigo: '03', texto: 'CANCELADA MM' },
      { codigo: '04', texto: 'T. ROBADA' },
      { codigo: '05', texto: 'CANCELADA' },
      { codigo: '06', texto: 'CANCELADO' },
      { codigo: '07', texto: 'T. EXTRAVIADA' },
      { codigo: '08', texto: 'PAGO VOL' },
      { codigo: '09', texto: 'PAGO VOL MX 30' },
      { codigo: '10', texto: 'PAGO VOL MX 60' },
      { codigo: '11', texto: 'PAGO VOL MX 90' },
      { codigo: '12', texto: 'PAGO VOL MX 120' },
      { codigo: '13', texto: 'AL DIA MORA 30' },
      { codigo: '14', texto: 'AL DIA MORA 60' },
      { codigo: '15', texto: 'AL DIA MORA 90' },
      { codigo: '16', texto: 'AL DIA MORA 120' },
      { codigo: '17', texto: 'ESTA EN MORA 30' },
      { codigo: '18', texto: 'ESTA EN MORA 60' },
      { codigo: '19', texto: 'ESTA EN MORA 90' },
      { codigo: '20', texto: 'ESTA EN MORA 120' },
      { codigo: '21', texto: 'ESTA M 30 FM 60' },
      { codigo: '22', texto: 'ESTA M 30 FM 90' },
      { codigo: '23', texto: 'ESTA M 60 FM 90' },
      { codigo: '24', texto: 'ESTA M 30 FM 120' },
      { codigo: '25', texto: 'ESTA M 60 FM 120' },
      { codigo: '26', texto: 'ESTA M 90 FM 120' },
      { codigo: '27', texto: 'ESTA M 60 RM 30' },
      { codigo: '28', texto: 'ESTA M 90 RM 30' },
      { codigo: '29', texto: 'ESTA M 120 RM 30' },
      { codigo: '30', texto: 'ESTA M 30 RM 60' },
      { codigo: '31', texto: 'ESTA M 60 RM 60' },
      { codigo: '32', texto: 'ESTA M 90 RM 60' },
      { codigo: '33', texto: 'ESTA M 120 RM 60' },
      { codigo: '34', texto: 'ESTA M 30 RM 90' },
      { codigo: '35', texto: 'ESTA M 60 RM 90' },
      { codigo: '36', texto: 'ESTA M 90 RM 90' },
      { codigo: '37', texto: 'ESTA M 120 RM 90' },
      { codigo: '38', texto: 'ESTA M  30 RM 120' },
      { codigo: '39', texto: 'ESTA M  60 RM 120' },
      { codigo: '40', texto: 'ESTA M 90 RM 120' },
      { codigo: '41', texto: 'ESTA M 120 RM 120' },
      { codigo: '45', texto: 'CART. CASTIGADA' },
      { codigo: '46', texto: 'CARTERA RECUPERADA' },
      { codigo: '47', texto: 'DUDOSO RECAUDO' },
      { codigo: '49', texto: 'TARJETA NO RENOVADA' },
      { codigo: '60', texto: 'EN RECLAMACIÓN' },
    ];

    const codEstCue = [
      { codigoEstadoCuenta: '10', texto: 'PRESCRIPCIÓN' },
      { codigoEstadoCuenta: '14', texto: 'INSOLUTA' },
      { codigoEstadoCuenta: '16', texto: 'CANCELADA POR LIQUIDACIÓN PATRIMONIAL' },
    ];

    var found;

    if (codigoEstadoCuenta == "10" || codigoEstadoCuenta == "14" || codigoEstadoCuenta == "16") {
      found = codEstCue.find(data => data.codigoEstadoCuenta === codigoEstadoCuenta);
    } else {
      found = states.find(data => data.codigo === codigo);
    }

    if (found) {
      return found.texto;
    } else {
      return 'N/A';
    }

  }

  // CODIGOS Y ESTADOS DE CUENTA TARJETAS DE CREDITO Y CTAS CARTERA
  getStateDebt(codigo: string) {
    const states = [
      { codigo: '', texto: '', mora: false },
      { codigo: '01', texto: 'AL DIA', mora: false },
      { codigo: '02', texto: 'T. NO ENTREGADA', mora: false },
      { codigo: '03', texto: 'CANCELADA MM', mora: false },
      { codigo: '04', texto: 'T. ROBADA', mora: false },
      { codigo: '05', texto: 'CANCELADA', mora: false },
      { codigo: '06', texto: 'CANCELADA MX', mora: false },
      { codigo: '07', texto: 'T. EXTRAVIADA', mora: false },
      { codigo: '08', texto: 'PAGO VOL', mora: false },
      { codigo: '09', texto: 'PAGO VOL MX 30', mora: false },
      { codigo: '10', texto: 'PAGO VOL MX 60', mora: false },
      { codigo: '11', texto: 'PAGO VOL MX 90', mora: false },
      { codigo: '12', texto: 'PAGO VOL MX 120', mora: false },
      { codigo: '13', texto: 'AL DIA MORA 30', mora: true },
      { codigo: '14', texto: 'AL DIA MORA 60', mora: true },
      { codigo: '15', texto: 'AL DIA MORA 90', mora: true },
      { codigo: '16', texto: 'AL DIA MORA 120', mora: true },
      { codigo: '17', texto: 'ESTA EN MORA 30', mora: true },
      { codigo: '18', texto: 'ESTA EN MORA 60', mora: true },
      { codigo: '19', texto: 'ESTA EN MORA 90', mora: true },
      { codigo: '20', texto: 'ESTA EN MORA 120', mora: true },
      { codigo: '21', texto: 'ESTA M 30 FM 60', mora: true },
      { codigo: '22', texto: 'ESTA M 30 FM 90', mora: true },
      { codigo: '23', texto: 'ESTA M 60 FM 90', mora: true },
      { codigo: '24', texto: 'ESTA M 30 FM 120', mora: true },
      { codigo: '25', texto: 'ESTA M 60 FM 120', mora: true },
      { codigo: '26', texto: 'ESTA M 90 FM 120', mora: true },
      { codigo: '27', texto: 'ESTA M 60 RM 30', mora: true },
      { codigo: '28', texto: 'ESTA M 90 RM 30', mora: true },
      { codigo: '29', texto: 'ESTA M 120 RM 30', mora: true },
      { codigo: '30', texto: 'ESTA M 30 RM 60', mora: true },
      { codigo: '31', texto: 'ESTA M 60 RM 60', mora: true },
      { codigo: '32', texto: 'ESTA M 90 RM 60', mora: true },
      { codigo: '33', texto: 'ESTA M 120 RM 60', mora: true },
      { codigo: '34', texto: 'ESTA M 30 RM 90', mora: true },
      { codigo: '35', texto: 'ESTA M 60 RM 90', mora: true },
      { codigo: '36', texto: 'ESTA M 90 RM 90', mora: true },
      { codigo: '37', texto: 'ESTA M 120 RM 90', mora: true },
      { codigo: '38', texto: 'ESTA M  30 RM 120', mora: true },
      { codigo: '39', texto: 'ESTA M  60 RM 120', mora: true },
      { codigo: '40', texto: 'ESTA M 90 RM 120', mora: true },
      { codigo: '41', texto: 'ESTA M 120 RM 120', mora: true },
      { codigo: '45', texto: 'CART. CASTIGADA', mora: true },
      { codigo: '46', texto: 'PAGO JURIDICO ó PAGO VOLUNTARIO', mora: false },
      { codigo: '47', texto: 'DUDOSO RECAUDO', mora: false },
      { codigo: '49', texto: 'TARJETA NO RENOVADA', mora: false },
    ];

    const found = states.find(data => data.codigo === codigo);

    if (found) {
      return found.mora;
    } else {
      return false;
    }

  }

  // CODIGOS Y ESTADOS DE CUENTA CTAS AHORRO Y CORRIENTE
  getStateTextCtas(codigo: string) {
    const states = [
      { codigo: '', texto: '' },
      { codigo: '01', texto: 'ACTIVA' },
      { codigo: '02', texto: 'CANCELADA MAL MANEJO' },
      { codigo: '05', texto: 'SALDADA' },
      { codigo: '06', texto: 'EMBARGADA' },
      { codigo: '07', texto: 'EMBARGADA ACTIVA' },
      { codigo: '09', texto: 'INACTIVA' },
    ];

    const found = states.find(data => data.codigo === codigo);

    if (found) {
      return found.texto;
    } else {
      return 'N/A';
    }
  }

  // CALIFICACION CTAS
  getCalificacion(calificacion: string) {
    switch (calificacion) {
      case '1':
        return 'A';
      case '2':
        return 'B';
      case '3':
        return 'C';
      case '4':
        return 'D';
      case '5':
        return 'E';
      case '6':
        return 'AA';
      case '7':
        return 'BB';
      case '8':
        return 'CC';
      case '9':
        return 'K';
      default:
        return 'N/A';
    }
  }

  /**********************************************************
  ************************** ESCANEO ***************************
  ***********************************************************/

  obtener_textosdkw() {
    if (this.isEmpty(this.textosDarkWeb)) {
      this.broker.obtener_textosdkw().subscribe((data: any) => {
        this.textosDarkWeb = data;
      },
        (error) => {
          // this.router.navigate(['/error']);
        }
      );
    }
  }

  // ANÓNIMO

  generic(email: string, protege: boolean = false) {
    this.broker.generic(email).subscribe((data: any) => {
      setTimeout(() => {
        this.loading.stopAnimateRangeNum = true;
        if (data.stlTransactionData.errorCode === 'OK') {
          if (protege) {
            this.anonimoInterno = data;
          } else {
            this.anonimo = data;
          }
          this.loading.close();
          dataLayer.push({ event: 'optimize.activate' });
          $('html, body').animate({
            scrollTop: $('#btnDarWeb').offset().top
          }, 1000);
        } else {
          this.loading.close();
          this.modal.open(
            'Estamos trabajando para prestarte un mejor servicio',
            // tslint:disable-next-line: max-line-length
            'Nuestra plataforma está siendo renovada para ti. Por favor intenta de nuevo en unos minutos.',
            'assets/img/images/Estamos_trabajando.svg',
            null,
            false,
            '',
            'Entendido',
            'ok'
          );
        }
      }, 4000);
    },
      (error) => {
        // this.router.navigate(['/error']);
        this.loading.close();
        this.modal.open(
          'Estamos trabajando para prestarte un mejor servicio',
          // tslint:disable-next-line: max-line-length
          'Nuestra plataforma está siendo renovada para ti. Por favor intenta de nuevo en unos minutos.',
          'assets/img/images/Estamos_trabajando.svg',
          null,
          false,
          '',
          'Entendido',
          'ok'
        );
      }
    );
  }

  // CREATE USER DARK WEB
  createDKW() {

    this.broker.createDKW(this.cookieValue).subscribe((data: any) => {
      this.loading.close();
    },
      (error) => {
        this.loading.close();
      }
    );
  }
  // GET USER DARK WEB
  getDKW() {
    this.broker.getDKW(this.cookieValue).subscribe((data: any) => {
      if (data.stlTransactionData.errorCode === 'OK') {
        if (!data.extTransactionData.identity.idEmailAddr) {
          data.extTransactionData.identity.idEmailAddr = [];
        }
        if (!data.extTransactionData.identity.idCellPhone) {
          data.extTransactionData.identity.idCellPhone = [];
        }
        if (!data.extTransactionData.identity.idHomePhone) {
          data.extTransactionData.identity.idHomePhone = [];
        }
        if (!data.extTransactionData.identity.idPassportNum) {
          data.extTransactionData.identity.idPassportNum = [];
        }
        if (!data.extTransactionData.identity.idEmailAddr) {
          data.extTransactionData.identity.idEmailAddr = [];
        }
        const model = this;
        setTimeout(function () {
          model.identity = data;
        }, 200);
        this.loading.close();
      } else {
        // this.modal.open(
        //   'No pudimos consultar tu información satisfactoriamente',
        //   // tslint:disable-next-line: max-line-length
        //   'Hay un error en la información proporcionada',
        //   'assets/img/images/aviso_bad.svg',
        //   null,
        //   false,
        //   '',
        //   'Intentar de nuevo',
        //   'bad'
        // );
        // this.router.navigate(['/private/antifraude']);
      }
    },
      (error) => {
        // this.router.navigate(['/error']);
      }
    );
  }

  countDKW() {
    this.broker.countDKW(this.cookieValue).subscribe((data: any) => {
      this.countdk = data;
    },
      (error) => {
        // this.router.navigate(['/error']);
      }
    );
  }

  listDKW(pagina: number = 1, items: number = 10) {
    this.broker.listDKW(this.cookieValue, pagina, items).subscribe((data: any) => {
      if (data) {
        if (data.stlTransactionData.errorCode === 'OK') {
          this.listdk = data;
        }
      }
      this.actualizandoListdk = false;
    },
      (error) => {
        // this.router.navigate(['/error']);
      }
    );
  }

  readDKW(idAlert: string, index: any) {
    this.broker.readDKW(idAlert).subscribe((data: any) => {
      if (data.stlTransactionData.errorCode === 'OK') {
        this.listdk.extTransactionData.alertNotification[index].channelState = '1';
      }
    },
      (error) => {
        // this.router.navigate(['/error']);
      }
    );
  }

  loadingDKW() {
    this.loadingOpen();
  }

  addMailDKW(email: any) {
    this.loadingDKW();
    this.accionesDkw.closePopUpMail();

    const content: IdentityAddData = {
      session: this.cookieValue,
      serviceCode: '536',
      extTransactionData: {
        identity: {
          idEmailAddr: [
            email
          ]
        }
      }
    };

    this.broker.addDKW(content).subscribe((data: any) => {
      this.getDKW();
      this.listDKW();
    },
      (error) => {
        this.loading.close();
        this.modal.open(
          'No pudimos almacenar tu información satisfactoriamente',
          // tslint:disable-next-line: max-line-length
          'Hay un error en la información proporcionada',
          'assets/img/images/aviso_bad.svg',
          null,
          false,
          '',
          'Intentar de nuevo',
          'bad'
        );
      }
    );
  }

  addPassDKW(pass: any) {
    this.loadingDKW();
    this.accionesDkw.closePopUpPass();

    const content: IdentityAddData = {
      session: this.cookieValue,
      serviceCode: '536',
      extTransactionData: {
        identity: {
          idPassportNum: [
            pass
          ]
        }
      }
    };

    this.broker.addDKW(content).subscribe((data: any) => {
      this.getDKW();
      this.listDKW();
      this.loading.close();
    },
      (error) => {
        this.loading.close();
        this.modal.open(
          'No pudimos almacenar tu información satisfactoriamente',
          // tslint:disable-next-line: max-line-length
          'Hay un error en la información proporcionada',
          'assets/img/images/aviso_bad.svg',
          null,
          false,
          '',
          'Intentar de nuevo',
          'bad'
        );
      }
    );
  }

  addPhoneDKW(phone: any) {
    this.loadingDKW();
    this.listDKW();
    this.accionesDkw.closePopUpTel();

    const content: IdentityAddData = {
      session: this.cookieValue,
      serviceCode: '536',
      extTransactionData: {
        identity: {
          idHomePhone: [
            phone
          ]
        }
      }
    };

    this.broker.addDKW(content).subscribe((data: any) => {
      this.getDKW();
      this.listDKW();
      this.loading.close();
    },
      (error) => {
        this.loading.close();
        this.modal.open(
          'No pudimos almacenar tu información satisfactoriamente',
          // tslint:disable-next-line: max-line-length
          'Hay un error en la información proporcionada',
          'assets/img/images/aviso_bad.svg',
          null,
          false,
          '',
          'Intentar de nuevo',
          'bad'
        );
      }
    );
  }

  addCellDKW(cell: any) {
    this.loadingDKW();
    this.accionesDkw.closePopUpCell();

    const content: IdentityAddData = {
      session: this.cookieValue,
      serviceCode: '536',
      extTransactionData: {
        identity: {
          idCellPhone: [
            cell
          ]
        }
      }
    };

    this.broker.addDKW(content).subscribe((data: any) => {
      this.getDKW();
      this.listDKW();
      this.loading.close();
    },
      (error) => {
        this.loading.close();
        this.modal.open(
          'No pudimos almacenar tu información satisfactoriamente',
          // tslint:disable-next-line: max-line-length
          'Hay un error en la información proporcionada',
          'assets/img/images/aviso_bad.svg',
          null,
          false,
          '',
          'Intentar de nuevo',
          'bad'
        );
      }
    );
  }

  editUserDKW() {
    this.loadingDKW();

    const content: IdentityAddData = {
      session: this.cookieValue,
      serviceCode: '501',
      extTransactionData: this.identity.extTransactionData
    };

    this.broker.editDKW(content).subscribe((data: any) => {
      this.getDKW();
      this.listDKW();
      this.loading.close();
    },
      (error) => {
        this.getDKW();
        this.loading.close();
        this.modal.open(
          'No pudimos almacenar tu información satisfactoriamente',
          // tslint:disable-next-line: max-line-length
          'Hay un error en la información proporcionada',
          'assets/img/images/aviso_bad.svg',
          null,
          false,
          '',
          'Intentar de nuevo',
          'bad'
        );
      }
    );
  }

  /**********************************************************
  ******************** PROXIMO CREDITO ***********************
  ***********************************************************/

  probabilidadClase(probabilidad: string) {
    switch (true) {
      case (probabilidad === '0'):
        return 'probabilidad-i';
      case (probabilidad >= '1' && probabilidad <= '19'):
        return 'probabilidad-1';
      case (probabilidad >= '20' && probabilidad <= '39'):
        return 'probabilidad-2';
      case (probabilidad >= '40' && probabilidad <= '59'):
        return 'probabilidad-3';
      case (probabilidad >= '60' && probabilidad <= '79'):
        return 'probabilidad-4';
      case (probabilidad >= '80' && probabilidad <= '99'):
        return 'probabilidad-5';
      case (probabilidad === '100'):
        return 'probabilidad-f';
    }
    return 'probabilidad-i';
  }

  simular(request: SolicitudCreditoRequest) {
    this.loadingOpen();
    this.broker.simularCredito(request).subscribe((data: any) => {
      this.loading.close();
      this.simulacionError = false;
      this.simulacionResponse = data;
    },
      (error) => {
        this.loading.close();
        this.simulacionResponse = null;
        this.simulacionError = true;
      }
    );
  }

  /**********************************************************
  **************** NOTIFICACIONES Y ALERTAS *****************
  ***********************************************************/

  consultarNotificaciones() {
    this.broker.consultarNotificaciones(this.cookieValue).subscribe((data: any) => {
      this.listadoNotificaciones = data;
      this.notifications = data.notifications;
    },
      (error) => {

      }
    );
  }
  bellCount() {
    this.broker.bell(this.cookieValue).subscribe((data: any) => {
      this.bellCountData = data;
    },
      (error) => {

      }
    );
  }
  //Antiguo
  notificaciones(readMark: string) {
    this.broker.notificaciones(this.cookieValue, readMark).subscribe((data: any) => {
      this.listNotificaciones = data;
    },
      (error) => {

      }
    );
  }

  /**********************************************************
  *********************** PQRS - CONTACTO *******************
  ***********************************************************/

  obtener_pqrs() {
    if (this.isEmpty(this.pqrs)) {
      this.broker.obtener_pqrs().subscribe((data: any) => {
        this.pqrs = data;
      },
        (error) => {
          // this.router.navigate(['/error']);
        }
      );
    }
  }

  enviarContacto() {
    this.loadingOpen();
    this.broker.enviarContacto(this.contacto).subscribe((data: any) => {
      this.loading.close();
      if (data.status === 'OK') {
        this.modal.open(
          '¡Tu mensaje ha sido enviado exitosamente!',
          // tslint:disable-next-line: max-line-length
          'Su solicitud número ' + data.ticketCode + ' ha sido enviada exitosamente',
          'assets/img/images/aviso_ok.svg',
          null,
          false,
          '',
          'Entendido',
          'ok'
        );

      } else {
        this.modal.open(
          'No pudimos enviar tu mensaje satisfactoriamente',
          // tslint:disable-next-line: max-line-length
          'Hay un error en la información proporcionada',
          'assets/img/images/aviso_bad.svg',
          null,
          false,
          '',
          'Intentar de nuevo',
          'bad'
        );
      }
      this.contacto.documentType = 'CC';
    },
      (error) => {
        this.loading.close();
        this.modal.open(
          'No pudimos enviar tu mensaje satisfactoriamente',
          // tslint:disable-next-line: max-line-length
          'Hay un error en la información proporcionada',
          'assets/img/images/aviso_bad.svg',
          null,
          false,
          '',
          'Intentar de nuevo',
          'bad'
        );
      }
    );
  }

  /**********************************************************
  **************** ACTIVAR / DESACTIVAR CELULAR ***********
  ***********************************************************/

  enviarCodigo(celular: string) {
    this.loadingOpen();

    this.estadoCelular = false;

    this.broker.codigo(celular, this.cookieValue).subscribe((data: any) => {
      this.loading.close();
      if (data.status === 'OK') {
        this.estadoCodigo = true;
        this.modal.open(
          '¡Tu código ha sido enviado exitosamente!',
          // tslint:disable-next-line: max-line-length
          'Verifica tu dispositivo',
          'assets/img/images/aviso_ok.svg',
          null,
          false,
          '',
          'Entendido',
          'ok'
        );
      } else {
        this.estadoCodigo = false;
        this.modal.open(
          'No pudimos enviar tu código satisfactoriamente',
          // tslint:disable-next-line: max-line-length
          'Hay un error en la información proporcionada',
          'assets/img/images/aviso_bad.svg',
          null,
          false,
          '',
          'Intentar de nuevo',
          'bad'
        );
      }
    },
      (error) => {
        this.loading.close();
        this.estadoCodigo = false;
        this.modal.open(
          'No pudimos enviar tu código satisfactoriamente',
          // tslint:disable-next-line: max-line-length
          'Hay un error en la información proporcionada',
          'assets/img/images/aviso_bad.svg',
          null,
          false,
          '',
          'Intentar de nuevo',
          'bad'
        );
      }
    );
  }

  validarCodigo(celular: any, codigo: string) {

    if (celular === this.user.phone) { celular = null; }

    this.broker.validar(celular, codigo, this.cookieValue).subscribe((data: any) => {
      if (data.status === 'OK') {
        this.estadoCelular = true;
      } else {
        this.estadoCelular = false;
        this.modal.open(
          'Código inválido',
          // tslint:disable-next-line: max-line-length
          'Hay un error en la información proporcionada',
          'assets/img/images/aviso_bad.svg',
          null,
          false,
          '',
          'Intentar de nuevo',
          'bad'
        );
      }
    },
      (error) => {
        this.estadoCelular = false;
        this.modal.open(
          'No pudimos validar tu código satisfactoriamente',
          // tslint:disable-next-line: max-line-length
          'Hay un error en la información proporcionada',
          'assets/img/images/aviso_bad.svg',
          null,
          false,
          '',
          'Intentar de nuevo',
          'bad'
        );
      }
    );
  }
  // DESACTIVAR CELULAR
  desactivarCelular() {
    this.loadingOpen();

    this.broker.desactivar(this.cookieValue).subscribe((data: any) => {
      this.loading.close();
      if (data.status === 'OK') {
        this.modal.open(
          '¡Tu celular ha sido desactivado exitosamente!',
          // tslint:disable-next-line: max-line-length
          'Recuerda que al desactivar tu celular no podemos informarte en tiempo real los cambios que se presenten en tu información personal y financiera.',
          'assets/img/images/aviso_ok.svg',
          null,
          false,
          '',
          'Entendido',
          'ok'
        );
        this.estadoCelular = false;
      } else {
        this.modal.open(
          'No pudimos Desactivar tu celular satisfactoriamente',
          // tslint:disable-next-line: max-line-length
          'Hay un error en la información proporcionada',
          'assets/img/images/aviso_bad.svg',
          null,
          false,
          '',
          'Intentar de nuevo',
          'bad'
        );
        this.estadoCelular = true;
      }
    },
      (error) => {
        this.loading.close();
        this.modal.open(
          'No pudimos Desactivar tu celular satisfactoriamente',
          // tslint:disable-next-line: max-line-length
          'Hay un error en la información proporcionada',
          'assets/img/images/aviso_bad.svg',
          null,
          false,
          '',
          'Intentar de nuevo',
          'bad'
        );
        this.estadoCelular = true;
      }
    );
  }

  /**********************************************************
  ****************** NOTIFICACIONES ACTIVAS *******************
  ***********************************************************/
  public alertMongo = false;
  public errorAlertMongo = false;
  public notificationIni: Notificacion[] = [];

  getAlertsMongoWS() {
    this.alertMongo = false;
    this.errorAlertMongo = false;
    this.broker.getAlertsMongoWS(this.cookieValue).subscribe((data: any) => {
      if (data.length > 0) {
        for (const alert of data) {
          const indexAlert = this.notificacionesActivas.findIndex((obj: any) => obj.notificationId === alert.notificationId);
          this.notificacionesActivas[indexAlert].checked = true;
        }
      }
      this.notificationIni = [this.notificacionesActivas.filter((obj: any) => obj.checked === true)];
      this.alertMongo = true;
      setTimeout(() => {
        $('.content-sub-changes').hide();
      }, 100);
    },
      (error) => {
        this.alertMongo = true;
        this.errorAlertMongo = true;
      });
  }

  saveAlertsMongoWS() {
    this.loading.open();
    const notificacionesFiltradas: any = [];
    const notificaciones = this.notificacionesActivas.filter((obj: any) => obj.checked === true);
    for (const alert of notificaciones) {
      notificacionesFiltradas.push({ notificationId: alert.notificationId, name: alert.name, description: alert.description });
    }

    this.broker.saveAlertsMongoWS(this.cookieValue, notificacionesFiltradas).subscribe((data: any) => {
      this.loading.close();
      this.modal.open(
        '¡Cambios guardados correctamente!',
        '',
        'assets/img/images/aviso_ok.svg',
        null,
        false,
        '',
        'Entendido',
        'ok'
      );
    },
    (error) => {

    });
  }

  /**********************************************************
  ****************** LOAD EXTERNAL SCRIPT *******************
  ***********************************************************/

  loadScript(url: string): Observable<any> {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }

    this._loadedLibraries[url] = new ReplaySubject();

    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = url;
    script.onload = () => {
      this._loadedLibraries[url].next(0);
      this._loadedLibraries[url].complete();
    };

    this.document.body.appendChild(script);

    return this._loadedLibraries[url].asObservable();
  }

  loadStyle(url: string): Observable<any> {
    if (this._loadedLibraries[url]) {
      return this._loadedLibraries[url].asObservable();
    }

    this._loadedLibraries[url] = new ReplaySubject();

    const style = this.document.createElement('link');
    style.type = 'text/css';
    style.href = url;
    style.rel = 'stylesheet';
    style.onload = () => {
      this._loadedLibraries[url].next(0);
      this._loadedLibraries[url].complete();
    };

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(style);

    return this._loadedLibraries[url].asObservable();
  }

  /**********************************************************
  ************************** PAGOS ***************************
  ***********************************************************/

  infoPagos() {
    let lsPlanSleeccionado = localStorage.getItem('planSeleccionado');
    if (lsPlanSleeccionado != null) {
      this.plan = JSON.parse(lsPlanSleeccionado);
    }
    this.planId = localStorage.getItem('planId');
    this.tipoPlan = localStorage.getItem('tipoPlan');
    this.valorPlanMensual = localStorage.getItem('valorPlanMensual');
    this.valorPlanTotal = localStorage.getItem('valorPlanTotal');
    this.valorPlanTotalDescuento = localStorage.getItem('valorPlanTotal');
    if (this.dataUser) {
      if (this.dataUser.params[1].value !== 'INPROCESS') {
        this.valorPlanTotalDescuento = localStorage.getItem('valorPlanTotal');
      } else {
        this.valorPlanTotalDescuento = localStorage.getItem('valorPlanTotalDescuento');
      }
    }
  }

  obtener_paises() {
    if (this.isEmpty(this.paises)) {
      this.broker.obtener_paises().subscribe((data: any) => {
        this.paises = data;
      },
        (error) => {
          // this.router.navigate(['/error']);
        }
      );
    }
  }

  obtener_ciudades() {
    if (this.isEmpty(this.ciudades)) {
      this.broker.obtener_ciudades().subscribe((data: any) => {
        this.ciudades = data;
        const model = this;
        this.ciudades.sort(function (a: any, b: any) {
          return model.compareStrings(a.NAME_MUN, b.NAME_MUN);
        });
      },
        (error) => {
          // this.router.navigate(['/error']);
        }
      );
    }
  }

  compareStrings(a: any, b: any) {
    // Assuming you want case-insensitive comparison
    a = a.toLowerCase();
    b = b.toLowerCase();
    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }

  listadoBancos() {
    if (this.isEmpty(this.bancos)) {
      this.broker.listadoBancos().subscribe((data: any) => {
        this.bancos = data;
      },
        (error) => {
          // this.router.navigate(['/error']);
        }
      );
    }
  }

  // validarDescuento() {
  //   this.descuento = null;
  //   if (this.descuentoData.params.promoCode) { this.descuentoData.params.promoCode = this.descuentoData.params.promoCode.toUpperCase(); }
  //   this.broker.pagos(this.descuentoData).subscribe((data: any) => {
  //     //if (data.valueToPayString && data.valueToPayString !== '0') {
  //     if (data.valueToPayString) {
  //       this.descuento = 'OK';
  //       this.valorPlanTotalDescuento = data.valueToPayString;
  //       this.spinnerLoader = false;
  //     } else {
  //       this.descuento = 'BAD';
  //       this.valorPlanTotalDescuento = localStorage.getItem('valorPlanTotal');
  //       this.spinnerLoader = false;
  //     }
  //   },
  //     (error) => {
  //       this.descuento = 'BAD';
  //       this.valorPlanTotalDescuento = localStorage.getItem('valorPlanTotal');
  //       this.spinnerLoader = false;
  //     });
  // }

  pagos(dataPago: any, tipo: number) {
    localStorage.setItem('tipoPago', tipo.toString());

    // this.loadingOpen(
    //   'Por favor espera un momento',
    //   'Estamos validando la información para activar tu plan',
    //   'assets/img/animations/loading_clock.json',
    //   'animation',
    //   true
    // );

    this.broker.pagos(dataPago).subscribe((data: any) => {
      this.descuento = null;
      switch (tipo) {
        case 1:
          this.confirmacion = data;

          if (this.confirmacion.code === 'SUCCESS') {
            // Validar estados PayU
            if (this.confirmacion.transactionResponse.state === 'APPROVED') {
              this.estadoTransaccion = true;
            } else {
              this.estadoTransaccion = false;
            }

            this.router.navigate(['/confirmacion-pago']);
            this.loading.close();
          } else {
            this.loading.close();
            this.router.navigate(['/confirmacion-pago']);
            // this.modal.open(
            //   'No pudimos activar tu plan satisfactoriamente CASO 1',
            //   // tslint:disable-next-line: max-line-length
            //   'Hay un error en la información proporcionada',
            //   'assets/img/images/aviso_bad.svg',
            //   null,
            //   false,
            //   '',
            //   'Intentar de nuevo',
            //   'bad'
            // );
          }
          this.spinnerDebt = false;
          break;
        case 2:
          this.confirmacion = data;
          // Validar estados pago
          if (this.confirmacion.code === 'OK') {
            this.estadoTransaccion = true;
          } else {
            this.estadoTransaccion = false;
          }
          this.router.navigate(['/confirmacion-pago']);
          this.loading.close();
          this.spinnerDebt = false;
          break;
        case 3:
          if (data.transactionResponse.state === 'PENDING') {
            window.location.replace(data.transactionResponse.extraParameters.BANK_URL);
          } else {
            this.modal.open(
              'No pudimos activar tu plan satisfactoriamente',
              // tslint:disable-next-line: max-line-length
              'Hay un error en la información proporcionada [PAYMENT-000]',
              'assets/img/images/aviso_bad.svg',
              null,
              false,
              '',
              'Intentar de nuevo',
              'bad'
            );
            this.loading.close();
            this.spinnerDebt = false;
            this.btnValidar = true;
            $("#validar").show();
            this.datalayer_payment_error();
          }
          break;
      }
    },
      (error) => {
        switch (tipo) {
          case 1:
            //this.loading.close();
            this.modal.open(
              'No pudimos activar tu plan satisfactoriamente',
              // tslint:disable-next-line: max-line-length
              'Hay un error en la información proporcionada [PAYMENT-001]',
              'assets/img/images/aviso_bad.svg',
              null,
              false,
              '',
              'Intentar de nuevo',
              'bad'
            );
            this.spinnerDebt = false;
            $("#validar").show();
            this.datalayer_payment_error();
            break;
          case 2:
            this.modal.open(
              'No pudimos activar tu plan satisfactoriamente',
              // tslint:disable-next-line: max-line-length
              'Hay un error en la información proporcionada [PAYMENT-002]',
              'assets/img/images/aviso_bad.svg',
              null,
              false,
              '',
              'Intentar de nuevo',
              'bad'
            );
            this.spinnerDebt = false;
            $("#validar").show();
            this.datalayer_payment_error();
            //this.loading.close();
            break;
          case 3:
            //this.loading.close();            
            this.modal.open(
              'No pudimos activar tu plan satisfactoriamente',
              // tslint:disable-next-line: max-line-length
              'Hay un error en la información proporcionada [PAYMENT-003]',
              'assets/img/images/aviso_bad.svg',
              null,
              false,
              '',
              'Intentar de nuevo',
              'bad'
            );
            this.spinnerDebt = false;
            this.btnValidar = true;
            $("#validar").show();
            this.datalayer_payment_error();
            break;
        }
      }
    );
  }

  /**********************************************************
  ************** ALERTAS AL SISTEMA FINANCIERO ***************
  ***********************************************************/
  public loadAlerts = false;
  public errorAlerts = false;
  listarAlertas() {
    this.messCheck = false;
    this.messError = false;
    this.errorAlerts = false;
    this.loadAlerts = true;
    if (!this.alertasTipos) {
      this.loadAlerts = false;
      this.broker.listarAlertas().subscribe((data: any) => {
        this.alertasTipos = data.registers;
        this.loadAlerts = true;
        this.errorAlerts = false;
      },
        (error) => {
          this.loadAlerts = true;
          this.caseError = true;
          this.errorAlerts = true;
          switch (error.status) {
            case 500:

              break;
            case 403:

              break;
            default:
              break;
          }
        });
    }
  }
  crearAlerta() {
    this.loadAlerts = false;
    this.errorAlerts = false;
    this.broker.crearAlerta(this.cookieValue, this.alertCode, this.alertText).subscribe((data: any) => {
      this.loadAlerts = true;
      this.errorAlerts = false;
      if (data.Error) {
        this.responseAlertWasCreated = false;
      } else {
        this.spinnerDebt = false;
        this.responseAlertWasCreated = true;
        
          this.messCheck = true;
          this.messBg = 'check';
          this.messTitle = this.messageAlerts[1].alerts[0].text;
          this.messDescription =  this.messageAlerts[1].alerts[0].description;
          this.messIcon = 'fa-smile-o';
          this.messColorTxt = 'check-txt'
        
        this.consultarAlerta();
        if (this.estadoUserF == 'ACTIVO' || this.estadoUserF == 'ACTIVE_PROCESS') {
          this.router.navigate(['/private/historico']);
        } else {
          this.router.navigate(['/freemium/historico']);
        }
      }
    },
      (error) => {
        this.loadAlerts = true;
        this.errorAlerts = true;
        this.spinnerDebt = false;
        
          this.messCheck = true;
          this.messBg = 'error';
          this.messTitle = this.messageAlerts[1].alerts[1].text;
          this.messDescription =  this.messageAlerts[1].alerts[1].description;
          this.messIcon = 'fa-meh-o';
          this.messColorTxt = 'error-txt'
        
      });
  }
  consultarAlerta() {

    this.broker.consultarAlerta(this.cookieValue).subscribe((data: any) => {
      this.listadoAlertas = data.registers;
    },
      (error) => {
      }
    );
  }

  /**********************************************************
  ********************** FACTURACION ***********************
  ***********************************************************/
  consultarFacturas() {
    if (!this.misfacturas) {
      this.broker.consultarFacturas(this.cookieValue).subscribe((data: any) => {
        if (data.status === 'OK') {
          this.misfacturas = data;
        }
      },
        (error) => {
        }
      );
    }
  }

  descargarFactura(invoiceNumber: string) {
    this.loadingOpen();
    this.broker.descargarFactura(this.cookieValue, invoiceNumber).subscribe((data: any) => {
      if (data.status === 'OK') {
        this.factura = data;
        this.loading.close();
        this.showPdf(invoiceNumber);
      } else {
        this.loading.close();
        this.modal.open(
          'No pudimos descargar tu factura satisfactoriamente',
          // tslint:disable-next-line: max-line-length
          'Hay un error en la información proporcionada',
          'assets/img/images/aviso_bad.svg',
          null,
          false,
          '',
          'Intentar de nuevo',
          'bad'
        );
      }
    },
      (error) => {
        this.factura = null;
        this.loading.close();
        this.modal.open(
          'No pudimos descargar tu factura satisfactoriamente',
          // tslint:disable-next-line: max-line-length
          'Hay un error en la información proporcionada',
          'assets/img/images/aviso_bad.svg',
          null,
          false,
          '',
          'Intentar de nuevo',
          'bad'
        );
      }
    );
  }

  showPdf(invoiceNumber: string) {
    const linkSource = 'data:application/pdf;base64,' + this.factura.returnInfo;
    const downloadLink = document.createElement('a');
    const fileName = invoiceNumber + '.pdf';

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  saveTerms() {
    const data2 = {
      session: this.cookieValue,
      termCondition: 5
    };

    this.broker.guardarTerminos(data2).subscribe((resultado: any) => {
    }, error => {
    })
  }

  saveAcceptAdvertising() {
    let newAuths:setAuthorization[]=[];
    this.authorizations.forEach((auth: any) => {
      let auths = {
        authorizationType:auth.authorizationType,
        status:auth.status
      }
      newAuths.push(auths);
    });
    const data = {
      sessionId: this.cookieValue,
      termsConditions: newAuths
    };
    this.broker.setAutorizations(data).subscribe(
      {
        next: (data: any) => {
        },
        error: (error) => {
        }
      }
      
    );
  }

  dontAccept() {
    this.closeSession();
    this.router.navigate(['/']);
  }

  modulesUser(plan: string) {
    if (this.estadoUserF == "ACTIVO" || this.estadoUserF == 'ACTIVE_PROCESS') {
      // PER ANT PRE
      switch (plan) {
        case 'PER1':
        case 'PER2':
        case 'PER3':
        case 'PER4':
        case 'PER5':
        case 'PER6':
          this.monitoreo = true;
          this.proteccion = false;
          this.poliza = false;
          break;
        case 'ANT1':
        case 'ANT2':
        case 'ANT3':
        case 'ANT4':
        case 'ANT5':
          this.monitoreo = true;
          this.proteccion = true;
          this.poliza = false;

          // if(this.estadoDark != 1){
          //   this.createDKW();
          // }

          // setTimeout( () => {
          //   this.countDKW();
          //   this.getDKW();
          //   this.listDKW();
          // }, 1500);

          break;
        case 'PRO1':
        case 'PRO2':
        case 'PRO3':
        case 'PRO4':
        case 'PRO5':
          this.monitoreo = true;
          this.proteccion = true;
          this.poliza = true;

          // if(this.estadoDark != 1){
          //   this.createDKW();
          // }

          // setTimeout( () => {
          //   this.countDKW();
          //   this.getDKW();
          //   this.listDKW();
          // }, 1500);

          break;
      }
    }

  }

  updatePayment() {
    this.broker.checkPendingPayments(this.user.documento).subscribe((resp: any) => {
      // this.datosUsuario( false, true );
    }, (error) => { });
  }

  modalPending() {
    if (
      this.router.url !== '/private/planes' &&
      this.router.url !== '/pago' &&
      this.router.url !== '/confirmacion-pago' &&
      this.router.url !== '/confirmacion-pago/rechazada' &&
      this.router.url !== '/confirmacion-pago/aprobada'
    ) {
      if (this.valorPlanTotalDescuento) {
        this.modal.open(
          'Tu pago del plan ' + this.plan.popup + ' no fue exitoso',
          // tslint:disable-next-line: max-line-length
          '<p class="text-left mdc-text-4-1" style="max-width: 400px">Te invitamos a actualizar la información, en el módulo de pago, obteniendo un 20% de descuento en tu plan actual.<p> <ul class="text-left list-group list-group-flush mdc-block-transparent"><li class="list-group-item p-0 border-0"><b class="mdc-text-secondary">Valor</b><br><span class="through">$ ' + this.valorPlanTotal + '</span><br>$ ' + this.valorPlanTotalDescuento + '</li><li class="list-group-item p-0 border-0"><b class="mdc-text-secondary">Estado</b><br>Vencido</li></ul>',
          'assets/img/images/Inactivo2.svg',
          null,
          true,
          '/pago',
          'Renovar plan $ ' + this.valorPlanTotalDescuento,
          'ok',
          null,
          null,
          true
        );
      }
    }
  }

  planIdCart() {
    switch (this.planId) {
      case 'PER1':
        return 'plan_perfil_mensual';
      case 'ANT1':
        return 'plan_proteje_mensual';
      case 'PRO1':
        return 'plan_premium_mensual';
      case 'PER2':
        return 'plan_perfil_trimestral';
      case 'ANT2':
        return 'plan_proteje_trimestral';
      case 'PRO2':
        return 'plan_premium_trimestral';
      case 'PER5':
        return 'plan_perfil_anual';
      case 'ANT4':
        return 'plan_proteje_anual';
      case 'PRO4':
        return 'plan_premium_anual';
      default:
        return '';
    }
  }


  setMainInfo(data: any) {
    return new Observable<boolean>(obs => {
    this.dataUser = data;
    this.estadoCelular = true;
    this.estadoUserF = this.dataUser.params[1].value;
    (this.estadoUserF == "INACTIVO" || this.estadoUserF == "PENDING" || this.estadoUserF == "PENDING_PSE")?this.viewFree=true:this.viewFree=false;
    this.estadoDark = this.dataUser.params[6].value;
    this.terminos = this.dataUser.params[8].value;
    this.planCurrently = this.dataUser.params[0].value;
    //Upselling
    this.upsellDay = this.dataUser.params[7].value;
    this.expiratonDays = this.dataUser.params[4].value * -1;
    //this.planExpiration = this.today - this.expiratonDays;
    this.activeUser = this.dataUser.params[1].value;
    this.dateToday = new Date();
    //this.daysMore = this.dataUser[0].params[4].value;
    //this.daysMore = -5*-1;
    this.dateToday.setDate(this.dateToday.getDate() + this.expiratonDays);
    this.modulesUser(this.planCurrently);
    this.getAuthorizations();
    obs.next(true);
    });
  }

  getAuthorizations(){		
    this.broker.getAthorizations(this.cookieValue).subscribe(
      {
        next: (data: any) => {
        this.authorizations = data;
        let commercial;
        commercial = this.authorizations.find((data: any) => data.authorizationType === "commercial")
        if(commercial != undefined && commercial.status == false){
          this.advertising = true
        }else{
          this.advertising = false;
        }
        },
        error: () => {
        }
      }
      
    );
} 

  setVariables(password:string,id:string) {

    const CryptoJS = require("crypto-js");
    const ciphertext = CryptoJS.AES.encrypt(password, id);
    return ciphertext.toString();
    
  }

  // Función para descifrar la contraseña
  getVariables(data:string, id:string) {

    const variable = require("crypto-js");
    const bytes = variable.AES.decrypt(data, id);

    return bytes.toString(variable.enc.Utf8);
  }

  loadInitialParameters(){
    if(this.validateOTPU==""){
      this.broker.loadParameters('INIT').subscribe(
      {
        next: (data: any) => {
          this.validateOTPU = data.parameters.validateOTPU;
          this.broker.remarkety_id = this.getVariables(data.parameters.WHToken, this.validateOTPU.slice(4, -8));
          this.broker.store_id = this.getVariables(data.parameters.WHStoreId, this.validateOTPU.slice(4, -8));
        },
        error: (error) => {
        }
      });
    }
  }

  datalayer_payment_error(){
    dataLayer.push({
      'event': 'ga_event',
      'name_event': 'payment_error'
    });
  }
}
