import { Component, OnInit } from '@angular/core';
import { CampaignService } from 'src/app/core/services/campaign.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { RoutingService } from 'src/app/core/services/routing.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { environment } from 'src/environments/environment';

export interface offer {
  company?: any,
  card?: any,
  offerId?: any,
  detail?: any
  cat?: any,
  subCat?: any,
  urlRedirect?: any,
  labelType?: any,
  textLabel?: any,
  positionOffer?: any
}

@Component({
  selector: 'app-dash-bc-module',
  templateUrl: './dash-bc-module.component.html',
  styleUrls: ['./dash-bc-module.component.scss']
})
export class DashBcModuleComponent implements OnInit {

  public offers: Array<offer> = [];
  public offersBy2: Array<Array<object>> = [];
  public category: any = [];
  public linkBc = environment.enlaceBuscaCredito + "ofertas/";


  constructor(public campaign: CampaignService, public sesion: SesionService, public routing: RoutingService, private dataLayer: DataLayersService) { }

  ngOnInit(): void {
    // this.offers = [{company:'Aliado 1'},{company:'Aliado 2'},{company:'Aliado 3'},{company:'Aliado 4'},{company:'Aliado 5'},{company:'Aliado 6'},{company:'Aliado 7'},{company:'Aliado 8'}];
    this.getCategory();
  }

  /**
   * Funcion que llama las categorias de bc, despues la ofertas y detemina si hay ofertas para mostrar el modulo
   */
  getCategory() {
    // this.loading=true;
    // this.error=false;
    this.campaign.getCategory().subscribe({
      next: (cat: any) => {
        this.category = cat.categories;
        this.getOffers();
      },
      error: (error) => {
        // this.error=true;
      }
    });
  }

  /**
   * Funcion que llama al servicio de homeOffers/midc y las procesa para homogeneizar la informacion y luego hacer un array para agruparlas en 2
   */
  getOffers() {
    this.campaign.getListBc().subscribe({
      next: (result: any) => {
        // this.loading=false;
        if (result.data) {
          this.offers = this.offers.concat(this.convertOffer(result.data));
          this.setOffersMax2();
          if(this.routing.elementToScroll == 'modulo-bc'){
            this.routing.scrollToElement();
          }

        } else if (result.code = "1") {
          // this.error=true;
          // this.txtError =result.message;
        } else {
          // this.error=true;
        }
        if (this.offers.length > 0) {
          this.sesion.showModuleBC = true;
        }
      },
      error: (error) => {
        // this.error=true;
      }
    });
  }

  /**
   * Funcion que homogeniza los datos y generar un nuevo array con las ofertas con la información que se utilizara.
   * @param offersList lista de ofertas como viene en el servicio
   * @returns lista de ofertsa con la informacion homogeneizada
   */
  convertOffer(offersList: any[]) {
    let newOffersList: any = [];
    let cat: any;
    let subCat: any;
    offersList.forEach((offer: any) => {
      offer.offers.forEach((companyOffer: any) => {
        let offerAuxTmp: any = [];
        offerAuxTmp.company = offer.company;
        offerAuxTmp.card = companyOffer.card;
        offerAuxTmp.offerId = companyOffer.id;
        offerAuxTmp.detail = companyOffer.detail;
        offerAuxTmp.labelType = (companyOffer.labelType == null || companyOffer.labelType == "" ? '0' : companyOffer.labelType);
        offerAuxTmp.textLabel = companyOffer.textLabel;
        offerAuxTmp.positionOffer = companyOffer.positionOffer;
        cat = this.category.filter((ele: any) => ele.id == offerAuxTmp.detail.categoryId);
        subCat = (cat[0].subcategories != undefined) ? cat[0].subcategories.filter((ele: any) => ele.acronyn == offerAuxTmp.detail.subcategories[0]) : subCat = [];
        offerAuxTmp.urlRedirect = this.linkBc + cat[0].url + "/" + (subCat.length > 0 ? subCat[0].url : "");
        newOffersList.push(offerAuxTmp);
      });
    });
    return this.sortOffer(newOffersList).slice(0, 8);
  }

  /**
   * Funcion para dejar un array de arrays de maximo 2 elementos para las ofertas para que se visualicen segun diseño en desktop
   */
  setOffersMax2() {
    let cont = 0;
    let numDebts = this.offers.length;
    let array2: any = []
    this.offers.forEach((ele: object) => {
      array2.push(ele);
      cont++;
      numDebts--;
      if (cont == 2) {
        this.offersBy2.push(array2);
        array2 = [];
        cont = 0;
      }
      if (numDebts == 0) {
        if (array2.length > 0) {
          this.offersBy2.push(array2);
          array2 = [];
        }
      }
    })
  }

  /**
   * Funcion para redireccionar al hacer click en la aplicar oferta
   */
  goToBC() {
    this.dataLayer.dl_eventoGA4_menu_dashboard('Ver ofertas a mi medida', 'Buscacrédito');
    window.open(this.linkBc + "?utm_source=Midatacredito&utm_medium=Dashboard&utm_campaign=ModuloBC", "_self");
  }

  /**
    * Funcion que orderna la ofertas de menor a mayor teniendo en cuenta el campo positionOffer si este viene en 0 las ordena de manera aleatoria
    * @param listOffers lista de ofertas
    * @returns lista de ofertas ordenada
    */
  sortOffer(listOffers: Array<any>) {
    let offerRandom = listOffers.filter(ele => ele.positionOffer == 0 || ele.positionOffer == null) as never[];
    let offerPosition = listOffers.filter(ele => ele.positionOffer > 0) as never[];
    offerRandom = offerRandom.sort(() => { return Math.random() - 0.5 });
    offerPosition.sort((a: any, b: any) => Number(a.positionOffer) - Number(b.positionOffer));
    return [].concat(offerPosition, offerRandom);
  }

  addClass(event: Event, nameClass: string) {
    let htmlEle = event.target as HTMLElement;
    let children = htmlEle.parentElement?.parentElement?.children;
    if (children != undefined) {
      for (let i = 0; i < children.length; i++) {
        children[i].classList.remove(nameClass);
      }
    }
    htmlEle.parentElement?.classList.add(nameClass);
  }

}
