import { Component, Input, OnInit } from '@angular/core';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-publicidad-productos',
  templateUrl: './publicidad-productos.component.html',
  styleUrls: ['./publicidad-productos.component.scss']
})
export class PublicidadProductosComponent implements OnInit {

  @Input() publicidad = '';
  @Input() bordes = '';
  @Input() padding = '';
  @Input() sombra = '';
  @Input() ubicacion = '';
  @Input() marginTop = '';
  @Input() flexTable = '';
  routeS3 = environment.enlaceS3;

  constructor(
    public sesion: SesionService,
    private dataLayerService: DataLayersService
  ) { }

  ngOnInit() {
  }

  // EVENTO GA4 clic_inside_tool
  clic_inside_tool(){
    this.dataLayerService.dl_clic_button_name('clic_inside_tool','Buscar productos | Historico');
  }

}
