import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { BrokerService } from 'src/app/core/services/broker.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { DebtsService } from 'src/app/core/services/debts.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';

declare var $: any;

@Component({
	selector: 'app-detalle-cuenta',
	templateUrl: './detalle-cuenta.component.html',
	styleUrls: ['./detalle-cuenta.component.scss']
})
export class DetalleCuentaComponent implements OnInit {
	//@Input() data: any;

	tabActive = 4;
	deudasMatch: any;
	tabActive0 = false;
	tabActive1 = false;
	tabActive2 = false;
	tabActive3 = false;
	tabActive4 = false;
	maintenanceAlert = false;

	constructor(
		public sesion: SesionService,
		public modal: ModalService,
		public loading: LoadingService,
		public debtsService: DebtsService,
		public broker: BrokerService,
		public router: Router,
		public elementRef: ElementRef,
		private dlService: DataLayersService,
		private utilities:UtilitiesService
	) {
	}

	tabsHC(id: any) {
		this.tabActive = id;
	}

	ngOnInit() {
		// GA4 - User
		this.dlService.dl_userId('.', this.sesion.planCurrently);

		// Size window
		if (this.sesion.detalleCuentaFree == undefined) {
			if (this.sesion.estadoUserF == "ACTIVO" || this.sesion.estadoUserF == 'ACTIVE_PROCESS') {
				this.router.navigate(['/private/historico']);
			} else {
				this.router.navigate(['/freemium/historico']);
			}
		}
		if ($(window).width() >= 768) {
			this.tabActive0 = true;
			this.tabActive1 = true;
			this.tabActive2 = true;
			this.tabActive3 = true;
			this.tabActive4 = true;
		} else {
			if (this.sesion.detalleCuentaFree.comportamiento.length <= 4) {
				this.tabActive = 3;
			} else {
				this.tabActive = 4;
			}
		}
	}

	cta() {
		return this.sesion.detalleCuentaFree;
	}
	name() {
		return this.sesion.nombreCuenta
	}

	goReclamation() {
		this.sesion.getIntermittence().subscribe({
			next: (response: any) => {
				let date = new Date()
				let hour = date.getHours();
				let day = date.getDate();
				let month = date.getMonth();
				let year = date.getFullYear();
				if (month == this.sesion.intermittenceClaimHour.month && (day >= this.sesion.intermittenceClaimHour.dayStart && day <= this.sesion.intermittenceClaimHour.dayEnd) && (hour >= this.sesion.intermittenceClaimHour.HourEnd) && year == this.sesion.intermittenceClaimHour.year) {
					this.maintenanceAlert = true;
				} else if (month == this.sesion.intermittenceClaimHour.month && (day >= this.sesion.intermittenceClaimHour.dayStart && day <= this.sesion.intermittenceClaimHour.dayEnd) && year == this.sesion.intermittenceClaimHour.year) {
					this.maintenanceAlert = true;
				} else if (month == this.sesion.intermittenceClaimHour.month && (day >= this.sesion.intermittenceClaimHour.dayStart && day <= this.sesion.intermittenceClaimHour.dayEnd) && (hour < this.sesion.intermittenceClaimHour.hourStart) && year == this.sesion.intermittenceClaimHour.year) {
					this.maintenanceAlert = true;
				} else {
					this.modal.cuenta = this.sesion.detalleCuentaFree;
					this.modal.modalClaim();
				}
			}
		  });
	}

	addManyChat() {
		let script = document.createElement('script');
		script.src = "//widget.manychat.com/1897847597147648.js";
		script.async = true;
		this.elementRef.nativeElement.appendChild(script);
	}

	irHistoriaCredito() {
		if (this.sesion.viewFree) {
			this.router.navigate(['/freemium/historico']);
		} else {
			this.router.navigate(['/private/historico']);
		}
	}

	permanenceFormat(date: any) {
		let dateSplit = date.split('-');
		let meses: any = { "01": "ENE", "02": "FEB", "03": "MAR", "04": "ABR", "05": "MAY", "06": "JUN", "07": "JUL", "08": "AGO", "09": "SEP", "10": "OCT", "11": "NOV", "12": "DIC" }
		return meses[dateSplit[1]] + "/" + dateSplit[0];
	}

	getComportSlice(init: number, end: number) {
		return this.sesion.detalleCuentaFree.comportamiento.slice(init, end);
	}

	public canCallApplyPadYa: boolean = true;
	public isLoadApplyPadYa: boolean = false;

	openModalPadYa(detalleCa: any) {
		this.isLoadApplyPadYa = true;
		if(this.canCallApplyPadYa){
			if((detalleCa.nameCTA != null && detalleCa.nameCTA != "") && detalleCa.paymentInstructions != null){
				if(detalleCa.paymentInstructions.length > 0){
					this.canCallApplyPadYa= false;
					this.modal.ctaPadYa = detalleCa.nameCTA;
					this.modal.paymentInstructionsPadYA = detalleCa.paymentInstructions;			
					this.modal.itemPadYa = detalleCa.item;
					this.modal.urlPadYa = detalleCa.enlacePad;
					this.modal.numberPadYa = detalleCa.numero;
					this.modal.estadotexto = detalleCa.estadoTexto;
					this.modal.saldoMora = detalleCa.saldoMora;
					this.modal.numeroCuenta = detalleCa.numeroCuenta;
					let num = detalleCa.item;
					let text = num.toString();
					this.utilities.getUtmParams(false);
					let sourceOfTraffic = '';
					let campaignOfTraffic = '';
					let mediumOfTraffic = '';
					if ((Object.keys(this.utilities.utmParams).length > 0)) {
						for (const key in this.utilities.utmParams) {
						if (key.toLowerCase() == 'utm_source') {
							sourceOfTraffic = this.utilities.utmParams[key]
						}
						if (key.toLowerCase() == 'utm_medium') {
							mediumOfTraffic = this.utilities.utmParams[key]
						}
						if (key.toLowerCase() == 'utm_campaign') {
							campaignOfTraffic = this.utilities.utmParams[key]
						}
						}
					}
					this.broker.registerApplyPAD(text,detalleCa.estadoTexto,detalleCa.saldoMora,detalleCa.numeroCuenta,sourceOfTraffic,campaignOfTraffic,mediumOfTraffic).subscribe(
					  {
						next: (data: any) => {
							if (data.code == 0) {
								this.modal.consumerNumber = data.consumerNumber;
								this.canCallApplyPadYa = true;
								this.isLoadApplyPadYa = false;
								this.modal.modalPadYa();
							  }
						},
						error: (error) => {
							this.canCallApplyPadYa = true;
							this.isLoadApplyPadYa = false;
						}
					  }
					  
					);
					
				}else{
					this.isLoadApplyPadYa = false;
				}
			}else{
				this.isLoadApplyPadYa = false;
			}
		}
	}

	isPadYa(detalleCa: any) {
		return detalleCa.enlacePad != '' && detalleCa.enlacePad != undefined && detalleCa.enlacePad != null;
	}
}
