import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-clausula-poliza',
  templateUrl: './clausula-poliza.component.html',
  styleUrls: ['./clausula-poliza.component.scss']
})
export class ClausulaPolizaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $('.contenido').height(window.innerHeight - 100);
  }

}
