
<!-- usuario free -->
<div class="scoreFree genericBox" *ngIf="sesion.viewFree && !sesion.campainFree">
    <div class="fondo">
    </div>
    <div class="contFree">
        <h2>Conoce tu <b>PUNTAJE</b> en <b>DATACRÉDITO</b>. Suscríbete a nuestros planes y conócelo hoy.</h2>
        <p>Consulta tu puntaje ilimitadamente, recibe un diagnóstico de tu perfil crediticio, descarga tu historia de crédito en PDF y mucho más.</p>
        <a class="btnGeneric linkPER linkPago" href="/pago?funn=DASS&plan=PER1" (click)="redirectPlan_and_send_datalayer('Comprar plan')">Comprar plan</a>
    </div>
</div>

<!-- usuario free campaña activa -->
<div class="genericBox boxFreeCamp" *ngIf="sesion.viewFree && sesion.campainFree">
        <div class="scoreFreeCamp">
            <app-score [home]="true"></app-score>
        </div>
        <div class="contFreeCamp">
            <h2>Este es tu <b>PUNTAJE DE CRÉDITO.</b></h2>
            <p class="fecha">Estará disponible para tu consulta hasta el {{sesion.campainfinalDate}}</p>
            <div class="boxFecha">
                <i class="fa fa-exclamation-circle"(click)="getTip(true)"></i>
                <div class="tipCampain" id="contenTooltip">
                    <button (click)="getTip(false)">x</button>
                    <p class="textTipCampain" >El Puntaje de Crédito es una <b>característica paga</b>, que por tiempo limitado va a estar disponible para tu consulta de forma <b>gratuita</b>.</p>
                    <i class="fa fa-sort-down"></i>
                </div>
            </div>
            <p>Si quieres consultar tu puntaje ilimitadamente, tener un diagnóstico de tu perfil crediticio y mucho más, te invitamos a comprar uno de nuestros planes.</p>
            <a class="btnGeneric linkCamp linkPago" href="/pago?funn=DASS&plan=PER1" (click)="redirectPlan_and_send_datalayer('Comprar plan')">Comprar PLAN</a>
        </div>
</div>


<!-- usuario private -->
<div class="genericBox" *ngIf="!sesion.viewFree">
    <h2 class="titlePrivate">Conoce tu diagnóstico crediticio <br><span>un estado general de tu situación financiera.</span> </h2>
    <div class="scorePrivate">
        <div class="dash-score" [ngClass]="{'text-score' : showTextScore()}">
            <app-score [home]="true"></app-score>
            <div class="desc" [ngClass]="{'noAdvance' : !sesion.dataUser.params[13].value}">
                <h2 *ngIf="sesion.miscore">{{titleScore}}</h2>
                <p *ngIf="sesion.miscore">{{descScore}}</p>
            </div>
        </div>
        <div class="diagnostic" *ngIf="this.sesion.diagnostico != null">
            <div class="d-none d-md-block">
                <div class="itemDesc">
                    <img src="assets/img/landings/diagnostico/{{selImg(average(diagnostic.experienciaCrediticia.cals))}}" alt="dignostico bueno">
                    <p [ngClass]="[selTxt(average(diagnostic.experienciaCrediticia.cals))]"><span class="title">Experiencia Crediticia:</span><br>{{selTxt(average(diagnostic.experienciaCrediticia.cals))}}</p>
                </div>
                <div class="itemDesc">
                    <img src="assets/img/landings/diagnostico/{{selImg(average(diagnostic.endeudamiento.cals))}}" alt="dignostico bueno">
                    <p [ngClass]="[selTxt(average(diagnostic.endeudamiento.cals))]"><span class="title">Endeudamiento:</span><br>{{selTxt(average(diagnostic.endeudamiento.cals))}}</p>
                </div>
                <div class="itemDesc">
                    <img src="assets/img/landings/diagnostico/{{selImg(average(diagnostic.estadoPortafolio.cals))}}" alt="dignostico bueno">
                    <p [ngClass]="[selTxt(average(diagnostic.estadoPortafolio.cals))]"><span class="title">Estado de su Portafolio:</span><br>{{selTxt(average(diagnostic.estadoPortafolio.cals))}}</p>
                </div>
                <div class="itemDesc">
                    <img src="assets/img/landings/diagnostico/{{selImg(average(diagnostic.habitoPago.cals))}}" alt="dignostico bueno">
                    <p [ngClass]="[selTxt(average(diagnostic.habitoPago.cals))]"><span class="title">Hábito de Pago:</span><br>{{selTxt(average(diagnostic.habitoPago.cals))}}</p>
                </div>
            </div>
            <div id="carDiagnostic" class="carousel d-block d-md-none" data-ride="carousel" data-interval="false">
                <ul class="carousel-indicators circles">
                    <li data-target="#carDiagnostic" class="btnCircle active"[attr.data-slide-to]="0"><i class="fa fa-circle"></i></li>
                    <li data-target="#carDiagnostic" class="btnCircle active"[attr.data-slide-to]="1"><i class="fa fa-circle"></i></li>
                    <li data-target="#carDiagnostic" class="btnCircle active"[attr.data-slide-to]="2"><i class="fa fa-circle"></i></li>
                    <li data-target="#carDiagnostic" class="btnCircle active"[attr.data-slide-to]="3"><i class="fa fa-circle"></i></li>
                </ul>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="box-car"> 
                            <div class="box-txt itemDesc">
                                <img src="assets/img/landings/diagnostico/{{selImg(average(diagnostic.experienciaCrediticia.cals))}}" alt="dignostico bueno">
                                <p [ngClass]="[selTxt(average(diagnostic.experienciaCrediticia.cals))]"><span class="title">Experiencia Crediticia:</span><br>{{selTxt(average(diagnostic.experienciaCrediticia.cals))}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="box-car"> 
                            <div class="box-txt itemDesc">
                                <img src="assets/img/landings/diagnostico/{{selImg(average(diagnostic.endeudamiento.cals))}}" alt="dignostico bueno">
                                <p [ngClass]="[selTxt(average(diagnostic.endeudamiento.cals))]"><span class="title">Endeudamiento:</span><br>{{selTxt(average(diagnostic.endeudamiento.cals))}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="box-car"> 
                            <div class="box-txt itemDesc">
                                <img src="assets/img/landings/diagnostico/{{selImg(average(diagnostic.estadoPortafolio.cals))}}" alt="dignostico bueno">
                                <p [ngClass]="[selTxt(average(diagnostic.estadoPortafolio.cals))]"><span class="title">Estado de su Portafolio:</span><br>{{selTxt(average(diagnostic.estadoPortafolio.cals))}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="box-car"> 
                            <div class="box-txt itemDesc">
                                <img src="assets/img/landings/diagnostico/{{selImg(average(diagnostic.habitoPago.cals))}}" alt="dignostico bueno">
                                <p [ngClass]="[selTxt(average(diagnostic.habitoPago.cals))]"><span class="title">Hábito de Pago:</span><br>{{selTxt(average(diagnostic.habitoPago.cals))}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <a class="carousel-control-prev spaceBtn" href="#carDiagnostic" role="button" data-slide="prev">
                    <i class="fa fa-chevron-left"></i>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next spaceBtn" href="#carDiagnostic" role="button" data-slide="next">
                    <i class="fa fa-chevron-right"></i>
                    <span class="sr-only">Next</span>
                </a>
            </div>
            <button class="btnGeneric" (click)="redirectPlan_and_send_datalayer('Ver diagnóstico completo')">Ver diagnóstico completo</button>
        </div>
    </div>
</div>
