<div class="experian">
	<section class="gradient-gray section margin-main-top">
		<div class="container">
			<div class="row">
				<div class="col-12 col-md-6">
					<p class="hashtag">#MonitoreaTusDeudas</p>
					<h2 class="txt-purple">Conoce como tu puntaje y diagnóstico pueden mejorar tu vida financiera.</h2>
					<p class="outstanding">En Midatacrédito te ayudamos a llevar a otro nivel tu perfil crediticio</p>
					<img src="assets/img/landings/diagnostico/img_header.svg" class="img-main d-block d-md-none" alt="Dianóstico + Puntaje Midatacrédito">
					<div class="btn-next btn-55">
						<a class="btn btn-primary" href="/landing/diagnostico-puntaje#nuevosPlanes" target="_self">Ver Planes <span class="arrow-right"><img src="assets/img/images/arrow-right.svg" alt="flecha derecha" class="txt-experian-purple"></span></a>                
					</div>
					
				</div>
				<div class="col-12 col-md-6 text-center d-none d-md-block">
					<img src="assets/img/landings/diagnostico/img_header.svg" class="img-main" alt="Dianóstico + Puntaje Midatacrédito">
				</div>
				<div class="col-12 text-center">
				<p class="terms txt-purple">Para acceder a este plan requieres de una tarjeta de crédito válida, conforme lo establecen los <a href="https://www.midatacredito.com/terminoscondiciones" target="_blank">Términos y Condiciones.</a></p>
				</div>
			</div>
		</div>
	</section>
	<section class="white section bg-bullets">
		<div class="container">
			<div class="row">
				<div class="col-12 text-center title-section">
				<h2 class="bold">Toma el control de tus finanzas</h2>
				<h3 class="light">Haz parte de las personas que hoy tienen un mejor perfil de crédito</h3>
				</div>
				<div class="col-12 col-md-4 text-center ">
				<img src="assets/img/landings/diagnostico/Puntaje.svg" class="img-bullets" alt="Obten tu puntaje de crédito">
				<div class="box-title">
					<h4 class="title-description">Obtén tu puntaje<br>de crédito</h4>
				</div>              
				<p class="description-bullets">Un buen puntaje te acerca más<br>a tu próximo crédito</p>
				</div>
				<div class="col-12 col-md-4 text-center ">
				<img src="assets/img/landings/diagnostico/diagnostico.svg" class="img-bullets" alt="Conoce tu diagnóstico">
				<div class="box-title">
					<h4 class="title-description">Conoce tu<br>diagnóstico</h4>
				</div>              
				<p class="description-bullets">Vigila tus hábitos de pago y potencia <br>tu capacidad de endeudamiento</p>
				</div>
				<div class="col-12 col-md-4 text-center ">
				<img src="assets/img/landings/diagnostico/monitoreo.svg" class="img-bullets" alt="Monitorea tu información personal">
				<div class="box-title">
					<h4 class="title-description">Monitorea tu<br>información personal</h4>
				</div>              
				<p class="description-bullets">Recibe alertas cada vez que detectemos <br>filtración de tus datos personales </p>
				</div>
			</div>
		</div>
	</section>
	<section class="section gray-0 plan" id="nuevosPlanes">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-12 col-md-9">
					<h3>Hoy Midatacrédito protege y monitorea <br>a más de 150.000 personas cada mes</h3>
					<p>Haz parte de los colombianos que se preocupan por su realidad financiera y toma el control de tu vida crediticia.</p>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-12 col-md-5">
					<div class="planBox">
						<div class="planSuperior">
							<div class="titlePlan">PERFIL DE CRÉDITO</div>
							<div class="pricePlan">{{sesion.planes.planes.per.valor[0]}} <span>/ mes</span></div>
							<!-- <div class="antes">Antes $<span class="tachado">17.500</span><span class="descuento">&nbsp; -45% de descuento</span></div> -->
							<div id="plan1" class="ctaPlan noDestacado" (click)="seleccionarPlan(sesion.planes.planes?.per, '1', sesion.planes.planes?.per?.mensual?.[1], sesion.planes.planes?.per?.mensual?.[0])">
								Adquirir plan
								<span class="arrow-right"><img src="../../../assets/img/images/arrow-right.svg" alt="flecha derecha" class="txt-experian-purple"></span>
							</div>
						</div>
						<div class="planInferior">
							<div class="item">
								<div class="icono">
									<i class="fa fa-check" aria-hidden="true"></i>
								</div>
								<div class="caracteristica">Puntaje de crédito para saber con qué seguridad las entidades te prestarán dinero</div>
							</div>
							<div class="item">
								<div class="icono">
									<i class="fa fa-check" aria-hidden="true"></i>
								</div>
								<div class="caracteristica">Alertas inmediatas en cuanto las entidades reporten o consulten tu historial.</div>
							</div>
							<div class="item">
								<div class="icono">
									<i class="fa fa-check" aria-hidden="true"></i>
								</div>
								<div class="caracteristica">Alertas antifraude (por email o sms). Con cada cambio en tu historia de crédito recibes un aviso de seguridad.</div>
							</div>
							<div class="item">
								<div class="icono">
									<i class="fa fa-check" aria-hidden="true"></i>
								</div>
								<div class="caracteristica">Calculadora de éxito de solicitud de créditos y deudas.</div>
							</div>
							<div class="item">
								<div class="icono">
									<i class="fa fa-check" aria-hidden="true"></i>
								</div>
								<div class="caracteristica">Asesor financiero virtual para mejorar tu score y acceso al crédito.</div>
							</div>
							<div class="ghost"></div>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-5">
					<div class="planBox morePopular">
						<div class="sello">RECOMENDADO</div>
						<div class="planSuperior">
							<div class="titlePlan">PLAN PREMIUM</div>
							<div class="pricePlan">{{sesion.planes.planes.ant.valor[0]}} <span>/ mes</span></div>
							<!-- <div class="antes">Antes $<span class="tachado">23.500</span><span class="descuento">&nbsp; -35% de descuento</span></div> -->
							<div id="plan2" class="ctaPlan" (click)="seleccionarPlan2(sesion.planes.planes?.pre, '1', sesion.planes.planes?.pre?.mensual?.[1], sesion.planes.planes?.pre?.mensual?.[0])">
								Adquirir plan
								<span class="arrow-right"><img src="../../../assets/img/images/arrow-right.svg" alt="flecha derecha" class="txt-experian-purple"></span>
							</div>
							<div class="item">
								<div class="icono">
									<img src="assets/img/landings/diagnostico/check-premium.svg" alt="Seguro Anti-Fraude" width="23">
								</div>
								<div class="caracteristica resaltada">Adquiere un seguro Anti-Fraude en caso de robo de identidad o tarjetas de crédito.</div>
							</div>
							<div class="item">
								<div class="icono">
									<img src="assets/img/landings/diagnostico/check-premium.svg" alt="Seguro Anti-Fraude" width="23">
								</div>
								<div class="caracteristica resaltada">Recibe Asesoría Legal personalizada.</div>
							</div>
							<div class="item">
								<div class="icono">
									<img src="assets/img/landings/diagnostico/check-premium.svg" alt="Seguro Anti-Fraude" width="23">
								</div>
								<div class="caracteristica resaltada">Monitoreo de vulnerabilidad de tus contraseñas y cuentas en internet.</div>
							</div>
							<div class="item">
								<div class="icono ">
									<img src="assets/img/landings/diagnostico/check-premium.svg" alt="Seguro Anti-Fraude" width="23">
								</div>
								<div class="caracteristica resaltada">Revisión de la exposición en la red de documentación personal.</div>
							</div>
							<div class="ghost"></div>
						</div>
						<div class="planInferior">
							<div class="item">
								<div class="icono">
									<i class="fa fa-check" aria-hidden="true"></i>
								</div>
								<div class="caracteristica">Además, incluye todos los beneficios del plan 			<span>Perfil de Crédito</span>
								</div>
							</div>
							<div class="ghost"></div>
						</div>
					</div>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-12 col-md-10">
					<div class="importantes">
						Nota: Tanto la póliza como la asesoría legal son servicios prestados por SBS Seguros Colombia S.A.<br />
						<!-- * Para acceder a este plan requieres de una tarjeta de crédito válida, conforme lo establecen los <a href="/terminoscondiciones" target="_blank">Términos y Condiciones</a>. Validaremos tu tarjeta de crédito con una transacción de $200 pesos que luego te serán reembolsados. -->
					</div>
				</div>
			</div>
		</div>
	</section>
</div>