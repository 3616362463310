import { Component, OnInit } from '@angular/core';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { SesionService } from 'src/app/core/services/sesion.service';
declare var $: any;

@Component({
  selector: 'app-diagnostico',
  templateUrl: './diagnostico.component.html',
  styleUrls: ['./diagnostico.component.scss']
})
export class DiagnosticoComponent implements OnInit {

  public textoMicroLoader = 'Por favor espera un momento, estamos cargando tu informacion.';
  public textErrorMicroLoader = 'En este momento estamos presentando problemas. Por favor inténtalo más tarde.';

  constructor(public sesion : SesionService, private dataLayerService: DataLayersService) {
    if (this.sesion.diagnostico == undefined) {
      this.sesion.infoHC(true);
    }
   }

  ngOnInit() {
    $('.content-tab').hide();
    // GA4 - User
    this.dataLayerService.dl_userId( '.', this.sesion.planCurrently );
  }

  openAcordeon(id:any){
    if($('#tab-'+id).css("display") == "none"){
      $('.content-tab').hide();
      $('#tab-'+id).show('fast');
    }else{
      $('.content-tab').hide('fast');
    }
  }

  selCal(cal:any){
    var calificacion:any = {
      'B' : 'good',
      'E' : 'excelent',
      'R' : 'bad',
      'N/E' : ''
    }
    return calificacion[cal];
  }

}
