import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BrokerService } from 'src/app/core/services/broker.service';
import { DebtsService } from 'src/app/core/services/debts.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pad-app',
  templateUrl: './pad-app.component.html',
  styleUrls: ['./pad-app.component.scss']
})
export class PadAppComponent implements OnInit {

  public loadHC: boolean = false;
  public linkBc = environment.enlaceBuscaCredito + "ofertas/";

  constructor(public sesion: SesionService, public router: Router, public broker: BrokerService, private debts:DebtsService) { }

  ngOnInit(): void {
    this.validateSession();
  }

  validateSession() {
    this.sesion.getCookie();
    if (this.sesion.cookieValue) {
      this.broker.validationSession(this.sesion.cookieValue).subscribe({
        next: (resp: any) => {
          this.sesion.user = resp;
          this.sesion.loggedIn = true;
          this.getHc();
        },
        error: (err: any) => {
          this.router.navigate(['']);
        }
      }

      )
    } else {
      this.router.navigate(['']);
    }
  }

  getHc() {
    if (this.sesion.resumenhc == undefined) {
      if (this.sesion.viewFree) {
        this.sesion.freepremium(true);
      } else {
        this.sesion.infoHC(true);
      }
    }else{
			this.debts.getDebtsPrintPadYa(this.sesion.allHistory)
		}
  }

   /**
   * Funcion para redireccionar al hacer click en la aplicar oferta
   */
   goToBC() {
    window.open(this.linkBc + "?utm_source=midatacredito&utm_medium=boton_app&utm_campaign=pad_app", '_self');
  }

}
