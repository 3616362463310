import { Component, OnInit } from '@angular/core';
import { SuscripcionService } from 'src/app/core/services/suscripcion.service';
import { Plan } from '../../../core/models/plan.model';
import { SesionService } from 'src/app/core/services/sesion.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { BrokerService } from 'src/app/core/services/broker.service';
import { Router } from '@angular/router';
import { DataLayersService } from 'src/app/core/services/data-layers.service';

declare var $: any;

@Component({
  selector: 'app-diagnostico-puntaje',
  templateUrl: './diagnostico-puntaje.component.html',
  styleUrls: ['./diagnostico-puntaje.component.scss']
})
export class DiagnosticoPuntajeComponent implements OnInit {
  varProtege: any;

  constructor(public suscripcion: SuscripcionService, public sesion: SesionService, public loading: LoadingService, public broker: BrokerService, public router: Router, private dataLayerService: DataLayersService) {
    this.varProtege = localStorage.getItem("lanProtege");
  }

  ngOnInit() {
    if (this.varProtege == '3') {
      $('#plan1').click();
    }
    if (this.varProtege == '7') {
      $('#plan2').click();
    }
    if (this.sesion.user){
      this.dataLayerService.dl_userId( '.', this.sesion.planCurrently );
    } else {
      this.dataLayerService.dl_userId( null, 'anonimo' );
    }
  }

  seleccionarPlan(plan: any, tipo: any, valorMensual: any, valorTotal: any) {
    this.loading.open();

    localStorage.setItem("lanProtege", "3");

    //this.broker.promov = 5;
    this.suscripcion.planSeleccionado = plan;
    this.suscripcion.tipoPlan = tipo;
    this.suscripcion.valorSeleccionadoMensual = valorMensual;
    this.suscripcion.valorSeleccionadoTotal = valorTotal;

    if (this.sesion.user) {
      this.suscripcion.planSesion(true, 'LDP');
    } else {
      this.suscripcion.planSesion(false, 'LDP');
    }

    this.loading.close();
  }

  seleccionarPlan2(plan: any, tipo: any, valorMensual: any, valorTotal: any) {
    this.loading.open();

    localStorage.setItem("lanProtege", "7");

    this.broker.promov = 6;
    this.suscripcion.planSeleccionado = plan;
    this.suscripcion.tipoPlan = tipo;
    this.suscripcion.valorSeleccionadoMensual = valorMensual;
    this.suscripcion.valorSeleccionadoTotal = valorTotal;

    if (this.sesion.user) {
      this.suscripcion.planSesion(true, 'LDP');
    } else {
      this.suscripcion.planSesion(false, 'LDP');
    }

    this.loading.close();
  }
}
