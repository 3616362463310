import { Injectable,Inject } from '@angular/core';
import { Plan } from '../models/plan.model';
import { Router, ActivatedRoute, Params  } from '@angular/router';
import { environment } from '../../../environments/environment';
import { SesionService } from './sesion.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class SuscripcionService {

  planSeleccionado: any;
  valorSeleccionadoMensual: any;
  valorSeleccionadoTotal: any;
  tipoPlan: any;
  darkweb: any;


  constructor(private route: Router, private sesion: SesionService) {
  }

  planSesion( activo: boolean, funn: string ) {
    localStorage.setItem('planSeleccionado', JSON.stringify(this.planSeleccionado));
    localStorage.setItem('tipoPlan', this.tipoPlan);
    localStorage.setItem('valorPlanMensual', this.valorSeleccionadoMensual);
    localStorage.setItem('valorPlanTotal', this.valorSeleccionadoTotal);
    localStorage.setItem('planId', this.planSeleccionado.id + this.tipoPlan);
    localStorage.setItem('planUrl', this.planSeleccionado.id + this.tipoPlan);
    localStorage.setItem("pay", "1");
    localStorage.setItem("funn", funn);
    //localStorage.setItem("confirmPay", "1");
    if ( activo && !this.darkweb ) {
      localStorage.removeItem('lanProtege');
      window.location.href = "/pago?funn="+funn;
      
      //this.route.navigate(['/pago?funn='+funn]);
    } else if (!activo && !this.darkweb) {
      
    let params = new URLSearchParams(window.location.search)
    let params2 = Object.fromEntries(new URLSearchParams(params).entries());
    localStorage.setItem('urlParams', JSON.stringify(params2));
      const redirect = this.sesion.getCookieServices('REDIRECT');
      // tslint:disable-next-line: max-line-length
      // redirect ? window.location.replace(environment.enlaceLogin + '&redirect=' + redirect) : window.location.replace(environment.enlaceLogin);
      redirect ? window.location.replace(environment.enlaceLogin + '&redirect=' + redirect) : window.location.replace(environment.enlaceLogin + "&"+ params.toString());
    } else if (this.darkweb) {
      this.route.navigate(['/protege/registro']);
    }
  }

  setStoragePay(data:any) {
    localStorage.setItem('planSeleccionado', data.planSeleccionado);
    localStorage.setItem('tipoPlan', data.tipoPlan);
    localStorage.setItem('valorPlanMensual', data.valorMensual);
    localStorage.setItem('valorPlanTotal', data.valorTotal);
    localStorage.setItem('planId', data.planId);
    localStorage.setItem('funn', data.funn);
    localStorage.setItem('planUrl', data.planId);
    this.sesion.infoPagos();
  }
  setPlanUrl(loggedIn:boolean){
      let params = new URLSearchParams(window.location.search)
      
      let params2 = Object.fromEntries(new URLSearchParams(params).entries());
      const planUrl = params2['plan']
      if(planUrl && loggedIn){
        localStorage.setItem('planUrl', planUrl);
        localStorage.setItem('funn', params2['funn']);
      }else if(planUrl && !loggedIn){
        localStorage.setItem("pay", "1");
        localStorage.setItem('urlParams', sessionStorage.getItem('utmParams') as string);
        localStorage.setItem('planUrl', planUrl);
        localStorage.setItem('funn', params2['funn']);
        window.location.replace(environment.enlaceLogin + "&"+ params.toString())
      }
      return new Promise((resolve) => {
        resolve({ success: true });
      });
  }
}
