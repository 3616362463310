import { Component, OnInit } from '@angular/core';
import { BrokerService } from 'src/app/core/services/broker.service';
import { LoadingService } from '../../core/services/loading.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  public tipsFinancieros = [
    {
        "id": 1,
        "texto": "Toda persona que paga sus obligaciones a tiempo y no recurre con frecuencia a los tiempos extemporáneos, tendrá un reporte positivo."
    },
    {
        "id": 2,
        "texto": "El puntaje de crédito demuestra que tan responsable es una persona con sus pagos ¡Entre más alto mejor!"
    },
    {
        "id": 3,
        "texto": "El historial crediticio es un reporte que hace más rápido y fácil el estudio para una solicitud de crédito."
    },
    {
        "id": 4,
        "texto": "Todos estamos reportados en centrales de riesgo, solo que hay reportes positivos y reportes negativos."
    },
    {
        "id": 5,
        "texto": "La única forma de cambiar el reporte de negativo a positivo ante las centrales de riesgo es pagando las obligaciones y controlando la vida crediticia."
    },
    {
        "id": 6,
        "texto": "Tener muchas deudas no es sinónimo de contar con una buena vida financiera, créditos innecesarios pueden dañar tu puntaje de crédito al no poder pagarlos a tiempo."
    },
    {
        "id": 7,
        "texto": "Tu capacidad de endeudamiento no debe superar el 30% de tus ingresos."
    },
    {
        "id": 8,
        "texto": "Consulta tu historia de crédito periódicamente, recuerda que puedes hacerlo gratis en midatacrédito."
    },
    {
        "id": 9,
        "texto": "93% de las obligaciones reportadas corresponden a datos positivos."
    },
    {
        "id": 10,
        "texto": "Estar reportado en una central de riesgo mejora las oportunidades del acceso al crédito."
    },
    {
        "id": 11,
        "texto": "A mayor experiencia se espera que los créditos sean usados principalmente para construcción de patrimonio, y en menor medida para los gastos del día a día."
    },
    {
        "id": 12,
        "texto": "Se considera que una persona tiene un portafolio sólido y diverso cuando tiene 4 o más obligaciones abiertas y al día."
    },
    {
        "id": 13,
        "texto": "Ser codeudor tendrá un impacto en el cálculo de su capacidad de endeudamiento y es un riesgo adicional ya que el pago de la deuda no depende de ti."
    },
    {
        "id": 14,
        "texto": "Estar siempre al día en tus obligaciones ayudará a construir unas finanzas sólidas y saludables."
    }
    
];
  public tipFinanciero = "";

  constructor( public content: LoadingService, public broker : BrokerService ) {
    this.obtenerTip();
  }

  ngOnInit() {
  }

  obtenerTip(){
    let minimo = 1;
    let maximo = this.tipsFinancieros.length-1;
    let numRandom = Math.floor(Math.random() * (maximo - minimo + minimo)) + minimo;
    this.tipFinanciero = this.tipsFinancieros[numRandom].texto;
  }

}
