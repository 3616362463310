<div class="containerBc">
    <div class="cabecera">
        <img src="assets/img/header/Logo_Buscacredito.svg" alt="logo buscacrédito">
    </div>
    <div class="fondo">
        <h4 class="desc"><b>Buscacrédito,</b> un servicio de <b>Midatacrédito</b> donde encontrarás ofertas de crédito y tarjetas de crédito ajustadas a tu <b>perfil crediticio,</b> de forma segura, rápida y gratis.</h4>
        <hr class="sepTitle">
        <app-micro-loader [error]="error" [textoError]="txtError" *ngIf="loading">
        </app-micro-loader>
        <div class="cardB" *ngFor="let offer of offers; let i = index">
            <div class="tab">
                <h2 class="txtTab">{{offer.card.productName }}</h2>
            </div>
            <div class="cont" id="offer{{offer.card.buttonId}}" [ngClass]="setback(offer.detail.subcategories.includes('TC'),offer.card.buttonId,(offer.detail.offerLogoURL != ''?offer.detail.offerLogoURL:offer.company.entityimg))">
                <div class="partOne">
                    <img *ngIf="!offer.detail.companyLogoFlag" src="{{offer.company.entityimg}}" alt="logo oferta">
                    <img *ngIf="offer.detail.companyLogoFlag" src="{{offer.detail.offerLogoURL}}" alt="logo oferta">
                    <a class="d-none d-md-block btnAplicar" (click)="open_offer(offer)" target="_blank" rel="noopener">Aplica ahora</a>
                </div>
                <hr class="d-block d-md-none">
                <div class="partTwo">
                    <div class="info twoParts">
                        <p class="title">{{offer.card.offerValueTitle}}</p>
                        <p class="desc">{{offer.card.offerValue}}</p>
                    </div>
                    <div class="info twoParts">
                        <p class="title">{{offer.card.offerAproxValueTitle}}</p>
                        <p class="desc">{{offer.card.offerAproxValue}}</p>
                    </div>
                </div>
                <hr class="d-block d-md-none">
                <a class="d-block d-md-none btnAplicar" (click)="open_offer(offer)">Aplica ahora</a>
            </div>
        </div>
        <a class="generalBtn" href="{{linkBc}}?utm_source=midatacredito&utm_medium=boton_HC&utm_campaign=menu_freemium" target="_blank" rel="noopener">Conocer ofertas<i class="fa fa-chevron-right"></i></a>
    </div>
</div>
