import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {

  @Input() testimonials: any[] = [];
  public testimonialBefore: any;
  public testimonialMain: any;
  public testimonialAfter: any;
  constructor() { }

  ngOnInit(): void {
  }

  validarEstrella(start: any, qualify: any) {
    if (start <= qualify) {
      return "white";
    } else {
      return "disabled";
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['testimonials'].currentValue != undefined){
      this.testimonialBefore = changes['testimonials'].currentValue[0];
      this.testimonialMain = changes['testimonials'].currentValue[1];
      this.testimonialAfter = changes['testimonials'].currentValue[changes['testimonials'].currentValue.length - 1];
    }
  }

  moveTestimomnal(after:boolean) {
    if(window.innerWidth <= 768){
      let num = (after ? 1 : -1);
      let indexMain = (this.testimonialMain.id - 1) + num;
      if (indexMain >= this.testimonials.length) {
        indexMain = 0;
      }
      if(indexMain < 0){
        indexMain = this.testimonials.length -1;
      }
      let indexAfter = (this.testimonialAfter.id - 1) + num;
      if (indexAfter >= this.testimonials.length) {
        indexAfter = 0;
      }
      if(indexAfter < 0){
        indexAfter = this.testimonials.length -1;
      }
      let indexBefore = (this.testimonialBefore.id - 1) + num;
      if (indexBefore >= this.testimonials.length) {
        indexBefore = 0;
      }
      if(indexBefore < 0){
        indexBefore = this.testimonials.length -1;
      }
      this.testimonialMain = this.testimonials[indexMain];
      this.testimonialAfter = this.testimonials[indexAfter];
      this.testimonialBefore = this.testimonials[indexBefore];
    }
  }

}
