import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BrokerService } from 'src/app/core/services/broker.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { environment } from '../../../environments/environment';
import { SesionService } from 'src/app/core/services/sesion.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';

@Component({
  selector: 'app-webcheckout',
  templateUrl: './webcheckout.component.html',
  styleUrls: ['./webcheckout.component.scss']
})
export class WebcheckoutComponent implements OnInit {

  public nombre = "[Nombre]";
  public plan = "[nombre del plan]"
  public planId:any;
  public planes = [
    { "Id": "PER", "plan": 'Plan perfil de credito mensual', "caracteristicas": ['Conoce tu puntaje', 'Monitorea todos los cambios en tu Historia de Crédito', 'Mejora tu perfil con un diagnóstico personalizado'] }
    , { "Id": "ANT", "plan": 'Plan protege mensual', "caracteristicas": ['Conoce tu puntaje', 'Monitorea todos los cambios en tu Historia de Crédito', 'Mejora tu perfil con un diagnóstico personalizado', 'Activa el monitoreo ante posibles fraudes', 'Detecta filtraciones de tus datos personales'] }
    , { "Id": "PRO", "plan": 'Plan premium mensual', "caracteristicas": ['Conoce tu puntaje', 'Monitorea todos los cambios en tu Historia de Crédito', 'Mejora tu perfil con un diagnóstico personalizado', 'Activa el monitoreo ante posibles fraudes', 'Detecta filtraciones de tus datos personales', 'Adquiere un seguro Anti-Fraude en caso de robo de identidad o tarjetas de crédito', 'Recibe asesoría Legal personalizada'] }
  ]
  public caracetristicasPlan:any;
  public tituloPlan:any;
  public valorPagar = '0';
  public documentId:any;
  public dataPayu:any;

  constructor(private activatedRoute: ActivatedRoute, private brokerService: BrokerService,public loading: LoadingService, public router: Router, private sesion: SesionService, private dataLayerService: DataLayersService) { }

  ngOnInit() {
    this.documentId = this.activatedRoute.snapshot.paramMap.get('documentId');
    this.tituloPlan = this.planes[2].plan;
    this.caracetristicasPlan = this.planes[2].caracteristicas;
    this.getPaymentWC(this.documentId);
    if (this.sesion.user){
      this.dataLayerService.dl_userId( '.', this.sesion.planCurrently );
    } else {
      this.dataLayerService.dl_userId( null, 'anonimo' );
    }
  }

  getPaymentWC(documentId:any) {
    this.loading.open();
    this.brokerService.paymentWC(documentId).subscribe(
      (result:any) => {
        this.nombre = this.jsUcfirst(result.buyerFullName.toLowerCase());
        this.dataPayu = result;
        this.planId = this.dataPayu.description.split(' ')[2];
        var planSel = this.planes.find((element) => element.Id == this.planId.substring(0, 3));
        if(planSel != undefined){
          switch (this.planId.substring(3, 4)) {
            case '2':
              planSel["plan"] = planSel.plan.replace('mensual', 'trimestral');
              break;
            case '3':
              planSel["plan"] = planSel.plan.replace('mensual', 'anual');
              break;
            default:
              break;
          }
          this.tituloPlan = planSel.plan;
          this.caracetristicasPlan = planSel.caracteristicas;
          this.plan = planSel.plan;
          this.valorPagar = this.dataPayu.tax + this.dataPayu.taxReturnBase
          this.irPagar();
          this.loading.close();
        }
      },
      (error:any) => {
        this.loading.close();
        this.router.navigate(['/error']);
      }
    )
  }

  irPagar() {
    var formPago: any = document.getElementById("formPago");
    formPago.elements['amount'].value = this.dataPayu.amount;
    formPago.elements['merchantId'].value = this.dataPayu.merchantId;
    formPago.elements['referenceCode'].value = this.dataPayu.referenceCode;
    formPago.elements['accountId'].value = this.dataPayu.accountId;
    formPago.elements['description'].value = this.dataPayu.description;
    formPago.elements['tax'].value = this.dataPayu.tax;
    formPago.elements['taxReturnBase'].value = this.dataPayu.taxReturnBase;
    formPago.elements['signature'].value = this.dataPayu.signature;
    formPago.elements['currency'].value = this.dataPayu.currency;
    formPago.elements['buyerEmail'].value = this.dataPayu.buyerEmail;
    formPago.elements['lng'].value = this.dataPayu.lng;
    formPago.elements['extra1'].value = this.dataPayu.extra1;
    formPago.elements['confirmationUrl'].value = this.dataPayu.confirmationUrl;
    formPago.elements['mobilePhone'].value = this.dataPayu.mobilePhone;
    formPago.elements['shippingAddress'].value = this.dataPayu.shippingAddress;
    formPago.elements['telephone'].value = this.dataPayu.telephone;
    formPago.elements['algorithmSignature'].value = this.dataPayu.algorithmSignature;
    formPago.elements['shippingCity'].value = this.dataPayu.shippingCity;
    formPago.elements['shippingCountry'].value = this.dataPayu.shippingCountry;
    formPago.elements['buyerFullName'].value = this.dataPayu.buyerFullName;
    formPago.elements['responseUrl'].value = environment.APIEndPoints.urlEndPoints + 'webcheckout-confirm';
    sessionStorage.setItem('documentMDCWCO', this.documentId)
    sessionStorage.setItem('planMDCWCO', this.planId)
    formPago.submit();
  }

  jsUcfirst(string:any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

}
