import { Component, HostListener, Inject, Injectable, InjectionToken, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Plan } from 'src/app/core/models/plan.model';
import { ConstantesService } from 'src/app/core/services/constantes.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { MetadatosService } from 'src/app/core/services/metadatos.service';
import { SesionService } from 'src/app/core/services/sesion.service';
declare var $: any;
@Component({
  selector: 'app-comparacion-planes',
  templateUrl: './comparacion-planes.component.html',
  styleUrls: ['./comparacion-planes.component.scss']
})
export class ComparacionPlanesComponent implements OnInit {

  public planes = {} as any;
  public planesFil = [];
  public freePremium = {};
  public planPer = {} as Plan;
  public planAnt = {} as Plan;
  public planPre = {} as Plan;
  public scroll = false;
  public caracteristicasTotal = [
    {
      plan: "Historia de crédito:",
      caracteristicasPlan: [
        {
          caracteristica: "Historia de crédito detallada",
          free: true,
          per: true,
          ant: true,
          pre: true,
        },
        {
          caracteristica: "Reclamar a las entidades si tienes algún problema con tu información financiera.",
          free: true,
          per: true,
          ant: true,
          pre: true,
        },
        {
          caracteristica: "Revisar tus huellas de consulta.",
          free: true,
          per: true,
          ant: true,
          pre: true,
        },
        {
          caracteristica: "Colocar Alertas al sistema financiero en caso de suplantación o fraude.",
          free: true,
          per: true,
          ant: true,
          pre: true,
          tooltip: {
            titulo: "Alertas al sistema financiero en caso de:",
            items: ["Me suplantaron", "Perdí la cédula", "Vivo en el exterior"]
          }
        },
        {
          caracteristica: "Recibir comunicaciones sobre nuevas aperturas en tu historia de crédito.",
          free: true,
          per: true,
          ant: true,
          pre: true
        }
      ]
    },
    {
      plan: "Entrenador financiero:",
      caracteristicasPlan: [
        {
          caracteristica: "Conocer tu puntaje de crédito.",
          free: false,
          per: true,
          ant: true,
          pre: true,
        },
        {
          caracteristica: "Conocer tu diagnóstico detallado sobre tu perfil crediticio.",
          free: false,
          per: true,
          ant: true,
          pre: true,
        },
        {
          caracteristica: "Conocer el histórico de puntaje de crédito de los últimos 24 meses.",
          free: false,
          per: true,
          ant: true,
          pre: true,
        },
        {
          caracteristica: "Calcular la probabilidad de éxito ante solicitud de un crédito.",
          free: false,
          per: true,
          ant: true,
          pre: true,
        },
        {
          caracteristica: "Conocer el consolidado de tus deudas.",
          free: false,
          per: true,
          ant: true,
          pre: true,
        },
        {
          caracteristica: "Descargar tu Historia de Crédito en formato PDF.",
          free: false,
          per: true,
          ant: true,
          pre: true
        }
      ]
    },
    {
      plan: "Tu presencia en internet:",
      caracteristicasPlan: [
        {
          caracteristica: "Monitorear tus emails, contraseñas y  documentos personales expuestos en internet.",
          free: false,
          per: false,
          ant: true,
          pre: true,
        }

      ]
    },
    {
      plan: "Evita el fraude en tus compras",
      caracteristicasPlan: [
        {
          caracteristica: "Recibir una poliza anti-fraude ante el robo de identidad y ante el robo de tarjetas de crédito.*",
          free: false,
          per: false,
          ant: false,
          pre: true,
        },
        {
          caracteristica: "Recibir asesoría legal personalizada.*",
          free: false,
          per: false,
          ant: false,
          pre: true,
        }
      ]
    }
  ];

  constructor(
    public sesion: SesionService, 
    public constant : ConstantesService,
    private dataLayerService: DataLayersService,
    private metadatosService: MetadatosService,
    private titulo: Title,
    private meta: Meta
    ) {
      titulo.setTitle('Planes para cumplir tus objetivos financieros - Midatacrédito');
      meta.updateTag({name: 'description', 
        content:"Pensando en tu futuro y bienestar, en Midatacrédito te ofrecemos diferentes planes para que logres tus objetivos financieros. Conócelos aquí."});

    this.planPer = this.constant.planPer;
		this.planAnt = this.constant.planAnt;
		this.planPre = this.constant.planPre;
    this.freePremium = this.constant.freePremium;
    this.planesFil = this.constant.planes.mensual;
  }

  ngOnInit() {
    this.tracking_dataLayer();
    this.metadatosService.actualizando_canonical('https://www.midatacredito.com/comparacion-planes');
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event:any) {
    if (window.pageYOffset !== 0) {
      this.scroll = true;
      $(".header").hide();
      //$(".mdc-sm-icon").addClass("filter");
    } else {
      this.scroll = false;
      $(".header").show();
      //$(".mdc-sm-icon").removeClass("filter");      
    }
  }

  changePago(pago:any) {
    this.planesFil = this.constant.planes[pago];
    this.actualizar_dataLayer(pago);
  }

  /**
   * La función de este método es el envio de dos data layer. El primero distiguiendo el tipo de usuario que visita la página
   * para ese se utiliza el "userId". El segundo "view_item_list" para mostrar los diferentes productos.
   */
  tracking_dataLayer(){
    if (this.sesion.user){
      this.dataLayerService.dl_userId( '.', this.sesion.planCurrently );
    } else {
      this.dataLayerService.dl_userId( null, 'anonimo' );
    }
    this.dataLayerService.dl_view_item_list( this.planPer, this.planAnt, this.planPre, 'COMPARACIÓN PLANES', 'Mensual' );
  }

  actualizar_dataLayer( periodo: string ){
    periodo = periodo[0].toUpperCase() + periodo.substring(1);

    let plan_per = this.planPer;
    let plan_ant = this.planAnt;
    let plan_pre = this.planPre;

    plan_per.mensual[0] = this.planesFil[0]['valorMes'];
    plan_ant.mensual[0] = this.planesFil[1]['valorMes'];
    plan_pre.mensual[0] = this.planesFil[2]['valorMes'];

    this.dataLayerService.dl_view_item_list( plan_per, plan_ant, plan_pre, 'COMPARACIÓN PLANES', periodo );
  }

}
