import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { SesionService } from 'src/app/core/services/sesion.service';
import { ScoreComponent } from '../../components/score/score.component';
import { BrokerService } from 'src/app/core/services/broker.service';
import { boxSimulator } from '../../core/models/resumenhc.model'
declare var $: any;

@Component({
  selector: 'app-simulador',
  templateUrl: './simulador.component.html',
  styleUrls: ['./simulador.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SimuladorComponent implements OnInit {
  obs:boolean[]=[false,false,false];
  simulacion:boolean[]=[false,false,false];
  txtTootip:string='';
  scenary?:any;
  loading:boolean=false;
  errorTxt:boolean=false;
  try:boolean=false;
  @ViewChild(ScoreComponent ) childScore!: ScoreComponent ;
  constructor(public sesion: SesionService,public broker: BrokerService) {
    if (this.sesion.simulator == undefined) {
      this.loading=true;
      this.sesion.infoHC(true)
          //this.getScenary();
    }
  }

  ngOnInit(): void {
    $('#modalSim').modal('show')
    $("#carouselExampleIndicators").hide();
  }

  openOb(id:number,flag:boolean){
    if(flag){
      this.obs[id]=true;
    }else{
      this.obs[id]=false;
    }
  }
  simular(scenary:string, index:number){
    let num = scenary.split("_")
    this.broker.getScoreSimulator(Number(num[1])).subscribe({
      next: (data: any) => {
    this.simulacion=[false,false,false];
    this.simulacion[index]=true;
    this.addAnimation(data);
      },
      error: (error) => {
      }
    });
  }
  addAnimation(data:any){
    data.forEach((ele:any) => {
      let gap = (((ele.score)/10)*180)/100;
      let move = gap-90;
      $(".rot_"+ele.numberMonths).css("transform","rotate("+move.toString()+"deg)");
    });
    //$('.rot').css('transform','rotate('+deg+'deg)');
  }
  moveBox(position:string){
    $("#ilow, #imedium, #ihigh").removeClass('active')
    $( "#i"+position).addClass('active')
    $( "#"+position)[0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  }

  reload(){
    this.simulacion=[false,false,false];
    $('.rot_1').css('transform','rotate(-90deg)');
    $('.rot_3').css('transform','rotate(-90deg)');
  }
  getScenary(){
    if(!this.try){
      this.try=true;
      this.broker.getScenarySimulator(this.sesion.simulator).subscribe({
        next:(res:any) =>{
          this.scenary = res;
          this.loading=false;
        },
        error: (res:any) =>{
          this.errorTxt=true;
          this.scenary=[];
        }
      });
    }
    return ""
  }
  hideSteps(){
    $("#steps").hide();
    $("#carouselExampleIndicators").show();
  }
}
