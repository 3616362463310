import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { SuscripcionService } from '../../core/services/suscripcion.service';
import { SesionService } from '../../core/services/sesion.service';
import { WebActivityService } from 'src/app/core/services/web-activity.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { BrokerService } from 'src/app/core/services/broker.service';
import { ConstantesService } from 'src/app/core/services/constantes.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { MetadatosService } from 'src/app/core/services/metadatos.service';

declare var $: any;

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})

export class InicioComponent implements OnInit {

  linkDc = environment.enlaceBuscaCredito;
  Bc = environment.buscacredito;
  linkLogin = environment.enlaceLogin;
  public allPlansBenefitsPerfil: any = [];
  public allPlansBenefitsProtege: any = [];
  public allPlansBenefitsPremium: any = [];
  public twoPartsPerfil: any = [];
  public twoPartsProtege: any = [];
  public twoPartsPremium: any = [];
  public typePlan: string = '1';//Tipo de plan Mensual = 1, Trimestral = 2, Anual = 4
  public testimonials:any[] = [];
  public sliders:any[] = [];
  public checkDivInicio = false;
  public checkDivInicioMobile = false;
  contenido_movil = false;

  constructor(
    private route: Router,
    public suscripcion: SuscripcionService,
    public sesion: SesionService,
    public webActivity: WebActivityService,
    public modal: ModalService,
    public elementRef: ElementRef,
    public constant: ConstantesService,
    public broker: BrokerService,
    public utilities: UtilitiesService,
    private dataLayerServices: DataLayersService,
    private metadatosService: MetadatosService
  ) {
    this.suscripcion.darkweb = false;
    this.allBenefits();
    this.contenido_movil = window.innerWidth <= 768;
  }

  // Obtener el tamaño de la página 
  @HostListener('window:resize', ['$event'])
  onResize(event: any){
    this.contenido_movil = event.target.innerWidth <= 768;
  }

  ngOnInit() {
    this.metadatosService.actualizando_canonical('https://www.midatacredito.com/');
    this.sesion.obtener_planes();
    $("#new-header").hide();

    if (this.sesion.home == 1) {
      this.route.navigate(['/pago']); // VALIDAR
    }
    this.validarFechaFuera();
  }
  checkBannerInicio(){
    if(!this.checkDivInicio){
      let tamano = [[970, 250]];
      this.utilities.addPubliById(environment.slotsBanners.home2, 'div-gpt-ad-1722271695890-0', tamano);
      this.checkDivInicio = true;
    }
    return ''
  }

  checkBannerInicioMobile(){
    if(!this.checkDivInicioMobile){
      let tamano = [[300, 250]];
      this.utilities.addPubliById(environment.slotsBanners.home2Mobile, 'div-gpt-ad-1722271937484-0', tamano); 
      this.checkDivInicioMobile = true;
    }
    return ''
  }

  mouseBannerOver(item: any) {
    let banner = item;
    //$(".oneB"+item).fadeOut("fast");
    $(".oneB" + item).css({ "display": "none" }, 2500);
    $(".oneDown" + item).css({ "display": "block" }, 2500);
    //$(".oneDown"+item).fadeIn("fast");
  }

  mouseBannerOut(item: any) {
    let banner = item;
    $(".oneDown" + item).css({ "display": "none" }, 2500);
    $(".oneB" + item).css({ "display": "block" }, 2500);
  }

  redirectExterno(url: string, nametag: string, idtag: string) {
    this.webActivity.sendDataAWS("click-" + nametag, idtag, true).then((res: any) => {
      this.sesion.getCookieServices('REDIRECT') ? window.location.href = url + '&redirect=' + this.sesion.getCookieServices('REDIRECT') : window.location.href = url;
    });
  }

  viewPrices(item: any) {
    this.sesion.facturacion = item;
  }

  seleccionarPlan(plan: any) {
    localStorage.setItem("ph", "1");
    this.suscripcion.planSeleccionado = plan.planGene;
    this.suscripcion.tipoPlan = plan.tipo;
    this.suscripcion.valorSeleccionadoMensual = plan.valorMes;
    this.suscripcion.valorSeleccionadoTotal = plan.valorTotal;

    this.dataLayerServices.dl_add_to_cart(plan.planGene, plan.valorTotal, plan.tipo, 'PLANES HOME');
 
    if (this.sesion.user) {
      this.suscripcion.planSesion(true, 'H');
    } else {
      this.suscripcion.planSesion(false, 'H');
    }
  }

  addManyChat() {
    let script = document.createElement('script');
    script.src = "//widget.manychat.com/1897847597147648.js";
    script.async = true;
    this.elementRef.nativeElement.appendChild(script);
  }

  validarFechaFuera() {
    this.sesion.getIntermittence().subscribe({
      next: (response: any) => {
        let dateG = this.sesion.intermittenceGeneral
        let date = new Date()
        let dateStart = new Date(dateG.yearStart, dateG.monthStart,dateG.dayStart, dateG.hourStart, dateG.minStart);
        let dateEnd = new Date(dateG.yearEnd, dateG.monthEnd,dateG.dayEnd,dateG.hourEnd, dateG.minEnd);
        if ((date.getTime() >= dateStart.getTime()) && (date.getTime() <= dateEnd.getTime())) {
          this.modal.modalIntermitencia();
        }
        this.testimonials = this.sesion.testimonials;
        this.sesion.sliders?this.sliders = this.sesion.sliders:this.sliders = [];
      }
    });
  }

  productLink(tipo: any) {
    switch (tipo) {
      case 1:
        return location.href = this.linkLogin;
      case 2:
        return window.open('https://buscacredito.midatacredito.com/', '_blank');
      case 3:
        return window.open('https://usuario.midatacredito.com/oneshot?product=os', '_blank');
      case 4:
        return window.open('https://www.midatacredito.com/verifica/#', '_blank');
      default:
        return '';
    }
  }

  validRange(ev: Event) {
    let value = parseInt((ev.currentTarget as HTMLInputElement).value);

    if (value >= 1 && value <= 33) {
      (ev.currentTarget as HTMLInputElement).value = '1';
      this.typePlan = '1';
    }
    if (value >= 33 && value <= 66 ) {
      (ev.currentTarget as HTMLInputElement).value = '50';
      this.typePlan = '2';
    }
    if (value >= 66 && value <=100) {
      (ev.currentTarget as HTMLInputElement).value = '100';
      this.typePlan = '4';
    }
    this.actualizar_dataLayer(this.typePlan);
  }

  actualizar_dataLayer(caso: string){
    let plan_per = this.constant.planes.mensual[0].planGene;
    let plan_ant = this.constant.planes.mensual[1].planGene;
    let plan_pre = this.constant.planes.mensual[2].planGene;
    let periodo = 'Mensual';
    
    if (caso == '1'){
      plan_per.mensual[0] = this.constant.planes.mensual[0].valorMes;
      plan_ant.mensual[0] = this.constant.planes.mensual[1].valorMes;
      plan_pre.mensual[0] = this.constant.planes.mensual[2].valorMes;
      periodo = 'Mensual';
    }
    if (caso == '2'){
      plan_per.mensual[0] = this.constant.planes.trimestral[0].valorMes;
      plan_ant.mensual[0] = this.constant.planes.trimestral[1].valorMes;
      plan_pre.mensual[0] = this.constant.planes.trimestral[2].valorMes;
      periodo = 'Trimestral';
    }
    if (caso == '3' || caso == '4'){
      plan_per.mensual[0] = this.constant.planes.anual[0].valorMes;
      plan_ant.mensual[0] = this.constant.planes.anual[1].valorMes;
      plan_pre.mensual[0] = this.constant.planes.anual[2].valorMes;
      periodo = 'Anual';
    }
    this.dataLayerServices.dl_view_item_list( plan_per, plan_ant, plan_pre, 'PLANES HOME', periodo );
  }

  allBenefits() {
    this.constant.planes.mensual[2].caracteristicas.forEach((element: any) => {
      this.allPlansBenefitsPremium.push({
        "benefit": element.txt,
        "icon": element.icon,
        "iconM": element.iconM,
        "alt": element.alt,
        "disabled": false
      });
    });
    this.constant.planes.mensual[1].caracteristicas.forEach((element: any) => {
      this.allPlansBenefitsProtege.push({
        "benefit": element.txt,
        "icon": element.icon,
        "iconM": element.iconM,
        "alt": element.alt,
        "disabled": false
      });
      this.allPlansBenefitsPremium.push({
        "benefit": element.txt,
        "icon": element.icon,
        "iconM": element.iconM,
        "alt": element.alt,
        "disabled": false
      });
    });
    this.constant.planes.mensual[0].caracteristicas.forEach((element: any) => {
      this.allPlansBenefitsPerfil.push({
        "benefit": element.txt,
        "icon": element.icon,
        "iconM": element.iconM,
        "alt": element.alt,
        "disabled": false
      });
      this.allPlansBenefitsProtege.push({
        "benefit": element.txt,
        "icon": element.icon,
        "iconM": element.iconM,
        "alt": element.alt,
        "disabled": false
      });
      this.allPlansBenefitsPremium.push({
        "benefit": element.txt,
        "icon": element.icon,
        "iconM": element.iconM,
        "alt": element.alt,
        "disabled": false
      });
    });
    this.twoPartsPerfil[0] = this.allPlansBenefitsPerfil.slice(0, 5);
    this.twoPartsPerfil[1] = this.allPlansBenefitsPerfil.slice(5);
    this.twoPartsProtege[0] = this.allPlansBenefitsProtege.slice(0, 5);
    this.twoPartsProtege[1] = this.allPlansBenefitsProtege.slice(5);
    this.twoPartsPremium[0] = this.allPlansBenefitsPremium.slice(0, 5);
    this.twoPartsPremium[1] = this.allPlansBenefitsPremium.slice(5);
  }

  selPlan(acron: string) {
    switch (this.typePlan) {
      case '1':
        return Object.assign({}, this.constant.planes.mensual.find((x: any) => x.plan == acron));
      case '2':
        return Object.assign({}, this.constant.planes.trimestral.find((x: any) => x.plan == acron));
      case '4':
        return Object.assign({}, this.constant.planes.anual.find((x: any) => x.plan == acron));
      case '5':
        return Object.assign({}, this.constant.planes.anual.find((x: any) => x.plan == acron));
      default:
        break;
    }
  }

  showBullets(id: string, e : Event) {
    let element = e.target as HTMLElement;
    if($(element).children('i').hasClass('fa-chevron-down')){
      $(".more-bullets > button > i").removeClass('fa-chevron-up');
      $(".more-bullets > button > i").addClass('fa-chevron-down');
      $(element).children('i').removeClass('fa-chevron-down');
      $(element).children('i').addClass('fa-chevron-up');
    }else{
      $(element).children('i').removeClass('fa-chevron-up');
      $(element).children('i').addClass('fa-chevron-down');
    }
    $(".box-plan-n.mobile .bullets-n").slideUp();
    if($("#"+id+"-bullets").css("display") == "none"){
      $("#"+id+"-bullets").slideDown();
    }else{
      $("#"+id+"-bullets").slideUp();
    }
  }

  validarEstrella(start:any, qualify:any){
    if(start <= qualify){
     return "orange";
   }else{
     return "disabled";
   }
 }
}
