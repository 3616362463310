<section class="credit-profile">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-7">
				<h1>La protección de datos personales es la mejor forma de asegurar tu futuro</h1>
				<div class="bullet-box">
					<div class="img-bullet">
						<img src="assets/img/landings/new/premium/Plan_Premium-1-Seguro.svg" alt="Seguro" />
					</div>
					<div class="content-bullet">
						<h4>Obtén un seguro en caso de fraude*</h4>
						<p>El seguro te cubre en caso de robo de identidad o tarjetas de crédito.</p>
					</div>
				</div>
				<div class="bullet-box">
					<div class="img-bullet">
						<img src="assets/img/landings/new/premium/Plan_Premium-2-Asesoria.svg" alt="Asesoría legal" />
					</div>
					<div class="content-bullet">
						<h4>Asesoría legal personalizada*</h4>
						<p>En caso de que seas víctima de fraude y requieras asesoría legal.</p>
					</div>
				</div>
				<a class="btn-midc btn-orange h-lg-btn w-50-btn d-none d-md-flex mh" id="planPreMensualLanding" webActivity data-idtag="Landings" data-nametag="planPreMensualLanding" (click)="seleccionarPlan(sesion.planes.planes?.pre, '1', sesion.planes.planes?.pre?.mensual?.[1], sesion.planes.planes?.pre?.mensual?.[0])">
					Adquirir protección premium <i class="fa fa-chevron-right" aria-hidden="true"></i>
				</a>
        <p class="note">* Tanto la póliza como la asesoría legal del Plan Premium, son servicios prestados por SBS Seguros Colombia S.A.</p>
			</div>
			<div class="col-12 col-md-5 align-self-center d-none d-md-block">
				<img src="assets/img/landings/new/premium/Plan_Premium.svg" alt="Plan Protege tus datos" />
			</div>
		</div>
	</div>
</section>

<section class="get-plan">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="box-get">
					<h3>Con este plan también obtienes</h3>
					<div class="bullets">
						<div class="column-bullet col-33">
              					<h3>Historia de crédito</h3>
							<div class="bullet expand" *ngFor="let item of sesion.planes.planes.freemium.itemsV2 | slice:0:4">
								<img src="assets/img/landings/new/Ico-Check.svg" alt="bullet" />
								<p>{{item.txt}}</p>
							</div>
							<div class="bullet">
								<ul>
									<li *ngFor="let item of sesion.planes.planes.freemium.itemsV2 | slice:4:8">{{item.txt}}</li>
								</ul>
							</div>						
						</div>
						<div class="column-bullet col-33">
              					<h3>Entrenador financiero</h3>
							<div class="bullet expand" *ngFor="let item of sesion.planes.planes.per.itemsV2">
								<img src="assets/img/landings/new/Ico-Check.svg" alt="bullet" />
								<p>{{item.txt}}</p>
							</div>
						</div>
            				<div class="column-bullet col-33">
              					<h3>Protección de datos</h3>
							<div class="bullet expand" *ngFor="let item of sesion.planes.planes.ant.itemsV2">
								<img src="assets/img/landings/new/Ico-Check.svg" alt="bullet" />
								<p>{{item.txt}}</p>
							</div>
						</div>
					</div>
				</div>
				<img src="assets/img/landings/new/protege/Plan_Protege.svg" alt="Plan Perfil de Crédito" class="img-mobile"/>
				<h4>Toma el control de tu información</h4>
				<p>Haz parte de las personas que hoy aseguran su futuro</p>
			</div>
		</div>
	</div>
</section>

<section class="plan">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-4 align-self-center">
				<div class="box-select">
					<form id="facturacion" name="facturacion" action="" method="">
						<div class="line-step"></div>
						<div *ngIf="sesion.facturacion == 1" class="contentInput inputActivo">
							<label for="pagMen">Mensual</label>
							<input type="radio" name="pagos" id="pagMen" value="1" (change)='viewPrices(1)' checked class="mdc-link-detail"/>
						</div>
						<div *ngIf="sesion.facturacion != 1" class="contentInput">
							<label for="pagMen">Mensual</label>
							<input type="radio" name="pagos" id="pagMen" value="1" (change)='viewPrices(1)' class="mdc-link-detail"/>
						</div>
						<div *ngIf="sesion.facturacion == 3" class="contentInput inputActivo">
							<label for="pagTri"><span>- {{ sesion.planes.planes?.pre?.trimestral?.[3] }} Dto. </span> Trimestral</label>
							<input type="radio" name="pagos" id="pagTri" value="3" (change)='viewPrices(3)' checked  class="mdc-link-detail"/> 
						</div>
						<div *ngIf="sesion.facturacion != 3" class="contentInput">
							<label for="pagTri"><span>- {{ sesion.planes.planes?.pre?.trimestral?.[3] }} Dto. </span> Trimestral</label> 
							<input type="radio" name="pagos" id="pagTri" value="3" (change)='viewPrices(3)'  class="mdc-link-detail"/>
						</div>
						<div *ngIf="sesion.facturacion == 12" class="contentInput inputActivo">
							<label for="pagAnu"><span>- {{ sesion.planes.planes?.pre?.anual?.[3] }} Dto. </span> Anual</label> 
							<input type="radio" name="pagos" id="pagAnu" value="12" (change)='viewPrices(12)' checked  class="mdc-link-detail"/>
						</div>
						<div *ngIf="sesion.facturacion != 12" class="contentInput">
							<label for="pagAnu"><span>- {{ sesion.planes.planes?.pre?.anual?.[3] }} Dto. </span> Anual</label> 
							<input type="radio" name="pagos" id="pagAnu" value="12" (change)='viewPrices(12)' class="mdc-link-detail" />
						</div>
					</form>
					<div class="planBox">
						<h2 *ngIf="sesion.facturacion == 1">${{ sesion.planes.planes?.pre?.mensual?.[1] }}</h2>
						<h2 *ngIf="sesion.facturacion == 3">${{ sesion.planes.planes?.pre?.trimestral?.[1] }}</h2>
						<h2 *ngIf="sesion.facturacion == 12">${{ sesion.planes.planes?.pre?.anual?.[1] }}</h2>
						<h5>Mensual</h5>
						<p *ngIf="sesion.facturacion == 1">{{ sesion.planes.planes?.pre?.mensual?.[2] }}</p>
						<p *ngIf="sesion.facturacion == 3">{{ sesion.planes.planes?.pre?.trimestral?.[2] }}</p>
						<p *ngIf="sesion.facturacion == 12">{{ sesion.planes.planes?.pre?.anual?.[2] }}</p>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-8 align-self-center">
				<div class="box-cta">
					<h3>No dejes nada al azar, monitorea y asegura hoy tus datos ante posibles fraudes.</h3>
					<p>Haz parte de los Colombianos que se preocupan por su realidad financiera y toma el control de tu vida crediticia.</p>
					<div class="bg-btn" *ngIf="sesion.facturacion == 1" >
						<a 
							class="btn-midc btn-orange h-lg-btn w-50-btn" 							
							id="planPreMensualLandingInf" webActivity data-idtag="Landings" data-nametag="planPreMensualLandingInf"
							(click)="seleccionarPlan(sesion.planes.planes?.pre, '1', sesion.planes.planes?.pre?.mensual?.[1], sesion.planes.planes?.pre?.mensual?.[0])">
							Adquirir protección premium
							<i class="fa fa-chevron-right" aria-hidden="true"></i>
						</a>
					</div>
					<div class="bg-btn" *ngIf="sesion.facturacion == 3" >
						<a 
							class="btn-midc btn-orange h-lg-btn w-50-btn" 							
							id="planPreTrimestralLandingInf" webActivity data-idtag="Landings" data-nametag="planPreTrimestralLandingInf"
							(click)="seleccionarPlan(sesion.planes.planes?.pre, '2', sesion.planes.planes?.pre?.trimestral?.[1], sesion.planes.planes?.pre?.trimestral?.[0])">
							Adquirir protección premium
							<i class="fa fa-chevron-right" aria-hidden="true"></i>
						</a>
					</div>
					<div class="bg-btn" *ngIf="sesion.facturacion == 12" >
						<a 
							class="btn-midc btn-orange h-lg-btn w-50-btn" 							
							id="planPreAnualLandingInf" webActivity data-idtag="Landings" data-nametag="planPreAnualLandingInf"
							(click)="seleccionarPlan(sesion.planes.planes?.pre, '4', sesion.planes.planes?.pre?.anual?.[1], sesion.planes.planes?.pre?.anual?.[0])">
							Adquirir protección premium
							<i class="fa fa-chevron-right" aria-hidden="true"></i>
						</a>
					</div>					
					<p>
						<a routerLink="/comparacion-planes">Compara todos nuestros planes</a>
					</p>
				</div>
			</div>
		</div>
	</div>
</section>