<section class="client-service">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-8 offset-md-2">
                <div class="title">
                    <div class="imgTitle">
                        <img src="assets/img/images/help.svg" class="img-head" alt="Servicio al cliente">
                    </div>
                    <div class="txtTitle">
                        <h1>Servicio al cliente</h1>
                    </div>
                </div>
                <p class="mdc-fs-3 mdc-text-gray mdc-text-service mt-4 text-justify text-lg-left mdc-text-sb2">
                    Midatacredito le ofrece a sus suscriptores distintos canales de
                    atención para resolver sus sugerencias, dudas e inquietudes. Te ayudamos en la utilización de todos nuestros servicios y también en la consulta de tu información.
                </p>
                <div *ngIf="sesion.estadoUserF == 'ACTIVO' || sesion.estadoUserF == 'ACTIVE_PROCESS'">
                    <h3 class="mdc-text-secondary mdc-fs-4 font-weight-bold text-center text-lg-left">Chat</h3>                 
                    <p class="mdc-text-service mt-3 mdc-fs-2 font-weight-bold text-center text-md-left">A través de nuestro servicio de chat podrás conectarte en línea con alguno de nuestros asesores para resolver tus inquietudes de forma inmediata.</p>
                </div>
                <div class="attention-chat">
                    <div class="imgChat">
                        <img src="assets/img/icons/wait.svg" class="img-clock" alt="horarios de atención al cliente">
                    </div>
                    <div class="txtChat">
                        <p>Conoce nuestros canales de atención:</p>
                        <p>Centro de Atención y Servicios: <span>Avenida Américas # 62 – 84, piso 2, Local 71-72, Bogotá </span></p>
                        <p>Email: <a href="mailto:servicioalciudadano@experian.com">servicioalciudadano@experian.com</a></p>
                        <p>Teléfono: <span><a href="tel:6017430606">6017430606</a></span></p>
                        <p><span>Lunes a viernes de 7:00AM a 7:00PM</span></p>
                        <p><span>Sábados de 8:00AM a 1:00PM</span></p><br>
                        <p>Solo para notificaciones judiciales:  <span>Calle 76 #7-35, Bogotá</span></p>
                    </div>
                    <div *ngIf="sesion.estadoUserF == 'ACTIVO' || sesion.estadoUserF == 'ACTIVE_PROCESS'" id="idChatDatacredito" (click)="svValidar()" webActivity data-idtag="SRVCCLNT" data-nametag="chatear-chateaConNosotros" class="text-white btn mdc-btn-primary btn-block mdc-text-b">
                        Chatea con Nosotros <i class="fa fa-chevron-right float-right mt-arrow"></i>
                    </div>
                    <div class="txtDatina" *ngIf="sesion.estadoUserF != 'ACTIVO' && sesion.estadoUserF != 'ACTIVE_PROCESS'">
                        <p>También puedes hacerle tu <b>consulta</b> a nuestra Asistente virtual <b>Datina.</b> <span class="d-none d-md-inline"> <br><br> Para hablar con ella, toca el icono en la parte inferior derecha.</span></p>
                        <img src="assets/img/icons/flecha_datina.svg" class="fDatina d-block d-md-none" alt="Datina atención al cliente">
                    </div>
                </div>                
                <div class="alert"  *ngIf="sesion.estadoUserF == 'ACTIVO' || sesion.estadoUserF == 'INPROCESS' || sesion.estadoUserF == 'ACTIVE_PROCESS'">
                    <div class="imgAlert">
                        <img src="assets/img/icons/important_info.svg" class="img-alert" alt="">
                    </div>
                    <div class="txtAlert">
                        <!-- <p>Si aceptaste la renovación automática y deseas cancelarla, podrás llamar a la línea telefónica en Bogotá 6017430606 o a nivel nacional 01 8000 947 030, en días laborales de lunes a viernes en el horario de 8:00 a.m a 5:00 p.m. con 5 días hábiles de anticipación a que caduque el término de vigencia contratado.</p> -->
                        <p>Si deseas contactarte con un agente o necesitas ayuda personalizada podrás llamar a la línea telefónica en Bogotá <a href="tel:6017430606">6017430606</a> o a nivel nacional 01 8000 947 030, en días laborales de lunes a viernes en el horario de 8:00 am a 5:00 pm.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="banner-container col-12" *ngIf="sesion.viewFree">
        <div id='div-gpt-ad-1715290469293-0' class="commercial-banner" [ngClass]="checkBanner()"></div>
    </div>
</section>