<section class="credit-profile">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-7">
				<h1>Consulta, revisa y administra tu puntaje de datacrédito</h1>
				<div class="bullet-box">
					<div class="img-bullet">
						<img src="assets/img/landings/new/perfil-credito/Plan_Perfil-1-Puntaje.svg" alt="Puntaje" />
					</div>
					<div class="content-bullet">
						<h4>Obtén tu puntaje de Datacrédito</h4>
						<p>Un buen puntaje te acerca más a tu próximo crédito</p>
					</div>
				</div>
				<div class="bullet-box">
					<div class="img-bullet">
						<img src="assets/img/landings/new/perfil-credito/Plan_Perfil-2-Diagnostico.svg" alt="Diagnóstico" />
					</div>
					<div class="content-bullet">
						<h4>Conoce tu diagnóstico</h4>
						<p>Asesor financiero virtual para mejorar tu puntaje y acceso al crédito.</p>
					</div>
				</div>
				<div class="bullet-box">
					<div class="img-bullet">
						<img src="assets/img/landings/new/perfil-credito/Plan_Perfil-3-Alertas.svg" alt="Alertas" />
					</div>
					<div class="content-bullet">
						<h4>Recibe alertas antifraude</h4>
						<p>Te notificamos cada ves que alguna entidad haga una consulta a tu historial crediticio.</p>
					</div>
				</div>
				<a class="btn-midc btn-orange h-lg-btn w-40-btn d-none d-md-flex" id="planPerMensualLanding" webActivity data-idtag="Landings" data-nametag="planPerMensualLanding" (click)="seleccionarPlan(sesion.planes.planes?.per, '1', sesion.planes.planes?.per?.mensual?.[1], sesion.planes.planes?.per?.mensual?.[0])">
					Mejorar mi perfil <i class="fa fa-chevron-right" aria-hidden="true"></i>
				</a>
			</div>
			<div class="col-12 col-md-5 align-self-center d-none d-md-block">
				<img src="assets/img/landings/new/perfil-credito/Plan_Perfil.svg" alt="Plan Perfil de Crédito" />
			</div>
		</div>
	</div>
</section>

<section class="get-plan">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="box-get">
					<h3>Con este plan también obtienes</h3>
					<div class="bullets">
						<div class="column-bullet">
							<div class="bullet" *ngFor="let item of sesion.planes.planes.freemium.itemsV2 | slice:0:3">
								<img src="assets/img/landings/new/Ico-Check.svg" alt="bullet" />
								<p>{{item.txt}}</p>
							</div>							
						</div>
						<div class="column-bullet">
							<div class="bullet" *ngFor="let item of sesion.planes.planes.freemium.itemsV2 | slice:3:4">
								<img src="assets/img/landings/new/Ico-Check.svg" alt="bullet" />
								<p>{{item.txt}}</p>
							</div>
							<div class="bullet">
								<ul>
									<li *ngFor="let item of sesion.planes.planes.freemium.itemsV2 | slice:4:8">{{item.txt}}</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<img src="assets/img/landings/new/perfil-credito/Plan_Perfil.svg" alt="Plan Perfil de Crédito" class="img-mobile"/>
				<h4>Tu entrenador financiero</h4>
				<p>Te ayudamos a mejorar tu puntaje, para que las entidades financieras puedan ofrecerte sus productos, como créditos, tarjetas de crédito, entre otros.</p>
			</div>
		</div>
	</div>
</section>

<section class="plan">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-4 align-self-center">
				<div class="box-select">
					<form id="facturacion" name="facturacion" action="" method="">
						<div class="line-step"></div>
						<div *ngIf="sesion.facturacion == 1" class="contentInput inputActivo">
							<label for="pagMen">Mensual</label>
							<input type="radio" name="pagos" id="pagMen" value="1" (change)='viewPrices(1)' checked class="mdc-link-detail"/>
						</div>
						<div *ngIf="sesion.facturacion != 1" class="contentInput">
							<label for="pagMen">Mensual</label>
							<input type="radio" name="pagos" id="pagMen" value="1" (change)='viewPrices(1)' class="mdc-link-detail"/>
						</div>
						<div *ngIf="sesion.facturacion == 3" class="contentInput inputActivo">
							<label for="pagTri"><span>- {{ sesion.planes.planes?.per?.trimestral?.[3] }} Dto. </span> Trimestral</label>
							<input type="radio" name="pagos" id="pagTri" value="3" (change)='viewPrices(3)' checked  class="mdc-link-detail"/> 
						</div>
						<div *ngIf="sesion.facturacion != 3" class="contentInput">
							<label for="pagTri"><span>- {{ sesion.planes.planes?.per?.trimestral?.[3] }} Dto. </span> Trimestral</label> 
							<input type="radio" name="pagos" id="pagTri" value="3" (change)='viewPrices(3)'  class="mdc-link-detail"/>
						</div>
						<div *ngIf="sesion.facturacion == 12" class="contentInput inputActivo">
							<label for="pagAnu"><span>- {{ sesion.planes.planes?.per?.anual?.[3] }} Dto. </span> Anual</label> 
							<input type="radio" name="pagos" id="pagAnu" value="12" (change)='viewPrices(12)' checked  class="mdc-link-detail"/>
						</div>
						<div *ngIf="sesion.facturacion != 12" class="contentInput">
							<label for="pagAnu"><span>- {{ sesion.planes.planes?.per?.anual?.[3] }} Dto. </span> Anual</label> 
							<input type="radio" name="pagos" id="pagAnu" value="12" (change)='viewPrices(12)' class="mdc-link-detail" />
						</div>
					</form>
					<div class="planBox">
						<h2 *ngIf="sesion.facturacion == 1">${{ sesion.planes.planes?.per?.mensual?.[1] }}</h2>
						<h2 *ngIf="sesion.facturacion == 3">${{ sesion.planes.planes?.per?.trimestral?.[1] }}</h2>
						<h2 *ngIf="sesion.facturacion == 12">${{ sesion.planes.planes?.per?.anual?.[1] }}</h2>
						<h5>Mensual</h5>
						<p *ngIf="sesion.facturacion == 1">{{ sesion.planes.planes?.per?.mensual?.[2] }}</p>
						<p *ngIf="sesion.facturacion == 3">{{ sesion.planes.planes?.per?.trimestral?.[2] }}</p>
						<p *ngIf="sesion.facturacion == 12">{{ sesion.planes.planes?.per?.anual?.[2] }}</p>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-8 align-self-center">
				<div class="box-cta">
					<h3>Te enseñamos a usar los factores que impactan tu puntaje para que tengas un mejor perfil de crédito</h3>
					<p>Haz parte de los Colombianos que se preocupan por su realidad financiera y toma el control de tu vida crediticia</p>
					<div class="bg-btn" *ngIf="sesion.facturacion == 1" >
						<a 
							class="btn-midc btn-orange h-lg-btn w-40-btn" 							
							id="planPerMensualLandingInf" webActivity data-idtag="Landings" data-nametag="planPerMensualLandingInf"
							(click)="seleccionarPlan(sesion.planes.planes?.per, '1', sesion.planes.planes?.per?.mensual?.[1], sesion.planes.planes?.per?.mensual?.[0])">
							Mejorar mi perfil
							<i class="fa fa-chevron-right" aria-hidden="true"></i>
						</a>
					</div>
					<div class="bg-btn" *ngIf="sesion.facturacion == 3" >
						<a 
							class="btn-midc btn-orange h-lg-btn w-40-btn" 							
							id="planPerTrimestralLandingInf" webActivity data-idtag="Landings" data-nametag="planPerTrimestralLandingInf"
							(click)="seleccionarPlan(sesion.planes.planes?.per, '2', sesion.planes.planes?.per?.trimestral?.[1], sesion.planes.planes?.per?.trimestral?.[0])">
							Mejorar mi perfil
							<i class="fa fa-chevron-right" aria-hidden="true"></i>
						</a>
					</div>
					<div class="bg-btn" *ngIf="sesion.facturacion == 12" >
						<a 
							class="btn-midc btn-orange h-lg-btn w-40-btn" 							
							id="planPerAnualLandingInf" webActivity data-idtag="Landings" data-nametag="planPerAnualLandingInf"
							(click)="seleccionarPlan(sesion.planes.planes?.per, '5', sesion.planes.planes?.per?.anual?.[1], sesion.planes.planes?.per?.anual?.[0])">
							Mejorar mi perfil
							<i class="fa fa-chevron-right" aria-hidden="true"></i>
						</a>
					</div>					
					<p>
						<a routerLink="/comparacion-planes">Compara todos nuestros planes</a>
					</p>
				</div>
			</div>
		</div>
	</div>
</section>