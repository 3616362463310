<div id="modulo-bc" class="box-bc genericBox">
    <div class="header-bc">
        <h2>Buscacrédito, conoce GRATIS</h2>
        <p>ofertas de productos financieros.</p>
    </div>
    <div class="content-bc">
        <!--Carrusel de 2 ofertas maximo por slide para desktop-->
        <div id="carousel-bc" class="carousel d-none d-md-block" data-ride="carousel" data-interval="false">
            <div class="carousel-inner">
                <div class="carousel-item" [ngClass]="{'active': i==0}" *ngFor="let offers of offersBy2;let i=index">
                    <div class="box-carousel">
                        <app-box-offer *ngFor="let offer of offers;" [offer]="offer"></app-box-offer>
                    </div>
                </div>
            </div>
            <ul class="carousel-indicators circles" *ngIf="offersBy2.length > 1">
                <li data-target="#carousel-bc" class="btnCircle" [ngClass]="{'active': i==0}" (click)="addClass($event,'active')"
                    *ngFor="let offers of offersBy2;let i=index" [attr.data-slide-to]="i"><i class="fa fa-circle"></i>
                </li>
            </ul>
            <a class="carousel-control-prev spaceBtn" href="#carousel-bc" role="button" data-slide="prev"
                *ngIf="offersBy2.length > 1">
                <i class="fa fa-angle-left"></i>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next spaceBtn" href="#carousel-bc" role="button" data-slide="next"
                *ngIf="offersBy2.length > 1">
                <i class="fa fa-angle-right"></i>
                <span class="sr-only">Next</span>
            </a>
        </div>
        <!--Carrusel de 2 ofertas maximo por slide para desktop-->
        <!--Carrusel de oferta por slide para mobile-->
        <div id="carousel-bc-m" class="carousel d-block d-md-none" data-ride="carousel" data-interval="false">
            <div class="carousel-inner">
                <div class="carousel-item" [ngClass]="{'active': i==0}" *ngFor="let offer of offers;let i=index">
                    <div class="box-carousel">
                        <app-box-offer [offer]="offer"></app-box-offer>
                    </div>
                </div>
            </div>
            <ul class="carousel-indicators circles" *ngIf="offers.length > 1">
                <li data-target="#carousel-bc-m" class="btnCircle" [ngClass]="{'active': i==0}" (click)="addClass($event,'active')"
                    *ngFor="let offer of offers;let i=index" [attr.data-slide-to]="i"><i class="fa fa-circle"></i></li>
            </ul>
            <a class="carousel-control-prev spaceBtn" href="#carousel-bc-m" role="button" data-slide="prev"
                *ngIf="offers.length > 1">
                <i class="fa fa-angle-left"></i>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next spaceBtn" href="#carousel-bc-m" role="button" data-slide="next"
                *ngIf="offers.length > 1">
                <i class="fa fa-angle-right"></i>
                <span class="sr-only">Next</span>
            </a>
        </div>
        <!--Carrusel de oferta por slide para mobile-->
        <div>
            <p class="disclaimer">Conoce aquí las ofertas que más se ajustan a tu perfil financiero y crediticio:</p>
        </div>
        <div>
            <button (click)="goToBC()" class="btnGeneric green">Ver ofertas a mi medida<i class="fa fa-chevron-right"></i></button>
        </div>
    </div>
</div>