<div class="main-notif">
  <div class="titAlerts">
    <div class="icon">
      <img src="../../../../assets/img/notifications/comunicaciones.png" alt="Administrar notificaciones."
        title="Administrar notificaciones.">
      <h2 class="d-block d-md-none">MONITOREO DEL PUNTAJE DE CRÉDITO</h2>
    </div>
    <div class="title">
      <h2 class="d-none d-md-block">MONITOREO DEL PUNTAJE DE CRÉDITO</h2>
      <p>Este tipo de comunicaciones te ayudan a <u>mantenerte informado</u> sobre <b>todos los cambios</b> que pueden ocurrir en tu historia de crédito, potenciando la <u>prevención de fraude</u> y el <u>monitoreo</u> de tu puntaje de crédito.</p>
    </div>
  </div>
  <div class="content-notif">
    <div class="col-12" *ngIf="sesion.alertMongo && sesion.errorAlertMongo">
      <app-micro-loader [error]="sesion.errorAlertMongo">
      </app-micro-loader>
    </div>
    <div class="alert active " id="exito">
      <img src="assets/img/huellas-consulta/Alerta_huellas.svg" alt="alerta de huellas de consulta">
      <p>Tus comunicaciones fueron actualizadas con éxito.</p>
    </div>
    <div class="box-notification" *ngIf="sesion.estadoCelular && sesion.alertMongo">
      <div class="boxEmail">
        <p class="txt">Este es el <b>correo</b> al que llegarán las comunicaciones</p>
        <p class="email">{{sesion.user.correo}}</p>
      </div>
      <div class="box-swich">
        <p class="pSwitch notNine">{{sesion.notificacionesActivas[7].description}}</p>
        <label class="switch"><input type="checkbox" [(ngModel)]="sesion.notificacionesActivas[7].checked" (change)="activateBtn(false)"><span class="slider"></span></label>
      </div>
      <button *ngIf="btnGuardar[0]" class="guardar" (click)="saveNotificacions()">Guardar cambios</button>
    </div>
    <div class="box-changes" *ngIf="sesion.estadoCelular && sesion.alertMongo">
      <div class="content-changes">
        <div class="head-sub-changes">
          <button (click)="openAcordeon(2)">Comunicaciones sobre tus datos personales<i
              class="fa fa-angle-right"></i></button>
        </div>
        <div class="content-sub-changes" id="tab-2">
          <div class="box-swich">
            <p class="pSwitch">{{ sesion.notificacionesActivas[0].description }}</p>
            <label class="switch"><input type="checkbox" [(ngModel)]="sesion.notificacionesActivas[0].checked" (change)="activateBtn(true)"><span
                class="slider"></span></label>
          </div>
          <div class="box-swich">
            <p class="pSwitch">{{ sesion.notificacionesActivas[1].description }}</p>
            <label class="switch"><input type="checkbox" [(ngModel)]="sesion.notificacionesActivas[1].checked" (change)="activateBtn(true)"><span
                class="slider"></span></label>
          </div>
        </div>
        <div class="head-sub-changes">
          <button (click)="openAcordeon(3)">Comunicaciones sobre cambios en tu historia de crédito<i
              class="fa fa-angle-right"></i></button>
        </div>
        <div class="content-sub-changes" id="tab-3">
          <div class="box-swich">
            <p class="pSwitch">{{ sesion.notificacionesActivas[2].description }}</p>
            <label class="switch"><input type="checkbox" [(ngModel)]="sesion.notificacionesActivas[2].checked" (change)="activateBtn(true)"><span
                class="slider"></span></label>
          </div>
          <div class="box-swich">
            <p class="pSwitch">{{ sesion.notificacionesActivas[3].description }}</p>
            <label class="switch"><input type="checkbox" [(ngModel)]="sesion.notificacionesActivas[3].checked" (change)="activateBtn(true)"><span
                class="slider"></span></label>
          </div>
          <div class="box-swich">
            <p class="pSwitch">{{ sesion.notificacionesActivas[6].description }}</p>
            <label class="switch"><input type="checkbox" [(ngModel)]="sesion.notificacionesActivas[6].checked" (change)="activateBtn(true)"><span
                class="slider"></span></label>
          </div>
          <div class="box-swich">
            <p class="pSwitch">{{ sesion.notificacionesActivas[9].description }}</p>
            <label class="switch"><input type="checkbox" [(ngModel)]="sesion.notificacionesActivas[9].checked" (change)="activateBtn(true)"><span
                class="slider"></span></label>
          </div>
          <div class="box-swich">
            <p class="pSwitch">{{ sesion.notificacionesActivas[8].description }}</p>
            <label class="switch"><input type="checkbox" [(ngModel)]="sesion.notificacionesActivas[8].checked" (change)="activateBtn(true)"><span
                class="slider"></span></label>
          </div>
        </div>
        <div class="head-sub-changes">
          <button (click)="openAcordeon(4)">Comunicaciones sobre tus reclamaciones<i
              class="fa fa-angle-right"></i></button>
        </div>
        <div class="content-sub-changes" id="tab-4">
          <div class="box-swich">
            <p class="pSwitch">{{ sesion.notificacionesActivas[4].description }}</p>
            <label class="switch"><input type="checkbox" [(ngModel)]="sesion.notificacionesActivas[4].checked" (change)="activateBtn(true)"><span
                class="slider"></span></label>
          </div>
          <div class="box-swich">
            <p class="pSwitch">{{ sesion.notificacionesActivas[5].description }}</p>
            <label class="switch"><input type="checkbox" [(ngModel)]="sesion.notificacionesActivas[5].checked" (change)="activateBtn(true)"><span
                class="slider"></span></label>
          </div>
        </div>
        <div class="head-sub-changes" *ngIf="sesion.proteccion || sesion.poliza">
          <button (click)="openAcordeon(5)">Comunicaciones sobre tus datos comprometidos en la red<i
              class="fa fa-angle-right"></i></button>
        </div>
        <div class="content-sub-changes" id="tab-5">
          <div class="box-swich">
            <p class="pSwitch">{{ sesion.notificacionesActivas[10].description }}</p>
            <label class="switch"><input type="checkbox" [(ngModel)]="sesion.notificacionesActivas[10].checked" (change)="activateBtn(true)"><span
                class="slider"></span></label>
          </div>
        </div>
      </div>
      <button *ngIf="btnGuardar[1]" class="guardar" (click)="saveNotificacions()">Guardar cambios</button>
    </div>
    <div class="warnning">
      <img src="assets/img/huellas-consulta/Alerta_huellas.svg" alt="alerta de huellas de consulta" />
      <div><p>El reporte y envío de las comunicaciones dependerán del reporte de una nueva
        obligación o cuenta en tu historia de crédito por parte de la respectiva
        entidad</p>
        <p>Si no deseas recibir comunicaciones en tu correo electrónico registrado en <b>www.midatacredito.com</b> desactiva las comunicaciones en los <b>switches</b> de cada una.</p></div>
    </div>
  </div>
</div>