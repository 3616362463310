<section class="tc">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <a class="back" (click)="inactiveTabF()">
                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    Métodos de pago
                </a>
                <h2 class="gift-card">Tarjeta de regalo</h2>
                <div class="box-info">
                    <form #datos="ngForm" (ngSubmit)="activarGiftCard(datos)" autocomplete="off">
                        <div class="box-step">
                            <h3>Ingresa código promocional</h3>
                            <div class="row d-md-none d-block">
                                <div class="col-12">
                                    <app-sidebar [data]="sesion"></app-sidebar>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <mat-form-field>
                                        <input matInput type="text" name="codPromo" id="codPromo" class="form-control"
                                            minlength="6" maxlength="9" value="" required="required"
                                            pattern="^[A-Za-z0-9]+$" [(ngModel)]="giftCardCode" #giftcard="ngModel"
                                            [ngClass]="{'is-valid': giftcard.valid, 'is-invalid': ( giftcard.errors?.['required'] && giftcard.touched ) }"
                                            placeholder="Ingresa el código de tu Gift Card">
                                    </mat-form-field>
                                    <mat-error *ngIf="giftcard?.errors?.['required']" class="invalid-feedback">Campo
                                        obligatorio.</mat-error>
                                    <mat-error *ngIf="giftcard?.errors?.['pattern']" class="invalid-feedback">Código no
                                        válido. Solo letras y números, no caracteres especiales, espacios, ni
                                        tildes.</mat-error>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <p class="description">Si cuentas con un número de Gift Card, puedes empezar a
                                        disfrutar de los beneficios de Midatacredito totalmente gratis y sin registrar
                                        Tarjeta de Crédito.</p>
                                </div>
                            </div>
                            <div class="form-group col-12 px-0 mb-2 mb-md-4" *ngIf="acceptAdvertising">
                                <div class="custom-control custom-checkbox">
                                    <label class="check-midc" for="labelAdvert">
                                        <input type="checkbox" class="" id="aceptAdvert1" name="aceptAdvert"
                                            [(ngModel)]="isChecked" #aceptAdvert="ngModel"
                                            (change)="!isChecked = isChecked ">
                                        <p>Acepto ser contactado y recibir información comercial y de mercadeo de
                                            productos y servicios de Experian Colombia de acuerdo con esta
                                            autorización.<a href="{{url_autorizacion_opcional}}" target="_blank">Ver
                                                autorización.</a></p>
                                    </label>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-12">
                                    <button [attr.disabled]="datos.valid ? null : 'disabled'"
                                        [ngClass]="{'disabled': !datos.valid }" name="pagoGC" id="pagoGC" webActivity
                                        data-idtag="Pagos" data-nametag="validarGC"
                                        class="btn-midc btn-orange2 w-50-btn">
                                        Realizar pago
                                        <i class="fa fa-chevron-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>