import { Component, OnInit } from '@angular/core';
import { SesionService } from 'src/app/core/services/sesion.service';
declare var $ : any;

@Component({
  selector: 'app-endeudamiento',
  templateUrl: './endeudamiento.component.html',
  styleUrls: ['./endeudamiento.component.scss']
})
export class EndeudamientoComponent implements OnInit {

  constructor(public sesion: SesionService) { }

  ngOnInit() {
  }
  collpaseAll(i:any){
		$('#tableBody'+i).collapse('toggle');
		$('#arrow'+i).toggleClass('rotate0');		
	}
}
