import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-terminos-condiciones',
  templateUrl: './terminos-condiciones.component.html',
  styleUrls: ['./terminos-condiciones.component.scss']
})
export class TerminosCondicionesComponent implements OnInit {
  
  routeS3 = environment.enlaceS3;

  constructor() {
    window.location.href = environment.enlaceS3 + environment.s3.terminosYCondiciones;
  }

  ngOnInit() {
  }

}
