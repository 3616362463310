
<div class="prints genericBox">
    <img class="iconG" src="https://d1gj9peb9ac569.cloudfront.net/midc/icons/white/HuellaConsulta.png" alt="huellas">
    <p class="titleG ">Huellas de <br> consulta <br><span>a tu Historia de Crédito.</span></p>
    <div class="cell" *ngFor="let huella of huellas; let i = index">
            <div class="consult">
                <p>{{ huella.entidad }}</p>
                <p>{{ huella.fecha | date: 'dd MMMM yyyy' }}</p>
            </div>
            <div class="tooltip-consult">
                <div class="tooltip1 top">
                    <i class="fa fa-exclamation-circle" aria-hidden="true" id="clicOut" (mouseover)="getEntity(huella.entidad)"></i>
                    <span class="tiptext" >{{txtTootip}}</span>									
                </div>
            </div>
            <div class="number-consult">
                <p>{{ huella.cantidad | number }}</p>
            </div>
    </div>
    <button class="btnGeneric my-2" (click)="redirect_and_send_datalayer()">Ver todas las huellas</button>
</div>
