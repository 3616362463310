import { Component, OnInit } from '@angular/core';
import { SesionService } from 'src/app/core/services/sesion.service';
import {  Router } from '@angular/router';
import { RoutingService } from 'src/app/core/services/routing.service';
import { AnyARecord } from 'dns';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
declare var $: any;

interface diagnostic {
  endeudamiento: {
    estado: string;
    cals: number[];
  };
  estadoPortafolio: {
    estado: string;
    cals: number[];
  };
  experienciaCrediticia: {
    estado: string;
    cals: number[];
  };
  habitoPago: {
    estado: string;
    cals: number[];
  };
}

@Component({
  selector: 'app-dash-score',
  templateUrl: './dash-score.component.html',
  styleUrls: ['./dash-score.component.scss']
})
export class DashScoreComponent implements OnInit {
	public textsScore = [
		{ title: '¡Tu puntaje debería mejorar!', button: '¿Cómo puedo mejorar mi puntaje?', desc: 'Tu puntaje de crédito está por debajo del promedio de los colombianos.', scoreMin: 0, scoreMax: 310,scoreMinA: 0, scoreMaxA: 345  },
		{ title: '¡Tu puntaje podría ser mejor!', button: '¿Cómo puedo mejorar mi puntaje?', desc: 'Tu puntaje de crédito está cerca de llegar al promedio de los colombianos.', scoreMin: 311, scoreMax: 470,scoreMinA: 346, scoreMaxA: 470  },
		{ title: '¡Tu puntaje es promedio!', button: 'Ver qué impacta mi puntaje', desc: 'Tu puntaje de crédito está en el promedio de los colombianos.', scoreMin: 471, scoreMax: 630,scoreMinA: 471, scoreMaxA: 670  },
		{ title: '¡Puedes estar tranquilo!', button: 'Ver qué impacta mi puntaje', desc: 'Tu puntaje de crédito está por encima del promedio de los colombianos.', scoreMin: 631, scoreMax: 790,scoreMinA: 671, scoreMaxA: 815  },
		{ title: '¡Que buen puntaje!', button: 'Ver qué impacta mi puntaje', desc: 'Tu puntaje de crédito está por encima del promedio de los colombianos.', scoreMin: 791, scoreMax: 2000,scoreMinA: 816, scoreMaxA: 2000  },
	];
	public titleScore = "";
	public descScore = "";
  diagnostic!:diagnostic;

  constructor(public sesion: SesionService,public router: Router,public routing: RoutingService, private dataLayer: DataLayersService) {
    this.diagnostic = {
      endeudamiento:{
        estado:'E',
        cals:[]
      },
      estadoPortafolio:{
        estado:'E',
        cals:[]
      },
      experienciaCrediticia:{
        estado:'E',
        cals:[]
      },
      habitoPago:{
        estado:'E',
        cals:[]
      }
    }
   }

  ngOnInit(): void {
    this.setDiagnostic();
  }

  showTextScore() {
    if (this.sesion.numberScore) {
      let auxScore;
      !this.sesion.dataUser.params[13].value? auxScore = this.textsScore.find(ele => this.sesion.miscoreInt >= ele.scoreMin && this.sesion.miscoreInt <= ele.scoreMax): auxScore = this.textsScore.find(ele => this.sesion.miscoreInt >= ele.scoreMinA && this.sesion.miscoreInt <= ele.scoreMaxA);
      if (auxScore != undefined) {
        this.titleScore = auxScore.title;
        this.descScore = auxScore.desc;
      }
    }
    return true;
  }
  selImg(cal:any){
    var calificacion:any = {
      2 : 'Icono_diagnostico_regular.svg',
      3 : 'Icono_diagnostico_bueno.svg',
      1 : 'Icono_diagnostico_malo.svg',
    }
    return calificacion[cal];
  }
	selCal(cal:any){
		var calificacion:any = {
		  'B' : ['good',2],
		  'E' : ['excelent',3],
		  'R' : ['bad',1],
		  'N/E' : ''
		}
		return calificacion[cal];
	}
  selTxt(cal:any){
		var calificacion:any = {
		  2 : 'Bueno',
		  3 : 'Excelente',
		  1 : 'Regular',
		  'N/E' : ''
		}
		return calificacion[cal];
	}
  average(cals:number[]){
    let av;
    av = (cals.reduce((a, b) => a + b, 0))/cals.length;
    return Math.round(Math.round(av));
  }

  setDiagnostic(){
    
    this.sesion.diagnostico.endeudamiento.calificaciones.forEach((ele:any) => {
      if(ele.calificacionLetra!='N/E'){this.diagnostic.endeudamiento.cals.push(this.selCal(ele.calificacionLetra)[1])}
    });
    this.sesion.diagnostico.estadoPortafolio.calificaciones.forEach((ele:any) => {
      if(ele.calificacionLetra!='N/E'){this.diagnostic.estadoPortafolio.cals.push(this.selCal(ele.calificacionLetra)[1])}
    });
    this.sesion.diagnostico.experienciaCrediticia.calificaciones.forEach((ele:any) => {
      if(ele.calificacionLetra!='N/E'){this.diagnostic.experienciaCrediticia.cals.push(this.selCal(ele.calificacionLetra)[1])}
    });
    this.sesion.diagnostico.habitoPago.calificaciones.forEach((ele:any) => {
      if(ele.calificacionLetra!='N/E'){this.diagnostic.habitoPago.cals.push(this.selCal(ele.calificacionLetra)[1])}
    });
  }

  // Compra de plan y envio de data layer
  redirectPlan_and_send_datalayer(btn_name: string){
    if (btn_name == 'Ver diagnóstico completo'){
      this.dataLayer.dl_eventoGA4_menu_dashboard(btn_name, 'Midatacrédito');
      this.routing.redirectAllAccess(0,'/diagnostico');
    } 
    if (btn_name == 'Comprar plan'){
      this.dataLayer.dl_add_to_cart(this.sesion.planes.planes.per, this.sesion.planes.planes.per.mensual[0], 'Mensual', 'DASHBOARD');
    }    
  }

  getTip(fl:boolean){
    if(fl){
      $('#contenTooltip').css("visibility", "visible")
    }else{
      $('#contenTooltip').css('visibility', 'hidden')
    }
  }

}
