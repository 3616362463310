<div class="modal fade" id="ModalSuscripcion" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mdc-modal-dialog" role="document">
      <div class="modal-content px-5 mdc-br">
          <div class="modal-body mb-5">
              <div class="row text-center">
                  <div class="col-12 ">
                      <button type="button" class="close mdc-close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                      <p class="titleH2 mdc-text-white mt-5 mb-3 mdc-text-b" [innerHTML]="suscripcion.planSeleccionado?.popup"></p>
                      <hr class="mdc-hr-white m-auto">
                  </div>
              </div>
              <div class="row text-center">
                  <div class="col-12 offset-0 col-md-4 offset-md-0 mt-md-0 mt-5 mt-md-5">
                      <div class="md-info p-2">
                          <p class="titleH4 mdc-text-white mdc-text-b">Mensual <small id="idPremiumGenMes"></small></p>
                          <p class="titleH5 mdc-text-white mdc-text-b my-3"> <span id="idPremiumGenMesOLM">$ {{ suscripcion.planSeleccionado?.mensual[0] }} </span> </p>
                          <p class="titleH3 mdc-text-white mdc-text-b mdc-line-height"> <span id="idPremiumGenMesOLM">$ {{ suscripcion.planSeleccionado?.mensual[1] }} </span> <br> <small class="mdc-fs-2 mdc-text-b">mes</small></p>
                          <a>
                              <button (click)="seleccionarPlan('1', suscripcion.planSeleccionado?.mensual[1], suscripcion.planSeleccionado?.mensual[0])" type="button" class="btn btn-block mdc-btn-alter mdc-btn-rd mdc-text-4 mt-3">Suscr&iacute;bete Ya</button>
                          </a>
                      </div>
                  </div>
                  <div class="col-12 offset-0 col-md-4 offset-md-0 mt-md-0 mt-2 mt-md-5">
                      <div class="md-info p-2">
                          <p class="titleH4 mdc-text-white mdc-text-b">Trimestral <br> <small id="idPremiumGenTri"></small></p>
                          <p class="titleH5 mdc-text-white mdc-text-b my-3"> <span id="idPremiumGenTriOLM">$ {{ suscripcion.planSeleccionado?.trimestral[0] }}</span> </p>
                          <p class="titleH3 mdc-text-white mdc-text-b mdc-line-height"> <span id="idPremiumGenTriOLM">$ {{ suscripcion.planSeleccionado?.trimestral[1] }}</span> <br> <small class="mdc-fs-2 mdc-text-b">mes</small></p>
                          <a>
                              <button (click)="seleccionarPlan('2', suscripcion.planSeleccionado?.trimestral[1], suscripcion.planSeleccionado?.trimestral[0])" type="button" class="btn btn-block mdc-btn-alter mdc-btn-rd mdc-text-4 mt-3">Suscr&iacute;bete Ya</button>
                          </a>
                      </div>
                  </div>
                  <div class="col-12 offset-0 col-md-4 offset-md-0 mt-md-0 mt-2 mt-md-5">
                      <div class="md-info p-2">
                          <p class="titleH4 mdc-text-white mdc-text-b">Anual <br> <small id="idPremiumGenAnual"></small></p>
                          <p class="titleH5 mdc-text-white mdc-text-b my-3"> <span id="idPremiumGenAnualOLM">$ {{ suscripcion.planSeleccionado?.anual[0] }}</span></p>
                          <p class="titleH3 mdc-text-white mdc-text-b mdc-line-height"> <span id="idPremiumGenAnualOLM">$ {{ suscripcion.planSeleccionado?.anual[1] }}</span><br> <small class="mdc-fs-2 mdc-text-b">mes</small></p>                            
                          <a>
                              <button (click)="seleccionarPlan('3', suscripcion.planSeleccionado?.anual[1], suscripcion.planSeleccionado?.anual[0])" type="button" class="btn btn-block mdc-btn-alter mdc-text-4 mt-3">Suscr&iacute;bete Ya</button>
                          </a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div class="modal fade" id="ModalColfondos" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered mdc-modal-dialog" role="document">
      <div class="modal-content px-5 mdc-br">
          <div class="modal-body mb-5">
              <div class="row text-center">
                  <div class="col-12 ">
                      <button type="button" class="close mdc-close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                      <p class="titleH2 mdc-text-white mt-5 mb-3 mdc-text-b" [innerHTML]="suscripcion.planSeleccionado?.popup"></p>
                      <hr class="mdc-hr-white m-auto">
                  </div>
              </div>
              <div class="row text-center">
                  <div class="col-12 offset-0 col-md-6 offset-md-0 mt-md-0 mt-5 mt-md-5">
                      <div class="md-info p-2">
                          <p class="titleH4 mdc-text-white mdc-text-b">Mensual<small id="idPremiumGenMes"></small></p>
                          <p class="titleH3 mdc-text-white mdc-text-b mdc-line-height"> <span id="idPremiumGenMesOLM">$12.900 </span> <br> <small class="mdc-fs-2 mdc-text-b">mes</small></p>
                          <a>
                              <button (click)="seleccionarPlan('1', suscripcion.planSeleccionado?.mensual[1], suscripcion.planSeleccionado?.mensual[0])" type="button" class="btn btn-block mdc-btn-alter mdc-btn-rd mdc-text-4 mt-3">Suscr&iacute;bete Ya</button>
                          </a>
                          <!-- <a>
                              <button (click)="seleccionarPlan1(sesion.planes.planes?.per, '1', sesion.planes.planes?.per.mensual[1], sesion.planes.planes?.per.mensual[0])" type="button" class="btn btn-block mdc-btn-alter mdc-text-4 mt-3">Suscr&iacute;bete Ya</button>
                          </a> -->
                      </div>
                  </div>
                  <div class="col-12 offset-0 col-md-6 offset-md-0 mt-md-0 mt-2 mt-md-5">
                      <div class="md-info p-2">
                          <p class="titleH4 mdc-text-white mdc-text-b">Anual <br> <small id="idPremiumGenAnual"></small></p>
                          <p class="titleH3 mdc-text-white mdc-text-b mdc-line-height"> <span id="idPremiumGenAnualOLM">$85.900</span><br> <small class="mdc-fs-2 mdc-text-b">anual</small></p>
                          <a>
                              <button (click)="seleccionarPlan('3', suscripcion.planSeleccionado?.anual[1], suscripcion.planSeleccionado?.anual[0])" type="button" class="btn btn-block mdc-btn-alter mdc-text-4 mt-3">Suscr&iacute;bete Ya</button>
                          </a>
                          <!-- <a>
                              <button (click)="seleccionarPlan1(sesion.planes.planes?.per, '3', sesion.planes.planes?.per.anual[1], sesion.planes.planes?.per.anual[0])" type="button" class="btn btn-block mdc-btn-alter mdc-text-4 mt-3">Suscr&iacute;bete Ya</button>
                          </a> -->
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>