import { Component, OnInit, ElementRef } from '@angular/core';
import { ModalService } from '../../core/services/modal.service';
import { WebActivityService } from 'src/app/core/services/web-activity.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { SuscripcionService } from 'src/app/core/services/suscripcion.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/core/services/utilities.service';

declare var $: any;

@Component({
  selector: 'app-crear-alerta',
  templateUrl: './crear-alerta.component.html',
  styleUrls: ['./crear-alerta.component.scss']
})
export class CrearAlertaComponent implements OnInit {
  errorMessage = '';
  listAlerts: any;
  public isIntermittence = false;
  public checkDiv = false;

  constructor(
    public sesion: SesionService, 
    private modal: ModalService, 
    private webActivity: WebActivityService,
    public suscripcion: SuscripcionService,
    public loading: LoadingService,
    public elementRef: ElementRef,
    private utilities: UtilitiesService,
    private dataLayerService: DataLayersService ) {
  }

  ngOnInit() {
    // GA4 - User
    this.dataLayerService.dl_userId( '.', this.sesion.planCurrently );
    this.errorMessage = '';
    this.sesion.alertCode = null;
    // this.sesion.consultarAlerta();
    this.sesion.listarAlertas();
    if(this.listAlerts != undefined){
      this.listAlerts = this.sesion.alertasTipos;
    }
    this.intermittence();
  }

  seleccionarAlerta( codigo: string, description: string ) {    
    this.sesion.alertCode = codigo;
    this.sesion.alertText = description;
  }

  formularAlerta(idTag: string) {
    //EVENTO GA4 clic_inside_tool
    this.dataLayerService.dl_clic_button_name('clic_inside_tool','Generar alerta');
    
    this.webActivity.sendDataAWS("click-formular-formularAlert", idTag, false);
    if (this.sesion.alertCode) {
      this.errorMessage = '';
      this.sesion.crearAlerta();
      $('#alerta' + this.sesion.alertCode).prop('checked', false);
      if(this.sesion.responseAlertWasCreated){
        this.webActivity.sendDataAWS("action-response-okFormularAlert",null, false);
      }else{
        this.webActivity.sendDataAWS("action-response-errorFormularAlert",null, false);
      }
    } else {
      this.errorMessage = 'Seleccionar tipo de alerta';
    }
  }

  addManyChat(){
    let script = document.createElement('script');
    script.src = "//widget.manychat.com/1897847597147648.js";
    script.async = true;
    this.elementRef.nativeElement.appendChild(script);
  }

  showModal(codigo:any){
    if(codigo == "004"){
      this.modal.modalAlertImpersonation();
    }
  }

  intermittence(){
    this.sesion.getIntermittence().subscribe({
      next: (response: any) => {
        let date = new Date()
        let hour = date.getHours();
        let day = date.getDate();
        let month = date.getMonth();
        let year = date.getFullYear();
        if(month == this.sesion.intermittenceClaimHour.month && (day >= this.sesion.intermittenceClaimHour.dayStart && day <= this.sesion.intermittenceClaimHour.dayEnd) && (hour >= this.sesion.intermittenceClaimHour.HourEnd) && year == this.sesion.intermittenceClaimHour.year){
          this.isIntermittence = true;
        } else if (month == this.sesion.intermittenceClaimHour.month && (day >= this.sesion.intermittenceClaimHour.dayStart && day <= this.sesion.intermittenceClaimHour.dayEnd) && year == this.sesion.intermittenceClaimHour.year){
               this.isIntermittence = true;
            } else if (month == this.sesion.intermittenceClaimHour.month && (day >= this.sesion.intermittenceClaimHour.dayStart && day <= this.sesion.intermittenceClaimHour.dayEnd) && (hour < this.sesion.intermittenceClaimHour.hourStart) && year == this.sesion.intermittenceClaimHour.year){
          this.isIntermittence = true;
        }
      }
    });
  }

  /**
   * Inicializar banner de Google Ad Manager
   */
  checkBanner(){
    if (!this.checkDiv) {
			let tamano = [[728, 90], [300, 250]];
			this.utilities.addPubliById(environment.slotsBanners.free_generarAlerta, 'div-gpt-ad-1694400137283-0', tamano);
			this.checkDiv = true;
		}
		return ''
  }
}
