<div class="cardB" [ngClass]="getConfigLabel().color">
    <div class="tab">
        <h2 class="txtTab"><b>{{offer.company.name}}</b> - {{offer.card.productName }}</h2>
    </div>
    <div class="cont offer{{offer.card.buttonId}}" [ngClass]="setback(offer.detail.subcategories.includes('TC'),offer.card.buttonId,(offer.detail.offerLogoURL != ''?offer.detail.offerLogoURL:offer.company.entityimg))">
        <div class="custom-label" *ngIf="getConfigLabel().showLabel" [ngClass]="getConfigLabel().color">
            <p>{{offer.textLabel.slice(0,20)}}</p>
            <img [src]="getConfigLabel().img" title="{{offer.textLabel.slice(0,20)}}"
                alt="{{offer.textLabel.slice(0,20)}}" />
            <!-- <div class="box-sponsor" >
            </div> -->
        </div>
        <div class="partOne">
            <img *ngIf="!offer.detail.companyLogoFlag" src="{{offer.company.entityimg}}" alt="logo oferta">
            <img *ngIf="offer.detail.companyLogoFlag" src="{{offer.detail.offerLogoURL}}" alt="logo oferta">
        </div>
        <hr/>
        <div class="partTwo">
            <div class="info twoParts">
                <p class="title">{{offer.card.offerValueTitle}}</p>
                <p class="desc">{{offer.card.offerValue}}</p>
            </div>
            <div class="info twoParts">
                <p class="title">{{offer.card.offerAproxValueTitle}}</p>
                <p class="desc">{{offer.card.offerAproxValue}}</p>
            </div>
        </div>
        <hr/>
        <button class="btnAplicar" (click)="open_offer(offer)">Aplica ahora</button>
    </div>
</div>
