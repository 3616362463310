<section class="response" *ngIf="sesion.loggedIn">
	<div class="container-fluid">
		<div class="row">
			<div class="col-12 col-md-6 confirm-left">
				<div class="box-state check" *ngIf="sesion.estadoTransaccion">
					<div class="txt-state">
						<h2>Pago exitoso</h2>
						<p>Has adquirido tu <span>Plan {{ plan.popup }}</span> exitosamente</p>
					</div>
					<div class="img-state">
						<img src="assets/img/pay/Ico-confirmacion-Y.svg" alt="Aprobación" class="opacity-2"/>
					</div>
				</div>
				<div class="box-state red" *ngIf="!sesion.estadoTransaccion && this.sesion.confirmacion.transactionResponse.state !== 'PENDING' ">
					<div class="txt-state">
						<h2>Algo salió mal</h2>
						<p>Puedes intentarlo con otro método de pago</p>
					</div>
					<div class="img-state">
						<img src="assets/img/pay/Ico-confirmacion-N.svg" alt="Aprobación" class="opacity-1"/>
					</div>
				</div>
				<div class="box-state warn" *ngIf="!sesion.estadoTransaccion && this.sesion.confirmacion.transactionResponse.state === 'PENDING'">
					<div class="txt-state">
						<h2>Espera</h2>
						<p>Tu transacción se encuentra en proceso.</p>
					</div>
					<div class="img-state">
						<img src="assets/img/pay/Ico-confirmacion-N.svg" alt="Aprobación" class="opacity-1"/>
					</div>
				</div>			
				<div class="summary" *ngIf="sesion.estadoTransaccion">
					<h3>Felicitaciones</h3>
					<p>Conocer tu información y aprender a administrarla es el primer paso para cumplir tus sueños.</p>
					<p>A partir de ahora puedes disfrutar de todos los beneficios de tu Plan {{ plan.popup }}</p>
					<a class="btn-midc btn-orange w-50-btn" href="/private/home" id="pagoAprobado" webActivity data-idtag="confirmacionPago" data-nametag="pagoAprobado">
						Empezar mi plan
						<i class="fa fa-chevron-right"></i>
					</a>  
				</div>
				<div class="summary" *ngIf="!sesion.estadoTransaccion && this.sesion.confirmacion.transactionResponse.state !== 'PENDING' ">
					<h3>Tu pago no ha sido procesado</h3>
					<p>Se ha presentado un error en tu activación, no hemos podido recibir tu pago.</p>
					<a class="btn-midc btn-outline-purple w-70-btn" href="/pago" id="pagoRechazado" webActivity data-idtag="confirmacionPago" data-nametag="pagoRechazado">
						Probar otro método de pago
						<i class="fa fa-chevron-right"></i>
					</a>
					<div class="dude">
						<img src="assets/img/icons/recuerda.svg" alt="recuerda"/>
						<p>¿Tienes una duda, inquietud o sugerencia? escríbenos a nuestro email: 
							<a href="">servicioalciudadano@experian.com</a></p>
					</div>
				</div>	
				<div class="summary" *ngIf="!sesion.estadoTransaccion && this.sesion.confirmacion.transactionResponse.state === 'PENDING'">
					<h3>Estamos confirmando tu pago</h3>
					<p>Mientras tanto puedes acceder a nuestra herramienta de consulta gratuita. Una vez tu pago sea procesado, serás notificado vía email para acceder a todas las herramientas de tu plan en Midatacrédito.</p>
					<a class="btn-midc btn-orange w-50-btn" href="/freemium/historico" id="pagoPendiente" webActivity data-idtag="confirmacionPago" data-nametag="pagoPendiente">
						Ir a Freemium
						<i class="fa fa-chevron-right"></i>
					</a>  
				</div>			
			</div>
			<div class="col-12 col-md-6 confirm-right align-self-center" *ngIf="sesion.confirmacion && respuesta === true">
				<h3>Resumen de tu transacción</h3>
				<div class="box-content">
					<h5>Fecha :</h5>
					<p>{{ sesion.confirmacion.transactionResponse.operationDate | date: 'dd MMMM yyyy' }}</p>
				</div>
				<div class="box-content">
					<h5>Producto :</h5>
					<p>{{ plan.popup }}</p>
				</div>
				<div class="box-content">
					<h5>Vigencia :</h5>
					<!-- <p>Febrero 14, 2019</p> -->
					<p *ngIf="sesion.tipoPlan === '1'">30 días a partir de la compra</p>
            			<p *ngIf="sesion.tipoPlan === '2'">90 días a partir de la compra</p>
            			<p *ngIf="sesion.tipoPlan === '4'">365 días a partir de la compra</p>
					<p *ngIf="sesion.tipoPlan === '5'">365 días a partir de la compra</p>
				</div>
				<div class="box-content">
					<h5>Estado de Transacción :</h5>
					<!-- <p>{{ sesion.confirmacion.transactionResponse.state }}</p> -->
					<p class="check" *ngIf="this.sesion.confirmacion.transactionResponse.state == 'APPROVED'">APROBADO</p>
					<p class="check" *ngIf="this.sesion.confirmacion.transactionResponse.state == 'Aprobada'">APROBADO</p>
					<p class="check" *ngIf="this.sesion.confirmacion.transactionResponse.state == 'Aceptado'">APROBADO</p>
					<p class="red" *ngIf="this.sesion.confirmacion.transactionResponse.state == 'BAD'">RECHAZADO</p>
					<p class="red" *ngIf="this.sesion.confirmacion.transactionResponse.state == 'Rechazada'">RECHAZADO</p>
					<p class="red" *ngIf="this.sesion.confirmacion.transactionResponse.state == 'DECLINED'">RECHAZADO</p>
				</div>
				<div class="box-content">
					<h5>Valor :</h5>
					<p>{{ sesion.confirmacion.transactionResponse.TX_VALUE - sesion.confirmacion.transactionResponse.TX_TAX | currency:'COP': 'symbol':'1.0-0' }}</p>
				</div>
				<div class="box-content">
					<h5>IVA :</h5>
					<p>{{ sesion.confirmacion.transactionResponse.TX_TAX | currency:'COP': 'symbol':'1.0-0' }}</p>
				</div>
				<div class="box-content">
					<h5>Total :</h5>
					<p>{{ sesion.confirmacion.transactionResponse.TX_VALUE | currency:'COP': 'symbol':'1.0-0' }}</p>
				</div>
				<div class="box-content">
					<h5>Tipo Medio de Pago :</h5>
					<!-- <p>Febrero 14, 2019</p> -->
					<p *ngIf="tipoPago === '1'">Tarjeta de Crédito</p>
					<p *ngIf="tipoPago === '3'">PSE - Transferencia bancaria</p>
					<p *ngIf="tipoPago === '2'">Gift Card</p>
				</div>
				<div class="box-content">
					<h5>Moneda :</h5>
					<p>COP</p>
				</div>
				<div class="box-content">
					<h5>Ref. de Pago :</h5>
					<p>{{ sesion.confirmacion.transactionResponse.orderid }}</p>
				</div>
				<div class="box-content">
					<h5>Dirección IP :</h5>
					<p>0.0.0.0.0.0.0.1</p>
				</div>
				<div class="box-content">
					<h5>Banco :</h5>
					<p>{{ sesion.confirmacion.transactionResponse.pseBank }}</p>
				</div>
				<div class="box-content">
					<h5>CUS :</h5>
					<p>1540970</p>
				</div>
				<div class="box-content">
					<h5>Empresa que factura :</h5>
					<p>Experian Colombia</p>
				</div>
				<div class="box-content">
					<h5>NIT :</h5>
					<p>900422614</p>
				</div>
				<div class="box-content">
					<h5>Dirección :</h5>
					<p>Carrera 7 # 76 - 35</p>
				</div>
			</div>
		</div>
	</div>
</section>
