import { Component, OnInit } from '@angular/core';
import { BrokerService } from 'src/app/core/services/broker.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-app-politica-privacidad',
  templateUrl: './app-politica-privacidad.component.html',
  styleUrls: ['./app-politica-privacidad.component.scss']
})
export class AppPoliticaPrivacidadComponent implements OnInit {
  policy:any;
  constructor(public broker: BrokerService,private sanitizer:DomSanitizer) { }

  ngOnInit(): void {
    this.getPolicy();
  }
  getPolicy() {
    this.broker.getPolicy().subscribe(response => {
      this.policy = this.sanitizer.bypassSecurityTrustHtml(response);
  })
  }

}
