import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { PlanesGuard } from './core/guards/planes.guard';
import { ValidateSesionGuard } from './core/guards/validate-sesion.guard';
import { PayGuardGuard } from './core/guards/pay-guard.guard';
import { ZonaPagoGuard } from './core/guards/zona-pago.guard';
import { ClausulaPolizaComponent } from './pages/clausula-poliza/clausula-poliza.component';
import { ComparacionPlanesComponent } from './pages/comparacion-planes/comparacion-planes.component';
import { ConfirmacionPagoComponent } from './pages/confirmacion-pago/confirmacion-pago.component';
import { ContactanosComponent } from './pages/contactanos/contactanos.component';
import { CrearAlertaComponent } from './pages/crear-alerta/crear-alerta.component';
import { DetalleCuentaComponent } from './pages/detalle-cuenta/detalle-cuenta.component';
import { DiagnosticoComponent } from './pages/diagnostico/diagnostico.component';
import { ErrorComponent } from './pages/error/error.component';
import { FaqComponent } from './pages/faq/faq.component';
import { Historicov2Component } from './pages/historicov2/historicov2.component';
import { HuellasComponent } from './pages/huellas/huellas.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { PerfilCreditoComponent } from './pages/landings/perfil-credito/perfil-credito.component';
import { PremiumComponent } from './pages/landings/premium/premium.component';
import { ProtegeComponent } from './pages/landings/protege/protege.component';
import { MainComponent } from './pages/main/main.component';
import { MiDeudaComponent } from './pages/mi-deuda/mi-deuda.component';
import { MiplanComponent } from './pages/miplan/miplan.component';
import { NotificacionComponent } from './pages/notificacion/notificacion.component';
import { PagoComponent } from './pages/pago/pago.component';
import { PoliticasPrivacidadComponent } from './pages/politicas-privacidad/politicas-privacidad.component';
import { PolizaPremiumComponent } from './pages/poliza-premium/poliza-premium.component';
import { PresenciaInternetComponent } from './pages/presencia-internet/presencia-internet.component';
import { ProximoCreditoComponent } from './pages/proximo-credito/proximo-credito.component';
import { ReclamacionComponent } from './pages/reclamacion/reclamacion.component';
import { ServicioClienteComponent } from './pages/servicio-cliente/servicio-cliente.component';
import { TerminosCondicionesComponent } from './pages/terminos-condiciones/terminos-condiciones.component';
import { WebcheckoutConfirmComponent } from './pages/webcheckout-confirm/webcheckout-confirm.component';
import { WebcheckoutComponent } from './pages/webcheckout/webcheckout.component';
import { PlanesComponent } from './pages/landings/planes/planes.component';
import { BlogComponent } from './pages/blog/blog.component'
import { PlanesDinamicosComponent } from './pages/landings/planes-dinamicos/planes-dinamicos.component';
import { NotificationsFreeComponent } from './pages/notification-free/notifications-free.component';
import { DiagnosticoPuntajeComponent } from './pages/landings/diagnostico-puntaje/diagnostico-puntaje.component';
import { MainGuardGuard } from './core/guards/main-guard.guard';
import { HistoricoScoreComponent } from './pages/historico-score/historico-score.component';
import { DeudaSaludableComponent } from './pages/landings/deuda-saludable/deuda-saludable.component';
import { ListaBcComponent } from './pages/lista-bc/lista-bc.component';
import { SimuladorComponent } from './pages/simulador/simulador.component';
import { AppPoliticaPrivacidadComponent } from './pages/landings/app-politica-privacidad/app-politica-privacidad.component';
import { Historicov3Component } from './pages/historicov3/historicov3.component';
import { FeriaComponent } from './pages/landings/feria/feria.component';
import { PadAppComponent } from './pages/landings/pad-app/pad-app.component';
import { AutorizacionComponent } from './pages/autorizacion/autorizacion.component';
import { AutorizacionOpcionalComponent } from './pages/autorizacion-opcional/autorizacion-opcional.component';
import { PadComponent } from './pages/landings/pad/pad.component';
import { HomeComponent } from './pages/home/home.component';
// import { LayoutComponent } from './shared/layout/layout.component';
import { FraudeComponent } from './pages/landings/fraude/fraude.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [PlanesGuard,ValidateSesionGuard] },
  // { path: '', component: InicioComponent, canActivate: [PlanesGuard, ValidateSesionGuard] },
  {
    path: 'freemium', component: MainComponent,
    children: [
      { path: 'historico', component: Historicov2Component, canActivate: [PlanesGuard] },
      { path: 'home', component: Historicov3Component, canActivate: [PlanesGuard] },
      { path: 'generar-alerta', component: CrearAlertaComponent },
      { path: 'huellas', component: HuellasComponent },
      { path: 'servicio-cliente', component: ServicioClienteComponent },
      { path: 'detalle-cuenta', component: DetalleCuentaComponent },
      { path: 'reclamaciones', component: ReclamacionComponent },
      { path: 'mi-plan', component: MiplanComponent },
      { path: 'mis-notificaciones', component: NotificationsFreeComponent },
      { path: 'buscacredito', component: ListaBcComponent },
    ]
    , canActivateChild: [ValidateSesionGuard]
  },
  {
    path: 'private', component: MainComponent,
    children: [
      { path: 'historico', component: Historicov2Component, canActivate: [PlanesGuard] },
      { path: 'home', component: Historicov3Component },
      { path: 'generar-alerta', component: CrearAlertaComponent },
      { path: 'huellas', component: HuellasComponent },
      { path: 'servicio-cliente', component: ServicioClienteComponent },
      { path: 'detalle-cuenta', component: DetalleCuentaComponent },
      { path: 'reclamaciones', component: ReclamacionComponent },
      { path: 'mi-plan', component: MiplanComponent },
      { path: 'mis-notificaciones', component: NotificacionComponent },
      { path: 'buscacredito', component: ListaBcComponent },
      //pay options
      { path: 'diagnostico', component: DiagnosticoComponent , canActivate: [PayGuardGuard]},
      { path: 'mi-deuda', component: MiDeudaComponent , canActivate: [PayGuardGuard]},
      { path: 'mi-proximo-credito', component: ProximoCreditoComponent , canActivate: [PayGuardGuard]},
      { path: 'historico-score', component: HistoricoScoreComponent , canActivate: [PayGuardGuard]},
      { path: 'mi-presencia', component: PresenciaInternetComponent , canActivate: [PayGuardGuard]},
      { path: 'poliza-premium', component: PolizaPremiumComponent , canActivate: [PayGuardGuard]},
      { path: 'simulador', component: SimuladorComponent, canActivate: [PayGuardGuard] }
    ]
    , canActivateChild: [ValidateSesionGuard]
  },
  { path: 'comparacion-planes', component: ComparacionPlanesComponent, canActivate: [PlanesGuard] },
  { path: 'politicas-privacidad', component: PoliticasPrivacidadComponent },
  { path: 'terminos-condiciones', component: TerminosCondicionesComponent },
  // { path: 'poliza', component: ClausulaPolizaComponent },
  { path: 'pago', component: PagoComponent, canActivate: [ZonaPagoGuard, PlanesGuard] },
  { path: 'confirmacion-pago', component: ConfirmacionPagoComponent, canActivate: [PlanesGuard, ZonaPagoGuard] },
  { path: 'confirmacion-pago/:estado', component: ConfirmacionPagoComponent, canActivate: [PlanesGuard, ZonaPagoGuard] },
  { path: 'landing/perfil-credito', component: PerfilCreditoComponent, canActivate: [PlanesGuard] },
  { path: 'landing/protege', component: ProtegeComponent, canActivate: [PlanesGuard] },
  { path: 'landing/premium', component: PremiumComponent, canActivate: [PlanesGuard] },
  { path: 'landing/politica-terminos', component: AppPoliticaPrivacidadComponent },
  { path: 'landing/planes', component: PlanesDinamicosComponent, canActivate: [PlanesGuard] },
  { path: 'landing/diagnostico-puntaje', component: DiagnosticoPuntajeComponent, canActivate: [PlanesGuard] },
  { path: 'landing/deuda-saludable', component: DeudaSaludableComponent, canActivate: [PlanesGuard] },
  { path: 'prevencion-de-fraude', component: FraudeComponent},
  { path: 'deudas', component: PadComponent },
  { path: 'feria', component: FeriaComponent },
  { path: 'planes-midc', component: PlanesComponent, canActivate: [PlanesGuard] },
  { path: 'faq', component: FaqComponent, canActivate: [PlanesGuard] },
  { path: 'contactanos', component: ServicioClienteComponent },
  { path: 'servicio-cliente', component: ServicioClienteComponent },
  { path: 'webcheckout/:documentId', component: WebcheckoutComponent },
  { path: 'webcheckout-confirm', component: WebcheckoutConfirmComponent },
  { path: 'blog/:article', component: BlogComponent },
  { path: 'alianzas', component: ComparacionPlanesComponent, canActivate: [PlanesGuard] },
  { path: 'autorizacion', component: AutorizacionComponent },
  { path: 'autorizacion-opcional', component: AutorizacionOpcionalComponent },
  { path: 'modulopadapp', component: PadAppComponent },
  { path: 'prueba293487', component: FeriaComponent },
  { path: 'error', component: ErrorComponent },
  { path: '**', redirectTo: 'error' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
