<section class="freemiumNotifications">
	<div class="container-fluid">
		<div class="row justify-content-end">
			<!-- Contenido principal. -->
			<div class="col-12">
				<div class="row justify-content-center" style="background-color: #F1F1F7;">
					<div class="rightAlerts">
						<div *ngIf="sesion.viewFree" id='div-gpt-ad-1694401046883-0' class="commercial-banner2" [ngClass]="checkBanner()"></div>
						<div class="titAlerts">
							<div class="icon">
								<img src="../../../../assets/img/notifications/comunicaciones.png" alt="Administrar notificaciones." title="Administrar notificaciones.">
								<h2 class="d-block d-md-none">MONITOREO DEL PUNTAJE DE CRÉDITO</h2>
							</div>
							<div class="title">
								<h2 class="d-none d-md-block">MONITOREO DEL PUNTAJE DE CRÉDITO</h2>
								<p>Este tipo de comunicaciones te ayudan a <u>mantenerte informado</u> sobre <b>todos los cambios</b> que pueden ocurrir en tu historia de crédito, potenciando la <u>prevención de fraude</u> y el <u>monitoreo</u> de tu puntaje de crédito.</p>
							</div>
						</div>
						<div class="notifications">
							<div class="alert active" *ngIf="!sesion.errorAlertMongo && sesion.notificacionesActivas[7].checked && flagS">
								<img class="imgB" src="assets/img/huellas-consulta/Alerta_huellas.svg" alt="alert">
								<p>Tus comunicaciones fueron activadas con éxito.</p>
							</div>
							<div class="alert disabled" *ngIf="!sesion.errorAlertMongo && !sesion.notificacionesActivas[7].checked && flagS">
								<img class="imgB" src="assets/img/huellas-consulta/Alerta_huellas.svg" alt="alert">
								<p>Tus comunicaciones fueron desactivadas con éxito.</p>
							</div>
							<div *ngIf="!sesion.alertMongo">
								<app-micro-loader [error]="sesion.errorAlertMongo">
								</app-micro-loader>
							</div>
							<div *ngIf="sesion.alertMongo">
								<div class="box-notification">
									<div class="boxEmail">
										<p class="txt">Este es el <b>correo</b> al que llegarán las comunicaciones</p>
										<p class="email">{{sesion.user.correo}}</p>
									</div>
									<div class="box-swich">
										<p class="pSwitch">{{sesion.notificacionesActivas[7].description}}</p>
										<label class="switch"><input type="checkbox" [(ngModel)]="sesion.notificacionesActivas[7].checked" (click)="activate()"><span class="slider"></span></label>
									</div>
								</div>
							</div>
							<div class="warnning">
								<img class="imgB" src="assets/img/huellas-consulta/Alerta_huellas.svg" alt="warnning"/>
								<p>El reporte y envío de las comunicaciones dependerán del reporte de una nueva
									obligación o cuenta en tu historia de crédito por parte de la respectiva
									entidad.</p>
							</div>
						</div>
						<div class="notificationBlock">
							<div class="titeBlock">
								<div class="icon">
									<img src="../../../../assets/img/images/Icono_Notificaciones.svg"
										alt="Administrar notificaciones." title="Administrar notificaciones.">
								</div>
								<div class="info">
									<h2>Toma el control de tu vida financiera</h2>
									<p>Entérate de todos los cambios que puedes tener en tu puntaje de crédito.</p>
								</div>
							</div>
							<a class="btnBlock" href="/pago?funn=PG&plan=PER1">Desbloquear monitoreo de puntaje<i class="fa fa-chevron-right"></i></a>
							<div class="box-swich borderTop">
								<p class="pTitle">Comunicaciones sobre tus datos personales</p>
								 <img class="imgB" src="assets/img/header/icon/funcionalidad_bloqueada.svg" alt="block">
							</div>
							<div class="box-swich">
								<p class="pDesc">{{ sesion.notificacionesActivas[0].description }}</p>
								<label class="switch"><input type="checkbox" disabled><span class="slider"></span></label>
							</div>
							<div class="box-swich">
								<p class="pDesc">{{ sesion.notificacionesActivas[1].description }}</p>
								<label class="switch"><input type="checkbox" disabled><span class="slider"></span></label>
							</div>
							<div class="box-swich borderTop">
								<p class="pTitle">Comunicaciones sobre cambios en tu historia de crédito </p>
								 <img class="imgB" src="assets/img/header/icon/funcionalidad_bloqueada.svg" alt="block">
							</div>
							<div class="box-swich">
								<p class="pDesc">{{ sesion.notificacionesActivas[2].description }}</p>
								<label class="switch"><input type="checkbox" disabled><span class="slider"></span></label>
							</div>
								<div class="box-swich">
								<p class="pDesc">{{ sesion.notificacionesActivas[3].description }}</p>
								<label class="switch"><input type="checkbox" disabled><span class="slider"></span></label>
							</div>
							<div class="box-swich">
								<p class="pDesc">{{ sesion.notificacionesActivas[6].description }}</p>
								<label class="switch"><input type="checkbox" disabled><span class="slider"></span></label>
							</div>
							<div class="box-swich">
								<p class="pDesc">{{ sesion.notificacionesActivas[9].description }}</p>
								<label class="switch"><input type="checkbox" disabled><span class="slider"></span></label>
							</div>
							<div class="box-swich">
								<p class="pDesc">{{ sesion.notificacionesActivas[8].description }}</p>
								<label class="switch"><input type="checkbox" disabled><span class="slider"></span></label>
							</div>
							<div class="box-swich borderTop">
								<p class="pTitle">Comunicaciones sobre tus reclamaciones </p>
								 <img class="imgB" src="assets/img/header/icon/funcionalidad_bloqueada.svg" alt="block">
							</div>
							<div class="box-swich">
								<p class="pDesc">{{ sesion.notificacionesActivas[4].description }}</p>
								<label class="switch"><input type="checkbox" disabled><span class="slider"></span></label>
							</div>
							<div class="box-swich">
								<p class="pDesc">{{ sesion.notificacionesActivas[5].description }}</p>
								<label class="switch"><input type="checkbox" disabled><span class="slider"></span></label>
							</div>
							<div class="box-swich borderTop">
								<p class="pTitle">Comunicaciones sobre tus datos comprometidos en la red </p>
								 <img class="imgB" src="assets/img/header/icon/funcionalidad_bloqueada.svg" alt="block">
							</div>
							<div class="box-swich">
								<p class="pDesc">{{ sesion.notificacionesActivas[10].description }}</p>
								<label class="switch"><input type="checkbox" disabled><span class="slider"></span></label>
							</div>
							<a class="btnBlock" href="/pago?funn=PG&plan=PER1">Desbloquear monitoreo de puntaje<i class="fa fa-chevron-right"></i></a>
						</div>
					</div>
					<div *ngIf="sesion.viewFree" id='div-gpt-ad-1694401046883-1' class="commercial-banner" [ngClass]="checkBanner2()"></div>
				</div>
			</div>
		</div>
	</div>
</section>