import { Component, OnInit } from '@angular/core';
import { SesionService } from 'src/app/core/services/sesion.service';
import {  Router } from '@angular/router';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { DebtsService } from 'src/app/core/services/debts.service';
import { ModalService } from 'src/app/core/services/modal.service';
declare var $: any;

@Component({
  selector: 'app-historicov3',
  templateUrl: './historicov3.component.html',
  styleUrls: ['./historicov3.component.scss']
})
export class Historicov3Component implements OnInit {
	public textsScore = [
		{ title: '¡Tu puntaje debería mejorar!', button: '¿Cómo puedo mejorar mi puntaje?', desc: 'Tu puntaje de crédito está por debajo del promedio de los colombianos.', scoreMin: 0, scoreMax: 310,scoreMinA: 0, scoreMaxA: 345  },
		{ title: '¡Tu puntaje podría ser mejor!', button: '¿Cómo puedo mejorar mi puntaje?', desc: 'Tu puntaje de crédito está cerca de llegar al promedio de los colombianos.', scoreMin: 311, scoreMax: 470,scoreMinA: 346, scoreMaxA: 470  },
		{ title: '¡Tu puntaje es promedio!', button: 'Ver qué impacta mi puntaje', desc: 'Tu puntaje de crédito está en el promedio de los colombianos.', scoreMin: 471, scoreMax: 630,scoreMinA: 471, scoreMaxA: 670  },
		{ title: '¡Puedes estar tranquilo!', button: 'Ver qué impacta mi puntaje', desc: 'Tu puntaje de crédito está por encima del promedio de los colombianos.', scoreMin: 631, scoreMax: 790,scoreMinA: 671, scoreMaxA: 815  },
		{ title: '¡Que buen puntaje!', button: 'Ver qué impacta mi puntaje', desc: 'Tu puntaje de crédito está por encima del promedio de los colombianos.', scoreMin: 791, scoreMax: 2000,scoreMinA: 816, scoreMaxA: 2000  },
	];

	flagState:boolean=false;
	txtTootip:string="";
	public titleScore = "";
	public descScore = "";
	huellas:any[]=[];
	public textoMicroLoader = "Por favor espera un momento, estamos cargando tu historia de crédito."
	constructor(public sesion: SesionService, public router: Router, private dataLayerService: DataLayersService,private debts:DebtsService, public modal: ModalService ) { 
		if (this.sesion.resumenhc == undefined) {
				if (this.sesion.viewFree) {
					this.sesion.freepremium(true);
				} else {
					this.sesion.infoHC(true);
				}
		}else{
			this.debts.getDebtsPrintPadYa(this.sesion.allHistory)
		}
		if(!this.sesion.alertMongo){
			
			this.sesion.getAlertsMongoWS();
		}
	}

	ngOnInit(): void {
		// GA4 - User
		this.dataLayerService.dl_userId('.', this.sesion.planCurrently);	
		if (this.sesion.estadoUserF == "PENDING" || this.sesion.estadoUserF == "PENDING_PSE") {
			$('#pendingModalLong').modal('show');
		}
		this.dl_view_item();
		// if(this.sesion.campainFree){
		// 	this.modal.modalFreemium()	
		// }
	}

	// Evento view_item_list
	dl_view_item(){
		if (this.sesion.viewFree){
			this.dataLayerService.dl_view_item_list( this.sesion.planes.planes.per, this.sesion.planes.planes.ant, this.sesion.planes.planes.pre, 'DASHBOARD', 'Mensual' );
		}
	}

  	showTextScore() {
		if (this.sesion.numberScore) {
			let auxScore;
			!this.sesion.dataUser.params[13].value? auxScore = this.textsScore.find(ele => this.sesion.miscoreInt >= ele.scoreMin && this.sesion.miscoreInt <= ele.scoreMax): auxScore = this.textsScore.find(ele => this.sesion.miscoreInt >= ele.scoreMinA && this.sesion.miscoreInt <= ele.scoreMaxA);
			if (auxScore != undefined) {
				this.titleScore = auxScore.title;
				this.descScore = auxScore.desc;
			}
		}
		return true;
	}
	//history
	totalCA() {
		let totalAbiertas = 0;
		for (let i = 0; i < (this.sesion.resumenhc != undefined ? this.sesion.resumenhc.length : 0); i++) {
			let num = this.sesion.resumenhc[i].cuentasVigentes;
			totalAbiertas = num + totalAbiertas;
		}
		return totalAbiertas;
	}
	totalCC() {
		let totalCerradas = 0;

		for (let i = 0; i < (this.sesion.ctascerradas != undefined ? this.sesion.ctascerradas.length : 0); i++) {
			let num2 = this.sesion.ctascerradas[i].cuentasVigentes;
			totalCerradas = num2 + totalCerradas;
		}
		return totalCerradas;
	}
	totalRe() {
		let onlyRec = [];
		if (this.sesion.reclamaciones != undefined) {
			onlyRec = this.sesion.reclamaciones.filter((ele: any) => ele.numero != "0");
		}
		// total = (this.sesion.reclamaciones != undefined ? onlyRec.length : 0);
		return onlyRec.length;
	}

	totalCB() {
		let onlyCB = [];
		if (this.sesion.reclamaciones != undefined) {
			onlyCB = this.sesion.reclamaciones.filter((ele: any) => ele.numero == "0");
		}
		// total = (this.sesion.reclamaciones != undefined ? onlyRec.length : 0);
		return onlyCB.length;
	}
	//huellas
	getEntity(entidad:any){
		entidad.includes("CIUDADANO")?entidad="CIUDADANO":entidad;
		switch (entidad) {
			case 'CIUDADANO':
				this.txtTootip = 'Esta huella corresponde a todas las consultas hechas por el ciudadano (auto consultas) por medio de alguno de los canales habilitados.';
				$('#contenTooltip').css("visibility", "hidden");
				break;
			case 'CONSULTAS LOTE':
				this.txtTootip = 'Corresponden a consultas realizadas por las Entidades para la supervisión y control del riesgo crediticio.'
				$('#contenTooltip').css("visibility", "hidden");
				break;
			default:
				this.txtTootip = 'Esta huella corresponde a la consulta al historial crediticio realizada por alguna entidad con previa autorización del ciudadano.'
				$('#contenTooltip').css("visibility", "hidden");
				break;
		}
	}
	selImg(cal:any){
		var calificacion:any = {
		  'B' : 'Icono_diagnostico_regular.svg',
		  'E' : 'Icono_diagnostico_bueno.svg',
		  'R' : 'Icono_diagnostico_malo.svg',
		  'N/E' : ''
		}
		return calificacion[cal];
	}
	selCal(cal:any){
		var calificacion:any = {
		  'B' : 'good',
		  'E' : 'excelent',
		  'R' : 'bad',
		  'N/E' : ''
		}
		return calificacion[cal];
	}
	//huellas
	slicePrints(){
		this.huellas = this.sesion.listHuellas.totalHuellas.slice(0, 4);
		return this.sesion.listHuellas.lenghtHuellas;
	}
}
