<div class="content" *ngIf="reclamo.cuenta">
  <div class="container-fluid">
      <div class="row justify-content-center">
          <!-- Menu lateral freemium -->
          <div class="main col-12">
              <div class="row">
                  <div class="regresarHistoria col-12">
                      <button class="btn" (click)="volver()"><b><i class="fa fa-arrow-left"></i> Volver a historia de Crédito</b></button>
                  </div>
                  <div class="titulo col-12">
                      <div class="imgTitulo">
                          <img src="assets/img/images/reclamacion.svg" alt="reclamación"/>
                      </div>
                      <div class="textoTitulo">
                          <h1>Crear reclamación</h1>
                      </div>
                  </div>
                  <div class="descripcion col-12">
                      <p>
                          DataCrédito Experian como Operador de información financiera, crediticia, comercial y de
                          servicios está habilitado para trasladar tu reclamo a la entidad respectiva (fuente), quien
                          deberá resolverlo en los términos de ley.
                      </p>
                  </div>
                  <div class="pasos col-12">
                      <hr class="conexionPaso2" [ngClass]="{'activo' : activoPaso2}" />
                      <hr class="conexionPaso3" [ngClass]="{'activo' : activoPaso3}"/>
                      <div class="paso activo">
                          <i class="fa fa-circle"></i>
                          <label>TIPO DE RECLAMACIÓN</label>
                      </div>
                      <div class="paso" [ngClass]="{'activo' : activoPaso2}">
                          <i class="fa fa-circle-thin" [ngClass]="{'fa-circle-thin' : !activoPaso2, 'fa-circle' : activoPaso2}"></i>
                          <label>TUS DATOS</label>
                      </div>
                      <div class="paso" [ngClass]="{'activo' : activoPaso3}">
                          <i class="fa fa-circle-thin" [ngClass]="{'fa-circle-thin' : !activoPaso3, 'fa-circle' : activoPaso3}"></i>
                          <label>CONFIRMACIÓN</label>
                      </div>
                  </div>
                  <div class="col-12" *ngIf="!reclamo.paso3">
                      <div class="infoCta row">
                          <div class="detalleCuenta col-md-4">
                              <label>Tipo de cuenta</label>
                              <span>{{ reclamo.cuenta.tipoCuenta }}</span>
                          </div>
                          <div class="detalleCuenta col-md-4">
                              <label>Entidad</label>
                              <span>{{ reclamo.cuenta.entidad }}</span>
                          </div>
                          <div class="detalleCuenta col-md-4">
                              <label>Número de cuenta</label>
                              <span>{{ reclamo.cuenta.numeroCuenta }}</span>
                          </div>
                      </div>
                  </div>
                  <!-- Crear Reclamacion -->
                  <form class="form1 col-12" *ngIf="reclamo.paso1" (ngSubmit)="reclamoPaso1(paso1)" #paso1="ngForm"
                      autocomplete="off">
                      <div class="row mt-md-4 mt-2">
                          <div class="col-md-4 col-12">
                                <label class=" mdc-text-secondary mdc-text-b" for=""> Tipo de reclamo</label>
                                <div class="form-group" *ngIf="reclamo.tiposReclamos">
                                    <mat-form-field class="w-100">
                                        <mat-select name="tipoReclamo" id="tipoReclamo" required="required"
                                            (selectionChange)="seleccionarTipo(reclamo.reclamo.reclamo.tipoReclamo)"
                                            [(ngModel)]="reclamo.reclamo.reclamo.tipoReclamo">
                                            <mat-option value="-1" selected disabled>
                                                SELECCIONE UNA OPCIÓN</mat-option>
                                            <mat-option *ngFor="let rec of reclamo.tiposReclamos" value="{{ rec.tipo }}">
                                                {{ rec.nombre }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                          </div>
                          <div class="col-md-4 col-12 text-muted mdc-text-l mdc-fs-1 px-3 box-reclamo" *ngIf="detalleReclamo">
                              <div class="text-muted mdc-text-l mdc-fs-1 px-0">
                                  <label class=" mdc-text-secondary mdc-text-b mdc-fs-default"> Detalle de
                                      reclamo</label>
                                  <div class="form-group" *ngFor="let detalle of detalleReclamo; let i = index">
                                      <input name="detallereclamo" id="detallereclamo{{ i }}" type="radio"
                                          required="required" class="form-check-input" value="{{ detalle.codigo }}"
                                          (change)="seleccionarSubtipo(detalle.codigo)"
                                          [(ngModel)]="reclamo.reclamo.reclamo.subtipoReclamo" [hidden]="detalle.descripcion == 'Inconformidad con la permanencia de la información negativa'">
                                      <label for="detallereclamo{{ i }}" [hidden]="detalle.descripcion == 'Inconformidad con la permanencia de la información negativa'">
                                          {{ detalle.descripcion }}
                                      </label>
                                  </div>
                              </div>
                          </div>
                          <div class="col-md-4 col-12 mt-md-0 mt-2" id="descripcionFinal" *ngIf="detalleDescripcion">
                              <label class=" mdc-text-secondary mdc-text-b" id="descripcionHeadFinal">
                                  {{ detalleDescripcion.descripcion }}
                              </label>
                              <p class="mdc-text-l mdc-fs-1 text-muted" id="descripcionBodyFinal">
                                  {{ detalleDescripcion.nombre }}
                              </p>
                          </div>
                      </div>
                      <hr class="divisor" />
                      <div class="row">
                          <div class="col-md-6 col-12">
                              <div class="form-group h-90">
                                  <label for="comentario" class="mdc-text-secondary mdc-text-b">Tus
                                      observaciones</label>
                                  <textarea class="form-control mdc-default-area" name="comentario" id="comentario"
                                      cols="50" required="required" pattern=".{20,}"
                                      placeholder="Ingresa tus observaciones..." maxlength="250"
                                      #comentarior = "ngModel"
                                      [ngClass]="{'is-valid': comentarior.valid, 'is-invalid': ( (comentarior.errors?.['required'] || comentarior.errors?.['pattern'] ) && comentarior.touched ) }"
                                      [(ngModel)]="reclamo.reclamo.reclamo.comentario">
                                  </textarea>
                                  <div *ngIf="comentarior?.errors?.['required']" class="invalid-feedback">
                                      Campo obligatorio.
                                  </div>
                                  <div *ngIf="comentarior?.errors?.['pattern']" class="invalid-feedback">
                                      No cumple la longitud mínima de 20 caracteres.
                                  </div>
                              </div>
                          </div>
                          <div class="col-md-6 col-12">
                              <div class="infoObs">
                                  <p>Información adicional que usted
                                      considere importante que la entidad conozca para que pueda responder su
                                      reclamación.
                                      Recuerde que su comentario será publicado textualmente en su informe de crédito
                                      y
                                      será visto por todas las entidades que lo consulten a través de DataCrédito.
                                      Puede
                                      digitar desde 20 hasta 255 caracteres (letras+espacios).
                                  </p>
                              </div>
                              <button [attr.disabled]="((paso1.valid && checkStep1()) ? null : 'disabled')"
                                  [ngClass]="{'disabled': !paso1.valid }" type="submit"
                                  class="btn btn-block mdc-btn-secondary text-md-center text-left btnContinuar">
                                  Continuar
                                  <i class="fa fa-chevron-right float-right mt-arrow"></i>
                              </button>
                          </div>
                      </div>
                  </form>
                  <!-- Datos usuario-->
                    <form class="form2 col-12" *ngIf="reclamo.paso2" (ngSubmit)="reclamoPaso2(paso2)"  autocomplete="off" #paso2="ngForm">
                        <div class="infoDatos">
                            <div class="imgInfoDatos">
                                <img src="assets/img/icons/tip.svg" alt="tip"/>
                            </div>
                            <div class="textoInfoDatos">
                                <span>Por favor ingresa la siguiente información para crear tu reclamación.</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6 col-12" id="emaildiv">
                                <mat-form-field class="w-100">
                                    <input matInput type="text" name='mail' id="mail" required="required"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,9}$" placeholder="E-mail"
                                        #email="ngModel"
                                        [ngClass]="{'is-valid': email.valid, 'is-invalid': ( ( email.errors?.['required'] || email.errors?.['pattern'] ) && email.touched ) }"
                                        [(ngModel)]="reclamo.reclamo.reclamo.correoElectronico">
                                    <mat-error *ngIf="email?.errors?.['required']" class="invalid-feedback">
                                        Campo obligatorio.
                                    </mat-error>
                                    <mat-error *ngIf="email?.errors?.['pattern']" class="invalid-feedback">
                                        Correo electrónico inválido.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-6 col-12" id="emailcofdiv">
                                <mat-form-field class="w-100">
                                    <input matInput type="text" name='mailconf' id="mailconf" required="required"
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,9}$" placeholder="Confirmar E-mail"
                                        #emailconf="ngModel"
                                        [ngClass]="{'is-valid': emailconf.valid, 'is-invalid': ( ( emailconf.errors?.['required'] || emailconf.errors?.['pattern'] ) && emailconf.touched ) }"
                                        [(ngModel)]="confirmarEmail">
                                    <mat-error *ngIf="emailconf?.errors?.['required']" class="invalid-feedback">
                                        Campo obligatorio.
                                    </mat-error>
                                    <mat-error *ngIf="emailconf?.errors?.['pattern']" class="invalid-feedback">
                                        Correo electrónico inválido.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>                        
                        <div class="row ">
                            <div class=" col-md-6 col-12" id="celldiv">
                                <mat-form-field class="w-100">
                                    <input matInput type="number" name='telefono_celular' id="telefono_celular"
                                        required="required" pattern=".{10,}" placeholder="Celular"
                                        #telcel="ngModel"
                                        [ngClass]="{'is-valid': telcel.valid, 'is-invalid': ( ( telcel.errors?.['required'] || telcel.errors?.['pattern']  ) && telcel.touched ) }"
                                        [(ngModel)]="reclamo.reclamo.reclamo.numeroTelefono">
                                    <mat-error *ngIf="telcel?.errors?.['required']" class="invalid-feedback">
                                        Campo obligatorio.
                                    </mat-error>
                                    <mat-error *ngIf="telcel?.errors?.['pattern']" class="invalid-feedback">
                                        No cumple la longitud mínima de 10 y máxima de 15.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-md-6 col-12" id="dirdiv">
                                <mat-form-field class="w-100">
                                    <input matInput type="text" name='direccion_fisica' id="direccion_fisica"
                                        required="required" pattern=".{10,60}" placeholder="Dirección" 
                                        #dirfis="ngModel"
                                        [ngClass]="{'is-valid': dirfis.valid, 'is-invalid': ( ( dirfis.errors?.['required'] || dirfis.errors?.['pattern']  ) && dirfis.touched ) }"
                                        [(ngModel)]="reclamo.reclamo.reclamo.direccionFisica">
                                    <mat-error *ngIf="dirfis?.errors?.['required']" class="invalid-feedback">
                                        Campo obligatorio.
                                    </mat-error>
                                    <mat-error *ngIf="dirfis?.errors?.['pattern']" class="invalid-feedback">
                                        No cumple la longitud mínima de 10 y máxima de 60.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-12" id="ciudiv">
                                <div class="termsReclamacion">
                                    <input type="checkbox" id="acepta_correo" name="acepta_correo" required="required"
                                         [(ngModel)]="reclamomail">
                                    <label for="acepta_correo">Acepto recibir repuestas de este reclamo a trav&eacute;s
                                        de
                                        mi correo electr&oacute;nico</label>
                                </div>
                            </div>
                            <div class="col-md-6 col-12" *ngIf="reclamo.loadDispute">
                                <button [attr.disabled]="paso2.valid ? null : 'disabled'"
                                    [ngClass]="{'disabled': !paso2.valid }" type="submit"
                                    class="btn btn-block mdc-btn-secondary text-md-center text-left finalizarRec">
                                    Finalizar
                                    <i class="fa fa-chevron-right float-right mt-arrow finalizarRec"></i>
                                </button>
                                <div [innerHTML]="errorMessage" class="alert alert-danger" *ngIf="errorMessage">
                                </div>
                            </div>
                            <div class="col-12" *ngIf="!reclamo.loadDispute || reclamo.errorDispute">
                                <app-micro-loader [error]="reclamo.errorDispute" [textoError]="sesion.messDescription">
                                </app-micro-loader>
                            </div>
                        </div>
                    </form>
                  <!-- Confirmacion -->
                  <div class="form3 col-12" *ngIf="reclamo.paso3">
                      <div class="infoDatos">
                          <div class="imgInfoDatos">
                              <img src="assets/img/icons/tip.svg" alt="tip"/>
                          </div>
                          <div class="textoInfoDatos">
                              <span>Tu Reclamación ha sido creada con éxito.</span>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-12">
                              <div class="infoCta row">
                                  <div class="detalleCuenta col-md-4">
                                      <label>N&uacute;mero de reclamo</label>
                                      <span>{{ reclamo.code }}</span>
                                  </div>
                                  <div class="detalleCuenta col-md-4">
                                      <label>Tipo de identificaci&oacute;n</label>
                                      <span>{{ sesion.user.tipoDocumento }}</span>
                                  </div>
                                  <div class="detalleCuenta col-md-4">
                                      <label>N&#176; de identificaci&oacute;n</label>
                                      <span>{{ sesion.user.documento }}</span>
                                  </div>
                                  <div class="detalleCuenta col-md-4">
                                      <label>Nombre</label>
                                      <span>{{ sesion.user.nombre}} {{ sesion.user.apellido }}</span>
                                  </div>
                                  <div class="detalleCuenta col-md-4">
                                      <label>Tipo de cuenta</label>
                                      <span>{{ reclamo.cuenta.tipoCuenta }}</span>
                                  </div>
                                  <div class="detalleCuenta col-md-4">
                                      <label>N&#176; de cuenta</label>
                                      <span>{{ reclamo.cuenta.numeroCuenta }}</span>
                                  </div>
                                  <div class="detalleCuenta col-md-4">
                                      <label>Nombre del suscriptor</label>
                                      <span>{{ reclamo.cuenta.entidad }}</span>
                                  </div>
                                  <div class="detalleCuenta col-md-4">
                                      <label>Fecha</label>
                                      <span>{{fechaHoy | date:'dd/MM/yyyy'}}</span>
                                  </div>
                                  <div class="detalleCuenta col-md-4">
                                      <label>Texto del reclamo</label>
                                      <span>{{ reclamo.reclamo.reclamo.comentario }}</span>
                                  </div>
                              </div>
                              <div class="row">
                                  <div class="col-md-6">
                                      <p>DataCrédito actúa como intermediario ante las entidades registrando el
                                          reclamo e incluyendo la leyenda "Reclamo en trámite" para luego transmitirlo
                                          a la entidad y éstas cuentan con la opción de rectificar y/o modificar el
                                          reporte de historia de crédito directamente. El tiempo de respuesta
                                          establecido es 15 días.</p>
                                  </div>
                                  <div class="col-md-6">
                                      <button (click)="salir()" type="submit"
                                          class="btn mdc-btn-secondary btn-block px-md-3 px-5">
                                          Finalizar <i class="fa fa-chevron-right float-right mt-arrow"></i>
                                      </button>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>