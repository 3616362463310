<div class="row main-poliza">
  <div class="col-12">
    <div class="row head-poliza">
      <div class="col-12 col-md-6">
        <div class="row justify-content-center">
          <div class="col-8 col-md-6">
            <img src="assets/img/logos/SBS_logo.svg" alt="SBS Logo"/>
          </div>
          <div class="col-1 col-md-3 d-flex borde-separador">
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 d-flex">
        <h2>Cobertura productos plus</h2>
      </div>
    </div>
    <div class="row content-poliza">
      <div class="col-12">
        <p><b>SBS Seguros Colombia S.A.</b>, cuenta con una gran experiencia que ha permitido desarrollar productos que se
          ajustan a la necesidad de cada cliente.</p>
        <p>Este producto específico busca cubrir tus necesidades de aseguramiento mediante coberturas y valores
          asegurados brindando beneficios al asegurado mediante la protección de tu identidad y de tus finanzas</p>
      </div>
      <div class="col-12 servcio-poliza">
        <div class="row">
          <div class="col-12 col-md-3 text-center">
            <img src="assets/img/icons/Icono_SBS_Servicio.svg" alt="servicio"/>
          </div>
          <div class="col-12 col-md-9">
            <p>Toda la información referente al Servicio Cobertura Plus la encuentras en la Póliza, o si necesitas
              asesoría puedes recibirla por medio de los siguientes canales:</p>
            <p>Correo electrónico: <b>servicio.cliente@sbseguros.co</b><br/>
              Línea telefónica: <b>(1) 313 - 8700</b><br/>
              Línea Nacional: <b>01800 - 091- 1360</b></p>
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <button (click)="descargarPDF()" class="mdc-btn-primary">Descarga Poliza<i class="fa fa-chevron-right"></i></button>
      </div>
    </div>
  </div>
</div>