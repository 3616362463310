<div class="generalContainer">
    <div *ngIf="!sesion.allHistory" class="contHistory">
        <app-micro-loader [texto]="textoMicroLoader" [error]="sesion.errorServiceCreditHistory">
        </app-micro-loader>
    </div>
    <div class="contHistory" *ngIf="sesion.allHistory">
        <!--Modulo pad y padya-->
        <div class="row w-100 m-auto" [ngClass]="{'d-none':!sesion.showModulePad}">
            <div class="col-12 p-0">
                <app-dash-pad-module [infoPadYa]="sesion.allHistory"></app-dash-pad-module>
            </div>
        </div>
        <!--Modulo pad y padya-->
        <div class="row w-100 m-auto">
            <!-- banner de google 1 -->
            <div class="col-12 p-0" *ngIf="sesion.viewFree">
                <app-dash-banner-google [id_banner]="'div-gpt-ad-1708610431205-0'" [styles]="'commercial-banner'"></app-dash-banner-google>
            </div>
            <!-- score -->
            <div class="col-12 p-0">
                <app-dash-score></app-dash-score>
            </div>
            <!-- banner de google 2 -->
            <div class="col-12 p-0" *ngIf="sesion.viewFree">
                <app-dash-banner-google [id_banner]="'div-gpt-ad-1708610192600-0'" class="banner-div" [styles]="'commercial-banner'"></app-dash-banner-google>
                <app-dash-banner-google [id_banner]="'div-gpt-ad-1717777536769-0'"class="banner-div-mobile" [styles]="'commercial-banner2'"></app-dash-banner-google>
            </div>
             <!--Modulo buscacredito-->
             <div class="row w-100 m-auto" *ngIf="sesion.viewFree" [ngClass]="{'d-none': (!sesion.showModuleBC) }">
                <div class="col-12 p-0">
                    <app-dash-bc-module></app-dash-bc-module>
                </div>
            </div>
            <!--Modulo buscacredito-->
            <!-- banner de google 3 -->
            <div class="col-12 p-0" *ngIf="sesion.viewFree">
                <app-dash-banner-google [id_banner]="'div-gpt-ad-1708610601977-0'" class="banner-div" [styles]="'commercial-banner'"></app-dash-banner-google>
                <app-dash-banner-google [id_banner]="'div-gpt-ad-1718203033597-0'" class="banner-div-mobile" [styles]="'commercial-banner2'"></app-dash-banner-google>
            </div>
            <!-- extra info -->
            <div class="col-12 p-0">
                <div class="row w-100 m-auto">
                    <!-- historico y cuentas -->
                    <div class="col-12  p-0" [ngClass]="{'col-md-8':!sesion.viewFree}">
                        <div class="row w-100 m-auto">
                            <!-- historico -->
                            <div class="col-12 p-0">
                               <app-dash-history></app-dash-history>
                            </div>
                            <!-- comunicacion obligatoria free -->
                            <div class="col-6 col-md-4 p-0" [ngClass]="{'d-none':!sesion.viewFree}" *ngIf="sesion.alertMongo">
                                <app-dash-notification [id]="'FREE'"></app-dash-notification>
                            </div>
                            <!-- alertas free -->
                            <div class="col-6 col-md-4 p-0" [ngClass]="{'d-none':!sesion.viewFree}">
                                <app-dash-notification [id]="'FREE-ALERTS'"></app-dash-notification>
                            </div>
                            <!-- huellas free -->
                            <div class="d-none p-0" [ngClass]="{'d-block col-12 col-md-4':sesion.viewFree}">
                                <app-dash-notification [id]="'FREE-PRINTS'"></app-dash-notification>
                            </div>
                            <!-- comunicacion obligatoria private -->
                            <div class="col-6 p-0" [ngClass]="{'d-none':sesion.viewFree}" *ngIf="sesion.alertMongo">
                                <app-dash-notification [id]="'PRIVATE'"></app-dash-notification>
                            </div>
                            <!-- comunicaciones private -->
                            <div class="col-6 p-0" [ngClass]="{'d-none':sesion.viewFree}" *ngIf="sesion.alertMongo">
                                <app-dash-notification [id]="'PRIVATE-PAY'"></app-dash-notification>
                            </div>
                        </div>
                    </div>
                    <!-- huellas -->
                    <div class="col-12 col-md-4 p-0" [ngClass]="{'d-none':sesion.viewFree}">
                        <app-dash-huellas></app-dash-huellas>
                    </div>
                </div>
            </div>
            <!-- pago ANT -->
            <div class="col-6 p-0" [ngClass]="{'d-none':!sesion.viewFree}">
               <app-dash-pay-option [id]="'ANT'"></app-dash-pay-option>
            </div>
            <!-- pago PRO -->
            <div class="col-6 p-0" [ngClass]="{'d-none':!sesion.viewFree}">
                <app-dash-pay-option [id]="'PRO'"></app-dash-pay-option>
            </div>
            <!--Modulo buscacredito-->
            <div class="row w-100 m-auto" *ngIf="!sesion.viewFree" [ngClass]="{'d-none': (!sesion.showModuleBC) }">
                <div class="col-12 p-0">
                    <app-dash-bc-module></app-dash-bc-module>
                </div>
            </div>
            <!--Modulo buscacredito-->
            <!-- banner de google 4 -->
            <div class="col-12 p-0" *ngIf="sesion.viewFree">
                <app-dash-banner-google [id_banner]="'div-gpt-ad-1709064708199-0'" [styles]="'commercial-banner2'"></app-dash-banner-google>
            </div>
        </div>
    
</div>