<div class="modal fade p-0" tabindex="-1" role="dialog" id="loading">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content m-auto">
      <div class="modal-body pt-0">
        <div class="container-fluid">
          <div class="row text-center">
            <div class="col-12">
              <img src="assets/img/animations/Loader_MiData.gif" alt="loading">
            </div>
            <div class="col-12">
              <hr/>
            </div>
            <div class="col-md-12">
              <p class="text-center">
                <!-- <img src="assets/img/images/loader.gif" alt="loading" class="m-auto"> -->
                {{tipFinanciero}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>