<div *ngIf="isSmall; else bigInfo" [ngSwitch]="product" >
    <div *ngSwitchCase="'midc'" class="box-product-small midc" (click)="clic_home_service()" (click)="goHref(linkLogin+'&utm_source=MIDC&utm_medium=HOME&utm_campaign=BOXMIDC')">
        <div class="txt">
            <h6>¿CONOCES TU HISTORIA DE CRÉDITO GRATIS?</h6>
            <p>Regístrate y conoce <b>GRATIS</b> tus reportes en <b>DataCrédito</b>.</p>
        </div>
        <div class="boxArrow">
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
        </div>
    </div>
    <div *ngSwitchCase="'bc'" class="box-product-small bc" (click)="clic_home_service()" (click)="goHref(linkBc+'?utm_source=MIDC&utm_medium=HOME&utm_campaign=BOXBC')">
        <div class="txt">
            <h6>¿BUSCAS EL CRÉDITO PERFECTO PARA TI?</h6>
            <p>Encuentra con nuestros aliados <b>OFERTAS</b> ajustadas a tu perfil financiero <b>¡GRATIS!</b></p>
        </div>
        <div class="boxArrow">
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
        </div>
    </div>
    <div *ngSwitchCase="'pntd'" class="box-product-small pntd" (click)="clic_home_service()" (click)="goHref(linkLandingPAD+'?utm_source=MIDC&utm_medium=HOME&utm_campaign=BOXPAD')">
        <div class="txt">
            <h6>¿TIENES DEUDAS PENDIENTES?</h6>
            <p><b>NEGOCIA</b> y <b>PAGA</b> tus deudas con nuestros aliados de forma rápida y sencilla.</p>
        </div>
        <div class="boxArrow">
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
        </div>
    </div>
</div>
<ng-template #bigInfo [ngSwitch]="product">
    <div *ngSwitchCase="'app'" class="contProduct app">
        <div class="txtPlay">
            <p><span>AÚN MÁS FÁCIL:</span><br>Únete a las <b>MILES DE PERSONAS</b> que llevan sus finanzas en la palma de su mano.</p>
            <br/>
            <a (click)="clic_home_app()" href="https://play.google.com/store/apps/details?id=com.midatacredito">
                <img class="imgPad" src="assets/img/home/Logo_PlayStore_Morado.svg" alt="app" title="app" />                    
            </a>
            <div class="d-none d-md-block">
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
        </div>
        <button (click)="clic_home_app()" (click)="goHref('https://play.google.com/store/apps/details?id=com.midatacredito')">Descargar APP</button>
    </div>
    <div *ngSwitchCase="'midc'" class="contProduct midc">
        <div class="boxHash">
            <div class="hash">
                <p>#MIDATAInteractivo</p>
                <i class="fa fa-sort-down" aria-hidden="true"></i>
            </div>
        </div>
        <p class="l-h-30"><b>INTERACTÚA</b> con Midatacrédito y conoce todas las herramientas necesarias para tomar el control de tu vida financiera.&#178;</p>
        <div class="iCarrousel" >
            <div class="iCard iBack d-md-none d-block" (click)="moveBenefit(false)">
                <div  *ngFor="let benefitPlan of benefitsPlan" class="icon-space right" [ngClass]="{'show':benefitPlan.id == benefitBefore.id }">
                    <img class="cinta" [src]="benefitPlan.icon" alt="{{benefitBefore.name}}" title="{{benefitBefore.name}}"/>
                </div>
                <p class="titleCard title-card-secon" [innerHtml]="benefitBefore.titleHtml"></p>
                <div class="txtCard">
                    <i class="fa fa-sort-up" aria-hidden="true"></i>
                    <p [innerHtml]="benefitBefore.comment"></p>
                </div>
            </div>
            <div class="iCard">
                <div *ngFor="let benefitPlan of benefitsPlan" class="icon-space" [ngClass]="{'show':benefitPlan.id == benefitMain.id }">
                    <img  class="cinta" [src]="benefitPlan.icon" alt="{{benefitMain.name}}" title="{{benefitMain.name}}" />                        
                </div>
                <p class="titleCard title-card-main" [innerHtml]="benefitMain.titleHtml"></p>
                <div class="txtCard">
                    <i class="fa fa-sort-up" aria-hidden="true"></i>
                    <p [innerHtml]="benefitMain.comment"></p>
                </div>
            </div>
            <div class="iCard iBack" (click)="moveBenefit(true)">
                <div *ngFor="let benefitPlan of benefitsPlan" class="icon-space" [ngClass]="{'show':benefitPlan.id == benefitAfter.id }">
                    <img class="cinta" [src]="benefitPlan.icon" alt="{{benefitAfter.name}}" title="{{benefitAfter.name}}" />
                </div>
                <p class="titleCard title-card-secon" [innerHtml]="benefitAfter.titleHtml"></p>
                <div class="txtCard">
                    <i class="fa fa-sort-up" aria-hidden="true"></i>
                    <p [innerHtml]="benefitAfter.comment"></p>
                </div>
            </div>
        </div>
        <button (click)="clic_home_shop_plan(benefitMain.plan)" (click)="goHref(benefitMain.url)">Comprar Plan</button>
    </div>
    <div *ngSwitchCase="'bc'" class="contProduct bc">
        <div class="boxHash">
            <div class="hash">
                <p>#OfertasATuMEDIDA</p>
                <i class="fa fa-sort-down" aria-hidden="true"></i>
            </div>
        </div>
        <p><b>BUSCAR UN CRÉDITO:</b><br>Con las ofertas de productos financieros ajustados a perfil de crédito, estás a un paso de <b>CONSTRUIR</b> tu futuro financiero.&#179;</p>
        <div class="iCarrousel">
            <div class="iCard iBack first show"  (click)="moveCategorie(false)">
                <img *ngFor="let categorie of categoriesBc" [ngClass]="{'show':categorie.id == categorieBefore.id }" class="img-cover" [src]="categorie.img" alt="{{categorie.name}}" title="{{categorie.name}}" />
                <p class="titleCard" [innerHtml]="categorieBefore.titleHtml"></p>
                <div class="txtCard">
                    <i class="fa fa-sort-up" aria-hidden="true"></i>
                    <img [src]="categorieBefore.icon" alt="{{categorieBefore.name}}" title="{{categorieBefore.name}}">
                    <p [innerHtml]="categorieBefore.comment"></p>
                </div>
            </div>
            <div class="iCard show" >
                <img *ngFor="let categorie of categoriesBc" [ngClass]="{'show':categorie.id == categorieMain.id }" class="img-cover" [src]="categorie.img" alt="{{categorie.name}}" title="{{categorie.name}}"  />
                <p class="titleCard" [innerHtml]="categorieMain.titleHtml"></p>
                <div class="txtCard">
                    <i class="fa fa-sort-up" aria-hidden="true"></i>
                    <img [src]="categorieMain.icon" alt="{{categorieMain.name}}" title="{{categorieMain.name}}">
                    <p [innerHtml]="categorieMain.comment"></p>
                </div>
            </div>
            <div class="iCard iBack show"(click)="moveCategorie(true)">
                <img  *ngFor="let categorie of categoriesBc" [ngClass]="{'show':categorie.id == categorieAfter.id }"  class="img-cover" [src]="categorie.img" alt="{{categorie.name}}" title="{{categorie.name}}"  />
                <p class="titleCard" [innerHtml]="categorieAfter.titleHtml"></p>
                <div class="txtCard">
                    <i class="fa fa-sort-up" aria-hidden="true"></i>
                    <img [src]="categorieAfter.icon" alt="{{categorieAfter.name}}" title="{{categorieAfter.name}}">
                    <p [innerHtml]="categorieAfter.comment"></p>
                </div>
            </div>
        </div>
        <button (click)="clic_home_two_services('Ver Ofertas')" (click)="goHref(categorieMain.url)">Ver Ofertas</button>
    </div>
    <div *ngSwitchCase="'pntd'" class="contProduct pntd">
        <div class="boxHash left-hash">
            <div class="hash">
                <p>#NEGOCIARTusDeudas</p>
                <i class="fa fa-sort-down" aria-hidden="true"></i>
            </div>
        </div>
        <p><b>SALIR DE DEUDAS:</b><br>Conoce las formas de <b>PONERTE AL DÍA</b> con tus obligaciones en mora, negocia con el aliado y paga al instante.</p>
        <img class="imgPad" src="assets/img/home/Icono_PAD.svg" alt="Ponte al día" title="Ponte al día"/>
        <button (click)="clic_home_two_services('Ponerme al Día')" (click)="goHref(this.linkLogin+'&view=pntd&utm_source=MIDC&utm_medium=HOME&utm_campaign=BOXPAD2')">Ponerme al Día</button>
    </div>
</ng-template>