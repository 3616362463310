<div class="row main-prox">
  <div class="col-12">
    <div class="row head-prox">
      <div class="col-12">
        <div class="row">
          <div class="col-4 col-md-2">
            <img src="assets/img/images/Icono_proximo_credito.svg" alt="próximo crédito" />
          </div>
          <div class="col-8 col-md-10">
            <h2>¿Qué tipo de crédito necesitas?</h2>
            <p class="d-none d-md-block">
              Calcula cuál es la probabilidad y el monto que podrías solicitar para tu próximo crédito
            </p>
          </div>
          <div class="col-12 d-block d-md-none">
            <p>
              Calcula cuál es la probabilidad y el monto que podrías solicitar para tu próximo crédito
            </p>
          </div>
          <div class="col-12 sub-tittle">
            <p><b>El simulador está construido con base a tu perfil de crédito y en el comportamiento de la población en
                Colombia que tiene un perfil similar al tuyo.</b></p>
            <p>La máxima deuda recomendada es el monto máximo, después del cual las personas con un perfil similar al
              tuyo empiezan a tener problemas de incumplimientos en sus pagos.</p>
          </div>
        </div>
      </div>
    </div>
    <div id='div-gpt-ad-1716905815303-0' class="commercial-banner-mobile" [ngClass]="checkBannerProximoCredito()"></div>
    <div class="row content-prox">
      <div class="col-12">
        <div class="row box-prox">
          <div class="col-12">
            <div class="head-box">
              <h2><b>Simulador de Crédito</b></h2>
              <p>Ingresa los siguientes datos para poder darte nuestra recomendación:</p>
            </div>
            <div class="row box-credito">
              <div class="col-md-6 col-12">
                <div class="row">
                  <div class="col-12">
                    <h2>Datos Básicos</h2>
                  </div>
                  <div class="col-md-6">
                    <h3>Ingresos</h3>
                  </div>
                  <div class="col-md-6">
                    <div class="input-custom">
                      <input type="text" class="input-credito form-control value" currencyMask #ingreso="ngModel" id="ingreso"
                        required="required" [(ngModel)]="ingresoVal"
                        [ngClass]="{'is-valid': ingreso.valid, 'is-invalid': ( (ingreso.errors?.['required'] || ingreso.errors?.['pattern']) && ingreso.touched ) }"
                        pattern="^0*([1-8][0-9]{5}|9[0-8][0-9]{4}|99[0-8][0-9]{3}|999[0-8][0-9]{2}|9999[0-8][0-9]|99999[0-9]|[1-8][0-9]{6}|9[0-8][0-9]{5}|99[0-8][0-9]{4}|999[0-8][0-9]{3}|9999[0-8][0-9]{2}|99999[0-8][0-9]|999999[0-9]|[1-8][0-9]{7}|9[0-8][0-9]{6}|99[0-8][0-9]{5}|999[0-8][0-9]{4}|9999[0-8][0-9]{3}|99999[0-8][0-9]{2}|999999[0-8][0-9]|9999999[0-9]|100000000)$"
                        placeholder="Ej:$5.000.000">
                        <div *ngIf="ingreso?.errors?.['required']" class="invalid-feedback">
                          Campo obligatorio.
                        </div>
                        <div *ngIf="ingreso?.errors?.['pattern']" class="invalid-feedback">
                          Valor mínimo $100.000
                        </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <h3>Gastos Financieros</h3>
                  </div>
                  <div class="col-md-6">
                    <div class="input-custom">
                      <input type="text" class="input-credito form-control value" currencyMask #gastosFin="ngModel" id="gastosFin"
                        required="required" [(ngModel)]="gastosFinVal"
                        pattern="^0*([1-8][0-9]{5}|9[0-8][0-9]{4}|99[0-8][0-9]{3}|999[0-8][0-9]{2}|9999[0-8][0-9]|99999[0-9]|[1-8][0-9]{6}|9[0-8][0-9]{5}|99[0-8][0-9]{4}|999[0-8][0-9]{3}|9999[0-8][0-9]{2}|99999[0-8][0-9]|999999[0-9]|[1-8][0-9]{7}|9[0-8][0-9]{6}|99[0-8][0-9]{5}|999[0-8][0-9]{4}|9999[0-8][0-9]{3}|99999[0-8][0-9]{2}|999999[0-8][0-9]|9999999[0-9]|100000000)$"
                        [ngClass]="{'is-valid': gastosFin.valid, 'is-invalid': ( (gastosFin.errors?.['required'] || gastosFin.errors?.['pattern']) && gastosFin.touched ) }"
                        placeholder="Ej:$3.000.000" />
                        <div *ngIf="gastosFin?.errors?.['required']" class="invalid-feedback">
                          Campo obligatorio.
                        </div>
                        <div *ngIf="gastosFin?.errors?.['pattern']" class="invalid-feedback">
                          Valor mínimo $100.000
                        </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <h3>Mis Gastos</h3>
                  </div>
                  <div class="col-md-6">
                    <div class="input-custom">
                      <input type="text" class="input-credito form-control value" currencyMask #misGastos="ngModel" id="misGastos"
                        required="required" [(ngModel)]="misGastosFVal"
                        pattern="^0*([1-8][0-9]{5}|9[0-8][0-9]{4}|99[0-8][0-9]{3}|999[0-8][0-9]{2}|9999[0-8][0-9]|99999[0-9]|[1-8][0-9]{6}|9[0-8][0-9]{5}|99[0-8][0-9]{4}|999[0-8][0-9]{3}|9999[0-8][0-9]{2}|99999[0-8][0-9]|999999[0-9]|[1-8][0-9]{7}|9[0-8][0-9]{6}|99[0-8][0-9]{5}|999[0-8][0-9]{4}|9999[0-8][0-9]{3}|99999[0-8][0-9]{2}|999999[0-8][0-9]|9999999[0-9]|100000000)$"                       
                        [ngClass]="{'is-valid': misGastos.valid, 'is-invalid': ( (misGastos.errors?.['required'] || misGastos.errors?.['pattern']) && misGastos.touched ) }"
                        placeholder="Ej:$500.000" />
                    </div>
                    <div *ngIf="misGastos?.errors?.['required']" class="invalid-feedback">
                      Campo obligatorio.
                    </div>
                    <div *ngIf="misGastos?.errors?.['pattern']" class="invalid-feedback">
                      Valor mínimo $100.000
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="row">
                  <div class="col-12">
                    <h2>Información de crédito</h2>
                  </div>
                  <div class="col-md-4 col-12">
                    <h3>Tasa</h3>
                  </div>
                  <div class="col-md-4 col-6">
                    <div class="input-custom">
                      <input type="text" class="input-credito form-control value" type="text" required="required" id="tasaInteres"
                        #tasa="ngModel" placeholder="1.2%" currencyMask pattern="^[0-4]+([.][0-9]+)?$"
                        [ngClass]="{'is-valid': tasa.valid, 'is-invalid': ( (tasa.errors?.['required'] || tasa.errors?.['pattern']) && tasa.touched ) }"
                        [(ngModel)]="tasaVal"/>
                        <div *ngIf="tasa?.errors?.['required']" class="invalid-feedback">
                          Campo obligatorio.
                        </div>
                        <div *ngIf="tasa?.errors?.['pattern']" class="invalid-feedback">
                          Tasa de 0 a 4.9%
                        </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-6">
                    <p>
                      Tasa de interés M.V. (mes vencido)
                    </p>
                  </div>
                  <div class="col-md-4 col-12">
                    <h3>Plazo</h3>
                  </div>
                  <div class="col-md-4 col-6">
                    <div class="input-custom">
                      <input type="text" id="plazoParam" #plazo="ngModel" class="input-credito form-control text-center"
                        [(ngModel)]="plazoVal"
                        [ngClass]="{'is-valid': plazo.valid, 'is-invalid': ( (plazo.errors?.['required'] || plazo.errors?.['pattern']) && plazo.touched ) }"
                        pattern="^0*([1-9]|[1-8][0-9]|9[0-9]|[1-4][0-9]{2}|500)$" placeholder="60" />
                        <div *ngIf="plazo?.errors?.['required']" class="invalid-feedback">
                          Campo obligatorio.
                        </div>
                        <div *ngIf="plazo?.errors?.['pattern']" class="invalid-feedback">
                          Plazo de 1 a 500 meses
                        </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-6">
                    <p>
                      Meses
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 box-btn-activar" *ngIf="!llamarServicio">
                <button class="btn-activar" [disabled]="!verifyInfo()" (click)="calcular(1)">Calcular Montos<i
                    class="fa fa-angle-right"></i></button>
              </div>
              <div class="col-12" *ngIf="llamarServicio">
                <app-micro-loader [texto]="textoMicroLoader" [error]="simulacionError"
                  [textoError]="textErrorMicroLoader">
                </app-micro-loader>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row acordeon" id="tab-1">
              <div class="col-12 tab borde-abajo-tab">
                <div class="row alig-center">
                  <div class="col-md-2 col-6 order-1 order-md-0">
                    <div class="hipotecario contGrafica" [ngClass]="[checkProbability('HIP')]">
                      <div class="grafica"></div>
                    </div>
                  </div>
                  <div class="col-md-2 col-6 order-1 order-md-0">
                    <p class="num-probabilidad">{{ sesion.probabilidad?.objResult.HIP }}%</p>
                    <p class="text-probabilidad">de probabilidad</p>
                  </div>
                  <div class="col-md-4 col-12 order-0 order-md-1">
                    <h2>Crédito Hipotecario</h2>
                  </div>
                  <div class="col-md-4 col-12 order-2">
                    <button (click)="openAcordeon(2)">Ver recomendacion<i class="fa fa-caret-down"></i></button>
                  </div>
                  <div class="col-12 order-3 content-tab" id="tab-2">
                    <div class="row info-tips">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12">
                            <div class="row align-items-center">
                              <div class="col-md-4">
                                <h2 *ngIf="simulacionResponseHip?.objResult.RecomendacionesDeudas != undefined">Te
                                  sugerimos que</h2>
                                <h2 *ngIf="simulacionResponseHip?.objResult.RecomendacionesDeudas == undefined">Nuestra
                                  recomendación para ti</h2>
                              </div>
                              <div class="col-md-8">
                                <div class="tip">
                                  <div class="subTip"
                                    *ngIf="simulacionResponseHip?.objResult.RecomendacionesDeudas != undefined">
                                    <p class="textSimple">{{ simulacionResponseHip?.objResult.RecomendacionesDeudas}}</p>
                                    <p class="textSimple">{{ simulacionResponseHip?.objResult.RecomendacionesIngresos}}
                                    </p>
                                    <p class="textSimple">{{ simulacionResponseHip?.objResult.RecomendacionesScore}}</p>
                                    <p class="textSimple">Reduce tus cuotas mensuales por lo menos en el siguiente monto
                                      <b>{{ simulacionResponseHip?.objResult.ValorExcedido.replace(",", '.') | currency:'COP':'':'1.0-0'}}</b>
                                    </p>
                                  </div>
                                  <div class="subTip"
                                    *ngIf="simulacionResponseHip?.objResult.RecomendacionesDeudas == undefined">
                                    <p class="titleTip">Monto</p>
                                    <p class="descTip">$
                                      {{ simulacionResponseHip?.objResult.ValorTotalCredito.replace(",", '.')  | currency:'COP':'':'1.0-0'}}
                                    </p>
                                    <p class="titleTip">Cuota menssual:</p>
                                    <p class="descTip">$
                                      {{ simulacionResponseHip?.objResult.ValorCuotaMensual.replace(",", '.')  | currency:'COP':'':'1.0-0'}}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 tab borde-abajo-tab">
                <div class="row alig-center">
                  <div class="col-md-2 col-6 order-1 order-md-0">
                    <div class="libreinversion contGrafica" [ngClass]="[checkProbability('CAB')]">
                      <div class="grafica"></div>
                    </div>
                  </div>
                  <div class="col-md-2 col-6 order-1 order-md-0">
                    <p class="num-probabilidad">{{ sesion.probabilidad?.objResult.CAB }}%</p>
                    <p class="text-probabilidad">de probabilidad</p>
                  </div>
                  <div class="col-md-4 col-12 order-0 order-m-1">
                    <h2>Crédito libre Inversión</h2>
                  </div>
                  <div class="col-md-4 col-12 order-2">
                    <button (click)="openAcordeon(4)">Ver recomendacion<i class="fa fa-caret-down"></i></button>
                  </div>
                  <div class="col-12 order-3 content-tab" id="tab-4">
                    <div class="row info-tips">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12">
                            <div class="row align-items-center">
                              <div class="col-md-4">
                                <h2 *ngIf="simulacionResponseLin?.objResult.RecomendacionesDeudas != undefined">Te
                                  sugerimos que</h2>
                                <h2 *ngIf="simulacionResponseLin?.objResult.RecomendacionesDeudas == undefined">Nuestra
                                  recomendación para ti</h2>
                              </div>
                              <div class="col-md-8">
                                <div class="tip">
                                  <div class="subTip"
                                    *ngIf="simulacionResponseLin?.objResult.RecomendacionesDeudas != undefined">
                                    <p class="textSimple">{{ simulacionResponseLin?.objResult.RecomendacionesDeudas}}</p>
                                    <p class="textSimple">{{ simulacionResponseLin?.objResult.RecomendacionesIngresos}}
                                    </p>
                                    <p class="textSimple">{{ simulacionResponseLin?.objResult.RecomendacionesScore}}</p>
                                    <p class="textSimple">Reduce tus cuotas mensuales por lo menos en el siguiente monto
                                      <b>{{ simulacionResponseLin?.objResult.ValorExcedido.replace(",", '.') | currency:'COP':'':'1.0-0'}}</b>
                                    </p>
                                  </div>
                                  <div class="subTip"
                                    *ngIf="simulacionResponseLin?.objResult.RecomendacionesDeudas == undefined">
                                    <p class="titleTip">Monto</p>
                                    <p class="descTip">$
                                      {{ simulacionResponseLin?.objResult.ValorTotalCredito.replace(",", '.')  | currency:'COP':'':'1.0-0'}}
                                    </p>
                                    <p class="titleTip">Cuota menssual:</p>
                                    <p class="descTip">$
                                      {{ simulacionResponseLin?.objResult.ValorCuotaMensual.replace(",", '.')  | currency:'COP':'':'1.0-0'}}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 tab borde-abajo-tab">
                <div class="row alig-center">
                  <div class="col-md-2 col-6 order-1 order-md-0">
                    <div class="tarjetascredito contGrafica" [ngClass]="[checkProbability('TDC')]">
                      <div class="grafica"></div>
                    </div>
                  </div>
                  <div class="col-md-2 col-6 order-1 order-md-0">
                    <p class="num-probabilidad">{{ sesion.probabilidad?.objResult.TDC }}%</p>
                    <p class="text-probabilidad">de probabilidad</p>
                  </div>
                  <div class="col-md-4 col-12 order-0 order-md-1">
                    <h2>Tarjetas de Crédito</h2>
                  </div>
                  <div class="col-md-4 col-12 order-2">
                    <button (click)="openAcordeon(3)">Ver recomendacion<i class="fa fa-caret-down"></i></button>
                  </div>
                  <div class="col-12 order-3 content-tab" id="tab-3">
                    <div class="row info-tips">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12">
                            <div class="row align-items-center">
                              <div class="col-md-4">
                                <h2 *ngIf="simulacionResponseTdc?.objResult.RecomendacionesDeudas != undefined">Te
                                  sugerimos que</h2>
                                <h2 *ngIf="simulacionResponseTdc?.objResult.RecomendacionesDeudas == undefined">Nuestra
                                  recomendación para ti</h2>
                              </div>
                              <div class="col-md-8">
                                <div class="tip">
                                  <div class="subTip"
                                    *ngIf="simulacionResponseTdc?.objResult.RecomendacionesDeudas != undefined">
                                    <p class="textSimple">{{ simulacionResponseTdc?.objResult.RecomendacionesDeudas}}</p>
                                    <p class="textSimple">{{ simulacionResponseTdc?.objResult.RecomendacionesIngresos}}
                                    </p>
                                    <p class="textSimple">{{ simulacionResponseTdc?.objResult.RecomendacionesScore}}</p>
                                    <p class="textSimple">Reduce tus cuotas mensuales por lo menos en el siguiente monto
                                      <b>{{ simulacionResponseTdc?.objResult.ValorExcedido.replace(",", '.') | currency:'COP':'':'1.0-0'}}</b>
                                    </p>
                                  </div>
                                  <div class="subTip"
                                    *ngIf="simulacionResponseTdc?.objResult.RecomendacionesDeudas == undefined">
                                    <p class="titleTip">Máximo cupo</p>
                                    <p class="descTip">$
                                      {{ simulacionResponseTdc?.objResult.ValorAdicionalCredito.replace(",", '.')  | currency:'COP':'':'1.0-0'}}
                                    </p>
                                    <p class="titleTip">Cupo actual</p>
                                    <p class="descTip">$
                                      {{ simulacionResponseTdc?.objResult.ValorCupoActual.replace(",", '.')  | currency:'COP':'':'1.0-0'}}
                                    </p>
                                    <p class="titleTip">Diferencia</p>
                                    <p class="descTip">$
                                      {{ simulacionResponseTdc?.objResult.ValorCupoActual - simulacionResponseTdc?.objResult.ValorAdicionalCredito.replace(",", '.')  | currency:'COP':'':'1.0-0'}}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row boxTipsAll">
                  <div class="col-12">
                    <h2>Ten en cuenta</h2>
                  </div>
                  <div class="col-md-4">
                    <p class="numero-tip">1</p>
                    <p>La deuda recomendada está bajo el supuesto que sólo abrirás un crédito a la vez, ya sea
                      un crédito hipotecario, un préstamo de libre inversión o una tarjeta de crédito.</p>
                  </div>
                  <div class="col-md-4">
                    <p class="numero-tip">2</p>
                    <p>Si acabas de adquirir una nueva deuda la recomendación es que esperes un tiempo prudente
                      de tres meses antes de solicitar un nuevo préstamo, para adaptar tu flujo de caja a la
                      nueva obligación.</p>
                  </div>
                  <div class="col-md-4">
                    <p class="numero-tip">3</p>
                    <p class="textoTip">El simulador arroja un resultado estadístico, vale la pena tener en cuenta otras
                      consideraciones, como la variabilidad de tus ingresos y tus gastos. En caso de presentar
                      un flujo de caja variable, la recomendación es ser conservador en el cálculo de Ingresos y
                      Gastos (menor ingreso y mayor gasto).</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id='div-gpt-ad-1716905815303-1' class="commercial-banner" [ngClass]="checkBannerProximoCredito2()"></div>
</div>