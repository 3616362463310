<div class="container-fluid p-0">
	<div class="row justify-content-center">
		<div class="col-md-6">
			<app-micro-loader></app-micro-loader>
		</div>
	</div>
</div>
<!-- <section class="blog-midc">
	<div class="header">
		<div class="left">
			<h1>{{articleUrl.title}}</h1>
			<div class="date-author">
				<div class="logo">
					<img src="assets/img/blog/Thumbnail_Midc.svg" alt="Write Midatacrédito" title="Write Midatacrédito" />
				</div>
				<div class="content-txt">
					<p>Por: {{articleUrl.author}}</p>
					<p>{{articleUrl.date}}</p>
				</div>
			</div>
		</div>
		<div class="right">
			<img src="{{articleUrl.imgHeader}}" alt="Blog header Midatacrédito" title="Blog header Midatacrédito" />
		</div>		
	</div>
	<div class="content-blog">
		<img src="{{articleUrl.imgArticle}}" alt="{{articleUrl.title}}" title="{{articleUrl.title}}" [ngClass]="articleUrl.id == 4 || articleUrl.id == 6 || articleUrl.id == 7 || articleUrl.id == 8 ? 'fl-right' : 'fl-left'" />
		<div [innerHTML]="articleUrl.description"></div>
		<div class="back-next">
			<div class="pag">
				<a class="box" href="/blog/{{itemsBack.meta}}">
					<div class="arrow">
						<img src="assets/img/blog/Navegador_anterior.svg" alt="Atrás" title="Atrás" />
					</div>
					<div class="txt">
						<p>Anterior</p>
						<p class="title-back">{{itemsBack.title}}</p>
					</div>
				</a>
			</div>
			<div class="pag">
				<a class="box" href="/blog/{{itemsNext.meta}}">
					<div class="txt">
						<p>Siguiente</p>
						<p class="title-back">{{itemsNext.title}}</p>
					</div>
					<div class="arrow">
						<img src="assets/img/blog/Navegador_Siguiente.svg" alt="Atrás" title="Atrás" />
					</div>					
				</a>
			</div>
		</div>
	</div>
</section>
-->