import { Component, OnInit } from '@angular/core';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-planes',
  templateUrl: './planes.component.html',
  styleUrls: ['./planes.component.scss']
})
export class PlanesComponent implements OnInit {
	rutaS3:string=environment.enlaceS3 + environment.s3.planesYPagos;

	planes = [
		{
			name: 'Acceso Gratuito',
			description: 'Con tu ACCESO GRATUITO puedes:',
			bullets: [
				'Consultar tu historia de crédito detallada.',
				'Reclamar a la entidad si tienes algún problema.',
				'Revisar si las entidades consultaron tu historial para concederte un crédito.',
				'Presentar alertas al sistema financiero en caso de:',
				'Suplantación.',
				'Perdiste tu cédula.',
				'Te robaron la cédula.',
				'Vives en el exterior.'

			],
			price: [
				{
					price: 'ACCESO GRATUITO',
					cycle: ''
				}				
			]
		},
		{
			name: 'Plan Perfil de Crédito',
			img: 'assets/img/landings/planes/Icono_Plan_Perfil.svg',
			description: 'Adicional a los beneficios del ACCESO GRATUITO puedes:',
			bullets: [
				'Conocer tu puntaje o score de crédito el cual consultan las entidades al momento de adquirir un producto o servicio.',
				'Recibir alertas inmediatas en cuanto las entidades reporten o consulten tu historial.',
				'Calcular la posibilidad de éxito para la solicitud de créditos y deudas, por medio de un simulador.',
				'Recibir asesoría virtual para mejorar tu score y acceso al crédito.'
			],
			price: [
				{
					price: this.sesion.planes.planes.per.mensual[0],
					cycle: 'Valor Mensual'
				},
				{
					price: this.sesion.planes.planes.per.trimestral[0],
					cycle: 'Valor Trimestral'
				},
				{
					price: this.sesion.planes.planes.per.anual[0],
					cycle: 'Valor Anual'
				},
			]
		},
		{
			name: 'Plan Protege tus Datos',
			img: 'assets/img/landings/planes/Icono_Plan_Protege.svg',
			description: 'Adicional los beneficios del PLAN PERFIL DE CRÉDITO puedes:',
			bullets: [
				'Realizar un monitoreo y consultar la exposición de tu información personal en la dark web, para protegerla.',
				'Recibir notificaciones cuando la información personal está comprometida en la dark web.'
			],
			price: [
				{
					price: this.sesion.planes.planes.ant.mensual[0],
					cycle: 'Valor Mensual'
				},
				{
					price: this.sesion.planes.planes.ant.trimestral[0],
					cycle: 'Valor Trimestral'
				},
				{
					price: this.sesion.planes.planes.ant.anual[0],
					cycle: 'Valor Anual'
				},
			]
		},
		{
			name: '*Plan Premium',
			img: 'assets/img/landings/planes/Icono_Plan_Premium.svg',
			description: 'Adicional a todos los beneficios del PLAN PROTEGE puedes:',
			bullets: [
				'Adquirir un seguro Anti-Fraude en caso de robo de identidad o tarjetas de crédito.',
				'Recibir Asesoría Legal personalizada en caso de que la necesites.',
				'Usar la línea de atención prioritaria que tienes por la suscripción al Plan Premium.'
			],
			price: [
				{
					price: this.sesion.planes.planes.pre.mensual[0],
					cycle: 'Valor Mensual'
				},
				{
					price: this.sesion.planes.planes.pre.trimestral[0],
					cycle: 'Valor Trimestral'
				},
				{
					price: this.sesion.planes.planes.pre.anual[0],
					cycle: 'Valor Anual'
				},
			]
		},
	]

	bulletInside: any;
	constructor(
		public sesion: SesionService,
		private dlService: DataLayersService
	) {}

	ngOnInit() {
		// GA4 - user
		this.dlService.dl_userId( null, 'anonimo' );
		this.bulletInside = this.planes[0].bullets;	
	}

}
