import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ArticlesService } from 'src/app/core/services/articles.service';
import { Title, Meta } from '@angular/platform-browser';
import { MetatagsService } from 'src/app/core/services/metatags.service';
import { SesionService } from '../../core/services/sesion.service';

@Component({
	selector: 'app-blog',
	templateUrl: './blog.component.html',
	styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

	articleUrl: any;
	randItems: any;
	itemsNext: any;
	itemsBack: any;

	constructor(
		public blog: ArticlesService,
		private activateRoute: ActivatedRoute,
		public metaServ: MetatagsService,
		private _title: Title,
		private _metaService: Meta,
		public sesion: SesionService,
		private router: Router
	) { 
		this.goArticle();
	}

	ngOnInit() {
		// this.sesion.validateSession(false);
		// this.goArticle();
	}

	goArticle() {
		var urlArticle = this.activateRoute.snapshot.paramMap.get('article');
		if (urlArticle != null) {
			window.location.href = `https://blog.midatacredito.com/${urlArticle}`;
			// this.articleUrl = this.blog.articles.find(ele => urlArticle == ele.meta)
		} else {
			this.router.navigate(['/error']);
		}
		/*this.metaData()
		this.goNext();
		this.goBack();*/
	}
	
	goBack() {
		let idBack = this.articleUrl.id - 1;
		this.itemsBack = this.blog.articles.find(ele => idBack == ele.id)
		if (this.itemsBack == undefined) {
			let fuck = this.blog.articles.length;
			this.itemsBack = this.blog.articles.find(ele => fuck == ele.id)
		}
		return this.itemsBack
	}
	goNext() {
		let idNext = this.articleUrl.id + 1;
		this.itemsNext = this.blog.articles.find(ele => idNext == ele.id)
		if (this.itemsNext == undefined) {
			this.itemsNext = this.blog.articles.shift();
		}
		return this.itemsNext
	}

	metaData(): any {
		switch (this.articleUrl.id) {
			case 4:
				this._title.setTitle('Consejos para organizar las compras navideñas en este 2021');
				this._metaService.updateTag({
					name: 'description', content: '¡Felices fiestas, te desea Midatacrédito! Siempre las pequeñas acciones son las que comienzan con un gran cambio. ¡Continúa leyendo!'
				});
				this._metaService.addTag({
					name: 'author', content: 'Ecs - Experian'
				});
				this._metaService.updateTag({
					name: 'keywords', content: 'navidad, compras navideñas, compras, consejos para compras navideñas, navidad, compras en navidad, regalos de navidad, compra online, datacredito, centrales de riesgo, datacredito experian, datacredito empresas, datacredito colombia, datacredito gratis, midatacredito, experian datacredito, data credito, data experian, salud financiera'
				});
				break;
			case 5:
				this._title.setTitle('Explicación breve sobre la Amnistía - Ley de Borrón y Cuenta Nueva');
				this._metaService.updateTag({
					name: 'description', content: 'La intención de esta nueva ley, Borrón y Cuenta Nueva, no es generar una cultura del no pago en Colombia. Antes de pagar, ¡infórmate!'
				});
				this._metaService.addTag({
					name: 'author', content: 'Ecs - Experian'
				});
				this._metaService.updateTag({
					name: 'keywords', content: 'ley de borron y cuenta nueva, ley de borrón y cuenta nueva 2020 hoy, ley de borrón y cuenta nueva últimas noticias, ley de borron y cuenta nueva datacredito, que es la ley de borron y cuenta nueva, cuando empieza a regir la ley de borrón y cuenta nueva, cual es la ley de borrón y cuenta nueva, amnistia, amnistía que es, amnistía en colombia, que es ley de amnistía, datacredito, centrales de riesgo, datacredito experian, datacredito empresas, datacredito colombia, datacredito gratis midatacredito, experian datacredito, data credito, data experian, salud financiero, Amnistía'
				});
				break;
			case 6:
				this._title.setTitle('¿Cómo sacarle el mejor provecho a la prima navideña? ');
				this._metaService.updateTag({
					name: 'description', content: 'Recuerda que un dinero bien administrado te deja mayores beneficios, para ello sigue estos consejos y administra bien tu prima en esta navidad. '
				});
				this._metaService.addTag({
					name: 'author', content: 'Ecs - Experian'
				});
				this._metaService.updateTag({
					name: 'keywords', content: 'prima navideña, primas de navidad, ahorrar, gastos, compras, invertir, crédito, ley de borron y cuenta nueva, ley de borrón y cuenta nueva 2020 hoy, ley de borron y cuenta nueva datacredito, que es la ley de borron y cuenta nueva, amnistía en colombia, datacredito, centrales de riesgo, datacredito experian, datacredito empresas, datacredito colombia, datacredito gratis midatacredito, experian datacredito, data credito, data experian, salud financiera'
				});
				break;
			case 7:
				this._title.setTitle('¿Qué rol juegan las compras innecesarias? Salud Financiera');
				this._metaService.updateTag({
					name: 'description', content: '¡Recuerda! Tú tienes el control de cómo gastas y administras tu dinero. Aprende cómo reducir los gastos innecesarios y mantener una buena salud financiera. '
				});
				this._metaService.addTag({
					name: 'author', content: 'Ecs - Experian'
				});
				this._metaService.updateTag({
					name: 'keywords', content: 'compras innecesarias, gastos, compras, finanzas, finanzas personales, salud financiera, tipos de gastos, Ley de Borrón y Cuenta Nueva, datacredito, centrales de riesgo, datacredito experian, datacredito empresas, datacredito colombia, datacredito gratis, midatacredito, experian datacredito, data credito, data experian, salud financiera'
				});
				break;
			case 8:
				this._title.setTitle('¿Cómo salir de deudas atrasadas y cuál deuda pagar primero? ');
				this._metaService.updateTag({
					name: 'description', content: 'Las deudas consumen los ingresos, crean estrés, perjudican las relaciones y tu trabajo. Elimina tus deudas para mejorar tu salud financiera más tranquilo. '
				});
				this._metaService.addTag({
					name: 'author', content: 'Ecs - Experian'
				});
				this._metaService.updateTag({
					name: 'keywords', content: 'deudas, deudas atrasadas, como pagar deudas atrasadas, pagar deudas, morosidad, datacredito, centrales de riesgo, datacredito experian, datacredito empresas, datacredito colombia, datacredito gratis, midatacredito, experian datacredito, data credito, data experian, salud financiera'
				});
				break;
			case 9:
				this._title.setTitle('¿Qué se puede comprar en el día sin IVA y cómo sacarle el máximo beneficio?');
				this._metaService.updateTag({
					name: 'description', content: 'Prepárate este 2022 para comprar, ahorrar y reactivar la economía. Aprovecha para comprar lo que siempre has querido en los días sin IVA.'
				});
				this._metaService.addTag({
					name: 'author', content: 'Ecs - Experian'
				});
				this._metaService.updateTag({
					name: 'keywords', content: 'día sin iva, dia sin iva en colombia, dia sin iva televisores, dia sin iva productos, dia sin iva fechas, dia sin iva cuando es, cuando hay dia sin iva, cuando es dia sin iva en colombia, datacredito, experian, datacredito empresas, datacredito colombia, datacredito gratis, midatacredito, experian datacredito, data credito, data experian'
				});
				break;
			case 10:
				this._title.setTitle('7 Mitos sobre cómo funciona una tarjeta de crédito ');
				this._metaService.updateTag({
					name: 'description', content: 'Las tarjetas de crédito son de funcionamiento sencillo a la hora de hacer compras y realizar avances. ¡Aprende más sobre mitos, consejos y soluciones!'
				});
				this._metaService.addTag({
					name: 'author', content: 'Ecs - Experian'
				});
				this._metaService.updateTag({
					name: 'keywords', content: 'tarjetas de crédito, avances tarjeta, finanzas, finanzas personales, planeacion financiera, datacredito, centrales de riesgo, datacredito experian, datacredito empresas, datacredito colombia, datacredito gratis, midatacredito, experian datacredito, data credito, data experian, salud financiera '
				});
				break;
			default:
				return '';
		}
	}

}
