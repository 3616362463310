<div class="sponsor">
    <div class="marqueeO">   
        <div class="box-move">
            <div class="logoMar" *ngFor="let sponsor of sponsors" >
                <img src="{{sponsor.icon}}" alt="{{sponsor.name}}" title="{{sponsor.name}}"/>	
            </div>  	
        </div>
        <div class="box-move">
            <div class="logoMar" *ngFor="let sponsor of sponsors" >
                <img src="{{sponsor.icon}}" alt="{{sponsor.name}}" title="{{sponsor.name}}"/>	
            </div>  	
        </div>
    </div>
</div>