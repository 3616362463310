<section class="plans-tyc">
	<div class="header">
		<h2>Conoce las opciones que Mi Datacrédito tiene para ti</h2>
		<p>Cada opción tiene características y beneficios, con los que te ayudaremos a lograr tus objetivos financieros.</p>
	</div>
	<div class="box-plan" *ngFor="let item of planes; let i = index">
		<div class="left-box">
			<div class="title">
				<div class="img" [ngClass]="i == 0 ? 'd-none' : 'd-block'">
					<img src="{{item.img}}" alt="item"/>
				</div>
				<div class="txt">
					<h3>{{item.name}}</h3>
					<p>{{item.description}}</p>
				</div>
			</div>
			<div class="description" [ngClass]="i == 0 ? 'd-block' : 'd-none'">
				<div class="bullet" *ngFor="let bullet of item.bullets | slice:0:4">
					<i class="fa fa-check" aria-hidden="true"></i>
					<p>{{bullet}}</p>
				</div>
				<div class="bullet">
					<ul>
						<li *ngFor="let bulletIns of bulletInside | slice:4:8">{{bulletIns}}</li>
					</ul>
				</div>
			</div>
			<div class="description" [ngClass]="i != 0 ? 'd-block' : 'd-none'">
				<div class="bullet" *ngFor="let bullet of item.bullets">
					<i class="fa fa-check" aria-hidden="true"></i>
					<p>{{bullet}}</p>
				</div>
				<div class="bullet" [ngClass]="i == 3 ? 'd-block' : 'd-none'">
					<p class="note-premium">* Tanto la póliza como la asesoría legal del Plan Premium, son servicios prestados por <span>SBS Seguros Colombia S.A.</span></p>
				</div>
			</div>
		</div>
		<div class="right-box">
			<div class="box-price" *ngFor="let price of item.price">
				<h4> {{price.price}} </h4>
				<p> {{price.cycle}} </p>
			</div>
		</div>
	</div>
	<div class="download-pdf">
		<p>Si quieres puedes descargar este documento y revisarlo en cualquier momento:</p>
		<a href="{{rutaS3}}" class="cta-download" target="_blank" rel="noopener">
			Descargar Planes y Precios
			<i class="fa fa-download" aria-hidden="true"></i>
		</a>
	</div>
</section>