import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConstantesService } from 'src/app/core/services/constantes.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { SuscripcionService } from 'src/app/core/services/suscripcion.service';
import { Plan } from '../../../core/models/plan.model';
import { DataLayersService } from 'src/app/core/services/data-layers.service';

declare var $: any;

@Component({
	selector: 'app-planes-dinamicos',
	templateUrl: './planes-dinamicos.component.html',
	styleUrls: ['./planes-dinamicos.component.scss']
})
export class PlanesDinamicosComponent implements OnInit {

	params: any;
	planUser: any;
	plansCheck: any;
	//items: any;
	paramPlan1: any;
	paramPlan2: any;
	paramPlan3: any;
	paramPlan4: any;
	tipo: any;


	constructor(
		public activatedRoute: ActivatedRoute,
		public constantes: ConstantesService,
		public suscripcion: SuscripcionService,
		public sesion: SesionService,
		private dataLayerService: DataLayersService
	) {
		this.activatedRoute.queryParams.subscribe((params): any => {
			this.params = params;
			this.planUser = this.params.utm_var;
		});
	}

	ngOnInit() {
		localStorage.clear()
		this.dynamicSPlans();
		$(window).on("scroll", function () {
			var scrollHeight = $(document).height();
			var scrollPosition = $(window).height() + $(window).scrollTop();
			if ((scrollHeight - scrollPosition) / scrollHeight === 0) {
				$(".footer-copy").css({ "padding-bottom": "30px" });
			} else {
				//$(".footer-copy").css({"padding-bottom" : "0"});
			}
		});
		if (this.sesion.user){
			this.dataLayerService.dl_userId( '.', this.sesion.planCurrently );
		  } else {
			this.dataLayerService.dl_userId( null, 'anonimo' );
		  }
	}
	dynamicSPlans() {
		switch (this.planUser) {
			case 'A3':
				return [
					this.plansCheck = this.constantes.perTrimestral,
					this.constantes.items,
					this.paramPlan1 = this.sesion.planes.planes.per,
					this.paramPlan2 = '2',
					this.paramPlan3 = this.sesion.planes.planes.per.trimestral[1],
					this.paramPlan4 = this.sesion.planes.planes.per.trimestral[0],
					//this.seleccionarPlan(this.paramPlan1, this.paramPlan2, this.paramPlan3, this.paramPlan4)
				];
			case 'A12':
				return [
					this.plansCheck = this.constantes.perAnual,
					this.constantes.items,
					this.paramPlan1 = this.sesion.planes.planes.per,
					this.paramPlan2 = '5',
					this.paramPlan3 = this.sesion.planes.planes.per.anual[1],
					this.paramPlan4 = this.sesion.planes.planes.per.anual[0],
				];
			case 'AT':
				return 'Plan Perfil de Crédito Trimestral y Anual <br>(2 Periodicidades)';
			case 'B3':
				return [
					this.plansCheck = this.constantes.antTrimestral,
					this.constantes.items,
					this.paramPlan1 = this.sesion.planes.planes.ant,
					this.paramPlan2 = '2',
					this.paramPlan3 = this.sesion.planes.planes.ant.trimestral[1],
					this.paramPlan4 = this.sesion.planes.planes.ant.trimestral[0],
				];
			case 'B12':
				return [
					this.plansCheck = this.constantes.antAnual,
					this.constantes.items,
					this.paramPlan1 = this.sesion.planes.planes.ant,
					this.paramPlan2 = '4',
					this.paramPlan3 = this.sesion.planes.planes.ant.anual[1],
					this.paramPlan4 = this.sesion.planes.planes.ant.anual[0],
				];
			case 'BT':
				return 'Plan Protege Trimestral y Anual <br>(2 Periodicidades)';
			case 'C3':
				return [
					this.plansCheck = this.constantes.proTrimestral,
					this.constantes.items,
					this.paramPlan1 = this.sesion.planes.planes.pre,
					this.paramPlan2 = '2',
					this.paramPlan3 = this.sesion.planes.planes.pre.trimestral[1],
					this.paramPlan4 = this.sesion.planes.planes.pre.trimestral[0],
				];
			case 'C12':
				return [
					this.plansCheck = this.constantes.proAnual,
					this.constantes.items,
					this.paramPlan1 = this.sesion.planes.planes.pre,
					this.paramPlan2 = '4',
					this.paramPlan3 = this.sesion.planes.planes.pre.anual[1],
					this.paramPlan4 = this.sesion.planes.planes.pre.anual[0],
				];
			case 'CT':
				return 'Plan Premium Trimestral y Anual <br>(2 Periodicidades)';
			default:
				return '';
		}
	}

	seleccionarPlan(plan: Plan, tipo: string, valorMensual: string, valorTotal: string) {
		this.suscripcion.planSeleccionado = plan;
		this.suscripcion.tipoPlan = tipo;
		this.suscripcion.valorSeleccionadoMensual = valorMensual;
		this.suscripcion.valorSeleccionadoTotal = valorTotal;
		if (this.sesion.user) {
			this.suscripcion.planSesion(true, 'LPD');
		} else {
			this.suscripcion.planSesion(false, 'LPD');
		}
	}
	scrollToElement($element: any): void {
		$element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
	}

}
