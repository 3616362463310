<section class="tc">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <a class="back" (click)="inactiveTabF()">
                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    Métodos de pago
                </a>
                <div class="box-info">
                    <form id="debitoForm" #datos="ngForm" (ngSubmit)="realizarPago(datos)" (keyup)="validarData(datos)"
                        autocomplete="off">
                        <div class="box-step" id="tcStep1">
                            <h3>Datos del titular de la Cuenta Bancaria</h3>
                            <div class="form-row">
                                <div class="form-group col-12 col-md-6">
                                    <mat-form-field>
                                        <input (focus)="focus($event)" matInput type="text" name="firstName"
                                            id="firstNamePSE" required="required" class="form-control"
                                            placeholder="Nombres" pattern="^[A-Za-zÑñáéíóúÁÉÍÓÚ ]+$"
                                            [(ngModel)]="pse.params.transaction.payer.firstName" #firstName="ngModel"
                                            [ngClass]="{'is-valid': firstName.valid, 'is-invalid': ( (firstName.errors?.['required']  ) && firstName.touched ) }">
                                        <mat-error *ngIf="firstName?.errors?.['required']"
                                            class="invalid-feedback">Campo obligatorio.</mat-error>
                                        <mat-error *ngIf="firstName?.errors?.['pattern']"
                                            class="invalid-feedback">Nombre no válido. Ingresar solo letras.</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <mat-form-field>
                                        <input (focus)="focus($event)" matInput type="text" name="lastName"
                                            id="lastNamePSE" required="required" class="form-control"
                                            placeholder="Apellidos" pattern="^[A-Za-zÑñáéíóúÁÉÍÓÚ ]+$"
                                            [(ngModel)]="pse.params.transaction.payer.lastName" #lastName="ngModel"
                                            [ngClass]="{'is-valid': lastName.valid, 'is-invalid': ( (lastName.errors?.['required']  ) && lastName.touched ) }">
                                        <mat-error *ngIf="lastName?.errors?.['required']" class="invalid-feedback">Campo
                                            obligatorio.</mat-error>
                                        <mat-error *ngIf="lastName?.errors?.['pattern']" class="invalid-feedback">Nombre
                                            no válido. Ingresar solo letras.</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-12 col-md-6">
                                    <mat-form-field>
                                        <mat-label>Tipo de documento</mat-label>
                                        <mat-select (focus)="focus($event)" name="typeidBankAccountH"
                                            id="typeidBankAccountH" required="required" class="form-control"
                                            [(ngModel)]="pse.params.transaction.extraParameters.PSE_REFERENCE2"
                                            #reference2="ngModel">
                                            <mat-option value="CC">Cédula de ciudadanía</mat-option>
                                            <mat-option value="CE">Cédula de extranjería</mat-option>
                                            <mat-option value="NIT">Número de Identificación Tributaria</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <mat-form-field>
                                        <input (focus)="focus($event)" matInput name="idUser" id="idUser" type="tel"
                                            required="required" class="form-control"
                                            pattern="^[+]?([0-9]+(?:[0-9]*)?|[0-9]+)$" placeholder="Documento"
                                            maxlength="10" [(ngModel)]="pse.params.transaction.payer.dniNumber"
                                            #identificacion="ngModel"
                                            [ngClass]="{'is-valid': identificacion.valid, 'is-invalid': ( (identificacion.errors?.['required']  ) && identificacion.touched ) }">
                                        <mat-error *ngIf="identificacion?.errors?.['required']"
                                            class="invalid-feedback">Campo obligatorio.</mat-error>
                                        <mat-error *ngIf="identificacion?.errors?.['pattern']"
                                            class="invalid-feedback">Documento no válido. Ingresar solo
                                            números.</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-12 col-md-6" id="phonedivTD">
                                    <mat-form-field>
                                        <input (focus)="focus($event)" matInput type="tel" name="cellphoneTD"
                                            id="cellphoneTD" class="form-control" placeholder="Celular"
                                            required="required" maxlength="10"
                                            [(ngModel)]="pse.params.transaction.payer.billingAddress.phone"
                                            pattern="^[+]?([0-9]+(?:[0-9]*)?|[0-9]+)$" #phone="ngModel">
                                        <mat-error *ngIf="phone?.errors?.['pattern']" class="invalid-feedback">Celular
                                            no válido. Ingresar solo números.</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="form-group col-12 col-md-6" id="citydivPSE">
                                    <mat-form-field>
                                        <mat-label>Seleccionar ciudad</mat-label>
                                        <mat-select (selectionChange)="validarData(datos)" (focus)="focus($event)" name="cityPSE" id="cityPSE"
                                        class="form-control" required="required" #city="ngModel"
                                        [(ngModel)]="pse.params.transaction.payer.billingAddress.city" (click)="resetCiudades()">
                                            <input type="text"
                                            placeholder="Buscar"
                                            (keyup)="filter()" name="filterC" id="filterC"
                                            class="inputCiudad"
                                            [(ngModel)]="filterC"
                                            (blur)="resetCiudades()">
                                        
                                            <mat-option *ngFor="let ciudad of ciudades"[value]="ciudad.COD_MUN">{{ ciudad.NAME_MUN }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="box-step" id="tcStep2">
                            <div class="form-row">
                                <div class="form-group col-12" id="addressdivTD">
                                    <mat-form-field>
                                        <input (focus)="focus($event)" matInput type="text" name="addressTD"
                                            id="addressTD" placeholder="Dirección"
                                            pattern="^[A-Za-zÑñáéíóúÁÉÍÓÚ 0-9#-]+$" maxlength="50" class="form-control"
                                            required="required"
                                            [(ngModel)]="pse.params.transaction.payer.billingAddress.street1"
                                            #address="ngModel">
                                        <mat-error *ngIf="address?.errors?.['pattern']"
                                            class="invalid-feedback">Dirección no válida. Solo letras, números y
                                            caracteres permitidos (# - .)</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="box-step" id="tcStep3">
                            <div class="form-row">
                                <div class="form-group col-12 col-md-6" id="bankDiv">
                                    <mat-form-field class="w-100" *ngIf="sesion.bancos">
                                        <mat-label>Selecciona tu banco</mat-label>
                                        <mat-select (focus)="focus($event)" name="bank" required="required" id="bank"
                                            class="form-control" pattern="^0*[1-9]\d*$"
                                            [(ngModel)]="pse.params.transaction.extraParameters.FINANCIAL_INSTITUTION_CODE"
                                            #bankCode="ngModel"
                                            [ngClass]="{'is-valid': bankCode.valid, 'is-invalid': ( (bankCode.errors?.['required'] || bankCode.errors?.['pattern']  ) && bankCode.touched ) }"
                                            (selectionChange)="constantes.buscarAyudaBanco($event.value)"
                                            (selectionChange)="validarData(datos)">
                                            <mat-option *ngFor="let banco of sesion.bancos.banks"
                                                value="{{ banco.pseCode }}">
                                                {{ banco.description }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <mat-form-field>
                                        <input (focus)="focus($event)" matInput name="numberAccount"
                                            class="form-control" required="required"
                                            pattern="^[+]?([0-9]+(?:[0-9]*)?|[0-9]+)$" id="numberAccount" type="tel"
                                            [ngClass]="{'disabled': !pse.params.recurrency }"
                                            [attr.required]="!pse.params.recurrency ? null : 'required'"
                                            placeholder="Número de cuenta"
                                            [(ngModel)]="pse.params.transaction.extraParameters.PSE_NUMBER_COUNT"
                                            #accountNumber="ngModel">
                                        <mat-error *ngIf="accountNumber?.errors?.['pattern']"
                                            class="invalid-feedback">Número de cuenta no válido. Ingresar solo
                                            números.</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="form-group col-12 col-md-6" id="client_typediv">
                                    <mat-form-field>
                                        <mat-label>Tipo de cuenta</mat-label>
                                        <mat-select (focus)="focus($event)" name="bankAccountTy" name="client_type"
                                            id="client_type" class="form-control" required="required"
                                            [(ngModel)]="pse.params.transaction.extraParameters.USER_TYPE"
                                            #userType="ngModel">
                                            <mat-option value="N">Persona Natural</mat-option>
                                            <mat-option value="J">Persona Jurídica</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="form-group col-md-6">
                                    <mat-form-field class="w-100">
                                        <mat-label>Tipo de cuenta bancaria</mat-label>
                                        <mat-select (focus)="focus($event)" name="bankAccountType" class="form-control"
                                            id="bankAccountType" required="required"
                                            [ngClass]="{'disabled': !pse.params.recurrency }"
                                            [attr.required]="!pse.params.recurrency ? null : 'required'"
                                            [(ngModel)]="pse.params.transaction.extraParameters.PSE_TYPE_COUNT"
                                            #bankAccount="ngModel"
                                            [ngClass]="{'is-valid': bankAccount.valid, 'is-invalid': ( (bankAccount.errors?.['required']  ) && bankAccount.touched ) }">
                                            <mat-option value="A">Cuenta ahorros</mat-option>
                                            <mat-option value="C">Cuenta corriente</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-error *ngIf="bankAccount?.errors?.['required']" class="invalid-feedback">Campo
                                        obligatorio.</mat-error>
                                </div>
                            </div>
                            <div class="form-row" *ngIf="constantes.ayudaSel != undefined">
                                <div class="form-group col-12">
                                    <div class="ayudaBanco">
                                        <div class="imgAyudaBanco">
                                            <img [src]="constantes.ayudaSel.img" alt="ayuda" />
                                        </div>
                                        <div class="textAyudaBAnco">
                                            <h2>Tienes 10 minutos para realizar tu pago antes de que la sesión expire.
                                            </h2>
                                            <p [innerHtml]="constantes.ayudaSel.ayuda"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>