<div class="history genericBox">
    <h2>Historia de crédito <br><span>Conoce el estado de tus productos.</span></h2>
    <div class="accounts" id="headingOne">
        <button class="btnAcordeon" (click)="redirect_and_datalayer('Cuentas abiertas')">
            <div class="imgBtnAcordeon">
                <img
                    src="assets/img/freepremium/historico/Ico-hc-CuentasAbiertas.svg" alt="cuentas abiertas"/>
            </div>
            <div class="textBtnAcordeon" (click)="redirect_and_datalayer('Cuentas abiertas')">
                <!-- <span>{{totalCA()}} Cuentas abiertas</span><br /> -->
                <span>{{totalCA()}} Cuentas abiertas</span><br />
                Tus productos activos
            </div>
            <i class="fa fa-chevron-right"></i>
        </button>
    </div>
    <div class="accounts" id="headingTwo">
        <button class="btnAcordeon" (click)="redirect_and_datalayer('Cuentas cerradas')">
            <div class="imgBtnAcordeon">
                <img
                    src="assets/img/freepremium/historico/Ico-hc-CuentasCerradas.svg" alt="cuentas cerradas"/>
            </div>
            <div class="textBtnAcordeon">
                <span>{{totalCC()}} Cuentas cerradas</span><br />
                Productos cerrados o cancelados
            </div>
            <i class="fa fa-chevron-right"></i>
        </button>
    </div>
    <div class="accounts" id="headingThree">
        <button class="btnAcordeon" (click)="redirect_and_datalayer('Reclamaciones')">
            <div class="imgBtnAcordeon">
                <img src="assets/img/freepremium/historico/Ico-hc-Reclamaciones.svg" alt="icono reclamaciones"/>
            </div>
            <div class="textBtnAcordeon">
                <span>{{totalRe() + totalCB()}} Reclamaciones</span><br />
                Inconformidades en tus reportes
            </div>
            <i class="fa fa-chevron-right"></i>
        </button>
    </div>
    <div class="accounts" id="headingFour">
        <button class="btnAcordeon" (click)="redirect_and_datalayer('Alertas')">
            <div class="imgBtnAcordeon">
                <img src="assets/img/freepremium/historico/Ico-hc-Alertas.svg" alt="icono historico alertas" />
            </div>
            <div class="textBtnAcordeon">
                <span>{{sesion.listadoAlertas?.length}} Alertas</span><br />
                Prevención del fraude
            </div>
            <i class="fa fa-chevron-right"></i>
        </button>
    </div>
</div>
