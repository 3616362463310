import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BrokerService } from 'src/app/core/services/broker.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { environment } from '../../../environments/environment';
import { SesionService } from 'src/app/core/services/sesion.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';

@Component({
  selector: 'app-webcheckout-confirm',
  templateUrl: './webcheckout-confirm.component.html',
  styleUrls: ['./webcheckout-confirm.component.scss']
})
export class WebcheckoutConfirmComponent implements OnInit {

  public nombre = "[Nombre]";
  public plan = "[nombre del plan]";
  public planId:any;
  public transaccion:any;
  public planes = [
    { "Id": "PER", "plan": 'Plan perfil de credito mensual', "caracteristicas": ['Conoce tu puntaje', 'Monitorea todos los cambios en tu Historia de Crédito', 'Mejora tu perfil con un diagnóstico personalizado'] }
    , { "Id": "ANT", "plan": 'Plan protege mensual', "caracteristicas": ['Conoce tu puntaje', 'Monitorea todos los cambios en tu Historia de Crédito', 'Mejora tu perfil con un diagnóstico personalizado', 'Activa el monitoreo ante posibles fraudes', 'Detecta filtraciones de tus datos personales'] }
    , { "Id": "PRO", "plan": 'Plan premium mensual', "caracteristicas": ['Conoce tu puntaje', 'Monitorea todos los cambios en tu Historia de Crédito', 'Mejora tu perfil con un diagnóstico personalizado', 'Activa el monitoreo ante posibles fraudes', 'Detecta filtraciones de tus datos personales', 'Adquiere un seguro Anti-Fraude en caso de robo de identidad o tarjetas de crédito', 'Recibe asesoría Legal personalizada'] }
  ];
  public caracetristicasPlan:any;
  public tituloPlan:any;
  public estado = 0;
  public formData:any = {};
  public falloServicio = false;

  constructor(private activatedRoute: ActivatedRoute, public loading: LoadingService, public broker: BrokerService, private sesion: SesionService, private dataLayerService: DataLayersService) {
    this.activatedRoute.queryParams.subscribe((params:any) => {
      if (params["transactionState"] == undefined || params["transactionState"] == null) {
        window.location.href = "/";
      }
      this.transaccion = params["transactionState"];
      this.formData['merchantId'] = params["merchantId"];
      this.formData['merchant_name'] = params["merchant_name"];
      this.formData['merchant_address'] = params["merchant_address"];
      this.formData['telephone'] = params["telephone"];
      this.formData['merchant_url'] = params["merchant_url"];
      this.formData['transactionState'] = params["transactionState"];
      this.formData['lapTransactionState'] = params["lapTransactionState"];
      this.formData['message'] = params["message"];
      this.formData['referenceCode'] = params["referenceCode"];
      this.formData['reference_pol'] = params["reference_pol"];
      this.formData['transactionId'] = params["transactionId"];
      this.formData['description'] = params["description"];
      this.formData['trazabilityCode'] = params["trazabilityCode"];
      this.formData['cus'] = params["cus"];
      this.formData['orderLanguage'] = params["orderLanguage"];
      this.formData['extra1'] = params["extra1"];
      this.formData['extra2'] = params["extra2"];
      this.formData['extra3'] = params["extra3"];
      this.formData['polTransactionState'] = params["polTransactionState"];
      this.formData['signature'] = params["signature"];
      this.formData['polResponseCode'] = params["polResponseCode"];
      this.formData['lapResponseCode'] = params["lapResponseCode"];
      this.formData['risk'] = params["risk"];
      this.formData['polPaymentMethod'] = params["polPaymentMethod"];
      this.formData['lapPaymentMethod'] = params["lapPaymentMethod"];
      this.formData['polPaymentMethodType'] = params["polPaymentMethodType"];
      this.formData['lapPaymentMethodType'] = params["lapPaymentMethodType"];
      this.formData['installmentsNumber'] = params["installmentsNumber"];
      this.formData['TX_VALUE'] = params["TX_VALUE"];
      this.formData['TX_TAX'] = params["TX_TAX"];
      this.formData['currency'] = params["currency"];
      this.formData['lng'] = params["lng"];
      this.formData['pseCycle'] = params["pseCycle"];
      this.formData['buyerEmail'] = params["buyerEmail"];
      this.formData['pseBank'] = params["pseBank"];
      this.formData['pseReference1'] = params["pseReference1"];
      this.formData['pseReference2'] = params["pseReference2"];
      this.formData['pseReference3'] = params["pseReference3"];
      this.formData['authorizationCode'] = params["authorizationCode"];
      this.formData['TX_ADMINISTRATIVE_FEE'] = params["TX_ADMINISTRATIVE_FEE"];
      this.formData['TX_TAX_ADMINISTRATIVE_FEE'] = params["TX_TAX_ADMINISTRATIVE_FEE"];
      this.formData['TX_TAX_ADMINISTRATIVE_FEE_RETURN_BASE'] = params["TX_TAX_ADMINISTRATIVE_FEE_RETURN_BASE"];
      this.formData['processingDate'] = params["processingDate"];
      this.broker.confirmationWC(this.formData).subscribe(
        (result:any) => {
          if (result.code) {
            if (result.code != "OK") {
              this.falloServicio = true;
            }
          }
        },
        (error:any) => {
          this.falloServicio = true;
        }
      );
    });
  }

  ngOnInit() {
    this.loading.open();
    this.tituloPlan = this.planes[2].plan;
    this.caracetristicasPlan = this.planes[2].caracteristicas;
    switch (this.transaccion) {
      case "4":
        this.estado = 1;
        break;
      case "6":
        this.estado = 0;
        break;
      case "7":
        this.estado = 2;
        break;
      default:
        break;
    }
    if (sessionStorage.getItem('planMDCWCO') != null) {
      this.planId = sessionStorage.getItem('planMDCWCO');
      var planSel = this.planes.find((element) => element.Id == this.planId.substring(0, 3))
      if(planSel != undefined){
        switch (this.planId.substring(3, 4)) {
          case '2':
            planSel["plan"] = planSel.plan.replace('mensual', 'trimestral');
            break;
          case '3':
            planSel["plan"] = planSel.plan.replace('mensual', 'anual');
            break;
          default:
            break;
        }
        this.tituloPlan = planSel.plan;
        this.caracetristicasPlan = planSel.caracteristicas;
        this.plan = planSel.plan;
      }
    } else {
      window.location.href = "/";
    }
    this.loading.close();
    if (this.sesion.user){
      this.dataLayerService.dl_userId( '.', this.sesion.planCurrently );
    } else {
      this.dataLayerService.dl_userId( null, 'anonimo' );
    }
  }

  reintentar() {
    window.location.href = environment.APIEndPoints.urlEndPoints + 'webcheckout/' + sessionStorage.getItem('documentMDCWCO');
  }

  irHome() {
    window.location.href = '/';
  }

}
