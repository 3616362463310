import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Plan } from '../../core/models/plan.model';
import { SesionService } from '../../core/services/sesion.service';
import { environment } from '../../../environments/environment';
import { NgForm } from '@angular/forms';
import { ModalService } from '../../core/services/modal.service';

declare var $: any;

interface GiftCard {
  action: string;
  session: string;
  params: {
    test: boolean;
    giftcardCode: string;
    planCode: string;
  };
}

@Component({
  selector: 'app-gc',
  templateUrl: './gc.component.html',
  styleUrls: ['./../tc/tc.component.scss']
})
export class GcComponent implements OnInit {

  giftCard: any;
  giftCardCode: any;
  isChecked: any;
  acceptAdvertising = false;
  @Output() inactiveTab = new EventEmitter<void>();
  url_autorizacion_opcional = environment.enlaceS3 + environment.s3.autorizacionDatosOpcional;

  constructor(public sesion: SesionService, public modal: ModalService) {
    this.sesion.infoPagos();

    this.giftCardCode = null;

    this.giftCard = {
      action: 'GIFTCARD',
      session: sesion.cookieValue,
      params: {
        test: environment.payULatam.TestPayU,
        giftcardCode: null,
        planCode: this.sesion.planId
      }
    };
  }

  ngOnInit() {
    $(document).ready(function () {
      $("form").keypress((e: any) => {
        if (e.which == 13) {
          return false;
        }
        return true;
      });
    });
    setTimeout(() => {
      if (this.sesion.advertising == true) {
        this.acceptAdvertising = true;
      }
    }, 2000);
    this.giftCardCode = null;
    this.giftCard.params.giftcardCode = null;
  }

  activarGiftCard(datos: NgForm) {
    if (datos.valid) {
      this.giftCard.params.giftcardCode = this.giftCardCode.toUpperCase();
      this.sesion.pagos(this.giftCard, 2);
      //CHECK PUBLICIDAD          
      if (this.isChecked == true) {
        this.sesion.authorizations.filter(t => t.authorizationType === "commercial").forEach(ta => {
          ta.status = true
        });
        this.sesion.saveAcceptAdvertising();
      }
    }
  }

  back() {
    window.history.back();
  }

  inactiveTabF() {
    this.inactiveTab.emit();
  }

}