import { Component, OnInit } from '@angular/core';
import { SesionService } from 'src/app/core/services/sesion.service';
import { Router } from '@angular/router';
import { RoutingService } from 'src/app/core/services/routing.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
declare var $: any;

@Component({
	selector: 'app-dash-huellas',
	templateUrl: './dash-huellas.component.html',
	styleUrls: ['./dash-huellas.component.scss']
})
export class DashHuellasComponent implements OnInit {

	huellas: any[] = [];
	txtTootip: string = "";

	constructor(public sesion: SesionService, public router: Router, public routing: RoutingService, private dataLayer: DataLayersService) { }

	ngOnInit(): void {
		this.slicePrints();
	}

	slicePrints() {
		this.huellas = this.sesion.listHuellas.totalHuellas.slice(0, 4);
	}

	getEntity(entidad: any) {
		entidad.includes("CIUDADANO") ? entidad = "CIUDADANO" : entidad;
		switch (entidad) {
			case 'CIUDADANO':
				this.txtTootip = 'Esta huella corresponde a todas las consultas hechas por el ciudadano (auto consultas) por medio de alguno de los canales habilitados.';
				$('#contenTooltip').css("visibility", "hidden");
				break;
			case 'CONSULTAS LOTE':
				this.txtTootip = 'Corresponden a consultas realizadas por las Entidades para la supervisión y control del riesgo crediticio.'
				$('#contenTooltip').css("visibility", "hidden");
				break;
			default:
				this.txtTootip = 'Esta huella corresponde a la consulta al historial crediticio realizada por alguna entidad con previa autorización del ciudadano.'
				$('#contenTooltip').css("visibility", "hidden");
				break;
		}
	}

	/**
	 * Redirigir a huellas y enviar data layer
	*/
	redirect_and_send_datalayer() {
		this.dataLayer.dl_eventoGA4_menu_dashboard('Ver todas las huellas', 'Midatacrédito');
		this.routing.redirectAllAccess(0, '/huellas');
	}

}
