import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-autorizacion-opcional',
  templateUrl: './autorizacion-opcional.component.html',
  styleUrls: ['./autorizacion-opcional.component.scss']
})
export class AutorizacionOpcionalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.location.href = environment.enlaceS3 + environment.s3.autorizacionDatosOpcional;
  }

}
