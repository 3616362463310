import { Component, OnInit } from '@angular/core';
import { BrokerService } from 'src/app/core/services/broker.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-proximo-credito',
  templateUrl: './proximo-credito.component.html',
  styleUrls: ['./proximo-credito.component.scss']
})
export class ProximoCreditoComponent implements OnInit {
  public simulacionError: boolean = false;
  public simulacionResponseHip: any;
  public simulacionResponseLin: any;
  public simulacionResponseTdc: any;
  public ingresoVal:any;
  public gastosFinVal:any;
  public misGastosFVal:any;
  public tasaVal = 1.2;
  public plazoVal = 60;
  public textoMicroLoader = 'Por favor espera un momento, estamos calculando tus probabilidades.';
  public textErrorMicroLoader = 'En este momento no podemos realizar el calculo. Por favor inténtalo más tarde.';
  public llamarServicio = false;
  public acordeonAbierto = false;
  public checkDiv = false;
  public checkDiv2 = false;

  constructor(public sesion: SesionService, public broker: BrokerService, private dataLayerService: DataLayersService, private utilities: UtilitiesService) {
    if (this.sesion.diagnostico == undefined) {
      this.sesion.infoHC(true);
    }
  }

  ngOnInit() {
    $('.acordeon, .content-tab').hide();
    // GA4 - User
    this.dataLayerService.dl_userId( '.', this.sesion.planCurrently );
  }

  calcular(id:any) {
    this.llamarServicio = true;
    let jsonHipotecario = {
      simulador: 'HIPOTECARIO',
      session: this.sesion.cookieValue,
      ingresosTotal: this.ingresoVal,
      gastosFinanacieros: this.gastosFinVal,
      otrosGastos: this.misGastosFVal,
      tasaI: this.tasaVal,
      plazo: this.plazoVal
    }
    let jsonLibreInversion = {
      simulador: 'LIBREINVERSION',
      session: this.sesion.cookieValue,
      ingresosTotal: this.ingresoVal,
      gastosFinanacieros: this.gastosFinVal,
      otrosGastos: this.misGastosFVal,
      tasaI: this.tasaVal,
      plazo: this.plazoVal
    }
    let jsonTarjetaCredito = {
      simulador: 'TARJETACREDITO',
      session: this.sesion.cookieValue,
      ingresosTotal: this.ingresoVal,
      gastosFinanacieros: this.gastosFinVal,
      otrosGastos: this.misGastosFVal,
      tasaI: this.tasaVal,
      plazo: this.plazoVal
    }
    if(this.acordeonAbierto){
      this.openAcordeon(id);
    }
    this.broker.simularCredito(jsonHipotecario).subscribe((data: any) => {
      this.simulacionError = false;
      this.simulacionResponseHip = data;
      this.broker.simularCredito(jsonLibreInversion).subscribe((data: any) => {
        this.simulacionError = false;
        this.simulacionResponseLin = data;
        this.broker.simularCredito(jsonTarjetaCredito).subscribe((data: any) => {
          this.simulacionError = false;
          this.simulacionResponseTdc = data;
          this.llamarServicio = false;
          this.openAcordeon(id);
        },
          (error) => {
            this.simulacionResponseTdc = null;
            this.simulacionError = true;
          }
        );
      },
        (error) => {
          this.simulacionResponseLin = null;
          this.simulacionError = true;
        }
      );
    },
      (error) => {
        this.simulacionResponseHip = null;
        this.simulacionError = true;
      }
    );
  }

  verifyInfo() {
    return ((this.ingresoVal > 0 && this.ingresoVal >= 100000) && (this.gastosFinVal > 0 && this.gastosFinVal >= 100000) && (this.misGastosFVal > 0 && this.misGastosFVal >= 100000) && this.plazoVal > 0 && this.tasaVal > 0);
  }

  openAcordeon(id:any) {
    if (id == 1) {
      if ($('#tab-' + id).css("display") == "none") {
        $('.acordeon').hide();
        $('#tab-' + id).show('fast');
        this.acordeonAbierto = true;
      } else {
        $('.acordeon').hide('fast');
        this.acordeonAbierto = false;
      }
    } else {
      if ($('#tab-' + id).css("display") == "none") {
        $('.content-tab').hide();
        $('#tab-' + id).show('fast');
      } else {
        $('.content-tab').hide('fast');
      }
    }
  }

  checkProbability(prob:any) {
    if (this.sesion.probabilidad != undefined) {
      let typeProb = this.sesion.probabilidad.objResult[prob];
      if (typeProb == 0) {
        return 'probabilidad-i';
      } else if (typeProb >= 1 && typeProb <= 19) {
        return 'probabilidad-1';
      } else if (typeProb >= 20 && typeProb <= 39) {
        return 'probabilidad-2';
      } else if (typeProb >= 40 && typeProb <= 59) {
        return 'probabilidad-3';
      } else if (typeProb >= 60 && typeProb <= 79) {
        return 'probabilidad-4';
      } else if (typeProb >= 80 && typeProb <= 99) {
        return 'probabilidad-5';
      } else if (typeProb == 100) {
        return 'probabilidad-f';
      }
      return 'probabilidad-i';
    } else {
      return 'probabilidad-i';
    }
  }

  checkBannerProximoCredito(){
    if(!this.checkDiv){
      let tamano = [[728, 90], [300, 250]];
      this.utilities.addPubliById(environment.slotsBanners.pri_proximo_credito,'div-gpt-ad-1716905815303-0', tamano);
      this.checkDiv = true;
    }
    return ''
  }

  checkBannerProximoCredito2(){
    if(!this.checkDiv2){
      let tamano = [[728, 90], [300, 250]];
      this.utilities.addPubliById(environment.slotsBanners.pri_proximo_credito,'div-gpt-ad-1716905815303-1', tamano);
      this.checkDiv2 = true;
    }
    return ''
  }

}
