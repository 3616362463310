import { Injectable } from '@angular/core';
import { resolve } from 'dns';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { SesionService } from './sesion.service';

@Injectable({
  providedIn: 'root'
})
export class CookiesGoogleService {

  constructor( private cookieService: CookieService, private sesion: SesionService ) { }


  async verificarCookies(){
    const cookieGoogle = await this.checkCookie();
    if ( cookieGoogle ){
      this.set_cookies_google();
    }
  }

  checkCookie(): Promise<boolean>{
    return new Promise (resolve => {
      const checkearCookie = setInterval(() => {
        const gads = this.sesion.getCookieServices('__gads') !== null;
        const gpi = this.sesion.getCookieServices('__gpi') !== null;
        if ( gads && gpi){
          clearInterval(checkearCookie);
          resolve(true);
        }
      }, 700);
    });
  }

  /**Seteo de Cookies de Google
    @param gads = Esta cookie se utiliza para rastrear la interacción del usuario con los anuncios del sitio web y mostrar los más revelantes (Google AdSense).
    @param gpi = Con funcionalidad desconocida.
  */
  set_cookies_google( ){
    const gads = this.sesion.getCookieServices('__gads');
    const gpi = this.sesion.getCookieServices('__gpi');
    const gcl_au = this.sesion.getCookieServices('_gcl_au');

    window.onload = () => {

      
      if ( gads != '' && gpi != ''){
        this.cookieService.set('__gpi', gpi, undefined, '/', environment.domain, true, 'None');
        this.cookieService.set('__gads', gads, undefined, '/', environment.domain, true, 'None');
      } else {
        this.verificarCookies();
      }

      if ( gcl_au != ''){
        this.cookieService.set( '_gcl_au', gcl_au, undefined, '/', environment.domain, true, 'None' );
      }

    }
  }
}
