import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from 'src/app/core/services/modal.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { BrokerService } from 'src/app/core/services/broker.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { RoutingService } from 'src/app/core/services/routing.service';
declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent implements OnInit {
  @Input() data: any;
  public imgPlan = 'assets/img/landings/new/perfil-credito/Plan_Perfil-2-Diagnostico.svg';
  public nombrePlanText = "Historia de crédito";
  constructor(
    public modal: ModalService,
    public router: Router,
    public sesion: SesionService,
		public broker: BrokerService,
    private dataLayerService: DataLayersService,
		public routing: RoutingService
  ) { }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (window.pageYOffset > 1400) {
      $('.menu-freemium').css({ 'margin-bottom': '100px' })
    } else {
      //$(".mdc-sm-icon").removeClass("filter");      
    }
  }
  ngOnInit() {
    this.sesion.getMenu();
    $(".btn-main-menu").removeClass("active");
    //$(".btn-main-menu")[0].classList.add("active");
    setTimeout(() => {
      var boxFilter = $(".txtScore");
      var p = document.createElement("p");
      boxFilter.append(p)
      if (this.data.miscoreInt > 630) {
        p.append("Tu puntaje de crédito está por encima del promedio de los colombianos.");
      } else if (this.data.miscoreInt > 310 && this.data.miscoreInt <= 630) {
        p.append("Tu puntaje de crédito está en el promedio de los colombianos.");
      } else if (this.data.miscoreInt <= 310) {
        p.append("Tu puntaje de crédito está por debajo del promedio de los colombianos.");
      }
    }, 5000);
  }

  irVerificar(id:any) {
    $(".btn-main-menu").removeClass("active");
    $(id).addClass("active");
    this.modal.modalVerifica();
  }

  nombrePlan() {
    if (!this.sesion.monitoreo && !this.sesion.proteccion && !this.sesion.poliza) {
      this.imgPlan = 'assets/img/landings/new/perfil-credito/Plan_Perfil-2-Diagnostico.svg';
      this.nombrePlanText = 'Historia de crédito';
    }
    if (this.sesion.monitoreo && !this.sesion.proteccion && !this.sesion.poliza) {
      this.imgPlan = 'assets/img/landings/new/perfil-credito/Plan_Perfil-1-Puntaje.svg';
      this.nombrePlanText = 'Mejorar tu puntaje'
    }
    if (this.sesion.monitoreo && this.sesion.proteccion && !this.sesion.poliza) {
      this.imgPlan = 'assets/img/landings/new/protege/Plan_Protege-1-Monitoreo.svg';
      this.nombrePlanText = 'Protege tus datos'
    }
    if (this.sesion.monitoreo && this.sesion.proteccion && this.sesion.poliza) {
      this.imgPlan = 'assets/img/landings/new/protege/Plan_Protege-2-DatosPersonales.svg';
      this.nombrePlanText = 'Protección Premium'
    }
    return true;
  }

  textNotification(index:number){
    if(this.sesion.viewFree){
      return this.sesion.dataMenu[index].descriptionFree;
    }
    return this.sesion.dataMenu[index].description;
  }
  // getMenu(){
  //   this.broker.getMenu()
  //   .subscribe(
  //     {
  //       next: (data: any) => {
  //         this.sesion.dataMenu =data.items;
  //       },
  //       error: (error) => {
  //       }
  //     }
      
  //   );
  // }

  selectEvent(fun:string,id:any, texto_boton:string){
    var idS = "#link-menu-"+id;
    // GA4 - clic_tool_menu
    this.enviar_datalayer( texto_boton );
    switch(fun){
      
		  case "/huellas":
		  case "/mis-notificaciones":
		  case "/generar-alerta":
		  case "/historico":
        case "/simulador":
      this.routing.redirectAllAccess(idS,fun);
      break;
      case "/historico-score":
		  case "/mi-proximo-credito":
		  case "/mi-deuda":
		  case "/diagnostico":
      this.routing.redirectPayAccess(idS,fun);
      break;

      case "/home":
        if(idS == '#link-menu-9'){
          this.routing.redirectAllAccess(idS,fun,'modulo-bc');
        }else{
          this.routing.redirectAllAccess(idS,fun);
        }
		  break;

      case "/mi-presencia":
		  this.routing.irPresencia(idS);
		  break;
		  case "/poliza-premium":
		  this.routing.irProteccion(idS);
		  break;
      default:
      this.routing.redirectAllAccess(idS,fun);
      break;
    }
  }
  accessMenu(typePlan:any){
    if(typePlan.monitoreo != undefined){
      switch(typePlan.name){

        case"PER":
        if((this.sesion.monitoreo == typePlan.monitoreo && (this.sesion.estadoUserF == "ACTIVO" || this.sesion.estadoUserF == 'ACTIVE_PROCESS'))){
          return false;
        }else{
          return true;
        }
        case"ANT":
        if((this.sesion.monitoreo == typePlan.monitoreo)&&
              (this.sesion.proteccion == typePlan.proteccion)  && (this.sesion.estadoUserF == "ACTIVO" || this.sesion.estadoUserF == 'ACTIVE_PROCESS')){
            return false;
          }else{
            return true;
          }
        case"PRO":
        if((this.sesion.monitoreo == typePlan.monitoreo)&&
              (this.sesion.proteccion == typePlan.proteccion)&&
              (this.sesion.poliza == typePlan.poliza)  && (this.sesion.estadoUserF == "ACTIVO" || this.sesion.estadoUserF == 'ACTIVE_PROCESS')){
            return false;
          }else{
            return true;
          }
        default:
          return false;
      }
    }else{
      return false;
    }
  }

  enviar_datalayer( texto_boton:string ){
    this.dataLayerService.dl_eventoGA4_menu( 'clic_tool_menu', texto_boton );
  }

  setAdvance(url:string){
		if(this.sesion.viewFree || (url != "/simulador" && url != "/historico-score")){
			return true;
		}else if(!this.sesion.viewFree && url == "/simulador"){
			return (this.sesion.dataUser.params[13].value)?true:false;
		}else if(!this.sesion.viewFree && url == "/historico-score"){
			return (this.sesion.dataUser.params[13].value)?false:true;
		}
		else{
			return true;
		}
	}

}
