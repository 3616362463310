<div class="row main-diag">
  <div class="col-12">
    <div class="row head-diag">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-3 col-md-4">
            <img src="assets/img/icons/Diagnostico_ajustado.svg" alt="Diagnostico"/>
          </div>
          <div class="col-6 col-md-7 d-flex borde-separador">
            <h2>Diagnóstico</h2>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 d-flex">
        <p>Conoce como te encuentras respecto a los factores que impactan tu Score o Puntaje de Crédito y qué puedes
          hacer para mejorarlo.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-12" *ngIf="sesion.diagnostico == undefined">
        <app-micro-loader [texto]="textoMicroLoader" [error]="sesion.errorServiceCreditHistory"
          [textoError]="textErrorMicroLoader">
        </app-micro-loader>
      </div>
    </div>
    <div class="row content-diag" *ngIf="sesion.diagnostico">
      <!--EXPERIENCIA CREDITICIA-->
      <div class="col-12">
        <div class="row box-diag">
          <div class="col-12">
            <div class="head-box">
              <img src="assets/base/icon-experiencia-crediticia.png" alt="Icono experiencia crediticia" />
              <p>Experiencia Crediticia</p>
            </div>
            <div class="row item-box">
              <div class="col-12">
                <ul>
                  <li *ngFor="let cal of sesion.diagnostico.experienciaCrediticia.calificaciones">
                    <p>{{cal.texto}}</p>
                    <div class="score-diag">
                      <div class="color-score" [ngClass]="[selCal(cal.calificacionLetra)]">{{cal.calificacion}}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row acordeon-diag">
          <div class="col-12 tab" (click)="openAcordeon(1)">
            <span>¿Cómo mejorar mi experiencia crediticia?</span>
            <i class="fa fa-angle-down"></i>
          </div>
          <div class="col-12 content-tab" id="tab-1">
            <div>
              <p *ngFor="let cal of sesion.diagnostico.experienciaCrediticia.calificaciones">
                {{cal.textoMejoramiento}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--EXPERIENCIA CREDITICIA-->
      <!--ENDEUDAMIENTO-->
      <div class="col-12">
        <div class="row box-diag">
          <div class="col-12">
            <div class="head-box">
              <img src="assets/base/icon-endeudamiento.png" alt="icono de endeudamiento"/>
              <p>Endeudamiento</p>
            </div>
            <div class="row item-box">
              <div class="col-12">
                <ul>
                  <li *ngFor="let cal of sesion.diagnostico.endeudamiento.calificaciones">
                    <p>{{cal.texto}}</p>
                    <div class="score-diag">
                      <div class="color-score" [ngClass]="[selCal(cal.calificacionLetra)]">{{cal.calificacion}}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row acordeon-diag">
          <div class="col-12 tab" (click)="openAcordeon(2)">
            <span>¿Cómo mejorar mi experiencia crediticia?</span>
            <i class="fa fa-angle-down"></i>
          </div>
          <div class="col-12 content-tab" id="tab-2">
            <div>
              <p *ngFor="let cal of sesion.diagnostico.endeudamiento.calificaciones">
                {{cal.textoMejoramiento}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--ENDEUDAMIENTO-->
      <!--ESTADO PORTAFOLIO-->
      <div class="col-12">
        <div class="row box-diag">
          <div class="col-12">
            <div class="head-box">
              <img src="assets/base/icon-portafolio.png" alt="icono de portafolio" />
              <p>Estado de su portafolio</p>
            </div>
            <div class="row item-box">
              <div class="col-12">
                <ul>
                  <li *ngFor="let cal of sesion.diagnostico.estadoPortafolio.calificaciones">
                    <p>{{cal.texto}}</p>
                    <div class="score-diag">
                      <div class="color-score" [ngClass]="[selCal(cal.calificacionLetra)]">{{cal.calificacion}}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row acordeon-diag">
          <div class="col-12 tab" (click)="openAcordeon(3)">
            <span>¿Cómo mejorar mi experiencia crediticia?</span>
            <i class="fa fa-angle-down"></i>
          </div>
          <div class="col-12 content-tab" id="tab-3">
            <div>
              <p *ngFor="let cal of sesion.diagnostico.estadoPortafolio.calificaciones">
                {{cal.textoMejoramiento}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--ESTADO PORTAFOLIO-->
      <!--HABITO PAGO-->
      <div class="col-12">
        <div class="row box-diag">
          <div class="col-12">
            <div class="head-box">
              <img src="assets/base/icon-habito-pago.png" alt="icono de hábito de pago" />
              <p>Hábito de Pago</p>
            </div>
            <div class="row item-box">
              <div class="col-12">
                <ul>
                  <li *ngFor="let cal of sesion.diagnostico.habitoPago.calificaciones">
                    <p>{{cal.texto}}</p>
                    <div class="score-diag">
                      <div class="color-score" [ngClass]="[selCal(cal.calificacionLetra)]">{{cal.calificacion}}</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row acordeon-diag">
          <div class="col-12 tab" (click)="openAcordeon(4)">
            <span>¿Cómo mejorar mi experiencia crediticia?</span>
            <i class="fa fa-angle-down"></i>
          </div>
          <div class="col-12 content-tab" id="tab-4">
            <div>
              <p *ngFor="let cal of sesion.diagnostico.habitoPago.calificaciones">
                {{cal.textoMejoramiento}}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!--HABITO PAGO-->
    </div>
  </div>
</div>