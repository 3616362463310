import { Component, OnInit } from '@angular/core';
import { BrokerService } from 'src/app/core/services/broker.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { SesionService } from 'src/app/core/services/sesion.service';
declare var $: any;

@Component({
  selector: 'app-notificacion',
  templateUrl: './notificacion.component.html',
  styleUrls: ['./notificacion.component.scss']
})
export class NotificacionComponent implements OnInit {

  public celActiv = '';
  public loadSendSMS = false;
  public loadActivateCel = false;
  public loadDesactivateCel = false;
  public sendSMSService = false;
  public codeSMS = '';
  btnGuardar:boolean[]=[false,false];

  constructor(public sesion: SesionService, public modal: ModalService, public broker: BrokerService, private dlService: DataLayersService) {
    this.celActiv = this.sesion.user.phone;
  }

  ngOnInit() {
    // GA4 - User
		this.dlService.dl_userId( '.', this.sesion.planCurrently );

    $('.content-sub-changes').hide();
    if (this.sesion.estadoCelular) {
      this.sesion.getAlertsMongoWS();
    }
  }

  openAcordeon(id:any) {
    if ($('#tab-' + id).css("display") == "none") {
      $('.content-sub-changes').hide();
      $('#tab-' + id).slideDown('fast');
    } else {
      $('.content-sub-changes').hide('fast');
    }
  }

  sendSMS() {
    this.modal.okSendSMS = true;
    this.loadSendSMS = true;
    this.sendSMSService = false;
    this.broker.codigo(this.celActiv, this.sesion.cookieValue).subscribe((data: any) => {
      if (data.status === 'OK') {
        this.modal.okSendSMS = true;
        this.sendSMSService = true;
      } else {
        this.modal.okSendSMS = false;
        this.sendSMSService = false;
      }
      this.loadSendSMS = false;
      this.modal.modalSendSMS();
    },
      (error:any) => {
        this.modal.okSendSMS = true;
        this.loadSendSMS = false;
        this.sendSMSService = false;
        this.modal.modalSendSMS();
      }
    );
  }

  validateActivateBtn() {
    return !(this.sendSMSService && this.codeSMS != '')
  }

  validarCodigo() {
    this.loadActivateCel = true;
    this.broker.validar(this.celActiv, this.codeSMS, this.sesion.cookieValue).subscribe((data: any) => {
      if (data.status === 'OK') {
        this.sesion.estadoCelular = true;
        this.sesion.getAlertsMongoWS();
      } else {
        this.sesion.estadoCelular = false;
      }
      this.loadActivateCel = false;
    },
      (error:any) => {
        this.loadActivateCel = false;
        this.sesion.estadoCelular = false;
      }
    );
  }

  desactivarCodigo(){
    this.loadDesactivateCel = true;
    this.broker.desactivar( this.sesion.cookieValue ).subscribe( ( data: any ) => {
      if (data.status === 'OK' ) {
        this.sesion.estadoCelular = false;
      } else {
        this.sesion.estadoCelular = true;
      }
      this.loadDesactivateCel = false;
    },
    (error:any) => {
      this.loadDesactivateCel = false;
      this.sesion.estadoCelular = true;
    }
  );
  }

  saveNotificacions() {
    let numNotificationInit = this.sesion.notificationIni.length;
    let numNotificationChange = 0;
    let changeNotificaction = false;
    const notificaciones = this.sesion.notificacionesActivas.filter((obj:any) => obj.checked === true);

    if(notificaciones.length == numNotificationInit){
      notificaciones.forEach((element:any) => {
        let obj = this.sesion.notificationIni.find((ele:any) => ele.notificationId == element.notificationId);
        if(obj){
          numNotificationChange++;
        }
      });
      if(numNotificationInit !=  numNotificationChange){
        changeNotificaction = true;
      }
    }else{
      changeNotificaction = true;
    }
    if(changeNotificaction){
      this.sesion.alertMongo = false;
      this.sesion.errorAlertMongo = false;
      $("#exito").hide();
      this.broker.saveAlertsMongoWS(this.sesion.cookieValue, notificaciones).subscribe((data: any) => {
        this.sesion.notificationIni = [...this.sesion.notificacionesActivas.filter((obj:any) => obj.checked === true)];
        this.sesion.alertMongo = true;
        this.btnGuardar=[false,false];
        $("#exito").show();
        setTimeout(() => {
          $('.content-sub-changes').hide();
          window.scrollTo( 0, 0);
        }, 100);
      },
        (error:any) => {
          window.scrollTo( 0, 0);
          this.sesion.alertMongo = true;
          this.sesion.errorAlertMongo = true;
        });
    }
  }

  activateBtn(flag:boolean){
    if(flag){
      this.btnGuardar[1]=true;
    }else{
      this.btnGuardar[0]=true;
    }
  }
}
