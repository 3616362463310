import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomerDebts } from '../models/customerDebts.model';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { BrokerService } from './broker.service';

declare var $: any;

interface Companie {
  name: string;
  quantity: number;
  checked: boolean;
}

interface State {
  status: string;
  quantity: number;
  name: string;
  checked: boolean;
}

export interface debtPadYa {
  idSession?: string,
  itemEntity?: string,
  debtTime?: string,
  debtValue?: string,
  accountNumber?: string,
  printPath?: string,
}


@Injectable({
  providedIn: 'root'
})
export class DebtsService {

  debt: any;
  account: any;
  debtLength: any;
  debtSelect: any;
  customerDebts: any = null;
  filteredItems: any;
  select: any;
  filterCompany = false;
  filterState = false;
  public debtsPrint: Array<debtPadYa> = [];

  filters: {
    companies: Array<Companie>;
    state: Array<State>;
  };

  constructor(public http: HttpClient, private cookieService: CookieService, private router: Router, private broker: BrokerService) {

    this.filters = {
      companies: [],
      state: []
    };
  }

  crearFiltros() {
    // Filter states
    this.filters.state = [];
    // tslint:disable-next-line: max-line-length
    // this.filters.state.push( { status: 'TODAS', quantity: this.customerDebts.responseObject.debts.length, name: 'Todos', checked: true } );
    this.filters.state.push({ status: 'EN MORA', quantity: 0, name: 'En mora', checked: false });
    this.filters.state.push({ status: 'AL DIA', quantity: 0, name: 'Al día', checked: false });
    this.filters.state.push({ status: 'EN NEGOCIACION', quantity: 0, name: 'En negociación', checked: false });
    this.filters.state.push({ status: 'CON ACUERDO', quantity: 0, name: 'Con acuerdo', checked: false });

    // Filter companies
    this.filters.companies = [];

    // this.filters.companies.push( { name: 'Todas', quantity: this.customerDebts.responseObject.debts.length, checked: true } );
    for (const debt of this.customerDebts.responseObject.debts) {
      // States
      switch (debt.accountStatuts) {
        case 'EN MORA':
          const mora = this.filters.state.findIndex(obj => obj.status === 'EN MORA');
          this.filters.state[mora].quantity = this.filters.state[mora].quantity + 1;
          break;
        case 'AL DIA':
          const aldia = this.filters.state.findIndex(obj => obj.status === 'AL DIA');
          this.filters.state[aldia].quantity = this.filters.state[aldia].quantity + 1;
          break;
        case 'EN NEGOCIACION':
          const negociacion = this.filters.state.findIndex(obj => obj.status === 'EN NEGOCIACION');
          this.filters.state[negociacion].quantity = this.filters.state[negociacion].quantity + 1;
          break;
        case 'CON ACUERDO':
          const acuerdo = this.filters.state.findIndex(obj => obj.status === 'CON ACUERDO');
          this.filters.state[acuerdo].quantity = this.filters.state[acuerdo].quantity + 1;
          break;
      }

      // Companies
      const companie = this.filters.companies.findIndex(obj => obj.name === debt.nameCompany);
      if (companie >= 0) {
        this.filters.companies[companie].quantity = this.filters.companies[companie].quantity + 1;
      } else {
        this.filters.companies.push({ quantity: 1, name: debt.nameCompany, checked: false });
      }
    }
  }

  aplicarFiltros() {
    const comps = this.filters.companies.filter(obj => obj.checked === true);
    const states = this.filters.state.filter(obj => obj.checked === true);

    for (let con of comps) {
      ;
      var boxFilter = $(".btn-company").addClass("d-block");
      var btn = document.createElement("button");
      btn.setAttribute("class", "btn_class");
      btn.append(con.name);
      boxFilter.append(btn)
    };
    for (let con of states) {
      var boxFilter = $(".btn-statusAll").addClass("d-block");
      var btn = document.createElement("button");
      btn.setAttribute("class", "btn_status");
      btn.append(con.name);
      boxFilter.append(btn)
    };


    if (comps.length > 0 || states.length > 0) {

      const model = this;
      // tslint:disable-next-line: only-arrow-functions
      this.filteredItems = this.customerDebts.responseObject.debts.filter((item: any) => {

        if (comps.length > 0 && states.length > 0) {
          const compFind = comps.findIndex(obj => obj.name === item.nameCompany);
          const staFind = states.findIndex(obj => obj.status === item.accountStatuts);

          if (compFind >= 0 && staFind >= 0) {
            return true;
          } else {
            return false;
          }
        } else if (comps.length > 0 && states.length === 0) {
          const compFind = comps.findIndex(obj => obj.name === item.nameCompany);
          if (compFind >= 0) {
            return true;
          } else {
            return false;
          }
        } else if (comps.length === 0 && states.length > 0) {
          const staFind = states.findIndex(obj => obj.status === item.accountStatuts);
          if (staFind >= 0) {
            return true;
          } else {
            return false;
          }

        }
        else {
          return false;
        }
      });
    } else {
      this.filteredItems = this.customerDebts.responseObject.debts;
    }
  }


  limpiarFiltros() {
    for (const companie of this.filters.companies) {
      companie.checked = false;
      $('.btn_class').remove();
    }
    for (const state of this.filters.state) {
      state.checked = false;
      $('.btn_status').remove();
    }
    this.filteredItems = this.customerDebts.responseObject.debts;
  }

  /**
   * Funcion que busca en la HC las cuentas con deudas y que tengan padya, y guarda la informacion necesaria en un objeto general de deuda 
   * y al terminar llama a la funcion para que acomodar las deudas para visual de desktop y mobile
   */
  getDebtsPrintPadYa(hc: any) {
    try {
      if(this.debtsPrint.length > 0) {
        this.debtsPrint.map((ele:any) => {
          ele.printPath = this.getPrintPath();
        });
      }else{
        hc.openAccounts.forEach((element: any) => {
          let accountPadYa = element.detalleCuenta.filter((ele2: any) => ele2.enlacePad != null && ele2.enlacePad != '');
          accountPadYa.map((ele: any) => ele['type'] = element.nombreCuenta);
          accountPadYa.forEach((element2: any) => {
            let newDebt = {} as debtPadYa;
            newDebt.idSession = this.cookieService.get('IDSESSIONMDC'),
            newDebt.accountNumber = element2.numeroCuenta;
            newDebt.debtTime = element2.estadoTexto;
            newDebt.itemEntity = element2.item.toString();
            newDebt.debtValue = element2.saldoMora;
            newDebt.printPath = this.getPrintPath();
            this.debtsPrint.push(newDebt);
          });
        });
      }
      if (this.debtsPrint.length > 0) {
        this.broker.registerPadYaImpressions(this.debtsPrint).subscribe();
      }
    } catch (e) {
      console.error(e)
    }
  }

  getPrintPath() {
    let url = this.router.url.toLocaleLowerCase();
    if (url.includes('home')) {
      return 'DASH';
    } else if (url.includes('historico')) {
      return 'HC';
    } else if (url.includes('modulopadapp')) {
      return 'APP';
    } else {
      return 'OTRO';
    }
  }

}
