import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

const headers: any = new HttpHeaders({
  'Content-Type': 'application/json'
});

@Injectable({
  providedIn: 'root'
})

export class CampaignService {
  campaignInfo: {
	  entity: "";
	  companyNit: "";
	  expiredDate: any;
	  productName: any;
	  templateParams: any;
  } | undefined;
  orderNumber: any;
  orderDate: any;
  idNumber: any;
  urlCampaign: any;
  urlRedirectMethod: any;
  email: any;
  hasApply: any;

	constructor(
		public http: HttpClient
	) { }

	getCampaignInfo(data:any) {
		const body = JSON.stringify(data);
		return this.http.post(environment.APIEndPoints.urlEndPoints + environment.Bank.campaignInfo, data, { headers });
	}

	applyCampaign(data:any) {
		const body = JSON.stringify(data);
		return this.http.post(environment.APIEndPoints.urlEndPoints + environment.Bank.applyCampaign, body, { headers });
	}  

	registerSodimac(data:any) {
		const body = JSON.stringify(data);
		return this.http.post(environment.APIEndPoints.urlEndPoints + environment.Bank.sodimacRegister, body, { headers });
	}

	getCampaignReturns(data:any) {
		const body = JSON.stringify(data);
		return this.http.post(environment.APIEndPoints.urlEndPoints + environment.Bank.campaignInfoReturn, data, { headers });
	}

	applyCampaignReturns(data:any) {
		const body = JSON.stringify(data);
		return this.http.post(environment.APIEndPoints.urlEndPoints + environment.Bank.applyCampaignReturn, body, { headers });
	}
	getListBc() {
		return this.http.get(environment.APIEndPoints.urlEndPoints + environment.Bank.listBc, { headers });
	} 
	getCategory() {
		return this.http.get(environment.enlaceS3 + environment.s3.parametrosCategoryBc, { headers });
	} 

}