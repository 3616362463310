import { Component, OnInit, Input, } from '@angular/core';
import { BrokerService } from 'src/app/core/services/broker.service';
import { environment } from '../../../environments/environment';
import { DataLayersService } from 'src/app/core/services/data-layers.service';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {
  @Input() bann:any[]=[];
  public banners:any[]=[];
  linkLogin = environment.enlaceLogin;
  public linkBc = environment.enlaceBuscaCredito;
  constructor( public broker: BrokerService, private dataLayerService: DataLayersService ) { }

  ngOnInit(): void {
    this.getBanners();
  }

  getBanners(){
    let banners:any[] = this.bann;
    let date = new Date();
    let dataStart:any[];
    let dataEnd:any[];
    banners.forEach((ele) => {
      dataStart = ele.dateStart.split("-");
      dataEnd = ele.dateEnd.split("-"); 
      let dateStart = new Date(dataStart[0], dataStart[1] - 1, dataStart[2]);
      let dateEnd = new Date(dataEnd[0], dataEnd[1] - 1, dataEnd[2]);
        if((date.getTime() >= dateStart.getTime()) && (date.getTime() <= dateEnd.getTime()) ){
        this.banners.push(ele);
        }
    });
  }

  //EVENTO GA4 clic_slider_home
  /**
   * Esta función envia un evento de GA4 llamado "clic_slider_home"
   * por el banner seleccionado por el usuario
  */
  clic_slider_home( option:string ){
    this.dataLayerService.dl_clic_button_name('clic_slider_home', option + ' ' + '| Inicio');
  }

}
