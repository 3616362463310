import { Component, OnInit } from '@angular/core';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-poliza-premium',
  templateUrl: './poliza-premium.component.html',
  styleUrls: ['./poliza-premium.component.scss']
})
export class PolizaPremiumComponent implements OnInit {

  public urlPDF = environment.enlaceS3+"SBS/proteccion-premium-poliza.pdf";

  constructor( public sesion: SesionService, private dataLayerService: DataLayersService) { }

  ngOnInit() {
    // GA4 - User
    this.dataLayerService.dl_userId( '.', this.sesion.planCurrently );
  }

  descargarPDF() {
    return window.open(this.urlPDF);
  }
}
