<div class="row">
	<div class="col-12">
		<div class="row" *ngIf="data">
			<div class="col-12">
				<div class="tab-content" [ngClass]="{'noP' : noPadding}" id="pills-tabContent">
					<!-- Mis Reclamaciones -->
					<div class="row">
						<div class="col-12">
							<h4 *ngIf="showInfo" class="text-muted mdc-text-l mdc-fs-3 my-4 text-center">En esta secci&oacute;n
								encuentras informaci&oacute;n de los reclamos que has realizado sobre tus cuentas
								registradas en DataCr&eacute;dito</h4>
							<table class="table table-sm mdc-tb-history" *ngIf="data" aria-describedby="Reclamaciones">
								<thead>
									<tr class="mdc-text-secondary mt-5">
										<th >
											Entidad
										</th>
										<th>
											Fecha
										</th>
										<th>
											Estado
										</th>
										<th>
											Id. de reclamo
										</th>
									</tr>
								</thead>
								<tbody >
									<tr *ngFor="let reclamacion of data; let h = index">
										<td data-label="Entidad" width="270">
											{{ reclamacion.entidad }}
										</td>
										<td data-label="Fecha">
											{{ reclamacion.fecha | date: 'dd/MM/yyyy':'+0000' }}
										</td>
										<td data-label="Estado">
											{{  sesion.getStateReclamo(reclamacion.estado) }}
										</td>
										<td data-label="Id. de reclamo">
											{{ reclamacion.numero }}
										</td>
									</tr>
								</tbody>
							</table>
							<div class="tableMob row" *ngFor="let reclamacion of data; let h = index">
								<div class="tituloTableMob b-t-l col-6">
									<span>Entidad</span>
								</div>
								<div class="descTableMob col-6">
									{{ reclamacion.entidad }}
								</div>
								<div class="tituloTableMob col-6">
									<span>Fecha</span>
								</div>
								<div class="b-t-r descTableMob col-6">
									{{ reclamacion.fecha | date: 'dd/MM/yyyy':'+0000' }}
								</div>
								<div class="tituloTableMob col-6">
									<span>N° de cuenta</span>
								</div>
								<div class="descTableMob col-6">
									{{ reclamacion.numeroCuenta }}
								</div>
								<div class="tituloTableMob col-6">
									<span>Estado</span>
								</div>
								<div class="descTableMob col-6">
									{{  sesion.getStateReclamo(reclamacion.estado) }}
								</div>
								<div class="tituloTableMob b-b-l col-6">
									<span>Id. de reclamo</span>
								</div>
								<div class="b-b-r descTableMob col-6">
									{{ reclamacion.numero }}
								</div>
							</div>
							<div *ngIf="showInfo" class="infoReclamaciones">
								<div class="imgInfoReclamaciones">
									<img src="assets/img/icons/help_webcheckout.svg" alt="checkout">
								</div>
								<div class="textoInfoReclamaciones">
									<p>Si deseas formular un
										reclamo sobre alguna de tus cuentas, dir&iacute;gete a tus <u><a
												class="mdc-text-secondary"
												[routerLink]="['/freemium/historico']" [queryParams]="{pg: 'CA'}">Cuentas Abiertas</a></u> o
										<u><a class="mdc-text-secondary"
											[routerLink]="['/freemium/historico']" [queryParams]="{pg: 'CC'}">Cuentas Cerradas</a></u>,
										selecciona la cuenta y en detalles podr&aacute;s formular tu reclamo.</p>
								</div>
							</div>
						</div>
					</div>
					<!-- Fin de Mis Reclamaciones -->
				</div>
			</div>			
		</div>
	</div>
</div>