import { Injectable } from '@angular/core';
import { SesionService } from './sesion.service';

@Injectable({
	providedIn: 'root'
})
export class ConstantesService {

	//USAR PARA PLANES
	perTrimestral = {
		name: "Perfil de crédito",
		periodicity: "trimestral",
		price: "47.250",
		discount: "10%"
	}
	perAnual = {
		name: "Perfil de crédito",
		periodicity: "anual",
		price: "157.500",
		discount: "25%"
	}
	antTrimestral = {
		name: "Protege tus datos",
		periodicity: "trimestral",
		price: "58.050",
		discount: "10%"
	}
	antAnual = {
		name: "Protege tus datos",
		periodicity: "anual",
		price: "193.500",
		discount: "25%"
	}
	proTrimestral = {
		name: "Protección Premium",
		periodicity: "trimestral",
		price: "63.450",
		discount: "10%"
	}
	proAnual = {
		name: "Protección Premium",
		periodicity: "anual",
		price: "211.500",
		discount: "25%"
	}
	items = [
		{
			title: "Historia de crédito",
			description: [
				"<span>Historia de crédito</span> detallada.",
				"<span>Reclama a la entidad</span> si tienes algún problema.",
				"<span>Revisa si las entidades consultaron tu historial</span> para concederte un crédito.",
				"<span>Alertas al sistema financiero</span> en caso de:",
				"Te suplantaron.",
				"Perdiste tu cédula.",
				"Vives en el exterior."
			]
		},
		{
			title: "Entrenador financiero",
			description: [
				"<span>Tu puntaje </span> en Datacrédito.",
				"<span>Te avisamos cuando las entidades</span> te consulten o reporten.",
				"<span>Alertas antifraude</span> por cambios en tu historia de crédito.",
				"<span>Calculadora de éxito</span> ante solicitud de créditos.",
				"<span>Asesor financiero virtual</span> para mejorar tu score y acceso al crédito.",
				"<span>Atención telefónica</span> personalizada."
			]
		},
		{
			title: "Mi presencia en internet",
			description: [
				"<span>Monitoreo de tus emails</span> y contraseñas en internet..",
				"Exposición de tus <span>documentos personales.</span>",
				"Recomendaciones <span>para evitar fraude.</span>"
			]
		},
		{
			title: "Protección premium",
			description: [
				"<span>Seguro anti fraude</span> ante el robo de identidad.*",
				"<span>Seguro ante el robo</span> de tarjetas de crédito.*",
				"<span>Recibe Asesoría Legal</span> personalizada.*"
			]
		}
	];

	public ayudasBanco = [
		{
			"id": "1007", "banco": "bancolombia", "img": "assets/img/images/ayudaBancolombia.png", "ayuda": "Bancolombia te brinda una <b>clave dinámica</b> para continuar con el pago, debes estar atento, pues esta llegará en un SMS o puedes revisarla también desde la aplicación <b>Personas.<b/>"
		},
		{
			"id": "1051", "banco": "davivienda", "img": "assets/img/images/ayudaDavivienda.png", "ayuda": "Ten presente los cuatro digitos de tu <b>clave virtual</b>, esta es la misma con la que ingresas normalmente al portal de <b>Davivienda.</b>"
		},
		{
			"id": "1507", "banco": "nequi", "img": "assets/img/images/ayudaNequi.png", "ayuda": "¡Recuerda! Tu número de cuenta con <b>Nequi</b> es tu número de celular."
		},
		{
			"id": "1013", "banco": "bancobbva", "img": "assets/img/images/ayudaBbva.png", "ayuda": "Te recomendamos usar la versión de escritorio para pagar tu plan con <b>BBVA<b/>, allí podrás hacerlo más fácil."
		},
		{
			"id": "1551", "banco": "daviplata", "img": "assets/img/images/ayudaDaviplata.png", "ayuda": "Te recomendamos usar la versión web de <b>Daviplata</b>, para que puedas generar tu pago sin interrupciones."
		},
		{
			"id": "1001", "banco": "bancodebogotá", "img": "assets/img/images/ayudaBancobogota.png", "ayuda": "Te recomendamos usar la versión de escritorio. A la hora de finalizar el pago, ten en cuenta que debes generar tu clave de token en la aplicación de Banco de Bogotá."
		},
	]

	public ayudaSel: any;

	public planes = {} as any;
	public planesFil = [];
	public freePremium = {};
	public planPer:any = {};
	public planAnt:any = {};
	public planPre:any = {};

	constructor(public sesion: SesionService) {

		this.planPer = this.sesion.planes.planes.per;
		this.planAnt = this.sesion.planes.planes.ant;
		this.planPre = this.sesion.planes.planes.pre;
	
		this.freePremium = {
		  nombre: "", id: 'planFreeD', valorMes: 0, valorTotal: 0, caracteristicas: [], mensaje: "    ", planGene: {}, tipo: '', popular: false
		}
	
		this.planes =
		{
		  mensual: [
			{
			  plan: 'per', id: 'planPerMensualD', nombre: this.planPer.popup, valorMes: this.planPer.mensual[1], valorTotal: this.planPer.mensual[0], caracteristicas: this.planPer.itemsV2, mensaje: this.planPer.mensual[2], planGene: this.planPer, tipo: '1', popular: false, mensajePop : 'Mejora la forma en la que te ven las entidades financieras.', periodo : 'Mensual'
			},
			{
			  plan: 'ant', id: 'planAntMensualD', nombre: this.planAnt.popup, valorMes: this.planAnt.mensual[1], valorTotal: this.planAnt.mensual[0], caracteristicas: this.planAnt.itemsV2, mensaje: this.planAnt.mensual[2], planGene: this.planAnt, tipo: '1', popular: true , mensajePop : 'Cuida tus datos personales de posibles fraudes por suplantación.', periodo : 'Mensual'
			},
			{
			  plan: 'pro', id: 'planPreMensualD', nombre: this.planPre.popup, valorMes: this.planPre.mensual[1], valorTotal: this.planPre.mensual[0], caracteristicas: this.planPre.itemsV2, mensaje: this.planPre.mensual[2], planGene: this.planPre, tipo: '1', popular: false, mensajePop : 'Cuida tus datos personales de posibles fraudes por suplantación.', periodo : 'Mensual'
			}
		  ],
		  trimestral: [
			{
			  plan: 'per', id: 'planPerTrimestralD', nombre: this.planPer.popup, valorMes: this.planPer.trimestral[1], valorTotal: this.planPer.trimestral[0], caracteristicas: this.planPer.itemsV2, mensaje: this.planPer.trimestral[2], planGene: this.planPer, tipo: '2', popular: false, mensajePop : 'Mejora la forma en la que te ven las entidades financieras.', periodo : 'Trimestral'
			},
			{
			  plan: 'ant', id: 'planAntTrimestralD', nombre: this.planAnt.popup, valorMes: this.planAnt.trimestral[1], valorTotal: this.planAnt.trimestral[0], caracteristicas: this.planAnt.itemsV2, mensaje: this.planAnt.trimestral[2], planGene: this.planAnt, tipo: '2', popular: true, mensajePop : 'Cuida tus datos personales de posibles fraudes por suplantación.', periodo : 'Trimestral'
			},
			{
			  plan: 'pro', id: 'planPreTrimestralD', nombre: this.planPre.popup, valorMes: this.planPre.trimestral[1], valorTotal: this.planPre.trimestral[0], caracteristicas: this.planPre.itemsV2, mensaje: this.planPre.trimestral[2], planGene: this.planPre, tipo: '2', popular: false, mensajePop : 'Cuida tus datos personales de posibles fraudes por suplantación.', periodo : 'Trimestral'
			}
		  ],
		  anual: [
			{
			  plan: 'per', id: 'planPerAnualD', nombre: this.planPer.popup, valorMes: this.planPer.anual[1], valorTotal: this.planPer.anual[0], caracteristicas: this.planPer.itemsV2, mensaje: this.planPer.anual[2], planGene: this.planPer, tipo: '5', popular: false, mensajePop : 'Mejora la forma en la que te ven las entidades financieras.', periodo : 'Anual'
			},
			{
			  plan: 'ant', id: 'planAntAnualD', nombre: this.planAnt.popup, valorMes: this.planAnt.anual[1], valorTotal: this.planAnt.anual[0], caracteristicas: this.planAnt.itemsV2, mensaje: this.planAnt.anual[2], planGene: this.planAnt, tipo: '4', popular: true, mensajePop : 'Cuida tus datos personales de posibles fraudes por suplantación.', periodo : 'Anual'
			},
			{
			  plan: 'pro', id: 'planPreAnualD', nombre: this.planPre.popup, valorMes: this.planPre.anual[1], valorTotal: this.planPre.anual[0], caracteristicas: this.planPre.itemsV2, mensaje: this.planPre.anual[2], planGene: this.planPre, tipo: '4', popular: false, mensajePop : 'Cuida tus datos personales de posibles fraudes por suplantación.', periodo : 'Anual'
			}
		  ],
		};
	
	 }

	buscarAyudaBanco(bancoBuscar: any) {
		this.ayudaSel = this.ayudasBanco.find(ele => ele.id == bancoBuscar);
	}
}
