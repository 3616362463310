<div class="generalF">
    <div class="header-fraude">
        <img class="pieza-women" src="assets/img/landings/fraude/TextoMidatacredito_1.svg" alt="Midatacrédito">
    </div>
    <p class="principalTxt">
        <b>“6 de cada 10</b> personas entre 25 y 39 años maniﬁestan estar <b>preocupadas por</b> <span class="prpl"><b>fraude y suplantación de identidad”</b></span><br>
        <span class="disclaimer">2022 GLOBAL IDENTITY AND FRAUD REPORT EXPERIAN</span>
    </p>
    <div class="boxItems">
        <p class="title">Protégete <span>GRATIS</span> de la suplantación</p>
        <div class="items">
            <div class="item">
                <img src="assets/img/landings/fraude/alertas.svg" alt="alertas">
                <div class="boxTxt">
                    <p>Recibe alertas <br><span>Entérate si se abre un nuevo producto a tu nombre.</span></p>
                </div>
            </div>
            <div class="item">
                <img src="assets/img/landings/fraude/HistoriaCredito.svg" alt="historia de crédito">
                <div class="boxTxt">
                    <p>Historia de crédito <br><span>Monitorea tu historia de crédito constatemente</span></p>
                </div>
            </div>
            <div class="item">
                <img src="assets/img/landings/fraude/GenerarAlertas.svg" alt="alertas">
                <div class="boxTxt">
                    <p>Genera alertas <br><span>Si extraviaste o te robaron tu documento de identidad</span></p>
                </div>
            </div>
        </div>
        <a href="{{linkLogin}}" id="btnProtege">Protegerme GRATIS</a>
    </div>
    <p class="subTxt">
        ¿Quieres más? <br><span>Activa un PLAN en Midatacrédito</span>
    </p>
    <div class="boxItems">
        <div class="items">
            <div class="item">
                <img src="assets/img/landings/fraude/ProtegeDatos.svg" alt="Protege">
                <div class="boxTxt">
                    <p>Protege tus datos <br><span>Rastrea el número de tu documento de identidad email, contraseñas y protégelos de la Dark web</span></p>
                </div>
            </div>
            <div class="item">
                <img src="assets/img/landings/fraude/Poliza.svg" alt="Poliza">
                <div class="boxTxt">
                    <p>Póliza Antifraude <br><span>Recibe una póliza antifraude y úsala si eres víctima de fraude*</span></p>
                </div>
            </div>
            <div class="item">
                <img src="assets/img/landings/fraude/Asesoria.svg" alt="Asesoria">
                <div class="boxTxt">
                    <p>Asesoría legal <br><span>Recibe asesoría legal personalizada si eres víctima de fraude</span></p>
                </div>
            </div>
        </div>
        <a href="{{linkLogin}}" id="btnProtege">Activar PLAN</a>
    </div>
    <div class="boxBlogs">
        <h3>Conoce más sobre fraude y suplantación</h3>
        <div class="itemBlog">
            <div class="image">
                <img src="assets/img/landings/fraude/blog2.png" alt="Proteger">
            </div>
            <div class="cont">
                <p>¿Cómo proteger tu información personal en línea?</p>
                <a href="https://blog.midatacredito.com/consejos-para-proteger-tu-informacion-personal/" id="btnBlog1">Leer articulo</a>
            </div>
        </div>
        <div class="itemBlog">
            <div class="image">
                <img src="assets/img/landings/fraude/blog1.png" alt="Pasos">
            </div>
            <div class="cont">
                <p>5 Pasos a seguir para saber qué hacer en caso de robo de identidad </p>
                <a href="https://blog.midatacredito.com/que-hacer-en-caso-de-robo-de-identidad/" id="btnBlog2">Leer articulo</a>
            </div>
        </div>
    </div>
</div>
