import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-autorizacion',
  templateUrl: './autorizacion.component.html',
  styleUrls: ['./autorizacion.component.scss']
})
export class AutorizacionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.location.href = environment.enlaceS3 + environment.s3.autorizacionDatos;
  }

}
