<!-- tab tarjeta de credito -->
<app-micro-loader [error]="true" *ngIf="errorPayU">
</app-micro-loader>
<section class="tc" *ngIf="!errorPayU">
    <div class="container">
        <a class="back backTc" (click)="inactiveTabF()">
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Métodos de pago
        </a>
        <!-- <h2>Tarjeta de crédito</h2>
                <p>Midatacredito no guarda los datos de la tarjeta de crédito, estos son usados únicamente para efectos del pago.</p>
                <div class="row d-md-none d-block">
                    <div class="col-12">
                        <app-sidebar [data]="sesion"></app-sidebar>
                    </div>
                </div> -->
        <form class="pt-3 pb-3">
            <div class="row d-none">
                <div class="col-12 col-md-6 pl-md-1 pl-auto text-center">
                    <div class="row">
                        <div class="col-12 px-md-5 px-1">
                            <div id="mdc-list-cards" class="d-none"></div>
                            <ul class="mdc-list-cards list-inline mx-auto justify-content-center mt-4">
                                <li>
                                    <button id="tcVisa" type="button" class="mr-2">
                                        'd-inline': !card.method || card.method != 'VISA',
                                        <img src="assets/img/icons/visa_off.svg" alt="" [ngClass]="{
                                                'd-inline': !card.method || card.method != 'VISA',
                                                'd-none':  card.method === 'VISA'
                                                }">
                                        <img src="assets/img/icons/visa_on.svg" alt="VISA" [ngClass]="{
                                                'd-none': !card.method || card.method != 'VISA',
                                                'd-inline':  card.method === 'VISA'
                                                }">
                                    </button>
                                </li>
                                <li>
                                    <button id="tcMaste" type="button" class="mr-2">
                                        <img src="assets/img/icons/master_off.svg" alt="mastercard" [ngClass]="{
                                                'd-inline': !card.method || card.method != 'MASTERCARD',
                                                'd-none':  card.method === 'MASTERCARD'
                                                }">
                                        <img src="assets/img/icons/master_on.svg" alt="mastercard" [ngClass]="{
                                                'd-none': !card.method || card.method != 'MASTERCARD',
                                                'd-inline':  card.method === 'MASTERCARD'
                                                }">
                                    </button>
                                </li>
                                <li>
                                    <button id="tcAmex" type="button" class="mr-2">
                                        <img src="assets/img/icons/american_off.svg" alt="AMERICANEXPRESS" [ngClass]="{
                                                'd-inline': !card.method || card.method != 'AMERICANEXPRESS',
                                                'd-none':  card.method === 'AMERICANEXPRESS'
                                                }">
                                        <img src="assets/img/icons/american_on.svg" alt="AMERICANEXPRESS" [ngClass]="{
                                                'd-none': !card.method || card.method != 'AMERICANEXPRESS',
                                                'd-inline':  card.method === 'AMERICANEXPRESS'
                                                }">
                                    </button>
                                </li>
                                <li>
                                    <button id="tcDiners" type="button">
                                        <img src="assets/img/icons/diners_off.svg" alt="dinners" [ngClass]="{
                                                'd-inline': !card.method || card.method != 'DINERSCLUB',
                                                'd-none':  card.method === 'DINERSCLUB'
                                                }">
                                        <img src="assets/img/icons/diners_on.svg" alt="dinners" [ngClass]="{
                                                'd-none': !card.method || card.method != 'DINERSCLUB',
                                                'd-inline':  card.method === 'DINERSCLUB'
                                                }">
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="box-info">
                <form id="pagoTC" #datos="ngForm" (ngSubmit)="realizarPago(datos)" autocomplete="off"
                    (keyup)="validarData(datos)">
                    <input type="hidden" #method="ngModel" [(ngModel)]="card.method"
                        [ngModelOptions]="{standalone: true}">
                    <input type="hidden" id="creditCardTokenId" name="creditCardTokenId" #token="ngModel"
                        [(ngModel)]="tarjetaCredito.params.transaction.creditCardTokenId">
                    <div class="box-step">
                        <div class="form-row">
                            <div class="form-group col-12 col-md-6" id="titularname_div">
                                <mat-form-field>
                                    <input (focus)="focus($event)" matInput type="text" name="firstName"
                                        class="form-control" id="firstName"
                                        [(ngModel)]="tarjetaCredito.params.transaction.payer.fullName"
                                        #firstName="ngModel" pattern="^[A-Za-zÑñáéíóúÁÉÍÓÚ]([A-Za-zÑñáéíóúÁÉÍÓÚ ]*)[A-Za-zÑñáéíóúÁÉÍÓÚ]$"
                                        placeholder="Nombre de tarjeta habiente"
                                        [ngClass]="{'is-valid': firstName.valid, 'is-invalid': ( (firstName.errors?.['required']  ) && firstName.touched ) }"
                                        required="required">
                                    <mat-error *ngIf="firstName?.errors?.['required']" class="invalid-feedback">Campo
                                        obligatorio.</mat-error>
                                    <mat-error *ngIf="firstName?.errors?.['pattern']" class="invalid-feedback">Nombre no válido. Ingresar solo letras sin espacios al inicio o al final</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-12 col-md-6">
                                <mat-form-field>
                                    <input (focus)="focus($event)" matInput type="tel" name="dniNumber" id="dniNumber"
                                        class="form-control" #dniNumber="ngModel"
                                        pattern="^[+]?([0-9]+(?:[0-9]*)?|[0-9]+)$"
                                        [(ngModel)]="tarjetaCredito.params.transaction.payer.dniNumber"
                                        placeholder="Identificación de tarjeta habiente" maxlength="11"
                                        required="required"
                                        [ngClass]="{'is-valid': dniNumber.valid, 'is-invalid': ( dniNumber.errors?.['required'] && dniNumber.touched ) }">
                                    <mat-error *ngIf="dniNumber?.errors?.['required']" class="invalid-feedback">Campo
                                        obligatorio.</mat-error>
                                    <mat-error *ngIf="dniNumber?.errors?.['pattern']" class="invalid-feedback">Documento
                                        no válido. Ingresar solo números.</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-row mb-2">
                            <div class="form-group col-12 col-md-6" id="cardnumber_div">
                                <mat-form-field>
                                    <input (focus)="focus($event)" matInput type="tel" placeholder="Número de tarjeta"
                                        name="cardnumber" class="form-control" id="cardnumber"
                                        pattern="^[+]?([0-9]+(?:[0-9]*)?|[0-9]+)$" [(ngModel)]="card.number"
                                        maxlength="16" #cardnumber="ngModel"
                                        [ngClass]="{'is-valid': (cardnumber.valid && tarjetaValida), 'is-invalid': (( (cardnumber.errors?.['required'] ) && cardnumber.touched ) || !tarjetaValida) }"
                                        required="required" (keyup)="validateCard()">
                                    <img src="assets/img/pay/L-Visa.svg" alt="VISA" class="card-bank"
                                        [ngClass]="{'d-none': !card.method || card.method != 'VISA', 'card-bank':  card.method === 'VISA' }" />
                                    <img src="assets/img/pay/L-MasterCard.svg" alt="MASTERCARD" class="card-bank"
                                        [ngClass]="{'d-none': !card.method || card.method != 'MASTERCARD', 'card-bank':  card.method === 'MASTERCARD' }" />
                                    <img src="assets/img/pay/L-AmericanExpress.svg" alt="AMERICAN EXPRESS"
                                        class="card-bank"
                                        [ngClass]="{'d-none': !card.method || card.method != 'AMERICANEXPRESS', 'card-bank':  card.method === 'AMERICANEXPRESS' }" />
                                    <img src="assets/img/pay/L-DinersClub.svg" alt="DINERSCLUB" class="card-bank"
                                        [ngClass]="{'d-none': !card.method || card.method != 'DINERSCLUB', 'card-bank':  card.method === 'DINERSCLUB' }" />
                                    <mat-error *ngIf="cardnumber?.errors?.['required']" class="invalid-feedback">Campo
                                        obligatorio.</mat-error>
                                    <mat-error *ngIf="cardnumber?.errors?.['pattern']" class="invalid-feedback">Número
                                        de tarjeta no válido. Ingresar solo números.</mat-error>
                                    <mat-error
                                        *ngIf="!tarjetaValida && !cardnumber?.errors?.['pattern'] && !cardnumber?.errors?.['required']"
                                        class="invalid-feedback customError">Número de tarjeta no válido.</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-6 col-md-3" id="month_exp_div">
                                <mat-form-field floatLabel="always">
                                    <mat-label>Fecha de vencimiento</mat-label>
                                    <input (focus)="focus($event)" matInput name="expDate" id="expDate"
                                        class="form-control" #expDate="ngModel"
                                        pattern="[0-9]{2}/[0-9]{4}" maxlength="7"
                                        [(ngModel)]="exp"
                                        placeholder="MM/YYYY"
                                        required="required" (keyup)="setExpDate2($event)">
                                    <mat-error *ngIf="expDate?.errors?.['required']" class="invalid-feedback">Campo
                                        obligatorio.</mat-error>
                                    <mat-error *ngIf="expDate?.errors?.['pattern']" class="invalid-feedback">
                                        Formato no válido. Ingresar MM/YYYY</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-6 col-md-3" id="security_code_div">
                                <mat-form-field floatLabel="always" class="boxCvv">
                                    <mat-label>Código de seguridad</mat-label>
                                    <input (focus)="focus($event)" matInput type="tel" name="security_code"
                                        class="form-control" id="security_code" #securityCode="ngModel"
                                        pattern="^[+]?([0-9]+(?:[0-9]*)?|[0-9]+)$" [(ngModel)]="card.cvv"
                                        placeholder="CVV" maxlength="4" required="required"
                                        [ngClass]="{'is-valid': securityCode.valid, 'is-invalid': ( securityCode.errors?.['required'] && securityCode.touched ) }">
                                        
                                        <a data-toggle="modal" data-target="#cvv" data-placement="top">
                                            <img src="assets/img/icons/icon-question-purple.svg" class="cvv" alt="cvv" />
                                        </a>
                                        <mat-hint class="hint-cvv">Encuéntralo en la parte trasera de tu tarjeta.</mat-hint>
                                    <mat-error *ngIf="securityCode?.errors?.['required']" class="invalid-feedback">
                                        Campo obligatorio. </mat-error>
                                    <mat-error *ngIf="securityCode?.errors?.['pattern']" class="invalid-feedback">Código
                                        de seguridad no válido. Ingresar solo números. Ej: 123</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12 col-md-6" id="citydivTC">
                                <mat-form-field id="selectCity">
                                    <mat-label>Seleccionar ciudad</mat-label>
                                    <mat-select (selectionChange)="validarData(datos)" (focus)="focus($event)" placeholder="Ciudad" name="cityTC" id="cityTC"
                                    class="form-control" required="required"
                                    [(ngModel)]="tarjetaCredito.params.transaction.payer.billingAddress.city"
                                    #city="ngModel"(click)="resetCiudades()">
                                        <input type="text"
                                        placeholder="Buscar"
                                        [(ngModel)]="filterC"
                                        (keyup)="filter()" name="filterC" id="filterC"
                                        class="inputCiudad"
                                        (blur)="resetCiudades()">
                                        
                                        <mat-option *ngFor="let ciudad of ciudades"[value]="ciudad.COD_MUN">{{ ciudad.NAME_MUN }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="form-group col-12 col-md-6" id="addressdivTC">
                                <mat-form-field>
                                    <input (focus)="focus($event)" matInput type="text" name="addressTC" id="addressTC"
                                        #address="ngModel" class="form-control"
                                        [(ngModel)]="tarjetaCredito.params.transaction.payer.billingAddress.street1"
                                        placeholder="Dirección" maxlength="50" pattern="^[A-Za-zÑñáéíóúÁÉÍÓÚ 0-9#-]+$"
                                        required="required"
                                        [ngClass]="{'is-valid': address.valid, 'is-invalid': ( address.errors?.['required'] && address.touched ) }">
                                    <mat-error *ngIf="address?.errors?.['required']" class="invalid-feedback">Campo
                                        obligatorio.</mat-error>
                                    <mat-error *ngIf="address?.errors?.['pattern']" class="invalid-feedback">Dirección
                                        no válida. Solo letras, números y caracteres permitidos (# - .)</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="form-group col-12 col-md-6" id="cellphonedivTC">
                                <mat-form-field>
                                    <input (focus)="focus($event)" matInput type="number" name="cellphoneTC"
                                        id="cellphoneTC" maxlength="10" class="form-control" #phone="ngModel"
                                        [(ngModel)]="tarjetaCredito.params.transaction.payer.billingAddress.phone"
                                        placeholder="Celular" pattern="^[+]?([0-9]+(?:[0-9]*)?|[0-9]+)$"
                                        required="required"
                                        [ngClass]="{'is-valid': phone.valid, 'is-invalid': ( phone.errors?.['required'] && phone.touched ) }">
                                    <mat-error *ngIf="phone?.errors?.['required']" class="invalid-feedback">Campo
                                        obligatorio.</mat-error>
                                    <mat-error *ngIf="phone?.errors?.['pattern']" class="invalid-feedback">Celular no
                                        válido. Ingresar solo números.</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="create-errors alert-danger col-12 text-center"></div>
                        <button [attr.disabled]="datos.valid && tarjetaValida ? null : 'disabled'"
                            [ngClass]="{'disabled': !datos.valid && !tarjetaValida }" name="validar"
                            id="validar" webActivity data-idtag="Pagos" data-nametag="validarTC"
                            class="btn-midc btn-orange2 w-100-btn h-lg-btn">
                            Realizar pago
                            <i class="fa fa-chevron-right"></i>
                        </button>
                        <p (click)="setSpinner(false)" id="setSpinner"></p>
                    </div>
                </form>
            </div>
        </form>
    </div>
</section>
<!-- fin de tarjetas de credito -->