<section class="credit-profile">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-7">
				<h1>Evita el fraude financiero con el servicio de protección de datos de Midatacrédito</h1>
				<div class="bullet-box">
					<div class="img-bullet">
						<img src="assets/img/landings/new/protege/Plan_Protege-1-Monitoreo.svg" alt="Monitoreo" />
					</div>
					<div class="content-bullet">
						<h4>Activa el monitoreo ante posibles fraudes</h4>
						<p>Evita aperturas de cuentas y créditos a tu nombre</p>
					</div>
				</div>
				<div class="bullet-box">
					<div class="img-bullet">
						<img src="assets/img/landings/new/protege/Plan_Protege-2-DatosPersonales.svg" alt="Datos personales" />
					</div>
					<div class="content-bullet">
						<h4>Detecta filtraciones de tus datos personales</h4>
						<p>Monitoreo 24/7 de tus cuentas de correo y contraseñas en internet.</p>
					</div>
				</div>
				<div class="bullet-box">
					<div class="img-bullet">
						<img src="assets/img/landings/new/protege/Plan_Protege-3-Alertas.svg" alt="Alertas" />
					</div>
					<div class="content-bullet">
						<h4>Recibe alertas financieras</h4>
						<p>Cada vez que tus reportes sean actualizados en Datacrédito</p>
					</div>
				</div>
				<a class="btn-midc btn-orange h-lg-btn w-40-btn d-none d-md-flex" id="planAntMensualLanding" webActivity data-idtag="Landings" data-nametag="planAntMensualLanding" (click)="seleccionarPlan(sesion.planes.planes?.ant, '1', sesion.planes.planes?.ant?.mensual?.[1], sesion.planes.planes?.ant?.mensual?.[0])">
					Proteger mi información <i class="fa fa-chevron-right" aria-hidden="true"></i>
				</a>
			</div>
			<div class="col-12 col-md-5 align-self-center d-none d-md-block">
				<img src="assets/img/landings/new/protege/Plan_Protege.svg" alt="Plan Protege tus datos" />
			</div>
		</div>
	</div>
</section>

<section class="get-plan">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="box-get">
					<h3>Con este plan también obtienes</h3>
					<div class="bullets">
						<div class="column-bullet">
							<h3>Historia de crédito</h3>
							<div class="bullet" *ngFor="let item of sesion.planes.planes.freemium.itemsV2 | slice:0:4">
								<img src="assets/img/landings/new/Ico-Check.svg" alt="bullet" />
								<p>{{item.txt}}</p>
							</div>
              					<div class="bullet">
								<ul>
									<li *ngFor="let item of sesion.planes.planes.freemium.itemsV2 | slice:4:8">{{item.txt}}</li>
								</ul>
							</div>						
						</div>
						<div class="column-bullet">
              					<h3>Entrenador financiero</h3>
							<div class="bullet expand" *ngFor="let item of sesion.planes.planes.per.itemsV2">
								<img src="assets/img/landings/new/Ico-Check.svg" alt="bullet" />
								<p>{{item.txt}}</p>
							</div>
						</div>
					</div>
				</div>
				<img src="assets/img/landings/new/protege/Plan_Protege.svg" alt="Plan Perfil de Crédito" class="img-mobile"/>
				<h4>Tu presencia en internet</h4>
				<p class="w-60">Alerta inmediata ante cualquier riesgo, 24/7 protegiendo tus datos personales</p>
			</div>
		</div>
	</div>
</section>

<section class="plan">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-4 align-self-center">
				<div class="box-select">
					<form id="facturacion" name="facturacion" action="" method="">
						<div class="line-step"></div>
						<div *ngIf="sesion.facturacion == 1" class="contentInput inputActivo">
							<label for="pagMen">Mensual</label>
							<input type="radio" name="pagos" id="pagMen" value="1" (change)='viewPrices(1)' checked class="mdc-link-detail"/>
						</div>
						<div *ngIf="sesion.facturacion != 1" class="contentInput">
							<label for="pagMen">Mensual</label>
							<input type="radio" name="pagos" id="pagMen" value="1" (change)='viewPrices(1)' class="mdc-link-detail"/>
						</div>
						<div *ngIf="sesion.facturacion == 3" class="contentInput inputActivo">
							<label for="pagTri"><span>- {{ sesion.planes.planes?.ant?.trimestral?.[3] }} Dto. </span> Trimestral</label>
							<input type="radio" name="pagos" id="pagTri" value="3" (change)='viewPrices(3)' checked  class="mdc-link-detail"/> 
						</div>
						<div *ngIf="sesion.facturacion != 3" class="contentInput">
							<label for="pagTri"><span>- {{ sesion.planes.planes?.ant?.trimestral?.[3] }} Dto. </span> Trimestral</label> 
							<input type="radio" name="pagos" id="pagTri" value="3" (change)='viewPrices(3)'  class="mdc-link-detail"/>
						</div>
						<div *ngIf="sesion.facturacion == 12" class="contentInput inputActivo">
							<label for="pagAnu"><span>- {{ sesion.planes.planes?.ant?.anual?.[3] }} Dto. </span> Anual</label> 
							<input type="radio" name="pagos" id="pagAnu" value="12" (change)='viewPrices(12)' checked  class="mdc-link-detail"/>
						</div>
						<div *ngIf="sesion.facturacion != 12" class="contentInput">
							<label for="pagAnu"><span>- {{ sesion.planes.planes?.ant?.anual?.[3] }} Dto. </span> Anual</label> 
							<input type="radio" name="pagos" id="pagAnu" value="12" (change)='viewPrices(12)' class="mdc-link-detail" />
						</div>
					</form>
					<div class="planBox">
						<h2 *ngIf="sesion.facturacion == 1">${{ sesion.planes.planes?.ant?.mensual?.[1] }}</h2>
						<h2 *ngIf="sesion.facturacion == 3">${{ sesion.planes.planes?.ant?.trimestral?.[1] }}</h2>
						<h2 *ngIf="sesion.facturacion == 12">${{ sesion.planes.planes?.ant?.anual?.[1] }}</h2>
						<h5>Mensual</h5>
						<p *ngIf="sesion.facturacion == 1">{{ sesion.planes.planes?.ant?.mensual?.[2] }}</p>
						<p *ngIf="sesion.facturacion == 3">{{ sesion.planes.planes?.ant?.trimestral?.[2] }}</p>
						<p *ngIf="sesion.facturacion == 12">{{ sesion.planes.planes?.ant?.anual?.[2] }}</p>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-8 align-self-center">
				<div class="box-cta">
					<h3>Hoy Midatacrédito protege y monitorea a más de 150.000 personas cada mes</h3>
					<p>Haz parte de los Colombianos que se preocupan por su realidad financiera y toma el control de tu vida crediticia</p>
					<div class="bg-btn" *ngIf="sesion.facturacion == 1" >
						<a 
							class="btn-midc btn-orange h-lg-btn w-40-btn" 							
							id="planAntMensualLandingInf" webActivity data-idtag="Landings" data-nametag="planAntMensualLandingInf" 
							(click)="seleccionarPlan(sesion.planes.planes?.ant, '1', sesion.planes.planes?.ant?.mensual?.[1], sesion.planes.planes?.ant?.mensual?.[0])">
							Proteger mi información
							<i class="fa fa-chevron-right" aria-hidden="true"></i>
						</a>
					</div>
					<div class="bg-btn" *ngIf="sesion.facturacion == 3" >
						<a 
							class="btn-midc btn-orange h-lg-btn w-40-btn" 							
							id="planAntTrimestralLandingInf" webActivity data-idtag="Landings" data-nametag="planAntTrimestralLandingInf" 
							(click)="seleccionarPlan(sesion.planes.planes?.ant, '2', sesion.planes.planes?.ant?.trimestral?.[1], sesion.planes.planes?.ant?.trimestral?.[0])">
							Proteger mi información
							<i class="fa fa-chevron-right" aria-hidden="true"></i>
						</a>
					</div>
					<div class="bg-btn" *ngIf="sesion.facturacion == 12" >
						<a 
							class="btn-midc btn-orange h-lg-btn w-40-btn" 							
							id="planAntAnualLandingInf" webActivity data-idtag="Landings" data-nametag="planAntAnualLandingInf" 
							(click)="seleccionarPlan(sesion.planes.planes?.ant, '4', sesion.planes.planes?.ant?.anual?.[1], sesion.planes.planes?.ant?.anual?.[0])">
							Proteger mi información
							<i class="fa fa-chevron-right" aria-hidden="true"></i>
						</a>
					</div>					
					<p>
						<a routerLink="/comparacion-planes">Compara todos nuestros planes</a>
					</p>
				</div>
			</div>
		</div>
	</div>
</section>