<section class="myPlan" *ngIf="verificarTab()">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-6">
				<h1
					*ngIf="sesion.planCurrently == 'PER1' || sesion.planCurrently == 'PER2' || sesion.planCurrently == 'PER3' || sesion.planCurrently == 'PER4' || sesion.planCurrently == 'PER5'">
					Mejora tu puntaje</h1>
				<h1
					*ngIf="sesion.planCurrently == 'ANT1' || sesion.planCurrently == 'ANT2' || sesion.planCurrently == 'ANT3' || sesion.planCurrently == 'ANT4'">
					Protege tus datos</h1>
				<h1
					*ngIf="sesion.planCurrently == 'PRO1' || sesion.planCurrently == 'PRO2' || sesion.planCurrently == 'PRO3' || sesion.planCurrently == 'PRO4'">
					Protección premium</h1>
				<p
					*ngIf="sesion.planCurrently == 'PER1' || sesion.planCurrently == 'PER2' || sesion.planCurrently == 'PER3' || sesion.planCurrently == 'PER4' || sesion.planCurrently == 'PER5'">
					Tu entrenador financiero</p>
				<p
					*ngIf="sesion.planCurrently == 'ANT1' || sesion.planCurrently == 'ANT2' || sesion.planCurrently == 'ANT3' || sesion.planCurrently == 'ANT4'">
					Tu presencia en internet</p>
				<p
					*ngIf="sesion.planCurrently == 'PRO1' || sesion.planCurrently == 'PRO2' || sesion.planCurrently == 'PRO3' || sesion.planCurrently == 'PRO4'">
					Evita el fraude en tus compras</p>
				<ul class="tabs">
					<li *ngIf="sesion.estadoUserF == 'ACTIVO'">
						<a id="cambiarPlan" webActivity data-idtag="cambioPlanoPago" data-nametag="cambiarPlan"
							[ngClass]="tabActive == 1 ? 'active' : ''" (click)="tabsHC(1)">Mi plan</a>
					</li>
					<!-- <li class="w-60">
						<a id="cambiarMetodoPago" webActivity data-idtag="cambioPlanoPago"
							data-nametag="cambiarMetodoPago" [ngClass]="tabActive == 2 ? 'active' : ''"
							(click)="tabsHC(2)">Método de pago</a>
					</li> -->
				</ul>
			</div>
			<div class="col-12 col-md-6"></div>
		</div>
	</div>
</section>
<section class="content-myPlan">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<app-plan-upselling *ngIf="tabActive == 1"></app-plan-upselling>
				<!-- <app-metodo-pago *ngIf="tabActive == 2"></app-metodo-pago> -->
			</div>
		</div>
	</div>
</section>