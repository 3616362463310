import { Component, OnInit } from '@angular/core';
import { BrokerService } from 'src/app/core/services/broker.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/core/services/utilities.service';
declare var $: any;

@Component({
  selector: 'app-notifications-free',
  templateUrl: './notifications-free.component.html',
  styleUrls: ['./notifications-free.component.scss']
})

export class NotificationsFreeComponent implements OnInit {

  flagS:boolean=false;
  public checkDiv = false;
  public checkDiv2 = false;

  constructor(
    public sesion: SesionService,
    public broker: BrokerService,
    public modal: ModalService,
    public loading: LoadingService,
    private utilities: UtilitiesService, 
    private dlService: DataLayersService,
    public router: Router ) {
    this.sesion.getAlertsMongoWS();
  }

  guardarCambios() {
    this.sesion.saveAlertsMongoWS();
  }

  ngOnInit() {
    // GA4 - User
    this.dlService.dl_userId( '.', this.sesion.planCurrently );
    $('.toggle').click(function (e: any) {
      e.preventDefault(); // The flicker is a codepen thing
      $(e).toggleClass('toggle-on');
    });
  }

  activate() {
    setTimeout(() => {
      let activator = this.sesion.notificacionesActivas[7].checked;
      this.sesion.alertMongo = false;
      this.broker.activateEmailFree(this.sesion.user.phone, activator).subscribe({
        next: (result: any) => {
          if (result.status == "OK") {
            this.sesion.alertMongo = true;
            this.flagS=true;
          }
        },
        error: () => {
          this.sesion.notificacionesActivas[7].checked = activator;
          this.sesion.errorAlertMongo = true;
        }
      });
    }, 500);
  }

  /**
   * Inicializar banner de Google Ad Manager
   */
  checkBanner(){
    if (!this.checkDiv) {
			let tamano = [[728, 90], [300, 250]];
			this.utilities.addPubliById(environment.slotsBanners.free_notificaciones, 'div-gpt-ad-1694401046883-0', tamano);
			this.checkDiv = true;
		}
		return ''
  }

  checkBanner2(){
    if (!this.checkDiv2) {
			let tamano = [[728, 90], [300, 250]];
			this.utilities.addPubliById(environment.slotsBanners.free_notificaciones, 'div-gpt-ad-1694401046883-1', tamano);
			this.checkDiv2 = true;
		}
		return ''
  }
}
