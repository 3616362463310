import { Component, OnInit, Input } from '@angular/core';
import { offer } from '../dash-bc-module.component';
import { DataLayersService } from 'src/app/core/services/data-layers.service';

@Component({
  selector: 'app-box-offer',
  templateUrl: './box-offer.component.html',
  styleUrls: ['./box-offer.component.scss']
})
export class BoxOfferComponent implements OnInit {

  @Input() offer: offer;
  // Objeto de configuracion de etiquetas de la oferta donde 0 es por defecto la tarjeta sin etiqueta
  public configLabel: any = {
    '0': { color: 'blue', img: '', showLabel: false },
    '1': { color: 'orange', img: 'assets/img/bc/oferta_destacada_estrella.svg', showLabel: true },
    '2': { color: 'green', img: 'assets/img/bc/icono_etiqueta_nueva.svg', showLabel: true },
    '3': { color: 'blue', img: 'assets/img/bc/icono_etiqueta_regular.svg', showLabel: true },
  }
  constructor( private datalayer: DataLayersService ) {
    this.offer = {};
  }

  ngOnInit(): void {
  }

/**
 * Funcion para posicionar la imagen de la tarjeta de credito en diagonal y hasta la mitad
 * @param tarjeta varibale para saber si la oferta es de tarjeta y aplicar la clase y estilo
 * @param id id de la oferta
 * @param img imagen de la tarjeta
 * @returns clase en caso de que sea tarjeta
 */
  setback(tarjeta: boolean, id: string, img: string) {
    if (tarjeta) {
      let div = ".offer" + id + ":before {background-image:url(" + img + ");}";
      var styleElem = document.head.appendChild(document.createElement("style"));
      styleElem.innerHTML = div;
      return 'tarjeta';
    } else {
      return "";
    }
  }

  /**
   * Esta función envia un datalayer con información de la oferta, además redirige al sitio
   * de Buscacrédito
   */
  open_offer(offer_info: any) {
    this.datalayer.dl_select_content_bc('btn_apply_now_dashboard', offer_info.card.productName, offer_info.offerId, offer_info.company.name);
    window.open(offer_info.urlRedirect + '?utm_source=Midatacredito&utm_medium=Dashboard&utm_campaign=Cards', '_self', 'noopener');
  }

  /**
   * Funcion para validar que el labelType sea valido y si no lo es retorna una configuracion basica
   * @returns Objeto de configuracion de etiqueta
   */
  getConfigLabel() {
    if (this.configLabel[this.offer.labelType] != undefined) {
      return this.configLabel[this.offer.labelType];
    }
    return this.configLabel['0'];
  }

}
