
<div class="opPrivate genericBox priv-left" *ngIf=" id == 'FREE' && this.sesion.alertMongo">
    <img class="iconG vSmall" src="/assets/img/landings/dashboard/Comunicaciones_Morado.png" alt="Nuevas aperturas">
    <p class="titleG vSmall">{{sesion.notificacionesActivas[7].checked?'1':'0'}}/1<br><span>Comunicaciones sobre nuevas aperturas activas</span></p>
    <button class="btnGeneric my-2" (click)="redirect_and_send_data_layer('Ver comunicaciones','/mis-notificaciones')">Ver comunicaciones</button>
</div>
<div class="opPrivate genericBox alerts" *ngIf=" id == 'FREE-ALERTS'" >
    <img class="iconG vSmall" src="/assets/img/landings/dashboard/AlertasSistema_Blanco.png" alt="Nuevas aperturas">
    <p class="titleG vSmall"><span>Conoce el <b>SISTEMA DE ALERTAS</b> al sistema financiero, para <b>MITIGAR LA SUPLANTACIÓN</b> de identidad.</span></p>
    <button class="btnGeneric my-2" (click)="redirect_and_send_data_layer('Activa alertas gratis','/generar-alerta')">Activa alertas gratis</button>
</div>
<div class="opPrints genericBox" *ngIf=" id == 'FREE-PRINTS'">
    <img class="iconG" src="/assets/img/landings/dashboard/HuellaConsulta.png" alt="Nuevas aperturas">
    <p class="titleG"><span><b>Huellas de consulta</b> <br>de tu Historia de Crédito.</span></p>
    <button class="btnGeneric my-2" (click)="redirect_and_send_data_layer('Ver las huellas gratis','/huellas')">Ver las huellas gratis</button>
</div>
<div class="opPrivate genericBox priv-left" *ngIf="id == 'PRIVATE' && this.sesion.alertMongo">
    <img class="iconG vSmall" src="/assets/img/landings/dashboard/Comunicaciones_Morado.png" alt="Nuevas aperturas">
    <p class="titleG vSmall">{{sesion.notificacionesActivas[7].checked?'1':'0'}}/1<br><span>Comunicaciones sobre nuevas aperturas activas.</span></p>
    <button class="btnGeneric my-2"  (click)="redirect_and_send_data_layer('Ver comunicaciones','/mis-notificaciones')">Ver comunicaciones</button>
</div>
<div class="opPrivate genericBox priv-right" *ngIf="id == 'PRIVATE-PAY' && this.sesion.alertMongo">
    <img class="iconG vSmall" src="/assets/img/landings/dashboard/Comunicaciones_Morado.png" alt="Nuevas aperturas">
    <p class="titleG vSmall">{{countNotification()}}/{{calNotPrivate()}} <br><span>Comunicaciones sobre cambios en tu Historia de Crédito activas</span></p>
    <button class="btnGeneric my-2" (click)="redirect_and_send_data_layer('Ver comunicaciones','/mis-notificaciones')">Ver comunicaciones</button>
</div>
