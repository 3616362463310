<section class="pay">
    <div class="container-fluid padding-pay">
        <!-- Método de Pago -->
        <a class="back back-pay" (click)="go_back()" *ngIf="tabActivo == 0">
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            Volver
        </a>
        <div *ngIf="paymentType" class="box-pay">
            <h2>¿Cómo deseas pagar?</h2>
            <div class="secure-payment">
                <i class="fa fa-lock" aria-hidden="true"></i>
                <p>Realiza pagos de manera segura</p>
            </div> 
            <div class="box-payment-type row">
                <div class="col-6 pl-0 pr-1">
                    <div class="payment-type metodoTC" (click)="!validateGif() ?tabsPay(1):''" [ngClass]="{'activo': tabActivo == 1, 'disabled': validateGif() }">
                        <h3 class="type">Tarjeta Crédito o Débito (Con CVV)</h3>
                        <div class="franchise ">
                            <img class="visa" src="assets/img/pay/newPagos/Visa_icono_color.svg" alt="VISA"/>
                            <img class="master" src="assets/img/pay/newPagos/Mastercard_icono_color.svg" alt="MASTER CARD"/>
                            <img class="amex" src="assets/img/pay/newPagos/Amex_icono_color.svg" alt="AMERICAN EXPRESS"/>
                            <img class="diners" src="assets/img/pay/newPagos/Diners_icono_color.svg" alt="DINNERS"/>
                        </div>
                        <i class="fa fa-info icon" data-toggle="modal" data-target="#cvv" data-placement="top"></i>
                        <div class="boxIconDown">
                            <i class="fa fa-sort-down iconDown"></i>
                        </div>
                    </div>
                </div>
                <div class="col-6 pl-1 pr-0">
                    <div class="payment-type metodoPSE" (click)="!validateGif() ?tabsPay(2):''" [ngClass]="{'activo': tabActivo == 2, 'disabled': validateGif() }">
                        <h3 class="type">Cuenta de ahorro o corriente</h3>
                        <div class="franchise metodoPSE">
                            <img src="assets/img/pay/newPagos/PSE_icono_color.svg" alt="PSE" class="metodoPSE PSE"/>
                        </div>
                        <div class="boxIconDown">
                            <i class="fa fa-sort-down iconDown"></i>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Método de Pago -->
            <div>
                <app-tc *ngIf="tabActivo == 1 && sesion.valorPlanTotalDescuento != 0" (inactiveTab)="inactiveTab()" (sentActiveBtnPago)="enableButton($event,'tc')" (sentSaveAdvertising)="saveAdvertising()"></app-tc>
                <app-pse *ngIf="tabActivo == 2 && sesion.valorPlanTotalDescuento != 0" (inactiveTab)="inactiveTab()" (sentActiveBtnPago)="enableButton($event,'pse')" (sentSaveAdvertising)="saveAdvertising()"></app-pse>
                <app-gc *ngIf="tabActivo == 3 && sesion.valorPlanTotalDescuento != 0" (inactiveTab)="inactiveTab()"></app-gc>
            </div>    
            <form>
                <div class="form-row">
                    <div class="form-group col-12">
                        <p class="label-cod">Tienes un código promocional o una tarjeta de regalo:</p>
                        <input type="text" name="codPromoTC" id="codPromoTC" class="form-control"
                        #codPromo="ngModel" pattern="^[A-Za-z0-9]+$" (input)="validateCode()"
                        [(ngModel)]="sesion.descuentoData.params.promoCode"
                        placeholder="Ingresa aqui tu código" maxlength="8"
                        [ngClass]="{'is-valid': sesion.descuento === 'OK', 'is-invalid': (sesion.descuento === 'BAD' || ( codPromo.errors?.['pattern'] && codPromo.touched )) }">
                        <div *ngIf="sesion.spinnerLoader" class="spinGral"> 
                            <div class="spinner-border spinPurple" role="status"> 
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="textSpin">Por favor espera un momento, estamos validando el código promocional.</div>
                        </div>
                        <!-- <div *ngIf="!(sesion.descuento === 'BAD' || sesion.descuento === 'OK')" class="note">El uso de mayúsculas, minúsculas y guiones debe ser exacto</div> -->
                        <div *ngIf="codPromo?.errors?.['pattern']" class="invalid-feedback">Código no válido. Solo letras y números, no caracteres especiales, espacios, ni tildes.</div>
                        <div *ngIf="sesion.descuento === 'BAD'" class="invalid-feedback d-block">El código ingresado no es válido.</div>
                        <div *ngIf="sesion.descuento === 'OK'" class="valid-feedback d-block">El código promocional fue aplicado correctamente. <span *ngIf="sesion.valorPlanTotalDescuento != 0">Completa tu pago con:</span></div>  
                    </div>
                </div>
            </form>  
            <a href="{{url_terminos_condiciones}}?#page=7" fragmente="capitulo7" target="_blank" class="m-tyc" class="tyc-cta" rel="noopener">Ver términos y condiciones del pago</a>   
                        <!-- Promocode -->  
            <div class="custom-control px-0 mb-4" *ngIf="commercialAd != undefined && !commercialAd.status">
                <label class="check-midc" for="labelAdvert">
                    <input type="checkbox" class="" id="aceptAdvert1" name="aceptAdvert"
                        [(ngModel)]="isChecked" #aceptAdvert="ngModel"
                        (change)="!isChecked = isChecked ">
                    <p [innerHtml]="commercialAd.longDescription"></p>
                </label>
            </div>          
        </div>  
    </div>
    <div id="backdropFilter"></div>
	<div class="box-filter">
        <div class="contPlan">
            <div class="boxTab">
                <div class="tabPlanes" id="tab">
                    <button id="btnMobile" class="btnFilter" (click)="changeFilterView(false)">Qué incluye el plan</button>
                </div>
                <div class="contfilter" id="filterMobile">
                    <h6>Con el <span>PLAN {{plan.nombre}}</span> vas a tener:</h6>
                    <div class="boxPri">
                            <div class="opt">
                                <img src="{{benefits[0].icon}}" alt="benefit"/>
                                <p>{{benefits[0].txt}}</p>
                            </div>
                            <div class="opt">
                                <img src="{{benefits[1].icon}}" alt="benefit"/>
                                <p>{{benefits[1].txt}}</p>
                            </div>
                            <div class="opt">
                                <img src="{{benefits[2].icon}}" alt="benefit"/>
                                <p>{{benefits[2].txt}}</p>
                            </div>
                    </div>
                    <div *ngFor="let item of benefits; let i=index">
                        <div class="bullet" *ngIf="i>2">
                            <img src="{{item.icon}}" alt="benefit"/>
                            <p>{{item.txt}}</p>
                            <hr>
                        </div>
                    </div>
                </div>
            </div>
            <div class="boxInfoPlan">
                <div class="infoPlan comparacion" id="comparacion">
                    <h4>Escoge el plan que quieres</h4>
                    <div class="planes">
                        <label class="planOpt"><span>PLAN PERFIL DE CRÉDITO</span>
                            <input type="radio" name="acron" value="PER" [(ngModel)]="acron[1]" (change)="setPlan()">
                            <span class="geekmark"></span>
                        </label>
                        <label class="planOpt"><span>PLAN PROTEGE</span>
                            <input type="radio" name="acron" value="ANT" [(ngModel)]="acron[1]" (change)="setPlan()">
                            <span class="geekmark"></span>
                        </label>
                        <label class="planOpt"><span>PLAN PREMIUM</span>
                            <input type="radio" name="acron" value="PRO" [(ngModel)]="acron[1]" (change)="setPlan()">
                            <span class="geekmark"></span>
                        </label>
                    </div>
                    <hr>
                    <div class="planes">
                        <label class="planOpt"><span>Pago mensual</span>
                            <input type="radio" name="typePlan" value="1" [(ngModel)]="typePlan[1]" (click)="setPer(true)">
                            <span class="geekmark"></span>
                        </label>
                        <label class="planOpt"><span>Pago trimestral</span>
                            <input type="radio" name="typePlan" value="2" [(ngModel)]="typePlan[1]" (click)="setPer(true)">
                            <span class="geekmark"></span>
                            <span class="ahorro">Ahorra hasta {{plan.trimestral[3]}} con pago trimestral</span>
                        </label>
                        <label class="planOpt"><span>Pago anual</span>
                            <input type="radio" name="typePlan" value="{{acron[1]=='PER'?5:4}}" [(ngModel)]="typePlan[1]" (click)="setPer(true)">
                            <span class="geekmark"></span>
                            <span class="ahorro">Ahorra hasta {{plan.anual[3]}} con pago anual</span>
                        </label>
                    </div>
                </div>
                <div class="infoPlan">
                    <div class="boxBasicPlan">
                        <div class="valor">
                            <p class="title">{{plan.nombre}}</p>
                            <p class="txtV">${{sesion.descuento === 'OK'?sesion.valorPlanTotalDescuento:setPer(true)[0]}} <span>/${{setPer(true)[1]}}  por mes</span></p>
                        </div>
                        <div class="btns" id="btnPagar" *ngIf="!sesion.spinnerDebt">
                            <button class="pagar disabled" id="btnPagarB"(click)="pagar()">{{sesion.valorPlanTotalDescuento == 0 && sesion.descuento == 'OK'?'Activar mi plan':'Realizar pago'}}</button>
                            <button class="cambiar" (click)="changePlan()" [disabled]="sesion.descuento === 'OK'" [ngClass]="{'disabled':sesion.descuento === 'OK'}">Cambiar mi plan</button>
                        </div>
                        <div class="btns" id="btnPlan">
                            <button class="pagar" (click)="confirmPlan(true,false)">Confirmar cambios</button>
                            <button class="cambiar" (click)="confirmPlan(false,false)">Descartar cambios</button>
                        </div>
                        <div class="spinGral" *ngIf="sesion.spinnerDebt"> 
                            <img src="assets/img/spinner.gif" class="spinner-gif" alt="spinner"/>
                            <div class="textSpin"><span>Por favor espera un momento</span><br>
                            Estamos validando la información para activar tu plan</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</section>
<div id="dialog-message" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered mdc-modal-dialog tc-modal" role="document">
        <div class="modal-content">
            <div class="modal-body mdc-modal-my-1">
                <div class="row">
                    <div class="col-12 close-tc">
                        <a data-dismiss="modal">X</a>
                    </div>
                    <div class="col-md-6">
                        <div class="title-tc">
                            TÉRMINOS Y CONDICIONES
                        </div>
                        <div class="sub-title-tc">
                            <p>Condiciones de pago:</p>
                        </div>
                        <div class="content-tc">
                            <p>
                                Al aceptar la pr&oacute;rroga autom&aacute;tica, aceptas que al medio de pago utilizado
                                para
                                suscribirte, se cargue el valor correspondiente para la nueva vigencia. Podr&aacute;s
                                terminar
                                el contrato y evitar la pr&oacute;rroga autom&aacute;tica llamando a la <b>l&iacute;nea
                                    telef&oacute;nica en Bogot&aacute; 6017430606 o a nivel nacional 018000947030, de lunes
                                    a
                                    viernes
                                    en el horario de 8:00 a.m a 5:00 p.m.,</b> a m&aacute;s tardar el &uacute;ltimo
                                d&iacute;a
                                h&aacute;bil en que expira la vigencia inicial de la suscripci&oacute;n o de sus
                                pr&oacute;rrogas, seg&uacute;n el plan que escogiste.
                            </p>
                            <br/>
                        </div>
                        <div class="btn-close-tc">
                            <button type="button" name="" id="" class="btn mdc-btn-primary btn-block"
                                data-dismiss="modal" aria-label="Close">Entendido!
                                <i class="fa fa-chevron-right float-right mt-arrow"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col-md-6 text-center img-tc">
                        <img src="assets/img/icons/TyT_Pagos.svg" alt="pago">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- modal cvv -->
<div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" id="cvv">
    <div class="modal-dialog modal-dialog-centered mdc-modal-dialog" role="document">
        <div class="modal-content px-5 mdc-br">
            <div class="modal-body mb-2">
                <button type="button" class="closeModal" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="titleModal mb-3">¿En d&oacute;nde encuentras el c&oacute;digo de seguridad CW/CVC?</h5>
                <div class="row text-center">
                    <div class="col-md-6 col-12">
                        <p class="mdc-fs-1 mdc-text-gray">
                            Para tarjetas de crédito <u>VISA, Mastercard y Diners Club</u>, el código CVV se encuentra al <b>respaldo de la tarjeta</b>; para tarjetas de crédito <u>American Express</u>, el código CVV se encuentra en la al <b>frente de la tarjeta.</b>
                        </p>
                        <img src="assets/base/icon_tarjeta_credito.png" class="img-fluid" alt="tarjeta de crédito">
                    </div>
                    <div class="col-md-6 col-12">
                        <p class="mdc-fs-1 mdc-text-gray">
                            Para <u>tarjetas débito</u> el código verificación se encuentra al <b>respaldo de la tarjeta.</b>
                            <br />
                            <br />
                        </p>
                        <img src="assets/base/icon_tarjeta_debito_sinfondo.png" class="img-fluid" alt="tarjeta débito">
                    </div>
                </div>
                <button type="button" name="" id="" class="btn mdc-btn-primary btnModal" data-dismiss="modal"
                    aria-label="Close">Entendido
                    <i class="fa fa-chevron-right float-right mt-arrow"></i>
                </button>
            </div>
        </div>
    </div>
</div>

<!-- modal codigo pertenece a otro plan -->
<div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" id="modalCod" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered modalGifcard" role="document">
        <div class="modal-content boxModal">
            <div class="modal-body">
                <div class="boxImgCod">
                    <img src="assets/img/pay/newPagos/Giftcard_redencion_modal.svg" alt="redencion">
                </div>
                <div class="boxInfoCod">
                    <h3>¡Ups!</h3>
                    <p>No fue posible redimir el código promocional en está compra. <br><br>Este código promocional aplica para:</p>
                    <h4>{{paramsT[0]}}</h4>
                    <p class="infoCod">{{paramsT[1]}}% dto. <span>/ {{paramsT[2]}}.<br>Única redención.</span></p>
                    <p>¿Quieres que ajustemos tu compra?</p>
                    <button class="btnUse" (click)="confirmModal()">Sí, usar código</button>
                    <button class="btnClosCod" data-dismiss="modal" aria-label="Close" (click)="sesion.descuentoData.params.promoCode=''">Otro medio de pago     x</button>
                </div>
            </div>
        </div>
    </div>
</div>