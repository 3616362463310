import { Component, OnInit } from '@angular/core';
import { SesionService } from '../../core/services/sesion.service';
import { NgForm } from '@angular/forms';
import { SuscripcionService } from '../../core/services/suscripcion.service';
import { LoadingService } from '../../core/services/loading.service';
import { BrokerService } from 'src/app/core/services/broker.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-escaneo',
  templateUrl: './escaneo.component.html',
  styleUrls: ['./escaneo.component.scss']
})
export class EscaneoComponent implements OnInit {

  email: any;
  complete: boolean;
  termsDark = false;
  url_politica_privacidad = environment.enlaceS3 + environment.s3.politicaDePrivacidad;

  constructor(
    public sesion: SesionService,
    public suscripcion: SuscripcionService,
    private loading: LoadingService,
    public broker: BrokerService,
    private dataLayerService: DataLayersService) {
    this.complete = true;
    this.suscripcion.darkweb = false;
  }

  ngOnInit() {
  }

  escaneoAnonimo(datos: NgForm) {
    if (datos.valid) {
      this.openLoading();
      this.sesion.generic(this.email);
    }

    // GA4 - generate_lead
    this.dataLayerService.dl_generate_lead('0');
  }

  openLoading() {
    this.loading.open();
  }

  cerrarAnonimo() {
    this.sesion.anonimo = null;
    $('html, body').animate({
      scrollTop: $('.form-escaneo').offset().top
    }, 1000);
  }

  mostarCompleto() {
    this.complete = true;
  }
  ocultarCompleto() {
    this.complete = false;
  }
  seleccionarPlan(plan: any, tipo: any, valorMensual: any, valorTotal: any) {
    this.loading.open();

    localStorage.setItem("lanProtege", "1");

    this.broker.promov = 1;
    this.suscripcion.planSeleccionado = plan;
    this.suscripcion.tipoPlan = tipo;
    this.suscripcion.valorSeleccionadoMensual = valorMensual;
    this.suscripcion.valorSeleccionadoTotal = valorTotal;

    if (this.sesion.user) {
      this.suscripcion.planSesion(true, 'DRK');
    } else {
      this.suscripcion.planSesion(false, 'DRK');
    }

    this.loading.close();
  }
  showTerms() {
    if ($($(".text-ad")[0]).css("display") == "none") {
      $($(".text-ad")[0]).slideDown()
    } else {
      $($(".text-ad")[0]).slideUp()
    }
  }
}
