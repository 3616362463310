<div class="blog">
    <div class="box-post" [ngClass]="{'pink': postBefore.id == 1,'blue': postBefore.id == 2,'purple': postBefore.id == 3}" (click)="movePost(false)">
        <img [src]="postBefore.img" alt="Entrada Midatacredito" title="Entrada mi Midatacredito"/>
        <div class="pre-content">
            <p class="title-post">{{postBefore.date}}</p>
            <p>{{postBefore.desc}}</p>
        </div>
        <button (click)="clic_home_blog(postBefore.desc)" (click)="goPost(postBefore.url)" [ngClass]="{'orange': postBefore.id == 1,'blue': postBefore.id == 2,'purple': postBefore.id == 3}">Leer este artículo</button>
    </div>
    <div class="box-post" [ngClass]="{'pink': postMain.id == 1,'blue': postMain.id == 2,'purple': postMain.id == 3}">
        <img [src]="postMain.img" alt="Entrada Midatacredito" title="Entrada mi Midatacredito"/>
        <div class="pre-content">
            <p class="title-post">{{postMain.date}}</p>
            <p>{{postMain.desc}}</p>
        </div>
        <button (click)="clic_home_blog(postMain.desc)" (click)="goPost(postMain.url)" [ngClass]="{'orange': postMain.id == 1,'green': postMain.id == 2,'purple': postMain.id == 3}">Leer este artículo</button>
    </div>
    <div class="box-post" [ngClass]="{'pink': postAfter.id == 1,'blue': postAfter.id == 2,'purple': postAfter.id == 3}" (click)="movePost(true)">
        <img [src]="postAfter.img" alt="Entrada Midatacredito" title="Entrada mi Midatacredito"/>
        <div class="pre-content">
            <p class="title-post">{{postAfter.date}}</p>
            <p>{{postAfter.desc}}</p>
        </div>
        <button (click)="clic_home_blog(postAfter.desc)" (click)="goPost(postAfter.url)" [ngClass]="{'orange': postAfter.id == 1,'blue': postAfter.id == 2,'purple': postAfter.id == 3}">Leer este artículo</button>
    </div>
</div>