import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BrokerService } from '../services/broker.service';
import { LoadingService } from '../services/loading.service';
import { SesionService } from '../services/sesion.service';
import { SuscripcionService } from 'src/app/core/services/suscripcion.service';
import { UtilitiesService } from '../services/utilities.service';

@Injectable({
  providedIn: 'root'
})
export class ZonaPagoGuard implements CanActivate {
  constructor(public sesion: SesionService, public broker: BrokerService, public router: Router, public loading: LoadingService,public suscripcion: SuscripcionService,public utilities:UtilitiesService){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return new Observable<boolean>(obs => {
        this.sesion.getCookie();
        this.utilities.getUtmParams(false);
        if (this.sesion.cookieValue) {
          this.broker.validationSession(this.sesion.cookieValue).subscribe(
            {
              next: (resp: any) => {
                this.sesion.loggedIn = true;
                this.sesion.user = resp;
                if (this.sesion.dataUser == undefined) {
                    this.loading.open();
                    this.broker.datosUsuario(this.sesion.cookieValue).subscribe(
                    {
                      next: (data: any) =>{
                        this.loading.close();
                        this.sesion.setMainInfo(data).subscribe(
                          {
                            next: (data: any) => {
                              if (this.sesion.viewFree) {
                                this.suscripcion.setPlanUrl(this.sesion.loggedIn).then((res: any) => {
                                  obs.next(res.success)
                                }).catch((res:any) =>{
                                  obs.next(true)
                                });
                              } else {
                                localStorage.clear();
                                localStorage.setItem("ac", "1");
                                this.router.navigate(['/private/historico']);
                                obs.next(false);
                              }
                            },
                            error: (error) => {
                              obs.next(false);
                            }
                          });
                      },
                      error: (error: any) =>{
                        this.loading.close();
                        this.router.navigate(['']);
                        obs.next(false);
                      }
                    }
                      );
                } else if (this.sesion.dataUser != undefined) {
                  if (this.sesion.viewFree) {
                    this.suscripcion.setPlanUrl(this.sesion.loggedIn).then((res: any) => {
                      obs.next(res.success)
                      }).catch((res:any) =>{
                        obs.next(true)
                      });
                  } else {
                    localStorage.clear();
                    localStorage.setItem("ac", "1");
                    this.router.navigate(['/private/historico']);
                    obs.next(false);
                  }
                }
              },
              error: (error: any) =>{
                this.sesion.cleanData();
                this.router.navigate(['']);
                obs.next(true);
              }
            }
          );
        } else {
          if (next.url.length <= 0) {
            obs.next(true);
          } else if(state.url.split('?')[0] == "/pago"){
            this.suscripcion.setPlanUrl(this.sesion.loggedIn).then((res: any) => {
              obs.next(false);
              }).catch((res:any) =>{
                obs.next(true)
              });
          }else{
            obs.next(false);
          }
        }
      });
  }
}
