<div class="row main-presencia">
  <div class="col-12">
    <div class="row head-presencia">
      <div class="col-12 col-md-6">
        <div class="row">
          <div class="col-4 col-md-4">
            <img src="assets/img/icons/Icono_Presencia_internet.svg" alt="presencia"/>
          </div>
          <div class="col-8 col-md-7 d-flex borde-separador">
            <h2>Protección de datos personales internet</h2>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 d-flex">
        <p>Configura los datos que quieres monitorear y recibe detalles sobre los resultados de tus escaneos en la red.
        </p>
      </div>
    </div>
    <div class="row content-presencia">
      <div class="col-12" *ngIf="!(listDkLoad && getDkLoad)">
        <app-micro-loader [texto]="textoMicroLoader" [error]="errorList" [textoError]="textErrorMicroLoader">
        </app-micro-loader>
      </div>
      <!--CAJA DE CONFIGURAR-->
      <div class="col-12 boxConfigurar" *ngIf="getDkLoad">
        <div class="boxPresencia">
          <div class="tituloPresencia">
            <h2>Configurar Datos Monitoreados</h2>
          </div>
          <div id="accordionPresencia">
            <div class="card">
              <div class="card-header" id="headingOne">
                <button class="btnAcordeon" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
                  aria-controls="collapseOne" (click)="abrirAcordeon('headingOne')">
                  <div class="textBtnAcordeon">
                    <!-- <span>{{totalCA()}} Cuentas abiertas</span><br /> -->
                    <p>Direcciones de Correo Electrónico</p>
                    {{sesion.identity.extTransactionData.identity.idEmailAddr.length}}/3
                  </div>
                  <i class="fa fa-chevron-right"></i>
                </button>
              </div>
              <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionPresencia">
                <div class="card-body">
                  <div class="cuentas">
                    <div *ngFor="let email of sesion.identity.extTransactionData.identity.idEmailAddr;let i = index"
                      class="cuenta">
                      <div class="detalleCuenta">
                        <div class="textosCuentas">
                          <p class="nombreCuenta">{{email}}</p>
                        </div>
                        <div class="actions">
                          <button (click)="openModalDKW('mail',2,i)"><i class="fa fa-pencil"></i></button>
                          <button (click)="deleteUpdateInfo('mail',3,i)"><i class="fa fa-trash"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="caja-add">
                    <p>
                      Puedes registrar hasta tres ( 3 ) correos electrónicos para su protección y monitoreo en la red.
                    </p>
                    <div class="action-add">
                      <button [disabled]="sesion.identity.extTransactionData.identity.idEmailAddr.length > 2"
                        (click)="openModalDKW('mail',1)">Añadir mail</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingTwo">
                <button class="btnAcordeon" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                  aria-controls="collapseTwo" (click)="abrirAcordeon('headingTwo')">
                  <div class="textBtnAcordeon">
                    <!-- <span>{{totalCA()}} Cuentas abiertas</span><br /> -->
                    <p>Número de Teléfono/Celular</p>
                    {{sesion.identity.extTransactionData.identity.idCellPhone.length}}/3
                  </div>
                  <i class="fa fa-chevron-right"></i>
                </button>
              </div>
              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionPresencia">
                <div class="card-body">
                  <div class="cuentas">
                    <div *ngFor="let cellphone of sesion.identity.extTransactionData.identity.idCellPhone;let i = index"
                      class="cuenta">
                      <div class="detalleCuenta">
                        <div class="textosCuentas">
                          <p class="nombreCuenta">{{cellphone}}</p>
                        </div>
                        <div class="actions">
                          <button (click)="openModalDKW('cellPhone',2,i)"><i class="fa fa-pencil"></i></button>
                          <button (click)="deleteUpdateInfo('cellPhone',3,i)"><i class="fa fa-trash"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="caja-add">
                    <p>
                      Puedes registrar hasta tres ( 3 ) números telefónicos para su protección y monitoreo en la red.
                    </p>
                    <div class="action-add">
                      <button [disabled]="sesion.identity.extTransactionData.identity.idCellPhone.length > 2"
                        (click)="openModalDKW('cellPhone',1)">Añadir número</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <button class="btnAcordeon" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false"
                  aria-controls="collapseThree" (click)="abrirAcordeon('headingThree')">
                  <div class="textBtnAcordeon">
                    <!-- <span>{{totalCA()}} Cuentas abiertas</span><br /> -->
                    <p>Documentos de Identidad</p>
                    {{sesion.identity.extTransactionData.identity.idssnhash.length}}/1
                  </div>
                  <i class="fa fa-chevron-right"></i>
                </button>
              </div>
              <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionPresencia">
                <div class="card-body">
                  <div class="cuentas">
                    <div *ngFor="let idssnhash of sesion.identity.extTransactionData.identity.idssnhash" class="cuenta">
                      <div class="detalleCuenta">
                        <div class="textosCuentas">
                          <p class="nombreCuenta">{{idssnhash}}</p>
                        </div>
                        <!-- <div class="actions">
                          <button><i class="fa fa-pencil"></i></button>
                          <button><i class="fa fa-trash"></i></button>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingFour">
                <button class="btnAcordeon" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false"
                  aria-controls="collapseFour" (click)="abrirAcordeon('headingFour')">
                  <div class="textBtnAcordeon">
                    <!-- <span>{{totalCA()}} Cuentas abiertas</span><br /> -->
                    <p>Pasaporte</p>
                    {{sesion.identity.extTransactionData.identity.idPassportNum.length}}/1
                  </div>
                  <i class="fa fa-chevron-right"></i>
                </button>
              </div>
              <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionPresencia">
                <div class="card-body">
                  <div class="cuentas">
                    <div
                      *ngFor="let passport of sesion.identity.extTransactionData.identity.idPassportNum;let i = index"
                      class="cuenta">
                      <div class="detalleCuenta">
                        <div class="textosCuentas">
                          <p class="nombreCuenta">{{passport}}</p>
                        </div>
                        <div class="actions">
                          <button (click)="openModalDKW('pass',2,i)"><i class="fa fa-pencil"></i></button>
                          <button (click)="deleteUpdateInfo('pass',3,i)"><i class="fa fa-trash"></i></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="caja-add">
                    <p>
                      Puedes registrar hasta un ( 1 ) número de pasaporte para su protección y monitoreo en la red.
                    </p>
                    <div class="action-add">
                      <button [disabled]="sesion.identity.extTransactionData.identity.idPassportNum.length > 0"
                        (click)="openModalDKW('pass',1)">Añadir pasaporte</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--CAJA DE CONFIGURAR-->
      <!--CAJA DE NOTIFICACIONES-->
      <div class="col-12 boxConfigurar" *ngIf="listDkLoad">
        <div class="boxPresencia boxNotificaciones">
          <div class="tituloPresencia">
            <h2>Notificaciones</h2>
            <hr />
          </div>
          <div class="subtitulos d-none d-md-flex">
            <p class="sub-principal">Alerta</p>
            <p class="sub-secundaria">Fecha de hallazgo</p>
            <p class="sub-secundaria">Detalles</p>
          </div>
          <div id="accordionPresencia">
            <div class="card" *ngFor="let notificacion of listdk.extTransactionData.alertNotification; let i = index">
              <div class="card-header" id="headingNot{{i}}">
                <div class="btnAcordeon">
                  <div class="textBtnAcordeon">
                    <!-- <span>{{totalCA()}} Cuentas abiertas</span><br /> -->
                    <p class="titulo-principal d-block d-md-none">Alerta</p>
                    <p class="text-principal">{{getDescByType(notificacion.alertType)}}</p>
                    <p class="titulo-principal d-block d-md-none">Fecha de hallazgo</p>
                    <p class="text-secundario">{{ notificacion.findDate | date: 'd/M/y': 'd/M/y' }}</p>
                    <p class="titulo-principal d-block d-md-none">Detalles</p>
                    <p data-toggle="collapse" [attr.data-target]="'#collapseNot'+i" aria-expanded="false"
                      [attr.aria-controls]="'collapseNot'+i" class="text-secundario btn-detalle">
                      Detalles <i class="fa fa-caret-down"></i></p>
                  </div>
                </div>
              </div>
              <div id="collapseNot{{i}}" class="collapse" [attr.aria-labelledby]="'headingNot'+i"
                data-parent="#accordionPresencia">
                <div class="card-body">
                  <div class="info-dark">
                    <div class="row">
                      <div class="col-md-5 borde-derecho">
                        <p class="titulo">Descripción</p>
                        <p>{{ sesion.textosDarkWeb[notificacion.alertType]?.descripcion }}</p>
                      </div>
                      <div class="col-md-7">
                        <div class="row">
                          <div class="col-12">
                            <p class="titulo">Información Adicional</p>
                            <p>{{ sesion.textosDarkWeb[notificacion.alertType]?.infoadicional }}</p>
                          </div>
                          <div class="col-md-6">
                            <p class="titulo">Información comprometida</p>
                            <p>{{ notificacion.compromisedData }}</p>
                          </div>
                          <div class="col-md-6">
                            <p class="titulo">Contraseña comprometida</p>
                            <p>{{ notificacion.password }}</p>
                          </div>
                          <div class="col-12">
                            <p class="titulo">Sitio</p>
                            <p>{{ notificacion.site }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="info-tips">
                    <div class="row">
                      <div class="col-md-4">
                        <p class="numero-tip">1</p>
                        <p>{{ sesion.textosDarkWeb[notificacion.alertType]?.paso1 }}</p>
                      </div>
                      <div class="col-md-4">
                        <p class="numero-tip">2</p>
                        <p>{{ sesion.textosDarkWeb[notificacion.alertType]?.paso2 }}</p>
                      </div>
                      <div class="col-md-4">
                        <p class="numero-tip">3</p>
                        <p>{{ sesion.textosDarkWeb[notificacion.alertType]?.paso3 }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--CAJA DE NOTIFICACIONES-->
    </div>
  </div>
</div>
<!-- Modal Agregar Informacion Dark Web  -->
<div class="modal fade hide" id="modal-dkw" tabindex="-1" role="dialog" aria-labelledby="modal-dkw-Label"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog claimsModal modal-dialog-centered" role="document">
    <div class="modal-content advert">
      <div class="modal-header">
        <p>Agregar {{textInfo}}</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModalDKW()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body advert">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <form #datosInfo="ngForm">
                <p>Nuevo {{textInfo}}</p>
                <input class="form-control" name="info1" [(ngModel)]="addInfoText" type="text" required
                  [pattern]="patternModal" #info1="ngModel"
                  [ngClass]="{'is-valid': info1.valid, 'is-invalid': ( ( info1.errors?.['required'] || info1.errors?.['pattern'] ) && info1.touched ) }" />
                <div *ngIf="info1?.errors?.['required']" class="invalid-feedback">
                  Campo obligatorio.
                </div>
                <div *ngIf="info1?.errors?.['pattern']" class="invalid-feedback">
                  {{erroPattern}}
                </div>
                <p>Confirmar {{textInfo}}</p>
                <input class="form-control" name="info2" [(ngModel)]="confirmInfoText" type="text" required
                  [pattern]="patternModal" #info2="ngModel"
                  [ngClass]="{'is-valid': info2.valid, 'is-invalid': ( ( info2.errors?.['required'] || info2.errors?.['pattern'] ) && info2.touched ) }" />
                <div *ngIf="info2?.errors?.['required']" class="invalid-feedback">
                  Campo obligatorio.
                </div>
                <div *ngIf="info2?.errors?.['pattern']" class="invalid-feedback">
                  {{erroPattern}}
                </div>
              </form>
              <button class="btn-midc btn-orange h-lg-btn w-100-btn" [disabled]="!(datosInfo.valid && (addInfoText == confirmInfoText))"
                (click)="actionModal()">
                <span>{{textButton}}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Alerta Intento Suplantacion  -->