
<!-- Plan Estandar -->
<div class="boxPlan" *ngIf="caja == 'boxPlan'">
  <div class="valorPlan">
    <p class="titulo">{{planeSel.nombre.toUpperCase()}}</p>
    <p class="precio">${{planeSel.valorMes}}</p>
    <p class="tiempo">/ Mes</p>
  </div>
  <div class="descripcionPlan">
    <button class="btnPlan btnMob" (click)="seleccionarPlan('plan')" id="{{planeSel.id}}" webActivity data-idtag="ComparadorPlanes" data-nametag="ofertaPlanes">
      Adquirir plan
      <i class="fa fa-chevron-right"></i>
    </button>
    <h3>
      {{planeSel.subNombre}}
    </h3>
    <div class="caracteristicasPlan" >
      <div *ngFor="let carac of planeSel.caracteristicas;" class="caracteristica">
          <i class="fa fa-check" aria-hidden="true"></i>
          <p class="regular">{{carac.txt}}</p>
      </div>
      
    </div>
    <button class="btnPlan btnNoMob" (click)="seleccionarPlan('plan')" id="{{planeSel.id}}" webActivity data-idtag="ComparadorPlanes" data-nametag="ofertaPlanes">
      Adquirir plan
      <i class="fa fa-chevron-right"></i>
    </button>
  </div>
</div>

<!-- Plan Mini Usado en Comparacion de planes -->
<div class="boxPlanMini" *ngIf="caja == 'boxPlanMini'">
  <div class="popular" *ngIf="planeSel.popular">
    <p>
      MÁS POPULAR
    </p>
  </div>
  <div *ngIf="!free" class="valorPlan">
    <p class="titulo">{{planeSel.nombre}}</p>
    <p class="precio"><b>${{planeSel.valorMes}}</b> /Mes</p>
    <p class="subTitulo">{{planeSel.mensaje}}</p>
  </div>
  <div *ngIf="free" class="valorPlan">
    <p class="precio"><b>ACCESO GRATUITO</b></p>
  </div>
  <button class="btnPlan" *ngIf="!free" (click)="seleccionarPlan('mini')" id="{{planeSel.id}}" webActivity data-idtag="ComparadorPlanes" data-nametag="ofertaPlanes">
    {{returnContent('plan')}}
    <i class="fa fa-chevron-right"></i>
  </button>
  <button class="btnFree" *ngIf="free" (click)="irLogin()" id="{{planeSel.id}}" webActivity data-idtag="ComparadorPlanes" data-nametag="ofertaPlanes">
    {{returnContent('free')}}
    <i class="fa fa-chevron-right"></i>
  </button>
</div>
<!-- Modal Planes Mini -->
<div class="modal p-0" tabindex="-1" role="dialog" id="{{'modalPlan'+planeSel.plan}}" *ngIf="!free">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content m-auto">
    <div class="modal-body p-0">
      <div class="container-fluid">
      <div class="row">
        <div class="col-12 text-right">
          <button class="btn btn-close" (click)="close('modalPlan'+planeSel.plan)">
            X
          </button>
        </div>
        <div class="col-12">
          <div class="headModal">
            <h2>{{planeSel.nombre}}</h2>
            <p>
              {{planeSel.mensajePop}}
            </p>
          </div>
          <div class="pagos">
            <div class="pago">
              <label class="container">Pago mensual
                <input type="radio" checked="checked" name="{{'modalPlan'+planeSel.plan}}" (click)="changePago('mensual')">
                <span class="checkmark"></span>
              </label>
              <p class="precio"><b>${{planeSel.planGene.mensual[1]}}</b> /Mes</p>
            </div>
            <div class="pago">
              <label class="container">Pago trimestral <label class="subText">Hasta un {{planeSel.planGene.trimestral[3]}}  Dto</label>
                <input type="radio" name="{{'modalPlan'+planeSel.plan}}" (click)="changePago('trimestral')">
                <span class="checkmark"></span>
              </label>
              <p class="precio"><b>${{planeSel.planGene.trimestral[1]}}</b> /Mes</p>
              <p class="subTitulo">{{planeSel.planGene.trimestral[2]}}</p>
            </div>
            <div class="pago">
              <label class="container">Pago anual <label class="subText">Hasta un {{planeSel.planGene.anual[3]}} Dto</label>
                <input type="radio" name="{{'modalPlan'+planeSel.plan}}" (click)="changePago('anual')">
                <span class="checkmark"></span>
              </label>
              <p class="precio"><b>${{planeSel.planGene.anual[1]}}</b> /Mes</p>
              <p class="subTitulo">{{planeSel.planGene.anual[2]}}</p>
            </div>
          </div>
          <button class="btnPlan" (click)="seleccionarPlan('planModal')" id="{{'plan'+tiempo+planeSel.plan+'M'}}" webActivity data-idtag="ComparadorPlanes" data-nametag="ofertaPlanes">
            Adquirir Plan
            <i class="fa fa-chevron-right"></i>
          </button>
        </div>
      </div>
      </div>
    </div>
    </div>
  </div>
  </div>

<!-- Modal Planes Mini -->