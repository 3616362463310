<div class="container-fluid px-0">
    <div class="cabecera">
        <h1 class="title d-none d-md-block">En Midatacrédito ¡Estamos para ti!</h1>
        <h1 class="title d-block d-md-none">En <b>Midatacrédito</b> ¡Estamos para ti!</h1>
        <h3 class="txt d-block d-md-none">¿En qué podemos ayudarte hoy ?</h3><br>
        <h3 class="txt">Escríbenos todas tus preguntas, quejas o reclamos, y recibirás la respuesta por parte de nuestros asesores en el menor tiempo posible.</h3>
        <hr>
    </div>
    <div class="fomulario">
        <form id="contactenosForm" name="contactenosForm" class=""
        role="form" (ngSubmit)="enviarContacto(datos)" #datos="ngForm" autocomplete="off">
        <div class="alert alert-danger" *ngIf="errorMessage">
            {{ errorMessage }}
        </div>
        <h4 class="titleForm">¿Tienes preguntas?</h4>
        <p class="txtF">Déjanos tus datos y tu pregunta para darte una respuesta personalizada.</p>
        <hr class="sepF">
        <div class="form-row">
            <div class="form-group col-md-6" id="nombrediv">
                <input class="form-control inputF" type="text" placeholder="Nombre Completo"
                    required="required" [(ngModel)]="sesion.contacto.fullName" #nombre="ngModel"
                    name="nombre" pattern="^[a-zA-Z ]+$"
                    [ngClass]="{'is-valid': nombre.valid, 'is-invalid': ( (nombre.errors?.['required'] || nombre.errors?.['pattern']) && nombre.touched ) }">
                <div *ngIf="nombre?.errors?.['required']" class="invalid-feedback">
                    Campo obligatorio.
                </div>
                <div *ngIf="nombre?.errors?.['pattern']" class="invalid-feedback">
                    Nombre inválido.
                </div>
            </div>
            <div class="form-group col-md-6" id="emaildiv">
                <input class="form-control inputF" type="email" placeholder="Correo electrónico"
                    required="required" [(ngModel)]="sesion.contacto.email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,9}$" name="email" #email="ngModel"
                    [ngClass]="{'is-valid': email.valid, 'is-invalid': ( ( email.errors?.['required'] || email.errors?.['pattern'] ) && email.touched ) }">
                <div *ngIf="email?.errors?.['required']" class="invalid-feedback">
                    Campo obligatorio.
                </div>
                <div *ngIf="email?.errors?.['pattern']" class="invalid-feedback">
                    Correo electrónico inválido.
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-md-6" id="tipoIddiv">
                <select name="documentType" required="required" #documento="ngModel"
                    class="form-control inputF" [(ngModel)]="sesion.contacto.documentType">
                    <option value="CC" class="inputF">Cédula de ciudadanía</option>
                    <option value="CE" class="inputF">Cédula de extranjería</option>
                </select>
                <i class="fa fa-chevron-down"></i>
            </div>
            <div class="form-group col-md-6" id="iddiv">
                <input type="text" class="form-control inputF" placeholder="Documento"
                    [(ngModel)]="sesion.contacto.documentNumber" #documentoN="ngModel"
                    required="required" name="documentoN" pattern="[0-9]+"
                    [ngClass]="{'is-valid': documentoN.valid, 'is-invalid': ( (documentoN.errors?.['required'] || documentoN.errors?.['pattern'] ) && documentoN.touched ) }">
                <div *ngIf="documentoN?.errors?.['required']" class="invalid-feedback">
                    Campo obligatorio.
                </div>
                <div *ngIf="documentoN?.errors?.['pattern']" class="invalid-feedback">
                    Documento no válido.
                </div>
            </div>
        </div>
        <div class="form-group" id="mensajeDiv">
            <textarea name="mensaje" class="form-control inputF inquietud" rows="6" placeholder="¿Cuál es tu inquietud?"
                required="required" [(ngModel)]="sesion.contacto.commentary" #comentary="ngModel"
                name="comentary"
                [ngClass]="{'is-valid': comentary.valid, 'is-invalid': ( comentary.errors?.['required'] && comentary.touched ) }">
        </textarea>
            <div *ngIf="comentary?.errors?.['required']" class="invalid-feedback">
                Campo obligatorio.
            </div>
        </div>
        <div class="form-group">
            <div class="check-terms">
                <input type="checkbox" [(ngModel)]="terms" name="termsContact" #termsConcat="ngModel"/>
                <p>
                    Al seleccionar este checkbox autorizo a EXPERIAN COLOMBIA S.A. el tratamiento de
                    mis datos personales para que sean utilizados por Experian Midatacrédito de
                    acuerdo con esta autorización de <a (click)="showTerms()"><u>tratamiento de
                    datos personales.</u>  <i class="fa fa-angle-down"></i></a>
                </p>
                <p class="text-ad">
                    Al seleccionar este checkbox a EXPERIAN COLOMBIA S.A., identificada con
                    NIT.900.422.614-8 para que, en su calidad de responsable del tratamiento,
                    recopile y en general realice el tratamiento de la información personal
                    solicitada en este formulario con el fin de (i) Almacenarlo en su base de datos;
                    (ii) adelantar las gestiones necesarias para dar respuesta a la consulta o
                    reclamo incluido este formulario; (iii) Verificar mi identidad; (iV) Atende
                    requerimientos de autoridades en ejercicio de sus funciones; (v) consérvala de
                    forma indefinida para fines estadísticos, históricos y/o para cumplir con
                    obligaciones legales respecto o conservación de información; y (vi) compartirla
                    con terceros Encargados del Tratamiento para dar cumplimiento a las finalidades
                    previamente mencionadas. <br/>Declaro conocer que como titular de la información
                    conozco que tengo derecho a conocer, actualizar o rectificar mis datos
                    personales, presentar quejas ante la Superintendencia de Industria y Comercio,
                    suprimir o eliminar mis datos de las bases de datos de EXPERIAN COLOMBIA, lo
                    cual solo precederá en caso de que no se tenga una obligación legal o
                    contractual para mantenerla, así como solicitar prueba de la autorización
                    otorgada o revocarla, contactándome por medio de este mismo canal de contacto,
                    enviando comunicación a la carrera 7 # 76 – 35 o en el centro de Experiencia
                    ubicado en la Avenida Américas # 62-84 Piso 2 Local 71-72, así como en el correo
                    electrónico servicioalciudadano@experian.com, y que podre obtener mayor
                    información sobre el tratamiento de mi información personal accediendo a la
                    política de tratamiento de Datos personales de EXPERIAN COLOMBIA en el siguiente
                    <a href="https://images.ctfassets.net/i39eu1rqojum/MU9StmDXE9wAF1YlhIf36/0488615f36c7bc189b2e1c46b6daf967/Politica_tratamiento_de_datos_Canal_Reclamos_-_CAS_-_Peticiones_.pdf" target="_blank">link</a>
                </p>
            </div>
        </div>
        <button [disabled]="disabledBtn(datos)"
            [ngClass]="{'disabled': !datos.valid }" type="submit"
            class="btn btnF">
            Enviar
            <i class="fa fa-chevron-right float-right mt-arrow"></i>
        </button>
    </form>
    </div>
</div>