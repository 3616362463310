import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { environment } from '../../../environments/environment';
import { SesionService } from '../../core/services/sesion.service';
import { Observable, forkJoin } from 'rxjs';
import { NgForm } from '@angular/forms';
import { BrokerService } from 'src/app/core/services/broker.service';
import { ModalService } from 'src/app/core/services/modal.service';

declare var $: any;
declare var payU: any;

interface DataEmit{
  flag:NgForm;
  tValid:boolean;
}

interface TarjetaCredito {
  action: any;
  session: any;
  params: {
    transaction: {
      order: {
        referenceCode: any;
        description: any;
        buyer: {
          shippingAddress: {
            street1: any;
            street2: any;
            city: any;
            state: any;
            country: any;
            postalCode: any;
            phone: any;
          }
        }
      },
      payer: {
        merchantPayerId: any;
        fullName: any;
        firstName: any;
        lastName: any;
        dniNumber: any;
        billingAddress: {
          street1: any;
          street2: any;
          city: any;
          state: any;
          country: any;
          postalCode: any;
          phone: any;
        }
      },
      creditCardTokenId: any;
      extraParameters: {
        INSTALLMENTS_NUMBER: any;
      },
      paymentMethod: any;
      paymentCountry: any;
      deviceSessionId: any;
      ipAddress: any;
      cookie: any;
      userAgent: any;
    },
    test: any;
    giftcardCode: any;
    planCode: any;
    recurrency: any;
  };
}


@Component({
  selector: 'app-tc',
  templateUrl: './tc.component.html',
  styleUrls: ['./tc.component.scss']
})
export class TcComponent implements OnInit {
  datos!: NgForm
  card: any;
  tarjetaCredito: TarjetaCredito;
  dataEmit!: DataEmit;
  tarjetaValida = true;
  errorPayU:boolean=false;
  ciudades:any = [];
  screenWidth:any;
  exp:string="";
  filterC:string="";
  @Output() inactiveTab = new EventEmitter<void>();
  @Output() sentActiveBtnPago = new EventEmitter<any>();
  @Output() sentSaveAdvertising = new EventEmitter<any>();

  constructor(
    public sesion: SesionService, 
    public broker: BrokerService,
    public modal: ModalService) {  
    this.tarjetaCredito = {
      action: 'TC',
      session: sesion.cookieValue,
      params: {
        transaction: {
          order: {
            description: null,
            referenceCode: this.sesion.getCookieServices('UTMC'),
            buyer: {
              shippingAddress: {
                street1: null,
                street2: null,
                city: null,
                state: null,
                country: null,
                postalCode: null,
                phone: sesion.user.phone
              }
            }
          },
          payer: {
            merchantPayerId: '1',
            fullName: '',
            firstName: '',
            lastName: '',
            dniNumber: '',
            billingAddress: {
              street1: null,
              street2: null,
              city: null,
              state: null,
              country: 'CO',
              postalCode: null,
              phone: '',
            }
          },
          creditCardTokenId: null,
          extraParameters: {
            INSTALLMENTS_NUMBER: 1
          },
          paymentMethod: 'TC',
          paymentCountry: 'CO',
          deviceSessionId: null,
          ipAddress: null,
          cookie: null,
          userAgent: navigator.userAgent
        },
        test: environment.payULatam.TestPayU,
        giftcardCode: null,
        planCode: this.sesion.planId,
        recurrency: 1
      }
    };
  
    this.lazyLoadQuill().subscribe(_ => {
      this.loadParameters();
    });

    // Listado países
    this.sesion.obtener_paises();
    this.sesion.obtener_ciudades();

    this.card =  {
      number: null,
      name_card: null,
      payer_id: null,
      exp_month: null,
      exp_year: null,
      method: null,
      cvv: null
    };
  }
  generateArrayOfYears() {
    var max = new Date().getFullYear()
    var min = max + 10
    var years = []
    for (var i = max; i <= min; i++) {
      years.push(i)
    }
    return years
  }

  generateArrayOfMonths() {
    var month = []
    for (var i = 1; i < 13; i++) {
      month.push(i)
    }
    return month
  }

  @HostListener('window:resize', ['$event'])
    getScreenSize() {
          this.screenWidth = window.innerWidth;
  }
  ngOnInit() {  
    $(document).ready(function() {
      $("form").keypress((e:any) => {
        if (e.which == 13) {
            return false;
        }
        return true;
      });
    });
    setTimeout(() => { 
      this.ciudades=this.sesion.ciudades
    }, 3000);
    
  }
  validateCard(){
    const model = this;
    const value = $('#cardnumber').val().trim();
    payU.validateCard(value);
    const cardType = model.getCardType(value);
    if (!cardType) {
      model.card.method = null;
      model.tarjetaValida = false;
    } else {
      model.tarjetaCredito.params.transaction.paymentMethod = cardType;
      model.card.method = cardType;
      model.tarjetaValida = true;
    }
  }
  getCardType(cardNo:any) {
    const cards:any = {
      AMERICANEXPRESS: /^3[47][0-9]{13}$/,
      MASTERCARD: /^5[1-5][0-9]{14}$/,
      VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
      DINERSCLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/
    };

    for (const card in cards) {
      if (cards[card].test(cardNo)) {
        return card;
      }
    }

    return undefined;
  }


  createToken() {
    payU.setCardDetails(this.card);
    payU.createToken(this.responseHandler);
  }

  responseHandler(response:any) {
    if (response.error) {
      $('#creditCardTokenId').val(null);
      // Se muestra los mensajes de error.
      $('.create-errors').text(response.error);
      $('#setSpinner').click();
    } else {
      // Se obtiene el token y se puede guardar o enviarlo para algún pago.
      const token = response.token;
      const payer_id = response.payer_id;
      const document = response.document;
      const name = response.name;

      const input = $('#creditCardTokenId');
      input.val(response.token);

      $('#validar').click();
    }
  }

  realizarPago( datos: NgForm ) {  
    if ( this.sesion.stateEvidente === 2 ) {
      window.location.replace(environment.enlaceEvidente);
    } else {
      if ( datos.valid ) {
        this.sesion.spinnerDebt = true;
        $("#validar").hide();
        this.tarjetaCredito.params.transaction.creditCardTokenId = $('#creditCardTokenId').val();
        this.card.name_card = this.tarjetaCredito.params.transaction.payer.firstName + ' ' + this.tarjetaCredito.params.transaction.payer.lastName;
        this.card.payer_id = this.tarjetaCredito.params.transaction.payer.dniNumber;
        let date = this.exp.split("/")
        this.card.exp_month = date[0].replace(/^0+/, '');
        this.card.exp_year = date[1];
        if ( !this.tarjetaCredito.params.transaction.creditCardTokenId ) {
          this.createToken();
        } else {
          let name = this.tarjetaCredito.params.transaction.payer.fullName.split(" ")
          this.tarjetaCredito.params.transaction.payer.firstName = name[0];
          this.tarjetaCredito.params.transaction.payer.lastName = name[1];
          // tslint:disable-next-line: max-line-length
          this.tarjetaCredito.params.transaction.order.buyer.shippingAddress.street1 =  this.tarjetaCredito.params.transaction.payer.billingAddress.street1;
          // tslint:disable-next-line: max-line-length
          this.tarjetaCredito.params.transaction.order.buyer.shippingAddress.street2 =  this.tarjetaCredito.params.transaction.payer.billingAddress.street2;
          // tslint:disable-next-line: max-line-length
          this.tarjetaCredito.params.transaction.order.buyer.shippingAddress.city =  this.tarjetaCredito.params.transaction.payer.billingAddress.city;
          // tslint:disable-next-line: max-line-length
          this.tarjetaCredito.params.transaction.order.buyer.shippingAddress.state =  this.tarjetaCredito.params.transaction.payer.billingAddress.state;
          // tslint:disable-next-line: max-line-length
          this.tarjetaCredito.params.transaction.order.buyer.shippingAddress.country =  this.tarjetaCredito.params.transaction.payer.billingAddress.country;
          // tslint:disable-next-line: max-line-length
          this.tarjetaCredito.params.transaction.order.buyer.shippingAddress.postalCode =  this.tarjetaCredito.params.transaction.payer.billingAddress.postalCode;
          // tslint:disable-next-line: max-line-length
          this.tarjetaCredito.params.transaction.order.buyer.shippingAddress.phone =  this.tarjetaCredito.params.transaction.payer.billingAddress.phone;
          this.tarjetaCredito.params.planCode = this.sesion.planId;

          // Description
          // tslint:disable-next-line: max-line-length
          this.tarjetaCredito.params.transaction.order.description = this.sesion.plan.popup + ' - Tipo: ' + this.sesion.tipoPlan + ' - Promocode: ' + this.sesion.descuentoData.params.promoCode;
          // Promocode
          // tslint:disable-next-line: max-line-length
          this.sesion.descuento === 'OK' ? this.tarjetaCredito.params.giftcardCode = this.sesion.descuentoData.params.promoCode.toUpperCase() : this.tarjetaCredito.params.giftcardCode = null;
          // if (this.tarjetaCredito.params.recurrency) {
          //   this.tarjetaCredito.params.recurrency = 1;
          // } else {
          //   this.tarjetaCredito.params.recurrency = 0;
          // }
          // if(this.sesion.tipoPlan === '1'){
          //   this.tarjetaCredito.params.recurrency = 1;
          // } else {
          //   if(this.tarjetaCredito.params.recurrency == 1) {
          //     this.tarjetaCredito.params.recurrency = 1;
          //   } else {
          //     this.tarjetaCredito.params.recurrency = 0;
          //   }            
          // }
          this.sesion.pagos( this.tarjetaCredito, 1 );
          this.setUtmPago();
          this.customer();
          this.cart();
          //CHECK PUBLICIDAD          
          this.sentSaveAdvertising.emit()  
        }
      }
    }    
  }

  back() {
    window.history.back();
  }   

  customer() {
    const MD5 = require("crypto-js/md5");

    const cDate = new Date(); 
    const email = this.sesion.user.correo;
    const customerId = MD5(email).toString(); //Sugiero usar uno id basado en el email y timestamp 
    const first_name = this.tarjetaCredito.params.transaction.payer.firstName;
    const last_name = this.tarjetaCredito.params.transaction.payer.lastName; 
    const phone = this.tarjetaCredito.params.transaction.payer.billingAddress.phone;

    const Data = { 
      "accepts_marketing": true, 
      "created_at": cDate, 
      "email": email, 
      "first_name": first_name, 
      "last_name": last_name, 
      "id": customerId, 
      "updated_at": cDate, 
      "phone" : phone
    }
    this.broker.request_remarkety(Data, 'customers/create').subscribe(result => {
      },
      error => {}
    );
  }

  cart() {
    const MD5 = require("crypto-js/md5");
    
    const cDate = new Date(); 
    const email = this.sesion.user.correo;
    const product_id = this.sesion.planIdCart();
    const total_price = this.sesion.valorPlanTotal.replace('.',''); 
    const cDateHead = new Date(cDate.getUTCFullYear(),0,1);
    const cartId = (parseInt(((cDate.getTime()-cDateHead.getTime())/(1000)).toString())* 10) + parseInt(((cDate.getTime()-cDateHead.getTime())%(100)).toString()); 
    const customerId = MD5(email).toString(); //Sugiero usar uno id basado en el email 
    const quantity = 1 ; //Siempre tendremos un solo producto por carrito 
    const product_title = this.sesion.plan.popup;
    const product_price = this.sesion.valorPlanTotal.replace('.','');
    const Data = { 
      "abandoned_checkout_url": "", 
      "accepts_marketing": true, 
      "created_at": cDate, 
      "currency": "COP", 
      "customer": { 
        "accepts_marketing": true, 
        "email": email, 
        "id" : customerId 
      }, 
      "email": email , 
      "id": cartId, 
      "line_items": [{ 
        "product_id": product_id,
        "title":product_title,
        "price":product_price,
        "quantity": quantity, 
      }], 
      "total_discounts": 0, 
      "total_price": total_price, 
      "updated_at": cDate 
    }; 
    this.broker.request_remarkety(Data, 'carts/create').subscribe(result => {
      },
      error => {}
    );
  }

  inactiveTabF(){
    this.inactiveTab.emit();
  }

  validarData(flag:NgForm){
    this.dataEmit = {
      flag:flag,
      tValid:this.tarjetaValida
    }
      this.sentActiveBtnPago.emit(this.dataEmit)
  }

  pagarParent(e:NgForm) 
  { 
    this.realizarPago(e);
  }
  focus(ele:any){
    if(this.screenWidth < 720){

      $('html, body').animate({
        scrollTop: $('#' + ele.srcElement.id).offset().top - 30
      }, 500);
    }
  }

  lazyLoadQuill(): Observable<any> {
    return forkJoin([
      this.sesion.loadScript('https://gateway.payulatam.com/ppp-web-gateway/javascript/PayU.js')
    ]);
  }
  loadParameters(){
    
    this.broker.loadParameters('PAY_U').subscribe(
      {
      next: (data: any) => {
        payU.setURL(this.sesion.getVariables(data.parameters.UrlWebServicePagos,this.sesion.validateOTPU.slice(4, -8)));
        payU.setPublicKey(this.sesion.getVariables(data.parameters.PublicKey,this.sesion.validateOTPU.slice(4, -8)));
        payU.setAccountID(this.sesion.getVariables(data.parameters.AccountId,this.sesion.validateOTPU.slice(4, -8)));
        payU.setListBoxID('mdc-list-cards');
        payU.setLanguage('es');
        payU.getPaymentMethods();     
      },
      error: (error) => {
        this.errorPayU=true;
      }
      }
      
    );
  }
  filter() {
    const filterValue = this.filterC.toLowerCase();
    this.filterC != undefined?this.ciudades = this.sesion.ciudades.filter((option:any) => option.NAME_MUN.toLowerCase().startsWith(filterValue)):this.ciudades=this.sesion.ciudades;
  }
  resetCiudades(){
    if(this.ciudades.length == 0 || this.ciudades == undefined){
      this.ciudades=this.sesion.ciudades
    }
  }
  // setExpDate(date:any, picker: MatDatepicker<Date>){
  //   let newDate = new Date(date).toLocaleDateString("en-GB");
  //   let sepDate = newDate.split("/");
  //   console.log(sepDate)
  //   this.card.exp_year = date.toDateString().split(' ').slice(1).join(' ')
  //   console.log(this.card.exp_year)
  //   picker.close();
  // }

  setExpDate2(event:any){
    let newValue:string;

    if(this.exp.length == 3 && !this.exp.includes('/') && event.keyCode != 8){
       newValue =this.exp.slice(0, 2) + "/" + this.exp.slice(2);
       this.exp = newValue;
    }
  }
  setSpinner(set:boolean){
    if(this.sesion.spinnerDebt){
      this.sesion.spinnerDebt = set;
    }
  }
  async setUtmPago(){
    let params = JSON.parse(sessionStorage.getItem('utmParams') as string);
      let info = {
        sessionId: this.sesion.cookieValue,
        eventType:'purchase intent',
        paymentMethod:'tc',
        eventDate: Date.now(),
        utm_source: "",
        utm_medium: "",
        utm_campaign: ""
      }
      if(params != null){
        if(params.utm_source){info.utm_source=params.utm_source};
        if(params.utm_medium){info.utm_medium=params.utm_medium};
        if(params.utm_campaign){info.utm_campaign=params.utm_campaign};
      }
      await this.broker.setUtmPago(info).subscribe();
  }
}