<div class="error pt-5">
  <div class="container py-5 mt-5">
      <div class="row">
        <div class="col-md-10 col-xs-12 mx-auto mt-5">
            <div class="row">
              <div class="col-xs col-md-5 p-0">
                  <img class="w-100" src="assets/img/images/error.svg" alt="error">
              </div>
              <div class="col-xs col-md-7">
                  <h3 class="mdc-text-2 mdc-text-secondary">Lo sentimos</h3>
                  <strong class="mdc-text-3">Algo salió mal</strong>
                  <p class="mt-3 mdc-text-4">Algunos de nuestros servicios no están funcionando actualmente.</p>
                  <p>Puedes actualizar la página o volver a intentarlo más tarde.</p>
                  <div class="row">
                    <div class="col-lg-4 col-12">
                        <button class="btn mdc-btn-primary btn-block" (click)="routerLink()">
                          Ir al inicio <i class="fa fa-chevron-right"></i>
                        </button>
                    </div>  
                  </div>
              </div>
            </div>
        </div>          
      </div>
  </div>
</div>