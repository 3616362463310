import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import localeEs from '@angular/common/locales/es';
import { CommonModule, CurrencyPipe, registerLocaleData } from '@angular/common';

// Imports
import { HttpClientModule } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';

import {MatNativeDateModule, MAT_DATE_LOCALE} from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { NgxCurrencyModule } from 'ngx-currency';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CanActiveViaAuthGuard } from './core/services/auth-guard.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { MatRadioModule } from '@angular/material/radio';
// import { ChartsModule } from 'ng2-charts';
import { RecaptchaModule, RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';


// Providers
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { InicioComponent } from './pages/inicio/inicio.component';
import { PublicidadProductosComponent } from './components/publicidad-productos/publicidad-productos.component';
import { EscaneoComponent } from './components/escaneo/escaneo.component';
import { HeaderComponent } from './components/header/header.component';
import { Historicov2Component } from './pages/historicov2/historicov2.component';
import { MenuComponent } from './components/menu/menu.component';
import { MisReclamacionesComponent } from './components/mis-reclamaciones/mis-reclamaciones.component';
import { TarjetaPlanComponent } from './components/tarjeta-plan/tarjeta-plan.component';
import { ErrorComponent } from './pages/error/error.component';
import { MainComponent } from './pages/main/main.component';
import { CrearAlertaComponent } from './pages/crear-alerta/crear-alerta.component';
import { ModalComponent } from './components/modal/modal.component';
import { HuellasComponent } from './pages/huellas/huellas.component';
import { ComparacionPlanesComponent } from './pages/comparacion-planes/comparacion-planes.component';
import { ServicioClienteComponent } from './pages/servicio-cliente/servicio-cliente.component';
import { PoliticasPrivacidadComponent } from './pages/politicas-privacidad/politicas-privacidad.component';
import { TerminosCondicionesComponent } from './pages/terminos-condiciones/terminos-condiciones.component';
import { ClausulaPolizaComponent } from './pages/clausula-poliza/clausula-poliza.component';
import { LoadingComponent } from './components/loading/loading.component';
import { PagoComponent } from './pages/pago/pago.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { GcComponent } from './components/gc/gc.component';
import { PseComponent } from './components/pse/pse.component';
import { TcComponent } from './components/tc/tc.component';
import { DetalleCuentaComponent } from './pages/detalle-cuenta/detalle-cuenta.component';
import { ReclamacionComponent } from './pages/reclamacion/reclamacion.component';
import { ConfirmacionPagoComponent } from './pages/confirmacion-pago/confirmacion-pago.component';
import { PerfilCreditoComponent } from './pages/landings/perfil-credito/perfil-credito.component';
import { ProtegeComponent } from './pages/landings/protege/protege.component';
import { PremiumComponent } from './pages/landings/premium/premium.component';
import { FaqComponent } from './pages/faq/faq.component';
import { ContactanosComponent } from './pages/contactanos/contactanos.component';
import { WebcheckoutComponent } from './pages/webcheckout/webcheckout.component';
import { WebcheckoutConfirmComponent } from './pages/webcheckout-confirm/webcheckout-confirm.component';
import { MiplanComponent } from './pages/miplan/miplan.component';
import { MetodoPagoComponent } from './components/metodo-pago/metodo-pago.component';
import { PseChangeComponent } from './components/pse-change/pse-change.component';
import { TcChangeComponent } from './components/tc-change/tc-change.component';
import { ScoreComponent } from './components/score/score.component';
import { DiagnosticoComponent } from './pages/diagnostico/diagnostico.component';
import { MicroLoaderComponent } from './components/micro-loader/micro-loader.component';
import { PolizaPremiumComponent } from './pages/poliza-premium/poliza-premium.component';
import { PresenciaInternetComponent } from './pages/presencia-internet/presencia-internet.component';
import { NotificacionComponent } from './pages/notificacion/notificacion.component';
import { ProximoCreditoComponent } from './pages/proximo-credito/proximo-credito.component';
import { MiDeudaComponent } from './pages/mi-deuda/mi-deuda.component';
import { EndeudamientoComponent } from './components/endeudamiento/endeudamiento.component';
import { PlanesComponent } from './pages/landings/planes/planes.component';
import { PlanUpsellingComponent } from './components/plan-upselling/plan-upselling.component';
import { SuscripcionComponent } from './components/suscripcion/suscripcion.component';
import { PlanesDinamicosComponent } from './pages/landings/planes-dinamicos/planes-dinamicos.component';
import { BlogComponent } from './pages/blog/blog.component';
import { NotificationsFreeComponent } from './pages/notification-free/notifications-free.component';
import { DiagnosticoPuntajeComponent } from './pages/landings/diagnostico-puntaje/diagnostico-puntaje.component';
import { DeudaSaludableComponent } from './pages/landings/deuda-saludable/deuda-saludable.component';
import { FooterComponent } from './components/footer/footer.component';
import { NgChartsModule } from 'ng2-charts';
import { ChatDatinaComponent } from './components/chat-datina/chat-datina.component';
import { HistoricoScoreComponent } from './pages/historico-score/historico-score.component';
import { BannersComponent } from './components/banners/banners.component';
import { ListaBcComponent } from './pages/lista-bc/lista-bc.component';
import { SimuladorComponent } from './pages/simulador/simulador.component';
import { AppPoliticaPrivacidadComponent } from './pages/landings/app-politica-privacidad/app-politica-privacidad.component';
import { Historicov3Component } from './pages/historicov3/historicov3.component';
import { DashScoreComponent } from './components/dashboard/dash-score/dash-score.component';
import { DashHuellasComponent } from './components/dashboard/dash-huellas/dash-huellas.component';
import { DashHistoryComponent } from './components/dashboard/dash-history/dash-history.component';
import { DashNotificationComponent } from './components/dashboard/dash-notification/dash-notification.component';
import { DashPayOptionComponent } from './components/dashboard/dash-pay-option/dash-pay-option.component';
import { DashPadModuleComponent } from './components/dashboard/dash-pad-module/dash-pad-module.component';
import { BoxDebtComponent } from './components/dashboard/dash-pad-module/box-debt/box-debt.component';
import { DashBcModuleComponent } from './components/dashboard/dash-bc-module/dash-bc-module.component';
import { BoxOfferComponent } from './components/dashboard/dash-bc-module/box-offer/box-offer.component';
import { DashBannerGoogleComponent } from './components/dashboard/dash-banner-google/dash-banner-google.component';
import { FeriaComponent } from './pages/landings/feria/feria.component';
import { PadAppComponent } from './pages/landings/pad-app/pad-app.component';
import { AutorizacionComponent } from './pages/autorizacion/autorizacion.component';
import { AutorizacionOpcionalComponent } from './pages/autorizacion-opcional/autorizacion-opcional.component';
import { PadComponent } from './pages/landings/pad/pad.component';
import { HomeComponent } from './pages/home/home.component';
import { SponsorsComponent } from './components/home/sponsors/sponsors.component';
import { TestimonialsComponent } from './components/home/testimonials/testimonials.component';
import { ViewPostsComponent } from './components/home/view-posts/view-posts.component';
import { InfoProductComponent } from './components/home/info-product/info-product.component';
import { FraudeComponent } from './pages/landings/fraude/fraude.component';


registerLocaleData(localeEs, 'es');
//landings 2020


@NgModule({
  declarations: [
    AppComponent,
    CapitalizePipe,
    InicioComponent,
    PublicidadProductosComponent,
    EscaneoComponent,
    HeaderComponent,
    Historicov2Component,
    MenuComponent,
    MisReclamacionesComponent,
    TarjetaPlanComponent,
    ErrorComponent,
    MainComponent,
    CrearAlertaComponent,
    ModalComponent,
    HuellasComponent,
    ComparacionPlanesComponent,
    ServicioClienteComponent,
    PoliticasPrivacidadComponent,
    TerminosCondicionesComponent,
    ClausulaPolizaComponent,
    LoadingComponent,
    PagoComponent,
    SidebarComponent,
    GcComponent,
    PseComponent,
    TcComponent,
    DetalleCuentaComponent,
    ReclamacionComponent,
    ConfirmacionPagoComponent,
    PerfilCreditoComponent,
    ProtegeComponent,
    PremiumComponent,
    FaqComponent,
    ContactanosComponent,
    WebcheckoutComponent,
    WebcheckoutConfirmComponent,
    MiplanComponent,
    MetodoPagoComponent,
    PseChangeComponent,
    TcChangeComponent,
    ScoreComponent,
    DiagnosticoComponent,
    MicroLoaderComponent,
    PolizaPremiumComponent,
    PresenciaInternetComponent,
    NotificacionComponent,
    ProximoCreditoComponent,
    MiDeudaComponent,
    EndeudamientoComponent,
    PlanesComponent,
    PlanUpsellingComponent,
    SuscripcionComponent,
    PlanesDinamicosComponent,
    BlogComponent,
    NotificationsFreeComponent,
    DiagnosticoPuntajeComponent,
    DeudaSaludableComponent,
    FooterComponent,
    ChatDatinaComponent,
    HistoricoScoreComponent,
    BannersComponent,
    ListaBcComponent,
    SimuladorComponent,
    AppPoliticaPrivacidadComponent,
    Historicov3Component,
    DashScoreComponent,
    DashHuellasComponent,
    DashHistoryComponent,
    DashNotificationComponent,
    DashPayOptionComponent,
    DashPadModuleComponent,
    BoxDebtComponent,
    DashBcModuleComponent,
    BoxOfferComponent,
    DashBannerGoogleComponent,
    FeriaComponent,
    PadAppComponent,
    AutorizacionComponent,
    AutorizacionOpcionalComponent,
    PadComponent,
    HomeComponent,
    SponsorsComponent,
    TestimonialsComponent,
    ViewPostsComponent,
    InfoProductComponent,
    FraudeComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgChartsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    RecaptchaModule,
    RecaptchaV3Module,
    BrowserAnimationsModule
  ],
  providers: [
    CookieService,
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Lf-c0gaAAAAAOMDQJ9e35rrwTb8FeQ0t3rl3rTn' },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  entryComponents: [
    CurrencyPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
