import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { MetadatosService } from 'src/app/core/services/metadatos.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { BrokerService } from 'src/app/core/services/broker.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
public faqs: any[]= [];
  // public faqs = [
  //   {
  //     "id":"1","grupo": "¿Cómo funciona Midatacrédito?","img-blanco":"icono_como_funciona_blanco.svg","img-morado":"icono_como_funciona_morado.svg", "textoalt":"¿Cómo funciona midatacrédito?" ,"preguntas": [
  //       {
  //         "pregunta" : "¿Qué es Midatacrédito?", "respuesta" : `
  //         <p>Midatacrédito es la manera fácil de controlar tu información crediticia para mejorar tus posibilidades financieras y protegerte contra el fraude por suplantación.</p>
  //         <p>Nuestros suscriptores podrán contar con los siguientes servicios:</p>
  //         <ul>
  //         <li>Consulta ilimitada de su Historia de Crédito.</li>
  //         <li>Análisis de su Perfil de Crédito y los factores que lo impactan positiva y negativamente.</li>
  //         <li>Conocer tu Score o Puntaje de Crédito.</li>
  //         <li>Consejos personalizados sobre cómo mejorar su perfil.</li>
  //         <li>Póliza de Seguro y asesoría jurídica en casos de fraude por suplantación de identidad*.</li>
  //         <li>Alertas preventivas vía e-mail y celular, en caso de consultas sobre su historia de crédito, apertura de nuevas cuentas a su nombre y cualquier cambio que impacte su historia de crédito como saldos en mora, inactivación de alertas, respuesta a reclamos, entre otros.</li>
  //         <li>Servicio en línea para alertar al sistema financiero sobre situaciones que puedan poner en riesgo su identidad.</li>
  //         </ul>
  //         <p>Midatacrédito se puede utilizar 24 horas al día y desde cualquier computador con acceso a Internet.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Qué beneficios tengo al ser usuario de Midatacrédito?", "respuesta" : `
  //         <p>Tendrás mayor seguridad y certeza sobre tu huella en el sistema financiero ya que podrás ver lo que está ocurriendo con tus movimientos bancarios y crediticios, tal cual lo reportan y lo ven las mismas entidades y empresas.</p>
  //         <p>Evitarás que te suplanten y podrás mantener plena confianza sobre tu dinámica crediticia. De esta manera al tramitar una solicitud de crédito te evitarás sorpresas o inconvenientes que podrían dilatar tus propósitos perdiendo tiempo o realizando trámites innecesarios.</p>
  //         <p>Te será mucho más fácil el reportar inexactitudes en tus reportes y tendrás la oportunidad de programar las alertas y notificaciones que desees (relacionadas con tu buen nombre crediticio) para que te lleguen a tu celular y de esta manera puedas monitorear desde cualquier lugar tus consolidados bancarios.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Ventajas y razones para adquirir Midatacrédito?", "respuesta" : `
  //         <p>Midatacrédito es un aliado para administrar, analizar y proteger de manera fácil, sencilla y rápida toda la información relacionada con tus finanzas personales.</p>
  //         <p>Por medio de Midatacrédito podrás:</p>
  //         <ul>
  //         <li>Monitorear tus finanzas.</li>
  //         <li>Conocer tu Score y tu historia de crédito.</li>
  //         <li>Ver cómo reflejan tus hábitos de pago las entidades bancarias, empresas de telecomunicaciones, cooperativas, aseguradoras y administradoras de cesantías y pensiones, entre otras.</li>
  //         <li>Optimizar el manejo de los diferentes créditos y de paso el manejo de las finanzas personales.</li>
  //         <li>Recibir alertas en tiempo real sobre cambios en tu información y alertar a las entidades en caso de ser susceptible a fraudes por suplantación.</li>
  //         <li>Realizar una simulación de crédito.</li>
  //         <li>Tramitar reclamaciones ante las empresas y entidades financieras.</li>
  //         <li>Consultar sobre el estado de reclamaciones, estado de cuentas, ahorro, cuentas corrientes, tarjetas de crédito, préstamos rotativos y múltiples líneas de financiación.</li>
  //         </ul>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Cómo consultar tu historia de crédito?", "respuesta" : `
  //         <p>La Historia de Crédito es tu carta de presentación ante cualquier entidad crediticia, por eso es importante consultarla por lo menos una vez al mes y siempre antes de solicitar un crédito. Así puedes controlar que efectivamente la información allí contenida esté correcta, o si encuentras alguna obligación o cuenta en mora, puedes tratar de normalizar rápidamente tu situación. Igualmente, si encuentras algún error en tu Historia de Crédito podrás realizar las solicitudes respectivas a tiempo.</p>
  //         <p>A través de tu suscripción puedes tener acceso en línea a tu historia de crédito en Datacrédito y de manera permanente te notificaremos de cualquier cambio que se produzca, así como también, en caso de ser necesario, puedes alertar al sistema financiero de cambios inusuales, no autorizados o intentos de fraude con tu información personal.</p>
  //         <p>La Historia de Crédito es apenas una de las diferentes herramientas a las que también tendrás acceso en Midatacrédito, para ayudarte a administrar tu vida financiera.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Es lo mismo ser titular o fiador?", "respuesta" : `
  //         <p>Sí. Al momento de firmar un documento de compromiso de pago de un crédito tanto el tomador directo de la financiación como quien le sirve de codeudor adquieren la misma responsabilidad ante la correspondiente entidad financiera.</p>
  //         <p>Por ello, en caso de que el titular incumpla, el fiador queda obligado a responder por el pago de las cuotas, intereses y multas asociadas en igualdad de condiciones. Y en caso de que no se cumplan estos compromisos en los términos acordados, el titular y el fiador pueden ser reportados como morosos.</p>
  //         <p>En caso de existir más de un codeudor la entidad que le haya financiado a ese titular la adquisición de un bien o servicio podrá dirigirse contra todos los deudores solidarios conjuntamente o contra uno en particular a su arbitrio.</p>
  //         <p>Ten en cuenta que las entidades financieras comunican quién es el deudor principal de una obligación y quiénes son sus eventuales codeudores, de manera tal que así quedan registrados en sus respectivos historiales crediticios.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Qué es simulador de puntaje de crédito?", "respuesta" : `
  //         <p>El simulador de puntaje de crédito es una herramienta incluida en los planes de suscripción que te brinda Midatacrédito en la cual puedes visualizar hasta tres diferentes opciones de accionables que podrían impactar tu puntaje de crédito; así mismo, podrás ver el impacto de cada opción en una franja de tiempo de un mes y tres meses y compararlo con tu puntaje actual.</p>
  //         <p>Está herramienta está disponible en Midatacrédito.com y se puede utilizar 24 horas al día y desde cualquier computador o dispositivo móvil con acceso a Internet.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Cómo funciona el simulador de puntaje de crédito?", "respuesta" : `
  //         <p>Debes elegir alguna de las opciones de simulador y hacer clic en simular puntaje, ahí podrás ver el impacto en tu puntaje de crédito de acuerdo con los rangos en los que se mueve el puntaje de crédito.</p>
  //         <p>El simulador te dará un estimado de dónde podría llegar a quedar tu puntaje de crédito, si tomas las acciones allí recomendadas y se cumplen los parámetros establecidos en la ley sobre la permanencia de los reportes negativos en la historia de crédito.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿La información del simulador es real?", "respuesta" : `
  //         <p>Sí, el simulador de puntaje está construido con tus características de hábito de pago y basado en tu historia de crédito. Así mismo, se proyecta en posibles escenarios de comportamiento tuyos y del cumplimiento de la ley. </p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Qué debo hacer para que mi puntaje suba?", "respuesta" : `
  //         <p>Mejorar tu score es un proceso que puede tomar algunos meses mientras ajustas tu perfil crediticio, dado que tanto tu situación actual como tu comportamiento histórico son tenidos en cuenta en el cálculo del puntaje de crédito.</p>
  //         <p>Hay variables de fácil y rápido manejo, como pagar a tiempo y disminuir los saldos de tus créditos. Hay otras variables que tienen un impacto en el tiempo, como lo es tu experiencia crediticia, o el número de créditos abiertos y cerrados.</p>
  //         <p>Lo importante es que analices tu perfil de crédito y entiendas como manejar los factores que se encuentran en el diagnóstico financiero y que inciden en tu nivel de atractivo y riesgo crediticio para el mercado. Con el tiempo, esto se traduce en un mejor score de crédito.</p>
  //         `
  //       }
  //     ]
  //   },
  //   {
  //     "id":"2","grupo": "Acceso a Midatacrédito","img-blanco":"icono_acceso_blanco.svg","img-morado":"icono_acceso_morado.svg", "textoalt":"Acceso a midatacrédito" , "preguntas": [
  //       {
  //         "pregunta" : "¿Al ingresar la clave el sistema no la acepta?", "respuesta" : `
  //         <p>Si al ingresar ves el mensaje Contraseña o usuario inválido, quizás alguno de los datos digitados no es correcto, por lo cual, te recomendamos cambiar tu contraseña. Da clic en ¿Olvidaste tu contraseña? donde encontrarás dos formas de recuperar tu contraseña y acceder a tu cuenta: a través del correo electrónico con el que te registraste o si no tienes acceso o no recuerdas ese correo puedes responder unas preguntas de validación que te permitirán restablecer tu clave de acceso.</p>
  //         <p>Para crear tu nueva contraseña, ten en cuenta que cumpla estás condiciones de seguridad:</p>
  //         <ul>
  //         <li>Mínimo 9 caracteres.</li>
  //         <li>Al menos una letra mayúscula.</li>
  //         <li>Al menos una letra minúscula.</li>
  //         <li>Al menos un número.</li>
  //         <li>Sin caracteres especiales (ejemplos: $ + – ¿ ” * : &)</li>
  //         </ul>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Tu cuenta se encuentra inactiva?", "respuesta" : `
  //         <p>Si al ingresar a la herramienta con tu usuario y clave se genera el mensaje: “Tu cuenta se encuentra inactiva”; debes dar clic sobre el link que aparece en dicho mensaje para que el sistema te lleve a nuestra página de planes y precios y así tu puedas seleccionar el plan que desees y continuar el proceso de ingreso.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿No recibes el correo para cambio de clave?", "respuesta" : `
  //         <p>Midatacrédito hace el mayor esfuerzo para que la información sea recibida de manera rápida y eficiente pero las demoras o no entregas de los mensajes pueden generarse por causas que no son controlables por Midatacrédito: correo lleno, problemas de comunicación, rechazo del mensaje, envío de mensaje a carpeta de spam, etc.</p>
  //         <p>Si no recibes el mensaje intenta nuevamente, no olvides revisar la bandeja de correo no deseado ya que puede encontrarse allí.</p>
  //         <p>Si no recibes el mensaje para el cambio de clave, realiza el siguiente procedimiento para que puedas asignar tu clave inmediatamente:</p>
  //         <ul>
  //         <li>Regresa nuevamente a la opción ¿Olvidó su contraseña?</li>
  //         <li>Selecciona la opción: No recuerdo el correo con el que me registré y da clic en siguiente.</li>
  //         <li>Ve a la parte inferior del recuadro y da clic en el botón Validar mi identidad.</li>
  //         <li>Diligencia los campos solicitados en el formulario de validación y da clic en el botón Valide su identidad.</li>
  //         <li>En ese momento el sistema generará unas preguntas para comprobar tu identidad y si el proceso es exitoso te permitirá cambiar tu contraseña inmediatamente.</li>
  //         </ul>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿No puedes cambiar tu clave porque no recuerdas o no tienes acceso a los correos que registraste?", "respuesta" : `
  //         <p>Si no tienes acceso a tu cuenta de correo principal realiza los siguientes pasos:</p>
  //         <ul>
  //         <li>Ingresa a Midatacrédito y ve a la parte superior derecha de la página y da clic en Inicia sesión.</li>
  //         <li>En el recuadro de Ingreso, da clic en el link ¿Olvidaste tu contraseña?</li>
  //         <li>Selecciona la opción No recuerdo el correo con el que me registré da clic en el botón siguiente.</li>
  //         <li>Ve a la parte inferior del recuadro y da clic en el botón Validar mi identidad.</li>
  //         <li>Diligencia los campos solicitados en el formulario de validación y da clic en el botón Valide su identidad.</li>
  //         <li>En ese momento el sistema generará unas preguntas y si el proceso es exitoso te permitirá cambiar tu contraseña.</li>
  //         <li>Una vez ingreses con tu usuario y clave, ve a la opción Mi Información Personal ubicada en la parte superior derecha de la página. Allí aparecerán tres opciones dentro de las que se encuentra Mi información, da clic allí y actualiza tu correo electrónico. Para finalizar da clic en Actualizar información.</li>
  //         <li>El sistema te arrojará el mensaje "Información de cuenta actualizada con éxito".</li>
  //         </ul>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿El proceso de validación de tu identificación no se realizó exitosamente?", "respuesta" : `
  //         <p>Si al realizar el proceso de validación en Midatacrédito el sistema te genera este mensaje, se debe a que la información que estás ingresando no coincide con la información registrada en nuestra base de datos.</p>
  //         <p>En caso de presentar alguna inconsistencia en la información de identificación te recomendamos realizar el trámite para la corrección de inconsistencias en la información de identificación (nombres, apellidos, fecha de expedición del documento de identificación).</p>
  //         <p>Para mayor información comunícate con la línea nacional 01 8000 947 030.</p>          
  //         `
  //       },
  //       {
  //         "pregunta" : "¿No recuerdas tu clave de ingreso?", "respuesta" : `
  //         <p>Si no recuerdas tu clave de acceso a tu cuenta de Midatacrédito, realiza el procedimiento descrito a continuación:</p>
  //         <ul>
  //         <li>Ingresa a la página de <a href="www.midatacredito.com" target="_blank" >www.midatacredito.com</a> , dirígete a la parte superior derecha de la página y da clic en el link Inicia sesión.</li>
  //         <li>En la parte inferior del recuadro de Ingrese da clic en ¿Olvidó su contraseña?</li>
  //         <li>Selecciona una de las dos opciones y da clic en Siguiente.</li>
  //         </ul>
  //         <p>Si seleccionaste la opción “Recuerdo el correo con el que me registré” se desplegará un formulario en el que se solicitará tu identificación y correo electrónico personal*(el que reportaste cuando te registraste o el último que hayas actualizado), diligencia todos los campos y da clic en Enviar.</p>
  //         <p>El sistema te arrojará el mensaje: “El e-mail fue enviado a tu cuenta” confirmando el envío de un mensaje con las instrucciones para la asignación de clave.</p>
  //         <p>Ten presentes las instrucciones que allí te dan:</p>
  //         <p>En el mensaje irá un enlace el cual estará activo únicamente 24 horas, si no realizas el proceso de cambio dentro de este tiempo deberás repetir la operación.</p>
  //         <p>Verifica que el mensaje haya sido recibido, no olvides revisar en la carpeta de spam o correo no deseado.</p>
  //         <p>Ten en cuenta las características que debe tener la clave:</p>
  //         <ul>
  //         <li>Mínimo 6 caracteres.</li>
  //         <li>Alfanumérica.</li>
  //         <li>Diferencia mayúsculas y minúsculas.</li>
  //         <li>Sin caracteres especiales (ejemplos: $ + – ¿”*: &)</li>
  //         </ul>
  //         <p>Si seleccionaste la opción "No recuerdo el correo con el que me registré" recuperarás tu contraseña contestando un breve cuestionario para validar tu identidad.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿No aprobaste el proceso de confirmación de identidad?", "respuesta" : `
  //         <p>Con el propósito de garantizar la confidencialidad de tu información y proteger la misma, Midatacrédito cuenta con un moderno proceso de validación electrónica que brinda tranquilidad y seguridad a todos sus usuarios. Por esto, en el proceso de registro, la herramienta formula una serie de preguntas secretas muy específicas relacionadas con actividades, lugares y datos de contacto actuales y del pasado, las cuales exigen respuestas precisas; en caso de cualquier inconsistencia en las respuestas, el sistema no te permitirá el acceso.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿No fue posible generar el cuestionario para tu validación?", "respuesta" : `
  //         <p>Si al realizar el proceso de validación para registrarte en Midatacrédito, se genera el mensaje: “No fue posible generar el cuestionario para su validación”, se debe a que tu Historia de Crédito no cuenta con información suficiente para generar las preguntas requeridas para el proceso de validación de identidad y no podrás registrarte en Midatacrédito.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Tu cédula está registrada en nuestra base de datos pero el sistema no te permite continuar?", "respuesta" : `
  //         <p>Si al realizar el proceso de validación en Midatacrédito, el sistema te genera el mensaje:“Tu cédula está registrada en nuestra base de datos, sin embargo ninguna entidad ha reportado información comercial a la fecha”; hacemos referencia a que no cuentas con ninguna obligación abierta y/o vigente en tu Historia de Crédito en nuestra base de datos y por tal motivo el sistema no te permite el registro.</p>
  //         `
  //       },
  //       {
  //         "pregunta": "¿Al realizar el proceso de validación se genera el mensaje: Has agotado el número de intentos permitidos?", "respuesta" : `
  //         <p>Para validarte exitosamente en Midatacrédito, debes ingresar correctamente los datos de identificación solicitados en el formulario. Ten en cuenta que puedes realizar un máximo de intentos y si estos se cumplen, deberás esperar mínimo dos (2) horas para intentar nuevamente ingresar a la herramienta para continuar con tu proceso.</p>
  //         `
  //       }
  //     ]
  //   },
  //   {
  //     "id":"3","grupo": "Planes y Precios","img-blanco":"icono_planes_precios_blanco.svg","img-morado":"icono_planes_precios_morado.svg", "textoalt":"Planes y precios", "preguntas": [
  //       {
  //         "pregunta" : "¿Por qué inscribirse en Midatacrédito?", "respuesta" : `
  //         <p>Midatacrédito es un aliado que de manera centralizada te permite administrar, analizar y proteger de manera fácil, sencilla y rápida toda la información relacionada con tus finanzas personales.</p>
  //         <p>Al poder analizar y comparar fácilmente el estado de sus cuentas, los pagos de intereses y los aportes de capital, los usuarios de Midatacrédito pueden planear de manera más eficiente sus hábitos crediticios y por ende sus finanzas personales y familiares.</p>
  //         <p>Al tener pleno conocimiento sobre cómo se reflejan tus actividades en el sistema financiero, puedes optimizar tu relación con las diferentes entidades bancarias y empresas prestadoras de múltiples servicios para que el crédito se constituya en una verdadera herramienta de crecimiento y proyección.</p>
  //         <p>Midatacrédito te permite tomar decisiones con pleno conocimiento, mejorar tu flujo de caja y perfilarte mejor para que puedas mantener siempre abiertas las puertas para acceder a líneas de financiación a tu medida.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿En qué consisten las Alertas preventivas de fraude al sistema financiero?", "respuesta" : `
  //         <p>Cualquier persona puede ser víctima de fraude por suplantación o estar expuesta a situaciones de riesgo, como pérdida o robo de documentos.</p>
  //         <p>Es por esto que nuestros suscriptores cuentan con la posibilidad de generar alertas en caso de situaciones de riesgo. Estas alertas son visualizadas por cualquier entidad que consulte tu información en DataCrédito, siendo una herramienta efectiva de prevención de fraude.</p>
  //         <p>Podrás alertar al sistema financiero si has sido víctima de fraude por suplantación, si resides fuera de Colombia, si te han robado o si has perdido tus documentos. </p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿La Póliza solamente tiene vigencia durante el tiempo de mi suscripción?", "respuesta" : `
  //         <p>La póliza cubre todos los eventos ocurridos durante el tiempo de tu suscripción, así descubras el siniestro una vez vencido la suscripción. El plazo máximo para reportar el siniestro ante la aseguradora es de dos años después de ocurrido el mismo.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Qué utilidad tiene Midatacrédito en relación con las obligaciones que tengo?", "respuesta" : `
  //         <p>Al tener en un solo lugar toda tu información crediticia y poder conocer el estado de las cuotas y hábitos de pago de manera fácil y rápida, podrás planear y programar de forma más eficaz el cumplimiento de tus obligaciones.</p>
  //         <p>Al mismo tiempo podrás tener claro cuáles son los créditos más onerosos en términos de intereses y cuáles los más económicos, información con la cual podrás tomar mejores decisiones para seguir o cambiar tus líneas y/o entidades de financiación.</p>
  //         <p>De otro lado, Midatacrédito te sirve para tener pleno conocimiento sobre los reportes que las entidades y empresas generan en relación con los hábitos de pago de tus obligaciones.</p>
  //         <p>Estar al tanto de estas también te permitirá detectar cualquier inconsistencia para proceder a solicitar la corrección correspondiente y así hacer de tus créditos la mejor carta de presentación para que al momento de tramitar nuevos préstamos estos sean gestionables en tiempos muy cortos y con mayor favorabilidad en las condiciones.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Cómo cancelar mi suscripción a Midatacrédito?", "respuesta" : `
  //         <p>Lamentamos que no quieras continuar con tu suscripción a Midatacrédito.</p>
  //         <p>Para cancelar una suscripción podrás llamar a la línea telefónica en Bogotá 6017430606 o a nivel nacional 01 8000 947 030, en días laborales de lunes a viernes en el horario de 8:00 a.m a 5:00 p.m. con 5 días hábiles de anticipación a que caduque el término de vigencia contratado. Por ejemplo, si el servicio que contrataste tiene una vigencia de tres meses, para cancelar la suscripción con cargo a tu tarjeta de crédito o cuenta de ahorros, deberás solicitarlo a Midatacrédito 5 días hábiles antes que estos tres meses se cumplan.</p>
  //         <p>Si elegiste una suscripción mensual, podrás cancelar para que el servicio no te genere cobros en cualquier momento, es decir, no habrá permanencia mínima para lo cual debes comunicarte con las líneas telefónicas con 5 días hábiles de anticipación a que termine el mes. Si solicitas extemporáneamente la cancelación, es decir, cuando está por fuera del término recién señalado, deberás pagar la siguiente vigencia y continuarás teniendo acceso al servicio hasta el final del ciclo de facturación corriente.</p>
  //         <p>Midatacrédito no tiene la obligación de informarte que los periodos gratuitos promocionales finalizaron o que el periodo facturable empezó, pero puedes comunicarte para cancelar tu suscripción.</p>
  //         <p>Así mismo, para tu mayor comodidad, podrás cancelar tu suscripción a través de nuestros canales digitales:</p>
  //         <ul>
  //         <li>Chat: disponible al iniciar sesión en tu cuenta en la sección de servicio al cliente de lunes a viernes en el horario de 7 a.m. a 7 p.m. y sábados de 7 a.m. a 1 p.m.</li>
  //         <li>Pestaña contáctenos: disponible al iniciar sesión en el menú superior.</li>
  //         </ul>
  //         <p>Para conocer más información, te invitamos a conocer nuestros <a href="https://d1gj9peb9ac569.cloudfront.net/tyc/terminos_y_condiciones.pdf" target="_blank">Términos y Condiciones</a>.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Qué necesito para renovar un plan de Midatacrédito?", "respuesta" : `
  //         <p>Es muy sencillo. Te tomará solo unos segundos hacerlo en cuatro pasos:</p>
  //         <ul>
  //         <li>Selecciona el plan que quieres adquirir.</li>
  //         <li>Acepta los términos legales para utilizar el sitio.</li>
  //         <li>Paga con el método de pago que prefieras.</li>
  //         <li>Disfruta de Midatacrédito.</li>
  //         </ul>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Qué ocurre cuando se vence mi plan de Midatacrédito?", "respuesta" : `
  //         <p>Una vez caduque el tiempo de afiliación al que tienes derecho por tu plan, el sistema no te permitirá utilizar las múltiples herramientas de Midatacrédito.</p>
  //         <p>Sin embargo, hay que tener en cuenta que a través de correos electrónicos y mensajes de texto al celular, el sistema te recordará con antelación la fecha de vencimiento de tu plan y que este mismo está próximo a caducar. De esta manera, podrás renovarlo o adquirir uno de mayor periodo de tiempo.</p>
  //         <p>Es importante tener en cuenta que tu información no será borrada y que Midatacédito la mantendrá actualizada para que puedas continuar beneficiándote de la herramienta una vez decidas renovar o reactivar tu afiliación (esto en el caso de que la dejes vencer).</p>
  //         `
  //       }
  //     ]
  //   },
  //   {
  //     "id":"4","grupo": "Educación Financiera","img-blanco":"icono_educacion_fin_blanco.svg","img-morado":"icono_educacion_fin_morado.svg", "textoalt":"Educación financiera", "preguntas": [
  //       {
  //         "pregunta" : "¿Cómo identificar un correo de Midatacrédito?", "respuesta" : `
  //         <p>Para identificar nuestros correos ten en cuenta estas tres recomendaciones:</p>
  //         <ul>
  //         <li>Siempre estará presente la Zona de Seguridad, en la parte superior de los correos podrás confirmar tus nombres y los últimos 3 dígitos de tu cédula.</li>
  //         <li>Remitentes. Ten presente que todos nuestros correos son enviados de alguna de las siguientes direcciones: contacto@midatacrédito.com, alertas.midatacrédito@experian.com, alianzas@midatacrédito.com o noresponder@midatacrédito.com.</li>
  //         <li>Recuerda que nosotros nunca solicitamos información confidencial o claves de acceso a portales o productos financieros.</li>
  //         </ul>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Qué son las Centrales de Riesgo?", "respuesta" : `
  //         <p>Las centrales de riesgo o centrales de información (Datacrédito Experian, Cifin, entre otras) son entidades privadas que poseen bases de datos donde las entidades registran las operaciones y el comportamiento crediticio de las personas naturales y jurídicas. Las entidades que reportan información a una central de riesgo pueden ser: bancos, entidades financieras, cooperativas, empresas de telecomunicaciones y del sector real. La información que está contenida en estas bases de datos es tanto positiva como negativa (incumplimiento en el pago de obligaciones).</p>
  //         <p>La central de riesgo se encarga de almacenar, procesar y suministrar información sobre el comportamiento de pago de las obligaciones de las personas naturales y jurídicas. Esta información es usada al momento de otorgar un crédito pero no es el único factor considerado, pues las entidades son autónomas y establecen sus propias políticas de aprobación. Es importante señalar que DataCrédito Experian no aprueba o niega créditos, cada entidad toma sus decisiones.</p>
  //         <p>Las entidades que reportan información a DataCrédito Experian están en el deber de actualizar y rectificar los datos reportados.</p>
  //         <p>Para conocer la información que reportan las entidades en tu historia de crédito, existen varios canales.</p>
  //         <p>Para tener acceso ilimitado y en línea a tu historia de crédito, te invitamos a descubrir <a href="https://www.midatacredito.com/">Midatacrédito.com</a></p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Qué relación existe entre mi Score y mi bienestar financiero?", "respuesta" : `
  //         <p>Son diversos los motivos que nos pueden llevar a solicitar un crédito: comprar una casa, pagar deudas, irnos de viaje, o comprar un vehículo, pero para hacerlo es importante tener en cuenta una serie de factores que serán decisivos para lograr acceder a este.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Las huellas de consulta afectan el score?", "respuesta" : `
  //         <p>No, el número de consultas no afectan tu score o puntaje de crédito. DataCrédito Experian diseñó Midatacrédito para que administres totalmente tu información y siempre esté disponible para tu análisis y la mejor toma de decisión con tu historial crediticio.</p>
  //         <p>Puedes consultar frecuentemente tu información, las consultas que realices no bajan de ninguna forma tu puntaje.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Qué pasa si obtengo un nuevo préstamo, me baja el score?", "respuesta" : `
  //         <p>No necesariamente, el score analiza muchos factores del perfil de crédito actual e histórico de una persona; cuando se abre una nueva obligación el score debe aprender si estás o no preparado para el nuevo nivel de endeudamiento y cómo será tu comportamiento frente a esta nueva deuda. Ahora, varios nuevos préstamos abiertos en un corto plazo si pueden llegar a bajar el score porque muestran que el nivel de obligaciones creció rápidamente de manera significativa.</p>
  //         <p>Es importante anotar que un score se construye a través de toda la vida crediticia, y que eventos como la compra de un primer automóvil o casa no significan un deterioro en el perfil de crédito. De hecho, si en el tiempo tienen un buen manejo, estas nuevas obligaciones tendrán un impacto positivo en el score.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Para qué me sirve conocer el Score de Crédito?", "respuesta" : `
  //         <p>Hay tres razones principales para cuidar y administrar tu Score:</p>
  //         <ul>
  //         <li>Un buen Score de Crédito te abre puertas, no sólo en el mercado financiero sino también en todas las entidades otorgadoras de crédito, como compañías de telecomunicaciones, servicios públicos, entre otras.</li>
  //         <li>Un buen Score te permite ahorrar dinero y acceder a una mejor oferta de productos por parte de las entidades. Algunas entidades pueden otorgar tasas de interés preferenciales a personas con bajos niveles de riesgo crediticio. En otros países, esto ya es práctica común.</li>
  //         <li>El score resume en un solo puntaje muchos factores de tu perfil de crédito que afectan tu nivel de atractivo crediticio y riesgo para el mercado que otorga crédito. Es una manera fácil y rápida de saber si estás logrando mejorar tu atractivo crediticio y/o disminuyendo tu riesgo financiero.</li>
  //         </ul>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Toda mi información es positiva pero el puntaje es bajo, por qué?", "respuesta" : `
  //         <p>El score de Midatacrédito no sólo se basa en el hábito de pago de las personas, también existen otros factores como experiencia crediticia, los productos de crédito que tienes o has tenido, tus niveles de endeudamiento y utilización, entre otros que afectan tu puntaje.</p>
  //         <p>El diagnóstico financiero y las recomendaciones personalizadas de Midatacrédito te ayudarán a entender cómo mejorar tu score. Empieza ahora.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Cómo se establecen los cupos de endeudamiento?", "respuesta" : `
  //         <p>Cada entidad utiliza factores matemáticos distintos para determinar cuál es la capacidad de endeudamiento de las personas. No obstante, en términos generales se espera que el conjunto de cuotas mensuales de los créditos no supere el 60 por ciento los ingresos mensuales de la persona.</p>
  //         <p>Lo anterior siempre y cuando los gastos fijos como vivienda, educación, manutención y transporte sean menores al 40 por ciento de los ingresos.</p>
  //         <p>Claro que es fundamental tener en cuenta que cada banco analiza de manera puntual cada caso y que las políticas cambian de una entidad a otra y también dependen del tipo de crédito (libre inversión, vehículo, vivienda, consumo, entre otros). Por ejemplo, en el caso de las líneas hipotecarias de vivienda, por Ley la primera cuota mensual no puede ser superior al 30 por ciento de los ingresos mensuales.</p>
  //         <p>En todos los escenarios, el propósito de los bancos al calcular la capacidad de endeudamiento es que el solicitante del crédito pueda cumplir normalmente con las obligaciones de pago, sin que ponga en riesgo su estabilidad financiera.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Cuál es el puntaje adecuado para acceder a un crédito?", "respuesta" : `
  //         <p>Cada entidad otorgadora de crédito fija sus políticas; en consecuencia, un mismo puntaje puede generar diferentes decisiones según la entidad que está analizando el caso.</p>          
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Cómo se calcula mi score?", "respuesta" : `
  //         <p>Tu información de crédito está en constante cambio y el score resume en un puntaje todos los elementos que afectan el nivel de riesgo crediticio de una persona. No solamente el pagar o no tus obligaciones afecta tu score de crédito o puntaje de crédito, otros factores como experiencia crediticia, utilizaciones y cupos disponibles en tus tarjetas de crédito, saldos y cuotas de tus créditos, aperturas recientes de productos, diversidad de tu portafolio, número de productos abiertos y cerrados, cupos disponibles en rotativos, entre otros, también son incluidos en el cálculo del score. Tanto tu situación actual como tu comportamiento histórico son tenidos en cuenta en el cálculo del Score de Crédito.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Es posible que no me aprueben el crédito por un mal score?", "respuesta" : `
  //         <p>La aprobación del crédito no sólo se basa en el puntaje sino también en las políticas internas de cada entidad, el monto solicitado, los ingresos de la persona entre otros factores.</p>
  //         <p>En términos generales a mayor puntaje, mayores son las probabilidades de acceder a un crédito. Puntajes bajos no necesariamente implican la negación del crédito, pero se dificulta el otorgamiento cuando el score es muy bajo. Por eso es tan importante conocer y administrar tu score.</p>
  //         <p>No he tenido ni tengo moras, pero me dicen que mi Score no es bueno</p>
  //         <p>El score de Midatacrédito no solo se basa en el hábito de pago de las personas, también existen otros factores como experiencia crediticia, los productos de crédito que tienes o has tenido, tus niveles de endeudamiento y utilización, entre otros que afectan tu puntaje.</p>
  //         <p>El diagnóstico financiero te da recomendaciones personalizadas basadas en tu situación actual para que puedas mejorar tu perfil de crédito y tu score</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Qué debo hacer si me negaron un préstamo?", "respuesta" : `
  //         <p>¿Alguna vez te has preguntado por qué te negaron un crédito? y ¿Qué podrías hacer para que tu próxima solicitud de préstamo finalice de forma exitosa?</p>
  //         <p>Lo primero y lo más importante es que entiendas cuál fue la razón por la que te negaron el crédito.</p>
  //         <ul>
  //         <li>Tu Puntaje de Crédito es menor al exigido por la entidad. Tu Puntaje de Crédito mide estadísticamente la probabilidad de que pagues cumplidamente tus obligaciones. Si conoces cómo funciona el Puntaje de Crédito podrás actuar sobre los factores que lo están afectando.</li>
  //         <li>Tu capacidad de endeudamiento. Es el cálculo de cuál es la máxima cuota mensual (sumando las cuotas de los productos que ya tienes) con la que te puedes comprometer, de acuerdo a tu nivel de ingresos y a tu perfil de crédito. Revisa tu capacidad de endeudamiento, puede ser una de las razones por las que te negaron el crédito.</li>
  //         <li>Otros factores. Las entidades también tienen en cuenta algunos factores demográficos como tu edad, tu ciudad de residencia, el tipo de contrato laboral que tienes, si eres independiente o si tienes un contrato temporal, también pueden aplicar variables de la Historia de Crédito como tu experiencia crediticia o moras históricas. Cuando es un crédito con garantías entra a jugar el valor del avalúo del bien (casa, carro) pues normalmente no te prestarán el 100% de su valor comercial.</li>
  //         </ul>
  //         <p>Cada entidad decide y usa criterios diferentes para la aprobación de crédito, por ejemplo, es posible que a un mismo cliente le nieguen un crédito en un banco y se lo aprueben en otro.</p>
  //         <p>Si recientemente te ha sido negada una solicitud, debes mejorar tus posibilidades antes de volver a realizar el trámite.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Cómo puedo mejorar mi Score?", "respuesta" : `
  //         <p>Mejorar tu score es un proceso que puede tomar algunos meses mientras ajustas tu perfil crediticio, dado que tanto tu situación actual como tu comportamiento histórico son tenidos en cuenta en el cálculo del score de crédito.</p>
  //         <p>Hay variables de fácil y rápido manejo, como pagar a tiempo y disminuir los saldos de tus créditos. Hay otras variables que tienen un impacto en el tiempo, como lo es tu experiencia crediticia, o el número de créditos abiertos y cerrados.</p>
  //         <p>Lo importante es que analices tu perfil de crédito y entiendas como manejar los factores que se encuentran en el diagnóstico financiero y que inciden en tu nivel de atractivo y riesgo crediticio para el mercado. Con el tiempo, esto se traduce en un mejor score de crédito.</p>
  //         `
  //       }
  //     ]
  //   },
  //   {
  //     "id":"5","grupo": "Ley Habeas Data","img-blanco":"icono_ley_habeas_blanco.svg","img-morado":"icono_ley_habeas_morado.svg", "textoalt":"Ley de Habeas Data", "preguntas": [
  //       {
  //         "pregunta" : "¿Cómo saber si estoy reportado en DataCrédito?", "respuesta" : `
  //         <p>Todas las personas que hayan abierto una cuenta de ahorros, una cuenta corriente o cualquier tipo de obligación con una entidad que reporte información a DataCrédito están reportadas. En DataCrédito se encuentra reportada tanto la información positiva como de incumplimiento en el pago de obligaciones.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Por qué es importante que se reporte mi información si estoy al día en mis obligaciones?", "respuesta" : `
  //         <p>Es muy importante que esta información sea reportada a la central de riesgo pues le permite a las entidades conocer tus hábitos de pago, es la carta de presentación que te abre las puertas en las entidades. El crédito te permite realizar inversiones que serían difíciles de realizar con recursos propios o que tendrías que esperar por más tiempo hasta ahorrar los recursos necesarios, por ejemplo, una casa, un carro, educación, un viaje o un bien. Esta información positiva permanece en el tiempo.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Los reportes de mora o incumplimiento en mis obligaciones permanecerán para siempre?", "respuesta" : `
  //         <p>Los reportes negativos que contienen registros de incumplimiento en tus obligaciones están regidos por la Ley Habeas Data (artículo 13 de la Ley 1266 de 2008 y la Sentencia C-1011 de 2008 de la Corte constitucional). Una vez te encuentres al día con las obligaciones y, si la mora fue inferior a dos años, el informe durará el doble del tiempo de la mora. Por ejemplo, si tu mora fue de 10 meses, el reporte negativo será visible durante 20 meses. Después de lo cual será removido de tu historial.</p>
  //         <p>Cuando la mora supera los dos años, el sistema mantendrá disponible el correspondiente informe durante cuatro años, que serán contados desde el momento en que la deuda sea puesta al día</p>
  //         <p>En todos los casos, una vez estás a paz y salvo, junto con tu reporte, aparecerá una nota que aclarará que tu obligación está al día.</p>
  //         <p>Si deseas conocer cómo consultar tu historial crediticio y toda la información reportada en tu historia de crédito, te invitamos a ver este artículo donde encontrarás los canales disponibles para consultar tu información.</p>
  //         `
  //       },
  //       {
  //         "pregunta" : "¿Cuánto tiempo permanece un reporte negativo en mi historia de crédito de DataCrédito?", "respuesta" : `
  //         <p>La Ley Habeas Data (artículo 13 de la Ley 1266 de 2008 y la Sentencia C-1011 de 2008 de la Corte constitucional) establece que la información negativa que refleja incumplimientos o moras tendrá un plazo máximo de permanencia de 4 años, contados a partir de la fecha de pago o extinción de la obligación. Si la mora en el pago es inferior a dos años, el reporte permanecerá por el doble del tiempo de la mora, contados a partir de que sean pagadas las cuotas vencidas, o que se extinga la obligación por cualquier modo. Por ejemplo, si estuviste en mora durante dos meses y te pusiste al día en tus obligaciones, el reporte negativo permanecerá durante 4 meses. Es importante señalar que, si estabas en mora y pagaste, el historial crediticio reflejará que la obligación se encuentra al día. Sin embargo, el pasado de mora que presentaste será eliminado conforme a los términos que acabamos de explicar.</p>
  //         `
  //       }
  //     ]
  //   }
  // ];
  public selFaqObject:any;
  public checkDiv = false;
  constructor(
    private titulo: Title, 
    private meta: Meta,
    private sesionServ: SesionService,
    private metadatosService: MetadatosService,
    private dlService: DataLayersService,
    public broker: BrokerService,
    private utilities: UtilitiesService,
    ) {    
      titulo.setTitle('Preguntas frecuentes - Midatacrédito');
      meta.updateTag({name: 'description', 
        content:"¿Te preocupa tu historial crediticio? Midatacrédito te ofrece la respuesta que necesitas."});
   }

  ngOnInit() {
    this.broker.getFaq().subscribe({
      next: (response: any) => {
        this.faqs = response;
        setTimeout(() => {
          let button = document.getElementById("btn-faq-1");
          button?.click();
          this.selFaqObject = this.faqs.find(x => x.id == '1');
        }, 100);
        this.metadatosService.actualizando_canonical('https://www.midatacredito.com/faq');
         // GA4 - User
         if (this.sesionServ.user){
          this.dlService.dl_userId( '.', this.sesionServ.planCurrently );
        } else {
          this.dlService.dl_userId( null, 'anonimo' );
        }
      }
    });
    
  }

  selFaq(id: string){
    this.faqs.forEach(element => {
      let button = document.getElementById("btn-faq-"+element.id);
      button?.classList.remove("activo");
      button?.classList.add("inactivo");
      button?.children[1].setAttribute('src','assets/img/icons/'+element['img-morado']);
    });
    this.selFaqObject = this.faqs.find(x => x.id == id);
    let button = document.getElementById("btn-faq-"+id);
    button?.classList.remove("inactivo");
    button?.classList.add("activo");
    button?.children[1].setAttribute('src','assets/img/icons/'+this.selFaqObject?.['img-blanco']); 
  }

  changeType(num2: any) {
    if ($('#moreIcon' + num2).hasClass('fa-angle-right')) {
      $('.btn-link > i').removeClass();
      $('.btn-link > i').addClass('fa fa-angle-right');
      $('#moreIcon' + num2).toggleClass('fa fa-angle-right fa fa-angle-down');
    } else if ($('#moreIcon'+ num2).hasClass('fa-angle-down')) {
      $('#moreIcon' + num2).toggleClass('fa fa-angle-down fa fa-angle-right');
    }
  }

  /**
   * Inicializar banner de Google Ad Manager
   */
  checkBanner(){
    if (!this.checkDiv) {
			let tamano = [[728, 90], [300, 250]];
			this.utilities.addPubliById(environment.slotsBanners.faq, 'div-gpt-ad-1716905496511-0', tamano);
			this.checkDiv = true;
		}
		return ''
  }

}
