<div class="mainCompPlanes">
  <div class="headerCompPlanes" [ngClass]="{'scrollClassHead' : scroll}">
    <div class="row" [ngClass]="{'displatNone' : scroll}">
      <div class="col-12">
        <div class="titulo">
          <h1>No dejes tu futuro en manos del destino y cumple tus objetivos financieros con nuestras herramientas</h1>
          <p>
            Cada opción tiene características y beneficios, con los que te ayudaremos a lograr tus objetivos financieros.
          </p>
        </div>
      </div>
    </div>
    <div class="row boxPlanes">
      <div class="col-4 dm-none">
        <div class="pagos">
          <label class="container">Suscripción mensual
            <input type="radio" checked="checked" name="radio" (click)="changePago('mensual')">
            <span class="checkmark"></span>
          </label>
          <label class="container">Suscripción trimestral <label class="subText"> Hasta un {{planPre.trimestral[3]}} Dto.</label>
            <input type="radio" name="radio" (click)="changePago('trimestral')">
            <span class="checkmark"></span>
          </label>
          <label class="container">Suscripción anual <label class="subText"> Hasta un {{planPre.anual[3]}} Dto.</label>
            <input type="radio" name="radio" (click)="changePago('anual')">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div class="col-md-8 col-12">
        <div class="row compra">
          <div class="col-md-3 col-3 padding-lr-mob">
            <app-tarjeta-plan [caja]="'boxPlanMini'" [planeSel]="freePremium" [free]="true" [funnPlan]="'CP'"></app-tarjeta-plan>
          </div>
          <div class="col-md-3 col-3 padding-lr-mob" *ngFor="let plan of planesFil;">
            <app-tarjeta-plan [caja]="'boxPlanMini'" [planeSel]="plan" [funnPlan]="'CP'"></app-tarjeta-plan>
          </div>
          <!-- <div class="col-3">
            <app-tarjeta-plan [caja]="'boxPlanMini'"></app-tarjeta-plan>
          </div>
          <div class="col-3">
            <app-tarjeta-plan [caja]="'boxPlanMini'"></app-tarjeta-plan>
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div class="caracteristicasPlan" [ngClass]="{'scrollClassPlanes' : scroll}">
    <div class="row" *ngFor="let caracteristicas of caracteristicasTotal;">
      <div class="col-12">
        <h2>{{caracteristicas.plan}}</h2>
      </div>
      <div class="col-12 caracteristica">
        <div class="row align-center" *ngFor="let carac of caracteristicas.caracteristicasPlan;">
          <div class="col-12 col-md-4">
            <p>{{carac.caracteristica}}</p>
          </div>
          <div class="col-12 col-md-8">
            <div class="row check">
              <div class="col-3" [ngClass]="{'noTiene' : !carac.free}">
                <i class="fa fa-check" [ngClass]="{'fa-times' : !carac.free}"></i>
              </div>
              <div class="col-3" [ngClass]="{'noTiene' : !carac.per}">
                <i class="fa fa-check" [ngClass]="{'fa-times' : !carac.per}"></i>
              </div>
              <div class="col-3" [ngClass]="{'noTiene' : !carac.ant}">
                <i class="fa fa-check" [ngClass]="{'fa-times' : !carac.ant}"></i>
              </div>
              <div class="col-3" [ngClass]="{'noTiene' : !carac.pre}">
                <i class="fa fa-check" [ngClass]="{'fa-times' : !carac.pre}"></i>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="barra">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="info">
    <div class="row">
      <div class="col-12">
        <div>
          <p>
            * Tanto la póliza como la asesoría legal del Plan Premium, son servicios prestados por SBS Seguros Colombia S.A.<br />
          </p>
        </div>
      </div>
    </div>
  </div>
</div>