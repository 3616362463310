
<div class="ant genericBox" *ngIf="id=='ANT'">
    <img src="https://d1gj9peb9ac569.cloudfront.net/midc/icons/purple/MonitoreoEmail.png" alt="content">
    <div class="info">
        <h4>Descubre si tu información personal está comprometida en la dark web.</h4>
        <p>Esta funcionalidad hace parte del <b>Plan Protege tus Datos.</b> Compra este plan para acceder a esta característica.</p>
        <a class="btnGeneric linkPago my-2" href="/pago?funn=DAPRO&plan=ANT1" (click)="payPlan_send_datalayer('ant')">Comprar plan</a>
    </div>
</div>

<div class="pro genericBox" *ngIf="id=='PRO'">
    <img src="https://d1gj9peb9ac569.cloudfront.net/midc/icons/white/ProbabilidadExito.png" alt="content">
    <div class="info">
        <h4>Adquiere una póliza antifraude, robo o suplantación de identidad.</h4>
        <p>Esta funcionalidad hace parte del <b>Plan Premium.</b> Compra este plan para acceder a esta característica.</p>
        <a class="btnGeneric linkPago my-2" href="/pago?funn=DAPRE&plan=PRO1" (click)="payPlan_send_datalayer('pro')">Comprar plan</a>
    </div>
</div>
