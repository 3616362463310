import { Injectable } from '@angular/core';
// import { flattenStyles } from '@angular/platform-browser/src/dom/dom_renderer';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PlanesType } from '../interfaces/planes.interface';
import { BrokerService } from '../services/broker.service';
import { SesionService } from '../services/sesion.service';

@Injectable({
  providedIn: 'root'
})
export class PlanesGuard implements CanActivate {

  constructor(public broker: BrokerService, public sesion: SesionService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(obs => {
      if (this.sesion.isEmpty(this.sesion.planes)) {
        this.broker.obtener_planes().subscribe(
          {
            next: (data:any) =>{
              this.sesion.planes = data;
              obs.next(true);
            }, 
            error: (error)=>{
              obs.next(false);
            }
          }
        );
      }else{
        obs.next(true);
      }
    });
  }
}
