<section class="dynamic-header">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-6">
				<div class="header-content">
					<p>#YoComproInteligente</p>
					<h2>Adquiere tu suscripción {{plansCheck.periodicity}} Midatacrédito, una decisión inteligente para tu futuro.</h2>
					<div class="line-purple"></div>
					<h3><span>Una suscripción {{plansCheck.periodicity}}</span> te otorga protección y tranquilidad, y te ayuda a <span>ahorrar tiempo y dinero</span>.</h3>
					<a class="cta-more-info" (click)="scrollToElement(target)">
						Conoce más
					</a>
				</div>
			</div>
			<div class="col-12 col-md-6 align-self-center">
				<img src="assets/img/landings/dinamicas/header.svg" class="w-100 mx-auto d-none d-md-block" alt="Planes Midatacrédito" title="Planes Midatacrédito" />
			</div>
		</div>
	</div>
</section>
<section class="dynamic-content" #target>
	<div class="container">
		<div class="row">
			<div class="col-12 text-center">
				<h1>Plan {{plansCheck.name}}</h1>
				<div class="line-purple"></div>
			</div>
			<div class="col-12">
				<div class="price-plan">
					<div class="left">
						<h2>Suscripción {{plansCheck.periodicity}}</h2>
						<h3>${{plansCheck.price}}</h3>
						<div class="ribbon">
							<p><span>Te ahorras</span> {{plansCheck.discount}} de la suscripción mensual</p>
							<div class="triangule-top-left"></div>
							<div class="triangule-bottom-left"></div>
							<div class="triangule-top-right"></div>
							<div class="triangule-bottom-right"></div>
						</div>
						<a class="cta-pay-plan" id="dynamic{{plansCheck.periodicity}}Landing" webActivity data-idtag="dynamicsLandings" data-nametag="dynamicLanding"
							(click)="seleccionarPlan(paramPlan1, paramPlan2, paramPlan3, paramPlan4)">
							Adquirir plan
							<i class="fa fa-chevron-right" aria-hidden="true"></i>
						</a>
						<div class="fixed-cta">
							<a class="cta-pay-plan" id="dynamic{{plansCheck.periodicity}}Landing" webActivity data-idtag="dynamicsLandings" data-nametag="dynamicLanding"
								(click)="seleccionarPlan(paramPlan1, paramPlan2, paramPlan3, paramPlan4)">
								Adquirir plan
								<i class="fa fa-chevron-right" aria-hidden="true"></i>
							</a>
						</div>						
					</div>
					<div class="right">
						<div id="carouselIndicators" class="carousel slide" data-ride="carousel">							
							<div class="carousel-inner">
								<div class="carousel-item active">
									<img src="assets/img/landings/dinamicas/slide-1.svg" class="d-block" alt="slide 1">
									<div class="carousel-caption">
										<h5>Ahorra dinero</h5>
									</div>
								</div>
								<div class="carousel-item">
									<img src="assets/img/landings/dinamicas/slide-2.svg" class="d-block" alt="slide 2">
									<div class="carousel-caption">
										<h5>Ahorra tiempo</h5>
									</div>
								</div>
								<div class="carousel-item">
									<img src="assets/img/landings/dinamicas/slide-3.svg" class="d-block" alt="slide 3">
									<div class="carousel-caption">
										<h5>Beneficios adicionales</h5>
									</div>
								</div>
							</div>
							<ol class="carousel-indicators">
								<li data-target="#carouselIndicators" data-slide-to="0" class="active"></li>
								<li data-target="#carouselIndicators" data-slide-to="1"></li>
								<li data-target="#carouselIndicators" data-slide-to="2"></li>
							</ol>
						</div>					
					</div>
				</div>
			</div>
			<div class="col-12"></div>
			<div class="col-12"></div>
		</div>
	</div>
</section>
<section class="dynamic-bullets">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="box-bullets" >
					<h4>Beneficios de este plan</h4>
					<div class="bullets-columns">
						<div class="column" *ngIf="planUser == 'A3' || planUser == 'A12' || planUser == 'B3' || planUser == 'B12' || planUser == 'C3' || planUser == 'C12'">
							<div *ngFor="let item of constantes.items | slice: 0 : 1">
								<h5>{{item.title}}</h5>
								<div class="line-purple"></div>
								<div class="bullet" *ngFor="let bullet of item.description | slice: 0:4">
									<i class="fa fa-check" aria-hidden="true"></i>
									<p [innerHTML]="bullet"></p>
								</div>
								<div class="bullet">
									<ul>
										<li [innerHTML]="bullet" *ngFor="let bullet of item.description | slice: 4:7"></li>
									</ul>
								</div>
							</div>
						</div>
						<div class="column" *ngIf="planUser == 'A3' || planUser == 'A12' || planUser == 'B3' || planUser == 'B12' || planUser == 'C3' || planUser == 'C12'">
							<div *ngFor="let item of constantes.items | slice: 1 : 2">
								<h5>{{item.title}}</h5>
								<div class="line-purple"></div>
								<div class="bullet" *ngFor="let bullet of item.description">
									<i class="fa fa-check" aria-hidden="true"></i>
									<p [innerHTML]="bullet"></p>
								</div>
							</div>
						</div>
						<div class="column" *ngIf="planUser == 'B3' || planUser == 'B12' || planUser == 'C3' || planUser == 'C12'">
							<div *ngFor="let item of constantes.items | slice: 2 : 3">
								<h5>{{item.title}}</h5>
								<div class="line-purple"></div>
								<div class="bullet" *ngFor="let bullet of item.description">
									<i class="fa fa-check" aria-hidden="true"></i>
									<p [innerHTML]="bullet"></p>
								</div>
							</div>
						</div>
						<div class="column" *ngIf="planUser == 'B3' || planUser == 'B12'">
							
						</div>
						<div class="column d-none d-md-block" *ngIf="planUser == 'C3' || planUser == 'C12'">
							<div *ngFor="let item of constantes.items | slice: 3 : 4">
								<h5>{{item.title}}</h5>
								<div class="line-purple"></div>
								<div class="bullet" *ngFor="let bullet of item.description">
									<i class="fa fa-check" aria-hidden="true"></i>
									<p [innerHTML]="bullet"></p>
								</div>
								<p>*Tanto la póliza como la asesoría legal del Plan Premium, son servicios prestados por SBS Seguros Colombia S.A</p>
							</div>
						</div>						
					</div>
				</div>
			</div>
		</div>
	</div>
</section>