import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BrokerService } from 'src/app/core/services/broker.service';
import { ConstantesService } from 'src/app/core/services/constantes.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { environment } from '../../../environments/environment';

interface Pse {
  	nuevoMetodoPago: any;
 	session: any;
	detallesDatos:{
		tipoCuenta: any,
		numeroCuenta: any,
		codigoBanco: any,
		tipoPersona: any,
		nombreCompleto: any,
		tipoDocumento: any,
		cedula: any
	},
  	params: {
		transaction: {
			order: {
				description: any;
				referenceCode: any;
				notifyUrl: any;
				buyer: {
					emailAddress: any;
					shippingAddress: {
						street1: any;
						street2: any;
						city: any;
						state: any;
						country: any
						postalCode: any;
						phone: any;
					}
				}
			},
			payer: {
				//fullName: any;
				firstName: any;
				lastName: any;
				emailAddress: any;
				dniNumber: any;
				contactPhone: any;
				billingAddress: {
					street1: any;
					street2: any;
					city: any;
					state: any;
					country: any;
					postalCode: any;
					phone: any;
				}
			},
			extraParameters: {
				RESPONSE_URL: any;
				PSE_REFERENCE1: any;
				FINANCIAL_INSTITUTION_CODE: any;
				USER_TYPE: any;
				PSE_REFERENCE2: any;
				PSE_REFERENCE3: any;
				PSE_TYPE_COUNT: any;
				PSE_NUMBER_COUNT: any;
			},
			paymentMethod: any;
			paymentCountry: any;
			ipAddress: any;
			cookie: any;
			userAgent: any;
		},
		test: any;
		giftcardCode: any;
		planCode: any;
		recurrency: any;
  	};
}

@Component({
  selector: 'app-pse-change',
  templateUrl: './pse-change.component.html',
  styleUrls: ['./../tc-change/tc-change.component.scss']
})
export class PseChangeComponent implements OnInit {
	pse: Pse;
	tcPay = true;
	tcChange = false;
	alertCheck = false;
	alertError = false;
  	constructor(
		  public sesion: SesionService, 
		  public broker: BrokerService,
		  public loading: LoadingService,
		  public constantes: ConstantesService
	  ) { 
		  
		 // Listado bancos		
		this.sesion.listadoBancos();

		this.pse = {
      		nuevoMetodoPago: 'PSE',
      		session: sesion.cookieValue,
			detallesDatos:{
				tipoCuenta:"A",
				numeroCuenta: null,
				codigoBanco:"0",
				tipoPersona:"N",
				nombreCompleto: sesion.user.nombre + ' ' + sesion.user.apellido,
				tipoDocumento:"CC",
				cedula: sesion.user.documento
			},
      		params: {
				transaction: {
					order: {
						description: null,
						referenceCode: this.sesion.getCookieServices('UTMC'),
						notifyUrl: null,
						buyer: {
							emailAddress: sesion.user.correo,
							shippingAddress: {
								street1: null,
								street2: null,
								city: null,
								state: null,
								country: null,
								postalCode: null,
								phone: sesion.user.phone
							}
						}
					},
					payer: {
						//fullName: sesion.user.nombre + ' ' + sesion.user.apellido,
						firstName: sesion.user.nombre,
						lastName: sesion.user.apellido,
						emailAddress: sesion.user.correo,
						dniNumber: sesion.user.documento,
						contactPhone: sesion.user.phone,
						billingAddress: {
							street1: null,
							street2: null,
							city: '11001',
							state: null,
							country: 'CO',
							postalCode: null,
							phone: sesion.user.phone,
						}
					},
					extraParameters: {
						RESPONSE_URL: environment.payULatam.notifyURL,
						PSE_REFERENCE1: null,
						FINANCIAL_INSTITUTION_CODE: '0',
						USER_TYPE: 'N',
						PSE_REFERENCE2: 'CC',
						PSE_REFERENCE3: null,
						PSE_TYPE_COUNT: 'A',
						PSE_NUMBER_COUNT: null,
					},
					paymentMethod: 'PSE',
					paymentCountry: 'CO',
					ipAddress: null,
					cookie: null,
					userAgent: navigator.userAgent
				},
				test: false,
				giftcardCode: null,
				planCode: this.sesion.planId,
				recurrency: 1
			}      
    		};
	}

	ngOnInit() {
	}

	clickPay() {
		this.tcPay = false;
		this.tcChange = true;
	}

	actualizarMetodo( datos: NgForm ) {
		 this.loading.open();
		const DataPSE = {
			"nuevoMetodoPago": 'PSE',
      		"session": this.sesion.cookieValue,
			"detallesDatos":{
				tipoCuenta: this.pse.detallesDatos.tipoCuenta,
				numeroCuenta: this.pse.detallesDatos.numeroCuenta,
				codigoBanco: this.pse.detallesDatos.codigoBanco,
				tipoPersona: this.pse.detallesDatos.tipoPersona,
				nombreCompleto: this.sesion.user.nombre + ' ' + this.sesion.user.apellido,
				tipoDocumento: this.pse.detallesDatos.tipoDocumento,
				cedula: this.pse.detallesDatos.cedula
			},
		}
		this.broker.cambiarMetodoPago(DataPSE).subscribe(result => {
			window.scrollTo( 0, 0);
			localStorage.setItem("changeMethod", "1");
			this.alertCheck = true
			//window.location.href = "/private/miscore";
			this.loading.close();
		},error => {		
			this.alertError = true;	
		});
	}

}

