import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BrokerService } from 'src/app/core/services/broker.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { SuscripcionService } from 'src/app/core/services/suscripcion.service';
import { Plan } from '../../../core/models/plan.model';
import { MetadatosService } from 'src/app/core/services/metadatos.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';

@Component({
  selector: 'app-perfil-credito',
  templateUrl: './perfil-credito.component.html',
  styleUrls: ['./perfil-credito.component.scss']
})
export class PerfilCreditoComponent implements OnInit {

  constructor(
    public suscripcion: SuscripcionService,
    public sesion: SesionService,
    public loading: LoadingService,
    public broker: BrokerService,
    public router: Router,
    private metadatosService: MetadatosService,
    private titulo: Title,
    private meta: Meta,
    private dlService: DataLayersService
  ) {
      titulo.setTitle('Reporte de crédito con Datacrédito gratis - Midatacrédito');
      meta.updateTag({name: 'description', 
        content:"Con Datacrédito gratis podrás consultar tu reporte crediticio para que accedas a productos financieros con mejores condiciones. Conoce más aquí."});
  }

  ngOnInit() {
    this.metadatosService.actualizando_canonical('https://www.midatacredito.com/landing/perfil-credito');
    this.tags_datalayer();
    localStorage.clear();
  }

  tags_datalayer(){
    // GA4 - user
    if (this.sesion.user){
      this.dlService.dl_userId( '.', this.sesion.planCurrently );
    } else {
      this.dlService.dl_userId( null, 'anonimo' );
    }

    this.dlService.dl_view_item_list(this.sesion.planes.planes.per, null, null, 'LANDING-PERFIL-CREDITO', 'Mensual');
  }

  viewPrices(item: any) {
    this.sesion.facturacion = item;
    this.cambio_de_planes(item);
  }

  cambio_de_planes(item: string){
    let plan = this.sesion.planes.planes.per;

    if ( item == '1'){
      plan.mensual[0] = this.sesion.planes.planes.per.valor[0];
      this.dlService.dl_view_item_list(plan, null, null, 'LANDING-PERFIL-CREDITO', 'Mensual');
    }
    if ( item == '3'){
      plan.mensual[0] = this.sesion.planes.planes.per.trimestral[0];
      this.dlService.dl_view_item_list(plan, null, null, 'LANDING-PERFIL-CREDITO', 'Trimestral');
    }
    if ( item == '12'){
      plan.mensual[0] = this.sesion.planes.planes.per.anual[0];
      this.dlService.dl_view_item_list(plan, null, null, 'LANDING-PERFIL-CREDITO', 'Anual');
    }
  }

  seleccionarPlan(plan: any, tipo: any, valorMensual: any, valorTotal: any) {
    this.suscripcion.planSeleccionado = plan;
    this.suscripcion.tipoPlan = tipo;
    this.suscripcion.valorSeleccionadoMensual = valorMensual;
    this.suscripcion.valorSeleccionadoTotal = valorTotal;
    this.dlService.dl_add_to_cart(plan, valorTotal, tipo, 'LANDING-PERFIL-CREDITO' );

    if (this.sesion.user) {
      this.suscripcion.planSesion(true, 'LPER');
    } else {
      this.suscripcion.planSesion(false, 'LPER');
    }
  }
}
