<section class="sidebar">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="box-plan">
					<div class="content-img">
						<img src="assets/img/pay/check-pago.svg" alt="icono check" />
					</div>
					<h3>Estas comprando:</h3>
					<p class="title">{{ planSel.nombre }}</p>
					<p>{{ planSel.periodo }}</p>
					<div class="content-total">
						<h4 *ngIf="data.descuento == null || data.descuento == 'BAD'">$ {{ data.valorPlanTotal}}</h4>
						<h4 *ngIf="data.descuento == 'OK'">$ {{ data.valorPlanTotalDescuento }}</h4>
						<p class="total">&nbsp; / ${{planSel.valorMes}} por Mes</p>
					</div>
					<hr/>
					<div id="benefits-pay" class="benefits">
						<h3>Beneficios del plan:</h3>
						<div class="bullets">
							<div class="completeBullet" *ngFor="let item of planSel.caracteristicas">
								<i class="fa fa-check" aria-hidden="true"></i>
								<p class="regular">{{item.txt}}</p>
							</div>
							<div class="completeBullet" *ngIf="planSel.plan == 'pro'">
								<p class="regular">* Tanto la póliza como la asesoría legal del Plan Premium, son servicios prestados por SBS Seguros Colombia S.A.</p>
							</div>
						</div>  
					</div>
					<div class="btn-benefits">
						<button (click)="showBenefits()">Ver beneficios del Plan<i class="fa fa-angle-down"></i></button>
					</div>				
				</div>
				<!-- <img src="assets/img/pay/img-MetodoPago.svg" alt="Método de pago" /> -->
			</div>
		</div>
	</div>
</section>
