import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BrokerService } from './broker.service';
import { LoadingService } from './loading.service';
import { SesionService } from './sesion.service';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ChangePaymentService {

	payUTcConsult: any;
	tokenConsult: any;
	methodPay: any;
	infoMethodPay: any;
	//PENDING, PENDING_PSE, INPROCESS
	alertInprocess = false;
	btnActived = true;
	btnDisabled = false;
	//parameters payu
	AccountId:string="";
	UrlWebServicePagos:string="";
	PublicKey:string="";
	public bancos = [
		{
			"id": "1007", 
			"banco": "Bancolombia", 
			"img": "assets/img/images/ayudaBancolombia.png"
		},
		{
			"id": "1051", 
			"banco": "Davivienda", 
			"img": "assets/img/images/ayudaDavivienda.png"
		},
		{
			"id": "1507", 
			"banco": "Nequi", 
			"img": "assets/img/images/ayudaNequi.png"
		},
		{
			"id": "1013", 
			"banco": "Banco BBVA", 
			"img": "assets/img/images/ayudaBbva.png"
		},
		{
			"id": "1551", 
			"banco": "Daviplata", 
			"img": "assets/img/images/ayudaDaviplata.png"
		},
		{
			"id": "1001", 
			"banco": "Banco de Bogotá", 
			"img": "assets/img/images/ayudaBancobogota.png"
		},
	];
	public bancoSel:any;

	constructor(
		public broker: BrokerService,
		public sesion: SesionService,
		public router: Router,
		public loading: LoadingService
	) { 

	}

  	consultarMetodoPago(){
		 this.loading.open();
		this.broker.consultarMetodoPago().subscribe(result => {
			this.methodPay = result;
			this.infoMethodPay = this.methodPay.response;
			this.broker.payUtcConsult = this.infoMethodPay.detallesDatos.payuToken;
			
			if(this.infoMethodPay.nuevoMetodoPago === 'PSE')	{
				this.bancoSel = this.bancos.find(ele => ele.id == this.infoMethodPay.detallesDatos.codigoBanco);
			}else {
				this.broker.loadParameters('PAY_U').subscribe(
					{
					next: (data: any) => {
						this.AccountId=data.parameters.AccountId;
						this.UrlWebServicePagos=data.parameters.UrlWebServicePagos;
						this.PublicKey=data.parameters.PublicKey;
						this.broker.consultTcPayU(this.sesion.getVariables(data.parameters.creditCards,this.sesion.validateOTPU.slice(4, -8))).subscribe(result => {
							this.payUTcConsult = result
						},error => {
		
						});
					},
					error: (error) => {
					}
					}
					
				  );
				
				
			}
			this.stateInprocess();
			this.loading.close();	
		},error => {

		});		
	}

	stateInprocess(){
		if(this.sesion.estadoUserF == 'PENDING' || this.sesion.estadoUserF == 'PENDING_PSE' || this.sesion.estadoUserF == 'INPROCESS' || this.sesion.estadoUserF == 'ACTIVE_PROCESS') {
			setTimeout(() => {
				$(".payment-type").addClass("disabled-method")
			}, 2000);
			this.alertInprocess = true;
			this.btnActived = false;
			this.btnDisabled = true;
			
		} else {
			this.alertInprocess = false;
			this.btnActived = true;
			this.btnDisabled = false;
			setTimeout(() => {
				$(".payment-type").removeClass("disabled-method")
			}, 3000);
		}
	}
}
