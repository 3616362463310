<section class="freemiumAlerts">
	<div class="container-fluid">
		<div class="row justify-content-end">
			<!-- Contenido principal. -->
			<div class="col-12">
				<div class="financial-alerts">
					<div class="main-title">
						<img src="assets/img/huellas-consulta/Huellas_de_consulta_icono.svg" alt="Huella de Consulta"/>
						<h2>Huellas de consulta a historia de crédito.</h2>
					</div>
					<div class="row" *ngIf="sesion.listHuellas == undefined">
						<div class="col-12">
							<app-micro-loader [texto]="textoMicroLoader" [error]="sesion.errorServiceCreditHistory"></app-micro-loader>
						</div>
					</div>
					<div class="main-table" *ngIf="sesion.listHuellas != undefined">
						<div class="head">
							<div class="img-head">
								<img src="assets/img/huellas-consulta/Huella_de_consulta_plataforma.svg" alt="Huella de Consulta"/>
							</div>
							<div class="title-head">
								<h3>Tus huellas de consulta</h3>
								<p>correspondientes a los últimos 6 meses</p>
							</div>							
						</div>
						<div class="cell" *ngFor="let huella of sesion.listHuellas?.totalHuellas; let i = index">
							<div class="space-blank d-none d-md-block"></div>
							<div class="consult">
								<p>{{ huella.entidad }}</p>
								<p class="date d-block d-md-none">{{ huella.fecha | date: 'dd MMMM yyyy' }}</p>
							</div>
							<div class="tooltip-consult">
								<div class="tooltip1 top">
									<i class="fa fa-exclamation-circle" aria-hidden="true" id="clicOut" (mouseover)="getEntity(huella.entidad)"></i>
									<span class="tiptext" >{{txtTootip}}</span>									
								</div>
								<div class="tooltip1 top info" >
									<span class="tiptext info" id="contenTooltip" >Aquí encontrarás información importante sobre tus huellas de consulta</span>
								</div>
							</div>
							<div class="date d-none d-md-block">
								<p>{{ huella.fecha | date: 'dd MMMM yyyy' }}</p>
							</div>
							<div class="number-consult">
								<p>{{ huella.cantidad | number }}</p>
							</div>
						</div>
					</div>
					<div class="warnning">
						<img src="assets/img/huellas-consulta/Alerta_huellas.svg" alt="alerta de huellas de consulta" />
						<p><span>NOTA:</span> La fecha y el contador, corresponden a la fecha de la última consulta y la cantidad de consultas realizadas respectivamente.</p>
					</div>
					<div *ngIf="sesion.viewFree" id='div-gpt-ad-1694399422378-0' class="commercial-banner" [ngClass]="checkBannerFreeHuellas()"></div>
					<div class="txts-alerts">
						<h4>¿Qué son las huellas de consulta?</h4>
						<p>Una huella de consulta es un registro que se guarda en tu historia crediticia siempre que haya una consulta a la Historia de Crédito.</p>
						<h4>¿Afectan mi puntaje?</h4>
						<p>NO, estos registros <span>no afectan negativamente el puntaje</span>.</p>
						<h4>¿Cuánto tiempo duran?</h4>
						<p>Suelen tener una duración de seis meses a partir de que se genera la consulta.</p>
					</div>
					<div class="dude">
						<div class="img-dude">
							<img src="assets/img/huellas-consulta/Bombillo_SAC.svg" alt="Bombillo SAC"/>
						</div>
						<div class="txt-dude">
							<p>¿Tienes una duda, inquietud o sugerencia?</p>
							<p class="contact">Comunícate con nosotros por medio de nuestros <a (click)="linkSAC();">Canales de Atención al Usuario</a>.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>