import { Injectable } from '@angular/core';
import { SesionService } from '../services/sesion.service';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PayGuardGuard implements CanActivate {
  constructor(public sesion: SesionService, public router:Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Observable<boolean>(obs => {
        switch (state.url) {
          case '/private/diagnostico':
          case '/private/mi-deuda':
          case '/private/mi-proximo-credito':
          case '/private/historico-score':
          case '/private/mi-proximo-credito?utm_source=midatacredito&utm_medium=menu-principal&utm_campaign=entrenador-simulador':
          case '/private/simulador':
            if(this.sesion.monitoreo){
              obs.next(true);
            }else{
              this.router.navigate(['/']);
              obs.next(false);
            }
            break;
          case '/private/mi-presencia':
           if(this.sesion.proteccion){
              obs.next(true);
            }else{
              this.router.navigate(['/']);
              obs.next(false);
            }
            break;
          case '/private/poliza-premium':
           if(this.sesion.poliza){
              obs.next(true);
            }else{
              this.router.navigate(['/']);
              obs.next(false);
            }
            break;
          default:
            this.router.navigate(['/']);
            obs.next(false);
        }
      });
  }
  
}
