import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { BrokerService } from 'src/app/core/services/broker.service';
import { ChangePaymentService } from 'src/app/core/services/change-payment.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { environment } from 'src/environments/environment';

declare var $: any;
declare var payU: any;

interface TarjetaCredito {
	nuevoMetodoPago: string, 
  	session: any;
	detallesDatos:{
		franquicia: any,
		payuToken: any,
		nombreCompleto: any,
		cedula: any
	},
	params: {
		transaction: {
			order: {
				referenceCode: any;
				description: any;
				buyer: {
					shippingAddress: {
						street1: any;
						street2: any;
						city: any;
						state: any;
						country: any;
						postalCode: any;
						phone: any;
					}
				}
			},
			payer: {
				merchantPayerId: any;
				fullName: any;
				firstName:any;
				lastName: any;
				dniNumber: any;
				billingAddress: {
					street1: any;
					street2: any;
					city: any;
					state: any;
					country: any;
					postalCode: any;
					phone: any;
				}
			},
			creditCardTokenId: any;
			extraParameters: {
			INSTALLMENTS_NUMBER: number;
			},
			paymentMethod: any;
			paymentCountry: any;
			deviceSessionId: any;
			ipAddress: any;
			cookie: any;
			userAgent: any;
		},
		test: any;
		giftcardCode: any;
		planCode: any;
		recurrency: any;
	};
}

@Component({
  selector: 'app-tc-change',
  templateUrl: './tc-change.component.html',
  styleUrls: ['./tc-change.component.scss']
})
export class TcChangeComponent implements OnInit {
	card: any;
	tarjetaValida = true;
	tarjetaCredito: TarjetaCredito;
	alertCheck = false;
	alertError = false;
	errorPayU:boolean=false;

	constructor(
		public sesion: SesionService, 
		public broker: BrokerService,
		public loading: LoadingService,
		public changeMethod: ChangePaymentService
		) { 

		this.tarjetaCredito = {
			nuevoMetodoPago:"TC", 
			session: sesion.cookieValue,
			detallesDatos:{
				franquicia:"TC",
				payuToken:null,
				nombreCompleto: sesion.user.nombre + ' ' + sesion.user.apellido,
				cedula:sesion.user.documento
			},
			params: {
				transaction: {
					order: {
						description: null,
						referenceCode: this.sesion.getCookieServices('UTMC'),
						buyer: {
							shippingAddress: {
								street1: null,
								street2: null,
								city: null,
								state: null,
								country: null,
								postalCode: null,
								phone: sesion.user.phone
							}
						}
					},
					payer: {
						merchantPayerId: '1',
						fullName: sesion.user.nombre + ' ' + sesion.user.apellido,
						firstName: sesion.user.nombre,
						lastName: sesion.user.apellido,
						dniNumber: sesion.user.documento,
						billingAddress: {
							street1: null,
							street2: null,
							city: '11001',
							state: null,
							country: 'CO',
							postalCode: null,
							phone: sesion.user.phone,
						}
					},
					creditCardTokenId: null,
					extraParameters: {
						INSTALLMENTS_NUMBER: 1
					},
					paymentMethod: 'TC',
					paymentCountry: 'CO',
					deviceSessionId: null,
					ipAddress: null,
					cookie: null,
					userAgent: navigator.userAgent
				},
				test: environment.payULatam.TestPayU,
				giftcardCode: null,
				planCode: this.sesion.planId,
				recurrency: 1
			}
		};
		
		this.loadParameters();

		this.card =  {
			number: null,
			name_card: null,
			payer_id: null,
			exp_month: null,
			exp_year: null,
			method: null,
			cvv: null
		};
	}

  

	ngOnInit() {		
		const model = this;
		$('#cardnumber').on('keyup', () => {
			const value = $('#cardnumber').val().trim();
			payU.validateCard(value);
			const cardType = model.getCardType(value);
			if (!cardType) {
				model.card.method = null;
				model.tarjetaValida = false;
			} else {
				model.tarjetaCredito.detallesDatos.franquicia = cardType;
				model.card.method = cardType;
				model.tarjetaValida = true;
			}
		});
	}

	lazyLoadQuill(): Observable<any> {
		return forkJoin([
			this.sesion.loadScript('https://gateway.payulatam.com/ppp-web-gateway/javascript/PayU.js')
		]);
	}

	getCardType(cardNo:any) {
		const cards:any = {
			AMERICANEXPRESS: /^3[47][0-9]{13}$/,
			MASTERCARD: /^5[1-5][0-9]{14}$/,
			// VISA: /^(4)(\\d{12}|\\d{15})$|^(606374\\d{10}$)/,
			VISA: /^4[0-9]{12}(?:[0-9]{3})?$/,
			DINERSCLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/
		};

			for (const card in cards) {
				if (cards[card].test(cardNo)) {
					return card;
				}
    			}

    		return undefined;
  	}

	generateArrayOfYears() {
		var max = new Date().getFullYear()
		var min = max + 10
		var years = []
		for (var i = max; i <= min; i++) {
			years.push(i)
		}
    		return years
  	}

  	generateArrayOfMonths() {
    	var month = []
		for (var i = 1; i < 13; i++) {
			month.push(i)
		}
    		return month
  	}

	createToken() {
		payU.setCardDetails(this.card);
		payU.createToken(this.responseHandler);
	}

	responseHandler(response:any) {
		if (response.error) {
			$('#creditCardTokenId').val(null);
			// Se muestra los mensajes de error.
			$('.create-errors').text(response.error);
		} else {
			// Se obtiene el token y se puede guardar o enviarlo para algún pago.
			const token = response.token;
			const payer_id = response.payer_id;
			const document = response.document;
			const name = response.name;

			const input = $('#creditCardTokenId');
			input.val(response.token);

			$('#actualizarTC').click();
		}
	}
	
	actualizarMetodo( datos: NgForm ) {	
		this.tarjetaCredito.detallesDatos.payuToken = $('#creditCardTokenId').val();
        	this.card.name_card = this.tarjetaCredito.detallesDatos.nombreCompleto;
        	this.card.payer_id = this.tarjetaCredito.detallesDatos.cedula;
		if ( !this.tarjetaCredito.detallesDatos.payuToken ) {
      		this.createToken();
		} else {	
			this.loading.open();		
			const nuevoMetodoPago = "TC";
			const session = this.sesion.cookieValue;
			const franquicia = this.tarjetaCredito.detallesDatos.franquicia;
			const payuToken = this.tarjetaCredito.detallesDatos.payuToken;
			const nombreCompleto = this.tarjetaCredito.detallesDatos.nombreCompleto;
			const cedula = this.tarjetaCredito.detallesDatos.cedula;
			const DataTC = {
				"nuevoMetodoPago": nuevoMetodoPago, 
				"session": session,
				"detallesDatos":{
					franquicia: franquicia,
					payuToken: payuToken,
					nombreCompleto: nombreCompleto,
					cedula: cedula
				},
			}		
			this.broker.cambiarMetodoPago(DataTC).subscribe(result => {
				window.scrollTo( 0, 0);
				localStorage.setItem("changeMethod", "1");
				this.alertCheck = true
				//window.location.href = "/private/miscore";
				this.loading.close();	
			},error => {
				this.alertError = true;
			});	
		}
		
	}  

	

	loadParameters(){
		this.broker.loadParameters('PAY_U').subscribe(
		  {
		  next: (data: any) => {
			
			this.lazyLoadQuill().subscribe(_ => {
			  payU.setURL(this.sesion.getVariables(data.parameters.UrlWebServicePagos,this.sesion.validateOTPU.slice(4, -8)));
			  payU.setPublicKey(this.sesion.getVariables(data.parameters.PublicKey,this.sesion.validateOTPU.slice(4, -8)));
			  payU.setAccountID(this.sesion.getVariables(data.parameters.AccountId,this.sesion.validateOTPU.slice(4, -8)));
			  payU.setListBoxID('mdc-list-cards');
			  payU.setLanguage('es');
			  payU.getPaymentMethods();
			});
		  },
		  error: (error) => {
			
  			this.errorPayU=true;
		  }
		  }
		  
		);
	}
}
