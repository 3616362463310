import { Component, OnInit, ElementRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
declare function enviarDatosUserChatLayer(): void

@Component({
    selector: 'app-chat-datina',
    templateUrl: './chat-datina.component.html',
    styleUrls: ['./chat-datina.component.scss']
})
export class ChatDatinaComponent implements OnInit {

    url_politica_privacidad = environment.enlaceS3 + environment.s3.politicaDePrivacidad;
    url_terminos_condiciones = environment.enlaceS3 + environment.s3.terminosYCondiciones;

    constructor(public elementRef: ElementRef, private dataLayerService: DataLayersService ) {
        this.addDatinaScripts();
    }

    ngOnInit(): void { }

    removeDatina() {
        return "";
    }

    addDatinaScripts() {
        var link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        document.head.appendChild(link);
        link.href = environment.styleDatina;

        const datina = document.createElement('script');
        datina.id = "datinaChatS";
        datina.type = 'text/javascript';
        datina.src = environment.urlChatDatina;
        // document.body.appendChild(datina);
        this.elementRef.nativeElement.appendChild(datina);

        // setTimeout(() => {
        //     const datinaUrl = document.createElement('script');
        //     datinaUrl.id = "datinaChatU";
        //     datinaUrl.type = 'text/javascript';
        //     datinaUrl.src = 'https://chatbox.prod.europe-west1.gc.chatlayer.ai/sdk/60fac38cb8cc2dc5d99709db';
        //     datinaUrl.setAttribute("referrerpolicy", "no-referrer-when-downgrade");
        //     datinaUrl.setAttribute("onload", "initChatlayerClientes()");
        //     datinaUrl.setAttribute("async", "");
        //     this.elementRef.nativeElement.appendChild(datinaUrl);
        // }, 1000);
    }

    // EVENTO GA4 start_chat
    start_chat(){
        this.dataLayerService.dl_eventoGA4_1parametro('start_chat');
        enviarDatosUserChatLayer();
    }

}
