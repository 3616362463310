import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ModalService } from 'src/app/core/services/modal.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  home: boolean = false;
  constructor(public sesion: SesionService, public router: Router, public modal: ModalService, public utilities: UtilitiesService) {
    this.router.events.subscribe((val) => {
      // this.utilities.getUtmParams();
      if (val instanceof NavigationEnd) {
        this.home = false;
        (this.router.url.includes("/private/home") || this.router.url.includes("/freemium/home") || this.router.url == "/prueba293487") ? this.home = true : this.home = false;
      }
    })
  }

  ngOnInit() {


  }

}
