import { Component, OnInit } from '@angular/core';
import { Chart, ChartConfiguration, ChartItem, registerables } from 'node_modules/chart.js'
import { BrokerService } from 'src/app/core/services/broker.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { SesionService } from 'src/app/core/services/sesion.service';
declare var $: any;
@Component({
  selector: 'app-historico-score',
  templateUrl: './historico-score.component.html',
  styleUrls: ['./historico-score.component.scss']
})
export class HistoricoScoreComponent implements OnInit {
  public loadHisoryScore = false;
  public errorHistoryScore = false;
  public textoMicroLoader = 'Por favor espera un momento, estamos cargando tu información.';
  public textErrorMicroLoader = 'En este momento estamos presentando problemas. Por favor inténtalo más tarde.';
  public labelsGraph: any = [];
  public dataOthers: any = [];
  public dataMy: any = [];
  public chart: any;
  public dataService: any;
  public yearSelected: any;
  public monthSelUser: any;
  public monthSelAll: any;
  public readyData: boolean = true;

  constructor(public broker: BrokerService, private sesion: SesionService, private dataLayerService: DataLayersService ) { }

  ngOnInit(): void {
    // GA4 - User
    this.dataLayerService.dl_userId( '.', this.sesion.planCurrently );
    this.getHistoryScore();
  }

  createChart(): void {
    Chart.register(...registerables);
    const data = {
      labels: this.labelsGraph,
      datasets: [{
        label: 'Mi Puntaje',
        backgroundColor: '#BA2F7D',
        borderColor: '#BA2F7D',
        pointBackgroundColor: '#BA2F7D',
        pointBorderColor: '#BA2F7D',
        hoverBackgroundColor: '#BA2F7D',
        data: this.dataMy,
      },
      {
        label: 'Puntaje de los colombianos',
        backgroundColor: '#632678',
        borderColor: '#632678',
        pointBackgroundColor: '#632678',
        pointBorderColor: '#632678',
        hoverBackgroundColor: '#632678',
        data: this.dataOthers,
      }]
    };
    const options: any = {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom'
        }
      }
    }
    const config: ChartConfiguration = {
      type: 'bar',
      data: data,
      options: options
    }
    const chartItem: ChartItem = document.getElementById('my-chart') as ChartItem;
    this.chart = new Chart(chartItem, config)
  }

  getHistoryScore() {
    this.loadHisoryScore = false;
    this.errorHistoryScore = false;
    this.dataService = {};
    this.broker.getHistoryScore().subscribe(
      {
        next: (result: any) => {
          this.dataService = this.calibrateData(result);
          if (this.dataService.userHistory == null) {
            this.errorHistoryScore = true;
          } else {
            this.readyData = (this.dataService.userHistory.length > 0);
            if (this.readyData) {

              this.dataService.userHistory.sort((a: any, b: any) => {
                return a.year - b.year;
              });

              this.dataService.userHistory.forEach((element: any) => {
                element.yearScores.sort((a: any, b: any) => {
                  return a.month - b.month
                })
              });

              this.dataService.averageAllScores.sort((a: any, b: any) => {
                return a.year - b.year;
              });

              this.dataService.averageAllScores.forEach((element: any) => {
                element.yearScores.sort((a: any, b: any) => {
                  return a.month - b.month
                })
              });

              this.yearSelected = 0;
              setTimeout(() => {
                this.createChart();
                this.changeDataGraph(this.yearSelected, $("#btn-graph-0")[0])
              }, 100);
            }
          }
          this.loadHisoryScore = true;
        }, error: (result: any) => {
          this.errorHistoryScore = true;
        }
      });
  }

  changeDataGraph(year: any, event: any) {
    $(event).parent().parent().children().children().removeClass("active");
    $(event).addClass("active");
    $(".months-graph")[0].scrollLeft = 0
    this.labelsGraph = [];
    this.dataOthers = [];
    this.dataMy = [];

    this.dataService.userHistory[year].yearScores.slice(0, 6).forEach((element: any) => {
      this.labelsGraph.push(this.convertMonth(element.month));
      this.dataMy.push(element.score);
    });

    this.dataService.averageAllScores[year].yearScores.slice(0, 6).forEach((element: any) => {
      this.dataOthers.push(element.score);
    });

    this.chart.destroy();
    this.createChart();
    this.yearSelected = year;
    let minMonth = this.dataService.userHistory[this.yearSelected].yearScores[0].month;
    this.selMountGraph(minMonth, $("#btn-month-0")[0]);
  };

  moreDataGraph() {
    this.labelsGraph = [];
    this.dataOthers = [];
    this.dataMy = [];

    this.dataService.userHistory[this.yearSelected].yearScores.slice(0, 12).forEach((element: any) => {
      this.labelsGraph.push(this.convertMonth(element.month));
      this.dataMy.push(element.score);
    });

    this.dataService.averageAllScores[this.yearSelected].yearScores.slice(0, 12).forEach((element: any) => {
      this.dataOthers.push(element.score);
    });

    this.chart.destroy();
    this.createChart();
  }

  selMountGraph(month: any, target: any) {
    $(target).parent().parent().children().children("button").removeClass("active");
    $(target).addClass("active");

    this.monthSelUser = this.dataService.userHistory[this.yearSelected].yearScores.find((ele: any) => ele.month == month);
    this.monthSelAll = this.dataService.averageAllScores[this.yearSelected].yearScores.find((ele: any) => ele.month == month);
    let dataMyPercent = 0;
    let dataOthersPercent = 0;
    if (this.monthSelUser != undefined) {
      dataMyPercent = Math.trunc((this.monthSelUser.score * 100) / 950);
    }
    if (this.monthSelAll != undefined) {
      dataOthersPercent = Math.trunc((this.monthSelAll.score * 100) / 950);
    }
    $(".my-hscore").css({ 'background': 'linear-gradient(to top, #BA2F7D ' + dataMyPercent + '%, white ' + (dataMyPercent - 100) + '%)' });
    $(".other-hscore").css({ 'background': 'linear-gradient(to top, #632678 ' + dataOthersPercent + '%, white ' + (dataOthersPercent - 100) + '%)' });
  }

  convertMonth(num: any) {
    let months: any = {
      "1": "Enero",
      "2": "Febrero",
      "3": "Marzo",
      "4": "Abril",
      "5": "Mayo",
      "6": "Junio",
      "7": "Julio",
      "8": "Agosto",
      "9": "Septiembre",
      "10": "Octubre",
      "11": "Noviembre",
      "12": "Diciembre"
    }
    if (num == undefined) {
      return "Enero";
    }
    return months[num];
  }

  calibrateData(data: any) {
    let calibratedData: any = {
      averageAllScores: [],
      userHistory: []
    }
    let currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    let maxMonths = 0;
    data.userHistory.forEach((element:any) => {
      maxMonths = maxMonths + element.yearScores.length;
    });
    if(maxMonths > 24){
      maxMonths = 24;
    }
    for (let index = 0; index < maxMonths; index++) {
      //Ajusta la data para la data de del usuario
      let yearFind = data.userHistory.find((ele: any) => ele.year == currentDate.getFullYear());
      if (yearFind == undefined) {        
        let yearCalibrated = calibratedData.userHistory.find((ele: any) => ele.year == currentDate.getFullYear());
        if(yearCalibrated == undefined){
          calibratedData.userHistory.push({ average: 0, year: currentDate.getFullYear(), yearScores: [] });
        }
        calibratedData.userHistory.find((ele: any) => ele.year == currentDate.getFullYear()).yearScores.push({ score: 0, month : (currentDate.getMonth() + 1) });
      } else {
        let yearCalibrated = calibratedData.userHistory.find((ele: any) => ele.year == yearFind.year);
        if (yearCalibrated == undefined) {
          calibratedData.userHistory.push({ average: yearFind.average, year: yearFind.year, yearScores: [] });
        }
        let monthCalibrated = yearFind.yearScores.find((ele: any) => ele.month == (currentDate.getMonth() + 1));
        if (monthCalibrated == undefined) {
          calibratedData.userHistory.find((ele: any) => ele.year == yearFind.year).yearScores.push({ score: 0, month : (currentDate.getMonth() + 1) });
        }
        else{
          calibratedData.userHistory.find((ele: any) => ele.year == yearFind.year).yearScores.push({ score: monthCalibrated.score, month : (currentDate.getMonth() + 1) });
        }
      }
      //Ajusta la data para la data de todos los ciudadanos
      let yearFindAll = data.averageAllScores.find((ele: any) => ele.year == currentDate.getFullYear());
      if (yearFindAll == undefined) {
        calibratedData.averageAllScores.push({ average: 0, year: currentDate.getFullYear(), yearScores: [] });
        let yearCalibrated = calibratedData.averageAllScores.find((ele: any) => ele.year == currentDate.getFullYear());
        if(yearCalibrated == undefined){
          calibratedData.averageAllScores.push({ average: 0, year: currentDate.getFullYear(), yearScores: [] });
        }
        calibratedData.averageAllScores.find((ele: any) => ele.year == currentDate.getFullYear()).yearScores.push({ score: 0, month : (currentDate.getMonth() + 1) });
      } else {
        let yearCalibrated = calibratedData.averageAllScores.find((ele: any) => ele.year == yearFindAll.year);
        if (yearCalibrated == undefined) {
          calibratedData.averageAllScores.push({ average: yearFindAll.average, year: yearFindAll.year, yearScores: [] });
        }
        let monthCalibrated = yearFindAll.yearScores.find((ele: any) => ele.month == (currentDate.getMonth() + 1));
        if (monthCalibrated == undefined) {
          calibratedData.averageAllScores.find((ele: any) => ele.year == yearFindAll.year).yearScores.push({ score: 0, month : (currentDate.getMonth() + 1) });
        }
        else{
          calibratedData.averageAllScores.find((ele: any) => ele.year == yearFindAll.year).yearScores.push({ score: monthCalibrated.score, month : (currentDate.getMonth() + 1) });
        }

      }
      currentDate.setMonth(currentDate.getMonth() - 1);
    }
    return calibratedData;
  }

}