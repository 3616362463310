<app-micro-loader [error]="true" *ngIf="errorPayU">
</app-micro-loader>
<section class="method-pay"  *ngIf="!errorPayU">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-8">
                <div>
					<div class="alertCheck check" *ngIf="alertCheck">
						<div class="alertMessage">
							<img src="assets/img/upselling/ok.svg" class="iconCheck" alt="ok">
							<div class="txtMessage">
								<h3>Tu Cambio de método de pago ha sido exitoso</h3>
							</div>							
						</div>
					</div>
                    <div class="alertCheck error" *ngIf="alertError">
						<div class="alertMessage">
							<img src="assets/img/upselling/bad.svg" class="iconCheck" alt="bad">
							<div class="txtMessage">
								<h3>Tu Cambio de método de pago no ha sido exitoso. Intentalo de nuevo</h3>
							</div>							
						</div>
					</div>
				</div>
				<h3>Datos de la tarjeta</h3>
				<form class="pt-3 pb-3">
                    <div class="row d-none">
                        <div class="col-12 col-md-6 pl-md-1 pl-auto text-center">
                            <div class="row">
                                <div class="col-12 px-md-5 px-1">
                                    <div id="mdc-list-cards" class="d-none"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box-info">              
                        <form id="pagoTC" #datos="ngForm" (ngSubmit)="actualizarMetodo(datos)" autocomplete="off">                        
                            <input type="hidden" #method="ngModel" [(ngModel)]="card.method" [ngModelOptions]="{standalone: true}">
                            <input type="hidden" id="creditCardTokenId" name="creditCardTokenId" #token="ngModel" [(ngModel)]="tarjetaCredito.detallesDatos.payuToken">
                            <div class="box-step">
                                <div class="form-row">
                                    <div class="form-group col-12 col-md-6" id="titularname_div">
                                        <mat-form-field>
                                            <input matInput type="text" name="fullName" class="form-control" id="fullName"
                                                [(ngModel)]="tarjetaCredito.detallesDatos.nombreCompleto"
                                                #fullName="ngModel" pattern="^[A-Za-zÑñáéíóúÁÉÍÓÚ ]+$" 
                                                placeholder="Nombre(s) de tarjeta habiente"
                                                [ngClass]="{'is-valid': fullName.valid, 'is-invalid': ( (fullName.errors?.['required']  ) && fullName.touched ) }"
                                                required="required">
                                                <mat-error *ngIf="fullName?.errors?.['required']" class="invalid-feedback">Campo obligatorio.</mat-error>
                                                <mat-error *ngIf="fullName?.errors?.['pattern']" class="invalid-feedback">Nombre no válido. Ingresar solo letras.</mat-error>  
                                        </mat-form-field>                                        
                                    </div>
                                    <div class="form-group col-12 col-md-6" id="cardnumber_div">
                                        <mat-form-field>
                                            <input matInput type="tel" placeholder="Número de tarjeta" name="cardnumber"
                                                class="form-control" id="cardnumber" pattern="^[+]?([0-9]+(?:[0-9]*)?|[0-9]+)$"
                                                [(ngModel)]="card.number" maxlength="16" #cardnumber="ngModel" 
                                                [ngClass]="{'is-valid': (cardnumber.valid && tarjetaValida), 'is-invalid': (( (cardnumber.errors?.['required'] ) && cardnumber.touched ) || !tarjetaValida) }"
                                                required="required">
                                                <img src="assets/img/pay/L-Visa.svg" alt="VISA" class="card-bank" [ngClass]="{'d-none': !card.method || card.method != 'VISA', 'card-bank':  card.method === 'VISA' }"/>
                                                <img src="assets/img/pay/L-MasterCard.svg" alt="MASTERCARD" class="card-bank" [ngClass]="{'d-none': !card.method || card.method != 'MASTERCARD', 'card-bank':  card.method === 'MASTERCARD' }"/>
                                                <img src="assets/img/pay/L-AmericanExpress.svg" alt="AMERICAN EXPRESS" class="card-bank" [ngClass]="{'d-none': !card.method || card.method != 'AMERICANEXPRESS', 'card-bank':  card.method === 'AMERICANEXPRESS' }"/>
                                                <img src="assets/img/pay/L-DinersClub.svg" alt="DINERSCLUB" class="card-bank" [ngClass]="{'d-none': !card.method || card.method != 'DINERSCLUB', 'card-bank':  card.method === 'DINERSCLUB' }"/>
                                                <mat-error *ngIf="cardnumber?.errors?.['required']" class="invalid-feedback">Campo obligatorio.</mat-error>
                                                <mat-error *ngIf="cardnumber?.errors?.['pattern']" class="invalid-feedback">Número de tarjeta no válido. Ingresar solo números.</mat-error>
                                                <mat-error *ngIf="!tarjetaValida && !cardnumber?.errors?.['pattern'] && !cardnumber?.errors?.['required']" class="invalid-feedback customError">Número de tarjeta no válido.</mat-error>   
                                        </mat-form-field>                                        
                                    </div>
                                </div> 
                                <div class="form-row">
                                    <h4 class="col-12 col-md-6">Fecha de vencimiento</h4>
                                    <h4 class="col-12 col-md-6 d-none d-md-inline-block">Código de seguridad</h4>
                                    <div class="form-group col-6 col-md-3" id="month_exp_div">
                                        <mat-form-field class="w-100">
                                            <mat-select 
                                                placeholder="Mes" id="expiryMonth" name="expiryMonth" class="form-control"
                                                [(ngModel)]="card.exp_month" #expiryMonth="ngModel" required="required">
                                                <mat-option *ngFor="let m of generateArrayOfMonths()" [value]="m">{{ m }}</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="expiryMonth?.errors?.['required']" class="invalid-feedback">Campo obligatorio.</mat-error>
                                        </mat-form-field>                                        
                                    </div>
                                    <div class="form-group col-6 col-md-3" id="year_exp_div">
                                        <mat-form-field>
                                            <mat-select 
                                                placeholder="Año" id="expiryYear" name="expiryYear" class="form-control"
                                                [(ngModel)]="card.exp_year" #expiryYear="ngModel" required="required">
                                                <mat-option *ngFor="let y of generateArrayOfYears()" [value]="y">{{ y }}</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="expiryYear?.errors?.['required']" class="invalid-feedback">Campo obligatorio.</mat-error>
                                        </mat-form-field>                                        
                                    </div>
                                    <h4 class="col-12 col-md-6 d-block d-md-none">Código de seguridad</h4>
                                    <div class="form-group col-12 col-md-6" id="security_code_div">
                                        <mat-form-field>
                                            <input matInput type="tel" name="security_code" class="form-control" id="security_code"
                                                #securityCode="ngModel" pattern="^[+]?([0-9]+(?:[0-9]*)?|[0-9]+)$"
                                                [(ngModel)]="card.cvv" placeholder="CW/CVC" maxlength="4" required="required"
                                                [ngClass]="{'is-valid': securityCode.valid, 'is-invalid': ( securityCode.errors?.['required'] && securityCode.touched ) }">                                            
                                            <a data-toggle="modal" data-target="#cvv" data-placement="top">
                                                <img src="assets/img/icons/icon-question-purple.svg" class="cvv" alt="cvv"/>
                                            </a>
                                            <mat-error *ngIf="securityCode?.errors?.['required']" class="invalid-feedback"> Campo obligatorio. </mat-error>
                                            <mat-error *ngIf="securityCode?.errors?.['pattern']" class="invalid-feedback">Código de seguridad no válido. Ingresar solo números. Ej: 123</mat-error>    
                                        </mat-form-field>                                        
                                    </div>
                                </div>
                                <div class="note">
                                    <!-- <p>* Para acceder a este plan requieres de una tarjeta de crédito válida conforme lo establecen los términos y condiciones. Validaremos tu tarjeta de crédito con una transacción de $200 pesos que luego te serán reembolsados. </p> -->
                                    <p>Al hacer clic en Actualizar aceptas que nuestros <a data-toggle="modal" data-target="#dialog-message" data-placement="top"  id="opener"> Términos y Condiciones</a>. Recuerda que podrás cancelar tu membresía en cualquier momento.</p>
                                </div> 
								<div class="form-row" id="btnPay"> 
                                    <div class="form-group col-12 col-md-6">
                                        <div class="create-errors alert-danger col-12 text-center"></div>
                                        <button 
                                            [attr.disabled]="datos.valid && tarjetaValida ? null : 'disabled'" type="submit"
                                            [ngClass]="{'disabled': !datos.valid && !tarjetaValida }" name="validar" 
                                            id="actualizarTC" webActivity data-idtag="actualizarMetodoPago" data-nametag="actualizarTC"
                                            class="btn-midc btn-orange2 w-100-btn h-lg-btn">
                                            Actualizar método
                                            <i class="fa fa-chevron-right"></i>
                                        </button>  
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </form>
			</div>
		</div>
	</div>
</section>
