<div class="col-12">
    <div class="row info-top">
        <div class="col-12">
            <div class="head-title">
                <img src="assets/img/Icono_historico_score.svg" title="Icono Hisotirco Score"
                    alt="Icono Hisotirco Score" />
                <div class="texts-title">
                    <h2>¿Quieres conocer el histórico de tu Puntaje de Crédito?</h2>
                    <p class="d-none d-md-block">Conoce como fue el comportamiento de tu puntaje crediticio mes a mes en
                        los últimos 2 años</p>
                </div>
                <p class="d-block d-md-none">Conoce como fue el comportamiento de tu puntaje crediticio mes a mes en los
                    últimos 2 años</p>
            </div>
            <div class="body-title">
                <p>En este espacio encontrarás tu puntaje de Crédito de los últimos dos años, así como el
                    puntaje promedio de los colombianos el cual podrás comparar con el tuyo. Toma el control de tu vida
                    financiera.</p>
                <!-- <p class="pie">*El puntaje promedio de los colombianos hace referencia al puntaje promedio de los
                    usuarios activos en Midatacrédito, y se toma como una muestra representativa del puntaje total de
                    los colombianos.</p> -->
            </div>
        </div>
    </div>
    <hr />
    <div class="row" *ngIf="!loadHisoryScore">
        <div class="col-12">
            <app-micro-loader [texto]="textoMicroLoader" [error]="errorHistoryScore"
                [textoError]="textErrorMicroLoader">
            </app-micro-loader>
        </div>
    </div>
    <div class="row waite-score" *ngIf="loadHisoryScore && !readyData" >
        <div class="col-12">
            <p>Estamos consolidando toda tu información. Esto podría tardar hasta un día...</p>
        </div>
        <div class="col-12">
            <img src="assets/img/Historico_carga_icono.svg" alt="Carga de informacion" title="Carga de informacion" />
        </div>
    </div>
    <div class="row info-hscore" *ngIf="loadHisoryScore && readyData">
        <div class="col-12">
            <div class="info-title">
                <p><b>Selecciona el año</b> para conocer tu información</p>
            </div>
            <div class="btn-years">
                <div *ngFor="let data of dataService.userHistory; let i = index;">
                    <button id="{{'btn-graph-'+i}}"
                        (click)="changeDataGraph(i,$event.target)">{{data.year}}</button>
                </div>
            </div>
            <div class="graph-mobile d-block d-md-none">
                <div class="months-graph">
                    <div *ngFor="let data of dataService.userHistory[yearSelected].yearScores;let i = index">
                        <button *ngIf="i==0" class="active" id="{{'btn-month-'+i}}"
                            (click)="selMountGraph(data.month,$event.target)">{{convertMonth(data.month).slice(0,3)}}</button>
                        <button *ngIf="i!=0" id="{{'btn-month-'+i}}"
                            (click)="selMountGraph(data.month,$event.target)">{{convertMonth(data.month).slice(0,3)}}</button>
                        <div *ngIf="dataService.userHistory[yearSelected].yearScores.length-1 != i"
                            class="line"></div>
                    </div>
                </div>
                <div class="hscore-mobile">
                    <div class="text-hscore">
                        <p>Puntaje</p>
                        <p class="month-hscore">{{convertMonth(monthSelUser?.month)}}</p>
                        <hr />
                        <p class="num-hscore">{{monthSelUser?.score}}</p>
                    </div>
                    <div class="bars-hscore">
                        <div class="my-hscore"></div>
                        <div class="other-hscore"></div>
                    </div>
                    <div class="info-hscore-all">
                        <p>Puntaje de los Colombianos: <b>{{monthSelAll?.score}}</b></p>
                    </div>
                </div>
                <div class="info-hscore-mobile">
                    <div class="c-hscore">
                        <div class="c-other"></div>
                        <p>Puntaje de los colombianos</p>
                    </div>
                    <div class="c-hscore">
                        <div class="c-my"></div>
                        <p>Mi puntaje</p>
                    </div>
                </div>                
            </div>
            <div class="graph d-none d-md-block">
                <div class="chart-container">
                    <canvas id="my-chart">
                    </canvas>
                </div>
                <div class="more-hscore">
                    <p>Ver más</p>
                    <button (click)="moreDataGraph()"><i class="fa fa-arrow-right"></i></button>
                </div>
            </div>
            <div class="text-prom">
                <h2>Tu Puntaje promedio en el <b>{{dataService.userHistory[yearSelected].year}}</b> fue:</h2>
                <p>{{dataService.userHistory[yearSelected].average}}</p>
            </div>
        </div>
    </div>
</div>