<div class="generalF">
    <div class="header-feria">
        <div class="div-banner">
            <img class="pieza-midc" src="assets/img/landings/feria/Titulo_medellin.svg" alt="Feria Midatacrédito">
            <img class="pieza-women" src="assets/img/landings/feria/IMAGEN-8.png" alt="Chica Midatacrédito">
        </div>
        <div class="headerMobile d-block d-md-none">
            <img class="pieza-midc" src="assets/img/landings/feria/Titulo_medellin.svg" alt="Feria Midatacrédito">
            <img class="pieza-women" src="assets/img/landings/feria/IMAGEN-8.png" alt="Chica Midatacrédito">
            <div class="clockMobile">
                <div class="clockItems">
                    <div class="segment">
                        <p><span>{{day <= 0? '0':day}}</span> <br>Días</p>
                    </div>
                    <div class="segment">
                        <p><span>{{hours <= 0? '0':hours}}</span> <br>Horas</p>
                    </div>
                    <div class="segment">
                        <p><span>{{minutes <= 0? '0':minutes}}</span> <br>Minutos</p>
                    </div>
                    <div class="segment">
                        <p><span>{{seconds <= 0? '0':seconds}}</span> <br>Segundos</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="principal">
        <p class="principalTXT">En la feria <b>Midatacrédito - Medellín 2024,</b> seguimos en la misión de construir país a través de la educación financiera de miles de colombianos. Siempre <span>#ATuFavor</span><br><br>¿Qué vas a poder hacer?</p>
        <div class="bubbles">
            <div class="bubble"><img  src="assets/img/landings/feria/Negociacion_deudas_icono.svg" alt="Negociación de deudas"><p><b>NEGOCIACIÓN</b> de deudas.</p></div>
            <div class="bubble of"><img  src="assets/img/landings/feria/Ofertas_financieras_icono.svg" alt="Negociación de deudas"><p><b>OFERTAS FINANCIERAS</b> según tu perfil.</p></div>
            <div class="bubble ef"><img  src="assets/img/landings/feria/Educacion_financiera_icono.svg" alt="Negociación de deudas"><p>Actividades de <b>EDUCACIÓN FINANCIERA.</b></p></div>
            <div class="bubble gr"><img  src="assets/img/landings/feria/Historia_credito_icono.svg" alt="Negociación de deudas"><p>Historia de crédito <b>GRATIS.</b></p></div>
            <div class="bubble pg"><img  src="assets/img/landings/feria/Puntaje gratis_icono.svg" alt="Negociación de deudas"><p>Puntaje <b>GRATIS</b> ese día.</p></div>
        </div>
        <p class="principalTXT">¡Te esperamos para que empieces a tomar el <b>control de tus finanzas!</b></p>
    </div>
    <div class="clockDesktop d-none d-md-block">
        <div class="clockItems">
            <div class="segment">
                <p><span>{{day <= 0? '0':day}}</span> <br>Días</p>
            </div>
            <div class="segment">
                <p><span>{{hours <= 0? '0':hours}}</span> <br>Horas</p>
            </div>
            <div class="segment">
                <p><span>{{minutes <= 0? '0':minutes}}</span> <br>Minutos</p>
            </div>
            <div class="segment">
                <p><span>{{seconds <= 0? '0':seconds}}</span> <br>Segundos</p>
            </div>
        </div>
    </div>
    <div class="generalInfo">
        <div class="data">
            <img src="assets/img/landings/feria/CUANDO.png" alt="Sábado 27 Abril">
            <h4>¿Cuándo?</h4>
            <p><b>Sábado 30 Noviembre</b> 2024</p>
        </div>
        <div class="data">
            <img src="assets/img/landings/feria/DONDE.png" class="map" alt="Av. 68 # 49A-47">
            <h4>¿Dónde?</h4>
            <p>Plaza Mayor, <b>Pabellón Rojo</b> Calle 41 N° 55-80, Medellín, Colombia</p>
        </div>
        <div class="data">
            <img src="assets/img/landings/feria/A-QUE-HORA.png" alt="8:00 a.m a 5:00 p.m">
            <h4>¿A qué hora?</h4>
            <p>9:00 a.m a 5:00 p.m</p>
        </div>
        <div class="dataMobile d-flex d-md-none">
            <img src="assets/img/landings/feria/CUANDO.png" alt="Sábado 27 Abril">
            <div class="txtMobile">
                <h4>¿Cuándo?</h4>
                <p><b>Sábado 30 Noviembre</b> 2024</p>
            </div>
        </div>
        <hr class="sep">
        <div class="dataMobile d-flex d-md-none">
            <img src="assets/img/landings/feria/DONDE.png" class="map" alt="Av. 68 # 49A-47">
            <div class="txtMobile">
                <h4>¿Dónde?</h4>
                <p>Plaza Mayor, <b>Pabellón Rojo</b> Calle 41 N° 55-80, Medellín, Colombia</p>
            </div>
        </div>
        <hr class="sep">
        <div class="dataMobile d-flex d-md-none">
            <img src="assets/img/landings/feria/A-QUE-HORA.png" alt="8:00 a.m a 5:00 p.m">
            <div class="txtMobile">
                <h4>¿A qué hora?</h4>
                <p>9:00 a.m a 5:00 p.m</p>
            </div>
        </div>
    </div>
    <div class="banner-div">
        <div id='div-gpt-ad-1712871841570-0' class="commercial-banner" [ngClass]="checkBanner()"></div>
    </div>
    <div class="sponsor" *ngIf="flagSponsors">
        <!-- <div class="desc">
            <p>Hasta <br> <span>XX%</span> <br> descuento</p>
            <p class="add">+</p>
            <p>Negociación <br> <span>100%</span> <br> gratis</p>
        </div> -->
        <h2 class="txtAliados">Nuestros Aliados:</h2>
        <div class="aliados">
            <img *ngFor="let sponsor of sponsors" src="{{sponsor.icon}}" alt="{{sponsor.name}}">
        </div>
    </div>
    
    <div class="out">
        <h2>¡Desde cualquier lugar!</h2>
        <p>Ingresa a <a href="https://www.midatacredito.com/" target="_blank" (click)="send_datalayer('www.midatacredito.com')" >www.midatacredito.com</a>, inicia sesión o regístrate, revisa tu historia de crédito, haz clic en el botón <b>PONERME AL DÍA</b>, contáctate con las entidades acreedoras y empieza a negociar.</p>
        <button (click)="goLogin()">Ir a Midatacrédito</button>
    </div>
</div>