import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BrokerService } from 'src/app/core/services/broker.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-huellas',
  templateUrl: './huellas.component.html',
  styleUrls: ['./huellas.component.scss']
})
export class HuellasComponent implements OnInit {

  public txtTootip = '';
  public textoMicroLoader = 'Por favor espera un momento, estamos cargando tus huellas de consulta.';
  public checkDivFree = false;

  constructor(
    public sesion: SesionService,
    public broker: BrokerService,
    public elementRef: ElementRef,
    public router: Router,
	private dataLayerService: DataLayersService,
	public utilities: UtilitiesService
  ) {
    if (this.sesion.listHuellas == undefined) {
      if (this.sesion.viewFree) {
        this.sesion.freepremium(true);
      } else {
        this.sesion.infoHC(true);
      }
    }
  }

	ngOnInit() {
		// this.addManyChat();
		setTimeout(() => {
			$(".circle-count").addClass("d-none")
		}, 5000);
		// GA4 - User
		this.dataLayerService.dl_userId( '.', this.sesion.planCurrently );
		//Tooltip
		$('body').click(function() {
			//$('#clicOut').fadeOut();
		});
		$('#clicOut').click(function(event:any){
			event.stopPropagation();			
		});
		//Size window
		if($(window).width() <= 768){
			setTimeout(() => {
				$('#contenTooltip').css("visibility", "visible")
			}, 2000);
		} else {
			$('#contenTooltip').css("visibility", "hidden");
		}
		// Size window
	}
	getEntity(entidad:any){
		entidad.includes("CIUDADANO")?entidad="CIUDADANO":entidad;
		switch (entidad) {
			case 'CIUDADANO':
				this.txtTootip = 'Esta huella corresponde a todas las consultas hechas por el ciudadano (auto consultas) por medio de alguno de los canales habilitados.';
				$('#contenTooltip').css("visibility", "hidden");
				break;
			case 'CONSULTAS LOTE':
				this.txtTootip = 'Corresponden a consultas realizadas por las Entidades para la supervisión y control del riesgo crediticio.'
				$('#contenTooltip').css("visibility", "hidden");
				break;
			default:
				this.txtTootip = 'Esta huella corresponde a la consulta al historial crediticio realizada por alguna entidad con previa autorización del ciudadano.'
				$('#contenTooltip').css("visibility", "hidden");
				break;
		}
	}
	linkSAC() {
		if (this.sesion.estadoUserF == 'INACTIVO' || this.sesion.estadoUserF == 'PENDING') {
			this.router.navigate(['/freemium/servicio']);
		} else {
			this.router.navigate(['/private/servicio']);
		}
	}

  addManyChat(){
    let script = document.createElement('script');
    script.src = "//widget.manychat.com/1897847597147648.js";
    script.async = true;
    this.elementRef.nativeElement.appendChild(script);
  }

  checkBannerFreeHuellas(){
	if (!this.checkDivFree){
		let tamano = [[728, 90], [300, 250]];
		this.utilities.addPubliById(environment.slotsBanners.huellasConsulta,'div-gpt-ad-1694399422378-0', tamano);
		this.checkDivFree = true;
	}
	return ''
	}
}
