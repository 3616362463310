import { Component, Input, OnInit } from '@angular/core';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { environment } from 'src/environments/environment';
interface post {
  id: number;
  date: string;
  desc: string;
  img: string;
  url: string;
}


@Component({
  selector: 'app-view-posts',
  templateUrl: './view-posts.component.html',
  styleUrls: ['./view-posts.component.scss']
})
export class ViewPostsComponent implements OnInit {
  public linkBc = environment.enlaceBuscaCredito;
  @Input() posts: Array<post> = [
    { id: 1, date: '06 Ene, 2023', desc: '¿Cómo puedo saber si estoy reportado en Datacredito GRATIS?', img: 'assets/img/home/entrada_midc_1.png', url: 'https://blog.midatacredito.com/como-saber-si-estoy-reportado-en-datacredito-gratis/?utm_source=MIDC&utm_medium=HOME&utm_campaign=BOXBLOGMIDC1' },
    { id: 2, date: '12 Jul, 2024', desc: 'Guía para tu solicitud de tarjeta de créditos.', img: 'assets/img/home/entrada_bc_1.png', url: this.linkBc + 'blog/tarjeta-credito/?utm_source=MIDC&utm_medium=HOME&utm_campaign=BOXBLOGBC' },
    { id: 3, date: '03 Oct, 2023', desc: '5 Pasos a seguir para saber qué hacer en caso de robo de identidad.', img: 'assets/img/home/entrada_midc_2.png', url: 'https://blog.midatacredito.com/que-hacer-en-caso-de-robo-de-identidad/?utm_source=MIDC&utm_medium=HOME&utm_campaign=BOXBLOGMIDC2' },
  ];

  public postBefore: post = this.posts[0];
  public postMain: post = this.posts[1];
  public postAfter: post = this.posts[2];

  constructor(private dataLayerService : DataLayersService) { }

  ngOnInit(): void {
  }

  goPost(post: string) {
    window.location.href = post;
  }

  movePost(after: boolean) {
    if (window.innerWidth <= 768) {
      let num = (after ? 1 : -1);
      let indexMain = (this.postMain.id - 1) + num;
      if (indexMain >= this.posts.length) {
        indexMain = 0;
      }
      if (indexMain < 0) {
        indexMain = this.posts.length - 1;
      }
      let indexAfter = (this.postAfter.id - 1) + num;
      if (indexAfter >= this.posts.length) {
        indexAfter = 0;
      }
      if (indexAfter < 0) {
        indexAfter = this.posts.length - 1;
      }
      let indexBefore = (this.postBefore.id - 1) + num;
      if (indexBefore >= this.posts.length) {
        indexBefore = 0;
      }
      if (indexBefore < 0) {
        indexBefore = this.posts.length - 1;
      }
      this.postMain = this.posts[indexMain];
      this.postAfter = this.posts[indexAfter];
      this.postBefore = this.posts[indexBefore];
    }
  }
  clic_home_blog(blog:string) {
    let event = {
      'event': 'ga_event',
      'name_event': 'clic_home_blog',
      'button_name' : 'Leer este artículo',
      'blog_name' : blog
    }
    this.dataLayerService.dl_event(event);
  }

}
