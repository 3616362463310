import { Component, Input, OnInit } from '@angular/core';
import { SesionService } from 'src/app/core/services/sesion.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dash-banner-google',
  templateUrl: './dash-banner-google.component.html',
  styleUrls: ['./dash-banner-google.component.scss']
})
export class DashBannerGoogleComponent implements OnInit {
  @Input() id_banner: string = '';
  @Input() styles: string = '';

  public checkDivFree = false;

  constructor( public utilities: UtilitiesService, public sesion: SesionService ) { }

  ngOnInit(): void {
  }

  checkBannerFree() {
		if (!this.checkDivFree) {
      let slotBanner = '';
      let tamano = [[728, 90], [300, 50]];

      // Banner #1
      if ( this.id_banner == 'div-gpt-ad-1708610431205-0'){
        slotBanner = environment.slotsBanners.dash1;
      }

      // Banner #2
      if ( this.id_banner == 'div-gpt-ad-1708610192600-0' ){
        slotBanner = environment.slotsBanners.dash2;
      }

      // Banner #3
      if ( this.id_banner == 'div-gpt-ad-1708610601977-0' ){
        slotBanner = environment.slotsBanners.dash3;
      }

      // Banner #4
      if ( this.id_banner == 'div-gpt-ad-1709064708199-0' ){
        tamano = [[728, 90], [300, 250]];
        slotBanner = environment.slotsBanners.dash4;
      }

      // Banner #1 - mobile
      if ( this.id_banner == 'div-gpt-ad-1717777383945-0'){
        tamano = [[300, 250]];
        slotBanner = environment.slotsBanners.dash1Mobile;
      }

      // Banner #2 - mobile
      if ( this.id_banner == 'div-gpt-ad-1717777536769-0' ){
        tamano = [[300, 250]];
        slotBanner = environment.slotsBanners.dash2Mobile;
      }

      // Banner #3 - mobile
      if ( this.id_banner == 'div-gpt-ad-1718203033597-0' ){
        tamano = [[300, 250]];
        slotBanner = environment.slotsBanners.dash3Mobile;
      }

			this.utilities.addPubliById(slotBanner, this.id_banner, tamano);
			this.checkDivFree = true;
		}
		return ''
	}
}
