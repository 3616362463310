<section class="method-pay">
	<div class="container">
		<div class="row">
			<div class="col-12 col-md-8" *ngIf="tcPay">
                <div>
					<div class="alertCheck check" *ngIf="alertCheck">
						<div class="alertMessage">
							<img src="assets/img/upselling/ok.svg" class="iconCheck" alt="ok">
							<div class="txtMessage">
								<h3>Tu Cambio de método de pago ha sido exitoso</h3>
							</div>							
						</div>
					</div>
                    <div class="alertCheck error" *ngIf="alertError">
						<div class="alertMessage">
							<img src="assets/img/upselling/bad.svg" class="iconCheck" alt="bad">
							<div class="txtMessage">
								<h3>Tu Cambio de método de pago no ha sido exitoso. Intentalo de nuevo</h3>
							</div>							
						</div>
					</div>
				</div>
				<div class="box-info">              
                    <form id="debitoForm" #datos="ngForm" (ngSubmit)="actualizarMetodo(datos)" autocomplete="off">
                        <div class="box-step">
                            <h3>Datos de quien paga</h3>
                            <div class="form-row">
								<div class="form-group col-12 col-md-6">
                                    <mat-form-field >
                                        <mat-label>Tipo de documento</mat-label>
                                        <mat-select name="typeidBankAccountH" id="typeidBankAccountH" required="required"
                                            class="form-control"
                                            [(ngModel)]="pse.detallesDatos.tipoDocumento"
                                            #reference2="ngModel"
                                            >
                                            <mat-option value="CC">Cédula de ciudadanía</mat-option>
                                            <mat-option value="CE">Cédula de extranjería</mat-option>
                                            <mat-option value="NIT">Número de Identificación Tributaria</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <mat-form-field>
                                        <input matInput name="idUser" id="idUser" type="tel" required="required"
                                            class="form-control" pattern="^[+]?([0-9]+(?:[0-9]*)?|[0-9]+)$"
                                            placeholder="Documento" maxlength="10"
                                            [(ngModel)]="pse.detallesDatos.cedula"
                                            #identificacion="ngModel"
                                            [ngClass]="{'is-valid': identificacion.valid, 'is-invalid': ( (identificacion.errors?.['required']  ) && identificacion.touched ) }">
                                            <mat-error *ngIf="identificacion?.errors?.['required']" class="invalid-feedback">Campo obligatorio.</mat-error>
                                            <mat-error *ngIf="identificacion?.errors?.['pattern']" class="invalid-feedback">Documento no válido. Ingresar solo números.</mat-error>   
                                    </mat-form-field>                                    
                                </div> 
                            </div>
							<h3>Datos de la cuenta</h3>
                            <div class="form-row">
                                <div class="form-group col-12 col-md-6" id="bankDiv">
                                    <mat-form-field class="w-100" *ngIf="sesion.bancos">
                                        <mat-label>Selecciona tu banco</mat-label>
                                        <mat-select name="bank" required="required" id="bank" class="form-control"
                                            pattern="^0*[1-9]\d*$"
                                            [(ngModel)]="pse.detallesDatos.codigoBanco"
                                            #bankCode="ngModel"
                                            [ngClass]="{'is-valid': bankCode.valid, 'is-invalid': ( (bankCode.errors?.['required'] || bankCode.errors?.['pattern']  ) && bankCode.touched ) }"
                                            (selectionChange)="constantes.buscarAyudaBanco($event.value)">
                                            <mat-option *ngFor="let banco of sesion.bancos.banks" value="{{ banco.pseCode }}">
                                                {{ banco.description }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="form-group col-12 col-md-6">
                                    <mat-form-field >
                                        <input matInput name="numberAccount" class="form-control" required="required"
                                            pattern="^[+]?([0-9]+(?:[0-9]*)?|[0-9]+)$" id="numberAccount" type="tel"
                                            [ngClass]="{'disabled': !pse.params.recurrency }"
                                            [attr.required]="!pse.params.recurrency ? null : 'required'"
                                            placeholder="Número de cuenta"
                                            [(ngModel)]="pse.detallesDatos.numeroCuenta"
                                            #accountNumber="ngModel">  
                                            <mat-error *ngIf="accountNumber?.errors?.['pattern']" class="invalid-feedback">Número de cuenta no válido. Ingresar solo números.</mat-error>                                                                   
                                    </mat-form-field>                                          
                                </div>
                                <div class="form-group col-12 col-md-6" id="client_typediv">
                                    <mat-label class="labelAccount">Tipo de persona</mat-label>
                                    <mat-radio-group aria-label="Select an option" 
                                        name="bankAccountTy" name="client_type" id="client_type"
                                        [(ngModel)]="pse.detallesDatos.tipoPersona" #userType="ngModel">
                                        <mat-radio-button value="N">Persona Natural</mat-radio-button>
                                        <mat-radio-button value="J">Persona Jurídica</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="form-group col-12 col-md-6" id="client_typediv">
                                    <mat-label class="labelAccount">Tipo de cuenta bancaria</mat-label>
                                    <mat-radio-group name="bankAccountType" id="bankAccountType" required="required"
                                            [ngClass]="{'disabled': !pse.params.recurrency }"
                                            [attr.required]="!pse.params.recurrency ? null : 'required'"
                                            [(ngModel)]="pse.detallesDatos.tipoCuenta"
                                            #bankAccount="ngModel"
                                            [ngClass]="{'is-valid': bankAccount.valid, 'is-invalid': ( (bankAccount.errors?.['required']  ) && bankAccount.touched ) }">
                                        <mat-radio-button value="A">Cuenta ahorros</mat-radio-button>
                                        <mat-radio-button value="C">Cuenta corriente</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <div class="note">
                                    <p>Al hacer clic en Actualizar aceptas que nuestros <a data-toggle="modal" data-target="#dialog-message" data-placement="top"  id="opener"> Términos y Condiciones</a>. Recuerda que podrás cancelar tu membresía en cualquier momento.</p>
                                </div> 
                                <div class="form-group col-12 col-md-6">
                                    <button 
                                        [attr.disabled]="datos.valid ? null : 'disabled'"
                                        [ngClass]="{'disabled': !datos.valid }" name="validar" type="submit"
                                        id="actualizarPSE" webActivity data-idtag="actualizarMetodoPago" data-nametag="actualizarPSE"
                                        class="btn-midc btn-orange2 w-100-btn">
                                        Actualizar método
                                        <i class="fa fa-chevron-right"></i>
                                    </button>  
                                </div>
                            </div>							
                        </div>
                    </form>
                </div>
			</div>
            <div class="col-12 col-md-8" *ngIf="tcChange">
                
            </div>
		</div>
	</div>
</section>
