<section class="upselling">
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div>
					<div class="alertCheck check" *ngIf="alertCheck">
						<div class="alertMessage">
							<img src="assets/img/upselling/ok.svg" class="iconCheck" alt="ok">
							<div class="txtMessage">
								<h3>Tu Cambio de plan ha sido exitoso</h3>
								<p *ngIf="planSelect.namePlan">Haz adquirido tu <span> {{ (planSelect != undefined ? planSelect.namePlan : '') && (planSelect.namePlan | titlecase) }}</span>.</p>
								<p *ngIf="!planSelect.namePlan">Haz adquirido tu <span> {{ planSelectCheck() }}</span>.</p>
							</div>							
						</div>
						<p class="remind" *ngIf="broker">Recuerda que el pago cuenta con <span>10% de descuento</span> y no se realizará si no hasta tu próxima <span>fecha de corte: {{ sesion.dateToday | date: 'dd MMMM yyyy' }}</span></p>
					</div>
					<div class="alertCheck error" *ngIf="alertError" >
						<div class="alertMessage">
							<img src="assets/img/upselling/bad.svg" class="iconCheck" alt="bad">
							<div class="txtMessage">
								<h3>Tu Cambio de plan NO fue exitoso</h3>
								<p>Estamos presentando fallas en este momento. Por favor intenta más tarde</p>
							</div>							
						</div>
					</div>
					<div class="alertCheck error" *ngIf="userError" >
						<div class="alertMessage">
							<img src="assets/img/upselling/bad.svg" class="iconCheck" alt="bad">
							<div class="txtMessage">
								<h3>Lo sentimos</h3>
								<p>El usuario no aplica al cambio de plan</p>
							</div>							
						</div>
					</div>
				</div>
				<h2>Plan actual</h2>
				<div class="box-plan" *ngIf="upsell.plansUpsell">
					<div class="header-plan">
						<div class="header-title">
							<div class="currently-plan">
								<h2 *ngIf="upsell.idPeriod == 'PER1' || upsell.idPeriod == 'PER2'">Mejora tu puntaje</h2>
								<h2 *ngIf="upsell.idPeriod == 'ANT1' || upsell.idPeriod == 'ANT2'">Protege tus datos</h2>
								<h5>Plan actual</h5>
							</div>
							<p *ngIf="upsell.idPeriod == 'PER1' || upsell.idPeriod == 'PER2'">Tu entrenador financiero</p>							
							<p *ngIf="upsell.idPeriod == 'ANT1' || upsell.idPeriod == 'ANT2'">Tu presencia en internet</p>
						</div>
						<div class="header-cta" [ngClass]="
								upsell.idPeriod == 'PER1' || upsell.idPeriod == 'PER2' ||
								upsell.idPeriod == 'ANT1' || upsell.idPeriod == 'ANT2' ? 'flex-end' : ''
							">
							<div class="cta-left">
								<h4  *ngIf="upsell.idPeriod == 'PER1'"> ${{ upsell.planUpsell.accountInfo.totalValue | currency:'COP':'':'1.0-0' }}<span>/mes</span></h4>
								<h4  *ngIf="upsell.idPeriod == 'PER2'"> ${{ upsell.planUpsell.accountInfo.totalValue | currency:'COP':'':'1.0-0' }}<span>/trimestral</span></h4>
								<h4  *ngIf="upsell.idPeriod == 'ANT1'"> ${{ upsell.planUpsell.accountInfo.totalValue | currency:'COP':'':'1.0-0' }}<span>/mes</span></h4>
								<h4  *ngIf="upsell.idPeriod == 'ANT2'"> ${{ upsell.planUpsell.accountInfo.totalValue | currency:'COP':'':'1.0-0' }}<span>/trimestral</span></h4>
								<p *ngIf="upsell.idPeriod == 'PER1'">Pagas al mes ${{ upsell.planUpsell.accountInfo.totalValue | currency:'COP':'':'1.0-0' }} </p>
								<p *ngIf="upsell.idPeriod == 'ANT1'">Pagas al mes ${{ upsell.planUpsell.accountInfo.totalValue | currency:'COP':'':'1.0-0' }}</p>						
								<p>Tu próxima factura <span>{{ sesion.dateToday | date: 'dd MMMM yyyy' }}</span></p>
							</div>
						</div>
						
					</div>
					
					
					<div class="header-summary">
						<h4>Características básicas</h4>
						<div class="bullets-new" *ngIf="upsell.idPeriod == 'PER1' || upsell.idPeriod == 'PER2'" >
							<div class="bullet-box">
								<div class="img-bullet">
									<img src="assets/img/landings/new/perfil-credito/Plan_Perfil-1-Puntaje.svg" alt="Puntaje" />
								</div>
								<div class="content-bullet">
									<h4>Obtén tu puntaje de Datacrédito</h4>
									<p>Un buen puntaje te acerca más a tu próximo crédito</p>
								</div>
							</div>
							<div class="bullet-box">
								<div class="img-bullet">
									<img src="assets/img/landings/new/perfil-credito/Plan_Perfil-2-Diagnostico.svg" alt="Diagnóstico" />
								</div>
								<div class="content-bullet">
									<h4>Conoce tu diagnóstico</h4>
									<p>Asesor financiero virtual para mejorar tu puntaje y acceso al crédito.</p>
								</div>
							</div>
							<div class="bullet-box">
								<div class="img-bullet">
									<img src="assets/img/landings/new/perfil-credito/Plan_Perfil-3-Alertas.svg" alt="Alertas" />
								</div>
								<div class="content-bullet">
									<h4>Recibe alertas antifraude</h4>
									<p>Te notificamos cada vez que alguna entidad haga una consulta a tu historial crediticio.</p>
								</div>
							</div>
						</div>
						<div class="bullets-new" *ngIf="upsell.idPeriod == 'ANT1' || upsell.idPeriod == 'ANT2'">
							<div class="bullet-box">
								<div class="img-bullet">
									<img src="assets/img/landings/new/protege/Plan_Protege-1-Monitoreo.svg" alt="Monitoreo" />
								</div>
								<div class="content-bullet">
									<h4>Activa el monitoreo ante posibles fraudes</h4>
									<p>Evita aperturas de cuentas y créditos a tu nombre</p>
								</div>
							</div>
							<div class="bullet-box">
								<div class="img-bullet">
									<img src="assets/img/landings/new/protege/Plan_Protege-2-DatosPersonales.svg" alt="Datos personales" />
								</div>
								<div class="content-bullet">
									<h4>Detecta filtraciones de tus datos personales</h4>
									<p>Monitoreo 24/7 de tus cuentas de correo y contraseñas en internet.</p>
								</div>
							</div>
							<div class="bullet-box">
								<div class="img-bullet">
									<img src="assets/img/landings/new/protege/Plan_Protege-3-Alertas.svg" alt="Alertas" />
								</div>
								<div class="content-bullet">
									<h4>Recibe alertas financieras</h4>
									<p>Cada vez que tus reportes sean actualizados en Datacrédito</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="box-plan" *ngIf="upsell.plansOthers">
					<div>
						<div class="header-plan">
							<div class="header-title">
								<div class="currently-plan">
									<h2 *ngIf="sesion.planCurrently == 'PER3' || sesion.planCurrently == 'PER4' || sesion.planCurrently == 'PER5'">
										{{ sesion.planes.planes?.per?.popup }}
									</h2>
									<h2 *ngIf="sesion.planCurrently == 'ANT3' || sesion.planCurrently == 'ANT4'">
										{{ sesion.planes.planes?.ant?.popup }}
									</h2>
									<h2 *ngIf="sesion.planCurrently == 'PRO1' || sesion.planCurrently == 'PRO2' || sesion.planCurrently == 'PRO3' || sesion.planCurrently == 'PRO4'">
										{{ sesion.planes.planes?.pre?.popup }}
									</h2>
									<h5>Plan actual</h5>									
								</div>
								<p *ngIf="sesion.planCurrently == 'PER3' || sesion.planCurrently == 'PER4' || sesion.planCurrently == 'PER5'">Tu entrenador financiero</p>				
								<p *ngIf="sesion.planCurrently == 'ANT3' || sesion.planCurrently == 'ANT4'">Tu presencia en internet</p>
								<p *ngIf="sesion.planCurrently == 'PRO3' || sesion.planCurrently == 'PRO4'">Evita el fraude en tus compras</p>
							</div>
							<div class="header-cta"[ngClass]="
								sesion.planCurrently == 'PRO1' || sesion.planCurrently == 'PRO2' ? 'flex-end' : ''
							">
								<div class="cta-left" *ngIf="sesion.planCurrently == 'PER3'">
									<h4 > ${{ sesion.planes.planes?.per?.trimestral?.[0] }}<span>/anual</span></h4>						
								</div>
								<div class="cta-left" *ngIf="sesion.planCurrently == 'PER4'">
									<h4 > ${{ sesion.planes.planes?.per?.anual?.[0] }}<span>/anual</span></h4>							
								</div>
								<div class="cta-left" *ngIf="sesion.planCurrently == 'PER5'">
									<h4 > ${{ sesion.planes.planes?.per?.anual?.[0] }}<span>/anual</span></h4>							
								</div>
								<div class="cta-left" *ngIf="sesion.planCurrently == 'ANT3'">
									<h4 > ${{ sesion.planes.planes?.ant?.anual?.[0] }}<span>/anual</span></h4>							
								</div>
								<div class="cta-left" *ngIf="sesion.planCurrently == 'ANT4'">
									<h4 > ${{ sesion.planes.planes?.ant?.anual?.[0] }}<span>/anual</span></h4>						
								</div>
								<div class="cta-left" *ngIf="sesion.planCurrently == 'PRO1'">
									<h4> ${{ sesion.planes.planes?.pre?.mensual?.[0] }}<span>/mensual</span></h4>
									<p>Pagas al mes ${{ sesion.planes.planes?.pre?.mensual?.[0] }} </p>						
								</div>
								<div class="cta-left" *ngIf="sesion.planCurrently == 'PRO2'">
									<h4 > ${{ sesion.planes.planes?.pre?.trimestral?.[0] }}<span>/trimestral</span></h4>						
								</div>
								<div class="cta-left" *ngIf="sesion.planCurrently == 'PRO3'">
									<h4 > ${{ sesion.planes.planes?.pre?.anual?.[0] }}<span>/anual</span></h4>						
								</div>
								<div class="cta-left" *ngIf="sesion.planCurrently == 'PRO4'">
									<h4 > ${{ sesion.planes.planes?.pre?.anual?.[0] }}<span>/anual</span></h4>							
								</div>
							</div>
					</div>
					<div class="header-summary">
						<h4>Características básicas</h4>
							<div class="bullets-new" *ngIf="sesion.planCurrently == 'PER3' || sesion.planCurrently == 'PER4' || sesion.planCurrently == 'PER5'" >
								<div class="bullet-box">
									<div class="img-bullet">
										<img src="assets/img/landings/new/perfil-credito/Plan_Perfil-1-Puntaje.svg" alt="Puntaje" />
									</div>
									<div class="content-bullet">
										<h4>Obtén tu puntaje de Datacrédito</h4>
										<p>Un buen puntaje te acerca más a tu próximo crédito</p>
									</div>
								</div>
								<div class="bullet-box">
									<div class="img-bullet">
										<img src="assets/img/landings/new/perfil-credito/Plan_Perfil-2-Diagnostico.svg" alt="Diagnóstico" />
									</div>
									<div class="content-bullet">
										<h4>Conoce tu diagnóstico</h4>
										<p>Asesor financiero virtual para mejorar tu puntaje y acceso al crédito.</p>
									</div>
								</div>
								<div class="bullet-box">
									<div class="img-bullet">
										<img src="assets/img/landings/new/perfil-credito/Plan_Perfil-3-Alertas.svg" alt="Alertas" />
									</div>
									<div class="content-bullet">
										<h4>Recibe alertas antifraude</h4>
										<p>Te notificamos cada vez que alguna entidad haga una consulta a tu historial crediticio.</p>
									</div>
								</div>
							</div>
							<div class="bullets-new" *ngIf="sesion.planCurrently == 'ANT3' || sesion.planCurrently == 'ANT4'" >
								<div class="bullet-box">
									<div class="img-bullet">
										<img src="assets/img/landings/new/protege/Plan_Protege-1-Monitoreo.svg" alt="Monitoreo" />
									</div>
									<div class="content-bullet">
										<h4>Activa el monitoreo ante posibles fraudes</h4>
										<p>Evita aperturas de cuentas y créditos a tu nombre</p>
									</div>
								</div>
								<div class="bullet-box">
									<div class="img-bullet">
										<img src="assets/img/landings/new/protege/Plan_Protege-2-DatosPersonales.svg" alt="Datos personales" />
									</div>
									<div class="content-bullet">
										<h4>Detecta filtraciones de tus datos personales</h4>
										<p>Monitoreo 24/7 de tus cuentas de correo y contraseñas en internet.</p>
									</div>
								</div>
								<div class="bullet-box">
									<div class="img-bullet">
										<img src="assets/img/landings/new/protege/Plan_Protege-3-Alertas.svg" alt="Alertas" />
									</div>
									<div class="content-bullet">
										<h4>Recibe alertas financieras</h4>
										<p>Cada vez que tus reportes sean actualizados en Datacrédito</p>
									</div>
								</div>
							</div>
							<div class="bullets-new" *ngIf="sesion.planCurrently == 'PRO1' || sesion.planCurrently == 'PRO2' || sesion.planCurrently == 'PRO3' || sesion.planCurrently == 'PRO4'" >
								<div class="bullet-box">
									<div class="img-bullet">
										<img src="assets/img/landings/new/premium/Plan_Premium-1-Seguro.svg" alt="Seguro" />
									</div>
									<div class="content-bullet">
										<h4>Obtén un seguro en caso de fraude*</h4>
										<p>El seguro te cubre en caso de robo de identidad o tarjetas de crédito.</p>
									</div>
								</div>
								<div class="bullet-box">
									<div class="img-bullet">
										<img src="assets/img/landings/new/premium/Plan_Premium-2-Asesoria.svg" alt="Asesoría legal" />
									</div>
									<div class="content-bullet">
										<h4>Asesoría legal personalizada*</h4>
										<p>En caso de que seas víctima de fraude y requieras asesoría legal.</p>
									</div>
								</div>
								<p class="note-premium">*Tanto la póliza como la asesoría legal del Plan Premium, son servicios prestados por SBS Seguros Colombia S.A.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12" *ngIf="(sesion.expiratonDays <= sesion.upsellDay && sesion.activeUser === 'ACTIVO')">
				<h2 class="titleH2" >Mejora tu plan</h2>
				<div class="box-plan" *ngFor="let plans of upsell.recommendedPlan; let i = index">
					<div class="header-plan">
						<div class="header-title">
							<div class="currently-plan">
								<h2 *ngIf="plans.idPeriod == 'PER1' || plans.idPeriod == 'PER2'">Mejora tu puntaje</h2>
								<h2 *ngIf="plans.idPeriod == 'ANT1' || plans.idPeriod == 'ANT2'">Protege tus datos</h2>
								<h2 *ngIf="plans.idPeriod == 'PRO1' || plans.idPeriod == 'PRO2'">Protección premium</h2>
								<!-- <h5>Plan ctual</h5> -->
							</div>
							<p *ngIf="plans.idPeriod == 'PER1' || plans.idPeriod == 'PER2'">Tu entrenador financiero</p>							
							<p *ngIf="plans.idPeriod == 'ANT1' || plans.idPeriod == 'ANT2'">Tu presencia en internet</p>
							<p *ngIf="plans.idPeriod == 'PRO1' || plans.idPeriod == 'PRO2'">Evita el fraude en tus compras</p>
						</div>
						<div class="header-cta">
							<div class="cta-left">
								<h4 *ngIf="plans.idPeriod == 'PER1' || plans.idPeriod == 'ANT1' || plans.idPeriod == 'PRO1'"> ${{ plans.totalWithDiscount | currency:'COP':'':'1.0-0' }}<span>/mes</span></h4>
								<h4 *ngIf="plans.idPeriod == 'PER2' || plans.idPeriod == 'ANT2' || plans.idPeriod == 'PRO2'"> ${{ plans.totalWithDiscount | currency:'COP':'':'1.0-0' }}<span>/trimestral</span></h4>
								<p *ngIf="plans.idPeriod == 'PER1' || plans.idPeriod == 'ANT1' || plans.idPeriod == 'PRO1'">Pagas al mes ${{ plans.totalWithDiscount | currency:'COP':'':'1.0-0' }} </p>
							</div>
							<div class="cta-right">
								<button 
									id="{{ plans.idPeriod }}" webActivity data-idtag="cambioPlan" data-nametag="cambioPlanaAdquirido" 
									data-toggle="modal" data-target="#planModal" (click)="seleccionarPlan(plans)"
									class="btn-midc btn-orange2 w-100-btn h-lg-btn">
									Adquirir plan
									<i class="fa fa-chevron-right"></i>
								</button>  
							</div>
						</div>
					</div>
					<div class="header-summary">
						<h4>Características básicas</h4>
						<div class="bullets-new" *ngIf="(plans != undefined ? plans.idPeriod : '') && (plans.idPeriod == 'PER1' || plans.idPeriod == 'PER2')" >
							<div class="bullet-box">
								<div class="img-bullet">
									<img src="assets/img/landings/new/perfil-credito/Plan_Perfil-1-Puntaje.svg" alt="Puntaje" />
								</div>
								<div class="content-bullet">
									<h4>Obtén tu puntaje de Datacrédito</h4>
									<p>Un buen puntaje te acerca más a tu próximo crédito</p>
								</div>
							</div>
							<div class="bullet-box">
								<div class="img-bullet">
									<img src="assets/img/landings/new/perfil-credito/Plan_Perfil-2-Diagnostico.svg" alt="Diagnóstico" />
								</div>
								<div class="content-bullet">
									<h4>Conoce tu diagnóstico</h4>
									<p>Asesor financiero virtual para mejorar tu puntaje y acceso al crédito.</p>
								</div>
							</div>
							<div class="bullet-box">
								<div class="img-bullet">
									<img src="assets/img/landings/new/perfil-credito/Plan_Perfil-3-Alertas.svg" alt="Alertas" />
								</div>
								<div class="content-bullet">
									<h4>Recibe alertas antifraude</h4>
									<p>Te notificamos cada vez que alguna entidad haga una consulta a tu historial crediticio.</p>
								</div>
							</div>
						</div>
						<div class="bullets-new" *ngIf="(plans != undefined ? plans.idPeriod : '') && (plans.idPeriod == 'ANT1' || plans.idPeriod == 'ANT2')" >
							<div class="bullet-box">
								<div class="img-bullet">
									<img src="assets/img/landings/new/protege/Plan_Protege-1-Monitoreo.svg" alt="Monitoreo" />
								</div>
								<div class="content-bullet">
									<h4>Activa el monitoreo ante posibles fraudes</h4>
									<p>Evita aperturas de cuentas y créditos a tu nombre</p>
								</div>
							</div>
							<div class="bullet-box">
								<div class="img-bullet">
									<img src="assets/img/landings/new/protege/Plan_Protege-2-DatosPersonales.svg" alt="Datos personales" />
								</div>
								<div class="content-bullet">
									<h4>Detecta filtraciones de tus datos personales</h4>
									<p>Monitoreo 24/7 de tus cuentas de correo y contraseñas en internet.</p>
								</div>
							</div>
							<div class="bullet-box">
								<div class="img-bullet">
									<img src="assets/img/landings/new/protege/Plan_Protege-3-Alertas.svg" alt="Alertas" />
								</div>
								<div class="content-bullet">
									<h4>Recibe alertas financieras</h4>
									<p>Cada vez que tus reportes sean actualizados en Datacrédito</p>
								</div>
							</div>
						</div>
						<div class="bullets-new" *ngIf="(plans != undefined ? plans.idPeriod : '') && (plans.idPeriod == 'PRO1' || plans.idPeriod == 'PRO2')" >
							<div class="bullet-box">
								<div class="img-bullet">
									<img src="assets/img/landings/new/premium/Plan_Premium-1-Seguro.svg" alt="Seguro" />
								</div>
								<div class="content-bullet">
									<h4>Obtén un seguro en caso de fraude*</h4>
									<p>El seguro te cubre en caso de robo de identidad o tarjetas de crédito.</p>
								</div>
							</div>
							<div class="bullet-box">
								<div class="img-bullet">
									<img src="assets/img/landings/new/premium/Plan_Premium-2-Asesoria.svg" alt="Asesoría legal" />
								</div>
								<div class="content-bullet">
									<h4>Asesoría legal personalizada*</h4>
									<p>En caso de que seas víctima de fraude y requieras asesoría legal.</p>
								</div>
							</div>
							<p class="note-premium">*Tanto la póliza como la asesoría legal del Plan Premium, son servicios prestados por SBS Seguros Colombia S.A.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- Modal Plan Protege -->
<div class="modal fade hide" id="planModal" tabindex="-1" role="dialog" aria-labelledby="planModalLabel" aria-hidden="true">
  	<div class="modal-dialog plansModal modal-dialog-centered" role="document">
    		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
      		<div class="modal-body">
        			<div class="container">
          				<div class="row">
						<div class="col-12 col-md-6 order-0 order-md-0 mb-3 align-self-center">
							<img src="assets/img/modals/plans-freemium/Compra_premium.svg" class="img-plan" alt="Plan Freemium" />
						</div>
            				<div class="col-12 col-md-6 order-1 order-md-1">              					
							<h2 *ngIf="(planSelect != undefined ? planSelect.idPeriod : '') && (planSelect.idPeriod == 'PER1' || planSelect.idPeriod == 'PER2')">Mejora tu puntaje</h2>
							<h2 *ngIf="(planSelect != undefined ? planSelect.idPeriod : '') && (planSelect.idPeriod == 'ANT1' || planSelect.idPeriod == 'ANT2')">Protege tus datos</h2>
							<h2 *ngIf="(planSelect != undefined ? planSelect.idPeriod : '') && (planSelect.idPeriod == 'PRO1' || planSelect.idPeriod == 'PRO2')">Protección premium</h2>
							<p *ngIf="(planSelect != undefined ? planSelect.idPeriod : '') && (planSelect.idPeriod == 'ANT1')" class="price">${{ (planSelect != undefined ? planSelect.totalWithDiscount : '') && (planSelect.totalWithDiscount | currency:'COP':'':'1.0-0') }}<span>/mes</span></p>
							<p *ngIf="(planSelect != undefined ? planSelect.idPeriod : '') && (planSelect.idPeriod == 'ANT2')" class="price">${{ (planSelect != undefined ? planSelect.totalWithDiscount : '') && (planSelect.totalWithDiscount | currency:'COP':'':'1.0-0') }}<span>/trimestral</span></p>
							<p *ngIf="(planSelect != undefined ? planSelect.idPeriod : '') && (planSelect.idPeriod == 'PRO1')" class="price">${{ (planSelect != undefined ? planSelect.totalWithDiscount : '') && (planSelect.totalWithDiscount | currency:'COP':'':'1.0-0') }}<span>/mes</span></p>
							<p *ngIf="(planSelect != undefined ? planSelect.idPeriod : '') && (planSelect.idPeriod == 'PRO2')" class="price">${{ (planSelect != undefined ? planSelect.totalWithDiscount : '') && (planSelect.totalWithDiscount | currency:'COP':'':'1.0-0') }}<span>/trimestral</span></p>
							<div class="bullets" *ngIf="(planSelect != undefined ? planSelect.idPeriod : '') && (planSelect.idPeriod == 'PER1' || planSelect.idPeriod == 'PER2')">
								<div class="completeBullet" *ngFor="let item of sesion.planes.planes.per.itemsV2">
									<i class="fa fa-check" aria-hidden="true"></i>
									<p class="regular">{{item.txt}}</p>
								</div>
							</div>
							<div class="bullets" *ngIf="(planSelect != undefined ? planSelect.idPeriod : '') && (planSelect.idPeriod == 'ANT1' || planSelect.idPeriod == 'ANT2')">
								<div class="completeBullet" *ngFor="let item of sesion.planes.planes.ant.itemsV2">
									<i class="fa fa-check" aria-hidden="true"></i>
									<p class="regular">{{item.txt}}</p>
								</div>
							</div>  
							<div class="bullets" *ngIf="(planSelect != undefined ? planSelect.idPeriod : '') && (planSelect.idPeriod == 'PRO1' || planSelect.idPeriod == 'PRO2')">
								<div class="completeBullet" *ngFor="let item of sesion.planes.planes.ant.itemsV2">
									<i class="fa fa-check" aria-hidden="true"></i>
									<p class="regular">{{item.txt}}</p>
								</div>
								<div class="completeBullet" *ngFor="let item of sesion.planes.planes.pre.itemsV2">
									<i class="fa fa-check" aria-hidden="true"></i>
									<p class="regular">{{item.txt}}</p>
								</div>
								<p class="note-premium">*Tanto la póliza como la asesoría legal del Plan Premium, son servicios prestados por SBS Seguros Colombia S.A.</p>
							</div>  
              					<a (click)="confirmarPlan()" class="btn-midc btn-orange h-lg-btn w-100-btn" 
							  id="{{ (planSelect != undefined ? planSelect.idPeriod : '') && (planSelect.idPeriod) }}" webActivity data-idtag="cambioPlanModal" data-nametag="cambioPlanAdquiridoModal" 
							  data-dismiss="modal" aria-label="Close">
                  					<span>Adquirir plan</span>
                  					<i class="fa fa-chevron-right" aria-hidden="true"></i>
              					</a>
            				</div>                            
          				</div>
        			</div>
      		</div>
    		</div>
  	</div>
</div>
<!-- Modal Plan Protege -->
