import { Component, OnInit, Input } from '@angular/core';
import { ConstantesService } from 'src/app/core/services/constantes.service';
import { SuscripcionService } from 'src/app/core/services/suscripcion.service';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input() data: any;

  valorMensual: any;
  public planSel: any;

  constructor(public sucripcion: SuscripcionService, public constant: ConstantesService) {
    this.valorMensual = localStorage.getItem("valorPlanMensual");
  }

  ngOnInit() {
    if(this.planSel == undefined){
      this.selPlan();
    }
  }

  esPromo() {
    return (this.data.planId == "PER6" || this.data.planId == "ANT5" || this.data.planId == "PRE5")
  }

  selPlan() {
    let planAcron = this.data.planId.slice(0, 3).toLowerCase();
    switch (this.data.planId.slice(3, 4)) {
      case '1':
        this.planSel = Object.assign({},this.constant.planes.mensual.find((x: any) => x.plan == planAcron));
        break;
      case '2':
        this.planSel = Object.assign({},this.constant.planes.trimestral.find((x: any) => x.plan == planAcron));
        break;
      case '4':
        this.planSel = Object.assign({},this.constant.planes.anual.find((x: any) => x.plan == planAcron));
        break;
      case '5':
        this.planSel = Object.assign({},this.constant.planes.anual.find((x: any) => x.plan == planAcron));
        break;
      default:
        break;
    }
    switch (planAcron) {
      case 'ant':
        this.planSel.caracteristicas = [].concat(this.constant.planes.mensual[0].caracteristicas,this.constant.planes.mensual[1].caracteristicas);
        break;
      case 'pro':
        this.planSel.caracteristicas = [].concat(this.constant.planes.mensual[0].caracteristicas,this.constant.planes.mensual[1].caracteristicas,this.constant.planes.mensual[2].caracteristicas);
        break;
    }
  }

  showBenefits() {
    if ($("#benefits-pay").css('display') == 'none') {
      $($(".btn-benefits")[0]).children('button').children('i').removeClass('fa-angle-down');
      $($(".btn-benefits")[0]).children('button').children('i').addClass('fa-angle-up');
      $("#benefits-pay").slideDown('fast');
    } else {
      $($(".btn-benefits")[0]).children('button').children('i').removeClass('fa-angle-up');
      $($(".btn-benefits")[0]).children('button').children('i').addClass('fa-angle-down');
      $("#benefits-pay").slideUp('fast');
    }
  }

}
