import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { environment } from 'src/environments/environment';

declare var $: any;

interface benefitPlan {
  id: number,
  plan: string,
  name: string,
  icon: string,
  titleHtml: string,
  comment: string,
  url: string
}

interface categorieBc {
  id: number,
  name: string,
  img: string,
  icon: string,
  titleHtml: string,
  comment: string,
  url: string
}

@Component({
  selector: 'app-info-product',
  templateUrl: './info-product.component.html',
  styleUrls: ['./info-product.component.scss']
})
export class InfoProductComponent implements OnInit {

  @Input() product: string = '';
  @Input() isSmall: boolean = false;
  public linkBc = environment.enlaceBuscaCredito;
  // Bc = environment.buscacredito;
  public linkLogin = environment.enlaceLogin;
  public linkLandingPAD = '/deudas';
  public linkPAD = environment.enlacePAD;

  public benefitsPlan: Array<benefitPlan> = [
    { id: 1, plan: 'Plan Perfil de crédito', name: 'Puntaje credito', icon: 'assets/img/home/puntaje_credito_home_icono.png', titleHtml: 'TU <span>PUNTAJE</span> DE CRÉDITO', comment: 'Conoce y monitorea tu <b>perfil de crédito.</b>', url: '/pago?funn=HOME&plan=PER1' },
    { id: 2, plan: 'Plan Perfil de crédito', name: 'Diagnostico crediticio', icon: 'assets/img/home/diagnostico_home_icono.png', titleHtml: '<span>DIAGNÓSTICO</span> CREDITICIO', comment: 'Identifica los factores que <b>impactan</b> tu puntaje.', url: '/pago?funn=HOME&plan=PER1' },
    { id: 3, plan: 'Plan Perfil de crédito', name: 'Alertas antifraude', icon: 'assets/img/home/alertas_antifraude_home_icono.png', titleHtml: 'ALERTAS <span>ANTIFRAUDE</span>', comment: 'Protege tu información financiera de la <b>suplantación</b>', url: '/pago?funn=HOME&plan=PER1' },
    { id: 4, plan: 'Plan Perfil de crédito', name: 'Simulador credito', icon: 'assets/img/home/simulador_home_icono.png', titleHtml: '<span>SIMULADOR</span> DE CRÉDITO', comment: '<b>Monitorea</b> y <b>controla</b> tu información crediticia.', url: '/pago?funn=HOME&plan=PER1' },
    { id: 5, plan: 'Plan Perfil de crédito', name: 'Consolidado deudas', icon: 'assets/img/home/consolidado_deudas_home_icono.png', titleHtml: 'CONSOLIDADO DE TUS <span>DEUDAS</span>', comment: 'Conoce el <b>estado</b> y el <b>monto</b> de tus obligaciones.', url: '/pago?funn=HOME&plan=PER1' },
    { id: 6, plan: 'Plan Protege', name: 'Monitoreo darkweb', icon: 'assets/img/home/darkweb_home_icono.png', titleHtml: 'MONITOREO EN LA <span>DARKWEB</span>', comment: 'Protege tu información personal sobre <b>fugas</b> en la <b>darkweb</b>.', url: '/pago?funn=HOME&plan=ANT1' },
    { id: 7, plan: 'Plan Premium', name: 'Poliza antifraude', icon: 'assets/img/home/poliza_home_icono.png', titleHtml: 'PÓLIZA CON PROTECCIÓN <span>ANTIFRAUDE</span>', comment: 'Protege tu salud financiera contra el <b>fraude</b> y el <b>robo</b>.', url: '/pago?funn=HOME&plan=PRO1' }
  ];

  public benefitMain: benefitPlan = this.benefitsPlan[0];
  public benefitAfter: benefitPlan = this.benefitsPlan[1];
  public benefitBefore: benefitPlan = this.benefitsPlan[this.benefitsPlan.length - 1];

  public categoriesBc: Array<categorieBc> = [
    { id: 1, name: 'Libre inversion', img: 'assets/img/home/libre_inversion_bc_home_.png', icon: 'assets/img/home/creditos_icono_bc_home.svg', titleHtml: '¿GANAS DE <span>VACACIONES?</span>', comment: 'Conoce las ofertas de Crédito de <b>Libre Inversión</b>.', url: this.linkBc + 'categoria/creditos/libre-inversion?utm_source=MIDC&utm_medium=HOME&utm_campaign=BOXBC_CATEGORIA' },
    { id: 2, name: 'Creditos', img: 'assets/img/home/creditos_bc_home.png', icon: 'assets/img/home/creditos_icono_bc_home.svg', titleHtml: '¿CORTO DE <span>EFECTIVO?</span>', comment: 'Conoce las ofertas de Créditos <b>Rápidos</b>.', url: this.linkBc + 'categoria/creditos/libre-inversion?utm_source=MIDC&utm_medium=HOME&utm_campaign=BOXBC_CATEGORIA' },
    { id: 3, name: 'Hipotecarios', img: 'assets/img/home/hipotecarios_bc_home.png', icon: 'assets/img/home/hipotecario_icono_bc_home.svg', titleHtml: '¿QUIERES <span>CASA PROPIA?</span>', comment: 'Conoce las ofertas de Créditos <b>Hipotecarios</b>.', url: this.linkBc + 'categoria/creditos/vivienda?utm_source=MIDC&utm_medium=HOME&utm_campaign=BOXBC_CATEGORIA' },
    { id: 4, name: 'Tarjetas credito', img: 'assets/img/home/tarjetas_credito_bc_home.png', icon: 'assets/img/home/tarjeta_credito_icono_bc_home.svg', titleHtml: '¿BUSCANDO <span>TARJETAS?</span>', comment: 'Conoce las ofertas de <b>Tarjetas de Crédito</b>.', url: this.linkBc + 'categoria/tarjetas-credito?utm_source=MIDC&utm_medium=HOME&utm_campaign=BOXBC_CATEGORIA' }
  ];

  public categorieMain: categorieBc = this.categoriesBc[0];
  public categorieAfter: categorieBc = this.categoriesBc[1];
  public categorieBefore: categorieBc = this.categoriesBc[this.categoriesBc.length - 1];

  constructor(public router: Router, private dataLayerService: DataLayersService) { }

  ngOnInit(): void {
  }

  goHref(href: string, navigate: boolean = false) {
    if (!navigate) {
      window.location.href = href;
    } else {
      this.router.navigate([href]);
    }
  }

  moveBenefit(after: boolean) {
    let num = (after ? 1 : -1);
    let indexMain = (this.benefitMain.id - 1) + num;
    if (indexMain >= this.benefitsPlan.length) {
      indexMain = 0;
    }
    if (indexMain < 0) {
      indexMain = this.benefitsPlan.length - 1;
    }
    let indexAfter = (this.benefitAfter.id - 1) + num;
    if (indexAfter >= this.benefitsPlan.length) {
      indexAfter = 0;
    }
    if (indexAfter < 0) {
      indexAfter = this.benefitsPlan.length - 1;
    }
    let indexBefore = (this.benefitBefore.id - 1) + num;
    if (indexBefore >= this.benefitsPlan.length) {
      indexBefore = 0;
    }
    if (indexBefore < 0) {
      indexBefore = this.benefitsPlan.length - 1;
    }
    this.benefitMain = this.benefitsPlan[indexMain];
    this.benefitAfter = this.benefitsPlan[indexAfter];
    this.benefitBefore = this.benefitsPlan[indexBefore];
    setTimeout(() => {
      $(".titleCard").children('span').css({ 'background-color': '#ffffff', 'color': '#645193', 'display': 'inline-block' });
      if (this.benefitMain.titleHtml.length > 35) {
        $(".title-card-main").css({ 'font-size': '21px' })
        if (this.benefitMain.titleHtml.length > 43) {
          $(".title-card-main").css({ 'font-size': '15px' })
        }
        $(".title-card-main").children('span').css({ 'font-size': '30px' });
      } else {
        $(".title-card-main").css({ 'font-size': '30px' })
      }
      if (this.benefitAfter.titleHtml.length > 35) {
        $(".title-card-secon").css({ 'font-size': '12px' })
        $(".title-card-secon").children('span').css({ 'font-size': '21px' });
      } else {
        $(".title-card-secon").css({ 'font-size': '21px' })
      }
      if (this.benefitBefore.titleHtml.length > 35) {
        $(".title-card-secon").css({ 'font-size': '12px' })
        $(".title-card-secon").children('span').css({ 'font-size': '21px' });
      } else {
        $(".title-card-secon").css({ 'font-size': '21px' })
      }
    }, 10);
  }

  moveCategorie(after: boolean) {
    let num = (after ? 1 : -1);
    let indexMain = (this.categorieMain.id - 1) + num;
    if (indexMain >= this.categoriesBc.length) {
      indexMain = 0;
    }
    if (indexMain < 0) {
      indexMain = this.categoriesBc.length - 1;
    }
    let indexAfter = (this.categorieAfter.id - 1) + num;
    if (indexAfter >= this.categoriesBc.length) {
      indexAfter = 0;
    }
    if (indexAfter < 0) {
      indexAfter = this.categoriesBc.length - 1;
    }
    let indexBefore = (this.categorieBefore.id - 1) + num;
    if (indexBefore >= this.categoriesBc.length) {
      indexBefore = 0;
    }
    if (indexBefore < 0) {
      indexBefore = this.categoriesBc.length - 1;
    }
    this.categorieMain = this.categoriesBc[indexMain];
    this.categorieAfter = this.categoriesBc[indexAfter];
    this.categorieBefore = this.categoriesBc[indexBefore];
  }

  clic_home_service() {
    let name_event: any = {
      'midc': 'clic_home_MIDC_service',
      'bc': 'clic_home_BC_service',
      'pntd': 'clic_home_PAD_service',
    };
    let event = {
      'event': 'ga_event',
      'name_event': name_event[this.product],
    }
    this.dataLayerService.dl_event(event);
  }

  clic_home_app() {
    let event = {
      'event': 'ga_event',
      'name_event': 'clic_home_download_app',
    }
    this.dataLayerService.dl_event(event);
  }

  clic_home_shop_plan(plan: string) {
    let event = {
      'event': 'ga_event',
      'name_event': 'clic_home_shop_plan',
      'button_name': plan
    }
    this.dataLayerService.dl_event(event);
  }

  clic_home_two_services(texto:string){
    let event = {
      'event': 'ga_event',
      'name_event': 'clic_home_two_services',
      'button_name': texto,
      'site_name' : this.product.toUpperCase()
    }
    this.dataLayerService.dl_event(event);
  }


}
