import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-politicas-privacidad',
  templateUrl: './politicas-privacidad.component.html',
  styleUrls: ['./politicas-privacidad.component.scss']
})
export class PoliticasPrivacidadComponent implements OnInit {

  constructor() { 
    window.location.href = environment.enlaceS3 + environment.s3.politicaDePrivacidad;
   }

  ngOnInit() { }

}
