<div class="miscore" style="background-image: url(assets/img/images/Score.svg)" *ngIf="view == 'generic'">
  <div class="grafica m-auto d-flex mt-4">
      <img *ngIf="sesion.miscore" src="assets/img/images/Indicador.svg" class="img-fluid indicador" alt="indicador"
      [ngClass]="{
        'r-min': (sesion.miscore < 150),
        'r-i': (sesion.miscore == 150),
        'r1-1':  (sesion.miscore >= 151 && sesion.miscore <= 229),
        'r1':  (sesion.miscore == 230),
        'r1-2':  (sesion.miscore >= 231 && sesion.miscore <= 310),
        'r2-1':  (sesion.miscore >= 311 && sesion.miscore <= 389),
        'r2':  (sesion.miscore == 390),
        'r2-2':  (sesion.miscore >= 391 && sesion.miscore <= 470),
        'r3-1':  (sesion.miscore >= 471 && sesion.miscore <= 549),
        'r3':  (sesion.miscore == 550),
        'r3-2':  (sesion.miscore >= 551 && sesion.miscore <= 630),
        'r4-1':  (sesion.miscore >= 631 && sesion.miscore <= 709),
        'r4':  (sesion.miscore == 710),
        'r4-2':  (sesion.miscore >= 711 && sesion.miscore <= 790),
        'r5-1':  (sesion.miscore >= 791 && sesion.miscore <= 869),
        'r5':  (sesion.miscore == 870),
        'r5-2':  (sesion.miscore >= 871 && sesion.miscore <= 949),
        'r-f':  (sesion.miscore == 950),
        'r-max':  (sesion.miscore > 950)
      }">      
      <div class="score text-white d-flex m-auto">
        <div class="spinner" *ngIf="sesion.spinnerScore">
          <img src="assets/img/three-dots.svg" alt="Cargando"/>
        </div>
        <div class="txt-score" *ngIf="sesion.numberScore">
          <p>Mi Puntaje</p>
          <p *ngIf="sesion.miscore" class="number-score">{{ sesion.miscore | number }}</p>
          <p *ngIf="!sesion.miscore" class="number-score" id="counter1">0</p>
        </div>
      </div>
  </div>
</div>

<!-- score advance -->
<div class="miscore" style="background-image: url(assets/img/images/advanceScoreIcono.svg)" *ngIf="view == 'advance'">
  <div class="grafica m-auto d-flex mt-4" [ngClass]="{'home':home}">
      <img *ngIf="sesion.miscore" src="assets/img/images/indicadorAdvanceScore.svg" class="img-fluid indicador iAdvance" alt="mi score" [ngClass]="{'':addAnimationScore(),'home':home}">      
      <div class="score advance text-white d-flex m-auto">
        <div class="spinner" *ngIf="sesion.spinnerScore">
          <img src="assets/img/three-dots.svg" alt="cargando"/>
        </div>
        <div class="txt-score advance" *ngIf="sesion.numberScore"  >
          <p *ngIf="sesion.miscore" class="number-score" [ngClass]="{'home':home}">{{ sesion.miscore | number }}</p>
          <p *ngIf="!sesion.miscore" class="number-score" id="counter1" [ngClass]="{'home':home}">0</p>
          <p>MI PUNTAJE</p>
        </div>
      </div>
  </div>
</div>

<!-- score campain freemium -->
<div class="miscore" style="background-image: url(assets/img/images/Score.svg)" *ngIf="view == 'campainFree'" [ngClass]="{'campainFree':home}">
  <div class="grafica m-auto d-flex mt-4">     
      <div class="score text-white d-flex m-auto">
        <!-- <div class="spinner" *ngIf="sesion.spinnerScore">
          <img src="assets/img/three-dots.svg" alt="Cargando"/>
        </div> -->
        <div class="txt-score" *ngIf="sesion.miscore">
          <p>Mi Puntaje</p>
          <p *ngIf="sesion.miscore" class="number-score">{{ sesion.miscore | number }}</p>
          <p *ngIf="!sesion.miscore" class="number-score" id="counter1">0</p>
        </div>
      </div>
  </div>
</div>

<!-- modal Cómo funciona Advance? -->
<div class="modal bd-example-modal-lg fade" tabindex="-1" role="dialog" aria-labelledby="modalFun" aria-hidden="true" id="modalFun" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered mDialog" role="document">
      <div class="modal-content mContent">
          <div class="modal-body boxModal">
              <div class="contModal">
                  <div class="cabecera">
                  </div>
                  <div class="boxSide">
                      <h3 [innerHtml] = "modalInfo.title"></h3>
                      <div class="item">
                          <p class="txt" [innerHtml] = "modalInfo.cont"></p>
                      </div>
                      <div class="warnning">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                          <p class="txt" [innerHtml] = "modalInfo.alert"></p>
                      </div>
                      <button data-dismiss="modal" aria-label="Close">Entendido</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>