import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class AccionesdkwService {

  // Restricciones
  correos = 3;
  celulares = 3;
  documento = 1;
  pasaporte = 1;

  tituloEmail: any;
  botonEmail: any;
  placeholderEmail = 'Nuevo email';
  placeholderConfirmarEmail = 'Confirmar email';
  tipoEmail: any;

  tituloPhone: any;
  botonPhone: any;
  placeholderPhone = 'Nuevo teléfono';
  placeholderConfirmarPhone = 'Confirmar teléfono';
  tipoPhone: any;

  tituloCell: any;
  botonCell: any;
  placeholderCell = 'Nuevo celular';
  placeholderConfirmarCell = 'Confirmar celular';
  tipoCell: any;

  tituloP: any;
  botonP: any;
  placeholderP = 'Nuevo pasaporte';
  placeholderConfirmarP = 'Confirmar pasaporte';
  tipoP: any;

  position: any;

  dataEmail!: {
    email: string;
    confirmacionEmail: string;
  };

  dataPhone!: {
    phone: string;
    confirmacionPhone: string;
  };

  dataCell!: {
    cell: string;
    confirmacionCell: string;
  };

  dataP!: {
    P: string;
    confirmacionP: string;
  };

  constructor() {}

  // Correos
  popUpMail( tipo: number, email: any = null, position: any = null ) {
    switch (tipo) {
      case 1:
        this.tituloEmail = 'Añadir email';
        this.botonEmail = 'Añadir';
        this.tipoEmail = tipo;
        $('#accionesEmail').modal('show');
        break;
      case 2:
        this.tituloEmail = 'Modificar email';
        this.botonEmail = 'Modificar';
        this.tipoEmail = tipo;
        this.dataEmail.email = email;
        this.dataEmail.confirmacionEmail = email;
        this.position = position;
        $('#accionesEmail').modal('show');
        break;
    }
  }

  closePopUpMail() {
    $('#accionesEmail').modal('hide');
    this.tituloEmail = '';
    this.botonEmail = '';
    this.tipoEmail = null;
  }


  // Teléfonos

  popUpTel( tipo: number, tel: any = null, position: any = null ) {
    switch (tipo) {
      case 1:
        this.tituloPhone = 'Añadir teléfono';
        this.botonPhone = 'Añadir';
        this.tipoPhone = tipo;
        $('#accionesTel').modal('show');
        break;
      case 2:
        this.tituloPhone = 'Modificar teléfono';
        this.botonPhone = 'Modificar';
        this.tipoPhone = tipo;
        this.dataPhone.phone = tel;
        this.dataPhone.confirmacionPhone = tel;
        this.position = position;
        $('#accionesTel').modal('show');
        break;
    }
  }

  closePopUpTel() {
    $('#accionesTel').modal('hide');
    this.tituloPhone = '';
    this.botonPhone = '';
    this.tipoPhone = null;
  }

  // Celulares
  popUpCell( tipo: number, cell: any = null, position: any = null ) {
    switch (tipo) {
      case 1:
        this.tituloCell = 'Añadir celular';
        this.botonCell = 'Añadir';
        this.tipoCell = tipo;
        $('#accionesCell').modal('show');
        break;
      case 2:
        this.tituloCell = 'Modificar celular';
        this.botonCell = 'Modificar';
        this.tipoCell = tipo;
        this.dataCell.cell = cell;
        this.dataCell.confirmacionCell = cell;
        this.position = position;
        $('#accionesCell').modal('show');
        break;
    }
  }

  closePopUpCell() {
    $('#accionesCell').modal('hide');
    this.tituloCell = '';
    this.botonCell = '';
    this.tipoCell = null;
  }

  // Pasaporte
  popUpPass( tipo: number, pass: any = null, position: any = null ) {
    switch (tipo) {
      case 1:
        this.tituloP = 'Añadir pasaporte';
        this.botonP = 'Añadir';
        this.tipoP = tipo;
        $('#accionesPass').modal('show');
        break;
      case 2:
        this.tituloP = 'Modificar pasaporte';
        this.botonP = 'Modificar';
        this.tipoP = tipo;
        this.dataP.P = pass;
        this.dataP.confirmacionP = pass;
        this.position = position;
        $('#accionesPass').modal('show');
        break;
    }
  }

  closePopUpPass() {
    $('#accionesPass').modal('hide');
    this.tituloP = '';
    this.botonP = '';
    this.tipoP = null;
  }
}
