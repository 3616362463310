import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Plan } from 'src/app/core/models/plan.model';
import { BrokerService } from 'src/app/core/services/broker.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { DebtsService } from 'src/app/core/services/debts.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { SuscripcionService } from 'src/app/core/services/suscripcion.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';
import { environment } from 'src/environments/environment';
import { authorization } from 'src/app/core/models/authorization.model';
declare var $: any;

@Component({
	selector: 'app-historicov2',
	templateUrl: './historicov2.component.html',
	styleUrls: ['./historicov2.component.scss']
})
export class Historicov2Component implements OnInit {

	nombreCuenta = "";
	public planAnt = {} as Plan;
	public textsScore = [
		{ title: '¡Tu puntaje debería mejorar!', button: '¿Cómo puedo mejorar mi puntaje?', desc: 'Tu puntaje de crédito está por debajo del promedio de los colombianos.', scoreMin: 0, scoreMax: 310, scoreMinA: 0, scoreMaxA: 345 },
		{ title: '¡Tu puntaje podría ser mejor!', button: '¿Cómo puedo mejorar mi puntaje?', desc: 'Tu puntaje de crédito está cerca de llegar al promedio de los colombianos.', scoreMin: 311, scoreMax: 470, scoreMinA: 346, scoreMaxA: 470 },
		{ title: '¡Tu puntaje es promedio!', button: 'Ver qué impacta mi puntaje', desc: 'Tu puntaje de crédito está en el promedio de los colombianos.', scoreMin: 471, scoreMax: 630, scoreMinA: 471, scoreMaxA: 670 },
		{ title: '¡Puedes estar tranquilo!', button: 'Ver qué impacta mi puntaje', desc: 'Tu puntaje de crédito está por encima del promedio de los colombianos.', scoreMin: 631, scoreMax: 790, scoreMinA: 671, scoreMaxA: 815 },
		{ title: '¡Que buen puntaje!', button: 'Ver qué impacta mi puntaje', desc: 'Tu puntaje de crédito está por encima del promedio de los colombianos.', scoreMin: 791, scoreMax: 2000, scoreMinA: 816, scoreMaxA: 2000 },
	];

	public titleScore = "";
	public descScore = "";
	public txtButton = "";
	public planPromo: any;
	acceptAdvertising = false;
	totalVigentesAbiertas: any
	totalVigentesCerradas: any;
	public banners = ['buscacredito'];
	public bannerActual = 'buscacredito';
	txtDefault = true;
	txtAprroved = false;
	public textoMicroLoader = "Por favor espera un momento, estamos cargando tu historia de crédito."
	public payActive: any;
	public alertPayment = false;
	spinnerDebt = false;
	BoxDownloadPdf = true;
	textMessage = "";
	iconMessage = '';
	public onlyRec = [];
	public onlyCB: any = [];
	base64: any;
	public checkDivFree = false;
	public checkDivFree2 = false;
	public checkDivCuentas = false;
	public checkDivCuentasCerrada = false;
	public checkDivScore = false;
	public checkDivReclamaciones = false;
	commercial:any;
	public autorizacion = environment.enlaceS3 + environment.s3.autorizacionDatos;
	public autorizacion_opcional = environment.enlaceS3 + environment.s3.autorizacionDatosOpcional;

	constructor(public sesion: SesionService,
		public broker: BrokerService,
		public modal: ModalService,
		public loading: LoadingService,
		public suscripcion: SuscripcionService,
		public router: Router,
		public elementRef: ElementRef,
		public sanitizer: DomSanitizer,
		public utilities: UtilitiesService,
		private dataLayerService: DataLayersService,
		private debts:DebtsService
	) {
		if (this.sesion.resumenhc == undefined) {
			if (this.sesion.viewFree) {
				this.sesion.freepremium(true);
			} else {
				this.sesion.infoHC(true);
			}
		}else{
			this.debts.getDebtsPrintPadYa(this.sesion.allHistory)
		}

		this.planAnt = this.sesion.planes.planes.per;
		this.planPromo = {
			plan: 'per',
			id: 'planPerfilCreditoTarjeta',
			subNombre: "Tu entrenador financiero",
			nombre: this.planAnt.popup,
			valorMes: this.planAnt.mensual[1],
			valorTotal: this.planAnt.mensual[0],
			caracteristicas: this.planAnt.itemsV2,
			mensaje: this.planAnt.mensual[2],
			planGene: this.planAnt,
			tipo: '1',
			popular: true,
			mensajePop: ''
		}
	}

	@HostListener('window:scroll', ['$event'])
	onScroll(event: any) {
		if (window.pageYOffset !== 0 && window.innerWidth > 768) {
			$(".alert-promo").addClass("promo-fixed");
			$(".img-promo img").css({ "width": "50px" });
		} else {
			$(".alert-promo").removeClass("promo-fixed");
			$(".img-promo img").css({ "width": "60px" });
		}
	}

	ngOnInit() {

		// setTimeout(() => {
		// 	if (this.sesion.advertising == true) {
		// 		this.acceptAdvertising = true;
		// 	}
		// }, 2000);
		// GA4 - User
		this.dataLayerService.dl_userId('.', this.sesion.planCurrently);
		this.escojerBanner();
		this.payActive = localStorage.getItem("ac");
		if (this.payActive == 1) {
			this.alertPayment = true;
		}
		localStorage.clear();
		this.getAuthorizations()
	}
	checkBannerFree() {
		if (!this.checkDivFree) {
			let tamano = [[728, 90], [300, 250]];
			this.utilities.addPubliById(environment.slotsBanners.historicoFree, 'div-gpt-ad-1674161440396-0', tamano);
			this.checkDivFree = true;
		}
		return ''
	}
	checkBannerFree2() {
		if (!this.checkDivFree2) {
			let tamano = [[728, 90], [300, 250]];
			this.utilities.addPubliById(environment.slotsBanners.historicoFree2, 'div-gpt-ad-1687365436781-0', tamano);
			this.checkDivFree2 = true;
		}
		return ''
	}
	checkBannerFreeBeforeScore() {
		if (!this.checkDivScore) {
			let tamano = [[728, 90], [300, 50]];
			this.utilities.addPubliById(environment.slotsBanners.historicoScore, 'div-gpt-ad-1694398587898-0', tamano);
			this.checkDivScore = true;
		}
		return ''
	}
	guardarTerminosPublicidad() {
		
		if (!this.txtAprroved) {
			this.sesion.authorizations.filter(t => t.authorizationType === "commercial").forEach(ta => {
				ta.status = true
			});
			this.sesion.saveAcceptAdvertising();
		}
		$(".alert-promo").addClass("bg-check");
		this.txtDefault = false;
		this.txtAprroved = true;
		$(".btn-outline-white").addClass("btn-check");
		$(".btn-outline-white span").hide();
		$(".btn-outline-white i").addClass("i-check");
	}

	totalCA() {
		let totalAbiertas = 0;
		for (let i = 0; i < (this.sesion.resumenhc != undefined ? this.sesion.resumenhc.length : 0); i++) {
			let num = this.sesion.resumenhc[i].cuentasVigentes;
			totalAbiertas = num + totalAbiertas;
		}
		return totalAbiertas;
	}
	totalCC() {
		let totalCerradas = 0;

		for (let i = 0; i < (this.sesion.ctascerradas != undefined ? this.sesion.ctascerradas.length : 0); i++) {
			let num2 = this.sesion.ctascerradas[i].cuentasVigentes;
			totalCerradas = num2 + totalCerradas;
		}
		return totalCerradas;
	}
	// totalRe() {
	// 	let total = 0
	// 	total = (this.sesion.reclamaciones != undefined ? this.sesion.reclamaciones.length : 0);
	// 	return total;
	// }

	cuentasAbiertas() {
		return this.sesion.resumenhc;
	}

	cuentasCerradas() {
		return this.sesion.ctascerradas;
	}
	totalRe() {
		// let total = 0;
		this.onlyRec = [];
		if (this.sesion.reclamaciones != undefined) {
			this.onlyRec = this.sesion.reclamaciones.filter((ele: any) => ele.numero != "0");
		}
		// total = (this.sesion.reclamaciones != undefined ? onlyRec.length : 0);
		return this.onlyRec.length;
	}

	totalCB() {
		this.onlyCB = [];
		if (this.sesion.reclamaciones != undefined) {
			this.onlyCB = this.sesion.reclamaciones.filter((ele: any) => ele.numero == "0");
		}
		// total = (this.sesion.reclamaciones != undefined ? onlyRec.length : 0);
		return this.onlyCB.length;
	}
	tipoReporte(tipo: any) {
		let clase = ''
		switch (tipo) {
			case 'Negativo':
				clase = 'neg';
				break;
			case 'Positivo':
				clase = 'pos';
				break;
			default:
				break;
		}
		return clase
	}
	prueba() {
		alert('prueba')
	}

	abrirAcordeon(id: any) {
		var card = $("#" + id).parent();
		var show = card.children(".show")[0]
		$(".card-header").css({ "border-bottom": "1px solid #F1F1F7" });
		if (show != undefined) {
			$("#" + id).children("button").children("i").removeClass("fa-chevron-up");
			$("#" + id).children("button").children("i").addClass("fa-chevron-right");
		} else {
			card.children(".card-header").css({ "border-bottom": "0px" });
			$("#" + id).children("button").children("i").removeClass("fa-chevron-right");
			$("#" + id).children("button").children("i").addClass("fa-chevron-up");
		}
		this.checkBannerCuentas();
		if (id == 'headingTwo') {
			this.checkBannerCuentasCerradas();
		}
		if (id == 'headingThree') {
			this.checkBannerReclamaciones();
		}
	}

	// Banner Google Ads - cuentas abiertas (Usuario Freemium)
	checkBannerCuentas() {
		if (!this.checkDivCuentas) {
			let tamano = [[468, 60], [300, 50]];
			this.utilities.addPubliById(environment.slotsBanners.historicoFreeCuentas, 'div-gpt-ad-1689808214367-0', tamano);
			this.checkDivCuentas = true;
		}
		return ''
	}

	// Banner Google Ads - Cuentas cerradas (Usuario Freemium)
	checkBannerCuentasCerradas() {
		if (!this.checkDivCuentasCerrada) {
			let tamano = [[468, 60], [300, 50]];
			this.utilities.addPubliById(environment.slotsBanners.historicoFreeCuentasC, 'div-gpt-ad-1694798549134-0', tamano);
			this.checkDivCuentasCerrada = true;
		}
		return ''
	}

	// Banner Gooogle Ads - Reclamaciones (Usuario Freemium)
	checkBannerReclamaciones() {
		if (!this.checkDivReclamaciones) {
			let tamano = [[468, 60], [300, 50]];
			this.utilities.addPubliById(environment.slotsBanners.reclamacionesFree, 'div-gpt-ad-1694798296992-0', tamano);
			this.checkDivReclamaciones = true;
		}
		return ''
	}

	capitalizeFLetter(value: any) {
		var string = value.toLowerCase();
		return string.charAt(0).toUpperCase() +
			string.slice(1);
	}

	formatDate(date: any) {
		let dateAux = date.split("-")
		const d = new Date(dateAux[0], dateAux[1], dateAux[2]);
		const ye = new Intl.DateTimeFormat('es', { year: 'numeric' }).format(d);
		const mo = new Intl.DateTimeFormat('es', { month: 'short' }).format(d);
		const da = new Intl.DateTimeFormat('es', { day: '2-digit' }).format(d);
		return da + " " + mo.toUpperCase().substring(0, 3) + " " + ye.substring(2, 4);
	}

	irDetalle(nombre: any, detalle: any) {
		this.sesion.nombreCuenta = nombre.nombreCuenta;
		this.sesion.detalleCuentaFree = detalle;
		if (this.sesion.viewFree) {
			this.router.navigate(['freemium/detalle-cuenta']);
		} else {
			this.router.navigate(['private/detalle-cuenta']);
		}
	}

	goAlerts() {
		if (this.sesion.viewFree) {
			this.router.navigate(['freemium/generar-alerta']);
		} else {
			this.router.navigate(['private/generar-alerta']);
		}
	}
	addManyChat() {
		let script = document.createElement('script');
		script.src = "//widget.manychat.com/1897847597147648.js";
		script.async = true;
		this.elementRef.nativeElement.appendChild(script);
	}

	escojerBanner() {
		//let random = Math.floor(Math.random() * (3 - 1) + 1) - 1;
		//this.bannerActual = this.banners[random];
	}

	validarBuscacredito(titulo: any) {
		if (titulo.includes("crédito")) {
			return true;
		}
		return false;
	}

	irBuscacredito(titulo: any) {
		if (titulo.includes("crédito")) {
			window.open("https://buscacredito.midatacredito.com?utm_source=Midatacredito&utm_medium=HC&utm_campaign=Cuentas_abiertas", "_blank");
		}
	}

	showTextScore() {
		if (this.sesion.numberScore) {
			let auxScore;
			!this.sesion.dataUser.params[13].value ? auxScore = this.textsScore.find(ele => this.sesion.miscoreInt >= ele.scoreMin && this.sesion.miscoreInt <= ele.scoreMax) : auxScore = this.textsScore.find(ele => this.sesion.miscoreInt >= ele.scoreMinA && this.sesion.miscoreInt <= ele.scoreMaxA);
			if (auxScore != undefined) {
				this.titleScore = auxScore.title;
				this.descScore = auxScore.desc;
				this.txtButton = auxScore.button;
			}
		}
		return true;
	}

	nombreAlerta(codigoAlerta: string) {
		const nombreAlerta = this.sesion.alertasTipos.TipoAlerta.findIndex((obj: any) => obj.codigo === codigoAlerta);
		return this.sesion.alertasTipos.TipoAlerta[nombreAlerta][''];
	}

	downloadHC() {
		// Evento de GA4 - clic_inside_tool
		this.dataLayerService.dl_clic_button_name('clic_inside_tool', 'Puedes descargar tu Historia de crédito');
		var byteCharacters = atob(this.sesion.pdfDownload);
		var byteNumbers = new Array(byteCharacters.length);
		for (var i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}
		var byteArray = new Uint8Array(byteNumbers);
		var file = new Blob([byteArray], { type: 'application/pdf;base64' });
		var fileURL = URL.createObjectURL(file);
		window.open(fileURL);
	}

	imgCuenta(tipo: any) {
		var imgCuenta: any = {
			'AHO': 'assets/img/freepremium/tipo-producto/Bancos-CUENTA_AHORRO.svg',
			'TDC': 'assets/img/freepremium/tipo-producto/Bancos-TARJETA_CREDITO.svg',
			'CAB': 'assets/img/freepremium/tipo-producto/Bancos-PRESTAMO_BANCARIO.svg',
			'CAV': 'assets/img/freepremium/tipo-producto/Bancos-CARTERA_AHORRO_VIVIENDA.svg',
			'CCF': 'assets/img/freepremium/tipo-producto/Bancos-COMPANIA_FINANCIAMIENTO_COMERCIAL.svg',
			'CBR': 'assets/img/freepremium/tipo-producto/Bancos-PRESTAMO_ROTATIVO.svg',
			'SBG': 'assets/img/freepremium/tipo-producto/Bancos-PRESTAMO_ROTATIVO.svg',
			'CAU': 'assets/img/freepremium/tipo-producto/Bancos-PRESTAMO_VEHICULO.svg',
			'CCL': 'assets/img/freepremium/tipo-producto/Bancos-PRESTAMO_LEASING.svg',
			'CTC': 'assets/img/freepremium/tipo-producto/Telecomunicaciones.svg',
			'CDC': 'assets/img/freepremium/tipo-producto/Telecomunicaciones.svg',
			'CAC': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'CVE': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'COC': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'CAF': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'CEL': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'CDB': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'AGR': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'CMZ': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'FER': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'GRM': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'LAB': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'SEG': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'EDU': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'CON': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'CAS': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'CFE': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'LBZ': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'COF': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'CLB': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'CCC': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'CMU': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'DIC': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'CSP': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'ALI': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'COM': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'FUN': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'IND': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'MSJ': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'TRT': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'SFI': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'APD': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'MCR': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'CTU': 'assets/img/freepremium/tipo-producto/Servicios.svg',
			'CFR': 'assets/img/freepremium/tipo-producto/Servicios-FINCA_RAIZ.svg',
			'CCS': 'assets/img/freepremium/tipo-producto/Servicios-SEGUROS.svg',
			'PCS': 'assets/img/freepremium/tipo-producto/Servicios-PENSIONES_CESANTIAS.svg',
			'CSA': 'assets/img/freepremium/tipo-producto/Servicios-CAJA_COMPENSACION_SALUD.svg'
		}
		return imgCuenta[tipo];
	}

	goPlanRisk() {
		switch (this.sesion.risk.plan) {
			case 'PER':
				// this.modal.modalPerfil('HCR');
				this.seleccionarPlan(this.sesion.planes.planes.per, '1', this.sesion.planes.planes.per.mensual[1], this.sesion.planes.planes.per.mensual[0], 'HCS');
				break;
			case 'PRO':
				// this.modal.modalPlans('HCR');
				this.seleccionarPlan(this.sesion.planes.planes.pre, '1', this.sesion.planes.planes.pre.mensual[1], this.sesion.planes.planes.pre.mensual[0], 'HCS');
				break;
			default:
				// this.modal.modalPerfil('HCR');
				this.seleccionarPlan(this.sesion.planes.planes.per, '1', this.sesion.planes.planes.per.mensual[1], this.sesion.planes.planes.per.mensual[0], 'HCS');
				break;
		}
	}

	seleccionarPlan(plan: any, tipo: any, valorMensual: any, valorTotal: any, funn: string) {
		this.loading.open();
		// evento add_to_cart
		this.dataLayerService.dl_add_to_cart(plan, valorTotal, 'Mensual', 'HISTORICO');

		//this.broker.promov = 3;
		this.suscripcion.planSeleccionado = plan;
		this.suscripcion.tipoPlan = tipo;
		this.suscripcion.valorSeleccionadoMensual = valorMensual;
		this.suscripcion.valorSeleccionadoTotal = valorTotal;
		if (this.sesion.user) {
			this.suscripcion.planSesion(true, funn);
		} else {
			this.suscripcion.planSesion(false, funn);
		}
		this.loading.close();
	}

	go_compare() {
		// show_all_plans - DL Conocer todos los paquetes
		this.dataLayerService.dl_eventoGA4_1parametro('show_all_plans');
		this.router.navigateByUrl('/comparacion-planes');
	}

	public canCallApplyPadYa: boolean = true;

	openModalPadYa(detalleCa: any) {
		let buttonElement = document.getElementById('btn-padya-'+detalleCa.numeroCuenta);
		let spanText = (buttonElement?.childNodes[0] as HTMLElement);
		let spanLoader = (buttonElement?.childNodes[1] as HTMLElement);
		spanText.style.display = 'none';
		spanLoader.style.display = 'inline-block';
		if(this.canCallApplyPadYa){
			if((detalleCa.nameCTA != null && detalleCa.nameCTA != "") && detalleCa.paymentInstructions != null){
				if(detalleCa.paymentInstructions.length > 0){
					this.canCallApplyPadYa = false;
					this.modal.ctaPadYa = detalleCa.nameCTA;
					this.modal.paymentInstructionsPadYA = detalleCa.paymentInstructions;				
					this.modal.itemPadYa = detalleCa.item;
					this.modal.urlPadYa = detalleCa.enlacePad;
					this.modal.numberPadYa = detalleCa.numero;
					this.modal.estadotexto = detalleCa.estadoTexto;
					this.modal.saldoMora = detalleCa.saldoMora;
					this.modal.numeroCuenta = detalleCa.numeroCuenta;
					let num = detalleCa.item;
					let text = num.toString();
					this.utilities.getUtmParams(false);
					let sourceOfTraffic = '';
					let campaignOfTraffic = '';
					let mediumOfTraffic = '';
					if ((Object.keys(this.utilities.utmParams).length > 0)) {
						for (const key in this.utilities.utmParams) {
						if (key.toLowerCase() == 'utm_source') {
							sourceOfTraffic = this.utilities.utmParams[key]
						}
						if (key.toLowerCase() == 'utm_medium') {
							mediumOfTraffic = this.utilities.utmParams[key]
						}
						if (key.toLowerCase() == 'utm_campaign') {
							campaignOfTraffic = this.utilities.utmParams[key]
						}
						}
					}
					this.broker.registerApplyPAD(text,detalleCa.estadoTexto,detalleCa.saldoMora,detalleCa.numeroCuenta,sourceOfTraffic,campaignOfTraffic,mediumOfTraffic).subscribe(
					  {
						next: (data: any) => {
							if (data.code == 0) {
								this.modal.consumerNumber = data.consumerNumber;
								this.canCallApplyPadYa = true;
								spanText.style.display = 'block';
								spanLoader.style.display = 'none';
								this.modal.modalPadYa();
							  }
						},
						error: (error) => {
							this.canCallApplyPadYa = true;
							spanText.style.display = 'block';
							spanLoader.style.display = 'none';
						}
					  }
					  
					);
				}else{
					spanText.style.display = 'block';
					spanLoader.style.display = 'none';
				}
			}else{
				spanText.style.display = 'block';
				spanLoader.style.display = 'none';
			}
		}
	}

	isPadYa(detalleCa: any) {
		return (detalleCa.enlacePad != null && detalleCa.enlacePad != undefined && detalleCa.enlacePad != '');
	}
	getTip(fl:boolean){
		if(fl){
		  $('#contenTooltip').css("visibility", "visible")
		}else{
		  $('#contenTooltip').css('visibility', 'hidden')
		}
	}
	getAuthorizations(){
		this.commercial = this.sesion.authorizations.find((data: any) => data.authorizationType === "commercial")
		if(this.commercial != undefined && this.commercial.status == false){
			return true
		}else{
			return false;
		}	
	} 

}
