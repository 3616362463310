<section>
  <div class="container-fluid">
    <div class="row justify-content-end">
      <!-- Contenido principal. -->
      <div class="col-12 order-0 order-sm-1 raise-alert">
        <div class="row">
          <div class="col-12">
            <div class="tittle-alerts">
              <img src="assets/img/freepremium/crear-alertas/crear_alert.svg" alt="icono crear alerta"/>
              <h2>Crear alerta</h2>
            </div>
            <p>La alerta permanecerá por un período de seis (6) meses, si deseas modificar o eliminar tu alerta deberás
              acercarte a nuestro centro de experiencia ya sea personalmente o enviando una comunicación
              escrita con tu firma autenticada ante un notario.</p>
          </div>
          <div class="col-12" *ngIf="!sesion.loadAlerts || sesion.errorAlerts">
            <app-micro-loader [error]="sesion.errorAlerts" [textoError]="sesion.messDescription">
            </app-micro-loader>
          </div>
          <div class="col-12" *ngIf="sesion.loadAlerts">
            <h3>Formular tu alerta</h3>
            <div class="alert alert-danger" *ngIf="errorMessage">
              {{ errorMessage }}
            </div>
            <div *ngIf="sesion.alertasTipos">
              <div class="box-alert" *ngFor="let alerta of sesion.alertasTipos">
                <input name="alerta" id="alerta{{ alerta.row.COD_ALERTA }}" type="radio" class=""
                  (change)="seleccionarAlerta(alerta.row.COD_ALERTA, alerta.row.DESCRIPCION)"
                  (click)="showModal(alerta.row.COD_ALERTA)">
                <label class="" for="alerta{{ alerta.row.COD_ALERTA }}">
                  {{ alerta.row.DESCRIPCION }}
                </label>
              </div>
            </div>
            <div class="note-buttom">
              <div class="note">
                <img src="assets/img/freepremium/crear-alertas/nota.svg" alt="Nota crear alertas" />
                <p>Nota: Recuerda que para formular tu alerta es necesaria la aceptación de términos legales para la
                  inclusión de alertas, el cuál será visible una vez hagas clic en el botón Formular tu alerta.</p>
              </div>
              <div class="cta" *ngIf="!isIntermittence">
                <a target="_blank" class="btn-midc btn-orange h-lg-btn w-100-btn" id="guardarAlerta"
                  (click)="formularAlerta('FRMLRLRTS1')">
                  <span>Generar alerta</span>
                  <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </a>
              </div>
              <div class="alert-payment" *ngIf="isIntermittence">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
								<p>{{sesion.intermittenceClaimHour.messageAlert}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-container col-12" *ngIf="sesion.viewFree">
      <div id='div-gpt-ad-1694400137283-0' class="commercial-banner" [ngClass]="checkBanner()"></div>
    </div>
  </div>
</section>