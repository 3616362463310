import { Component, OnInit } from '@angular/core';
import { BrokerService } from 'src/app/core/services/broker.service';
interface sponsor {
  id: number,
  name: string,
  icon: string,
}
@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss']
})
export class SponsorsComponent implements OnInit {

  public sponsors: Array<sponsor> = [];
  constructor(public broker: BrokerService) { }

  ngOnInit(): void {
    this.getSponsors();
  }

  getSponsors() {
    this.broker.getSponsorsFeria().subscribe(
      {
        next: (data: any) => {
          this.sponsors = data.logos;
        },
        error: (error) => {
        }
      }
    );
  }

}
