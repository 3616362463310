<div class="content container-fluid bg-private pt-md-5 pt-5 pb-5">
  	<div class="row mt-5">
		<div class="col-md-4 col-12 imagen d-md-block d-none">
			<img src="assets/img/images/Poliza_icon.svg" class="w-100 ml-5 position-relative" alt="Poliza">
		</div>
    		<div class="col-md-8 col-12">
      		<div class="row">
        		<div class="col-12 poliza position-relative">
            		<div class="p-md-2 m-md-4">
            			<div class="row mdc-block-transparent py-2 mx-0">
              				<div class="col-10 col-md-4 my-auto mdc-brr-purple">
                  				<img src="assets/img/logos/SBS_logo.svg" class="mdc-icon w-75" alt="SBS logo">
              				</div>
							<div class="col-10 offset-2 col-md-8 offset-md-0 my-auto pl-md-5">
								<h1 class="mdc-text-secondary mdc-sesion-title">Cobertura productos plus</h1>
							</div>
            			</div>

            			<div class="contenido">
							<p class="font-weight-bold underline">Cobertura productos plus</p>
           					<p>"La presente información es una cotización y no constituye oferta o propuesta de contrato alguno, simplemente contiene los términos y condiciones en los cuales SBS Seguros Colombia S.A., estaría dispuesta a otorgar cobertura, previo cumplimiento de los requisitos y parámetros de suscripción exigidos por ella. Requisitos y parámetros que se entenderán cumplidos y aceptados sólo mediante comunicación escrita de SBS Seguros Colombia S.A., manifestando además su intención de asumir el riesgo".</p>

           					<h4 class="titulo">NUESTRO PRODUCTO</h4>

            				<p>SBS Seguros Colombia S.A., cuenta con una gran experiencia que ha permitido desarrollar productos que se ajustan a la necesidad de cada cliente. </p>

            				<p>Este producto específico busca cubrir sus necesidades de aseguramiento mediante coberturas y valores asegurados brindando beneficios al asegurado mediante la protección de su identidad y de sus finanzas</p>

							<div class="row mx-0 mb-4">
							<div class="col-md-7 col-12">
								<table class="w-100" aria-describedby="poliza">
									<thead>
										<tr>
											<th class="text-center" id="coberturas">
												<div class="double">
												Coberturas
												</div>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<div class="py-1 pl-3">
													Suplantación de identidad
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div class="py-1 pl-3">
													Falsificación/Adulteración y/o clonación de Tarjetas <br>(Gastos Fraudulentos)
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div class="py-1 pl-3">
													Tarjetas Perdidas / Robadas (Gastos Fraudulentos)
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div class="py-1 pl-3">
													Compras Fraudulentas por Internet (Gastos Fraudulentos)
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div class="py-1 pl-3">
													Hurto calificado en cajero
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div class="py-1 pl-3">
													Billetera protegida (Incluye Documentos)
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div class="py-1 pl-3">
													Muerte accidental por atraco en cajero
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div class="py-1 pl-3">
													Reembolso de gastos médicos por asalto en cajero automático
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div class="py-1 pl-3">
													Pérdida o Hurto de Documentos
												</div>
											</td>
										</tr>
										<tr>
											<td>
												<div class="py-1 pl-3">
													Orientación Tarjetas y/o documentos
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="col-md-5 col-12">
								<table class="w-100" aria-describedby="Límites">
								<thead>
									<tr>
										<th colspan="2" class="text-center" id="limites">
											<div class="py-1">
											Límites asegurados
											</div>
										</th>
									</tr>
									<tr>
										<th class="text-center" id="evento">
											<div class="py-1">
											Evento
											</div>
										</th>
										<th class="text-center" id="vigencia">
											<div class="py-1">
											Vigencia
											</div>
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td class="text-center" colspan="2">
											<div class="py-1">
											$ 6.000.000
											</div>
										</td>
									</tr>
									<tr>
										<td class="text-center" height="54">
											<div class="py-1" style="height: 54px; justify-content: center; display: flex; align-items: center;">
											$ 2.000.000
											</div>
										</td>
										<td class="text-center rowspan" rowspan="3">
											$ 4.000.000
										</td>
									</tr>
									<tr>
										<td class="text-center">
											<div class="py-1">
											$ 2.000.000
											</div>
										</td>
									</tr>
									<tr>
										<td class="text-center">
											<div class="py-1">
											$ 2.000.000
											</div>
										</td>
									</tr>
									<tr>
										<td class="text-center" colspan="2">
											<div class="py-1">
											$ 1.000.000
											</div>
										</td>
									</tr>
									<tr>
										<td class="text-center" colspan="2">
											<div class="py-1">
											$ 200.000
											</div>
										</td>
									</tr>
									<tr>
										<td class="text-center" colspan="2">
											<div class="py-1">
											$ 10.000.000
											</div>
										</td>
									</tr>
									<tr>
										<td class="text-center" colspan="2">
											<div class="py-1">
											$ 500.000
											</div>
										</td>
									</tr>
									<tr>
										<td class="text-center" colspan="2">
											<div class="py-1" >
											$ 2.000.000
											</div>
										</td>
									</tr>
									<tr>
										<td colspan="2" class="text-center">
											<div class="py-1">
											Incluido
											</div>
										</td>
									</tr>
								</tbody>
								</table>
							</div>
							</div>
							<p class="font-weight-bold">Edades de ingreso y permanencia cobertura</p>
							<p>
								<strong>Edad mínima de ingreso:</strong> 18 años.<br>
								<strong>Edad máxima de ingreso:</strong> 65 años.<br>
								<strong>Edad máxima de permanencia:</strong> 70 años y 364 días.<br>
							
							</p>
							<p class="font-weight-bold underline">Deducible</p>
							<p class="font-weight-bold">Suplantación de identidad: 10% sobre el valor de la pérdida.</p>
							<h4 class="titulo">DESCRIPCIÓN DE AMPAROS</h4>            
							<p class="font-weight-bold underline">Amparo Identidad Protegida</p>
							<p>La cobertura de Identidad Protegida ampara los gastos que se describen a continuación:</p>
							<p><span class="font-weight-bold">Orientación Jurídica Telefónica.</span> Se realizará mediante una conferencia telefónica una orientación jurídica en aspectos relativos a una suplantación de identidad, cuando el asegurado requiera adelantar una consulta básica en tales aspectos. Este servicio es de medio y no de resultado, y constituye una herramienta para aclarar situaciones de tipo legal.</p>
							<p class="font-weight-bold">Las siguientes coberturas aplican para los eventos que hayan ocurrido 3 meses antes del inicio de vigencia</p>
							<p><span class="font-weight-bold">Gastos legales.</span> Ampara exclusivamente los gastos de honorarios profesionales en que incurra el asegurado para:</p>

							<ul>
								<li>Defenderse en un juicio iniciado en su contra por cualquier persona natural o jurídica, en virtud de obligaciones económicas adquiridas supuestamente por el asegurado.</li>
								<li>Interponer recursos contra cualquier sentencia civil o penal dictada en contra del asegurado.</li>
								<li>Solicitar la corrección, precisión o eliminación de datos reportados a las centrales de información financiera.</li>
							</ul>

            				<p><span class="font-weight-bold">Obligaciones financieras.</span> Ampara el reembolso del monto de las obligaciones financieras que el asegurado haya pagado como resultado de cuentas bancarias abiertas, tarjetas de crédito adquiridas o créditos tomados a nombre del asegurado, sin su autorización.</p>

            				<p><span class="font-weight-bold">Gastos varios.</span> Cubrirá al asegurado, mediante reembolso, los siguientes gastos:</p>

							<ul>
								<li>El costo de documentos notariados, llamadas telefónicas de larga distancia y correo certificado, en los cuales incurra el asegurado como resultado de esfuerzos para reportar o corregir registros financieros y de crédito que hayan sido alterados.</li>
								<li>El costo de obtención de informes de crédito que sirvan como prueba de la suplantación de identidad. La entidad que aporte dicha información debe ser previamente aprobada por escrito por AIG seguros.</li>
							</ul>

            				<p class="font-weight-bold underline">Amparo Documentos Protegidos</p>

            				<p>La cobertura de Documentos Protegidos ampara los costos de reexpedición de los documentos personales del asegurado, exclusivamente por el hurto o pérdida de los mismos. Los siguientes son los documentos personales amparados:</p>
            
							<ul>
								<li>Cédula de Ciudadanía o de Extranjería.</li>
								<li>Pasaporte.</li>
								<li>Pase de Conducción.</li>
								<li>Libreta Militar.</li>
								<li>Tarjetas Crédito y/o Débito..</li>
								<li>Carné de la empresa</li>
							</ul>
            
            				<p class="font-weight-bold underline">Amparo Gastos Fraudulentos</p>
            
            				<p>La cobertura de Gastos Fraudulentos indemniza al asegurado por la pérdida de dinero por el cual el asegurado sea responsable, como resultado del uso indebido o fraudulento por parte de un tercero, debido al hurto calificado o pérdida de una tarjeta crédito y/o débito amparada y emitida a favor del asegurado, siempre y cuando ocurran 48 horas antes de la primera notificación del evento al emisor de la tarjeta crédito y/o débito.</p>

           					<p>Este amparo se extiende a cubrir el hurto que tenga su origen en la falsificación adulteración y/o clonación, de una tarjeta crédito y/o débito amparada y que supuestamente fueron emitidas por el asegurado y el posterior uso indebido o fraudulento de dichas tarjetas por parte de terceros; y aquellas compras fraudulentas realizadas por internet y que fueron realizadas por terceros siempre y cuando dichos hechos ocurran <b>10 días</b> calendario antes de la primera notificación del evento al emisor de la tarjeta crédito y/o débito.</p>

            
            				<h4 class="titulo">EXCLUSIONES</h4>
            				<p class="font-weight-bold underline">Exclusiones Generales</p>
            				<p>Las exclusiones generales del presente convenio se rigen de acuerdo con las condiciones generales y particulares de la Póliza Identidad Protegida; Póliza Plus Gastos Fraudulentos; Póliza Documentos Protegidos.</p>
            				<p class="font-weight-bold underline">Exclusiones Particulares Suplantación de Identidad</p>
            				<p>En ningún caso se amparan pérdidas o daños que en su origen o extensión sean causados por:</p>

							<ul>
								<li>Pérdidas monetarias que no sean gastos relacionados con la suplantación de identidad o que no se encuentren cubiertos bajo esta póliza.</li>
								<li>Cualquier lesión física, dolencia, enfermedad física o mental, incapacidad, shock, aflicción mental y lesión mental o muerte.</li>
								<li>Informes de crédito solicitados antes del descubrimiento de la suplantación de identidad.</li>
								<li>Lucro cesante que obedezca a los esfuerzos para corregir registros financieros que hayan sido alterados a causa de la suplantación de identidad, por parte del asegurado que es empresario y por lo tanto desarrolla una actividad autónoma.</li>
								<li>Perjuicios extrapatrimoniales incluyendo, pero no limitado a daños morales y perjuicio fisiológico o daños a la vida en relación.</li>
							</ul>

            				<p class="font-weight-bold underline">Exclusiones Particulares Gastos Fraudulentos</p>
            				<p>En ningún caso se amparan pérdidas o daños que en su origen o extensión sean causados por:</p>

							<ul>
								<li>Cargos fraudulentos con la tarjeta crédito y/o débito, que se hayan realizado en un periodo diferente al, expresamente indicados en la carátula de la póliza. En todo caso, antes de la primera notificación del evento al emisor de la tarjeta crédito y/o débito.</li>
								<li>Cargos fraudulentos con la tarjeta crédito y/o débito, que se hayan realizado después de la notificación del evento al emisor de la tarjeta crédito y/o débito.</li>
								<li>Hurto por parte de algún residente del hogar del asegurado, o una persona encomendada por el mismo.</li>
							</ul>

            				<p class="font-weight-bold underline">Exclusiones Particulares Documentos Protegidos</p>

            				<p>En ningún caso se amparan pérdidas o daños que en su origen o extensión sean causados por:</p>

							<ul>
								<li>Dinero, cheques y otros títulos valores, pasajes de transporte u otros ítems similares, que hayan sido perdidos o hurtados, que no fueran documentos de identificación personal y/o tarjetas débito o crédito del asegurado.</li>
								<li>Pérdidas causadas por cualquier evento que no sea una pérdida o hurto, tales como fuego, agua, uso cotidiano, defectos en su fabricación, plaga, insectos, limpieza o reparaciones ó eventos similares.</li>
								<li>Daño accidental a los documentos de identificación personal del asegurado.</li>
								<li>Cualquier compra, retiro o gasto, derivado de la utilización fraudulenta no autorizada de las tarjetas crédito y/o débito del asegurado, realizado con ocasión de la pérdida o hurto.</li>
							</ul>
            				<h4 class="titulo">CONDICIONES</h4>
							<p class="font-weight-bold">Condiciones particulares</p>
            				<ol>
								<li>El presente convenio reemplaza cualquiera realizado con anterioridad.</li>
								<li>Pago de prima mensual, trimestral y anual y cobro mensual vencido</li>
								<li>Cobertura por Reembolso.</li>
								<li>Esta cotización no contempla gastos de mercadeo, de ser asumidos por la Compañía, la tarifa deberá ajustarse, según corresponda.</li>
								<li>Las condiciones de esta propuesta establecen la venta mandatorio del producto a cada cliente que adquiera el producto de Mi Datacrédito.</li>
								<li>Cobertura Internacional.</li>
								<li>Máximo 1 evento por vigencia para cada cobertura, a excepción de la cobertura de asistencia.</li>
								<li>Para la cobertura de suplantación de identidad, se otorga cobertura de 3 meses de reactividad de cobertura para cada asegurado a partir de la fecha de compra de su plan.</li>
								<li>Para garantizar el adecuado funcionamiento de este producto es necesario que Mi Datacrédito genere un reporte a SBS Seguros con los siguientes datos de cada usuario y/o cliente:
									<ul>
										<li>Nombre y Apellidos Completos</li>
										<li>Número de Cédula</li>
										<li>Plan seleccionado (Mensual, Trimestral o Anual).</li>
										<li>Fecha en la que adquirió el servicio</li>
									</ul>
								</li>
								<li>La indemnización debe realizarse dentro de los doce meses siguientes al conocimiento de la suplantación de identidad.</li>
								<li>Es requisito que el tomador envíe en el tiempo pactado el listado de personas aseguradas, para contar con la cobertura.</li>
								<li>Revocación o no renovación, con aviso escrito previo de 60 días.</li>
								<li>Renovación Automática: El tomador y/o asegurado autoriza expresamente a SBS Seguros Colombia S.A. para renovar automáticamente la presente póliza por periodos, sucesivos iguales a los inicialmente contratados una vez finalizada la vigencia inicial de la póliza hasta tanto alguna de las partes no manifieste por escrito a la otra parte su intención de no renovarla o de revocar la respectiva póliza.</li>
								<li>Garantías Exigidas Al Tomador y/o Asegurado o Suministro oportuno de listado actualizado de asegurados en los términos de este documento. o Realizar oportunamente los reportes de ingresos y retiros en los términos de este documento. o Notificar cualquier agravación de riesgo conocida.</li>
								<li>El Asegurador no será responsable de proporcionar cobertura o hacer algún pago si el realizarlo pudiera ser considerado como una violación de alguna de las leyes o regulaciones de Sanciones Económicas, lo que expondría al Asegurador, su casa matriz o su entidad controladora a una sanción estipulada bajo estas leyes o regulaciones.</li>
							</ol>

							<h4 class="titulo">REQUERIMIENTO DE INFORMACIÓN</h4>
							<p>Es garantía de este seguro recibir, revisar y aceptar la siguiente información antes del inicio de vigencia.</p>
							<ol>
								<li>Confirmación por escrito del tomador aceptando las condiciones presentadas, indicado fecha inicio de vigencia, la cual en ningún caso podrá se retroactiva.</li>
								<li>Adjuntar base de asegurados y declaración de asegurabilidad diligenciada y firmada por cada asegurado. En los casos que aplica.</li>
								<li>De conformidad con lo establecido en la Circular Externa # 061 del 2007 expedida por la Superintendencia Financiera, todo asegurado debe diligenciar el formulario de conocimiento de cliente adjuntando los anexos requeridos de acuerdo con los lineamientos del Sistema de Administración del Riesgo de Lavado de Activos y Financiación del Terrorismo SARLAFT. </li>
							</ol>
            				<!-- <h4 class="titulo">OBLIGACIONES DEL ASEGURADO EN CASO DE SINIESTRO</h4>
            				<p>Al ocurrir algún siniestro que pudiese dar lugar a indemnización conforme a esta póliza, el asegurado tendrá las siguientes obligaciones: </p>
							<ul>
								<li>Ocurrido el siniestro, evitar su extensión, propagación y proveer el salvamento de los bienes asegurados si lo hubiese.</li>
								<li>Dar aviso a la compañía de la ocurrencia del siniestro dentro de los diez (10) hábiles siguientes a la fecha en que lo haya conocido o debido conocer, declarando los seguros coexistentes, si los hay, con indicación del asegurador y de la suma asegurada.</li>
								<li>Presentar a la compañía la reclamación formal, acompañada de las pruebas que demuestren la ocurrencia del siniestro, sus circunstancias y la cuantía de la pérdida.</li>
							</ul> -->

            				<h4 class="titulo">PAGO DE LA INDEMNIZACIÓN</h4>

            				<p>El valor de la indemnización corresponderá al valor real del perjuicio patrimonial sufrido por el asegurado, teniendo como límite el correspondiente a la suma asegurada.</p>
            
							<p>AIG Seguros pagará la indemnización a que esté obligada, dentro del mes siguiente a la fecha en que se acredite la ocurrencia del siniestro y la cuantía de la pérdida, si fuere el caso.</p>
							
							<p>Los documentos señalados a continuación no constituyen los únicos documentos viables para acreditar la ocurrencia del siniestro y la cuantía de la perdida, por lo tanto, el Asegurado y/o Beneficiario, según corresponda, queda en libertad de acreditar tanto la ocurrencia del siniestro como la cuantía de la perdida mediante la presentación de otros documentos legalmente idóneos.</p>
            
            				<p class="font-weight-bold underline">Pago de la indemnización Suplantación de Identidad</p>

							<ul>
								<li>Copia de la denuncia efectuada a la autoridad competente por el descubrimiento de la suplantación de identidad.</li>
								<li>Copia de la notificación a las entidades financieras en donde se hallan abierto cuentas bancarias, adquirido tarjetas de crédito o tomado créditos a nombre del asegurado, sin su autorización, y en consecuencia, como resultado de la suplantación de la identidad..</li>
								<li>Soportes de las cuentas bancarias abiertas, tarjetas de crédito adquiridas créditos tomados a nombre del asegurado, sin su autorización y como consecuencia directa de e la suplantación de identidad.</li>
							</ul>

							<p class="font-weight-bold underline">Pago de la indemnización Gastos Fraudulentos</p>

							<ul>
								<li>Copia de la denuncia efectuada ante la autoridad competente por el hurto calificado y/o perdida de la tarjeta crédito y/o débito.</li>
								<li>Copia de la notificación del reporte de aviso por el hurto calificado de la tarjeta crédito y/o débito a la entidad financiera emisora de las mismas.</li>
								<li>Soporte de los gastos fraudulentos realizados con la tarjeta crédito y/o débito.</li>
							</ul>

            				<p class="font-weight-bold underline">Pago de la indemnización Documentos Protegidos</p>

							<ul>
								<li>Copia de la denuncia ante la autoridad competente por la pérdida o hurto de los documentos personales.</li>
								<li>Copia de las facturas correspondientes a los costos de reexpedición de los documentos personales.</li>
							</ul>

							<h4 class="titulo">VALIDEZ DE LA OFERTA</h4>
							<p>De acuerdo con su solicitud respecto de la cuenta en referencia, a continuación, relacionamos nuestra cotización de seguro, la cual tiene una validez de treinta (30) días calendario, contados a partir de la fecha de la presente comunicación, pasado este tiempo carecerá de toda validez.</p>
							<p>La presente información es una cotización y no constituye oferta o propuesta de contrato alguno; simplemente contiene los términos y condiciones en los cuales la compañía estaría dispuesta a otorgar cobertura previo cumplimiento de los requisitos y parámetros de suscripción exigidos por la compañía. Tales requisitos y parámetros serán evaluados por SBS Colombia y sólo se podrán entender como aceptados mediante comunicación escrita de la misma manifestando su intención de otorgar cobertura.</p>

            				<p><b>POLÍTICA DE PRIVACIDAD:</b>  Con el propósito de proteger sus datos personales, SBS Seguros Colombia S.A (“SBS Seguros”) ha diseñado una Política de Privacidad que nos permite manejar adecuadamente los datos personales que recolectemos, almacenemos o actualicemos, así como compartirlos, dentro o fuera del territorio nacional, con sociedades del grupo o con entidades con las cuales trabajamos. Aquella información que nos suministre la utilizaremos para comunicarnos con usted y enviarle información sobre: nuestros productos y servicios, las actividades comerciales de SBS Seguros, asuntos relacionados con el contrato de seguro y aspectos relativos a la seguridad de la información recolectada por SBS Seguros. Usted cuenta con los derechos establecidos en la Ley 1581 de 2012 o demás normas que la modifiquen, adicionen o complementen, y en especial tiene derecho a conocer, actualizar y rectificar los datos e información suministrados y podrá revocar las autorizaciones que aquí constan en cualquier momento. Adicionalmente, se le informa que son facultativas las respuestas a las preguntas que se le han hecho o se le harán sobre datos personales sensibles (incluidos los relativos a la salud y biométricos) o sobre datos de niñas, niños y adolescentes; por lo cual usted no se encuentra obligado a responderlas o a autorizar su tratamiento.</p>

            				<p>Dando aceptación a los términos de la cotización por Usted solicitada, Usted reconoce que ello constituye un comportamiento inequívoco mediante el cual acepta la Política de Privacidad de Datos que ha sido diseñada por SBS Seguros y así mismo autoriza de manera expresa, informada e inequívoca a SBS Seguros y a las demás sociedades del grupo y/o terceros y/o terceros con quienes SBS Seguros sostenga relaciones jurídicas y/o comerciales relacionadas con su objeto social (incluidos proveedores, FASECOLDA, INIF, INVERFAS S.A., entre otros), establecidos dentro o fuera del territorio nacional, para que utilice(n) los datos personales, incluidos los sensibles, que voluntariamente nos ha suministrado con los fines antes descritos. De igual forma, Usted autoriza de manera expresa, informada e inequívoca a SBS Seguros a consultar y reportar información relativa a su comportamiento financiero, crediticio y/o comercial a centrales de información y/o bases de datos debidamente constituidas y corroborar la información aquí suministrada por cualquier medio legal. </p>

            				<p>La Política de Privacidad de SBS Seguros se encuentra disponible en <a class="mdc-text-secondary mdc-text-b" href="https://www.sbseguros.co/" target="_blank" rel="nooppener nofollow">www.sbseguros.co</a>, , puede solicitar una copia en la línea de Atención al Cliente 01 8000 911 360, mediante el correo electrónico <a href="mailto: servicio.cliente@sbseguros.co">servicio.cliente@sbseguros.co</a> o en las oficinas de SBS Seguros y se le agradece poder revisarla periódicamente. Si por alguna razón ha entregado a SBS Seguros información de otra persona, Usted certifica que está autorizado para ello y que compartirá con esa persona la Política de Privacidad de SBS Seguros Colombia.</p>
          				</div>
        			</div>
        		</div>
      		</div>
    	</div>
  	</div>
</div>