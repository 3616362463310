<div class="oneshot">
	<div class="container-fluid">
		<div class="row">			
			<div class="col-md-6 col-12 rightShot">
				<div class="principal">
          <img *ngIf="estado == 1 || estado == 2" src="assets/img/images/img_webcheckout_ok.svg" alt="check">
          <img *ngIf="estado == 0" src="assets/img/images/img_webcheckout_bad.svg" alt="check">
				</div>
			</div>
			<div *ngIf="estado == 1 || estado == 2" class="col-md-6 col-12 leftShot">
				<div class="logo">
					<img src="assets/img/logos/logo_midc.svg" alt="Midatacrédito" title="Logo Midatacrédito">
				</div>
				<div class="logoMob">
					<img src="assets/img/logos/logo_midc_white.svg" alt="Midatacrédito, consulta tu historia de crédito" title="Logo Midatacrédito">
				</div>
				<div class="steps-gray">
          <div *ngIf="estado == 1" class="iconEstado">
            <img src="assets/img/icons/ok_webcheckout_D.svg" alt="estado"/>
          </div>
          <div *ngIf="estado == 1" class="iconEstado-m">
            <img src="assets/img/icons/ok_webcheckout_M.svg" alt="estado" />
          </div>
          <div *ngIf="estado == 1" class="textEstado">
            <span>Pago exitoso</span>
          </div>
          <div *ngIf="estado == 2" class="textEstadoP">
            <span >Pago en proceso de validación</span>
          </div>
          <br/>
          <br/>
          <p  *ngIf="estado == 1">Haz renovado tu plan <b>{{plan}}</b> exitosamente.</p>
          <p  *ngIf="estado == 2">Estamos procesando el pago de tu <b>{{plan}}</b>.</p>
        </div>      
				<div class="content-webcheck">
          <!-- <p>Haz renovado tu plan <b>{{plan}}</b> exitosamente.</p> -->          
          <h3 *ngIf="estado == 1">Felicitaciones</h3>
          <h3 *ngIf="estado == 2">Estamos pendientes de recibir la confirmación de tu pago</h3>
          <br/>
          <p *ngIf="estado == 1">Recuerda que podrás seguir disfrutando de las siguientes funcionalidades:</p>
          <p *ngIf="estado == 2">Una vez recibamos la confirmación Exitosa de tu pago podrás acceder a Midatacrédito y empezar a disfrutar de sus beneficios.</p>
          <p *ngIf="falloServicio" style="color: red;">Hubo una falla en el sistema por favor haga una captura de pantalla para la activacion de su plan.</p>
					<div class="plan row">
						<div class="col-md-6 col-12 titulo-plan text-center">
							<div>
								<b>{{tituloPlan}}</b>
							</div>
						</div>
						<div class="col-md-6 col-12 contenido-plan">
							<div class="lista-caracteristica" *ngFor="let caracteristica of caracetristicasPlan" >
                <div class="iconCaracte">
                    <img src="assets/img/icons/feat_prem.svg" alt="icon">
                </div>
                <div class="caracteText">
                    <span>{{caracteristica}}</span>
                </div>                                 
              </div>
						</div>
					</div>
          <br/>
          <div class="btnMDC">
            <button class="btn btn-block mdc-btn-secondary" (click)="irHome()">
              Ir a midatacredito					
            <i class="fa fa-chevron-right float-right mt-arrow"></i>
          </button>
          </div>
				</div>
      </div>
      <div *ngIf="estado == 0" class="col-md-6 col-12 leftShot">
				<div class="logo">
					<img src="assets/img/logos/logo_midc.svg" alt="Midatacrédito" title="Logo Midatacrédito">
				</div>
				<div class="logoMob">
					<img src="assets/img/logos/logo_midc_white.svg" alt="Midatacrédito, consulta tu historia de crédito" title="Logo Midatacrédito">
				</div>
				<div class="steps-gray step-error">
          <div class="iconEstado">
            <img src="assets/img/icons/bad_webcheckout_D.svg" alt="check"/>
          </div>
          <div class="iconEstado-m">
            <img src="assets/img/icons/bad_webcheckout_M.svg" alt="check" />
          </div>
          <div class="textEstado no-exitoso">
            <span>Pago no exitoso</span>
          </div>
          <br/>
          <br/>
          <p>El pago de tu <b>{{plan}}</b> no fue exitoso.</p>
				</div>
				<div class="content-webcheck">
          <!-- <p>Haz renovado tu plan <b>{{plan}}</b> exitosamente.</p> -->
          <h3>No se ha podido cargar tu pago.</h3>
          <br/>
          <p>Se ha presentado un error en tu activación, no hemos podido recibir tu pago.</p>
					<br/>
					<button class="btn btn-block mdc-btn-secondary btn-error" (click)="reintentar()">
            Reintentar				
					<i class="fa fa-chevron-right float-right mt-arrow"></i>
        </button>
        <div class="ayuda">
          <div class="iconAyuda">
            <img src="assets/img/icons/help_webcheckout.svg" alt="check" title="check"/>
          </div>
          <div class="textAyuda">
            <p><b>Necesitas ayuda? <a href="https://www.midatacredito.com/contactanos">Haz clic aquí</a></b></p>
            <p>También puedes comunicarte con nuestra línea de atención: <b>6017430606</b> en Bogotá <b>018000 947 030</b> Línea Nacional.</p>
          </div>
        </div>
				</div>
			</div>
		</div>
	</div>
</div>
<app-footer></app-footer>
<app-loading></app-loading>