import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CampaignService } from 'src/app/core/services/campaign.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-lista-bc',
  templateUrl: './lista-bc.component.html',
  styleUrls: ['./lista-bc.component.scss']
})
export class ListaBcComponent implements OnInit {
  public offers: any = [];
  public category: any = [];
  tempo = [];
  error:boolean= false;
  loading:boolean=false;
  txtError:string = "En este momento estamos presentando problemas. Por favor inténtalo más tarde.";
  linkBc = environment.enlaceBuscaCredito+"ofertas/";
  
  constructor(public campaign: CampaignService, private datalayerService: DataLayersService, private router: Router ) { }

  ngOnInit(): void {
    this.getCategory();
  }

  getCategory(){
    this.loading=true;
    this.error=false;
    this.campaign.getCategory().subscribe({
      next: (cat: any) => {
        this.category = cat.categories;
        this.getOffers();
      },
      error: (error) => {
        this.error=true;
      }
    });
  }

  getOffers() {
    this.campaign.getListBc().subscribe({
      next: (result: any) => {
      this.loading=false;
        if (result.data) {
          this.offers = this.offers.concat(this.convertOffer(result.data,false));
        }else if (result.code = "1"){
          this.error=true;
          this.txtError =result.message;
        }else{
          this.error=true;
        }
      },
      error: (error) => {
        this.error=true;
      }
    });
  }

  convertOffer(offersList:any[], free = false) {
    let newOffersList:any = [];
    let cat:any;
    let subCat:any;
    offersList.forEach((offer:any) => {
      offer.offers.forEach((companyOffer:any) => {
        if(companyOffer.payOffer.statePayOffer){
          let offerAuxTmp:any = [];
        offerAuxTmp.company = offer.company;
        offerAuxTmp.card = companyOffer.card;
        offerAuxTmp.offerId = companyOffer.id;
        offerAuxTmp.detail = companyOffer.detail;
        cat = this.category.filter((ele:any) => ele.id == offerAuxTmp.detail.categoryId);
        subCat = (cat[0].subcategories != undefined)? subCat = []:cat[0].subcategories.filter((ele:any) => ele.acronyn == offerAuxTmp.detail.subcategories[0]);
        offerAuxTmp.urlRedirect = this.linkBc + cat[0].url + "/" + (subCat.length>0? subCat[0].url:"")+"?utm_source=midatacredito&utm_medium=boton_HC&utm_campaign=menu_freemium";
        newOffersList.push(offerAuxTmp);
        }
      });
    });
    return newOffersList;
  }

  setback(tarjeta:boolean,id:string,img:string){
    if(tarjeta){
      let div = "#offer"+id+":before {background-image:url("+img+");}";
      var styleElem = document.head.appendChild(document.createElement("style"));
      styleElem.innerHTML = div;
      return 'tarjeta';
    }else{
      return "";
    }
  }

  /**
   * Esta función envia un datalayer con información de la oferta, además redirige al sitio
   * de Buscacrédito
   */
  open_offer(offer_info: any){
    this.datalayerService.dl_select_content_bc('select_content', offer_info.card.productName, offer_info.offerId, offer_info.company.name);
    window.open(offer_info.urlRedirect, '_blank', 'noopener');
  }
}
