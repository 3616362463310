import { Component, OnInit, ViewChild } from '@angular/core';
import { IdentityAddData } from 'src/app/core/models/identity.addData.model';
import { BrokerService } from 'src/app/core/services/broker.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { SesionService } from 'src/app/core/services/sesion.service';
declare var $: any;

@Component({
  selector: 'app-presencia-internet',
  templateUrl: './presencia-internet.component.html',
  styleUrls: ['./presencia-internet.component.scss']
})
export class PresenciaInternetComponent implements OnInit {

  public listdk:any;
  public listDkLoad = false;
  public getDkLoad = false;
  public errorList = false;
  @ViewChild('datosInfo') datosInfo:any;
  public addInfoText = '';
  public confirmInfoText = '';
  public textoMicroLoader = 'Por favor espera un momento, estamos cargando tus notificaciones.';
  public textErrorMicroLoader = 'En este momento estamos presentando problemas. Por favor inténtalo más tarde.';
  public typeInfo = 'N';
  public textInfo = 'mail';
  public patternModal = '';
  public erroPattern = 'Error';
  public actionButton = 1;
  public textButton = 'Agregar';
  public positionInfo = 0;

  public textosInformacion = [
    { type: "IDSSN", desc: "Información documento de identidad comprometida en línea." },
    { type: "IDHOMEPHONE", desc: "Información de teléfono comprometida en línea." },
    { type: "IDWORKPHONE", desc: "Información de teléfono comprometida en línea." },
    { type: "IDCELLPHONE", desc: "Información de celular comprometida en línea." },
    { type: "IDEMAILADDR", desc: "Información de correo electrónico comprometida en línea." },
    { type: "IDPASSPORTNUM", desc: "Información de pasaporte comprometida en línea." },
  ]

  constructor(public sesion: SesionService, public broker: BrokerService, private loading: LoadingService, private dlService: DataLayersService) {
    this.createDKW();
  }

  ngOnInit() {
    // GA4 - User
		this.dlService.dl_userId( '.', this.sesion.planCurrently );
  }
  abrirAcordeon(id:any) {
    var card = $("#" + id).parent();
    var show = card.children(".show")[0]
    $(".card-header").css({ "border-bottom": "1px solid #F1F1F7" });
    if (show != undefined) {
      $("#" + id).children("button").children("i").removeClass("fa-chevron-up");
      $("#" + id).children("button").children("i").addClass("fa-chevron-right");
    } else {
      card.children(".card-header").css({ "border-bottom": "0px" });
      $("#" + id).children("button").children("i").removeClass("fa-chevron-right");
      $("#" + id).children("button").children("i").addClass("fa-chevron-up");
    }
  }

  getDescByType(type:any) {
    let descObject = this.textosInformacion.find(ele => ele.type == type);
    if(descObject != undefined){
      return descObject.desc;
    }
    return "";
  }

  listDKW(pagina: number = 1, items: number = 10) {
    this.listDkLoad = false;
    this.errorList = false;
    this.sesion.obtener_textosdkw();
    this.broker.listDKW(this.sesion.cookieValue, pagina, items).subscribe((data: any) => {
      if (data) {
        if (data.stlTransactionData.errorCode === 'OK') {
          this.listdk = data;
          this.listDkLoad = true;
        } else if (data.stlTransactionData.errorCode === 'ERROR') {
          this.createDKW();
        }
      }
      this.sesion.actualizandoListdk = false;
    },
      (error) => {
        this.errorList = true;
        // this.router.navigate(['/error']);
      }
    );
  }

  createDKW() {
    this.loading.open();
    this.errorList = false;
    this.broker.createDKW(this.sesion.cookieValue).subscribe((data: any) => {
      this.loading.close();
      if (data) {
        if (!data.stlTransactionData.errorCode.includes("ERR")) {
          this.listDKW();
          this.getDKW();
        } else {
          this.errorList = true;
        }
      }
    },
      (error) => {
        this.errorList = false;
        this.loading.close();
      }
    );
  }

  getDKW() {
    this.getDkLoad = false;
    this.broker.getDKW(this.sesion.cookieValue).subscribe((data: any) => {
      if (data.stlTransactionData.errorCode === 'OK') {
        if (!data.extTransactionData.identity.idEmailAddr) {
          data.extTransactionData.identity.idEmailAddr = [];
        }
        if (!data.extTransactionData.identity.idCellPhone) {
          data.extTransactionData.identity.idCellPhone = [];
        }
        if (!data.extTransactionData.identity.idPassportNum) {
          data.extTransactionData.identity.idPassportNum = [];
        }
        const model = this;
        setTimeout(function () {
          model.sesion.identity = data;
        }, 200);
        this.loading.close();
        this.getDkLoad = true;
      }
    },
      (error) => {
        // this.router.navigate(['/error']);
      }
    );
  }

  actionModal() {
    if (this.actionButton == 1) {
      this.addInfo();
    } else {
      this.deleteUpdateInfo(this.typeInfo, 2, this.positionInfo);
    }
  }

  addInfo() {
    let content: IdentityAddData;
    content = {
      session: this.sesion.cookieValue,
      serviceCode: '536',
      extTransactionData: {
        identity: {}
      }
    };

    switch (this.typeInfo) {
      case 'mail':
        content.extTransactionData.identity = {
          idEmailAddr: [
            this.confirmInfoText
          ]
        };
        break;
      case 'cellPhone':
        content.extTransactionData.identity = {
          idCellPhone: [
            this.confirmInfoText
          ]
        };
        break;
      case 'pass':
        content.extTransactionData.identity = {
          idPassportNum: [
            this.confirmInfoText
          ]
        };
        break;
      default:
        break;
    }
    if (this.typeInfo != 'N') {
      this.getDkLoad = false;
      this.closeModalDKW();
      this.broker.addDKW(content).subscribe((data: any) => {
        this.getDKW();
        this.listDKW();
      },
        (error) => {
        }
      );
    }
  }

  deleteUpdateInfo(typeInfo:any, action:any, info:any) {
    switch (typeInfo) {
      case 'mail':
        if (action == 3) {
          this.sesion.identity.extTransactionData.identity.idEmailAddr[info] = null;
        } else {
          this.sesion.identity.extTransactionData.identity.idEmailAddr[info] = this.confirmInfoText;
        }
        break;
      case 'cellPhone':
        if (action == 3) {
          this.sesion.identity.extTransactionData.identity.idCellPhone[info] = null;
        } else {
          this.sesion.identity.extTransactionData.identity.idCellPhone[info] = this.confirmInfoText;
        }
        break;
      case 'pass':
        if (action == 3) {
          this.sesion.identity.extTransactionData.identity.idPassportNum[info] = null;
        } else {
          this.sesion.identity.extTransactionData.identity.idPassportNum[info] = this.confirmInfoText;
        }
        break;
      default:
        break;
    }
    const content: IdentityAddData = {
      session: this.sesion.cookieValue,
      serviceCode: '501',
      extTransactionData: this.sesion.identity.extTransactionData
    };
    this.getDkLoad = false;
    this.closeModalDKW();
    this.broker.editDKW(content).subscribe((data: any) => {
      this.getDKW();
      this.listDKW();
    },
      (error) => {
      }
    );
  }

  openModalDKW(type:any, action:any, position:any = -1) {
    this.typeInfo = type;
    this.selectInfo(action, position);
    $("#modal-dkw").modal("show");
  }

  closeModalDKW() {
    this.typeInfo = 'N';
    this.addInfoText = '';
    this.confirmInfoText = '';
    this.positionInfo = 0;
    this.datosInfo.reset();
    $("#modal-dkw").modal('hide')
  }

  addDisabled() {
    if (this.addInfoText == '' && this.confirmInfoText == '') {
      return true;
    } else {
      if (this.confirmInfoText == this.addInfoText) {
        return false;
      }
      return true;
    }
  }

  selectInfo(action:any, position:any) {
    this.positionInfo = position;
    switch (action) {
      case 1:
        this.actionButton = 1;
        this.textButton = "Agregar";
        break;
      case 2:
        this.actionButton = 2;
        this.textButton = "Modificar";
        break;
      default:
        break;
    }
    switch (this.typeInfo) {
      case 'mail':
        this.textInfo = 'mail';
        this.patternModal = '[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$';
        this.erroPattern = 'Correo electrónico inválido.';
        if (this.actionButton == 2) {
          this.addInfoText = this.sesion.identity.extTransactionData.identity.idEmailAddr[position];
          this.confirmInfoText = this.sesion.identity.extTransactionData.identity.idEmailAddr[position];
        }
        break;
      case 'cellPhone':
        this.textInfo = 'celular';
        this.patternModal = '[0-9]{10,10}$';
        this.erroPattern = 'Celular inválido (10 dígitos).';
        if (this.actionButton == 2) {
          this.addInfoText = this.sesion.identity.extTransactionData.identity.idCellPhone[position];
          this.confirmInfoText = this.sesion.identity.extTransactionData.identity.idCellPhone[position];
        }
        break;
      case 'pass':
        this.textInfo = 'pasaporte';
        this.patternModal = '';
        this.erroPattern = 'Error';
        if (this.actionButton == 2) {
          this.addInfoText = this.sesion.identity.extTransactionData.identity.idPassportNum[position];
          this.confirmInfoText = this.sesion.identity.extTransactionData.identity.idPassportNum[position];
        }
        break;
      default:
        break;
    }
  }

}
