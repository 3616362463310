<div class="box-debt" [ngClass]="{'max-size':maxSize}">
    <div class="info-debt">
        <div class="section-info">
            <p class="title">{{debt.entityName}}</p>
        </div>
        <div class="vertical-separator" [ngClass]="{'d-none':!maxSize}">
        </div>
        <div class="section-info">
            <p class="title">{{debt.typDebt}}</p>
            <p>CTA No. {{debt.accountDebt}}</p>
        </div>
        <div class="vertical-separator" [ngClass]="{'d-none':!maxSize}">
        </div>
        <div class="section-info ">
            <div class="box-info">
                <div class="box-pop">
                    <button (click)="switchPop($event)">&#10005;</button>
                    <p>{{textTooltipState(debt.stateDebt)}}</p>
                    <i class="fa fa-sort-down"></i>
                </div>
                <button (click)="switchPop($event)" class="btn-tooltip">i</button>
            </div>
            <div>
                <p>Estado de Cartera:</p>
                <p [ngClass]="getColorState(debt.stateDebt)">{{textTransform(debt.stateDebt)}}</p>
            </div>
        </div>
        <div class="vertical-separator">
        </div>
        <div class="section-info">
            <div>
                <p>Tiempo en mora:</p>
                <p class="bold-black">{{debt.debtTime}}</p>
            </div>
            <div>
                <p>Saldo en mora:</p>
                <p class="bold-black">${{ debt.debtValue | currency:'COP':'':'1.0-0' }}</p>
            </div>
        </div>
    </div>
    <hr />
    <div>
        <button class="btnGeneric" [ngClass]="isAgree(debt.stateDebt)" (click)="callMethodCta()"><span
                *ngIf="!isLoadApplyPadYa">{{textCta()}}</span><span class="custom-loader"
                *ngIf="isLoadApplyPadYa"></span></button>
    </div>
</div>