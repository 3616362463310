import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-fraude',
  templateUrl: './fraude.component.html',
  styleUrls: ['./fraude.component.scss']
})
export class FraudeComponent implements OnInit {
	linkLogin = environment.enlaceLogin;

  constructor() { }

  ngOnInit(): void {
  }

}
