import { Component,Input, OnInit } from '@angular/core';
import { SesionService } from 'src/app/core/services/sesion.service';
import {  Router } from '@angular/router';
import { RoutingService } from 'src/app/core/services/routing.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
declare var $: any;

@Component({
  selector: 'app-dash-notification',
  templateUrl: './dash-notification.component.html',
  styleUrls: ['./dash-notification.component.scss']
})
export class DashNotificationComponent implements OnInit {
	@Input() id: string = "";

  constructor(public sesion: SesionService,public router: Router,public routing: RoutingService, private dataLayer: DataLayersService) { }

	ngOnInit(): void {
	}

	countNotification(){
		let count;
		count = [this.sesion.notificacionesActivas.filter((obj: any) => obj.checked === true)]
		return  !this.sesion.notificacionesActivas[7].checked?count[0].length:(count[0].length-1);
	}

	calNotPrivate(){
		return (this.sesion.proteccion || this.sesion.poliza)?10:9;
	}

	// Redireccionamiento y envio de data layer
	redirect_and_send_data_layer(button_name: string, url: string){
		this.dataLayer.dl_eventoGA4_menu_dashboard(button_name, 'Midatacrédito');
		this.routing.redirectAllAccess(0, url);
	}

}
