import { Component, OnInit } from '@angular/core';
import { SesionService } from '../../core/services/sesion.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { BrokerService } from '../../core/services/broker.service';
import { LoadingService } from '../../core/services/loading.service';
import { ModalService } from '../../core/services/modal.service';
import { environment } from '../../../environments/environment';
import { DataLayersService } from 'src/app/core/services/data-layers.service';

@Component({
  selector: 'app-confirmacion-pago',
  templateUrl: './confirmacion-pago.component.html',
  styleUrls: ['./confirmacion-pago.component.scss']
})
export class ConfirmacionPagoComponent implements OnInit {
  plan: any;
  tipoPago: any;
  protege: any;
  respuesta: boolean;
  password: any;
  validatePassword: any;
  urlRecuperar: string;

  constructor(
    public sesion: SesionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private broker: BrokerService,
    private modal: ModalService,
    private loading: LoadingService,
    private dataLayerService: DataLayersService ) {
      this.modal.close();
      this.loading.close();
      this.get_token_remarkety();
      this.urlRecuperar = environment.enlaceRecovery;
      this.protege = null;    
      let lsPlan = localStorage.getItem('planSeleccionado');
      if(lsPlan != null){
        this.plan = JSON.parse(lsPlan);
      }
      this.tipoPago = localStorage.getItem('tipoPago');
      this.protege = localStorage.getItem('protege');
      let url = this.router.url;
      url = url.split('?')[0];
      url = url.split('#')[0];

      if (this.sesion.confirmacion) {
        this.respuesta = true;
      } else {
        this.respuesta = false;
      }

      if (url === '/confirmacion-pago') {
        this.respuesta = false;
        this.sesion.getCookie();
        this.sesion.validateSession(false);
        this.sesion.infoPagos();
        switch ( this.tipoPago ) {
          case '1':
            if (this.sesion.confirmacion) {
              this.sesion.confirmacion.transactionResponse.operationDate = new Date();
              this.sesion.confirmacion.transactionResponse.TX_VALUE = this.sesion.confirmacion.transactionResponse.valueToPayString;            
              if (this.sesion.estadoTransaccion === true) {  
                this.sesion.confirmacion = {
                  code: 'SUCCESS',
                  transactionResponse: {
                    operationDate: new Date(),
                    state: 'APPROVED',
                    orderid:  this.sesion.confirmacion.transactionResponse.transactionId,
                    TX_VALUE: parseInt(this.sesion.confirmacion.transactionResponse.TX_VALUE.replace('.', '')),
                    TX_TAX: Math.round(parseInt(this.sesion.confirmacion.transactionResponse.TX_VALUE.replace('.', '')) * 0.19 / 1.19)
                  }
                };
                this.navigateToState( true, 'PAYU' );
              } else {
                this.sesion.estadoTransaccion = false;
                this.sesion.confirmacion = {
                  code: 'SUCCESS',
                  transactionResponse: {
                    operationDate: new Date(),
                    state: 'BAD',
                    orderid:  this.sesion.confirmacion.transactionResponse.transactionId,
                    TX_VALUE: parseInt(this.sesion.confirmacion.transactionResponse.TX_VALUE.replace('.', '')),
                    TX_TAX: Math.round(parseInt(this.sesion.confirmacion.transactionResponse.TX_VALUE.replace('.', '')) * 0.19 / 1.19)
                  }
                };
                this.navigateToState( false, 'PAYU' );
              }
            }
            break;
          case '2':
            if (this.sesion.confirmacion) {
              if ( this.sesion.estadoTransaccion === true) {
                this.sesion.confirmacion = {
                  code: 'SUCCESS',
                  transactionResponse: {
                    operationDate: new Date(),
                    state: 'Aceptado',
                    TX_VALUE: 0,
                    TX_TAX: 0
                  }
                };
                this.navigateToState( true, 'GC' );
              } else {
                this.sesion.confirmacion = {
                  code: 'BAD',
                  transactionResponse: {
                    operationDate: new Date(),
                    state: 'Rechazada',
                  }
                };
                this.navigateToState( false, 'GC' );
              }
            }
            break;
          case '3':
            this.activatedRoute.queryParams.subscribe( (params:any): any => {
              if ( params['transactionState'] ) {
                if ( params['transactionState'] === '4') {
                  this.sesion.estadoTransaccion = true;
                  this.sesion.confirmacion = {
                    code: 'SUCCESS',
                    transactionResponse: {
                      operationDate: new Date(),
                      state: 'Aprobada',
                      orderid: params['transactionId'],
                      TX_VALUE: params['TX_VALUE'],
                      TX_TAX: params['TX_TAX'],
                      pseBank: params['pseBank']
                    }
                  };
                  this.navigateToState( true, 'PSE', params );
                } else {
                  this.sesion.estadoTransaccion = false;
                  this.sesion.confirmacion = {
                    code: 'BAD',
                    transactionResponse: {
                      operationDate: new Date(),
                      state:  params['lapTransactionState'],
                      orderid: params['transactionId'],
                      TX_VALUE: params['TX_VALUE'],
                      TX_TAX: params['TX_TAX'],
                      pseBank: params['pseBank']
                    }
                  };
                  this.navigateToState( false, 'PSE', params );
                }
              }
            });
            break;
        }
      }
  }

  ngOnInit() {
    // GA4 - User
    this.dataLayerService.dl_userId( '.', this.sesion.planCurrently );
  }

  trackDataLayer( tipo: string ) {
    if(tipo == 'PAYU') {
      tipo = 'TC'
    }
    if (tipo == 'GC'){
      this.sesion.confirmacion.transactionResponse.orderid = 'No-order-id';
    }
    let funnUrl = localStorage.getItem('funn');
    this.dataLayerService.dl_purchase(this.sesion, tipo, funnUrl);
    this.dataLayerService.dl_send_mail_user(this.sesion.user.correo);
  }

  get_token_remarkety(){
    if (this.broker.remarkety_id == '' || this.broker.store_id == ''){
      this.sesion.loadInitialParameters();
    }
  }

  navigateToState( state: boolean, method: string, params: any = null ) {
    switch ( state ) {
      case true:
        this.cleanCookies();
        this.router.navigate(['/confirmacion-pago/aprobada']);
        this.trackDataLayer( method );
        this.order();
        break;
      case false:
        this.router.navigate(['/confirmacion-pago/rechazada']);
        if (!this.sesion.estadoTransaccion && this.sesion.confirmacion.transactionResponse.state === 'PENDING'){
          this.dl_payment_pending();
        } else if (!this.sesion.estadoTransaccion && this.sesion.confirmacion.transactionResponse.state !== 'PENDING'){
          this.dl_payment_failed();
        }
        break;
      default:
        this.router.navigate(['/private']);
        break;        
    }
    this.respuesta = true;
    //this.sesion.datosUsuario( false );
  }

  reintentar() {
    this.sesion.estadoTransaccion = false;
    this.router.navigate(['/private/pago']);
  }

  /**
   * Proceso de pago pendiente
   * GA4 - payment_pending
   */
  dl_payment_pending(){
    this.dataLayerService.dl_eventoGA4_1parametro('payment_pending');
  }

  /**
   * Proceso de pago fallido
   * GA4 - payment_failed
   */
  dl_payment_failed(){
    this.dataLayerService.dl_payment_failed('Pago rechazado');
  }

  cleanCookies() {
    this.sesion.deleteCookieServices( 'SERVICE', environment.domain );
    this.sesion.deleteCookieServices( 'UTMC', environment.domain );
    this.sesion.deleteCookieServices( 'REDIRECT', environment.domain );
  }

  pendingStatus(){
    if ((this.sesion.activeUser == 'PENDING' && this.router.url !== '/confirmacion-pago/aprobada') || (this.sesion.activeUser == 'PENDING' && this.router.url !== '/confirmacion-pago/rechazada')){    
        this.router.navigate(['/freemium/historico']);
        this.loading.close();
        this.sesion.alertPending = true;
    } else if (this.sesion.activeUser == 'ACTIVO' || this.sesion.activeUser == 'INPROCESS') {
      this.router.navigate(['/private/historico']);
    } else {
      this.router.navigate(['/freemium/historico']);
    }
  }

  order() {
    const MD5 = require("crypto-js/md5"); 
    const cDate = new Date(); 
    const email = this.sesion.user.correo;
    const product_id = this.sesion.planIdCart();
    const total_price = this.sesion.valorPlanTotal.replace('.','');  
    const orderId = MD5(email).toString(); //Sugiero usar una identificación basada en el email 
    const quantity = 1 ; //Siempre tendremos un solo producto por carrito 

    const Data1 = { 
      "id" : orderId, 
      "created_at" : cDate, 
      "customer" : { 
        "accepts_marketing" : true, 
        "created_at" : cDate , 
        "updated_at" : cDate, 
        "email" : email, 
      }, 
      "line_items": [{ 
        "product_id": product_id, 
        "quantity": quantity, 
        "sku" : "", 
        "name" : "", 
        "url" : "", 
        "image": "", 
        "price": total_price, 
        "currency" : "COP" 
      }], 
      "status":{"name": "Created"}, 
      "subtotal_price" : total_price, 
      "total_shipping" : 0, 
      "total_tax" : 0, 
      "total_price" : total_price 
    }; 

    this.broker.request_remarkety(Data1, 'orders/update').subscribe((result:any) => {
      },
      (error:any) => {}
    );
  }  

}
