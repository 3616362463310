import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BrokerService } from 'src/app/core/services/broker.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { WebActivityService } from 'src/app/core/services/web-activity.service';
import { Location } from '@angular/common';
import { LoadingService } from 'src/app/core/services/loading.service';
import { UpsellingService } from 'src/app/core/services/upselling.service';

declare var $: any;

@Component({
  selector: 'app-plan-upselling',
  templateUrl: './plan-upselling.component.html',
  styleUrls: ['./plan-upselling.component.scss']
})
export class PlanUpsellingComponent implements OnInit {

  planSelect: any;
  //Alerts
  alertCheck = false;
  alertError = false;
  userError = false;

  constructor(
    public broker: BrokerService,
    public sesion: SesionService,
    public router: Router,
    public _location: Location,
    private webActivity: WebActivityService,
    public loading: LoadingService,
    public upsell: UpsellingService
  ) {
    this.upsell.obtener_upselling()
  }

  ngOnInit() {
  }

  seleccionarPlan(plan:any) {
    this.planSelect = plan;
    this.webActivity.sendDataAWS("click-ver-elegirPlan" + this.planSelect.idPeriod, "UPSELL1", false);
  }

  confirmarPlan() {
    this.webActivity.sendDataAWS("click-cambiar-confirmarPlan" + this.planSelect.idPeriod, "UPSELL2", false);
    this.loading.open();
    this.broker.confirmar_plan(this.sesion.cookieValue, this.planSelect).subscribe((result) => {
      switch (result.errorCode) {
        case '0':
          this.alertCheck = true;
          window.scrollTo(0, 0);
          this.upsell.obtener_upselling();
          break;
        case '100':
          this.alertError = true;
          window.scrollTo(0, 0);
          this.loading.close();
          break;
        case '101':
          this.alertCheck = true;
          window.scrollTo(0, 0);
          this.upsell.plansUpsell = false;
          this.upsell.plansOthers = true;
          $(".titleH2").hide();
          this.loading.close();
          break;
        case '102':
          this.userError = true;
          window.scrollTo(0, 0);
          this.loading.close();
          break;
      }
    });
  }
  planSelectCheck() {
    switch (this.sesion.planCurrently) {
      case 'PER1':
        return 'Mejora tu puntaje';
      case 'ANT1':
        return 'Protege tus datos';
      case 'PRO1':
        return 'Protección Premium';
      case 'PER2':
        return 'Mejora tu puntaje';
      case 'ANT2':
        return 'Protege tus datos';
      case 'PRO2':
        return 'Protección Premium';
      case 'PER5':
        return 'Mejora tu puntaje';
      case 'ANT4':
        return 'Protege tus datos';
      case 'PRO4':
        return 'Protección Premium';
      default:
        return '';
    }
  }
}
