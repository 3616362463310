import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-micro-loader',
  templateUrl: './micro-loader.component.html',
  styleUrls: ['./micro-loader.component.scss']
})
export class MicroLoaderComponent implements OnInit {
  @Input() texto = "Por favor espera un momento, estamos cargando la información.";
  @Input() error = false;
  @Input() textoError = 'En este momento estamos presentando problemas. Por favor inténtalo más tarde.';
  @Input() onlySpinner = false;

  constructor() { }

  ngOnInit() {
  }

}
