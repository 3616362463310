import { Component, ElementRef, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { MetadatosService } from 'src/app/core/services/metadatos.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-servicio-cliente',
  templateUrl: './servicio-cliente.component.html',
  styleUrls: ['./servicio-cliente.component.scss']
})
export class ServicioClienteComponent implements OnInit {

  noAtencion = true;

  constructor(public sesion: SesionService,public elementRef:ElementRef, private utilities: UtilitiesService,
              private modal: ModalService, private metadatosService: MetadatosService,
              private titulo: Title, private meta: Meta, private dlService: DataLayersService ) {
      
    titulo.setTitle('Servicio al cliente - Midatacrédito');
    meta.updateTag({name: 'description', content:"Obtén asistencia y consulta de información en Midatacredito. Conoce nuestros canales de atención en Bogotá. Contáctanos por email o teléfono."});
  }

  ngOnInit() {
    this.metadatosService.actualizando_canonical('https://www.midatacredito.com/servicio-cliente');
    // GA4 - User
    if (this.sesion.user){
      this.dlService.dl_userId( '.', this.sesion.planCurrently );
    } else {
      this.dlService.dl_userId( null, 'anonimo' );
    }
    const today = new Date();
    const hour = today.getHours();
    const day = today.getDay();
    if ( day === 0 ) {
      this.noAtencion = true;
    } else {
      if ( day === 6 ) {
        if ( (hour >= 7 && hour < 13 ) ) {
          this.noAtencion = false;
          // this.activarChat();
        } else {
          this.noAtencion = true;
        }
      } else {
        if ( (hour >= 7 && hour < 19 ) ) {
          this.noAtencion = false;
          // this.activarChat();
        } else {
          this.noAtencion = true;
        }
      }
    }
    if(this.sesion.viewFree){
      this.addManyChat();
    }
  }

  addManyChat(){
    let script = document.createElement('script');
    script.src = "//widget.manychat.com/1897847597147648.js";
    script.async = true;
    this.elementRef.nativeElement.appendChild(script);
  }

  activarChat() {
    (function() {
        // var proto  = document.location.protocol || 'https:';
        var node   = document.createElement('script');
        node.type  = 'text/javascript';
        node.async = true;
        node.src   = 'https://webchat-i6.atento.com.co/v3/click_to_chat?token=40E3B70ADCF3466042D71E8CAC27EB29&container_id=idChatDatacredito';
        // node.src = 'https://appsweb.americasbps.com/ExperianChat';
        var s = document.getElementsByTagName('script')[0];
        if(s.parentNode != null){
          s.parentNode.insertBefore(node, s);
        }
    })();
  }

  svValidar() {
    if ( this.noAtencion ) {
      this.modalNoAtencion();
    }else{
      window.open("https://achat.americasbps.com:8344/webapi/WEBAPI85/ChatExperian/Inicio.jsp", "Chat", "popup=1,width=400,height=620");
    }
  }

  modalNoAtencion() {
    this.modal.open(
      'En este horario no podemos atenderte',
      // tslint:disable-next-line: max-line-length
      'Recuerda que nuestros horarios de atención son: <br> <ul class="mt-2 mdc-text-secondary font-weight-bold"> <li class="mdc-text-4"> Lunes a viernes de 7:00 am a 7:00 pm </li> <li class="mdc-text-4"> Sábados de 7:00 am a 1:00 pm </li> </ul>',
      'assets/img/images/Aviso_horarios_servicio.svg',
      'assets/img/icons/wait.svg',
      false,
      '',
      'Entendido',
      'ok'
    );
  }

  public checkDiv = false;
  checkBanner(){
    if(!this.checkDiv){
      let tamano = [[728, 90], [300, 250]];
      this.utilities.addPubliById(environment.slotsBanners.servicioCliente,'div-gpt-ad-1715290469293-0', tamano);
      this.checkDiv = true;
    }
    return ''
  }

}
