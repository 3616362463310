<div class="container-sm">
    <div class="row" *ngIf="sesion.isLoadHC">
        <div class="col-12">
            <app-micro-loader ></app-micro-loader>
        </div>
    </div>
    <div class="row" *ngIf="!sesion.isLoadHC && sesion.isLoadedDebts">        
        <div class="col-12">
            <app-micro-loader ></app-micro-loader>
        </div>
    </div>
    <div class="row" *ngIf="!sesion.isLoadHC && !sesion.isLoadedDebts">
        <div class="col-12" style="margin-bottom: 10px;">
            <div class="row head-pad-app">
                <div class="col-3 col-md-2" *ngIf="sesion.showModulePad">
                    <img src="assets/img/webviewapp/pad_webview_app.png" title="report" alt="report" />
                </div>
                <div class="col-3 col-md-2" *ngIf="!sesion.showModulePad">
                    <img src="assets/img/webviewapp/bc_webview_app.png" title="report" alt="report" />
                </div>
                <div class="col-9 col-md-10">
                    <h1>TUS REPORTES NEGATIVOS</h1>
                </div>
            </div>
        </div>
        <div class="col-12" *ngIf="sesion.showModulePad">
            <p>
                Con Ponte al día puedes negociar tus obligaciones en mora con nuestras entidades aliadas y poder recibir
                grandes descuentos.
            </p>
        </div>
        <div class="col-12" *ngIf="!sesion.showModulePad">
            <p>No tienes deudas por negociar con nuestras <b>entidades aliadas.</b></p>
            <p>Te ayudamos a llevar a otro nivel tu perfil crediticio. Conociendo ofertas de productos financieros ajustados a tu medida.</p>
        </div>
        <div class="col-12">
            <div class="box-info">
                <div>
                    <img src="assets/img/webviewapp/info_icono_app.svg" title="info" alt="info" />
                </div>
                <div>
                    <p>
                        Ten en cuenta que puedes tener aún reportes negativos, sin embargo, no están disponibles para
                        negociar con los aliados en Ponte al Día.
                    </p>
                    <p>
                        Puedes conocer tus reportes gratuitos de forma gratuita en Midatacrédito.com
                    </p>
                </div>
            </div>
        </div>
        <div class="col-12 text-center" *ngIf="!this.sesion.showModulePad" style="margin-bottom: 20px;">
            <button (click)="goToBC()" class="btn-bc">Conocer ofertas<i class="fa fa-chevron-right"></i></button>
        </div>
    </div>
    <div class="box-debts" *ngIf="!sesion.isLoadHC">
        <app-dash-pad-module [infoPadYa]="sesion.allHistory" [isApp]="true"></app-dash-pad-module>
    </div>
</div>