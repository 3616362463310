<section class="payment-method">
	<div class="container">
		<div class="row" *ngIf="summaryPay">
			<div class="col-12">
				<div class="alertInProcess" *ngIf="changeMethod.alertInprocess">
					<i class="fa fa-exclamation-circle" aria-hidden="true"></i>
					<p>
						En este momento estamos procesando una transacción con tu cuenta. 
						<span>Podrás actualizar o cambiar el método de pago cuando este proceso finalice.</span>
					</p>
				</div>
				<h2>Método de pago actual</h2>
				<div class="box-update">
					<div class="update">
						<h3 [innerHTML]="(changeMethod.infoMethodPay != undefined ? changeMethod.infoMethodPay.nuevoMetodoPago  : '') && (changeMethod.infoMethodPay.nuevoMetodoPago === 'PSE' ? 'Cuenta de ahorro / corriente' : 'Tarjeta de crédito')"></h3>
						<div class="methodPay" *ngIf="(changeMethod.infoMethodPay != undefined ? changeMethod.infoMethodPay.nuevoMetodoPago  : '') && (changeMethod.infoMethodPay.nuevoMetodoPago === 'PSE')">
							<div *ngIf="changeMethod.bancoSel != undefined">
								<img [src]="changeMethod.bancoSel.img" class="card-bank" alt="banco"/>
							</div>
							<div class="bank">
								<p> {{ (changeMethod.bancoSel != undefined ? changeMethod.bancoSel.banco : '') && (changeMethod.bancoSel.banco )}} </p>
								<p> Cuenta No. {{ (changeMethod.infoMethodPay != undefined ? changeMethod.infoMethodPay.detallesDatos.numeroCuenta : '') && (changeMethod.infoMethodPay.detallesDatos.numeroCuenta) }} </p>
							</div>							
						</div>
						<div class="methodPay" *ngIf="(changeMethod.infoMethodPay != undefined ? changeMethod.infoMethodPay.nuevoMetodoPago  : '') && (changeMethod.infoMethodPay.nuevoMetodoPago === 'TC')">
							<div>
								<img src="assets/img/pay/L-Visa.svg" alt="VISA" class="card-bank" *ngIf="(changeMethod.payUTcConsult != undefined ? changeMethod.payUTcConsult.type  : '') && (changeMethod.payUTcConsult.type == 'VISA')" />
								<img src="assets/img/pay/L-MasterCard.svg" alt="MASTERCARD" class="card-bank" *ngIf="(changeMethod.payUTcConsult != undefined ? changeMethod.payUTcConsult.type  : '') && (changeMethod.payUTcConsult.type == 'MASTERCARD')" />
								<img src="assets/img/pay/L-AmericanExpress.svg" alt="AMERICAN EXPRESS" class="card-bank" *ngIf="(changeMethod.payUTcConsult != undefined ? changeMethod.payUTcConsult.type  : '') && (changeMethod.payUTcConsult.type == 'AMERICANEXPRESS')" />
								<img src="assets/img/pay/L-DinersClub.svg" alt="DINERSCLUB" class="card-bank" *ngIf="(changeMethod.payUTcConsult != undefined ? changeMethod.payUTcConsult.type  : '') && (changeMethod.payUTcConsult.type == 'DINERSCLUB')" />
							</div>
							<div class="bank">
								<p> Tarjeta No. ******{{ (changeMethod.payUTcConsult != undefined ? changeMethod.payUTcConsult.number  : '') && (changeMethod.payUTcConsult.number | slice: 6:16) }} </p>
							</div>	
						</div>
						<a
							id="actualizarMetodoPago" webActivity data-idtag="cambioPago" data-nametag="actualizarMetodoPago" *ngIf="changeMethod.btnActived"
							class="btn-midc btn-orange2 w-40-btn h-md-btn" (click)="tcChangeClick1()" [innerHTML]="(changeMethod.infoMethodPay != undefined ? changeMethod.infoMethodPay.nuevoMetodoPago  : '') && (changeMethod.infoMethodPay.nuevoMetodoPago === 'PSE') ? 'Actualizar Cuenta' : 'Actualizar Tarjeta' ">							
							<i class="fa fa-chevron-right"></i>
						</a>
						<a
							id="actualizarMetodoPago" webActivity data-idtag="cambioPago" data-nametag="actualizarMetodoPago" *ngIf="changeMethod.btnDisabled"
							class="btn-midc btn-orange2 w-40-btn h-md-btn disabled" [innerHTML]="(changeMethod.infoMethodPay != undefined ? changeMethod.infoMethodPay.nuevoMetodoPago  : '') && (changeMethod.infoMethodPay.nuevoMetodoPago === 'PSE') ? 'Actualizar Cuenta' : 'Actualizar Tarjeta' ">							
							<i class="fa fa-chevron-right"></i>
						</a>  
					</div>
					<div class="plan">
						<h4>Tu plan</h4>
						<p *ngIf="sesion.planCurrently == 'PER1'">
							<span>Mejora tu puntaje</span>
							 por ${{ sesion.planes.planes?.per?.mensual?.[0] }}/mes
						</p>
						<p *ngIf="sesion.planCurrently == 'PER2'">
							<span>Mejora tu puntaje</span>
							por ${{ sesion.planes.planes?.per?.trimestral?.[0] }}/trimestral
						</p>
						<p *ngIf="sesion.planCurrently == 'PER3' || sesion.planCurrently == 'PER4' || sesion.planCurrently == 'PER5'">
							<span>Mejora tu puntaje</span>
							por ${{ sesion.planes.planes?.per?.anual?.[0] }}/anual
						</p>
						<p *ngIf="sesion.planCurrently == 'ANT1'">
							<span>Protege tus datos</span>
							 por ${{ sesion.planes.planes?.ant?.mensual?.[0] }}/mes
						</p>
						<p *ngIf="sesion.planCurrently == 'ANT2'">
							<span>Protege tus datos</span>
							por ${{ sesion.planes.planes?.ant?.trimestral?.[0] }}/trimestral
						</p>
						<p *ngIf="sesion.planCurrently == 'ANT3' || sesion.planCurrently == 'ANT4'">
							<span>Protege tus datos</span>
							por ${{ sesion.planes.planes?.pre?.anual?.[0] }}/anual
						</p>
						<p *ngIf="sesion.planCurrently == 'PRO1'">
							<span>Protección premium</span>
							 por ${{ sesion.planes.planes?.pre?.mensual?.[0] }}/mes
						</p>
						<p *ngIf="sesion.planCurrently == 'PRO2'">
							<span>Protección premium</span>
							por ${{ sesion.planes.planes?.pre?.trimestral?.[0] }}/trimestral
						</p>
						<p *ngIf="sesion.planCurrently == 'PRO3' || sesion.planCurrently == 'PRO4'">
							<span>Protección premium</span>
							por ${{ sesion.planes.planes?.ant?.anual?.[0] }}/anual
						</p>
						<h4>Tu próxima factura</h4>
						<p>
							<span>{{ sesion.dateToday | date: 'dd MMMM yyyy' }}</span>
						</p>
					</div>
				</div>
			</div>
			<div class="col-12">
				<h2>Cambiar método de pago</h2>
				<div class="types" [ngClass]="sesion.estadoUserF == 'ACTIVE_PROCESS' || sesion.estadoUserF == 'PENDING' || sesion.estadoUserF == 'PENDING_PSE' || sesion.estadoUserF == 'INPROCESS' ? 'disabled-method' : '' ">
					<div class="payment-type" (click)="tcChangeClick()" 
						*ngIf="(changeMethod.infoMethodPay != undefined ? changeMethod.infoMethodPay.nuevoMetodoPago  : '') && (changeMethod.infoMethodPay.nuevoMetodoPago === 'PSE')"
						id="cambiarTC">
						<div class="content-box cambioMetodoTC">
							<div class="type cambioMetodoTC">
								<h3 class="cambioMetodoTC">Tarjeta de crédito</h3>
							</div>
							<div class="franchise cambioMetodoTC">
								<img src="assets/img/pay/L-Visa.svg" alt="VISA" class="cambioMetodoTC"/>
								<img src="assets/img/pay/L-MasterCard.svg" alt="MASTER CARD" class="cambioMetodoTC"/>
								<img src="assets/img/pay/L-AmericanExpress.svg" alt="AMERICAN EXPRESS" class="cambioMetodoTC"/>
								<img src="assets/img/pay/L-DinersClub.svg" alt="DINNERS" class="cambioMetodoTC"/>
							</div>
						</div>                    
						<div class="cta cambioMetodoTC">
							<img src="assets/img/pay/cta-detalle.svg" alt="go" class="cambioMetodoTC"/>
						</div>
					</div>
					<div class="payment-type" (click)="pseChangeClick()" *ngIf="(changeMethod.infoMethodPay != undefined ? changeMethod.infoMethodPay.nuevoMetodoPago  : '') && (changeMethod.infoMethodPay.nuevoMetodoPago === 'TC')"
					id="cambiarPSE">
						<div class="content-box cambiarMetodoPSE">
							<div class="type cambiarMetodoPSE">
								<h3 class="cambiarMetodoPSE">Cuenta de ahorro / corriente</h3>
							</div>
							<div class="franchise cambiarMetodoPSE">
								<img src="assets/img/pay/L-PSE.svg" alt="PSE" class="cambiarMetodoPSE"/>
							</div>
						</div>                    
						<div class="cta cambiarMetodoPSE">
							<img src="assets/img/pay/cta-detalle.svg" alt="go" class="cambiarMetodoPSE"/>
						</div>
					</div>
				</div>
			</div>			
		</div>
		<div class="row" *ngIf="backNav">
			<div class="col-12">
				<a class="back" (click)="backClick()">
					<i class="fa fa-arrow-left" aria-hidden="true"></i>
					Volver a métodos de pago
				</a>
			</div>
		</div>
		<div class="row" *ngIf="tcChange">
			<div class="col-12">
				<app-tc-change></app-tc-change>
			</div>
		</div>
		<div class="row" *ngIf="pseChange">
			<div class="col-12">
                    	<app-pse-change></app-pse-change>
			</div>
		</div>
	</div>
</section>
