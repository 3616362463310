import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { environment } from '../environments/environment';
import { SesionService } from './core/services/sesion.service';
import { WebActivityService } from './core/services/web-activity.service';
import { Subscription } from 'rxjs';
import { CookiesGoogleService } from './core/services/cookies-google.service';

declare var $: any;

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  subscription: Subscription;
  flagPagos = false;
  flagApp = false;

  constructor(
    private sesion: SesionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private webActivity: WebActivityService,
    private cookiesGoogle: CookiesGoogleService ) {
    // this.sesion.obtener_planes();

    if (environment.gtmPruebas) {
      const script = document.createElement('script');
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-5H395HG');`;
      document.head.appendChild(script);

      const noscript = document.createElement('noscript');
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5H395HG"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(noscript);
    } else {

      const script = document.createElement('script');
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-KJMJT32');`;
      document.head.appendChild(script);
      //inspector.trackSchemaFromEvent("Event Name", {"prop name": "prop value"});

      const noscript = document.createElement('noscript');
      noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KJMJT32"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      document.body.appendChild(noscript);
    }

    if (environment.production) {
      // tslint:disable-next-line: only-arrow-functions
      $(document).on('contextmenu', function (e: any) {
        e.preventDefault();
      });
      // tslint:disable-next-line: only-arrow-functions
      $(document).keydown(function (event: any) {
        if (event.keyCode === 123) { // Prevent F12
          return false;
        } else if (event.ctrlKey && event.shiftKey && event.keyCode === 73) { // Prevent Ctrl+Shift+I
          return false;
        }
        return true;
      });
    }
    this.subscription = router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
  }

  ngOnInit() {
    if (window.location.href.includes("webcheckout")) {
      return;
    }

    this.router.events.subscribe((evt: any) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if ((evt instanceof NavigationEnd)){
        this.cookiesGoogle.verificarCookies();
      }
      window.scrollTo(0, 0);
      this.webActivity.sendDataAWS("load-loadRoute", "", false);
      this.router.url.split('?')[0]  === "/pago"?this.flagPagos = true : this.flagPagos=false;
      this.router.url.split('?')[0]  === "/landing/politica-terminos"?this.flagApp = true : this.flagApp=false;
    });

    this.sesion.getCookie();
    if (this.sesion.cookieValue) {
      // this.sesion.validateSession( true );
      // tslint:disable-next-line: only-arrow-functions
      setInterval(() => { this.sesion.validateSession(false); }, 300000);
    }

    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params.utm_campaign) {
        this.sesion.setCookieServices('UTMC', params.utm_campaign);
      }
    });
  }

}
