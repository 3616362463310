import { Component, OnInit } from '@angular/core';
import { SesionService } from '../../core/services/sesion.service';
import { NgForm } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Subscription } from 'rxjs';
import { MetadatosService } from 'src/app/core/services/metadatos.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { Meta, Title } from '@angular/platform-browser';

declare var $: any;
declare var Firebase: any;

@Component({
  selector: 'app-contactanos',
  templateUrl: './contactanos.component.html',
  styleUrls: ['./contactanos.component.scss']
})
export class ContactanosComponent implements OnInit {

  search: any = null;
  nosearch = false;

  // FORMULARIO
  validCaptcha: any;
  errorMessage: any;

  // CHATBOT VARIABLES
  initchat = false;
  Fake = [
      ''
  ];

  $messages = $('.messages-content');
  d:any;
  h:any;
  m:any;
  i = 0;

  root = 'https://chat-f569a.firebaseio.com/';
  pb:any;
  that = '';
  init = true;
  conver = false;
  terms = false;

  private singleExecutionSubscription: Subscription= new Subscription;

  constructor( public sesion: SesionService,private recaptchaV3Service: ReCaptchaV3Service, private metadatosService: MetadatosService, private dlService: DataLayersService,
               private titulo: Title, private meta: Meta ) {

    this.sesion.obtener_pqrs();

    this.sesion.contacto = {
      fullName: '',
      email: '',
      documentType: 'CC',
      documentNumber: '',
      commentary: '',
      "captcha-response": ''
    };

    titulo.setTitle('¿Estás reportado en Datacrédito? Descúbrelo');
      meta.updateTag({name: 'description', 
        content:"Con Midatacrédito, saber si estás reportado en DataCrédito es rápido y sencillo. Regístrate y averígualo hoy. No dejes que un error afecte tu historia crediticia."});
  }

  ngOnInit() {
    this.metadatosService.actualizando_canonical('https://www.midatacredito.com/contactanos');
    // GA4 - User
    if (this.sesion.user){
      this.dlService.dl_userId( '.', this.sesion.planCurrently );
    } else {
      this.dlService.dl_userId( null, 'anonimo' );
    }
    const model = this;

    // CHATBOT.JS FUNCTIONS

    // tslint:disable-next-line: only-arrow-functions
    $('#tituchat').on('click', function() {
      if (model.initchat === false) {
        model.initchat = true;
        $('.chatbot').addClass('chat-bot');
        $('.chat-bot').removeClass('chatbot');
        $('#yousay').prop('disabled', true);
        // $('#tituchat').removeAttr('id');
        $('.chat-bot').show();
        $('#tituchat').hide();

        // tslint:disable-next-line: max-line-length
        $('.chat-bot').html('<div class="chatb"><div class="row" ><div id="activechat" class="col-12 mdc-block-secondary text-center mdc-link-detail"><div class="row p-3"><div class="col-4 mdc-li-pr"><img src="assets/base/icon-chat.svg" alt="icono de chat" class="img-fluid"></div><div class="col-8 text-white"><span>Chatea con <br> Nosotros</span></div></div></div></div><div class="messages"><div id="scro" class="messages-content mCustomScrollbar _mCS_1 mCS_no_scrollbar"><div id="mCSB_1" class="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside" tabindex="0" style="max-height: none;"><div id="mCSB_1_container" class="mCSB_container mCS_y_hidden mCS_no_scrollbar_y" style="position:relative; top:0; left:0;" dir="ltr"></div><div class="mCSB_draggerRail"></div></div></div></div></div></div><div class="message-box"><input  id="yousay" type="text" class="message-input" placeholder="...(escribiendo)"/input><button type="submit" class="message-submit">Enviar</button></div></div><div class="bg"></div>');
        $('#yousay').prop('disabled', true);

        // tslint:disable-next-line: only-arrow-functions
        $('#activechat').on('click', function() {
          if (model.initchat === true) {
            model.initchat = false;
            $('.chat-bot').hide();
            $('#tituchat').show();
          }
        });
        // $('.messages-content').html('');
        model.saludar();
      }
    });

    // tslint:disable-next-line: only-arrow-functions
    $(window).on('click', function(e:any) {

    });

    // tslint:disable-next-line: only-arrow-functions
    $(window).on('keydown', function(e:any) {
        if (e.which === 13) {
          model.insertMessage();
          return false;
        }
        return true;
    });

    // tslint:disable-next-line: only-arrow-functions
    $('.message-submit').on('click', function(e:any) {
        return false;
    });
  }

  resolved(captchaResponse: string) {
    if (!this.validCaptcha) {
      this.validCaptcha = captchaResponse;
    }
  }

  enviarContacto( data: NgForm ) {
  if  ( data.valid ) {
      if (this.singleExecutionSubscription) {
        this.singleExecutionSubscription.unsubscribe();
      }
      this.singleExecutionSubscription = this.recaptchaV3Service.execute("registroContacto").subscribe((token:any) => {
        this.errorMessage = null;
        this.sesion.contacto["captcha-response"] = token;
        this.sesion.enviarContacto();
        data.reset();
      }, (error:any) => {
        // Error.
      });
    }
  }

  busquedaWordpress() {
    if ( this.search ) {
      window.open('http://preguntas.midatacredito.com/?s=' + this.search, '_blank');
    } else {
      this.nosearch = true;
    }
  }

  clickPQ( id: number ) {
    $('.mdc-link-detail').removeClass('font-weight-bold');
    // tslint:disable-next-line: only-arrow-functions
    setTimeout(function() {
      if ( $('.link' + id).hasClass('collapsed')) {
        $('.link' + id).removeClass('font-weight-bold');
      } else {
        $('.link' + id).addClass('font-weight-bold');
      }
    }, 50);
  }

  // CHATBOT.JS FUNCTIONS
  guardar1() {
    $('.chat-bot').addClass('chatbot');
    // tslint:disable-next-line: max-line-length
    $('.chat-bot').html('<div class="row"><div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 icon-square"><img src="https://contenido.midatacredito.com/img_chat/chat-icon.png" alt="chat"></div><div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 text-square"><p>Â¿Tienes dudas? <br>Chatea con nosotros</p></div></div>');
    $('.chatbot').removeClass('chat-bot');
  }

  esconder_chat() {
    const alto = $('.chat-bot')[0].clientHeight;
    if ($('.chat-bot')[0].style.bottom === '' || $('.chat-bot')[0].style.bottom === '0px') {
        $('.chat-bot')[0].style.bottom = -($('.chat-bot')[0].clientHeight - 55) + 'px';
    } else {
        $('.chat-bot')[0].style.bottom = '0px';
    }
  }

  updateScrollbar() {
    $('#mCSB_1_container').scrollTop($('#mCSB_1_container')[0].scrollHeight);
  }

  setDate() {
    this.d = new Date();
    if (this.m !== this.d.getMinutes()) {
        this.m = this.d.getMinutes();
        $('<div class="timestamp">' + this.d.getHours() + ':' + this.m + '</div>').appendTo($('.message:last'));
    }
  }

  insertMessage() {
    const msg = $('.message-input').val();
    if ($.trim(msg) === '') {
      return false;
    }

    $('#yousay').prop('disabled', true);
    $('<div class="message message-personal">' + msg + '</div>').appendTo($('#mCSB_1_container')).addClass('new');
    this.setDate();
    this.updateScrollbar();
    $('.message-input').val(null);
    this.doTalk(msg);
    return true;
  }

  fakeMessage( respuesta:any ) {
    $('.message.loading').remove();
    if ($('.message-input').val() !== '') {
        return false;
    }
    $('<div class="message loading new"><span></span></div>').appendTo($('#mCSB_1_container'));
    this.updateScrollbar();
    const model = this;
    // tslint:disable-next-line: only-arrow-functions
    setTimeout(function() {
      $('.message.loading').remove();
      $('<div class="message new">' + respuesta + '</div>').appendTo($('#mCSB_1_container')).addClass('new');
      model.setDate();
      model.updateScrollbar();
      $('#yousay').prop('disabled', false);
      $('#yousay').focus();
    }, 1000 + (Math.random() * 20) * 100);
    return true;
  }

  // USER JS FUNCTIONS
  saludo() {
    const tiempo = new Date();
    let hora;
    let cad = 'Son las ';
    hora = tiempo.getHours();
    cad += hora + ':' + tiempo.getMinutes() + ':' + tiempo.getSeconds();

    if (hora < 12) {
      cad = 'Buenos dias';
    } else if (hora < 18) {
      cad = 'Buenas tardes';
    } else {
      cad = 'Buenas noches';
    }

    return cad;
  }

  saludar() {
    const date = new Date().toString();
    const input = this.saludo();

    // Talk function
    const model = this;
    // tslint:disable-next-line: only-arrow-functions
    if(this.pb != null){
      this.pb.talk(input, function(data:any) {
        const response = data.responses;
        const sessionid = data.sessionid;
        const client_name = model.pb.client_name;

        model.fakeMessage(response);

        // Create a new session in Firebase
        if (model.init === true) {
          const session = new Firebase(model.root + sessionid);
          session.set({
              client_name,
              date
          });
          model.init = false;
        }

        // Push interactions to Firebase
        const conversation = new Firebase(model.root + sessionid + '/conversation');
        conversation.push({
          client_name,
          date,
          input,
          that: model.that,
          response
        });

        // Store last response for next interaction
        model.that = response[response.length - 1];
      });
    }
  }

  doTalk( mensaje:any) {
    const date = new Date().toString();
    const input = mensaje;

    $('#yousay').val('');
    $('#convo').append('<p><strong><i class="fa fa-user user"></i></strong> T&uacute; :' + input + '</p>');

    // Talk function
    const model = this;
    // tslint:disable-next-line: only-arrow-functions
    this.pb.talk(input, function(data:any) {
      const response = data.responses;
      const sessionid = data.sessionid;
      const client_name = model.pb.client_name;

      model.fakeMessage(response);

      // Create a new session in Firebase
      if (model.init === true) {
          const session = new Firebase(model.root + sessionid);
          session.set({
              client_name,
              date
          });
          model.init = false;
      }

      // Push interactions to Firebase
      const conversation = new Firebase(model.root + sessionid + '/conversation');
      conversation.push({
          client_name,
          date,
          input,
          that: model.that,
          response
      });

      // Store last response for next interaction
      model.that = response[response.length - 1];
    });
  }

  showTerms(){
    if($($(".text-ad")[0]).css("display") == "none"){
      $($(".text-ad")[0]).slideDown()
    }else{
      $($(".text-ad")[0]).slideUp()
    }
  }

  disabledBtn(data: NgForm){
    return !(data.valid && this.terms);
  }
}
