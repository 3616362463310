<div class="experian">
  <section class="gradient-gray section margin-main-top">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <p class="hashtag">#MonitoreaTusDeudas</p>
                <h2 class="txt-purple">Descubre como las deudas afectan tu puntaje de crédito</h2>
                <p class="outstanding">Retrasarte en el pago de tus deudas ya adquiridas, afectan directamente tu puntaje en Datacrédito.</p>
                <img src="assets/img/landings/deuda/img_header.svg" class="img-main d-block d-md-none" alt="Deuda Saludable Midatacrédito">
                <div class="btn-next btn-55">
                    <button class="btn btn-primary" id="btnSupDeuda" (click)="seleccionarPlan(sesion.planes.planes?.per, '1', sesion.planes.planes?.per?.mensual?.[1], sesion.planes.planes?.per?.mensual?.[0])">Obtener Puntaje
                      <span class="arrow-right"><img src="assets/img/images/arrow-right.svg" alt="flecha derecha" class="txt-experian-purple"></span>
                    </button>                  
                </div>
            </div>
            <div class="col-12 col-md-6 text-center d-none d-md-block">
                <img src="assets/img/landings/deuda/img_header.svg" class="img-main" alt="Deuda Saludable Midatacrédito">
            </div>
            <div class="col-12 text-center">
              <p class="terms txt-purple">Para acceder a este plan requieres de una tarjeta de crédito válida, conforme lo establecen los <a href="https://www.midatacredito.com/terminoscondiciones" target="_blank">Términos y Condiciones.</a></p>
            </div>
        </div>
    </div>
  </section>
  <section class="white section bg-bullets">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center title-section">
              <h2 class="bold">Mejora tu salud financiera</h2>
              <h3 class="light">Haz parte de las personas que hoy tienen un mejor perfil de crédito</h3>
            </div>
            <div class="col-12 col-md-4 text-center ">
              <img src="assets/img/landings/deuda/puntaje.svg" class="img-bullets" alt="Obten tu puntaje de crédito">
              <div class="box-title">
                <h4 class="title-description">Obtén tu puntaje<br>de crédito</h4>
              </div>              
              <p class="description-bullets">Un buen puntaje refleja buenos<br>comportamientos de pago</p>
            </div>
            <div class="col-12 col-md-4 text-center ">
              <img src="assets/img/landings/deuda/diagnostico.svg" class="img-bullets" alt="Conoce tu diagnóstico">
              <div class="box-title">
                <h4 class="title-description">Conoce tu<br>diagnóstico</h4>
              </div>              
              <p class="description-bullets">Vigila tus hábitos de pago<br>y mejora tu perfil de crédito</p>
            </div>
            <div class="col-12 col-md-4 text-center ">
              <img src="assets/img/landings/deuda/monitoreo.svg" class="img-bullets" alt="Monitorea tu información financiera">
              <div class="box-title">
                <h4 class="title-description">Monitorea tu<br>información financiera</h4>
              </div>              
              <p class="description-bullets">Recibe alertas cada vez que existan<br>cambios en tu historia de crédito</p>
            </div>
        </div>
    </div>
  </section>
  <section class="section gray-0 plan">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-4 text-center text-md-right align-self-center px-3 px-md-5">
              <p class="pay-month">Pago mensual</p>
              <h2><span class="price">$</span>{{sesion.planes.planes.per.valor[0]}}</h2>
            </div>
            <div class="col-12 col-md-8 align-self-center">
              <h3>Hoy Midatacrédito protege y monitorea a más de 150.000 personas cada mes</h3>
              <p>Haz parte de los colombianos que se preocupan por su realidad financiera y toma el control de tu vida crediticia.</p>
              <div class="btn-next btn-35">
                <button class="btn-min btn-primary" id="btnInfDeuda" (click)="seleccionarPlan(sesion.planes.planes?.per, '1', sesion.planes.planes?.per?.mensual?.[1], sesion.planes.planes?.per?.mensual?.[0])">Obtener Puntaje
                  <span class="arrow-right"><img src="assets/img/images/arrow-right.svg" alt="flecha derecha" class="txt-experian-purple"></span>
                </button>                
            </div>   
            </div>
        </div>
    </div>
  </section>
</div>