import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BrokerService } from 'src/app/core/services/broker.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { SesionService } from 'src/app/core/services/sesion.service';

declare var $: any;

@Component({
	selector: 'app-miplan',
	templateUrl: './miplan.component.html',
	styleUrls: ['./miplan.component.scss']
})
export class MiplanComponent implements OnInit {
	tabActive = 1;

	constructor(
		public sesion: SesionService,
		private activateRoute: ActivatedRoute,
		public broker: BrokerService,
		private dlService: DataLayersService
	) {

		this.activateRoute.queryParams.subscribe((params:any): any => {
			if (params["pg"]) {
				this.iniciarPage(params["pg"]);
			}
		});
	}

	ngOnInit() {
		this.sesion.loadInitialParameters();
		localStorage.clear();
		// GA4 - User
		this.dlService.dl_userId( '.', this.sesion.planCurrently );
		// Size window
		if ($(window).width() >= 768) {
			$(".mdc-nav-foot").show();
		} else {
			$(".mdc-nav-foot").css("visibility", "hidden");
		}
	}

	tabsHC(id:any) {
		this.tabActive = id;
	}

	iniciarPage(page:any) {
		switch (page) {
			case 'MP':
				this.tabActive = 1;
				break;
			case 'CMP':
				this.tabActive = 2;
				break;
			default:
				break;
		}
	}

	verificarTab(){
		if ((this.sesion.estadoUserF == 'INACTIVO' || this.sesion.estadoUserF == 'PENDING' || this.sesion.estadoUserF == 'PENDING_PSE' || this.sesion.estadoUserF == 'ACTIVE_PROCESS') && this.sesion.planCurrently != undefined) {
			this.tabsHC(2);
		}
		return true;
	}
}

