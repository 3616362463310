<div class="menu-freemium">
  <div class="box-plan-actual" *ngIf="nombrePlan()">
    <div class="img-plan">
      <img [src]="imgPlan" alt="plan"/>
    </div>
    <div class="desc-plan">
      <p class="titulo-plan">Plan actual</p>
      <p class="text-plan">{{nombrePlanText}}</p>
    </div>
  </div>
  <div>
    <div *ngFor="let menu of sesion.dataMenu;let i = index">
      <!-- <button *ngIf="menu.subMenu.length == 0">{{menu.description}}</button> -->
      <a class="btn-main-menu" id="link-menu-{{menu.id}}" *ngIf=" menu.subMenu.length == 0 && menu.type == 'local'" (click)="selectEvent(menu.function,menu.id, menu.description)" [ngClass]="{'disabledGray' : accessMenu(menu.typePlan)}">
        <div class="bar">.
        </div>
        <img src="{{menu.icon}}" class="iconMenu" alt="Generar Alerta MIdatacrédito"/>
        <span>{{(menu.id != 4 ? menu.description : textNotification(i))}}</span>
        <img *ngIf="menu.new" class="iconNewItem" src="assets/img/header/icon/funcionalidad_nueva.svg" alt="Nueva funcionalidad"/>
        <img class="iconblock img-prem l2" *ngIf="accessMenu(menu.typePlan)" src="assets/img/header/icon/funcionalidad_bloqueada.svg" alt="Nueva funcionalidad"/>
      </a>
      <a class="btn-main-menu" id="link-menu-{{menu.id}}" *ngIf="menu.type == 'externo' && menu.cta == undefined" (click)="selectEvent(menu.function,menu.id, menu.description)">
        <div class="bar">.</div>
        <img *ngIf="menu.new" class="iconNewItem ext" src="assets/img/header/icon/funcionalidad_nueva.svg" alt="Nueva funcionalidad"/>
        <img src="{{menu.icon}}" class="iconMenuE" alt="{{menu.description}}"/>
      </a>
      <a class="btn-main-menu" id="link-menu-{{menu.id}}" *ngIf="menu.type == 'externo' && menu.cta != undefined" href="{{menu.cta}}" target="_blank" rel="noopener">
        <div class="bar">.</div>
        <img *ngIf="menu.new" class="iconNewItem ext" src="assets/img/header/icon/funcionalidad_nueva.svg" alt="Nueva funcionalidad"/>
        <img src="{{menu.icon}}" class="iconMenuE" alt="{{menu.description}}"/>
      </a>
      <div *ngIf=" menu.subMenu.length > 0">
        <a class="btn-main-menu" (click)="selectEvent(menu.function,menu.id, menu.description)" [ngClass]="{'disabledGray' : accessMenu(menu.typePlan)}">
          <div class="bar">.
          </div>
          <img src="{{menu.icon}}" class="iconMenu" alt="Generar Alerta MIdatacrédito"/>
          <span>{{menu.description}}</span>
          <img class="iconblock img-prem l2" *ngIf="accessMenu(menu.typePlan)" src="assets/img/header/icon/funcionalidad_bloqueada.svg" alt="Nueva funcionalidad"/>
        </a>
        <div (click)="selectEvent(sub.function,menu.id+sub.id, menu.description)" class="box-link btn-main-menu" *ngFor="let sub of sesion.dataMenu[i].subMenu" [ngClass]="{'disabledGray' : accessMenu(menu.typePlan),'d-none':!setAdvance(sub.function)}" id="link-menu-{{menu.id}}{{sub.id}}">
          <p class="pLink"><span class="tSub">{{sub.description}}</span><img *ngIf="sub.new" class="iconNew" src="assets/img/header/icon/funcionalidad_nueva.svg" alt="Nueva funcionalidad"/></p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mob-btn-plans">
  <div class="content-pla-fixed">
    <img src="assets/img/freepremium/logo_aliado.svg" alt="Plan Midatacrédito"/>
    <p>Conoce tu puntaje de crédito y lo que puedes hacer con el</p>
  </div>  
  <a (click)="modal.modalPerfil('M')" target="_blank" class="btn-midc btn-orange h-lg-btn w-100-btn" id="verPlanesMas">
    <span>Adquirir ahora</span>
    <i class="fa fa-chevron-right" aria-hidden="true"></i>
  </a>
</div>
