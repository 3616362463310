<div class="testimonials">
    <div class="box-all">
        <div class="box-people" *ngIf="testimonialBefore != undefined" (click)="moveTestimomnal(false)" [ngClass]="{'blue' : testimonialBefore.id == 1,'pink' : testimonialBefore.id == 2,'orange' : testimonialBefore.id == 3}" >
            <div class="icon-people">
                <img *ngIf=" testimonialBefore.id == 1" src="assets/img/home/testimonial_azul_icono.png" alt="Comentario de usuario" title="Testimonios"/>
                <img *ngIf=" testimonialBefore.id == 2" src="assets/img/home/testimonial_amarillo_icono.png" alt="Comentario de usuario" title="Testimonios"/>
                <img *ngIf=" testimonialBefore.id == 3" src="assets/img/home/testimonial_morado_icono.png" alt="Comentario de usuario" title="Testimonios"/>
            </div>
            <div class="qualify">
                <i class="fa fa-star" aria-hidden="true" id="1star" [ngClass]="validarEstrella(1, testimonialBefore.qualify)"></i>
                <i class="fa fa-star" aria-hidden="true" id="2star" [ngClass]="validarEstrella(2, testimonialBefore.qualify)"></i>
                <i class="fa fa-star" aria-hidden="true" id="3star" [ngClass]="validarEstrella(3, testimonialBefore.qualify)"></i>
                <i class="fa fa-star" aria-hidden="true" id="4star" [ngClass]="validarEstrella(4, testimonialBefore.qualify)"></i>
                <i class="fa fa-star" aria-hidden="true" id="5star" [ngClass]="validarEstrella(5, testimonialBefore.qualify)"></i>
            </div>
            <p>{{testimonialBefore.description}}</p>
            <div class="d-flex">
                <p class="author">{{testimonialBefore.author}}</p>
            </div>
        </div>
        <div class="box-people" *ngIf="testimonialMain != undefined" [ngClass]="{'blue' : testimonialMain.id == 1,'pink' : testimonialMain.id == 2,'orange' : testimonialMain.id == 3}">
            <div class="icon-people">
                <img *ngIf=" testimonialMain.id == 1" src="assets/img/home/testimonial_azul_icono.png" alt="Comentario de usuario" title="Testimonios"/>
                <img *ngIf=" testimonialMain.id == 2" src="assets/img/home/testimonial_amarillo_icono.png" alt="Comentario de usuario" title="Testimonios"/>
                <img *ngIf=" testimonialMain.id == 3" src="assets/img/home/testimonial_morado_icono.png" alt="Comentario de usuario" title="Testimonios"/>
            </div>
            <div class="qualify">
                <i class="fa fa-star" aria-hidden="true" id="1star" [ngClass]="validarEstrella(1, testimonialMain.qualify)"></i>
                <i class="fa fa-star" aria-hidden="true" id="2star" [ngClass]="validarEstrella(2, testimonialMain.qualify)"></i>
                <i class="fa fa-star" aria-hidden="true" id="3star" [ngClass]="validarEstrella(3, testimonialMain.qualify)"></i>
                <i class="fa fa-star" aria-hidden="true" id="4star" [ngClass]="validarEstrella(4, testimonialMain.qualify)"></i>
                <i class="fa fa-star" aria-hidden="true" id="5star" [ngClass]="validarEstrella(5, testimonialMain.qualify)"></i>
            </div>
            <p>{{testimonialMain.description}}</p>
            <div class="d-flex">
                <p class="author">{{testimonialMain.author}}</p>
            </div>
        </div>
        <div class="box-people" *ngIf="testimonialAfter != undefined" (click)="moveTestimomnal(true)" [ngClass]="{'blue' : testimonialAfter.id == 1,'pink' : testimonialAfter.id == 2,'orange' : testimonialAfter.id == 3}">
            <div class="icon-people">
                <img *ngIf=" testimonialAfter.id == 1" src="assets/img/home/testimonial_azul_icono.png" alt="Comentario de usuario" title="Testimonios"/>
                <img *ngIf=" testimonialAfter.id == 2" src="assets/img/home/testimonial_amarillo_icono.png" alt="Comentario de usuario" title="Testimonios"/>
                <img *ngIf=" testimonialAfter.id == 3" src="assets/img/home/testimonial_morado_icono.png" alt="Comentario de usuario" title="Testimonios"/>
            </div>
            <div class="qualify">
                <i class="fa fa-star" aria-hidden="true" id="1star" [ngClass]="validarEstrella(1, testimonialAfter.qualify)"></i>
                <i class="fa fa-star" aria-hidden="true" id="2star" [ngClass]="validarEstrella(2, testimonialAfter.qualify)"></i>
                <i class="fa fa-star" aria-hidden="true" id="3star" [ngClass]="validarEstrella(3, testimonialAfter.qualify)"></i>
                <i class="fa fa-star" aria-hidden="true" id="4star" [ngClass]="validarEstrella(4, testimonialAfter.qualify)"></i>
                <i class="fa fa-star" aria-hidden="true" id="5star" [ngClass]="validarEstrella(5, testimonialAfter.qualify)"></i>
            </div>
            <p>{{testimonialAfter.description}}</p>
            <div class="d-flex">
                <p class="author">{{testimonialAfter.author}}</p>
            </div>
        </div>
    </div>
</div> 