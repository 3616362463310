import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SesionService } from './sesion.service';
import { delay, of } from 'rxjs';
declare var dataLayer: any;

@Injectable({
  providedIn: 'root'
})

export class DataLayersService {

  constructor(private cookieService: CookieService, private sesion: SesionService) { }

  /**
   * EVENTO GA4 user
   * Este método envia un data layer identificando el tipo de usuario que se encuentra navegando; usuario anonimo o registrado y el tipo de plan.
   * @param id_usuario identificador del usuario registrado, en caso de ser un anonimo se envía como null. 
   * @param tipo_usuario Define si el usuario es "anonimo", o si esta registrado y el plan que tiene.
   */
  dl_userId(id_usuario: string | null, tipo_usuario: string) {
    // Usuarios logueados
    if (tipo_usuario != 'anonimo') {
      // Usuarios free o caducados
      if (this.sesion.estadoUserF === 'INACTIVO' || this.sesion.activeUser === 'PENDING') {
        if (this.sesion.planCurrently == undefined) {
          tipo_usuario = 'Fremium';
        }
        if (this.sesion.planCurrently != undefined) {
          tipo_usuario = 'Caducado'
        }
      }
      // Usuarios con plan
      if (this.sesion.estadoUserF === 'ACTIVO') {
        tipo_usuario = tipo_usuario.slice(0, -1);
        if (tipo_usuario == 'ANT') { tipo_usuario = 'Plan Protege'; }
        if (tipo_usuario == 'PRO') { tipo_usuario = 'Plan Protección Premium'; }
        if (tipo_usuario == 'PER') { tipo_usuario = 'Plan Perfil de Crédito'; }
      }

      // Reiniciar método
      if (this.sesion.estadoUserF == undefined) {
        of(this.sesion).pipe(delay(1000)).subscribe(planes => {
          this.dl_userId(id_usuario, tipo_usuario);
        })
      }
    }
    dataLayer.push({
      'event': 'ga_event',
      'name_event': 'user',
      'user_id': id_usuario,
      'user_tye': tipo_usuario
    });
  }

  /**
   * EVENTO GA4 view_item_list
   * Seguimiento de visualización de productos en una lista
   */
  dl_view_item_list(planA: any, planB: any, planC: any, ubicacion: string, periodo: string) {
    dataLayer.push({ ecommerce: null });
    if (planB != null && planC != null) {
      dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: [
            {
              item_name: limpiar_texto(planA.nombre, 'nombre'),
              item_id: planA.id,
              price: parseInt(planA.mensual[0].replace('.', '')),
              item_brand: "Midatacrédito",
              item_category: "Planes",
              item_variant: periodo,
              item_list_name: ubicacion,
              item_list_id: limpiar_texto(`${ubicacion}-${planA.id}`, 'id'),
              index: 1,
              quantity: 1
            },
            {
              item_name: limpiar_texto(planB.nombre, 'nombre'),
              item_id: planB.id,
              price: parseInt(planB.mensual[0].replace('.', '')),
              item_brand: "Midatacrédito",
              item_category: "Planes",
              item_variant: periodo,
              item_list_name: ubicacion,
              item_list_id: limpiar_texto(`${ubicacion}-${planB.id}`, 'id'),
              index: 2,
              quantity: 1
            },
            {
              item_name: limpiar_texto(planC.nombre, 'nombre'),
              item_id: planC.id,
              price: parseInt(planC.mensual[0].replace('.', '')),
              item_brand: "Midatacrédito",
              item_category: "Planes",
              item_variant: periodo,
              item_list_name: ubicacion,
              item_list_id: limpiar_texto(`${ubicacion}-${planC.id}`, 'id'),
              index: 3,
              quantity: 1
            }]
        }
      });
    } else {
      dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: [
            {
              item_name: limpiar_texto(planA.nombre, 'nombre'),
              item_id: planA.id,
              price: parseInt(planA.mensual[0].replace('.', '')),
              item_brand: "Midatacrédito",
              item_category: "Planes",
              item_variant: periodo,
              item_list_name: ubicacion,
              item_list_id: limpiar_texto(`${ubicacion}-${planA.id}`, 'id'),
              index: 1,
              quantity: 1
            }]
        }
      });
    }
  }

  /**
   * EVENTO GA4 add_to_cart
   * Seguimiento de añadir al carrito (antes de ir al checkout)
   */
  dl_add_to_cart(data: any, valor: string, recurrencia: string, ubicacion: string) {
    let costoTotal = valor.replace('.', '');

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        items: [{
          item_name: limpiar_texto(data.nombre, 'nombre'),
          item_id: data.id,
          price: parseInt(costoTotal),
          currency: "COP",
          item_brand: "Midatacrédito",
          item_category: "Planes",
          item_variant: tipo_plan(recurrencia),
          item_list_name: ubicacion,
          item_list_id: limpiar_texto(`${ubicacion}-${data.id}`, 'id'),
          index: encontrar_index(data.id),
          quantity: 1
        }]
      }
    });
  }

  /**
   * EVENTO GA4 begin_checkout
   * seguimiento de inicio de checkout
   */
  dl_begin_checkout(data: any, tipoPlan: string, funnUrl: string | null) {
    let costoTotal = data.valorPlanTotal.replace('.', '');

    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        currency: "COP",
        value: parseInt(costoTotal),
        items: [{
          affiliation: funnUrl,
          item_name: limpiar_texto(data.plan.nombre, 'nombre'),
          item_id: data.plan.id,
          currency: "COP",
          price: parseInt(costoTotal),
          item_brand: "Midatacrédito",
          item_category: "Planes",
          item_variant: tipo_plan(tipoPlan),
          item_list_name: encontrar_funn(funnUrl),
          item_list_id: (encontrar_funn(funnUrl) + '-' + data.plan.id).toLowerCase(),
          index: encontrar_index(data.plan.id),
          quantity: 1
        }]
      }
    });

    this.sesion.setCookieServices('item_list_name', encontrar_funn(funnUrl));
    this.sesion.setCookieServices('item_list_id', (encontrar_funn(funnUrl) + '-' + data.plan.id).toLowerCase());
  }

  /**
   * EVENTO GA4 add_payment_info
   * Seguimiento de formulario de pago
   */
  dl_add_payment_info(data: any, funnUrl: string | null, tipo: string) {
    let ecommerce_obj: any;
    let totalApagar = data.valorPlanTotalDescuento;
    let valorPlan = data.valorPlanTotal.replace('.', '');
    let totalDescuento = valorPlan - totalApagar;

    if (data.descuento == 'OK') {
      ecommerce_obj = {
        currency: "COP",
        value: parseInt(totalApagar),
        coupon: data.descuentoData.params.promoCode,
        payment_type: tipo,
        items: [
          {
            item_name: limpiar_texto(data.plan.nombre, 'nombre'),
            affiliation: funnUrl,
            discount: totalDescuento,
            item_id: data.plan.id,
            price: parseInt(totalApagar),
            currency: "COP",
            item_brand: "Midatacrédito",
            item_category: "Planes",
            item_variant: tipo_plan(data.tipoPlan),
            item_list_name: this.obtener_item_list('item_list_name'),
            item_list_id: this.obtener_item_list('item_list_id'),
            index: encontrar_index(data.plan.id),
            quantity: 1
          }]
      }
    } else {
      ecommerce_obj = {
        currency: "COP",
        value: parseInt(valorPlan),
        payment_type: tipo,
        items: [{
          item_name: limpiar_texto(data.plan.nombre, 'nombre'),
          affiliation: funnUrl,
          item_id: data.plan.id,
          price: parseInt(valorPlan),
          currency: "COP",
          item_brand: "Midatacrédito",
          item_category: "Planes",
          item_variant: tipo_plan(data.tipoPlan),
          item_list_name: this.obtener_item_list('item_list_name'),
          item_list_id: this.obtener_item_list('item_list_id'),
          index: encontrar_index(data.plan.id),
          quantity: 1
        }]
      }
    }
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: "add_payment_info",
      ecommerce: ecommerce_obj
    });
  }

  /** 
   * EVENTO GA4 purchase
   * Compra éxitosa
   * A tener en cuenta que para GA4 el punto (.) se utiliza como separador decimal y no como separador
   * de miles. Por lo que las variables value, tax, shipping, price y discount para el formato colombiano
   * debe ser sin puntos ni comas.
   */
  dl_purchase(data: any, metodo: string, funnUrl: string | null) {
    let ecommerce_obj: any;
    let valorSinDescuento = parseInt(data.valorPlanTotal.replace('.', ''));
    let valorPagado = data.confirmacion.transactionResponse.TX_VALUE;
    let imp = data.confirmacion.transactionResponse.TX_TAX;
    let totalDescuento = valorSinDescuento - valorPagado;

    if (metodo === 'PSE'){
      valorPagado = parseInt(valorPagado.replace('.', '').slice(0, -2));
      imp = parseInt(imp.replace('.','').slice(0, -2));

      if (isNaN(valorPagado)){ valorPagado = valorSinDescuento; }
      if (isNaN(imp)){ 
        imp = valorSinDescuento * 0.19; 
      }
    }

    if (metodo !== 'PSE' && data.descuentoData.params.promoCode != null) {
      ecommerce_obj = {
        transaction_id: data.confirmacion.transactionResponse.orderid.toString(),
        affiliation: metodo,
        value: valorPagado,
        tax: imp,
        shipping: 0,
        currency: "COP",
        coupon: data.descuentoData.params.promoCode,
        items: [{
          item_name: limpiar_texto(data.plan.nombre, 'nombre'),
          affiliation: funnUrl,
          discount: totalDescuento,
          item_id: data.plan.id,
          price: valorSinDescuento,
          currency: "COP",
          item_brand: "Midatacrédito",
          item_category: "Planes",
          item_variant: tipo_plan(data.tipoPlan),
          item_list_name: this.obtener_item_list('item_list_name'),
          item_list_id: this.obtener_item_list('item_list_id'),
          index: encontrar_index(data.plan.id),
          quantity: 1
        }]
      }
    } else {
      ecommerce_obj = {
        transaction_id: data.confirmacion.transactionResponse.orderid.toString(),
        affiliation: metodo,
        value: valorPagado,
        tax: imp,
        shipping: 0,
        currency: "COP",
        items: [{
          item_name: limpiar_texto(data.plan.nombre, 'nombre'),
          affiliation: funnUrl,
          item_id: data.plan.id,
          price: parseInt((data.valorPlanTotal).replace('.', '')),
          currency: "COP",
          item_brand: "Midatacrédito",
          item_category: "Planes",
          item_variant: tipo_plan(data.tipoPlan),
          item_list_name: this.obtener_item_list('item_list_name'),
          item_list_id: this.obtener_item_list('item_list_id'),
          index: encontrar_index(data.plan.id),
          quantity: 1
        }]
      }
    }
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: "purchase",
      ecommerce: ecommerce_obj
    });

    // Se eliminan variables de las cookies
    if (this.cookieService.get('item_list_name')) { this.cookieService.delete('item_list_name'); }
    if (this.cookieService.get('item_list_id')) { this.cookieService.delete('item_list_id'); }
  }

  /** 
   * EVENTO GA4 remove_from_cart
   * Abandonar carrito
   */
  dl_remove_from_cart(data: any) {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        items: [{
          item_name: limpiar_texto(data.plan.nombre, 'nombre'),
          item_id: data.plan.id,
          price: parseInt(data.valorPlanTotal.replace('.', '')),
          currency: "COP",
          item_brand: "Midatacrédito",
          item_category: "Planes",
          item_variant: tipo_plan(data.tipoPlan),
          item_list_name: this.obtener_item_list('item_list_name'),
          item_list_id: this.obtener_item_list('item_list_id'),
          index: encontrar_index(data.id),
          quantity: 1
        }]
      }
    });

    // Se eliminan variables de las cookies
    if (this.cookieService.get('item_list_name')) { this.cookieService.delete('item_list_name'); }
    if (this.cookieService.get('item_list_id')) { this.cookieService.delete('item_list_id'); }
  }

  /**
   * EVENTO GA4 de 1 parámetro
   * @param event: evento de Google Analytics.
   * @param name_event: Nombre de evento.
   */
  dl_eventoGA4_1parametro(name_event: string) {
    dataLayer.push({
      'event': 'ga_event',
      'name_event': name_event
    });
  }

  /**
   * EVENTO GA4 payment_failed
   * Proceso de pago fallido
   */
  dl_payment_failed(razon: string) {
    dataLayer.push({
      'event': 'ga_event',
      'name_event': 'payment_failed',
      'reason': razon, //EJ: fondos insuficientes, etc (o codigo de error de la pasarela que usen)
    });
  }

  /**
   * EVENTO GA4 - Parámetro: menu_option
   */
  dl_eventoGA4_menu(name_event: string, texto: string) {
    dataLayer.push({
      'event': 'ga_event',
      'name_event': name_event,
      'menu_option': texto,
    });
  }

  /**
   * EVENTO GA4 DASHBOARD - Parámetro: menu_option
   */
  dl_eventoGA4_menu_dashboard(texto: string, modulo: string) {
    dataLayer.push({
      'event': 'ga_event',
      'name_event': 'btn_clic_tool_dashboard',
      'menu_option': texto,
      'modulo_option': modulo
    });
  }

  /**
   * EVENTO GA4 DASHBOARD - padya
   */
  dl_eventoGA4_padya(button_text: string, name_event: string, url: string) {
    dataLayer.push({
      'event': 'ga_event',
      'name_event': name_event,
      'padYa_option': button_text,
      'url': url
    });
  }

  /**
   * EVENTO GA4 PAD - DASHBOARD
   */
  dl_eventoGA4_pad_dashboard(name_event: string, estado: string, marca: string, tipo_producto: string, mora: string, valor_pactado: string | number) {
    dataLayer.push({
      'event': 'ga_event',
      'name_event': name_event,
      'state_agreement': estado,
      'brand_agreement': marca,
      'product_agreement': tipo_producto,
      'time_agreement': mora,
      'deal_agreement': valor_pactado
    });
  }

  /**
   * EVENTO GA4 clic_button_name
   */
  dl_clic_button_name(name_event: string, texto: string) {
    dataLayer.push({
      'event': 'ga_event',
      'name_event': name_event,
      'button_name': texto,
    });
  }

  /**
   * EVENTO GA4 generate_lead
   */
  dl_generate_lead(valor: string) {
    dataLayer.push({
      'event': 'ga_event',
      'name_event': 'generate_lead',
      'currency': 'COP',
      'value': valor
    });
  }

  /**
   * EVENTO GA4 - Este evento informa sobre la oferta de Buscacrédito seleccionada desde MIDC
   */
  dl_select_content_bc(name_event: string, tipo_producto: string, id_oferta: string, marca: string) {
    dataLayer.push({
      'event': 'ga_event',
      'name_event': name_event,
      'content_type': tipo_producto,
      'content_id': id_oferta,
      'brand_offer': marca
    });
  }

  /** En este data layer se envía el correo del usuario cuando se realiza un pago */
  dl_send_mail_user(mail: string){
    dataLayer.push({
      'event': 'ga_event',
      'name_event': 'mail_user',
      'mail': mail 
    });
  }
  
  /** Funcion para enviar eventos dinamicos como parametro */
  dl_event(event:object){
    dataLayer.push(event);
  }

  /**
   * Esta función tiene como objetivo identificar el item_list_name o/y el item_list_id de los eventos de compra
   * @param variable Recibe el nombre de la variable a trabajar (item_list_name) o (item_list_id)
   * @returns Retorna el valor de la variable solicitada.
   */
  obtener_item_list(variable: string) {
    let item_variable = this.cookieService.get(variable);
    return item_variable;
  }

}

function encontrar_index(id: string) {
  let index_plan = 1;

  if (id == 'ANT') { index_plan = 2; }
  if (id == 'PRO') { index_plan = 3; }

  return index_plan;
}

function tipo_plan(tipo: string) {
  let recurrencia = 'Mensual';

  if (tipo == '2') { recurrencia = 'Trimestral'; }
  if (tipo != '1' && tipo != '2') { recurrencia = 'Anual'; }

  return recurrencia;
}

function limpiar_texto(texto: string, item: string) {
  let texto_final = '';
  if (item == 'nombre') { texto_final = texto.replace(" <br>", ""); }
  if (item == 'id') { texto_final = texto.replace(" ", "-").toLowerCase(); }
  return texto_final;
}

function encontrar_funn(funn: string | null) {
  let funn_name = 'historico';

  if (funn == 'H') { funn_name = 'planes home'; }
  if (funn == 'M') { funn_name = 'menu-historico'; }
  if (funn == 'HC') { funn_name = 'historico'; }
  if (funn == 'CP') { funn_name = 'comparación-planes'; }
  if (funn == 'LPER') { funn_name = 'landing-perfil-credito'; }
  if (funn == 'LANT') { funn_name = 'landing-plan-protegete'; }
  if (funn == 'LPRO') { funn_name = 'landing-plan-premium'; }
  if (funn == 'DASS') { funn_name = 'dashboard-plan-perfil-credito'; }
  if (funn == 'DAPRE') { funn_name = 'dashboard-pro'; }
  if (funn == 'DAPRO') { funn_name = 'dashboard-ant'; }

  return funn_name.toUpperCase();
}