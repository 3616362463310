import { Component, OnInit } from '@angular/core';
import { SesionService } from '../../core/services/sesion.service';
import { Router } from '@angular/router';
import { MetadatosService } from 'src/app/core/services/metadatos.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(private sesion: SesionService, private router: Router, private metadatos: MetadatosService) { }

  ngOnInit() {
    this.metadatos.actualizando_canonical('https://www.midatacredito.com/error');
  }

  routerLink() {
    if ( this.sesion.user ) {
      this.router.navigate(['/private/antifraude']);
    } else {
      this.router.navigate(['/']);
    }
  }

}
