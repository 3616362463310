import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { SesionService } from '../../core/services/sesion.service';
import { Planes } from '../../core/interfaces/planes.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { BrokerService } from 'src/app/core/services/broker.service';
import { NgForm } from '@angular/forms';
import { ModalService } from 'src/app/core/services/modal.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { TcComponent } from '../../components/tc/tc.component';
import { PseComponent } from '../../components/pse/pse.component';
import { delay, of } from 'rxjs';
import { SuscripcionService } from 'src/app/core/services/suscripcion.service';
declare var $: any;

@Component({
  selector: 'app-pago',
  templateUrl: './pago.component.html',
  styleUrls: ['./pago.component.scss']
})
export class PagoComponent implements OnInit {
  @ViewChild(TcComponent ) childTC!: TcComponent ;
  @ViewChild(PseComponent ) childPSE!: PseComponent ;

  plan: any;
  sideBar = true;
  tabActivo = 0;
  paymentType = true;  
  valorMensual:any;
  planes:Planes;
  hov:boolean[]=[false,false];
  acron: string[] = ['PER',""];
  typePlan: string[] = ['1',""];
  typeT:string = "";
  planT:any;
  benefits: any = [];
  giftCard: any;
  giftCardCode: any;
  flagBtnP:boolean[]=[false,false,false];
  datosTC!:NgForm;
  datosPSE!:NgForm;
  nameTag:string="";
  paramsT: string[] = ["","",""];
  funnUrl: string = "";
  btnPay:string = '0px';
  isChecked: any;
  commercialAd:any;
  url_terminos_condiciones = environment.enlaceS3 + environment.s3.terminosYCondiciones;
  url_autorizacion_opcional = environment.enlaceS3 + environment.s3.autorizacionDatosOpcional; 

  constructor(
    public sesion: SesionService,
    private router: Router,
    private route: ActivatedRoute,
    public broker: BrokerService,
		public modal: ModalService,
    private dataLayerService: DataLayersService,
		public suscripcion: SuscripcionService
    ) {
    this.plan = this.sesion.planes.planes.per;
    this.giftCard = {
      session: sesion.cookieValue,
      params: {
        promoCode: null,
        idPlan: this.sesion.planId
      }
    };
    this.sesion.infoPagos();
    
    this.planes = this.sesion.planes.planes;  
    this.valorMensual = localStorage.getItem("valorPlanMensual");
    localStorage.removeItem('pay');
    const PlanSeleccionado = localStorage.getItem('planSeleccionado');

    if (localStorage.getItem('planUrl')) {
      this.validatePlanUrl();
    } else if(PlanSeleccionado){
      this.plan = JSON.parse(PlanSeleccionado);
      this.acron= [this.plan.id,this.plan.id];
      this.typePlan = [this.sesion.tipoPlan,this.sesion.tipoPlan];
    }else{
      this.router.navigate(['/comparacion-planes']);
    }
    localStorage.removeItem('protege');
    localStorage.removeItem("lanProtege");
    
    setTimeout(() => { 
      if(this.sesion.advertising == true) {
        this.sesion.authorizations.filter(t => t.authorizationType === "commercial").forEach(ta => {
          this.commercialAd=ta;
        });
     }
   }, 2000);
  }

  ngOnInit() {
    // Size window
    if($(window).width() >= 768){
		  this.sideBar = true;
	  } else {
		  this.sideBar = false;
	  }
    this.setPlan();
    this.setPer(true);

    this.funnUrl = localStorage.getItem('funn') || 'HC';
    
    of (this.sesion).pipe(delay(1000)).subscribe( planes => {
      this.dataLayerService.dl_userId( '.', this.sesion.planCurrently );
      this.begin_checkout();
    })
    
    this.validarFechaFuera();
    this.sesion.loadInitialParameters();
    // Size window 
    
    if ( !this.sesion.descuentoData ) {
      this.sesion.descuentoData = {
        action: 'CONSULTARDESCUENTO',
        session: this.sesion.cookieValue,
        cedula: null,
        params: {
          promoCode: null,
          idPlan: this.sesion.planId,
        }
      };
    }

    setTimeout(() => {
      this.tabsPay(1);
    }, 800);
  }
  ngAfterContentChecked() {
    this.setButton();
  }

  // Seguimiento de inicio de checkout DL GA4
  begin_checkout(){
    this.dataLayerService.dl_begin_checkout(this.sesion, this.sesion.tipoPlan, this.funnUrl);
  }

  validateCode() {
    this.sesion.descuentoData.params.idPlan = this.sesion.planId;
    if (this.sesion.descuentoData.params.promoCode.length === 8) {
      this.sesion.spinnerLoader = true;
      this.giftCard.params.promoCode =  this.sesion.descuentoData.params.promoCode.toUpperCase();
      this.giftCard.params.idPlan =  this.sesion.planId;
      this.getGifcard();
    } else {
      this.sesion.descuento = null;
      this.sesion.valorPlanTotalDescuento = localStorage.getItem('valorPlanTotal');
    }
    //$('#modalCod').modal('show')
  }

  tabsPay(id:any) {
    if(id!=this.tabActivo){
      this.flagBtnP=[false,false,false]
    }
    this.tabActivo = id;
    id==1?this.hov = [true,false]:this.hov = [false,true];  
  }

  esPromo(){
    return (this.sesion.planId == "PER6" || this.sesion.planId == "ANT5" || this.sesion.planId == "PRE5")
  }

  inactiveTab(){
    this.tabActivo = 0;
    this.paymentType = true;
  }

  validarFechaFuera(){
    this.broker.getIntermittenceParameter().subscribe({
      next:(res:any) =>{
        let date = new Date()
        let dateStart = new Date(res.pagos.yearStart, res.pagos.monthStart,res.pagos.dayStart, res.pagos.hourStart, res.pagos.minStart);
        let dateEnd = new Date(res.pagos.yearEnd, res.pagos.monthEnd,res.pagos.dayEnd,res.pagos.hourEnd, res.pagos.minEnd);
        this.sesion.messageIntermittence = res.pagos.message;
        if((date.getTime() >= dateStart.getTime()) && (date.getTime() <= dateEnd.getTime()) ){
          this.modal.modalIntermitencia();
        }
      }
    });
	}

  go_back(){
    this.dataLayerService.dl_remove_from_cart( this.sesion );
    this.evento_abadonar_pagina();   
    this.router.navigateByUrl('/');
  }
  changeFilterView(cod:boolean){
    $("#filterMobile").toggle();
    $("#comparacion").hide();
    $("#backdropFilter").toggleClass("backdropFilter");
  }

  changePlan(){
    $("#comparacion, #btnPagar, #btnPlan, #tab").toggle();
    $("#filterMobile").hide();
    $("#backdropFilter").removeClass("backdropFilter");
  }

  setPlan() {
    switch (this.acron[1]) {
      case 'PER':
        this.plan = this.planes.per;
        this.benefits = this.planes.per.itemsV2;
        if(this.typePlan[1] == '4'){this.typePlan[1] = '5';}
        break;
      case 'ANT':
        this.plan = this.planes.ant;
        this.benefits = this.planes.ant.itemsV2.concat(this.planes.per.itemsV2);
        if(this.typePlan[1] == '5'){this.typePlan[1] = '4';}
        break;
      case 'PRO':
        this.plan = this.planes.pre;
        this.benefits = this.planes.pre.itemsV2.concat(this.planes.ant.itemsV2.concat(this.planes.per.itemsV2));
        if(this.typePlan[1] == '5'){this.typePlan[1] = '4';}
        break;
      default:
        this.plan = this.planes.per;
        this.benefits = this.planes.per.itemsV2;
        if(this.typePlan[1] == '4'){this.typePlan[1] = '5';}
        break;
    }
  }

  setPer(flag:boolean){
    switch (flag?this.typePlan[1]:this.typeT) {
      case '1':
        return Object.assign({}, flag?this.plan.mensual:this.planT.mensual);
      case '2':
        return Object.assign({}, flag?this.plan.trimestral:this.planT.trimestral);
      case '4':
        return Object.assign({}, flag?this.plan.anual:this.planT.anual);
      case '5':
        return Object.assign({}, flag?this.plan.anual:this.planT.anual);
      default:
        return Object.assign({}, flag?this.plan.anual:this.planT.anual);
    }
    
  }

  validateGif(){
    if(this.sesion.valorPlanTotalDescuento == 0 && this.sesion.descuento == 'OK'){
      this.flagBtnP[2]=true;
      return true;
    }else{
      this.flagBtnP[2]=false;
      return false;
    }
  }
  confirmPlan(flag:boolean,flagModalGift:boolean){
    let cPlan = {planId:"",tipoPlan:"",planSeleccionado:{},valorTotal:0,valorMensual:0,funn:""}
    if(flag){
      cPlan = {
        planId:this.acron[1]+this.typePlan[1],
        tipoPlan:this.typePlan[1],
        planSeleccionado:JSON.stringify(this.plan),
        valorTotal:this.setPer(true)[0],
        valorMensual:this.setPer(true)[1],
        funn:this.funnUrl
      }
      this.suscripcion.setStoragePay(cPlan);
      this.acron[0]= this.acron[1];
      this.typePlan[0] = this.typePlan[1];
      if(!flagModalGift){this.sesion.descuentoData.params.promoCode="";$("#comparacion, #btnPagar, #btnPlan, #tab").toggle();}
      this.sesion.descuento = null;
      this.dataLayerService.dl_eventoGA4_1parametro('confirm_change_payment');
      this.begin_checkout();
    }else{
      this.acron[1]= this.acron[0];
      this.typePlan[1] = this.typePlan[0];
      $("#comparacion, #btnPagar, #btnPlan, #tab").toggle();
      this.dataLayerService.dl_eventoGA4_1parametro('discard_change_payment');
    }
    this.setPlan();
    this.setPer(true);
  }

  // activarGiftCard() {
  //     this.giftCard.params.promoCode =  this.sesion.descuentoData.params.promoCode.toUpperCase();
  //     this.giftCard.params.planCode =  this.sesion.planId;
  //     this.sesion.pagos( this.giftCard, 2 );
  // }

  pagar(){
    this.sesion.spinnerDebt = true;
    if(this.flagBtnP[0]&&!this.flagBtnP[1]&&!this.flagBtnP[2]){
      this.childTC.pagarParent(this.datosTC);
      this.dataLayerService.dl_add_payment_info(this.sesion, this.funnUrl, 'Tarjeta de crédito');
    }else if(!this.flagBtnP[0]&&this.flagBtnP[1]&&!this.flagBtnP[2]){
      this.dataLayerService.dl_add_payment_info(this.sesion, this.funnUrl, 'PSE');
      this.childPSE.pagarParent(this.datosPSE);
    }
  }
  enableButton(e:any,medio:string){
    if(medio=="tc"){
      if(e.tValid){
        let tc:NgForm = e.flag;
        tc.valid?this.flagBtnP[0]=true:false;
        this.flagBtnP=[tc.valid?true:false,false,false];
        this.datosTC = tc;
      }
    }else{
      e.valid?this.flagBtnP[1]=true:false;
      this.flagBtnP=[false,e.valid?true:false,false];
      this.datosPSE = e;
    }
  }
  saveAdvertising(){
    if(this.isChecked){
      this.sesion.authorizations.filter(t => t.authorizationType === "commercial").forEach(ta => {
        ta.status = true
      });
      this.sesion.saveAcceptAdvertising(); 
    } 
  }

  setButton(){
    if(this.flagBtnP.includes(true)){
      $('#btnPagarB').prop('disabled', false)
      $("#btnPagarB").removeClass("disabled");
      return true;
    }else{
      $('#btnPagarB').prop('disabled', true)
      $("#btnPagarB").addClass("disabled");
      return false;
    }
  }

  getGifcard(){
    this.sesion.descuento = null;
    this.broker.gifCard(this.giftCard).subscribe({
      next: (response: any) => {
        switch(response.statusCode){
          case 200:
            if(response.data.typePromoCode == "GIFTCARD"){
              localStorage.setItem('tipoPago','2')
              this.sesion.confirmacion = {
                code: 'OK',
                transactionResponse: {
                  operationDate: new Date(),
                  state: 'Aceptado',
                  TX_VALUE: 0,
                  TX_TAX: 0
                }
              };
              this.sesion.confirmacion.code = "OK";
              this.sesion.estadoTransaccion = true;
              this.router.navigate(['/confirmacion-pago']);
              this.sesion.spinnerLoader = false;
            }else{
              this.sesion.descuento = 'OK';
              this.sesion.valorPlanTotalDescuento = response.data.valueToPay;
              this.sesion.spinnerLoader = false;
            }
          break;
          case 205:
          if(response.data.userValidInPlan == true){
            this.setPlanTempo(response.data.idPlan[0]);
            this.paramsT[1] = response.data.percentageDiscount;
            $('#modalCod').modal('show')
            this.sesion.spinnerLoader = false;
          }else{
            this.sesion.descuento = 'BAD';
            this.sesion.valorPlanTotalDescuento = localStorage.getItem('valorPlanTotal');
            this.sesion.spinnerLoader = false;
          }
          break;
          default:
            this.sesion.descuento = 'BAD';
            this.sesion.valorPlanTotalDescuento = localStorage.getItem('valorPlanTotal');
            this.sesion.spinnerLoader = false;
          break;
        }
      },
      error: (error: any) => {
          this.sesion.descuento = 'BAD';
          this.sesion.valorPlanTotalDescuento = localStorage.getItem('valorPlanTotal');
          this.sesion.spinnerLoader = false;
        
      }
    });
  }

  setPlanTempo(idPlanT:string){
    let acron: string[]=[];
    acron[0] = idPlanT.slice(0, 3);
    acron[1] = idPlanT.slice(3, 4);
    this.typeT = acron[1];
    switch (acron[0]) {
      case 'PER':
        this.planT = this.planes.per;
        break;
      case 'ANT':
        this.planT = this.planes.ant;
        break;
      case 'PRO':
        this.planT = this.planes.pre;
        break;
      default:
        this.planT = this.planes.pre;
        break;
    }
    this.paramsT[0] = this.planT.nombre;
    this.paramsT[2] = this.setPer(false)[4];
  }

  confirmModal(){
    $('#modalCod').modal('hide')
    this.plan = this.planT;
    this.typePlan[1] = this.typeT;
    this.acron[1] = this.planT.id;
    this.sesion.planId = this.acron[1]+this.typePlan[1];
    this.confirmPlan(true,true);
    this.validateCode();
  }

  validatePlanUrl(){
    
    const planUrl = localStorage.getItem('planUrl');
    const funn = localStorage.getItem('funn');
    let types:string[]=["1","2","4","5"];
    
    if(planUrl){
      let cPlan = {planId:"",tipoPlan:"",planSeleccionado:{},valorTotal:0,valorMensual:0,funn:funn}
          this.acron[1] = planUrl.slice(0, 3);
          this.typePlan[1] = planUrl.slice(3, 4);
          let filter = types.includes(this.typePlan[1]);
          if(filter){
            switch (this.acron[1]) {
              case 'PER':
                this.plan = this.planes.per;
                if(this.typePlan[1] == '4'){this.typePlan[1] = '5';}
                break;
              case 'ANT':
                this.plan = this.planes.ant;
                if(this.typePlan[1] == '5'){this.typePlan[1] = '4';}
                break;
              case 'PRO':
                this.plan = this.planes.pre;
                if(this.typePlan[1] == '5'){this.typePlan[1] = '4';}
                break;
              default:
                this.router.navigate(['/comparacion-planes']);
                break;
            }
          }else{
            this.router.navigate(['/comparacion-planes']);
          }
          cPlan = {
            planId:this.acron[1]+this.typePlan[1],
            tipoPlan:this.typePlan[1],
            planSeleccionado:JSON.stringify(this.plan),
            valorTotal:this.setPer(true)[0],
            valorMensual:this.setPer(true)[1],
            funn:funn
          }
          this.suscripcion.setStoragePay(cPlan);
          if(!this.sesion.loggedIn){
            window.location.replace(environment.enlaceLogin);
          }else{
            this.acron= [this.plan.id,this.plan.id];
            this.typePlan = [this.typePlan[1],this.typePlan[1]];
          }
    }else{
      this.router.navigate(['/comparacion-planes']);
    }
  }

  /**
   * Este evento se activará siempre y cuando el usuario de clic en el botón "regresar del navegador"
   */
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: any) {
    this.evento_abadonar_pagina();
    this.dataLayerService.dl_remove_from_cart( this.sesion );
  }

  /**
   * Evento de remarkety sobre abandonar la página del carrito
  */
  evento_abadonar_pagina(){
    const cDate = new Date();
    const cDateHead = new Date(cDate.getUTCFullYear(),0,1); 
    let email = this.sesion.user.correo;
    let cartId = (parseInt(((cDate.getTime()-cDateHead.getTime())/(1000)).toString())* 10) + parseInt(((cDate.getTime()-cDateHead.getTime())%(100)).toString());
    let price = this.sesion.valorPlanTotal; 
    let productId = this.sesion.planIdCart();
    let productTitle = '';
   
    // PLAN PERFIL DE CRÉDITO
    // Mensual
    if (productId === 'plan_perfil_mensual'){ productTitle = 'Plan Perfil de Crédito (Mensual)'; }
    // Trimestral
    if (productId === 'plan_perfil_trimestral'){ productTitle = 'Plan Perfil de Crédito (Trimestral)'; }
    // Anual
    if (productId === 'plan_perfil_anual'){ productTitle = 'Plan Perfil de Crédito (Anual)'; }
    
    // PLAN PROTEGE
    // Mensual
    if (productId === 'plan_proteje_mensual'){ productTitle = 'Plan Protege (Mensual)'; }
    // Trimestral
    if (productId === 'plan_proteje_trimestral'){ productTitle = 'Plan Protege (Trimestral)'; }
    // Anual
    if (productId === 'plan_proteje_anual'){ productTitle = 'Plan Protege (Anual)'; }
    
    // PLAN PREMIUM
    // Mensual
    if (productId === 'plan_premium_mensual'){ productTitle = 'Plan Premium (Mensual)'; }
    // Trimestral
    if (productId === 'plan_premium_trimestral'){ productTitle = 'Plan Premium (Trimestral)'; }
    // Anual
    if (productId === 'plan_premium_anual'){ productTitle = 'Plan Premium (Anual)'; }

    const data = {
      "abandoned_checkout_url": this.router.url,
      "accepts_marketing": true,
      "currency": "COP",
      "customer": { "accepts_marketing": true, "email": email},
      "email": email,
      "id": cartId,
      "line_items": [{"product_id":productId,"quantity": 1,"title": productTitle,"price":price}],
      "total_discounts": 0,"total_price": price
    }

    this.broker.request_remarkety(data, 'carts/update').subscribe(result => {
    },
    error => {}
    );
  }
}
