import { Component, OnInit, Input, Output, EventEmitter, HostListener  } from '@angular/core';
import { Plan } from '../../core/models/plan.model';
import { SesionService } from '../../core/services/sesion.service';
import { environment } from '../../../environments/environment';
import { NgForm,FormControl } from '@angular/forms';
import { BrokerService } from 'src/app/core/services/broker.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { ConstantesService } from 'src/app/core/services/constantes.service';
import {Observable} from 'rxjs';

declare var $: any;
declare var payU: any;

interface Pse {
  action: any;
  session: any;
  params: {
    transaction: {
      order: {
        description: any;
        referenceCode: any;
        notifyUrl: any;
        buyer: {
          emailAddress: any;
          shippingAddress: {
            street1: any;
            street2: any;
            city: any;
            state: any;
            country: any;
            postalCode: any;
            phone: any;
          }
        }
      },
      payer: {
        fullName: any;
        firstName: any;
        lastName: any;
        emailAddress: any;
        dniNumber: any;
        contactPhone: any;
        billingAddress: {
          street1: any;
          street2: any;
          city: any;
          state: any;
          country: any;
          postalCode: any;
          phone: any;
        }
      },
      extraParameters: {
        RESPONSE_URL: any;
        PSE_REFERENCE1: any;
        FINANCIAL_INSTITUTION_CODE: any;
        USER_TYPE: any;
        PSE_REFERENCE2: any;
        PSE_REFERENCE3: any;
        PSE_TYPE_COUNT: any;
        PSE_NUMBER_COUNT: any;
      },
      paymentMethod: any;
      paymentCountry: any;
      ipAddress: any;
      cookie: any;
      userAgent: any;
    },
    test: any;
    giftcardCode: any;
    planCode: any;
    recurrency: any;
  };
}

@Component({
  selector: 'app-pse',
  templateUrl: './pse.component.html',
  styleUrls: ['./../tc/tc.component.scss']
})
export class PseComponent implements OnInit {

  pse: Pse;
  @Output() inactiveTab = new EventEmitter<void>();
  @Output() sentActiveBtnPago = new EventEmitter<any>();
  @Output() sentSaveAdvertising = new EventEmitter<any>();
  ciudades:any = [];
  screenWidth:any;
  filterC:string="";
  constructor(public sesion: SesionService, public broker: BrokerService,
    public modal: ModalService, public constantes: ConstantesService) {
    // Listado bancos
    setTimeout(() => {
      this.sesion.listadoBancos();
    }, 2000);

    // Listado países
    this.sesion.obtener_paises();
    this.sesion.obtener_ciudades();
    // this.filteredOptions = this.myControl.valueChanges.pipe(
    //   startWith(''),
    //   map(value => this._filter(value || '')),
    // );

    this.getScreenSize();

    this.pse = {
      action: 'PSE',
      session: sesion.cookieValue,
      params: {
        transaction: {
          order: {
            description: null,
            referenceCode: this.sesion.getCookieServices('UTMC'),
            notifyUrl: null,
            buyer: {
              emailAddress: sesion.user.correo,
              shippingAddress: {
                street1: null,
                street2: null,
                city: null,
                state: null,
                country: null,
                postalCode: null,
                phone: sesion.user.phone
              }
            }
          },
          payer: {
            fullName: sesion.user.nombre + ' ' + sesion.user.apellido,
            firstName: '',
            lastName: '',
            emailAddress: sesion.user.correo,
            dniNumber: '',
            contactPhone: sesion.user.phone,
            billingAddress: {
              street1: null,
              street2: null,
              city: null,
              state: null,
              country: 'CO',
              postalCode: null,
              phone: '',
            }
          },
          extraParameters: {
            RESPONSE_URL: environment.payULatam.notifyURL,
            PSE_REFERENCE1: null,
            FINANCIAL_INSTITUTION_CODE: '0',
            USER_TYPE: 'N',
            PSE_REFERENCE2: 'CC',
            PSE_REFERENCE3: null,
            PSE_TYPE_COUNT: 'A',
            PSE_NUMBER_COUNT: null,
          },
          paymentMethod: 'PSE',
          paymentCountry: 'CO',
          ipAddress: null,
          cookie: null,
          userAgent: navigator.userAgent
        },
        test: false,
        giftcardCode: null,
        planCode: this.sesion.planId,
        recurrency: 1
      }
    };
    setTimeout(() => { 
      this.ciudades=this.sesion.ciudades
    }, 500);
  }

  @HostListener('window:resize', ['$event'])
    getScreenSize() {
          this.screenWidth = window.innerWidth;
    }
  ngOnInit() {
    $(document).ready(function () {
      $("form").keypress((e: any) => {
        if (e.which == 13) {
          return false;
        }
        return true;
      });
    });
  }

  realizarPago(datos: NgForm) {
    if (this.sesion.stateEvidente === 2) {
      window.location.replace(environment.enlaceEvidente);
    } else {
      if (datos.valid) {
        this.sesion.spinnerDebt = true;
        this.sesion.btnValidar = false;
        $("#validar").hide();
        // tslint:disable-next-line: max-line-length
        this.pse.params.transaction.payer.contactPhone = this.pse.params.transaction.payer.billingAddress.phone;
        this.pse.params.transaction.extraParameters.PSE_REFERENCE3 = this.pse.params.transaction.payer.dniNumber;

        // tslint:disable-next-line: max-line-length
        this.pse.params.transaction.order.buyer.shippingAddress.street1 = this.pse.params.transaction.payer.billingAddress.street1;
        // tslint:disable-next-line: max-line-length
        this.pse.params.transaction.order.buyer.shippingAddress.street2 = this.pse.params.transaction.payer.billingAddress.street2;
        // tslint:disable-next-line: max-line-length
        this.pse.params.transaction.order.buyer.shippingAddress.city = this.pse.params.transaction.payer.billingAddress.city;
        // tslint:disable-next-line: max-line-length
        this.pse.params.transaction.order.buyer.shippingAddress.state = this.pse.params.transaction.payer.billingAddress.state;
        // tslint:disable-next-line: max-line-length
        this.pse.params.transaction.order.buyer.shippingAddress.country = this.pse.params.transaction.payer.billingAddress.country;
        // tslint:disable-next-line: max-line-length
        this.pse.params.transaction.order.buyer.shippingAddress.postalCode = this.pse.params.transaction.payer.billingAddress.postalCode;
        // tslint:disable-next-line: max-line-length
        this.pse.params.transaction.order.buyer.shippingAddress.phone = this.pse.params.transaction.payer.billingAddress.phone;

        this.pse.params.planCode = this.sesion.planId,

          // Description
          // tslint:disable-next-line: max-line-length
          this.pse.params.transaction.order.description = this.sesion.plan.popup + ' - Tipo: ' + this.sesion.tipoPlan + ' - Promocode: ' + this.sesion.descuentoData.params.promoCode;
        // if (this.pse.params.recurrency) {
        //   this.pse.params.recurrency = 1;
        // } else {
        //   this.pse.params.recurrency = 0;
        // }
        // if(this.sesion.tipoPlan === '1'){
        //     this.pse.params.recurrency = 1;
        // } else {
        //   if(this.pse.params.recurrency == 1) {
        //     this.pse.params.recurrency = 1;
        //   } else {
        //     this.pse.params.recurrency = 0;
        //   }            
        // }
        // Promocode
        // tslint:disable-next-line: max-line-length
        this.sesion.descuento === 'OK' ? this.pse.params.giftcardCode = this.sesion.descuentoData.params.promoCode.toUpperCase() : this.pse.params.giftcardCode = null;
        this.sesion.pagos(this.pse, 3);
        this.setUtmPago();
        this.customer();
        this.cart();
        //CHECK PUBLICIDAD          
        //CHECK PUBLICIDAD          
          this.sentSaveAdvertising.emit() 
      }
    }
  }

  back() {
    window.history.back();
  }

  customer() {
    const MD5 = require("crypto-js/md5");

    const cDate = new Date();
    const email = this.sesion.user.correo;
    const customerId = MD5(email).toString(); //Sugiero usar uno id basado en el email y timestamp 
    const first_name = this.pse.params.transaction.payer.firstName;
    const last_name = this.pse.params.transaction.payer.lastName;
    const phone = this.pse.params.transaction.payer.billingAddress.phone;

    const Data = {
      "accepts_marketing": true,
      "created_at": cDate,
      "email": email,
      "first_name": first_name,
      "last_name": last_name,
      "id": customerId,
      "updated_at": cDate,
      "phone": phone
    }
    this.broker.request_remarkety(Data, 'customers/create').subscribe(result => {
    },
      error => { }
    );
  }

  cart() {
    const MD5 = require("crypto-js/md5");

    const cDate = new Date();
    const email = this.sesion.user.correo;
    const product_id = this.sesion.planIdCart();
    const total_price = this.sesion.valorPlanTotal.replace('.', '');
    const cDateHead = new Date(cDate.getUTCFullYear(), 0, 1);
    const cartId = (parseInt(((cDate.getTime() - cDateHead.getTime()) / (1000)).toString()) * 10) + parseInt(((cDate.getTime() - cDateHead.getTime()) % (100)).toString());
    const customerId = MD5(email).toString(); //Sugiero usar uno id basado en el email 
    const quantity = 1; //Siempre tendremos un solo producto por carrito 
    const product_title = this.sesion.plan.popup;
    const product_price = this.sesion.valorPlanTotal.replace('.', '');
    const Data = {
      "abandoned_checkout_url": "",
      "accepts_marketing": true,
      "created_at": cDate,
      "currency": "COP",
      "customer": {
        "accepts_marketing": true,
        "email": email,
        "id": customerId
      },
      "email": email,
      "id": cartId,
      "line_items": [{
        "product_id": product_id,
        "title": product_title,
        "price": product_price,
        "quantity": quantity,
      }],
      "total_discounts": 0,
      "total_price": total_price,
      "updated_at": cDate
    };
    this.broker.request_remarkety(Data, 'carts/create').subscribe(result => {
    },
      error => { }
    );
  }

  inactiveTabF() {
    this.inactiveTab.emit();
  }

  validarData(flag: NgForm) {
    this.sentActiveBtnPago.emit(flag)
  }

  pagarParent(e: NgForm) {
    this.realizarPago(e);
  }

  focus(ele: any) {
    if(this.screenWidth < 720){

      $('html, body').animate({
        scrollTop: $('#' + ele.srcElement.id).offset().top - 30
      }, 500);
    }
  }

  
  filter() {
    const filterValue = this.filterC.toLowerCase();
    this.filterC != undefined?this.ciudades = this.sesion.ciudades.filter((option:any) => option.NAME_MUN.toLowerCase().startsWith(filterValue)):this.ciudades=this.sesion.ciudades;
  }
  resetCiudades(){
    this.filterC = "";
    if(this.ciudades.length == 0 || this.ciudades == undefined){
      this.ciudades=this.sesion.ciudades
    }
  }
  async setUtmPago(){
    let params = JSON.parse(sessionStorage.getItem('utmParams') as string);
      let info = {
        sessionId: this.sesion.cookieValue,
        eventType:'purchase intent',
        paymentMethod:'pse',
        eventDate: Date.now(),
        utm_source: "",
        utm_medium: "",
        utm_campaign: ""
      }
      if(params != null){
        if(params.utm_source){info.utm_source=params.utm_source};
        if(params.utm_medium){info.utm_medium=params.utm_medium};
        if(params.utm_campaign){info.utm_campaign=params.utm_campaign};
      }
      await this.broker.setUtmPago(info).subscribe();
  }

}