import { Component, OnInit } from '@angular/core';
import { SesionService } from 'src/app/core/services/sesion.service';
import { Router } from '@angular/router';
import { RoutingService } from 'src/app/core/services/routing.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
declare var $: any;

@Component({
	selector: 'app-dash-history',
	templateUrl: './dash-history.component.html',
	styleUrls: ['./dash-history.component.scss']
})
export class DashHistoryComponent implements OnInit {

	constructor(public sesion: SesionService, public router: Router, public routing: RoutingService, private dataLayer: DataLayersService ) { }

	ngOnInit(): void {
	}

	totalCA() {
		let totalAbiertas = 0;
		for (let i = 0; i < (this.sesion.resumenhc != undefined ? this.sesion.resumenhc.length : 0); i++) {
			let num = this.sesion.resumenhc[i].cuentasVigentes;
			totalAbiertas = num + totalAbiertas;
		}
		return totalAbiertas;
	}

	totalCC() {
		let totalCerradas = 0;

		for (let i = 0; i < (this.sesion.ctascerradas != undefined ? this.sesion.ctascerradas.length : 0); i++) {
			let num2 = this.sesion.ctascerradas[i].cuentasVigentes;
			totalCerradas = num2 + totalCerradas;
		}
		return totalCerradas;
	}

	totalRe() {
		let onlyRec = [];
		if (this.sesion.reclamaciones != undefined) {
			onlyRec = this.sesion.reclamaciones.filter((ele: any) => ele.numero != "0");
		}
		// total = (this.sesion.reclamaciones != undefined ? onlyRec.length : 0);
		return onlyRec.length;
	}

	totalCB() {
		let onlyCB = [];
		if (this.sesion.reclamaciones != undefined) {
			onlyCB = this.sesion.reclamaciones.filter((ele: any) => ele.numero == "0");
		}
		// total = (this.sesion.reclamaciones != undefined ? onlyRec.length : 0);
		return onlyCB.length;
	}

	/**
	 * Lleva al usuario al historico y envia un data layer del botón
	*/
	redirect_and_datalayer( button_name: string ){
		this.dataLayer.dl_eventoGA4_menu_dashboard(button_name, 'Midatacrédito');
		this.routing.redirectAllAccess('0', '/historico');
	}

}
