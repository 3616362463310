<div class="oneshot">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-6 col-12 rightShot">
				<div class="principal">
					<img src="assets/img/images/img_webcheck_1.svg" alt="check" title="Check">
				</div>
			</div>
			<div class="col-md-6 col-12 leftShot">
				<div class="logo">
					<img src="assets/img/logos/logo_midc.svg" alt="Midatacrédito" title="Logo midatacrédito">
				</div>
				<div class="logoMob">
					<img src="assets/img/logos/logo_midc_white.svg" alt="Midatacrédito, consulta tu historia de crédito" title="Logo midatacrédito">
				</div>
				<div class="steps-gray">
					<div class="step">Midatacrédito <b>| Adquiere tu plan</b></div>
					<br />
					<p class="title-webcheck">BIENVENIDO A MIDATACRÉDITO</p>
				</div>
				<div class="content-webcheck">
					<p><b>{{nombre}}</b>, actualiza tu método de pago y renueva tu plan <b>{{plan}}</b> sin perder los
						beneficios que tienes actualmente.</p>
					<div class="plan row">
						<div class="col-md-6 col-12 titulo-plan text-center">
							<div>
								<b>{{tituloPlan}}</b>
							</div>
						</div>
						<div class="col-md-6 col-12 contenido-plan">
							<div class="lista-caracteristica" *ngFor="let caracteristica of caracetristicasPlan">
								<div class="iconCaracte">
									<img src="assets/img/icons/feat_prem.svg" alt="caracteristica">
								</div>
								<div class="caracteText">
									<span>{{caracteristica}}</span>
								</div>
							</div>
						</div>
					</div>
					<br />
					<button class="btn btn-block mdc-btn-secondary" (click)="irPagar()">
						Pagar &nbsp;&nbsp;{{valorPagar | currency :'COP':'symbol-narrow'}}
						<i class="fa fa-chevron-right float-right mt-arrow"></i>
					</button>
				</div>
			</div>
		</div>
	</div>
	<!-- <form method="post" action="https://sandbox.checkout.payulatam.com/ppp-web-gateway-payu/" class="formHidden" id="formPago"> -->
	<form method="post" action="https://checkout.payulatam.com/ppp-web-gateway-payu/" class="formHidden" id="formPago">		
		<input name="amount" type="hidden">
		<input name="merchantId" type="hidden">
		<input name="referenceCode" type="hidden">
		<input name="accountId" type="hidden">
		<input name="description" type="hidden">
		<input name="tax" type="hidden">
		<input name="taxReturnBase" type="hidden">
		<input name="signature" type="hidden">
		<input name="currency" type="hidden">
		<input name="buyerEmail" type="hidden">
		<input name="lng" type="hidden">
		<input name="extra1" type="hidden">
		<input name="confirmationUrl" type="hidden">
		<input name="mobilePhone" type="hidden">
		<input name="shippingAddress" type="hidden">
		<input name="telephone" type="hidden">
		<input name="algorithmSignature" type="hidden">
		<input name="shippingCity" type="hidden">
		<input name="shippingCountry" type="hidden">
		<input name="buyerFullName" type="hidden">
		<input name="responseUrl" type="hidden">
	</form>
</div>
<app-footer></app-footer>
<app-loading></app-loading>