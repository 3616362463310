import { Component, ElementRef, OnInit } from '@angular/core';
import { SesionService } from '../../core/services/sesion.service';
import { ReclamacionService } from '../../core/services/reclamacion.service';
import { Router } from '@angular/router';
import { ModalService } from '../../core/services/modal.service';
import { NgForm } from '@angular/forms';
import { BrokerService } from 'src/app/core/services/broker.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
declare var $: any;

@Component({
  selector: 'app-reclamacion',
  templateUrl: './reclamacion.component.html',
  styleUrls: ['./reclamacion.component.scss']
})
export class ReclamacionComponent implements OnInit {

  detalleReclamo: any;
  detalleDescripcion: any;
  terminos: boolean;
  reclamomail: boolean;
  confirmarEmail: any;
  errorMessage: any;
  activoPaso2 = false;
  activoPaso3 = false;
  public fechaHoy =  new Date();

  constructor(public sesion: SesionService, public reclamo: ReclamacionService, private router: Router, private modal: ModalService, public elementRef: ElementRef, public broker: BrokerService, private dlService: DataLayersService) {

    // Listado países
    this.sesion.obtener_paises();
    this.sesion.obtener_ciudades();
    this.terminos = true;
	this.reclamomail = false;
	this.reclamo.reclamoBase();
    if (this.reclamo.tiposReclamos == undefined) {
      this.broker.TiposReclamaciones().subscribe((data: any) => {
        this.reclamo.tiposReclamos = data;
        this.reclamo.reclamo.reclamo.tipoReclamo = '-1';
		this.reclamo.reclamo.reclamo.subtipoReclamo = '-1';
      },
        (error) => {
          // this.router.navigate(['/error']);
        }
      );
    } else {
      setTimeout(() => {
        this.reclamo.reclamo.reclamo.tipoReclamo = '-1';
		this.reclamo.reclamo.reclamo.subtipoReclamo = '-1';
	  }, 500);
    }
  }

	ngOnInit() {
		// GA4 - User
		this.dlService.dl_userId( '.', this.sesion.planCurrently );
		this.reclamo.paso1 = true;
		this.reclamo.paso2 = false;
		this.reclamo.paso3 = false;
		this.infoReclamo();
		setTimeout(() => {
			var boxFilter = $(".txtScore");
			var p = document.createElement("p");
			boxFilter.append(p)
			if (this.sesion.miscoreInt > 630) {
				p.append("Tu puntaje de crédito está por encima del promedio de los colombianos.");
			} else if (this.sesion.miscoreInt > 310 && this.sesion.miscoreInt <= 630) {
				p.append("Tu puntaje de crédito está en el promedio de los colombianos.");
			} else if (this.sesion.miscoreInt <= 310) {
				p.append("Tu puntaje de crédito está por debajo del promedio de los colombianos.");
			}
		}, 5000);
	}

  	infoReclamo() {
    	if (!this.reclamo.cuenta) {
			if (this.sesion.viewFree) {
				this.router.navigate(['/freemium/historico']);
			} else {
				this.router.navigate(['/private/historico']);
			}
    	} else {
			// this.reclamo.reclamoBase();
			this.reclamo.reclamo.sessionId = this.sesion.cookieValue;
			this.reclamo.reclamo.reclamo.correoElectronico = this.sesion.user.correo;
			this.reclamo.reclamo.reclamo.tipoReclamo = '-1';	
			this.reclamo.reclamo.reclamo.subtipoReclamo = '-1';		
			this.reclamo.reclamo.reclamo.numeroTelefono = this.sesion.user.phone;
			this.reclamo.reclamo.accountE = this.reclamo.cuenta.accountE;
			this.reclamo.reclamo.accountType = this.reclamo.cuenta.tipoCuenta;
			this.reclamo.reclamo.idEntity = this.reclamo.cuenta.identificacion;
			switch ( this.reclamo.cuenta.tipoCuenta ) {
				case 'CCB':
					this.reclamo.reclamo.accountType = 'CC'
				break;
				case 'TDC':
					this.reclamo.reclamo.accountType = 'TC'
				break;
				case 'AHO':
					this.reclamo.reclamo.accountType = 'CA'
				break;
				default:
					this.reclamo.reclamo.accountType = 'CCA'
				break;
			}  
    	}
  	}

	seleccionarTipo( codigo: string) {
		const found = this.reclamo.tiposReclamos.find((data:any) => data.tipo === codigo);
		const index = this.reclamo.tiposReclamos.findIndex((data:any) => data.tipo === codigo);
		if ( Array.isArray(found.detalles) ) {
			this.detalleReclamo = found.detalles;
			this.reclamo.reclamo.reclamo.subtipoReclamo = '-1';
		} else {
			this.detalleReclamo = [found.detalles];
			this.reclamo.reclamo.reclamo.subtipoReclamo = '-1';
		}
	}

	seleccionarSubtipo( codigo: string) {
		const found = this.detalleReclamo.find((data:any) => data.codigo === codigo);    
		this.detalleDescripcion = found;
	}

	reclamoPaso1(data: NgForm) {
		if ( data.valid ) {
			this.reclamo.paso1 = false;
			this.reclamo.paso2 = true;
			this.reclamo.paso3 = false;
			this.activoPaso2 = true;
		}
	}

	reclamoPaso2(data: NgForm) {
		if ( data.valid ) {
			if (this.reclamo.reclamo.reclamo.correoElectronico === this.confirmarEmail  ) {
				this.errorMessage = null;
				this.reclamo.crearReclamo();
				this.activoPaso3 = true;
			} else {
				this.errorMessage = 'Los correos ingresados no coinciden';
			}
		} else {
			this.errorMessage = 'Datos inválidos. Verifique e intente nuevamente';
		}
  	}

	volver(){
		this.reclamo.reclamoBase();
		if(this.sesion.estadoUserF == 'ACTIVO' || this.sesion.estadoUserF == 'ACTIVE_PROCESS'){
			this.router.navigate(['/private/historico']);
			// this.sesion.infoHC(true);
		} else{
			this.router.navigate(['/freemium/historico']);
		}
		this.reclamo.paso1 = true;
		this.reclamo.paso2 = false;
		this.reclamo.paso3 = false;
	}

	salir() {
		this.reclamo.reclamoBase();
		if(this.sesion.estadoUserF == 'ACTIVO' || this.sesion.estadoUserF == 'ACTIVE_PROCESS'){
			this.router.navigate(['/private/historico']);
		// this.sesion.infoHC(true);
		}else{
			this.router.navigate(['/freemium/historico']);
		}
		this.reclamo.paso1 = true;
		this.reclamo.paso2 = false;
		this.reclamo.paso3 = false;
	}

	usuarioActivo(){
		return this.sesion.estadoUserF == 'ACTIVO';
	}

	checkStep1(){
		let tipo = this.reclamo.reclamo.reclamo.tipoReclamo;
		let subTipo = this.reclamo.reclamo.reclamo.subtipoReclamo;
		return (tipo != '-1' && subTipo != '-1');
	}

}
