import { Component, OnInit, Input } from '@angular/core';
import { SesionService } from 'src/app/core/services/sesion.service';

@Component({
  selector: 'app-mis-reclamaciones',
  templateUrl: './mis-reclamaciones.component.html',
  styleUrls: ['./mis-reclamaciones.component.scss']
})
export class MisReclamacionesComponent implements OnInit {
  @Input() data: any;
  @Input() showInfo: boolean = true;
  @Input() noPadding: boolean = false;
  constructor(public sesion:SesionService) { }

  ngOnInit() {
  }

}
