<div class="container-fluid faq-main">
    <div class="faqHeader">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-7">
                    <div class="titFaqGral">
                        <h1 class="faqTitle">¿En qué podemos ayudarte?</h1>
                        <div class="faqDescription">En nuestra sección de preguntas frecuentes puedes resolver cualquier consulta, también encontrarás información sobre muchos temas.</div>
                        <hr/>
                    </div>
                </div>
                <div class="col-12 col-md-5 text-center">
                    <img src="assets/img/images/icono_preguntas_frecuentes.svg" class="faqImg" alt="Preguntas Frecuentes" title="Preguntas Frecuentes">  
                </div>
            </div>
        </div>
    </div>
	<div class="container">
        <div id='div-gpt-ad-1716905496511-0' class="commercial-banner" [ngClass]="checkBanner()"></div>
        <h2 class="title-faqs">Busca respuestas por categorías</h2>
        <div class="row faqGroup">
            <button *ngFor="let faq of faqs;let i = index;" class="inactivo" (click)="selFaq(faq.id)" [id]="'btn-faq-'+faq.id" >
                <span>{{faq.grupo}}</span>
                <img [src]="'assets/img/icons/'+faq['img-morado']" [alt]="faq['textoalt']"/>
            </button>
        </div>
        <div class="row box-faqs">
            <div class="col-12">
                <div class="accordion"  id="faqAccordion">
                    <div *ngFor="let pregunta of selFaqObject?.preguntas; let j = index;">
                        <div class="card-header faqQuestion" id="faq{{j}}">
                            <button class="btn btn-link" type="button" data-toggle="collapse" [attr.data-target]="'#collapse'+j" aria-expanded="false" [attr.aria-controls]="'collapse'+j" (click)="changeType(j)">
                                <!-- <i class="fa fa-plus" aria-hidden="true" id="moreIcon{{i+'-'+j}}"></i> -->
                                <span>{{pregunta.pregunta}}</span>
                                <i class="fa fa-angle-right" id="moreIcon{{j}}"></i>                                
                            </button>
                        </div>                        
                        <div id="collapse{{j}}" class="collapse" [attr.aria-labelledby]="'faq'+j" [attr.data-parent]="'#faqAccordion'">
                            <div class="card-body faqAnswer" [innerHTML] = "pregunta.respuesta">                                
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</div>