import { Component,Input, OnInit } from '@angular/core';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { SesionService } from 'src/app/core/services/sesion.service';

@Component({
  selector: 'app-dash-pay-option',
  templateUrl: './dash-pay-option.component.html',
  styleUrls: ['./dash-pay-option.component.scss']
})
export class DashPayOptionComponent implements OnInit {
  @Input() id: string = "ANT";

  constructor(private dataLayer: DataLayersService, private sesion: SesionService ) { }

  ngOnInit(): void {
  }

  // Enviar al usuario a comprar plan y enviar data layer de botón
  payPlan_send_datalayer(button_name: string){
    if (button_name == 'ant'){
      this.dataLayer.dl_add_to_cart(this.sesion.planes.planes.ant, this.sesion.planes.planes.ant.mensual[0], 'Mensual', 'DASHBOARD');
    }
    if (button_name == 'pro'){
      this.dataLayer.dl_add_to_cart(this.sesion.planes.planes.pre, this.sesion.planes.planes.pre.mensual[0], 'Mensual', 'DASHBOARD');
    }
  }

}
