import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../core/services/modal.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { BrokerService } from 'src/app/core/services/broker.service';
import { SuscripcionService } from 'src/app/core/services/suscripcion.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { ReclamacionService } from 'src/app/core/services/reclamacion.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';

declare var $: any;

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  data: any;
  scrollCheck = true;
  url_politica_privacidad = environment.enlaceS3 + environment.s3.politicaDePrivacidad;
  url_terminos_condiciones = environment.enlaceS3 + environment.s3.terminosYCondiciones;

  constructor(
    public content: ModalService,
    public sesion: SesionService,
    public broker: BrokerService,
    public suscripcion: SuscripcionService,
    public loading: LoadingService,
    private reclamo: ReclamacionService,
    private router: Router,
    private dataLayer: DataLayersService,
    public utilities:UtilitiesService
  ) {
    this.data = {
      publicity: ''
    }
  }

  ngOnInit() {
    // this.sesion.obtener_planes();
  }

  reportarMora() {
    const data = {
      sessionId: this.sesion.cookieValue,
      accountId: this.broker.llave,
      activated: true
    };

    this.broker.reportarMora(data).subscribe((reportar: any) => {
      if (this.sesion.reporteCovid) {
        this.sesion.reporteCovid.push({ 'accountId': this.broker.llave, status: "ACTIVA" });
      } else {
        this.sesion.reporteCovid = [{ 'accountId': this.broker.llave, status: "ACTIVA" }];
      }
    });
  }

  /*** Aceptar nuevos términos y condiciones y autorizar el envío de publicidad. ***/
  aceptar(datos: any) {
    $('#cerrarModal').click();
    this.guardarTerminos();
  }

  guardarTerminos() {
    this.sesion.saveTerms();
  }

  /*** No aceptar nuevos términos y condiciones y no autorizar el envío de publicidad. ***/
  noAceptar() {
    this.sesion.dontAccept();
  }

  seleccionarPlan(plan: any, tipo: any, valorMensual: any, valorTotal: any) {
    this.loading.open();

    //this.broker.promov = 3;
    this.suscripcion.planSeleccionado = plan;
    this.suscripcion.tipoPlan = tipo;
    this.suscripcion.valorSeleccionadoMensual = valorMensual;
    this.suscripcion.valorSeleccionadoTotal = valorTotal;
    if (this.sesion.user) {
      this.suscripcion.planSesion(true, this.content.funnModal);
    } else {
      this.suscripcion.planSesion(false, this.content.funnModal);
    }
    this.loading.close();
  }

  formularReclamacion() {
    this.reclamo.cuenta = this.content.cuenta;
    this.sesion.getReclamo(this.content.cuenta);
  }

  salirReclamacion() {
    this.reclamo.reclamoBase();
    if (this.sesion.estadoUserF == 'ACTIVO' || this.sesion.estadoUserF == 'ACTIVE_PROCESS') {
      this.router.navigate(['/private/historico']);
      this.sesion.infoHC(true);
    } else {
      this.router.navigate(['/freemium/historico']);
      this.sesion.freepremium(true);
    }
    this.reclamo.paso1 = true;
    this.reclamo.paso2 = false;
    this.reclamo.paso3 = false;
  }

  irVerifica() {
    window.open("https://www.midatacredito.com/verifica/#/", "_blank");
  }

  guardarTerminosPublicidad() {
    this.sesion.authorizations.filter(t => t.authorizationType === "commercial").forEach(ta => {
      ta.status = true
    });
    this.sesion.saveAcceptAdvertising();
    $(".alert-promo").addClass("bg-check");
    this.content.txtDefault = false;
    this.content.txtAprroved = true;
    $(".btn-outline-white").addClass("btn-check");
    $(".btn-outline-white span").hide();
    $(".btn-outline-white i").addClass("i-check");
    //this.acceptAdvertising = false;
    // $('#cerrarModal').click();
  }

  checkScrollReclamation() {
    let top = $(".scroll-check-claim > ol").position().top;
    top = top * -1;
    this.scrollCheck = (top <= 650);
  }

  closeIntermitencia() {
    $('#modalIntermitencia').modal('hide');
  }

  closeModalPadYa(){
    $('#pdaYaModal').modal('hide');    
  }

  public isLoadApplyPadYa: boolean = false;
  public canCallApplyPadYa: boolean = true;  
  redirectPadYa() {
    this.isLoadApplyPadYa = true;
    if(this.canCallApplyPadYa){
      if (this.content.itemPadYa != 0) {
        this.dataLayer.dl_eventoGA4_padya(this.content.ctaPadYa, this.getPathCtaPadYa(), this.content.urlPadYa);    
        this.canCallApplyPadYa = false;
        let text = this.content.itemPadYa.toString();
        this.utilities.getUtmParams(false);
        let sourceOfTraffic = '';
        let campaignOfTraffic = '';
        let mediumOfTraffic = '';
        if ((Object.keys(this.utilities.utmParams).length > 0)) {
          for (const key in this.utilities.utmParams) {
            if (key.toLowerCase() == 'utm_source') {
              sourceOfTraffic = this.utilities.utmParams[key]
            }
            if (key.toLowerCase() == 'utm_medium') {
              mediumOfTraffic = this.utilities.utmParams[key]
            }
            if (key.toLowerCase() == 'utm_campaign') {
              campaignOfTraffic = this.utilities.utmParams[key]
            }
          }
        }
        this.broker.registerApplyPAD(text, this.content.estadotexto, this.content.saldoMora, this.content.numeroCuenta,sourceOfTraffic,campaignOfTraffic,mediumOfTraffic, this.content.consumerNumber).subscribe(
          {
            next: (data: any) => {
              this.content.consumerNumber = '';
              this.content.itemPadYa = 0;
              this.canCallApplyPadYa = true;
              this.isLoadApplyPadYa = false;
              this.closeModalPadYa()
              window.open(this.content.urlPadYa, '_self');
            },
            error: (error) => {
              this.content.consumerNumber = '';
              this.content.itemPadYa = 0;
              this.canCallApplyPadYa = true;
              this.isLoadApplyPadYa = false;
              this.closeModalPadYa()
            }
          }
        );
      }else{
        this.isLoadApplyPadYa = false;
      }
    }
  }

  getPathCtaPadYa() {
    let url = this.router.url.toLocaleLowerCase();
    if (url.includes('home')) {
      return 'btn_cta_dashboard';
    } else if (url.includes('historico')) {
      return 'btn_cta_hc';
    } else if (url.includes('detalle-cuenta')) {
      return 'btn_cta_account_detail';
    }else if (url.includes('modulopadapp')) {
      return 'btn_cta_app';
    } else {
      return 'btn_cta_other';
    }    
  }

}