<section class="pb-2">
    <div class="container h-100">
        <div class="row">
            <div class="col-12">
                <div class="title">
                    <div class="left">
                        <img src="assets/img/mi-deuda/Icono_Mis Deudas.svg" title="Mis Deudas Y Pagos"
                            alt="Mis Deudas Y Pagos" />
                        <h1>Mis Deudas y Pagos</h1>
                    </div>
                    <div class="right">
                        <p>Encuentra todas tus deudas consolidadas en un solo lugar, revisa tus pagos mensuales y planea
                            un pago acelerado de tus deudas.</p>
                    </div>
                </div>
            </div>
            <div class="col-12" *ngIf="sesion.misdeudas == undefined">
                <app-micro-loader [texto]="textoMicroLoader" [error]="sesion.errorServiceCreditHistory" [textoError]="textErrorMicroLoader">
                </app-micro-loader>
            </div>
            <div class="col-12 col-lg-5" >
                <canvas  baseChart 
                    class="m-auto"
                    [data]="sesion.resumenDeudasChartData"
                    [type]="sesion.resumenDeudasChartType"
                    [legend]="false">
                </canvas>
                <p class="mdc-text-secondary text-center" *ngIf="sesion.misdeudas">
                    {{ sesion.misdeudas[4].nombreDeuda }} <br>
                    <span class="mdc-text-3 font-weight-bold">
                        {{ sesion.misdeudas[4].saldoActual | currency: '$ ':'symbol':'1.0' }}
                    </span>
                </p>
            </div>
            <div class="col-12 col-lg-7" *ngIf="sesion.misdeudas != undefined">
                <div class="balances">
                    <div class="box-balance" *ngFor="let balanceItem of getSliceDeudas(); let i = index">
                        <div class="border" [ngClass]="
                              i == 0 ? 'balance1' : '' || 
                              i == 1 ? 'balance2' : '' ||  
                              i == 2 ? 'balance3' : '' || 
                              i == 3 ? 'balance4' : ''
                      "></div>
                        <p [ngClass]="
                              i == 0 ? 'balance1' : '' || 
                              i == 1 ? 'balance2' : '' ||  
                              i == 2 ? 'balance3' : '' || 
                              i == 3 ? 'balance4' : ''
                      ">
                            <span *ngIf="sesion.misdeudas">{{ balanceItem.nombreDeuda }}</span>
                            <span *ngIf="sesion.misdeudas"
                                class="value">{{ balanceItem.saldoActual | currency: '$ ':'symbol':'1.0' }}</span>
                        </p>
                    </div>
                </div>
                <a class="cta-balance" (click)="endeudamiento = !endeudamiento">
                    Ver Detalles de Mis Deudas y Pagos
                    <i class="fa fa-chevron-right float-right mt-arrow"></i>
                </a>
            </div>
            <div class="col-12 mb-5" *ngIf="sesion.misdeudas != undefined">
                <p class="text-md-center text-left mdc-text-secondary font-weight-bold">* Se excluyen cuentas del sector
                    de telecomunicaciones: celulares, televisión por cable, internet, entre otros.</p>
            </div>
        </div>
    </div>
</section>
<app-endeudamiento *ngIf="endeudamiento"></app-endeudamiento>