import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, UrlTree } from '@angular/router';
import { Observable, delay, of } from 'rxjs';
import { BrokerService } from '../services/broker.service';
import { LoadingService } from '../services/loading.service';
import { SesionService } from '../services/sesion.service';
import { DataLayersService } from '../services/data-layers.service';
import { UtilitiesService } from '../services/utilities.service';

@Injectable({
  providedIn: 'root'
})
export class ValidateSesionGuard implements CanActivate, CanActivateChild {
  constructor(public sesion: SesionService, public broker: BrokerService, public router: Router, public loading: LoadingService, private dl_service: DataLayersService,public utilities:UtilitiesService ) { }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(obs => {
      this.sesion.getCookie();
      
      if (this.sesion.cookieValue) {
        this.broker.validationSession(this.sesion.cookieValue).subscribe(
          {
            next: (resp: any) =>{
              this.sesion.loggedIn = true;
              this.sesion.user = resp;
              if(localStorage.getItem("pay") == "1"){
                let urlParams = localStorage.getItem("urlParams")
                let params;
                if(urlParams){params = JSON.parse(urlParams); }
                this.router.navigate(['/pago'], {
                  queryParams: params,
                  queryParamsHandling: 'merge',
                });
                obs.next(true);
              }else{
                  this.sesion.getMenu().subscribe(
                    {
                      next: (data: any) => {
                        let itemsPrincipal;
                        itemsPrincipal = this.sesion.dataMenu.find((ele:any) => ele.principal == true);
                        itemsPrincipal != undefined?this.sesion.principal = itemsPrincipal.function:this.sesion.principal = "/home";
                        if (this.sesion.dataUser == undefined && this.sesion.loggedIn) {
                          this.loading.open();
                          this.broker.datosUsuario(this.sesion.cookieValue).subscribe( 
                            {
                              next: (main: any) => {
                                this.loading.close();
                                this.sesion.setMainInfo(main).subscribe(
                                {
                                  next: (data: any) => {
                                    if (next.url.length <= 0) {
                                        this.utilities.getUtmParams(false);
                                      if (this.sesion.viewFree) {
                                          this.router.navigate(['/freemium'+this.sesion.principal]);
                                        } else if (this.sesion.estadoUserF == "ACTIVO" || this.sesion.estadoUserF == "ACTIVE_PROCESS") {
                                          this.router.navigate(['/private'+this.sesion.principal]);
                                      } else {
                                        obs.next(true);
                                      }
                                    }
                                    obs.next(true);
                                  },
                                  error: (error) => {
                                    obs.next(false);
                                  }
                                });
                              },
                              error: (error) => {
                                this.loading.close();
                                this.router.navigate(['/']);
                                obs.next(false);
                              }
                            });
                        } else if (this.sesion.dataUser != undefined && this.sesion.loggedIn) {
                          if (next.url.length <= 0) {
                              this.utilities.getUtmParams(false);
                            if (this.sesion.viewFree) {
                                this.router.navigate(['/freemium'+this.sesion.principal]);
                              } else if (this.sesion.estadoUserF == "ACTIVO" || this.sesion.estadoUserF == "ACTIVE_PROCESS") {
                                this.router.navigate(['/private'+this.sesion.principal]);
                            } else {
                              obs.next(true);
                            }
                          } 
                          obs.next(true);
                        } else {
                          if (next.url.length <= 0) {
                            obs.next(true);
                          } else {
                            obs.next(false);
                          }
                        }
                      },
                      error: (error: any) =>{
                        this.sesion.cleanData();
                        this.router.navigate(['/']);
                        obs.next(true);
                      }
                    });
              }
            },
            error: (error: any) =>{
              this.sesion.cleanData();
              this.router.navigate(['/']);
              obs.next(true);
            }
          });
      } else {
        // GA4 - userId
        this.dl_service.dl_userId( null, 'anonimo' );
        if(this.sesion.isEmpty(this.sesion.planes)){
          of(this.sesion.planes).pipe(delay(1000)).subscribe( planes => {
            // GA4 - view_item_list
            this.dl_service.dl_view_item_list( this.sesion.planes.planes.per, this.sesion.planes.planes.ant, this.sesion.planes.planes.pre, 'PLANES HOME', 'Mensual' );
          });
        }
        if (next.url.length <= 0) {
          obs.next(true);
        } else {
          this.router.navigate(['/']);
          obs.next(true);
        }
      }
    });
  }
  
}

