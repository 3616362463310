<div class="banners" *ngIf="banners.length > 0">
    <div id="bannersHome" class="carousel slide box-banner" data-ride="carousel" data-interval="false">
        <ul class="carousel-indicators circles">
          <li *ngFor="let banner of banners; let i=index" data-target="#bannersHome" class="btnCircle" [ngClass]="{'active': i==0}" [attr.data-slide-to]="i"><i class="fa fa-circle"></i></li>
        </ul>
        <div class="carousel-inner">
            <div class="carousel-item" *ngFor="let banner of banners; let i=index" [ngClass]="{'active': i==0}" >
                <div class="maxBanner" [ngStyle]="{'background': banner.background}">
                    <div class="box-car" [ngStyle]="{'background-image': 'url(' + banner.img + ')'}"> 
                        <div class="box-txt">
                            <img *ngIf="banner.imgLogo != undefined" class="logoService" src="{{banner.imgLogo}}" alt="First slide">
                            <h1 class="title" *ngIf="i == 0">{{banner.title}}</h1>
                            <h2 class="title" *ngIf="i != 0">{{banner.title}}</h2>
                            <p class="txt">{{banner.subTitle}}</p>
                            <a *ngIf="banner.cta == linkBc" id="goHome{{banner.priority}}" class="btn" [ngStyle]="{'background': banner.btnBack,'color': banner.btnColor}" 
                            href="{{banner.cta}}?utm_source=Midatacredito&utm_medium=Home&utm_campaign=Banner" target="_blank" (click)="clic_slider_home(banner.txtBtn)">{{banner.txtBtn}} <i class="fa fa-angle-right"></i></a>
                            <a *ngIf="banner.cta != linkBc" id="goHome{{banner.priority}}" class="btn" [ngStyle]="{'background': banner.btnBack,'color': banner.btnColor}" 
                            href="{{banner.cta}}" target="_blank" (click)="clic_slider_home(banner.txtBtn)">{{banner.txtBtn}} <i class="fa fa-angle-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a class="carousel-control-prev spaceBtn d-none d-md-flex" href="#bannersHome" role="button" data-slide="prev">
          <img src="assets/img/banners/Icono_flecha_izquierda.svg" alt="prev">
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next spaceBtn d-none d-md-flex" href="#bannersHome" role="button" data-slide="next">
          <img src="assets/img/banners/Icono_flecha_derecha.svg" alt="next">
          <span class="sr-only">Next</span>
        </a>
    </div>
</div>
