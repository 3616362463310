import { Component, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Plan } from 'src/app/core/models/plan.model';
import { SesionService } from 'src/app/core/services/sesion.service';
import { SuscripcionService } from 'src/app/core/services/suscripcion.service';
import { environment } from '../../../environments/environment';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
declare var $: any;

@Component({
  selector: 'app-tarjeta-plan',
  templateUrl: './tarjeta-plan.component.html',
  styleUrls: ['./tarjeta-plan.component.scss']
})
export class TarjetaPlanComponent implements OnInit {

  public planGene = {} as Plan;
  @Input() planeSel:any = { plan:'free',nombre: "Plan a", subNombre: "",  id: 'planGenerico', valorMes: "17.500", mensaje: "Ahorra 25% con pago anual", valorTotal: "17.500", planGene: this.planGene, tipo: "0", popular: false, caracteristicas: {} }
  @Input() funnPlan: string = 'TP';
  @Input() caja = 'boxPlan';
  @Input() free = false;
  @Input() popular = false;
  screenHeight = 0;
  screenWidth = 0;
  pagoTotalModal;
  pagoMesModal;
  tiempo = 'mensual';
  constructor(
    public suscripcion: SuscripcionService,
    public sesion: SesionService,
    private dataLayerService: DataLayersService,
    public router: Router) {

    this.onResize();
    this.pagoMesModal = this.planeSel.valorMes;
    this.pagoTotalModal = this.planeSel.valorTotal;
  }

  ngOnInit() {
    if (this.funnPlan == 'HC'){
      this.dataLayerService.dl_view_item_list(this.planeSel.planGene, null, null, 'HISTORICO', 'Mensual');
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  seleccionarPlan(tipo:any) {
    switch (tipo) {
      case 'mini':
        this.dataLayerService.dl_add_to_cart(this.planeSel.planGene, this.planeSel.valorTotal, this.planeSel.tipo, 'COMPARACIÓN PLANES');
        if(this.screenWidth <= 768){
          $("#modalPlan"+this.planeSel.plan).modal("show");
          $(".modal-backdrop").css({'position' : 'unset'});
          this.changePago('mensual');
        }else{
          this.seleccionarPlan('plan');
        }
        break;
      case 'plan':
        this.suscripcion.planSeleccionado = this.planeSel.planGene;
        this.suscripcion.tipoPlan = this.planeSel.tipo;
        this.suscripcion.valorSeleccionadoMensual = this.planeSel.valorMes;
        this.suscripcion.valorSeleccionadoTotal = this.planeSel.valorTotal;
        if (this.funnPlan == 'HC'){
          this.dataLayerService.dl_add_to_cart(this.planeSel.planGene, this.planeSel.valorTotal, '1', 'HISTORICO');
        }

        if (this.sesion.user) {
          this.suscripcion.planSesion(true,this.funnPlan);
        } else {
          this.suscripcion.planSesion(false,this.funnPlan);
        }
        break;
        case 'planModal':
          this.suscripcion.planSeleccionado = this.planeSel.planGene;
          this.suscripcion.tipoPlan = this.planeSel.tipo;
          this.suscripcion.valorSeleccionadoMensual = this.pagoMesModal;
          this.suscripcion.valorSeleccionadoTotal = this.pagoTotalModal;
  
          if (this.sesion.user) {
            this.suscripcion.planSesion(true,this.funnPlan);
          } else {
            this.suscripcion.planSesion(false,this.funnPlan);
          }
          break;
      default:
        break;
    }
  }

  irRegistro() {
    window.location.href = environment.enlaceLogin;
  }

  irLogin() {
    // all_plans_free_access - DL Acceso gratuito desde comparación planes
    this.dataLayerService.dl_eventoGA4_1parametro('all_plans_free_access');
    window.location.href = environment.enlaceLogin;
  }

  changePago(pago:any){
    switch (pago) {
      case 'mensual':
        this.planeSel.tipo = '1';
        break;
    case 'trimestral':
      this.planeSel.tipo = '2';
    break;
    case 'anual':
      if(this.planeSel.plan == 'per'){
        this.planeSel.tipo = '5';
      }else{
        this.planeSel.tipo = '4';
      }
      break;
      default:
        break;
    }
    this.pagoMesModal = this.planeSel.planGene[pago][1];
    this.pagoTotalModal = this.planeSel.planGene[pago][0];
    this.tiempo = pago
  }

  returnContent(tipo:any) {
    switch (tipo) {
      case 'free':
        return "Acceder"
      case 'plan':
        if (this.screenWidth <= 768) {
          return "Seleccionar"
        } else {
          return "Adquirir plan"
        }
      default:
        return "";
    }
  }

  bullets(bullet:any){
    if(bullet.toString() != "[object Object]") {
      return true
    }
    return false
  }

  close(modal:any){
    $("#"+modal).modal("hide");
    $(".modal-backdrop").css({'position' : 'fixed'});
  }

}
