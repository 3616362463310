<div class="loadingSim" *ngIf="!sesion.simulator">
    <app-micro-loader [error]="errorTxt">
    </app-micro-loader>
</div>
<div class="boxSimulador" *ngIf="sesion.simulator">
    <div class="boxTtl">
        <img src="assets/img/simulador/Icono_simulador.png" alt="score">
        <div class="contTtl">
            <h2>SIMULADOR DE PUNTAJE</h2>
            <p class="d-none d-md-block">El simulador está construido con base en tu perfil de crédito y en el comportamiento de la población en Colombia que tiene un perfil similar al tuyo. <br><br> Tener un <b>puntaje saludable</b> te brinda más oportunidades de <u>acceder</u> a productos financieros como créditos y tarjetas de crédito.</p>
        </div>
        <p class="d-block d-md-none">El simulador está construido con base en tu perfil de crédito y en el comportamiento de la población en Colombia que tiene un perfil similar al tuyo. <br><br> Tener un <b>puntaje saludable</b> te brinda más oportunidades de <u>acceder</u> a productos financieros como créditos y tarjetas de crédito.</p>
    </div>
    <div class="boxScore">
        <div class="contP">
            <h2 class="d-none d-md-block">¿Cómo impactar tu puntaje?</h2>
            <p class="sub d-none d-md-block">Con el simulador de puntaje, podrás conocer qué <u>acciones puedes tomar</u> para mejorar tu puntaje y ver el impacto a 1 mes y 3 meses.</p>
            <div class="scores">
                <app-score [diagnostico]="false" [simulador]="true"></app-score>
                <div class="sim">
                    <div class="simS one">
                        <div class="boxRange">
                            <img src="assets/img/simulador/Icono_score simulado_.png" class="range" alt="score one month">
                            <img src="assets/img/simulador/needle.png" class="needle rot_1" alt="score" id="one">
                        </div>
                        <!-- <img src="assets/img/simulador/needle.png" class="needle" alt="score"> -->
                        <p>PUNTAJE EN 1MES</p>
                    </div>
                    <div class="simS three">
                        <div class="boxRange">
                            <img src="assets/img/simulador/Icono_score simulado_.png" alt="score one month">
                            <img src="assets/img/simulador/needle.png" class="needle rot_3" alt="score">
                        </div>
                        <p>PUNTAJE EN 3MESES</p>
                    </div>
                    <div class="warnning d-none d-md-flex"><img src="assets/img/huellas-consulta/Alerta_huellas.svg" alt="alert"><p>Estas dos simulaciones son pronósticos de mejora en tu puntaje basado en los pagos que realices. Ten en cuenta que el puntaje puede variar dependiendo si estás adquiriendo moras u obligaciones en ese mismo momento.</p></div>
                </div>
            </div>
            <div class="steps">
                <p class="step1"><span></span>0 - 345</p>
                <p class="step2"><span></span>346 - 470</p>
                <p class="step3"><span></span>471 - 670</p>
                <p class="step4"><span></span>671 - 815</p>
                <p class="step5"><span></span>816 - 1000</p>
            </div>
        </div>
        <!-- boxes desktop -->
        <div class="boxOptions" [ngClass]="getScenary()">
            <app-micro-loader [error]="errorTxt" *ngIf="loading">
            </app-micro-loader>
            <div class="contOpt" *ngIf="!loading && !errorTxt">
                <div class="option" id="low" *ngIf="scenary.low.txt" (click)="this.moveBox('low')">
                    <div [ngStyle]="!obs[0] ? {'display': 'inline-block'} : {'display': 'none'}" class="contOption">
                        <p class="title op1">OPCIÓN 1 <br> <span>IMPACTO BAJO</span></p>
                        <p class="txtOp">Para poder conseguir un impacto bajo en tu puntaje de crédito puedes:</p>
                        <div class="pago">
                            <p class="pagoTxt" [innerHtml] ="scenary.low.txt"></p>
                            <div class="tooltip1">
                                <i class="fa fa-exclamation-circle" aria-hidden="true" id="clicOut" ></i>
                                <span class="tiptext" [innerHtml] ="scenary.low.popup"></span>									
                            </div>
                        </div>
                        <div class="btnsOption">
                            <button [ngClass]="{'btnDisabled':simulacion[0]}" [disabled]="simulacion[0]" (click)="simular(sesion.simulator.low,0)">SIMULAR</button>
                            <!-- <p class="ob" (click)="openOb(0,true)"><i class="fa fa-eye"></i>VER MIS OBLIGACIONES</p> -->
                        </div>
                    </div>
                </div>
                
                <div class="option"  id="medium" *ngIf="scenary.medium.txt" (click)="this.moveBox('medium')">
                    <div [ngStyle]="!obs[1] ? {'display': 'inline-block'} : {'display': 'none'}" class="contOption">
                        <p class="title op2">OPCIÓN 2 <br> <span>IMPACTO MEDIO</span></p>
                        <p class="txtOp">Para poder conseguir un impacto medio en tu puntaje de crédito puedes:</p>
                        <div class="pago">
                            <p class="pagoTxt" [innerHtml] ="scenary.medium.txt"></p>
                            <div class="tooltip1">
                                <i class="fa fa-exclamation-circle" aria-hidden="true" id="clicOut" ></i>
                                <span class="tiptext" [innerHtml] ="scenary.medium.popup"></span>									
                            </div>
                        </div>
                        <div class="btnsOption">
                            <button [ngClass]="{'btnDisabled':simulacion[1]}" [disabled]="simulacion[1]" (click)="simular(sesion.simulator.medium,1)">SIMULAR</button>
                            <!-- <p class="ob" (click)="openOb(1,true)"><i class="fa fa-eye"></i>VER MIS OBLIGACIONES</p> -->
                        </div>
                    </div>
                </div>
                <div class="option"  id="high" *ngIf="scenary.high.txt" (click)="this.moveBox('high')">
                    <div [ngStyle]="!obs[2] ? {'display': 'inline-block'} : {'display': 'none'}" class="contOption">
                        <p class="title op3">MEJOR OPCIÓN <br> <span>MAYOR IMPACTO</span></p>
                        <p class="txtOp">Para poder conseguir el mayor impacto en tu puntaje de crédito puedes:</p>
                        <div class="pago"><p class="pagoTxt" [innerHtml] ="scenary.high.txt"></p>
                            <div class="tooltip1">
                                <i class="fa fa-exclamation-circle" aria-hidden="true" id="clicOut" ></i>
                                <span class="tiptext"  [innerHtml] ="scenary.high.popup"></span>									
                            </div>
                        </div>
                        <div class="btnsOption">
                            <button [ngClass]="{'btnDisabled':simulacion[2]}" [disabled]="simulacion[2]" (click)="simular(sesion.simulator.high,2)">SIMULAR</button>
                            <!-- <p class="ob" (click)="openOb(2,true)"><i class="fa fa-eye"></i>VER MIS OBLIGACIONES</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ol class="carousel-indicators carouselIn" *ngIf="scenary">
            <li id="ilow" (click)="this.moveBox('low')" class="active" *ngIf="scenary.low.txt"></li>
            <li id="imedium" (click)="this.moveBox('medium')" *ngIf="scenary.medium.txt"></li>
            <li id="ihigh" (click)="this.moveBox('high')" *ngIf="scenary.high.txt"></li>
        </ol>
        <button class="reload" *ngIf="simulacion.includes(true)" (click)="reload()">REINICIAR SIMULACIÓN</button>
        <div class="warnning"><img src="assets/img/huellas-consulta/Alerta_huellas.svg" alt="alert">
            <p>La información que impacta el puntaje depende del reporte de las entidades (fuentes de información) a las centrales de riesgo de igual forma estará atada al pago de la obligación. <br><br>Si tienes alguna duda adicional puedes consultar nuestra <b><a routerLink="/faq" target="_self">sección de preguntas frecuentes.</a></b></p>
        </div>
    </div>
</div>

<!-- modal Cómo funciona? -->
<div class="modal bd-example-modal-lg fade" tabindex="-1" role="dialog" aria-labelledby="modalSim" aria-hidden="true" id="modalSim" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered mDialog" role="document">
        <div class="modal-content mContent">
            <div class="modal-body boxModal">
                <button type="button" data-dismiss="modal" aria-label="Close" class="closeM" (click)="childScore.addAnimationScore()">&times;</button>
                <div class="contModal">
                    <div class="cabecera">
                    </div>
                    <div class="carrusel" id="steps">
                        <div class="boxSide" id="steps">
                            <h3>¿Cómo funciona el <b>simulador de puntaje?</b></h3>
                            <div class="item">
                                <p class="num">1.</p>
                                <p class="txt"><b>Simula</b> entre las tres opciones que tienes disponibles.</p>
                            </div>
                            <div class="item">
                                <p class="num">2.</p>
                                <p class="txt"><b>Elige</b> el impacto que quieres tener en tu puntaje de crédito.</p>
                            </div>
                            <div class="item">
                                <p class="num">3.</p>
                                <p class="txt"><b>Acércate</b> a estas entidades para poder entender cómo mejorar tu vida financiera.</p>
                            </div>
                            <button (click)="hideSteps()" data-slide-to="1">Comenzar</button>
                        </div>
                    </div>
                    <div id="carouselExampleIndicators" class="carrusel carousel slide">
                        <ul class="carousel-indicators circles">
                            <li data-target="#carouselExampleIndicators" class="btnCircle active" data-slide-to="0"><i class="fa fa-circle"></i></li>
                            <li data-target="#carouselExampleIndicators" class="btnCircle" data-slide-to="1"><i class="fa fa-circle"></i></li>
                        </ul>
                        <div class="carousel-inner">
                            <div class="carousel-item active">
                                <div class="boxSide">
                                    <h3>Algunas cosas a <b>tener en cuenta:</b></h3>
                                    <div class="item">
                                        <p class="txt">Recuerda que el <u>puntaje de crédito</u> es producto de un <b>análisis estadístico</b> de hechos objetivos relacionados con la <b>información completa</b> y <b>veraz</b> de tu comportamiento crediticio, y te ubica <u>dentro de un segmento de la población</u> con el cual compartes una serie de rasgos significativos.</p>
                                    </div>
                                    <button data-target="#carouselExampleIndicators" data-slide-to="1">Siguiente</button>
                                </div>
                            </div>
                            <div class="carousel-item">
                                <div class="boxSide">
                                    <h3>Algunas cosas a <b>tener en cuenta:</b></h3>
                                    <div class="item">
                                        <p class="txt"><b>Mejorar</b> tu puntaje de crédito es un proceso que <b>puede tomar algún tiempo</b> mientras ajustas tu perfil crediticio, pero hay variables de fácil y rápido manejo, como pagar a tiempo y disminuir los saldos de tus créditos y obligaciones financieras.</p>
                                    </div>
                                    <button data-dismiss="modal" aria-label="Close" (click)="childScore.addAnimationScore()">Simular mi puntaje</button>
                                    <p class="linkFaq">Si tienes alguna duda adicional puedes consultar nuestra <b><a routerLink="/faq" target="_blank">sección de preguntas frecuentes.</a></b></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>