<div class="modal fade p-0" tabindex="-1" role="dialog" id="modal">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content" [ngStyle]="{ 'background-image': 'url( ' + content.imgLateral + ')' }">
      <div class="modal-body py-5">
        <button type="button" class="close mdc-close" data-dismiss="modal" aria-label="Close"
          *ngIf="content.closeB && content.closeB != 'home'">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 ml-auto my-5 text-center">
              <img *ngIf="content.imgMobile" src="{{ content.imgMobile }}"
                class="d-block d-lg-none mdc-icon-asesor m-auto" alt="asesor">
              <p class="titulo mt-3 mt-lg-0"
                [ngClass]="{'mdc-text-secondary': content.tipo === 'ok', 'mdc-text-pink': content.tipo === 'bad'}">
                {{ content.titulo }}
              </p>
              <p class="mdc-text-gray mdc-text-4 my-5" [innerHTML]="content.textoHTML">
              </p>
              <a *ngIf="content.enlace" class="btn w-75 text-white" (click)="content.close()"
                [routerLink]="content.enlaceBoton"
                [ngClass]="{'mdc-btn-primary': content.tipo === 'ok', 'mdc-btn-pink': content.tipo === 'bad'}">
                {{ content.textBoton }}
                <i class="fa fa-chevron-right mt-1"></i>
              </a>
              <button *ngIf="!content.enlace && !content.enlaceExterno" (click)="content.close()" class="btn w-75"
                type="button"
                [ngClass]="{'mdc-btn-primary': content.tipo === 'ok', 'mdc-btn-pink': content.tipo === 'bad'}">
                {{ content.textBoton }}
                <i class="fa fa-chevron-right mt-1"></i>
              </button>
              <a *ngIf="content.enlaceExterno" class="btn w-75 text-white" (click)="content.close()"
                [href]="content.enlaceExterno"
                [ngClass]="{'mdc-btn-primary': content.tipo === 'ok', 'mdc-btn-pink': content.tipo === 'bad'}">
                {{ content.textBoton }}
                <i class="fa fa-chevron-right mt-1"></i>
              </a>
              <a *ngIf="content.closeB === 'home'" (click)="content.close()" routerLink="/private/historico"
                class="text-center d-block m-auto pt-2 link mdc-text-secondary" style="cursor: pointer;">
                - Ir a inicio -
              </a>
            </div>
            <div class="col-lg-6 ml-auto">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade p-0" tabindex="-1" role="dialog" id="modalEscaneo">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-body my-5">
        <button type="button" class="close mdc-close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-6 ml-auto text-center">
              <p class="titulo mt-3 mt-lg-0 mdc-text-2 text-left mdc-text-secondary-dark">
                {{ content.titulo }}
              </p>
              <p class="mdc-text-gray mdc-text-4 my-5" [innerHTML]="content.textoHTML">
              </p>
            </div>
            <div class="col-lg-6 ml-auto d-none d-lg-block">
              <img src="{{ content.imgLateral }}" class="d-block w-100 m-auto" alt="lateral">
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <form class="row" (ngSubmit)="content.escaneoAnonimo(datos)" #datos="ngForm" autocomplete="off">
                <div class="col-12 col-md-8">
                  <label for="correoElectronico" class="d-none"></label>
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <i class=" fa fa-envelope-o"></i>
                      </div>
                    </div>
                    <input type="text" class="form-control font-weight-lighter" name="mail" id="correoElectronico"
                      placeholder="Ingresa tu correo electrónico" required="required" maxlength="60"
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,9}$" [(ngModel)]="content.email"
                      #emailAnonimo="ngModel"
                      [ngClass]="{'is-valid': emailAnonimo.valid, 'is-invalid': ( ( emailAnonimo.errors?.['required'] || emailAnonimo.errors?.['pattern'] ) && emailAnonimo.touched ) }">
                    <div *ngIf="emailAnonimo?.errors?.['required']" class="invalid-feedback">
                      Campo obligatorio.
                    </div>
                    <div *ngIf="emailAnonimo?.errors?.['pattern']" class="invalid-feedback">
                      Correo electrónico inválido.
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-4 mt-3 mt-md-0">
                  <button id="botonEscaneo" type="submit"
                    class="btn mdc-btn-alter btn-block mdc-text-b m-auto rounded-0 mdc-text-secondary py-2">
                    Escanear gratis <i class="fa fa-chevron-right float-right mt-arrow"></i>
                  </button>
                </div>
                <div class="col-12">
                  <p class="pb-0 pt-3 mdc-text-6 politicas text-center">
                    La información que suministras por este medio podrá ser utilizada por DataCrédito en desarrollo de
                    su actividad. <a href="{{url_politica_privacidad}}" class="mdc-text-secondary">Ver Política de
                      Privacidad </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade p-0" tabindex="-1" role="dialog" id="respuestaEscaneo">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content"
      [ngClass]="{ 'bad': content.anonimo?.stlTransactionData.responseMetadata.recordCount !== 0, 'ok': content.anonimo?.stlTransactionData.responseMetadata.recordCount === 0}">
      <div class="modal-body my-5">
        <button type="button" class="close mdc-close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="container-fluid text-center">
          <div class="row m-0">
            <div class="col-lg-8 ml-auto text-left">
              <div class="justify-content-center align-self-center"
                *ngIf="content.anonimo?.stlTransactionData.responseMetadata.recordCount === 0">
                <div class="container-fluid">
                  <p class="mdc-text-white mdc-text-2-5-2 mdc-text-l mdc-line-height mb-4 text-center text-md-left">
                    ¡Puedes Estar Tranquilo! <img src="assets/img/images/Scan_NoAlerts.svg" width="100" alt="No alerts"
                      class="float-right ml-3 d-block d-md-none"></p>
                  <p class="mdc-text-white mdc-text-4 text-center text-md-left">El correo {{ content.email }} no genera
                    resultados de información comprometida en la Dark Web.</p>
                </div>
                <div class="container-fluid">
                  <div class="row bg-white p-1 pb-3 d-flex w-100 m-0">
                    <div class="col-12 col-md-2 justify-content-center align-self-center">
                      <img class="icon-alert m-auto" src="assets/img/protege/Alerta-1.svg" alt="Precaución">
                    </div>
                    <div class="col-12 col-md-10 justify-content-center align-self-center">
                      <p class="m-0 mdc-text-b">Cada 50 minutos se genera una compra por suplantación en Colombia.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="content.anonimo?.stlTransactionData.responseMetadata.recordCount !== 0"
                class="justify-content-center align-self-center">
                <div class="container-fluid">
                  <p class="mdc-text-white mdc-text-2-5-2 mdc-text-l mdc-line-height mb-5 text-center text-md-left">¡Tu
                    contraseña está expuesta!</p>
                </div>
                <div class="container-fluid">
                  <div class="row bg-black p-1 pb-3 d-flex w-100 m-0">
                    <div class="col-12 col-md-2 justify-content-center align-self-center">
                      <img class="icon-alert m-auto" src="assets/img/protege/Alerta-2.svg" alt="Alerta">
                    </div>
                    <div class="col-12 col-md-10 justify-content-center align-self-center">
                      <p class="m-0 mdc-text-b text-white">Contraseña comprometida en:</p>
                      <p style="color: #FFAB31 !important;" class="mdc-text-b mdc-text-3 m-0"
                        *ngIf="content.anonimo?.stlTransactionData.responseMetadata.recordCount > 1">
                        {{ content.anonimo?.stlTransactionData.responseMetadata.recordCount }} sitios de la darkweb</p>
                      <p style="color: #FFAB31 !important;" class="mdc-text-b mdc-text-3 m-0"
                        *ngIf="content.anonimo?.stlTransactionData.responseMetadata.recordCount === 1">
                        {{ content.anonimo?.stlTransactionData.responseMetadata.recordCount }} sitio de la darkweb</p>
                    </div>
                  </div>
                  <p class="m-0 text-white text-center mt-3">Tu información personal y financiera podría estar en manos
                    de piratas informáticos y ciberdelincuentes.</p>
                </div>
              </div>
              <div class="col-12 px-4 detalle mt-2 d-none d-md-block">
                <p class="mdc-text-white mdc-text-4 mdc-text-sb1 mb-3">
                  Mantén protegida y monitoreada tu información personal con Midatacrédito y evita:
                </p>
                <ul class="mdc-text-white mb-4 mdc-text-4">
                  <li class="mt-3">Compras online en tu nombre.</li>
                  <li class="mt-3">Que tu información privada sea revelada.</li>
                  <li class="mt-3">El uso de tu cuenta para actividades delictivas que generen perjuicios a tu nombre.
                  </li>
                  <li class="mt-3">Los envíos masivos de spam o virus a tus contactos.</li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 ml-auto d-flex">
              <img src="assets/img/images/Scan_NoAlerts.svg" alt="No alerts"
                *ngIf="content.anonimo?.stlTransactionData.responseMetadata.recordCount === 0"
                class="justify-content-center align-self-center w-100 m-auto d-none d-lg-block">
              <img src="assets/img/images/Scan_Alert.svg" alt="Recibe alertas financieras"
                *ngIf="content.anonimo?.stlTransactionData.responseMetadata.recordCount !== 0"
                class="justify-content-center align-self-center w-100 m-auto d-none d-lg-block">
            </div>
          </div>
          <a class="btn mdc-text-secondary mt-5 mdc-btn-alter m-auto px-5 mt-3" (click)="content.closeRespuesta()"
            routerLink="/private/planesint">
            Adquirir plan
            <i class="fa fa-chevron-right mt-1"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Covid *COVID -->
<div class="modal fade hide" id="covid" tabindex="-1" role="dialog" aria-labelledby="covidlLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 order-1 order-md-0 px-0">
              <h2 class="mb-4 covid">Alerta informativa pandemia <br /> COVID-19</h2>
              <p class="light covid">
                Por medio de esta Alerta Informativa, como Titular, según la Ley 1266 de 2008, podrá comunicarle a la
                entidad que lo reporta de forma positiva o negativa en DataCrédito y a los Usuarios, en los términos de
                la Ley 1266 de 2008, su manifestación de encontrarse en dificultades económicas debido a la crisis
                generada por la pandemia COVID 19.<br /><br />
                Así, al aceptar los Términos y Condiciones, <b>autoriza</b> a Experian para informar a esas entidades
                sobre esa manifestación.<br /><br />
                Al aceptar, conoce, comprende y acepta los <a href="{{url_terminos_condiciones}}?#page=9"
                  target="_blank">Términos
                  y Condiciones</a>.
              </p>
              <button class="btn btn-secondary my-3 w-100 w-md-75 txt-18 mt-4" data-dismiss="modal" aria-label="Close">
                <div class="row">
                  <div class="col-12 bold" (click)="reportarMora()">Acepto</div>
                </div>
              </button>
              <button class="btn noAcepto my-3 w-100 w-md-75 txt-18 mt-2" data-dismiss="modal" aria-label="Close">
                <div class="row">
                  <div class="col-12 bold">- No Acepto -</div>
                </div>
              </button>
            </div>
            <div class="col-12 col-md-6 order-0 order-md-1 mb-4 middle" style="text-align: center;">
              <img src="assets/img/freepremium/Img_Desktop.svg" class="img-cosult" alt="Conoce tu historia y puntaje de cŕedito">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- / Modal Covid *COVID-->

<!-- Modal T&C -->
<div class="modal fade hide" id="terminosModal" tabindex="-1" role="dialog" aria-labelledby="terminosModalLabel"
  aria-hidden="true">
  <div class="modal-dialog tym modal-dialog-centered" role="document">
    <div class="modal-content tym">
      <div class="modal-header tym">
        <button type="button" id="cerrarModal" class="close" data-dismiss="modal" aria-label="Close"
          (click)="noAceptar()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body tym">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 order-0 order-md-0">
              <img src="assets/img/images/terminos.svg" class="img-cosult"
                alt="Autorizo el tratamiento de mis datos personales"
                title="Autorizo el tratamiento de mis datos personales">
            </div>
            <div class="col-12 col-md-6 order-1 order-md-1 ">
              <div class="contentAccept">
                <div class="title">Autorizo el tratamiento de mis datos personales</div>
                <div class="description">Leer <a href="{{url_politica_privacidad}}?#page=5" target="_blank">tratamiento de
                    datos personales</a></div>
                <form (ngSubmit)="aceptar(datos)" #datos="ngForm">
                  <div class="checkPublicity">
                    <div class="checkP">
                      <input type="checkbox" id="publicity" name="publicity" value="1" pattern="true"
                        [(ngModel)]="data.publicity" #publicity="ngModel">
                    </div>
                    <div class="desCheck">Quiero recibir noticias y campañas publicitarias en mi correo electrónico y
                      celular proporcionados en el registro. <a href="{{url_politica_privacidad}}?#page=3" target="_blank">Ver
                        autorización envío email marketing</a></div>
                    <div class="clearFloat"></div>
                  </div>
                  <button type="submit">
                    Acepto <span class="arrow-right"><img src="assets/img/images/arrow-right-white.svg" alt="flecha derecha" class="txt-experian-purple"></span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal T&C -->

<!-- Modal Plan Premium -->
<div class="modal fade hide" id="planModal" tabindex="-1" role="dialog" aria-labelledby="planModalLabel"
  aria-hidden="true" *ngIf="sesion.planes.planes">
  <div class="modal-dialog plansModal modal-dialog-centered" role="document">
    <div class="modal-content premium">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 order-0 order-md-0 mb-3 align-self-center">
              <img src="assets/img/modals/plans-freemium/Compra_premium.svg" class="img-plan" alt="Plan Freemium" />
            </div>
            <div class="col-12 col-md-6 order-1 order-md-1">
              <p class="title-plan">{{ sesion.planes.planes.pre.popup }}</p>
              <p class="price">${{ sesion.planes.planes.pre.mensual[0] }}<span>/ mes</span></p>
              <p class="add">Adicional a los beneficios del <span>plan {{ sesion.planes.planes.ant.popup }}</span>
                recibe:</p>
              <div class="bullets">
                <div class="completeBullet" *ngFor="let item of sesion.planes.planes?.pre?.itemsV2">
                  <i class="fa fa-check" aria-hidden="true"></i>
                  <p class="regular">{{item.txt}}</p>
                </div>
                <p class="note-premium">*Tanto la póliza como la asesoría legal del Plan Premium, son servicios
                  prestados por SBS Seguros Colombia S.A.</p>
              </div>
              <a (click)="seleccionarPlan(sesion.planes.planes.pre, '1', sesion.planes.planes.pre.mensual[1], sesion.planes.planes.pre.mensual[0])"
                class="btn-midc btn-orange h-lg-btn w-100-btn" id="planPremiumModal" webActivity data-idtag="Freemium"
                data-nametag="planPremiumModal" data-dismiss="modal" aria-label="Close">
                <span>Adquirir plan</span>
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Plan Premium -->

<!-- Modal Plan Protege -->
<div class="modal fade hide" id="planProtegeModal" tabindex="-1" role="dialog" aria-labelledby="planProtegeModalLabel"
  aria-hidden="true" *ngIf="sesion.planes.planes">
  <div class="modal-dialog plansModal modal-dialog-centered" role="document">
    <div class="modal-content protege">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 order-0 order-md-0 mb-3 align-self-center">
              <img src="assets/img/modals/plans-freemium/Compra_premium.svg" class="img-plan" alt="Plan Freemium" />
            </div>
            <div class="col-12 col-md-6 order-1 order-md-1">
              <p class="title-plan">{{ sesion.planes.planes.ant.popup }}</p>
              <p class="price">${{ sesion.planes.planes.ant.mensual[0] }}<span>/ mes</span></p>
              <p class="add">Adicional a los beneficios del <span>plan {{ sesion.planes.planes.per.popup }}</span>
                recibe:</p>
              <div class="bullets">
                <div class="completeBullet" *ngFor="let item of sesion.planes.planes?.ant?.itemsV2">
                  <i class="fa fa-check" aria-hidden="true"></i>
                  <p class="regular">{{item.txt}}</p>
                </div>
              </div>
              <a (click)="seleccionarPlan(sesion.planes.planes.ant, '1', sesion.planes.planes.ant.mensual[1], sesion.planes.planes.ant.mensual[0])"
                class="btn-midc btn-orange h-lg-btn w-100-btn" id="planProtegeModal" webActivity data-idtag="Freemium"
                data-nametag="planProtegeModal" data-dismiss="modal" aria-label="Close">
                <span>Adquirir plan</span>
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Plan Protege -->



<!-- Modal Plan Perfil de Crédito -->
<div class="modal fade hide" id="planPerfilModal" tabindex="-1" role="dialog" aria-labelledby="planPerfilModalLabel"
  aria-hidden="true" *ngIf="sesion.planes.planes">
  <div class="modal-dialog plansModal modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 order-0 order-md-0 mb-3 align-self-center">
              <img src="assets/img/modals/plans-freemium/Compra_premium.svg" class="img-plan" alt="Plan Freemium" />
            </div>
            <div class="col-12 col-md-6 order-1 order-md-1">
              <p class="title-plan">{{ sesion.planes.planes.per.popup }}</p>
              <p class="price">${{ sesion.planes.planes.per.mensual[0] }}<span>/ mes</span></p>
              <p class="add">Adicional a los beneficios del <span>PLAN FREEMIUM</span> recibe:</p>
              <div class="bullets">
                <div class="completeBullet" *ngFor="let item of sesion.planes.planes?.per?.itemsV2">
                  <i class="fa fa-check" aria-hidden="true"></i>
                  <p class="regular">{{item.txt}}</p>
                </div>
              </div>
              <a (click)="seleccionarPlan(sesion.planes.planes.per, '1', sesion.planes.planes.per.mensual[1], sesion.planes.planes.per.mensual[0])"
                class="btn-midc btn-orange h-lg-btn w-100-btn" id="planPerfilCreditoModal" webActivity
                data-idtag="Freemium" data-nametag="planPerfilCreditoModal" data-dismiss="modal" aria-label="Close">
                <span>Adquirir plan</span>
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Plan Perfil de Crédito -->

<!-- Modal Reclamaciones  -->
<div class="modal fade hide" id="claimModal" tabindex="-1" role="dialog" aria-labelledby="claimModalLabel"
  aria-hidden="true">
  <div class="modal-dialog claimsModal modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 align-self-center">
              <img src="assets/img/modals/alert-claims/Tips.svg" alt="Alerta Notificaciones" class="img-claim" />
            </div>
            <div class="col-12 col-md-6">
              <p class="title-modal">Antes de formular un reclamo le recomendamos leer la siguiente información</p>
              <div class="box">
                <div class="box-scroll" (scroll)="checkScrollReclamation()">
                  <div class="scroll-check-claim">
                    <ol>
                      <li>La actualización de la información reportada por las fuentes se ve reflejada de manera mensual
                        en tu historia de crédito. Así mismo, es viable que la actualización de los reportes de
                        información lo realice la entidad (fuente) mes vencido.</li>
                      <li>En el caso de datos negativos, que son aquellos cuyo contenido hacen referencia al tiempo de
                        mora, tipo de cobro, estado de la cartera, y en general, aquellos datos referentes a una
                        situación
                        de incumplimiento de obligaciones se regirán por un término máximo de permanencia. Si deseas ver
                        más información sobre este tema de permanencia por favor dirígete a nuestro portal en la Sección
                        Habeas Data, donde encontrará más detalles, tips y documentos de ley existentes que hoy nos
                        rigen.
                      </li>
                      <li>Los detalles que registres sobre tu reclamo son vitales para el estudio de tu solicitud, por
                        favor regístralos en el campo de observaciones.</li>
                      <li>Al realizar tu reclamo por favor ten en cuenta que la aplicación te va a exigir “tipo” y
                        “subtipo” de reclamo los cuales deben ser en lo posible los más exactos frente a la novedad que
                        estás solicitando.</li>
                      <li>Si tienes varios productos con la entidad (fuente) verifica que el reclamo esté siendo
                        registrado en la obligación o producto correcto.</li>
                      <li>Ten en cuenta que la información positiva (datos referentes al cumplimiento de obligaciones)
                        pueden permanecer de manera indefinida en la base de datos del Operador DataCredito.</li>
                      <li>La venta de cartera que realicen las diferentes entidades o fuentes de DataCrédito, puede
                        conducir a que tu obligación ahora aparezca registrada por una nueva entidad (acreedor actual de
                        la obligación), caso en el cual la entidad actual que reporta la obligación en DataCrédito puede
                        informarte sobre las condiciones de la obligación y demás datos relacionados con la misma.</li>
                    </ol>
                  </div>
                  <div>
                    <button target="_blank" class="btn-midc btn-orange h-lg-btn w-100-btn d-block d-md-none" (click)=formularReclamacion()
                      id="generarReclamacion" data-dismiss="modal" aria-label="Close">
                      <span>Generar reclamación</span>
                    </button>
                  </div>
                </div>
              </div>
              <button target="_blank" class="btn-midc btn-orange h-lg-btn w-100-btn d-none d-md-block" (click)=formularReclamacion()
                id="generarReclamacion" data-dismiss="modal" aria-label="Close" [disabled]="scrollCheck">
                <span>Generar reclamación</span>
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Reclamaciones  -->

<!-- Modal Aceptación de Publicidad. -->
<div class="modal fade hide" id="publicityModal" tabindex="-1" role="dialog" aria-labelledby="publicityModalLabel"
  aria-hidden="true">
  <div class="modal-dialog claimsModal modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 align-self-center">
              <img src="assets/img/modals/alert-claims/autorizacion.svg" alt="Alerta Notificaciones"
                class="img-claim" />
            </div>
            <div class="col-12 col-md-6">
              <p class="title-modal">Autorización Opcional Midatacrédito</p>
              <div class="box">
                <div class="box-scroll">
                  <p>El Titular autoriza expresamente a Experian Colombia S.A., a realizar el tratamiento de sus datos,
                    ejecutando actividades tales como recolectar, confrontar, almacenar, actualizar, usar, circular,
                    transmitir, transferir y suprimir sus datos personales de forma parcial o total en los términos
                    expresados en la presente Política de Tratamiento de la Información Personal, particularmente para
                    las siguientes finalidades:</p>
                  <ul class="liPublicity">
                    <li>Para actividades de mercadeo, promoción y comercialización de los servicios propios del objeto
                      social de Experian Colombia S.A. o de terceros con quienes Experian Colombia S.A. (cualquiera de
                      sus cuatro unidades) haya celebrado alianzas comerciales, contratos de suscripción o de prestación
                      de servicios.</li>
                    <li>Para que los Suscriptores y/o Aliados de Experian Colombia S.A. del sector real, financiero,
                      cooperativo y demás sectores de la economía, comercialicen y promocionen sus productos, y realicen
                      campañas que pueden ser adelantadas directamente por el suscriptor, por Experian Colombia S.A. o
                      con el apoyo de terceros encargados con quienes se compartirá mi información personal, a través de
                      correos electrónicos, mensajería sms, llamadas y correo físico, de acuerdo con su perfil
                      crediticio y de consumo.</li>
                  </ul>
                  <p class="espInf">Para mayor información sobre cómo ejercer sus derechos y procedimientos para
                    presentar consultas, reclamos, solicitudes de eliminación y revocatoria de la autorización, puede
                    acceder a la Política de Tratamiento de Información Personal de Experian Consumer Services.</p>
                </div>
              </div>
              <!-- <a target="_blank" class="btn-midc btn-orange h-lg-btn w-100-btn" id="aceptaPublicidad" data-dismiss="modal" aria-label="Close">
                  <span>Aceptar</span>
                  <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Aceptación de Publicidad. -->

<!-- Modal Creacion Reclamacion -->
<div class="modal fade hide" id="okReclamacionModal" tabindex="-1" role="dialog" aria-labelledby="okReclamacionModal"
  aria-hidden="true">
  <div class="modal-dialog okReclamacion modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 align-self-center">
              <img src="assets/img/modals/alert-claims/ok-reclamacion.svg" alt="Alerta Notificaciones"
                class="img-claim" />
            </div>
            <div class="col-12 col-md-6">
              <p class="title-reclamacion">Tu Reclamación ha sido creada con éxito.</p>
              <div class="box">
                <p>
                  Datacrédito Experian se encargará de tramitar tu reclamación ante la entidad respectiva.
                </p>
              </div>
              <a target="_blank" class="btn-midc btn-orange h-lg-btn w-100-btn" (click)="salirReclamacion()"
                id="generarReclamacion" data-dismiss="modal" aria-label="Close">
                <span>Ver mis reclamaciones</span>
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Creacion Reclamacion -->


<!-- Modal Verifica -->
<div class="modal fade hide" id="modalVerifica" tabindex="-1" role="dialog" aria-labelledby="planPerfilModalLabel"
  aria-hidden="true">
  <div class="modal-dialog plansModal modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 order-0 order-md-0 mb-3 align-self-center">
              <img src="assets/img/modals/alert-claims/Banner_verifica.svg" class="img-plan" alt="Plan Freemium" />
            </div>
            <div class="col-12 col-md-6 order-1 order-md-1">
              <p class="title-verifica">Verifica a otra persona en Mi Datacrédito</p>
              <p class="add"><b>Verifica</b> te ayudará a obtener la <b>confianza</b> y la <b>tranquilidad</b> de saber
                con quién estás negociando.</p>
              <p class="price">$19.900</p>
              <div class="bullets">
                <div class="completeBullet">
                  <i class="fa fa-check" aria-hidden="true"></i>
                  <p class="regular">Validación de identidad de la persona consultada.</p>
                </div>
                <div class="completeBullet">
                  <i class="fa fa-check" aria-hidden="true"></i>
                  <p class="regular">Nivel de confianza de la persona consultada.</p>
                </div>
                <div class="completeBullet">
                  <i class="fa fa-check" aria-hidden="true"></i>
                  <p class="regular">Validación en Listas nacionales o internacionales ( prevención del lavado de
                    activos y financiación del terrorismo)</p>
                </div>
                <div class="completeBullet">
                  <i class="fa fa-check" aria-hidden="true"></i>
                  <p class="regular">Recomendaciones que puedes tener en cuenta para hacer mejores negocios.</p>
                </div>
              </div>
              <a (click)="irVerifica()" class="btn-midc btn-orange h-lg-btn w-100-btn verifica-modal"
                id="planVerificaModal" webActivity data-idtag="Freemium" data-nametag="planVerificaModal"
                data-dismiss="modal" aria-label="Close">
                <span class="verifica-modal">Verificar a otra persona</span>
                <i class="fa fa-chevron-right verifica-modal" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Verifica -->

<!-- Modal Intermitencia -->
<div class="modal fade hide" id="modalIntermitencia" tabindex="-1" role="dialog" aria-labelledby="planPerfilModalLabel"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog plansModal modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 order-0 order-md-0 mb-3 align-self-center">
              <img src="assets/img/modals/alert-claims/icono_modal_fin.svg" class="img-plan"
                alt="Plan Freemium" />
            </div>
            <div class="col-12 col-md-6 order-1 order-md-1">
              <div class="add" [innerHTML]="sesion.messageIntermittence"></div>
              <a routerLink="/" class="btn-midc btn-orange h-lg-btn w-100-btn" data-dismiss="modal" aria-label="Close" (click)="closeIntermitencia()">
                <span>Aceptar</span>
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Intermitencia -->

<!-- Modal Error -->
<div class="modal fade hide" id="modalErrorCampaign" tabindex="-1" role="dialog"
  aria-labelledby="ErrorCampaignModalLabel" aria-hidden="true">
  <div class="modal-dialog plansModal modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 order-0 order-md-0 mb-3 align-self-center">
              <img src="assets/img/landings/sodimac/Envio_negativo.svg" class="img-plan" alt="Plan Freemium" />
            </div>
            <div class="col-12 col-md-6 order-1 order-md-1">
              <p class="title-plan">¡Mantenimiento en nuestros Canales!</p>
              <p class="add">La funcionalidad de <b>Creación y Consulta de reclamos</b> estará inactiva por
                mantenimiento:</p>
              <p class="add">Desde el <ins>20 de Febrero a las 2pm</ins> hasta las <ins>12am del 21 de Febrero</ins></p>
              <p class="add">Disfruta de todos los otros servicios que <b>Mi Datacrédito</b> tiene para ti</p>
              <a class="btn-midc btn-orange h-lg-btn w-100-btn" data-dismiss="modal" aria-label="Close">
                <span>Aceptar</span>
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Error -->

<!-- Modal Aceptación publicidad TimeOut  -->
<div class="modal fade hide" id="accepAdvrModal" tabindex="-1" role="dialog" aria-labelledby="accepAdvrModalLabel"
  aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog claimsModal modal-dialog-centered" role="document">
    <div class="modal-content advert">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body advert">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 align-self-center">
              <img src="assets/img/freepremium/publicidad/icono_envio_comunicacion.svg" alt="Alerta Notificaciones"
                class="img-claim advert" />
            </div>
            <div class="col-12 col-md-6">
              <p class="title-modal advert">¡Mantente informado de todo lo que MiDatacrédito tiene para ti!</p>
              <p>Recibe noticias y promociones personalizadas vía correo y celular.</p>
              <a target="_blank" class="btn-midc btn-orange h-lg-btn w-100-btn" id="aceptacionPublicidadModal"
                webActivity data-idtag="envioCorreosMDC" data-nametag="aceptacionPublicidadModal" data-dismiss="modal"
                aria-label="Close">
                <span>Aceptar</span>
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Aceptación publicidad TimeOut  -->

<!-- Modal Alerta Intento Suplantacion  -->
<div class="modal fade hide" id="alert-impersonation" tabindex="-1" role="dialog"
  aria-labelledby="alert-impersonation-Label" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog claimsModal modal-dialog-centered" role="document">
    <div class="modal-content advert">
      <!-- <div class="modal-header"> -->
      <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
      <!-- </div> -->
      <div class="modal-body advert">
        <div class="container">
          <div class="row">
            <!-- <div class="col-12 col-md-6 align-self-center">
              <img src="assets/img/freepremium/publicidad/icono_envio_comunicacion.svg" alt="Alerta Notificaciones" class="img-claim advert"/>
            </div> -->
            <div class="col-12">
              <p>Como Titular de la información en caso de considerar ser víctima del delito de falsedad personal y/o
                suplantación de identidad, y le sea exigido el pago de obligaciones como resultado de ese delito, podrá
                registrar en su historia de crédito una leyenda que diga “victima de Falsedad Personal”. Así mismo, le
                recomendamos acercarse directamente a la entidad en particular (Fuente) y presentar una solicitud de
                corrección en la cual manifieste la situación de la cual es víctima adjuntando los soportes o documentos
                correspondientes. Lo anterior, de conformidad con lo establecido en el numeral 7 Item II del artículo 16
                de la Ley 1266 de 2008.</p>
              <a class="btn-midc btn-orange h-lg-btn w-100-btn" data-dismiss="modal" aria-label="Close">
                <span>Entendido</span>
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Alerta Intento Suplantacion  -->
<!-- Modal Creacion Reclamacion -->
<div class="modal fade hide" id="sendSMSModal" tabindex="-1" role="dialog" aria-labelledby="sendSMSModal"
  aria-hidden="true">
  <div class="modal-dialog okReclamacion modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 align-self-center">
              <img *ngIf="content.okSendSMS" src="assets/img/modals/alert-claims/ok-reclamacion.svg"
                alt="Alerta Notificaciones" class="img-claim" />
              <img *ngIf="!content.okSendSMS" src="assets/img/images/img_webcheckout_bad.svg"
                alt="Alerta Notificaciones" class="img-claim nok" />
            </div>
            <div class="col-12 col-md-6">
              <p class="title-reclamacion" *ngIf="content.okSendSMS">¡Tu código ha sido enviado exitosamente!</p>
              <p class="title-reclamacion" *ngIf="!content.okSendSMS">No pudimos enviar tu código satisfactoriamente</p>
              <div class="box">
                <p *ngIf="content.okSendSMS">
                  Verifica tu dispositivo
                </p>
                <p *ngIf="!content.okSendSMS">Hay un error en la información proporcionada</p>
              </div>
              <a target="_blank" class="btn-midc btn-orange h-lg-btn w-100-btn" data-dismiss="modal" aria-label="Close">
                <span *ngIf="content.okSendSMS">Entendido</span><span *ngIf="!content.okSendSMS">Intentar de
                  nuevo</span>
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Creacion Reclamacion -->
<!-- Modal Ponte al Dia YA  -->
<div class="modal fade hide" id="pdaYaModal" tabindex="-1" role="dialog" aria-labelledby="pdaYaModalLabel"
  aria-hidden="true">
  <div class="modal-dialog claimsModal modal-dialog-centered" role="document">
    <div class="modal-content">
      <!-- <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div> -->
      <div class="modal-body">
        <div class="container">
          <div class="row h-420">
            <div class="col-12 col-md-6 left-side">
              <button type="button" class="close d-block d-md-none" data-dismiss="modal" aria-label="Close">
                <img src="assets/img/modals/padYA/Cerrar_Mobile_PADYA.svg" alt="cerrar" title="cerrar"/>
              </button>
              <!-- <img src="assets/img/modals/padYA/D_modal_PADYA.png" alt="Modal padya" class="img-claim" /> -->
              <!-- imagen -->
              <div class="box-info">
                <img src="assets/img/modals/padYA/Informacion_PADYA.svg" alt="alert padya" title="alert padya"/>
                <p>Tomar el <b>control de tu vida financiera</b>, nunca fue tan <u>fácil</u>.</p>
              </div>
            </div>
            <div class="col-12 col-md-6 right-side">
              <button type="button" class="close d-none d-md-block" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="content-padya">
                <h3><b>{{sesion.user?.nombre}}</b>, así puedes <u>Ponerte al día</u>:</h3>
                <div class="steps-padya">
                  <div class="bullet-padya" *ngFor="let instruction of content.paymentInstructionsPadYA">
                    <div class="bullet-list"></div>
                    <div class="content-list" [innerHTML]="instruction">
                    </div>
                  </div>
                </div>
                <button target="_blank" class="btn-midc btn-orange h-lg-btn w-100-btn" (click)="redirectPadYa()"
                  id="btnNegociacionPadya" data-dismiss="modal" aria-label="Close">
                  <span *ngIf="!isLoadApplyPadYa">{{content.ctaPadYa}}</span>
                  <i class="fa fa-chevron-right" aria-hidden="true" *ngIf="!isLoadApplyPadYa"></i>
                  <span class="custom-loader" *ngIf="isLoadApplyPadYa" ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Modal Ponte al Dia YA  -->

<!-- Modal Freemium  -->
<div class="modal bd-example-modal-lg fade modalFreemium" tabindex="-1" role="dialog" aria-labelledby="freemiumModal" aria-hidden="true" id="freemiumModal">
    <div class="modal-dialog modal-dialog-centered mDialog" role="document">
        <div class="modal-content mContent">
            <div class="modal-body boxModal">
                <button type="button" data-dismiss="modal" aria-label="Close" class="closeM">&times;</button>
                <div class="contModal">
                    <div class="cabecera">
                    </div>
                    <div class="boxSide">
                        <h3>¡Ahora puedes conocer tu <b>Puntaje de Crédito!</b></h3>
                        <div class="item">
                            <p class="txt">Queremos darte todas las herramientas para que tomes el control de tu vida financiera. <br><br>
                               Por esto <b>POR TIEMPO LIMITADO</b> podrás conocer tu puntaje de crédito sin costo o alguna suscripción.</p>
                        </div>
                        <div class="boxBenefit">
                          <h6>Algunos <span>BENEFICIOS</span> de nuestros planes:</h6>
                          <div class="boxPri">
                            <div class="opt">
                                <img src="https://d1frrugl4zkdua.cloudfront.net/midc/icons/white/Puntaje.png" alt="benefit"/>
                                <p>Puntaje ilimítado.</p>
                            </div>
                            <div class="opt">
                                <img src="https://d1frrugl4zkdua.cloudfront.net/midc/icons/white/AlertasSistema.png" alt="benefit"/>
                                <p>Alertas antifraude.</p>
                            </div>
                            <div class="opt">
                                <img src="https://d1frrugl4zkdua.cloudfront.net/midc/icons/white/Diagnostico.png" alt="benefit"/>
                                <p>Diagnóstico.</p>
                            </div>
                          </div>
                          <p class="txt">y <span>MUCHO</span> más.</p>
                        </div>
                        <button data-dismiss="modal" aria-label="Close">Ver mi PUNTAJE</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Fin Modal Freemium  -->