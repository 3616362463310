import { Component, OnInit } from '@angular/core';
import { BrokerService } from 'src/app/core/services/broker.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { UtilitiesService } from 'src/app/core/services/utilities.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
declare var $: any;



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public linkBc = environment.enlaceBuscaCredito;
  // Bc = environment.buscacredito;
  public linkLogin = environment.enlaceLogin;
  public linkLandingPAD = '/deudas';


  public sliders: any[] = [];
  public testimonials: any;

  constructor(public broker: BrokerService, public sesion: SesionService, public modal: ModalService, public router: Router, public utilities: UtilitiesService, private dataLayerService: DataLayersService) { }

  ngOnInit(): void {
    this.validarFechaFuera();
    this.utilities.addComponent('HomeComponent');
    // this.setBanners();
  }

  ngOnDestroy(){
    this.utilities.deleteComponent('HomeComponent');
  }

  ngAfterViewInit() {
    $(".titleCard").children('span').css({ 'background-color': '#ffffff', 'color': '#645193', 'display': 'inline-block' });
    $(".contProduct.bc .titleCard").children('span').css({ 'background-color': '#ffffff', 'color': '#000000', 'display': 'inline-block' });
    this.setBanners();
  }

  validarEstrella(start: any, qualify: any) {
    if (start <= qualify) {
      return "white";
    } else {
      return "disabled";
    }
  }
  validarFechaFuera() {
    this.sesion.getIntermittence().subscribe({
      next: (response: any) => {
        let dateG = this.sesion.intermittenceGeneral
        let date = new Date()
        let dateStart = new Date(dateG.yearStart, dateG.monthStart, dateG.dayStart, dateG.hourStart, dateG.minStart);
        let dateEnd = new Date(dateG.yearEnd, dateG.monthEnd, dateG.dayEnd, dateG.hourEnd, dateG.minEnd);
        if ((date.getTime() >= dateStart.getTime()) && (date.getTime() <= dateEnd.getTime())) {
          this.modal.modalIntermitencia();
        }
        this.testimonials = this.sesion.testimonials;
        this.sesion.sliders ? this.sliders = this.sesion.sliders : this.sliders = [];
      }
    });
  }



  goHref(href: string, navigate: boolean = false) {
    if (!navigate) {
      window.location.href = href;
    } else {
      this.router.navigate([href]);
    }
  }

  setBanners() {
    let sizeTop = [[728, 90]];
    this.utilities.addPubliById(environment.slotsBanners.newHomeTop, 'div-gpt-ad-home-top', sizeTop, true);
    this.utilities.addPubliById(environment.slotsBanners.newHomeMiddle, 'div-gpt-ad-home-middle', sizeTop);
    this.utilities.addPubliById(environment.slotsBanners.newHomeBottom, 'div-gpt-ad-home-bottom', sizeTop);
  }

  clic_home_antifraud() {
    let event = {
      'event': 'ga_event',
      'name_event': 'clic_home_antifraud'
    }
    this.dataLayerService.dl_event(event);
  }



}

