<section>
  <div class="container">
      <div class="table-balance" *ngIf="sesion.misdeudas">
          <div class="row head d-none d-md-flex">
              <div class="col-md-2">Deuda actual</div>
              <div class="col-md-2">Valor <br> Inicial/Cupo</div>
              <div class="col-md-2">Saldo <br> Actual</div>
              <div class="col-md-2">Saldo <br> En mora</div>
              <div class="col-md-2">% sobre <br> el total</div>
              <div class="col-md-2">% de<br>Endeudamiento </div>
          </div>
          <div class="row head-mobile d-block d-md-none">
              <div class="col-12">Total Deuda Consolidada</div>
          </div>
          <div *ngFor="let deuda of sesion.misdeudas; let i = index">
              <div class="title d-flex d-md-none" (click)="collpaseAll(i)">
                      <h3>{{ deuda.nombreDeuda }}</h3>
                      <i class="fa fa-chevron-right" aria-hidden="true" id="arrow{{i}}"></i>
              </div>
              <div class="bg-table d-none d-md-flex">
                  <div class="row body-table">
                      <div class="col-md-2"><span>{{ deuda.nombreDeuda }}</span></div>
                      <div class="col-md-2" *ngIf="deuda.nombreDeuda != 'Saldo en Tarjetas de Crédito/Rotativos'">
                          <span *ngIf="deuda.valorInicial">{{ deuda.valorInicial | currency: '$ ':'symbol':'1.0' }}</span>
                          <span *ngIf="deuda.cupoTotal">{{ deuda.cupoTotal | currency: '$ ':'symbol':'1.0' }}</span>
                      </div>
                      <div class="col-md-2" *ngIf="deuda.nombreDeuda == 'Saldo en Tarjetas de Crédito/Rotativos'">
                          <span>{{ deuda.valorInicial | currency: '$ ':'symbol':'1.0' }}</span>
                      </div>
                      <div class="col-md-2">
                          <span>{{ deuda.saldoActual | currency: '$ ':'symbol':'1.0' }}</span>
                      </div>
                      <div class="col-md-2">
                          <span>{{ deuda.saldoMora | currency: '$ ':'symbol':'1.0' }}</span>
                      </div>
                      <div class="col-md-2">
                          <span>{{ deuda.porcentajeSobreTotal }} %</span>
                      </div>
                      <div class="col-md-2">
                          <span>{{ deuda.porcentajeEndeudamiento }} %</span>
                      </div>
                  </div>
              </div> 
              <div id="tableBody{{i}}" class="tableBodyAll collapse in">
                  <div class="row head d-inilne-block d-md-none">
                      <div class="col-6">Valor Inicial/Cupo</div>
                      <div class="col-6" *ngIf="deuda.nombreDeuda == 'Saldo en Tarjetas de Crédito/Rotativos'">
                          <span>{{ deuda.valorInicial | currency: '$ ':'symbol':'1.0' }}</span>
                      </div>
                  </div>
                  <div class="row head d-inilne-block d-md-none">
                      <div class="col-6">Saldo Actual</div>
                      <div class="col-6">
                          <span>{{ deuda.saldoActual | currency: '$ ':'symbol':'1.0' }}</span>
                      </div>
                  </div>
                  <div class="row head d-inilne-block d-md-none">
                      <div class="col-6">Saldo En mora</div>
                      <div class="col-6">
                          <span>{{ deuda.saldoMora | currency: '$ ':'symbol':'1.0' }}</span>
                      </div>
                  </div>
                  <div class="row head d-inilne-block d-md-none">
                      <div class="col-6">% sobre el total</div>
                      <div class="col-6" *ngIf="deuda.nombreDeuda != 'Saldo en Tarjetas de Crédito/Rotativos'">
                          <span>{{ deuda.porcentajeSobreTotal }} %</span>
                      </div>
                  </div>
                  <div class="row head d-inilne-block d-md-none">
                      <div class="col-6">% de Endeudamiento</div>
                      <div class="col-6" *ngIf="deuda.nombreDeuda != 'Saldo en Tarjetas de Crédito/Rotativos'">
                          <span>{{ deuda.porcentajeEndeudamiento }} %</span>
                      </div>
                  </div>
              </div>      
          </div>
      </div>
  </div>
</section>

<div class="row mdc-text-secondary mb-5 ">
  <div class="col-12 col-md-6">
      <h3 class=" mdc-text-secondary mt-5 mdc-fs-3 mdc-text-b text-center">Créditos a Plazo Fijo, Hipotecarios y Otros</h3>
      <div class="row text-center mdc-block-transparent rounded">
          <div class="col-12">
              <div style="width:100%;" class="text-center">
                  <div style="width:100%;" >
                      <canvas  baseChart
                          class="m-auto"
                          [data]="sesion.totalOtrosChartData"
                          [type]="sesion.totalOtrosChartType">
                      </canvas>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="col-12 col-md-6">
      <h3 class=" mdc-text-secondary mt-5 mdc-fs-3 mdc-text-b text-center">Tarjetas de crédito</h3>
      <div class="row text-center mdc-block-transparent rounded">
          <div class="col-12">
              <div style="width:100%;" class="text-center">
                  <div style="width:100%;" >
                      <canvas  baseChart
                          class="m-auto"
                          [data]="sesion.totalTcChartData"
                          [type]="sesion.totalTcChartType">
                      </canvas>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>