<div>
    <h1 class="title">Toma el control de tu <span>vida crediticia</span> <br> <span class="sub">Con nuestras soluciones:</span></h1>
    <div class="row custom-container">
        <div class="col-12 col-md-4">
            <app-info-product [product]="'midc'" [isSmall]="true" ></app-info-product>
        </div>
        <div class="col-12 col-md-4">
            <app-info-product [product]="'bc'" [isSmall]="true" ></app-info-product>
        </div>
        <div class="col-12 col-md-4">
            <app-info-product [product]="'pntd'" [isSmall]="true" ></app-info-product>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div id='div-gpt-ad-home-top' class="commercial-banner small">
            </div>
        </div>
    </div>
    <div class="slideBann">
        <p>El <b>80% DE LOS USUARIOS</b> de <b>Midatacrédito</b> vieron un <span>IMPACTO POSITIVO</span> en su vida financiera.&#185;</p>
    </div>
    <div class="box-product custom-container no-margin-bot">
        <app-info-product style="width: 45%;" [product]="'app'" ></app-info-product>
        <app-info-product style="width: 55%;" [product]="'midc'" ></app-info-product>
    </div>
    <!--FALTA EL VIDEO POR PARTE DEL EQUIPO DE MARKETING-->
    <!-- <div class="hashGeneric custom-container">
        <div class="boxHash">
            <div class="hash">
                <p>#VuélveteEXPERTO</p>
                <i class="fa fa-sort-down" aria-hidden="true"></i>
            </div>
        </div>
        <p>Vuélvete un <b>EXPERTO FINANCIERO</b> con todos los vídeos y contenido que Midatacrédito tiene para ti.</p>
    </div>
    <div class="custom-container">
        <div class="video">

        </div>
    </div> -->
    <div class="box-product custom-container no-margin-top">
        <app-info-product class="d-block d-md-none" style="width: 100%;" [product]="'pntd'" ></app-info-product>
        <app-info-product style="width: 55%;" [product]="'bc'" ></app-info-product>
        <app-info-product class="d-none d-md-block" style="width: 45%;" [product]="'pntd'" ></app-info-product>
    </div>
    <div class="hashGeneric custom-container">
        <div class="boxHash">
            <div class="hash">
                <p>#NuestrosALIADOS</p>
                <i class="fa fa-sort-down" aria-hidden="true"></i>
            </div>
        </div>
        <p>Conoce toda la red de <b>ALIADOS</b> que forman parte de nuestro ecosistema:</p>
    </div>
    <app-sponsors></app-sponsors>
    <div class="row">
        <div class="col-12">
            <div id='div-gpt-ad-home-middle' class="commercial-banner">
            </div>
        </div>
    </div>
    <div class="hashGeneric secondary custom-container">
        <div class="boxHash right secondary">
            <div class="hash">
                <p>#EvitaelFRAUDE</p>
                <i class="fa fa-sort-down" aria-hidden="true"></i>
            </div>
        </div>
        <p>Conoce el <span>SISTEMA DE ALERTAS DE OBLIGACIONES NUEVAS EN LA HISTORIA CREDITICIA</span> para mitigar suplantaciones de identidad.</p>
        <button (click)="clic_home_antifraud()" (click)="goHref(linkLogin+'&utm_source=MIDC&utm_medium=HOME&utm_campaign=BANNERALERTAS')">Conócelo Aquí</button>
    </div>
    <div class="custom-container">
        <app-testimonials [testimonials]="testimonials"></app-testimonials>
    </div>
    <div class="hashGeneric custom-container">
        <div class="boxHash">
            <div class="hash">
                <p>#VuélveteEXPERTO</p>
                <i class="fa fa-sort-down" aria-hidden="true"></i>
            </div>
        </div>
        <p>Entérate de todas las novedades del ecosistema de Midatacrédito:</p>
    </div>
    <div class="custom-container">
        <app-view-posts></app-view-posts>
    </div>
    <div class="row">
        <div class="col-12">
            <div id='div-gpt-ad-home-bottom' class="commercial-banner">
            </div>
        </div>
    </div>
</div>
