import { Component, OnInit, Input } from '@angular/core';
import { BrokerService } from 'src/app/core/services/broker.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { environment } from 'src/environments/environment';
import { debt } from '../dash-pad-module.component';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { UtilitiesService } from 'src/app/core/services/utilities.service';
declare var $: any;
@Component({
  selector: 'app-box-debt',
  templateUrl: './box-debt.component.html',
  styleUrls: ['./box-debt.component.scss']
})
export class BoxDebtComponent implements OnInit {

  @Input() maxSize: boolean = false;
  @Input() debt: debt;
  public isLoadApplyPadYa: boolean = false;
  public canCallApplyPadYa: boolean = true;
  constructor(public modal: ModalService, public broker: BrokerService, private dataLayer: DataLayersService, public utilities: UtilitiesService) {
    this.debt = {};
  }

  ngOnInit(): void { }

  /**
   * Funcion que abre el tooltip que esta asociado al estado de la negociacion
   * @param view evento del click para obtener el boton desde el cual se realizo la accion
   */
  switchPop(event: Event) {
    if ($(event.target).parent().attr('class').includes('box-pop')) {
      $(event.target).parent('.box-pop').toggle();
    } else {
      $(event.target).parent().children('.box-pop').toggle();
    }
  }

  /**
   * Funcion que dependiendo del estado de la deuda devuelve el texto que corresponde al toooltip
   * @param state estado de la deuda
   * @returns el texto que debe ir en el tooltip dependiendo del estado
   */
  textTooltipState(state?: string) {
    switch (state) {
      case 'AL DIA':
        return 'Esto quiere decir que completaste con éxito tu acuerdo de pago.';
      case 'CON DEUDA':
        return 'Esto quiere decir que tu obligación aún no cuenta con un acuerdo de pago.';
      case 'EN MORA':
        return 'Esto quiere decir que tu obligación aún no cuenta con un acuerdo de pago.';
      case 'EN NEGOCIACION':
        return 'Esto quiere decir que cuentas con un acuerdo de pago vigente.';
      case 'CON ACUERDO':
        return 'Esto quiere decir que incumpliste con tu acuerdo de pago.';
      default:
        return 'Informacion no encontrada';
    }
  }

  /**
   * Funcion para cambiar el texto de en negocacion a negociacion
   * @param state estado de la deuda
   * @returns 'NEGOCIACION' si el estado es 'EN NEGOACACION' de lo contrario de vuelve el mismo texto
   */
  textTransform(state?: string) {
    if (state == "EN NEGOCIACION") {
      return "NEGOCIACION";
    }
    return state;
  }

  /**
   * Funcion para obtener el color de la estado de la deuda
   * @param state estado obtenido en el servicio 
   * @returns clase para usar el color que corresponde al estado de la deuda
   */
  getColorState(state: any) {

    let states: any = {
      'AL DIA': 'ok',
      'CON DEUDA': 'conDeuda',
      'EN MORA': 'deb',
      'EN NEGOCIACION': 'neg',
      'CON ACUERDO': 'acuerdo'
    }
    return (states[state] != undefined ? states[state] : '');
  }

  isAgree(state: any) {
    let states: any = {
      'AL DIA': 'agree',
      'EN NEGOCIACION': 'agree',
      'CON ACUERDO': 'agree'
    }
    return (states[state] != undefined ? states[state] : '');
  }

  /**
   * Funcion que consume el servicio de registerApplyPAD y abre el modal cargando los valores correspondientes de cta e instrucciones.
   * @param detalleCa detalla cuenta que trae la Historia de credito
   */
  openModalPadYa(detalleCa: any) {
    this.isLoadApplyPadYa = true;
    if (this.canCallApplyPadYa) {
      if ((detalleCa.nameCTA != null && detalleCa.nameCTA != "") && detalleCa.paymentInstructions != null) {
        if (detalleCa.paymentInstructions.length > 0) {
          this.canCallApplyPadYa = false;
          this.modal.ctaPadYa = detalleCa.nameCTA;
          this.modal.paymentInstructionsPadYA = detalleCa.paymentInstructions;
          this.modal.itemPadYa = detalleCa.item;
          this.modal.urlPadYa = detalleCa.enlacePad;
          this.modal.numberPadYa = detalleCa.numero;
          this.modal.estadotexto = detalleCa.estadoTexto;
          this.modal.saldoMora = detalleCa.saldoMora;
          this.modal.numeroCuenta = detalleCa.numeroCuenta;
          let num = detalleCa.item;
          let text = num.toString();
          this.utilities.getUtmParams(false);
          let sourceOfTraffic = '';
          let campaignOfTraffic = '';
          let mediumOfTraffic = '';
          if ((Object.keys(this.utilities.utmParams).length > 0)) {
            for (const key in this.utilities.utmParams) {
              if (key.toLowerCase() == 'utm_source') {
                sourceOfTraffic = this.utilities.utmParams[key]
              }
              if (key.toLowerCase() == 'utm_medium') {
                mediumOfTraffic = this.utilities.utmParams[key]
              }
              if (key.toLowerCase() == 'utm_campaign') {
                campaignOfTraffic = this.utilities.utmParams[key]
              }
            }
          }
          this.broker.registerApplyPAD(text, detalleCa.estadoTexto, detalleCa.saldoMora, detalleCa.numeroCuenta,sourceOfTraffic,campaignOfTraffic,mediumOfTraffic).subscribe(
            {
              next: (data: any) => {
                if (data.code == 0) {
                  this.modal.consumerNumber = data.consumerNumber;
                  this.isLoadApplyPadYa = false;
                  this.canCallApplyPadYa = true;
                  this.modal.modalPadYa();
                }
              },
              error: (error) => {
                this.isLoadApplyPadYa = false;
                this.canCallApplyPadYa = true;
              }
            }

          );
        } else {
          this.isLoadApplyPadYa = false;
        }
      } else {
        this.isLoadApplyPadYa = false;
      }
    }
  }

  /**
   * Funcion que valida el texto que se debe visualizar en el boton de cta de las box de deudas.
   * @returns Texto del boton de las box de deudas
   */
  textCta() {
    if (this.debt.sourceDebt == 'pad') {
      if (this.debt.stateDebt == 'EN NEGOCIACION' || this.debt.stateDebt == 'CON ACUERDO' || this.debt.stateDebt == 'AL DIA') {
        return 'Ver acuerdo';
      }
      return 'Ver plan de pagos';
    }
    return 'Ponerme al Día';
  }

  /**
   * Funcion para validar que metodo se usa en caso de que la deuda sea de padYa o pad tradicional y si ya esta negociado redirecciona a pad.
   */
  callMethodCta() {
    if (this.debt.sourceDebt == 'pad') {
      if (this.debt.stateDebt == 'EN NEGOCIACION' || this.debt.stateDebt == 'CON ACUERDO' || this.debt.stateDebt == 'AL DIA') {
        window.open(environment.enlacePAD, '_self')
      } else {
        this.getUrlEscala(this.debt.debtDetail.de);
      }
      this.send_datalayer_pad(this.textCta(), this.debt);
    } else {
      this.openModalPadYa(this.debt.debtDetail);
      this.send_datalayer_pad('Ponerme al Día', this.debt);
    }
  }

  /**
   * Función para enviar data layer sobre el caso de la deuda de PAD 
  */
  send_datalayer_pad(btn_name: string, deuda: any) {
    let dt_name_event = '';
    if (btn_name == 'Ver plan de pagos') { dt_name_event = 'btn_view_payment_plan_dasboard' }
    if (btn_name == 'Ver acuerdo') { dt_name_event = 'btn_view_agreements_dasboard' }
    if (btn_name == 'Ponerme al Día') { dt_name_event = 'btn_catch_up_dashboard' }

    this.dataLayer.dl_eventoGA4_pad_dashboard(dt_name_event,
      deuda.stateDebt,
      deuda.entityName,
      deuda.typDebt,
      deuda.debtTime,
      deuda.debtValue
    )
  }

  /**
   * Funcion que envia el idConsumerdebts y el sessionID al metodo que llama el servicio /pontealdia/v1/process/preload/info 
   * que nos devuelve la url de escala para redireccionar al usuario a escala
   * @param idConsumerdebts ide de la deuda que se obtiene del servicio de /pontealdia/v1/debt
   */
  getUrlEscala(idConsumerdebts: String) {
    // this.loading.openFullScreen();
    this.broker.getUrlEscala(idConsumerdebts).subscribe({
      next: (value: any) => {
        window.open(value.urlPreload, '_self');
        // this.loading.closeFullScreen();
      },
      error: (err) => {
        // $("#spinnerDebtError"+i).addClass("d-block");
        // this.loading.closeFullScreen();
      },
    });
  }
}
