<div class="box-pad genericBox" [ngClass]="{'box-pad-app' : isApp}">
    <div class="header-pad" *ngIf="!isApp">
        <h2>Te ayudamos de manera GRATUITA</h2>
        <p>a negociar para que te Pongas al Día en tus obligaciones.</p>
    </div>
    <div class="content-pad" *ngIf="debts.length > 0">
        <div *ngIf="sesion.isLoadedDebts">
            <app-micro-loader [error]="erroLoadDebts"></app-micro-loader>
        </div>
        <!--Carrusel de 3 deudas maximo por slide para desktop-->
        <div id="carousel-pad" class="carousel d-none d-md-block" data-ride="carousel" data-interval="false"
            *ngIf="!sesion.isLoadedDebts && !isApp">
            <div class="carousel-inner">
                <div class="carousel-item" [ngClass]="{'active': i==0}" *ngFor="let debts of debtsBy3;let i=index">
                    <div class="box-car">
                        <app-box-debt *ngFor="let debt of debts;" [maxSize]="debts.length == 1"
                            [debt]="debt"></app-box-debt>
                    </div>
                </div>
            </div>
            <ul class="carousel-indicators circles" *ngIf="debtsBy3.length > 1">
                <li data-target="#carousel-pad" class="btnCircle" [ngClass]="{'active': i==0}"
                    (click)="addClass($event,'active')" *ngFor="let debts of debtsBy3;let i=index"
                    [attr.data-slide-to]="i"><i class="fa fa-circle"></i></li>
            </ul>
            <a class="carousel-control-prev spaceBtn" href="#carousel-pad" role="button" data-slide="prev"
                *ngIf="debtsBy3.length > 1">
                <i class="fa fa-angle-left"></i>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next spaceBtn" href="#carousel-pad" role="button" data-slide="next"
                *ngIf="debtsBy3.length > 1">
                <i class="fa fa-angle-right"></i>
                <span class="sr-only">Next</span>
            </a>
        </div>
        <!--Carrusel de 3 deudas maximo por slide para desktop-->
        <!--Carrusel de deuda por slide para mobile-->
        <div id="carousel-pad-m" class="carousel d-block d-md-none" data-ride="carousel" data-interval="false"
            *ngIf="!sesion.isLoadedDebts && !isApp">
            <div class="carousel-inner">
                <div class="carousel-item" [ngClass]="{'active': i==0}" *ngFor="let debt of debts;let i=index">
                    <div class="box-car">
                        <app-box-debt [debt]="debt"></app-box-debt>
                    </div>
                </div>
            </div>
            <ul class="carousel-indicators circles" *ngIf="debts.length > 1">
                <li data-target="#carousel-pad-m" class="btnCircle" [ngClass]="{'active': i==0}"
                    (click)="addClass($event,'active')" *ngFor="let debt of debts;let i=index" [attr.data-slide-to]="i">
                    <i class="fa fa-circle"></i>
                </li>
            </ul>
            <a class="carousel-control-prev spaceBtn" href="#carousel-pad-m" role="button" data-slide="prev"
                *ngIf="debts.length > 1">
                <i class="fa fa-angle-left"></i>
                <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next spaceBtn" href="#carousel-pad-m" role="button" data-slide="next"
                *ngIf="debts.length > 1">
                <i class="fa fa-angle-right"></i>
                <span class="sr-only">Next</span>
            </a>
        </div>
        <!--Carrusel de deuda por slide para mobile-->
        <!--Cards de deudas para el app-->
        <div *ngIf="!sesion.isLoadedDebts && isApp">
            <div class="card-debt-app" *ngFor="let debt of debts;let i=index">
                <div class="box-car">
                    <app-box-debt [debt]="debt"></app-box-debt>
                </div>
            </div>
        </div>
        <!--Cards de deudas para el app-->
    </div>
</div>