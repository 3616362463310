import { Component, OnInit } from '@angular/core';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { SesionService } from 'src/app/core/services/sesion.service';
@Component({
  selector: 'app-mi-deuda',
  templateUrl: './mi-deuda.component.html',
  styleUrls: ['./mi-deuda.component.scss']
})
export class MiDeudaComponent implements OnInit {

  public endeudamiento = false;
  public textoMicroLoader = 'Por favor espera un momento, estamos cargando tu información.';
  public textErrorMicroLoader = 'En este momento estamos presentando problemas. Por favor inténtalo más tarde.';

  constructor(public sesion: SesionService, private dataLayerService: DataLayersService) { 
    if (this.sesion.diagnostico == undefined) {
      this.sesion.infoHC(true);
    }
  }

  ngOnInit() {
    // GA4 - User
    this.dataLayerService.dl_userId( '.', this.sesion.planCurrently );
  }

  getSliceDeudas(){
    return this.sesion.misdeudas.slice(0,4)
  }

}
