import { Injectable } from '@angular/core';
import { BrokerService } from './broker.service';
import { LoadingService } from './loading.service';
import { SesionService } from './sesion.service';
import { WebActivityService } from './web-activity.service';
import { Location } from '@angular/common';
import { Upselling } from 'src/app/core/interfaces/upselling';
import { ModalService } from './modal.service';

declare var $: any;

interface Upselling1 {
	status: string,
	errorCode: string,
	message: string,
	accountInfo:{
		idPeriod: string,
		namePlan: string,
		totalValue: number,
		subscriptionDays: number,
		expirationDate: string,
		nextPaymentdDate: string,
		recommendedPlan:[{
			idPeriod: string,
			namePlan: string,
			period: string,
			months: number,
			totalValue: number,
			discount: number,
			totalWithDiscount:number
		}]
	}
}

@Injectable({
  providedIn: 'root'
})
export class UpsellingService {

	plansUpsell = true;
	plansOthers = false;

	planUpsell = {} as Upselling1;
	idPeriod: any;
	recommendedPlan: any;
	planSelect: any;
	noPay1: any;
	

  constructor(
    	public broker: BrokerService,
	public sesion: SesionService,
    	public loading: LoadingService,
    	private webActivity: WebActivityService,
    	public _location: Location,
    	public modal: ModalService,
    
  ) {
	  
   }

  	obtener_upselling() {	
		 this.loading.open();	 
		const data = {
			sessionId: this.sesion.cookieValue,
		};
		this.broker.obtener_upselling(this.sesion.cookieValue).subscribe( (result: any) => {
			this.noPay1 = parseInt(result.errorCode);
			if(this.noPay1 > 0 ){
				this.plansUpsell = false;
				this.plansOthers = true;
				$(".titleH2").hide();
				this.loading.close();
			} else {
				this.planUpsell = result;
				this.idPeriod = this.planUpsell.accountInfo.idPeriod;
				this.recommendedPlan = this.planUpsell.accountInfo.recommendedPlan;
				this.loading.close();
			}
			
		},
		(error) => {
		});	
  	}

	confirmarPlan(){
		this.webActivity.sendDataAWS("click-cambiar-confirmarPlan"+this.planSelect.idPeriod,"UPSELL2", false);
		this.loading.open();		
		this.broker.confirmar_plan(this.sesion.cookieValue, this.planSelect).subscribe( (result) => {
			localStorage.setItem("changeMethod", "1");
			window.location.href = "/private/historico";
		});		
	} 
	
}
