import { Component, OnInit } from '@angular/core';
import { BrokerService } from 'src/app/core/services/broker.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { SuscripcionService } from 'src/app/core/services/suscripcion.service';

@Component({
  selector: 'app-suscripcion',
  templateUrl: './suscripcion.component.html',
  styleUrls: ['./suscripcion.component.scss']
})
export class SuscripcionComponent implements OnInit {

  constructor(public suscripcion: SuscripcionService,
    private sesion: SesionService,
    public broker: BrokerService) { }

  ngOnInit() {
  }

  seleccionarPlan(tipo: string, valorMensual: string, valorTotal: string) {
    this.broker.promov = 3
    this.suscripcion.tipoPlan = tipo;
    this.suscripcion.valorSeleccionadoMensual = valorMensual;
    this.suscripcion.valorSeleccionadoTotal = valorTotal;
    if (this.sesion.user) {
      this.suscripcion.planSesion(true, 'SUS');
    } else {
      this.suscripcion.planSesion(false, 'SUS');
    }
  }

}
