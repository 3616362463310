import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BrokerService } from 'src/app/core/services/broker.service';
import { ChangePaymentService } from 'src/app/core/services/change-payment.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { SesionService } from 'src/app/core/services/sesion.service';

@Component({
  selector: 'app-metodo-pago',
  templateUrl: './metodo-pago.component.html',
  styleUrls: ['./metodo-pago.component.scss']
})
export class MetodoPagoComponent implements OnInit {
	@Input() data: any;
	summaryPay = true;
	tcChange = false;
	pseChange = false;
	backNav = false;

	constructor(
		public broker: BrokerService,
		public sesion: SesionService,
		public router: Router,
		public loading: LoadingService,
		public changeMethod: ChangePaymentService,
		private dataLayerService: DataLayersService
	) { 
		this.changeMethod.consultarMetodoPago();	
	}

	ngOnInit() {		
	}
	pseChangeClick(){
		// GA4 - change_payment_method
		this.dataLayerService.dl_eventoGA4_1parametro('change_payment_method');
		if(this.sesion.estadoUserF == 'PENDING' || this.sesion.estadoUserF == 'PENDING_PSE' || this.sesion.estadoUserF == 'INPROCESS' || this.sesion.estadoUserF == 'ACTIVE_PROCESS') {
			window.scrollTo( 0, 0); 		
		} else {
			this.backNav = true;
			this.summaryPay = false;
			this.pseChange = true;
			this.tcChange = false; 
		}		   
	}

	tcChangeClick(){
		if(this.sesion.estadoUserF == 'PENDING' || this.sesion.estadoUserF == 'PENDING_PSE' || this.sesion.estadoUserF == 'INPROCESS' || this.sesion.estadoUserF == 'ACTIVE_PROCESS') {
			window.scrollTo( 0, 0); 		
		} else {
			this.backNav = true;
			this.summaryPay = false;
			this.tcChange = true;
			this.pseChange = false;
		}		
	}

	backClick() {
		this.backNav = false;
		this.summaryPay = true;
		this.tcChange = false;
		this.pseChange = false;
	}

	tcChangeClick1() {
		// GA4 - update_credit_card
		this.dataLayerService.dl_eventoGA4_1parametro('update_credit_card');

		if(this.changeMethod.infoMethodPay.nuevoMetodoPago == 'PSE'){
			this.backNav = true;
			this.summaryPay = false;
			this.pseChange = true;
			this.tcChange = false;   
		} else {
			this.backNav = true;
			this.summaryPay = false;
			this.tcChange = true;
			this.pseChange = false;
		}
	}
}
