import { Component, Input, OnInit } from '@angular/core';
import { SesionService } from 'src/app/core/services/sesion.service';
import { BrokerService } from 'src/app/core/services/broker.service';
declare var $: any;

interface ModalInfo {
  title: string;
  cont: string;
  alert: string;
  dateStart: string;
  dateEnd: string;
}

@Component({
  selector: 'app-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss']
})
export class ScoreComponent implements OnInit {
  @Input() diagnostico: boolean = false;
  @Input() simulador: boolean = false;
  @Input() home: boolean = false;

  flagAnimation:boolean=false;
  modalInfo: ModalInfo;
  view:string='generic'
  constructor(public sesion: SesionService, public broker: BrokerService,) {
    if(sesion.viewFree && sesion.campainFree){
      this.view = 'campainFree'
    }else if(!sesion.viewFree && sesion.dataUser.params[13].value){
      this.view = 'advance'
    }else{
      this.view = 'generic'
    }
    this.modalInfo = {
      title: '¡Hemos <b>mejorado</b> nuestra forma de medir el <b>puntaje de crédito!</b>',
      cont: 'Te queremos presentar una forma <b>MUCHO</b> más acertada de medir el puntaje. <br><br> Esta medición nos permitirá estar más en ajustados con las entidades financieras y brindarte una experiencia mucho mejor.',
      alert: '<b>Nada</b> de que preocuparse si ves algún cambio en tu puntaje, esto solo significa que se <u>calibro correctamente</u> a la nueva medición. <br><br>Si tienes alguna duda adicional, estamos siempre para ti en <a href="tel:6017430606">Contáctanos.</a>',
      dateStart: '2023-10-25-12-0',
      dateEnd: '2023-12-30-20-1',
           
    };
  }

  ngOnInit() {
   if(!this.sesion.campainFree){
    if(this.sesion.dataUser.params[13].value){
      this.getInfoModal();
     }
   }
  }

  addAnimationScore(){
    if(!this.flagAnimation){setTimeout( () => {
    let gap = (((this.sesion.miscore)/10)*180)/100;
    let move = gap-90;
    $(".iAdvance").css("transform","rotate("+move.toString()+"deg)");
    this.flagAnimation = true;
    }, 1000);}
    return "";
  }

  getInfoModal(){
		this.broker.getAdvanceScore().subscribe(
		  {
			next: (data: any) => {
        this.modalInfo=data;
        let start= this.modalInfo.dateStart.split("-",);
        let end= this.modalInfo.dateEnd.split("-",);
			  let date = new Date()
        let dateStart = new Date(parseInt(start[0]),parseInt(start[1])-1,parseInt(start[2]),parseInt(start[3]),parseInt(start[4]));
        let dateEnd = new Date(parseInt(end[0]),parseInt(end[1])-1,parseInt(end[2]),parseInt(end[3]),parseInt(end[4]));
        if(((date.getTime() >= dateStart.getTime()) && (date.getTime() <= dateEnd.getTime())) && !this.sesion.modalAdvance){
          $('#modalFun').modal('show');
          this.sesion.modalAdvance=true;
        }
			},
			error: (error) => {
			}
		  }
		  
		);
	}

}
