import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BrokerService } from 'src/app/core/services/broker.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { SesionService } from 'src/app/core/services/sesion.service';
import { SuscripcionService } from 'src/app/core/services/suscripcion.service';
import { Plan } from '../../../core/models/plan.model';
import { MetadatosService } from 'src/app/core/services/metadatos.service';
import { DataLayersService } from 'src/app/core/services/data-layers.service';

@Component({
  selector: 'app-premium',
  templateUrl: './premium.component.html',
  styleUrls: ['./../perfil-credito/perfil-credito.component.scss']
})
export class PremiumComponent implements OnInit {

  constructor(
    public suscripcion: SuscripcionService,
    public sesion: SesionService,
    public loading: LoadingService,
    public broker: BrokerService,
    public router: Router,
    private metadatosService: MetadatosService,
    private titulo: Title,
    private meta: Meta,
    private dlService: DataLayersService
  ) {
      titulo.setTitle('Luis: Superé la suplantación con Midatacrédito');
      meta.updateTag({name: 'description', 
        content:"Luis E. encontró en Midatacrédito el apoyo que necesitaba para resolver problemas de suplantación. ¡Descubre cómo puede ayudarte!"});
   }

  ngOnInit() {
    this.metadatosService.actualizando_canonical('https://www.midatacredito.com/landing/premium');
    this.tags_datalayer();
    localStorage.clear();
  }

  tags_datalayer(){
    // GA4 - user
    if (this.sesion.user){
      this.dlService.dl_userId( '.', this.sesion.planCurrently );
    } else {
      this.dlService.dl_userId( null, 'anonimo' );
    }

    this.dlService.dl_view_item_list(this.sesion.planes.planes.pre, null, null, 'LANDING-PLAN-PREMIUM', 'Mensual');
  }

  viewPrices(item: any) {
    this.sesion.facturacion = item;
    this.cambio_de_planes(item);
  }

  cambio_de_planes(item: string){
    let plan = this.sesion.planes.planes.pre;

    if ( item == '1'){
      plan.mensual[0] = this.sesion.planes.planes.pre.valor[0];
      this.dlService.dl_view_item_list(plan, null, null, 'LANDING-PLAN-PREMIUM', 'Mensual');
    }
    if ( item == '3'){
      plan.mensual[0] = this.sesion.planes.planes.pre.trimestral[0];
      this.dlService.dl_view_item_list(plan, null, null, 'LANDING-PLAN-PREMIUM', 'Trimestral');
    }
    if ( item == '12'){
      plan.mensual[0] = this.sesion.planes.planes.pre.anual[0];
      this.dlService.dl_view_item_list(plan, null, null, 'LANDING-PLAN-PREMIUM', 'Anual');
    }
  }

  seleccionarPlan(plan: any, tipo: any, valorMensual: any, valorTotal: any) {
    this.suscripcion.planSeleccionado = plan;
    this.suscripcion.tipoPlan = tipo;
    this.suscripcion.valorSeleccionadoMensual = valorMensual;
    this.suscripcion.valorSeleccionadoTotal = valorTotal;
    this.dlService.dl_add_to_cart(plan, valorTotal, tipo, 'LANDING-PLAN-PREMIUM' );
    if (this.sesion.user) {
      this.suscripcion.planSesion(true, 'LPRO');
    } else {
      this.suscripcion.planSesion(false, 'LPRO');
    }
  }

}
